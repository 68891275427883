import React from 'react'

type TextBoxProps = {
  value: string
  disabled?: boolean
  onChange: (value: string) => void
}

const TextBox = ({ value, disabled, onChange }: TextBoxProps) => {
  return (
    <textarea
      disabled={disabled}
      className='rounded-sm surface-container-bg outline-border outline-0 margin-t-sm on-surface flex-grow-1 w-100 padding-md border-box'
      style={{ minHeight: '100px', maxHeight: '320px', resize: 'none' }}
      placeholder={'Type to add a comment.'}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

export default TextBox
