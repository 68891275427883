import { LatLngCoord } from '../../../../types'
import * as THREE from 'three'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { DxfParser, IDxf } from 'dxf-parser'
import { drawEntity } from './DrawUtils/Entity'
import { getPixelRes } from '../CoordHelper/CoordUtils'

export const parseDXF = async (
    file: any,
    scale: number,
    mapTileOrigin: LatLngCoord
) => {
    const loader = new FontLoader()
    const font = await loader.loadAsync(
        'https://threejs.org/examples/fonts/helvetiker_regular.typeface.json'
    )
    if (!font) return

    const group = new THREE.Group()
    const parser = new DxfParser()
    const data = parser.parseSync(file)
    if (!data) return

    // const { centerX, centerY } = getActiveViewPortCenter(data, scale)
    // const cv = new SVY21()
    // const latLon = cv.computeLatLon(centerY, centerX) // X is east, Y is north
    // const scene = latLngToScene(
    //     {
    //         latitude: latLon.lat,
    //         longitude: latLon.lon,
    //         zoom: mapTileOrigin?.zoom,
    //     },
    //     mapTileOrigin
    // )
    // if (Math.abs(scene.x) > 10000 || Math.abs(scene.y) > 10000) return
    const MAX_ENTITIES = 1000
    for (let i = 0; i < Math.min(MAX_ENTITIES, data.entities.length); i++) {
        const entity = data.entities[i]
        const obj = drawEntity(
            entity,
            data,
            scale,
            font,
            mapTileOrigin,
            getPixelRes()
        )
        if (obj) {
            group.add(obj)
        }
    }
    group.position.set(0, 0, 0.2)
    return group
}

const getActiveViewPortCenter = (data: IDxf, unit: number) => {
    const filtered = data.tables.viewPort.viewPorts.filter((value) =>
        value.name.includes('Active')
    )
    if (filtered.length > 0) {
        return {
            centerX: filtered[0].center.x / unit,
            centerY: filtered[0].center.y / unit,
        }
    }
    return { centerX: 0, centerY: 0 }
}
