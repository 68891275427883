import React, { useEffect, useState } from 'react'

import StateLayer from '../elements/StateLayer'
import FileLogo from '../../assets/icons/description_black_24dp.svg'
import VideoLogo from '../../assets/icons/movie_black_24dp.svg'
import MissionLogo from '../../assets/icons/design_services_black_24dp.svg'
import UserManualLogo from '../../assets/icons/menu_book_black_24dp.svg'
import PCDLogo from '../../assets/icons/grain_black_24dp.svg'
import { PageType } from '../../types'
import { isMobile } from '../utils/general'

type PageSelectorType = {
  title: string
  description: string
  Icon: SvgrComponent
  disabled?: boolean
  onClick: () => void
}

const PageSelector = ({
  title,
  description,
  Icon,
  disabled,
  onClick,
}: PageSelectorType) => {
  return (
    <div
      className={`flex flex-col align-center justify-center padding-lg
        ${disabled ? 'disabled' : ''}`}
      style={{ width: '260px', height: '260px' }}>
      <StateLayer
        className={`rounded-lg padding-xlg shadow`}
        color='surface'
        hoverColor='on-surface'
        disabledColor='surface-variant'
        disabled={disabled}
        onClick={onClick}
        child={<Icon className={`on-surface`} height={64} width={64} />}
      />
      <div className='h1' style={{ marginTop: '20px' }}>
        {title}
      </div>
      <div className='h3' style={{ marginTop: '8px' }}>
        {description}
      </div>
    </div>
  )
}

type SelectionPageType = {
  onChangePage: (page: PageType) => void
  userManualAccess: boolean
}

export const SelectionPage = ({
  onChangePage,
  userManualAccess,
}: SelectionPageType) => {
  const mobile = isMobile()

  return (
    <div
      className={`flex align-center text-center flex-grow-1
        ${mobile ? 'flex-col justify-start padding-t-lg' : 'justify-center'}`}>
      <PageSelector
        title='REPORTS'
        description='Check your reports here.'
        Icon={FileLogo}
        onClick={() => onChangePage(PageType.REPORT)}
      />
      <PageSelector
        title='RECORDINGS'
        description='Review your recordings here.'
        Icon={VideoLogo}
        onClick={() => onChangePage(PageType.VIDEO)}
      />
      <PageSelector
        title='POINT CLOUD DATA'
        description='Review your point cloud data here.'
        Icon={PCDLogo}
        onClick={() => onChangePage(PageType.PCD)}
      />
      {/* <PageSelector
        title='MISSION PLANNING'
        description='Plan autonomous missions here.'
        Icon={MissionLogo}
        disabled={true}
        onClick={() => onChangePage(PageType.MISSION)}
      /> */}
      {userManualAccess && (
        <PageSelector
          title='USER MANUALS'
          description='Get help here.'
          Icon={UserManualLogo}
          onClick={() => onChangePage(PageType.USER_MANUAL)}
        />
      )}
    </div>
  )
}
