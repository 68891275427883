import React, { Component, createRef, useRef } from 'react'
import * as UTM from 'utm'
import * as THREE from 'three'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls'
import {
    CSS2DObject,
    CSS2DRenderer,
} from 'three/examples/jsm/renderers/CSS2DRenderer'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass'
import {
    LatLngCoord,
    UTMCoord,
    CameraMode,
    Dimension,
    MapMode,
    MouseState,
    ModelType,
    MouseClick,
    MouseType,
    mouseTypeInit,
    MarkerType,
    SonarInfo,
    PoseData,
    ColorMap,
    AngleRange,
    Quality,
    ViewOrientation,
} from '../../types'
import { moveCameraBy, moveCameraToObject, resetCameraToNorth } from './utils/CameraUtils'
import {
    degToRad,
    drawSonarMask,
    getPixelRes,
    sceneToLatLng,
    utmToLatLng,
    utmToScene,
    latLngToScene,
} from './utils/CoordHelper/CoordUtils'
import {
    get3DRulerLabelContent,
    getBoxModel,
    getCircle,
    getCollada,
    getColor,
    getComplimentaryColor,
    getDepthColorMap,
    getDepthFromPixelDepth,
    getDepthMinMax,
    getDepthScaleLabels,
    getDotMarker,
    getHUDCamera,
    getHUDScene,
    getIkanMesh,
    getIkanPath,
    getIkanLabel,
    getLabelRenderer,
    getPositioningOfLine,
    getMosaic,
    getOrbitControls,
    getOrthographicCamera,
    getOutlinePass,
    getPCD,
    getPerspectiveCamera,
    getRulerLabel,
    getRulerLine,
    getScaleLabel,
    getScene,
    getSonar,
    getSphereMesh,
    getFullIkanPath,
    getXYZ,
    getAngleContent,
    getPlanarLineBetweenTwoPoints,
    getQuadrantBetweenTwoPoints,
    getUpdatedMaterialSizes,
} from './utils/ThreeUtils'
import { getBoatLabel, getPECLabel } from './utils/ThreeJS'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { SonarImageInterface } from '../utils/sonar'
import { getMapTiles, updateMapMesh } from './utils/MapTilesUtils'
import { Points, Vector3, TextureLoader } from 'three'
import MapMenu, { MapMenuInterface } from './MapMenu'
import { XYZLoader } from '../pointcloudpage/CustomXYZLoader'
import { labelRound } from './utils/ThreeUtils'
import { roundValue } from '../utils'
import { getDXF } from './utils/ThreeJS/Loaders/DXFLoader'
import { getBlob } from '../../backend'
import { getKML, getMosaicFromFile } from '../utils/ThreeJS'

const MAX_AUV_PATH = 2000

type MapProps = {
    instance: IPublicClientApplication
    account: AccountInfo | null
    storageAccount?: string
    location: LatLngCoord | null
    sonarInfo?: SonarInfo
    sonarRef?: React.RefObject<SonarImageInterface>
    sonarRefCurrent?: SonarImageInterface
    sonarImage?: string
    sonarMask?: HTMLCanvasElement
    mapMode: MapMode
    mouseState: MouseState
    cameraMode: CameraMode
    dimension: Dimension
    mapHeading: number
    viewOrientation?: ViewOrientation
    showTrail: boolean
    showSonar: boolean
    showMap: boolean
    showModels: boolean
    clearTrail: boolean
    zoom: number
    colorMap?: ColorMap
    markers?: MarkerType[] | null
    selectedMarkersIdx?: number[] | null
    angleRange?: AngleRange
    showAngles?: boolean
    models: ModelType[] | null
    terrain?: ModelType[]
    asset: string
    fullscreen?: boolean
    modelsOffset?: number[]
    materialSize?: number
    markerToFocus?: number
    modelToFocus?: number
    setIsTooFar?: (status: boolean) => void
    selectMarker?: (idxs: number | null) => void
    setClearTrail: () => void
    resetZoom: () => void
    setLocation: (coord: LatLngCoord) => void
    setMapHeading: (value: number) => void
    setShowAngles?: (value: boolean) => void
    modelOrigin?: UTMCoord | null
    modelFile?: File | null
    kmlFile?: File | null
    mosaicFiles?: File[]
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
    loading?: boolean
    resetObjects?: boolean
    setDialogText?: (value: string) => void
    setShowDialog?: (value: boolean) => void
    modelFileMap?: Record<string, any>
    modelQuality?: Quality
    setPercent: (value: number | ((oldValue: number) => number)) => void
    asBuilt?: number
    showingAxesHelperForMarker?: boolean
}

const TWOD_OBJECTS_DEPTH = 0.2

const GLB_MODEL_DEFAULT_SCALE = 100
const OBJ_MODEL_DEFAULT_SCALE = 0.5

// Axes helper & Assistive arrow configuration
const AXES_HELPER_LENGTH = 0.075
const AXES_HELPER_LABELS = ['x', 'y', 'z']
const AXES_HELPER_LABELS_DISPLACEMENT = [
    new Vector3(AXES_HELPER_LENGTH * 1.37, 0, 0),
    new Vector3(0, AXES_HELPER_LENGTH * 1.37, 0),
    new Vector3(0, 0, AXES_HELPER_LENGTH * 1.37),
]
const ASSISTIVE_ARROW_COLOR = 0x61cb0c
const ASSISTIVE_ARROW_LENGTH = AXES_HELPER_LENGTH * 1.2
const ASSISTIVE_ARROW_HEAD_SIZE = 0.2 * ASSISTIVE_ARROW_LENGTH
const ASSISTIVE_ARROW_LABEL_DISPLACEMENT = new Vector3(
    0,
    ASSISTIVE_ARROW_LENGTH * 1.37,
    0
)

class Map extends Component<MapProps, unknown> {
    private mapRef = createRef<HTMLDivElement>().current!
    private mapMenuRef = createRef<MapMenuInterface>().current!
    private mapMenu!: CSS2DObject

    private renderer!: THREE.WebGLRenderer
    private labelRenderer!: CSS2DRenderer
    private pCamera!: THREE.PerspectiveCamera
    private oCamera!: THREE.OrthographicCamera
    private scene!: THREE.Scene
    private oControls!: MapControls
    private dimension!: Dimension
    private viewOrientation!: ViewOrientation | undefined

    // AxesHelper & ArrowHelper
    private isGLBModel: boolean =
        this.props.models !== null &&
        this.props.models.some((model) => ['obj', 'glb'].includes(model.id))
    private showingAxesHelper =
        (this.props.models !== null &&
            this.props.models.some((model) =>
                ['xyz', 'pcd'].includes(model.id)
            )) ||
        this.isGLBModel
    private axesHelper = new THREE.AxesHelper(AXES_HELPER_LENGTH)
    private axesHelperLabels: CSS2DObject[] = []
    private axesHelperPosFactor = this.isGLBModel ? -0.69 : -0.715
    // These numbers are selected to ensure everyth can be seen
    private axesHelperRelativePos!: THREE.Vector3
    private assistiveArrow = new THREE.ArrowHelper(
        new Vector3(0, 1, 0),
        new Vector3(0, 0, 0),
        ASSISTIVE_ARROW_LENGTH,
        ASSISTIVE_ARROW_COLOR,
        ASSISTIVE_ARROW_HEAD_SIZE,
        ASSISTIVE_ARROW_HEAD_SIZE
    )
    private assistiveArrowLabel!: CSS2DObject

    // Static HUD
    private hudRef = createRef<HTMLDivElement>().current!
    private hudCamera!: THREE.OrthographicCamera
    private hudScene!: THREE.Scene
    private hudRenderer!: THREE.WebGLRenderer
    private hudObjects = new THREE.Object3D()
    private hudLabelRenderer!: CSS2DRenderer
    private depthObject = { min: Number.MAX_VALUE, max: Number.MIN_VALUE }

    // Mouse
    private mouse: MouseType = mouseTypeInit
    private raycaster = new THREE.Raycaster()
    private intersectPt = new Vector3()

    // Map
    private mapTiles = new THREE.Object3D()
    private mapObjects = new THREE.Object3D()
    private terrainObjects = new THREE.Object3D()
    private mapTilesOrigin: LatLngCoord | null = null
    private oControlsDrag = false

    // Markers
    private markers = new THREE.Object3D()
    private markersPath: THREE.Line | undefined
    private markerToFocus!: number

    // Models
    private modelToFocus!: number
    private defaultModels: THREE.Object3D<THREE.Event>[] = []
    private modelObjects = new THREE.Object3D()
    private modelLabels: CSS2DObject[] = []

    // Mosaic Object
    private mosaicObjects = new THREE.Object3D()

    // AUV Object
    private auv = new THREE.Object3D()
    private auvPath!: THREE.Line //ongoing
    private auvPathTraversed2d!: THREE.Line
    private fullAuvPath2d!: THREE.Line
    private auvPathTraversed3d!: THREE.Line
    private fullAuvPath3d!: THREE.Line
    private auvPathPts: {
        threed: THREE.Vector3[]
        twod: THREE.Vector3[]
    } = { threed: [], twod: [] }
    private fullAuvPathPts: {
        threed: THREE.Vector3[]
        twod: THREE.Vector3[]
    } = { threed: [], twod: [] }
    private fullAuvPathPtsTraversed: {
        threed: THREE.Vector3[]
        twod: THREE.Vector3[]
    } = { threed: [], twod: [] }
    private fullAuvPathPtsLeft: {
        threed: THREE.Vector3[]
        twod: THREE.Vector3[]
    } = { threed: [], twod: [] }
    private auvPositionTracker: THREE.Vector3 = new Vector3(0, 0, 0)
    private currAuvPathIndex: number = 0

    // Boat Object
    private boat = new THREE.Object3D()
    private boatHoverLabel!: CSS2DObject
    private boatPathTraversed!: THREE.Line
    private fullBoatPath!: THREE.Line
    private boatpathPtsTraversed: THREE.Vector3[] = []
    private fullBoatPathPtsLeft: THREE.Vector3[] = []
    private fullBoatPathPts: THREE.Vector3[] = []
    private boatPositionTracker: THREE.Vector3 = new Vector3(0, 0, 0)
    private currBoatPathIndex: number = 0

    // Ruler Object
    private ruler!: THREE.Line
    private angleLine!: THREE.Line
    private angleQuadrant!: THREE.Mesh
    private rulerMarkers = new THREE.Object3D()
    private hoverRuler!: THREE.Line
    private hoverRulerMarkers = new THREE.Object3D()
    private rulerLabel!: CSS2DObject
    // private angleLabel!: CSS2DObject
    private depthHoverLabel!: CSS2DObject
    private rulerPts: THREE.Vector3[] = []
    private hoverRulerPts: THREE.Vector3[] = []

    // MultiRuler Objects
    private rulerList: THREE.Line[] = []
    // rulerPts used for multiRuler objects
    private distanceTravelled: number = 0

    // Angle Labels
    private allAngleLines: THREE.Line[] = []
    private allAngleQuadrants: THREE.Mesh[] = []
    private allAngleLabels: CSS2DObject[] = []

    // Sonar
    private sonar!: THREE.Object3D
    private sonarCanvas = document.createElement('canvas')
    private sonarTex?: THREE.Texture
    // For Composer
    private composer!: EffectComposer
    private outlinePass!: OutlinePass
    private renderPass2D!: RenderPass
    private renderPass3D!: RenderPass
    // private effectFXAA!: ShaderPass
    // For Distance Labels
    private distLabels: CSS2DObject[] = []
    private distLines: THREE.Line[] = []
    private distMarkers = new THREE.Object3D()

    // For Marker Labels
    private markerLabels: CSS2DObject[] = []
    private labelHover: boolean = false // Used to determine if mouse is over label

    // Temp Marker
    private tempMarker!: CSS2DObject

    // MCD attribute
    private fileMCDs: Record<string, number> = {}

    shouldComponentUpdate(nextProps: MapProps): boolean {
        // if (isChanged(this.props.pose, nextProps.pose)) {
        //   this.updateIkanPose(nextProps.pose, nextProps.altitude)
        // }
        // if (isChanged(this.props.status, nextProps.status)) {
        //   if (this.props.status.type < 3 && nextProps.status.type === 3) {
        //     this.toggleDistLabels(true, 0)
        //   }
        // }

        if (
            isChanged(this.props.markers, nextProps.markers) ||
            isChanged(
                this.props.selectedMarkersIdx,
                nextProps.selectedMarkersIdx
            )
        ) {
            if (this.isAnyObjectTooFar(nextProps.markers, this.props.models))
                return false
            this.initMarkers(nextProps.markers, nextProps.selectedMarkersIdx)
        } else if (isChanged(this.props.models, nextProps.models)) {
            if (this.isAnyObjectTooFar(nextProps.markers, nextProps.models))
                return false

            this.initModels(nextProps.models)
        } else if (isChanged(this.props.location, nextProps.location)) {
            if (!nextProps.location) return false
            if (
                !this.props.location ||
                isCoordFar(nextProps.location, this.props.location) ||
                nextProps.location.zoom !== this.props.location.zoom
            ) {
                this.initMapTiles(nextProps.location)
            } else {
                this.updateMapTiles(nextProps.location)
            }
        } else if (
            isChanged(this.props.clearTrail, nextProps.clearTrail) &&
            nextProps.clearTrail
        ) {
            this.clearTrail()
        } else if (isChanged(this.props.mouseState, nextProps.mouseState)) {
            this.updateMouseState(nextProps.mouseState)
        } else if (isChanged(this.props.mapMode, nextProps.mapMode)) {
            this.toggleMapMode(nextProps.mapMode)
        } else if (isChanged(this.props.dimension, nextProps.dimension)) {
            this.toggleDimension(nextProps.dimension)
            if (this.dimension === Dimension.THREED && this.fullAuvPath3d) {
                this.scene.remove(this.fullAuvPath2d)
                this.scene.remove(this.auvPathTraversed2d)
                this.scene.add(this.fullAuvPath3d)
                this.scene.add(this.auvPathTraversed3d)
            } else if (
                this.dimension === Dimension.TWOD &&
                this.fullAuvPath2d
            ) {
                this.scene.remove(this.fullAuvPath3d)
                this.scene.remove(this.auvPathTraversed3d)
                this.scene.add(this.fullAuvPath2d)
                this.scene.add(this.auvPathTraversed2d)
            }
        } else if (
            isChanged(this.props.viewOrientation, nextProps.viewOrientation)
        ) {
            this.toggleViewOrientation(nextProps.viewOrientation)
        } else if (
            nextProps.mapHeading == -1
        ) {
            resetCameraToNorth(this.getCamera(), this.oControls)
        } else if (isChanged(this.props.cameraMode, nextProps.cameraMode)) {
            this.toggleCameraMode(nextProps.cameraMode)
        } else if (isChanged(this.props.showTrail, nextProps.showTrail)) {
            this.toggleShowTrail(nextProps.showTrail)
        } else if (isChanged(this.props.showSonar, nextProps.showSonar)) {
            this.toggleShowSonar(nextProps.showSonar)
        } else if (isChanged(this.props.showMap, nextProps.showMap)) {
            this.toggleShowMap(nextProps.showMap)
        } else if (isChanged(this.props.showModels, nextProps.showModels)) {
            this.toggleShowModels(nextProps.showModels)
        } else if (isChanged(this.props.showAngles, nextProps.showAngles)) {
            this.toggleShowAngles(nextProps.showAngles || false)
        } else if (isChanged(this.props.zoom, nextProps.zoom)) {
            this.toggleZoom(nextProps.zoom)
        } else if (isChanged(this.props.modelQuality, nextProps.modelQuality)) {
            this.toggleQuality(nextProps.modelQuality)
        } else if (isChanged(this.props.colorMap, nextProps.colorMap)) {
            this.toggleColorMap(nextProps.colorMap)
        } else if (isChanged(this.props.modelsOffset, nextProps.modelsOffset)) {
            this.offsetModel(nextProps.modelsOffset, this.props.modelsOffset)
            nextProps.colorMap
                ? this.renderDepthScale(
                      nextProps.colorMap,
                      nextProps.modelsOffset
                  )
                : {}
        } else if (this.props.fullscreen !== nextProps.fullscreen) {
            this.calculateMapHeading()
            this.onWindowResize()
        } else if (
            this.props.markerToFocus !== nextProps.markerToFocus ||
            this.props.sonarImage != nextProps.sonarImage ||
            this.props.sonarInfo != nextProps.sonarInfo ||
            this.props.sonarRefCurrent != nextProps.sonarRefCurrent
        ) {
            if (this.props.markerToFocus !== nextProps.markerToFocus) {
                this.changeObjectColor(
                    this.props.markerToFocus,
                    nextProps.markerToFocus
                )
            }
            if (nextProps.markerToFocus !== undefined) {
                this.markerToFocus = nextProps.markerToFocus
                moveCameraToObject(
                    this.mapObjects.children[this.markerToFocus],
                    this.getCamera(),
                    this.oControls,
                    undefined,
                    true,
                    false
                )
            }
            this.updateSelectedMarkerSonar(
                nextProps.markerToFocus != undefined
                    ? this.mapObjects.children[nextProps.markerToFocus]
                    : undefined,
                nextProps.sonarInfo,
                nextProps.sonarImage,
                this.props.markerToFocus != undefined
                    ? this.mapObjects.children[this.props.markerToFocus]
                    : undefined
            )
        } else if (
            this.props.modelToFocus !== nextProps.modelToFocus &&
            nextProps.modelToFocus !== undefined
        ) {
            this.modelToFocus = nextProps.modelToFocus
            moveCameraToObject(
                this.modelObjects.children[this.modelToFocus],
                this.getCamera(),
                this.oControls,
                this.viewOrientation,
                true,
                true
            )
        } else if (this.props.modelOrigin != nextProps.modelOrigin) {
            if (nextProps.modelFile && nextProps.modelOrigin) {
                if (this.props.setLoading != undefined)
                    this.props.setLoading(true)
                this.processXYZModel(nextProps.modelFile, nextProps.modelOrigin)
                if (this.props.setLoading != undefined)
                    this.props.setLoading(false)
            }
            if (nextProps.resetObjects) {
                // this.mapObjects.clear()
                this.modelObjects.clear()
            }
        } else if (this.props.kmlFile != nextProps.kmlFile) {
            this.props.setLoading && this.props.setLoading(false)
            nextProps.kmlFile && this.processKMLFile(nextProps.kmlFile)
            this.props.setLoading && this.props.setLoading(false)
        } else if (this.props.mosaicFiles != nextProps.mosaicFiles) {
            this.props.setLoading && this.props.setLoading(false)
            nextProps.mosaicFiles && this.processMosaicFiles(nextProps.mosaicFiles)
            this.props.setLoading && this.props.setLoading(false)
        }  else if (this.props.resetObjects != nextProps.resetObjects) {
            // if (nextProps.resetObjects) this.mapObjects.clear()
            if (nextProps.resetObjects) this.modelObjects.clear()
        } else if (
            this.props.angleRange != nextProps.angleRange &&
            this.props.showAngles
        ) {
            this.initAllAngles(nextProps.angleRange)
        } else if (this.props.asBuilt != nextProps.asBuilt) {
            this.initMarkers(nextProps.markers, nextProps.selectedMarkersIdx)
        }
        if (
            // standalone as materialSize can change with other properties
            this.props.materialSize != nextProps.materialSize &&
            nextProps.materialSize != undefined
        ) {
            this.updateMaterialSize(nextProps.materialSize)
        }
        // else if (isChanged(this.props.origin, nextProps.origin)) {
        //   this.updateOrigin(nextProps.origin)
        // } else if (isChanged(this.props.sonarInfo, nextProps.sonarInfo)) {
        //   this.updateSonarInfo(nextProps.sonarInfo)
        //   if (nextProps.sonarMask) {
        //     this.updateSonarMask(nextProps.sonarMask)
        //   }
        // } else if (isChanged(this.props.sonarOrientation, nextProps.sonarOrientation)) {
        //   this.updateSonarOrientation(nextProps.sonarOrientation)
        // }
        if (
            this.props.showingAxesHelperForMarker !==
            nextProps.showingAxesHelperForMarker
        )
            this.showingAxesHelper =
                nextProps.showingAxesHelperForMarker !== undefined &&
                nextProps.showingAxesHelperForMarker
        return false
    }

    componentDidMount(): void {
        const { width, height } = this.getWindowSize()
        const { hudWidth, hudHeight } = this.getHUDSize()
        this.initMouse()
        this.dimension = this.props.dimension
        this.viewOrientation = this.isGLBModel
            ? this.props.viewOrientation
            : undefined
        this.initRaycasterThreshold()
        this.oCamera = getOrthographicCamera(width, height)
        this.pCamera = getPerspectiveCamera(width, height)
        this.hudCamera = getHUDCamera(hudWidth, hudHeight)
        this.scene = getScene()
        this.hudScene = getHUDScene()
        this.updateAxesHelperRelativePos()

        AXES_HELPER_LABELS.forEach((label) => {
            const axesLabelDiv = document.createElement('div')
            axesLabelDiv.className = 'text-sm'
            axesLabelDiv.textContent = label
            this.axesHelperLabels.push(new CSS2DObject(axesLabelDiv))
        })
        if (this.isGLBModel) {
            const assistiveArrowLabelDiv = document.createElement('div')
            assistiveArrowLabelDiv.className = 'text-sm'
            assistiveArrowLabelDiv.textContent = 'front'
            this.assistiveArrowLabel = new CSS2DObject(assistiveArrowLabelDiv)
        } else this.scene.add(this.mapTiles)

        this.scene.add(this.mapObjects)
        this.scene.add(this.modelObjects)
        this.scene.add(this.mosaicObjects)
        this.hudScene.add(this.hudObjects)
        this.initRenderers(width, height, hudWidth, hudHeight)
        this.initOrbitControls()
        this.initEffectComposer()
        this.initOutlinePass()
        if (this.props.sonarInfo) {
            this.initOverallSonar(this.props.sonarInfo, this.props.sonarImage)
        }
        this.initMapTiles(this.props.location)
        this.initDistLabels()
        this.initTempMarker()
        this.animate()
        this.mapMenu = new CSS2DObject(this.mapMenuRef.divRef.current!)
        this.markerToFocus = this.props.markerToFocus
            ? this.props.markerToFocus
            : 0
        window.addEventListener('resize', this.onWindowResize.bind(this))
        this.onWindowResize()
    }

    initRenderers(
        windowWidth: number,
        windowHeight: number,
        hudWidth: number,
        hudHeight: number
    ) {
        this.renderer = new THREE.WebGLRenderer({ antialias: true })
        this.renderer.setSize(windowWidth, windowHeight)
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.labelRenderer = getLabelRenderer(windowWidth, windowHeight)
        this.hudRenderer = new THREE.WebGLRenderer({ alpha: true })
        this.hudRenderer.setSize(hudWidth, hudHeight)
        this.hudRenderer.setClearColor(0x000000, 0)
        this.hudLabelRenderer = getLabelRenderer(hudWidth, hudHeight)
        this.mapRef.appendChild(this.renderer.domElement)
        this.mapRef.appendChild(this.labelRenderer.domElement)
        this.hudRef.appendChild(this.hudRenderer.domElement)
        this.hudRef.appendChild(this.hudLabelRenderer.domElement)
    }

    initMouse() {
        const mouseTypeInit = {
            moved: false,
            down: false,
            type: MouseClick.LEFT,
            state: MouseState.DEFAULT,
            startPos: new Vector3(),
            endPos: new Vector3(),
        }
        this.mouse = mouseTypeInit
    }

    initRaycasterThreshold(threshold?: number) {
        if (this.raycaster.params.Points)
            this.raycaster.params.Points.threshold = threshold ? threshold : 0.5
    }

    getWindowSize() {
        return {
            width: this.mapRef.clientWidth,
            height: this.mapRef.clientHeight,
        }
    }

    getHUDSize() {
        return {
            hudWidth: this.hudRef.clientWidth,
            hudHeight: this.hudRef.clientHeight,
        }
    }

    initOrbitControls() {
        let target = null
        if (this.oControls) {
            target = this.oControls.target
            this.oControls.dispose()
        }
        this.oControls = getOrbitControls(
            this.getCamera(),
            this.labelRenderer.domElement,
            this.dimension,
            !this.isGLBModel
        )
        if (target) {
            this.oControls.target = target
        }
        this.oControls.addEventListener('change', () => {
            if (!this.mouse.down || !this.mapTilesOrigin) return
            const coord = sceneToLatLng(
                this.oControls.target,
                this.mapTilesOrigin
            )
            this.props.setLocation(coord)
            this.calculateMapHeading()
        })
        if (this.props.mouseState === MouseState.SELECTION) {
            this.oControls.enablePan = false
        }
    }

    calculateMapHeading() {
        let worldAngle =
        (this.oControls.getAzimuthalAngle() / 3.1415926) * 180
        if (worldAngle < 0) {
        worldAngle = 360 + worldAngle
        }
        this.props.setMapHeading(worldAngle)
    }

    initEffectComposer() {
        this.composer = new EffectComposer(this.renderer)
        const pass = new SMAAPass(
            this.mapRef.clientWidth * this.renderer.getPixelRatio(),
            this.mapRef.clientHeight * this.renderer.getPixelRatio()
        )
        this.composer.addPass(pass)
        // this.effectFXAA.uniforms[ 'resolution' ].value.set( 1 / this.container.clientWidth, 1 / this.container.clientHeight );
        // this.composer.addPass(this.effectFXAA);
        this.renderPass2D = new RenderPass(this.scene, this.oCamera)
        this.renderPass3D = new RenderPass(this.scene, this.pCamera)
    }

    initOutlinePass() {
        if (this.outlinePass) {
            this.composer.removePass(this.outlinePass)
            this.outlinePass.dispose()
        }
        if (this.dimension === Dimension.TWOD) {
            this.composer.removePass(this.renderPass3D)
            this.composer.addPass(this.renderPass2D)
        } else {
            this.composer.removePass(this.renderPass2D)
            this.composer.addPass(this.renderPass3D)
        }
        this.outlinePass = getOutlinePass(
            this.mapRef.clientWidth,
            this.mapRef.clientHeight,
            this.scene,
            this.getCamera()
        )
        this.composer.addPass(this.outlinePass)
    }

    // XYZ MODEL FUNCTIONS ========================================================== // not sure if this is still relevant
    async processXYZModel(file: File, coord: UTMCoord) {
        const { center, object, colors, mcd } = await XYZLoader(file)
        if (!this.mapTilesOrigin) return
        const { x, y } = utmToScene(
            {
                northing: coord.northing,
                easting: coord.easting,
                zone_letter: coord.zone_letter,
                zone_number: coord.zone_number,
            },
            this.mapTilesOrigin
        )

        if (isCoordFar(this.mapTilesOrigin, utmToLatLng(coord))) {
            alert(
                'XYZ model is too far from origin. Please select another XYZ file.'
            )
        } else {
            object.position.set(x, y, -(mcd * getPixelRes()))
            object.scale.setScalar(getPixelRes())
            // this.mapObjects.clear() // clears previous XYZ models
            // this.mapObjects.add(object)
            this.modelObjects.clear() // clears previous XYZ models
            this.modelObjects.add(object)
        }
    }

    // KML File
    async processKMLFile(file: File) {
        if (!this.mapTilesOrigin) return
        const obj = await getKML(file, this.mapTilesOrigin)
        if (obj) this.modelObjects.add(obj)
    }

    // Mosaic File
    async processMosaicFiles(files: File[]) {
        if (!this.mapTilesOrigin) return
        this.mosaicObjects.clear()
        for (let i = 0; i < files.length; i++) {
            const obj = await getMosaicFromFile(files[i], this.mapTilesOrigin)
            if (obj) this.mosaicObjects.add(obj)
        }
    }

    // TOGGLE FUNCTIONS ==========================================================
    toggleDimension(dimension: Dimension) {
        this.dimension = dimension

        // Map view changes
        this.initOrbitControls()
        this.initEffectComposer()
        this.initOutlinePass()
        // this.syncCamera()    // Not syncing to keep pCam & oCam independent from each other
        this.initMarkers(this.props.markers, this.props.selectedMarkersIdx)
        this.initModels(this.props.models)
        this.toggleShowAngles(false)
        this.removeRuler()

        // Recordings view changes
        this.displayAUVTraversed()
        if (
            this.fullAuvPathPtsTraversed.twod.length &&
            this.fullAuvPathPtsTraversed.threed.length
        ) {
            const prevAuvPosition =
                dimension === Dimension.TWOD
                    ? this.fullAuvPathPtsTraversed.twod.slice(-1)[0]
                    : this.fullAuvPathPtsTraversed.threed.slice(-1)[0]
            this.auv.position.set(
                prevAuvPosition.x,
                prevAuvPosition.y,
                prevAuvPosition.z
            )
        }
        // moveCameraToObject(this.ikan, this.getCamera(), this.oControls)
    }

    toggleViewOrientation(viewOrientation: ViewOrientation | undefined) {
        this.viewOrientation = viewOrientation
        moveCameraToObject(
            this.modelObjects.children[0],
            this.getCamera(),
            this.oControls,
            this.viewOrientation
        )
    }

    toggleCameraMode(mode: CameraMode) {
        if (mode === CameraMode.FOLLOW) {
            moveCameraToObject(this.auv, this.getCamera(), this.oControls)
        }
    }

    async toggleMapMode(mode: MapMode) {
        // if (mode === MapMode.MISSION) {
        //   this.toggleDistLabels(false, 0) // Hide mission distance labels
        // } else {
        //   if (this.props.status.type === 3) {
        //     this.toggleDistLabels(true, 0)
        //   }
        // }
        setTimeout(() => {
            this.onWindowResize()
        }, 0.5)
    }

    toggleShowTrail(showTrail: boolean) {
        if (!this.auvPath) return
        if (showTrail) {
            this.scene.add(this.auvPath)
        } else {
            this.scene.remove(this.auvPath)
        }
    }

    toggleShowSonar(showSonar: boolean) {
        if (showSonar) {
            this.auv.add(this.sonar)
        } else {
            this.auv.remove(this.sonar)
        }
    }

    toggleShowMap(showMap: boolean) {
        if (this.isGLBModel) return
        if (showMap) {
            this.scene.add(this.mapTiles)
        } else {
            this.scene.remove(this.mapTiles)
        }
    }

    toggleShowModels(showModels: boolean) {
        if (showModels) {
            this.showTerrain()
            // this.initModels(this.props.models)
            // this.scene.add(this.mapModelObjects)
        } else {
            this.hideTerrain()
            // this.removeHUD()
            // this.scene.remove(this.mapModelObjects)
        }
    }

    toggleShowAngles(showAngles: boolean) {
        if (showAngles != this.props.showAngles && this.props.setShowAngles) {
            this.props.setShowAngles(showAngles)
        }
        if (showAngles) {
            this.initAllAngles(this.props.angleRange)
        } else {
            this.removeAllAngles()
        }
    }

    toggleZoom(value: number) {
        if (value === 1) {
            // this.oControls.dIn(0.5)
            this.oControls.update()
        } else if (value === 2) {
            // this.oControls.dOut(0.5)
            this.oControls.update()
        }
        this.props.resetZoom()
    }

    toggleQuality(modelQuality: Quality | undefined) {
        this.modelObjects.clear()
        this.initModels(this.props.models, modelQuality)
    }

    toggleColorMap(colorMap: ColorMap | undefined) {
        if (!colorMap) {
            this.resetToDefaultModels()
            this.removeHUD()
        } else {
            this.renderDepthScale(colorMap)
        }
    }

    // BOAT
    async initBoatPose() {
        this.boatPathTraversed = getIkanPath()
        this.fullBoatPath = getFullIkanPath()
        if (this.fullBoatPath) {
            this.fullBoatPath.geometry =
                new THREE.BufferGeometry().setFromPoints(this.fullBoatPathPts)
        }
    }

    async updateBoatPosition(
        longitude: number,
        latitude: number,
        loading: boolean
    ) {
        if (this.boat.children.length < 1 && !loading) {
            this.boat.add(getDotMarker(1, 0xffffff))
            this.scene.add(this.boat)
            await this.initBoatPose()
            this.scene.add(this.fullBoatPath)
        }

        if (this.mapTilesOrigin) {
            const latLngCoordinate: LatLngCoord = {
                longitude: longitude,
                latitude: latitude,
                zoom: 20,
            }
            const scene = latLngToScene(latLngCoordinate, this.mapTilesOrigin)

            loading
                ? this.boatPositionTracker.set(scene.x, scene.y, 0)
                : this.boat.position.set(scene.x, scene.y, 0)

            loading ? this.updateFullBoatPath() : this.displayBoatTraversed()
        }
    }

    updateFullBoatPath() {
        const pos = this.boatPositionTracker.clone()
        if (this.fullBoatPathPts.length < 2) {
            this.fullBoatPathPts.push(pos)
            return
        }

        const dist = this.fullBoatPathPts.slice(-1)[0].distanceTo(pos)

        if (dist > 0.5) {
            this.fullBoatPathPts.push(pos.clone().setZ(0.1))
        }
        if (this.fullBoatPathPts.length > MAX_AUV_PATH) {
            this.fullBoatPathPts.shift()
        }
    }

    displayBoatTraversed() {
        if (!(this.boatPathTraversed && this.fullBoatPath)) return // initBoatPose have not run, being called from rosbag
        if (
            this.fullBoatPathPtsLeft.length === 0 &&
            this.fullBoatPathPts.length
        ) {
            // reposition boat to start point (i.e. first fullBoatPathPt)
            const position = this.fullBoatPathPts[0]
            this.boat.position.set(position.x, position.y, position.z)
            this.currBoatPathIndex = 0
        }
        this.scene.remove(this.fullBoatPath)
        this.scene.remove(this.boatPathTraversed)
        this.boatpathPtsTraversed = []
        this.fullBoatPathPtsLeft = []

        // Find minimum distance
        const currMinIndex = findMinDistanceFromObject(
            this.fullBoatPathPts,
            this.boat.position
            // this.currBoatPathIndex
        )
        this.currBoatPathIndex = currMinIndex

        this.fullBoatPathPtsLeft = this.fullBoatPathPts.slice(currMinIndex)
        this.boatpathPtsTraversed = this.fullBoatPathPts.slice(0, currMinIndex)

        this.boatPathTraversed.geometry =
            new THREE.BufferGeometry().setFromPoints(this.boatpathPtsTraversed)
        this.fullBoatPath.geometry = new THREE.BufferGeometry().setFromPoints(
            this.fullBoatPathPtsLeft
        )
        this.scene.add(this.boatPathTraversed)
        this.scene.add(this.fullBoatPath)
    }

    // IKAN
    async initAuvPose() {
        this.auv.add(await getIkanMesh())
        this.auv.scale.setScalar(getPixelRes())
        this.scene.add(this.auv)

        this.auvPath = getIkanPath()
        this.fullAuvPath2d = getFullIkanPath()
        this.fullAuvPath3d = getFullIkanPath()
        this.auvPathTraversed2d = getIkanPath()
        this.auvPathTraversed3d = getIkanPath()
        this.scene.add(this.auvPath)
        //Load 2d full path first, see if can toggle between 2d full path and 3d full path at runtime
        if (this.fullAuvPath2d) {
            this.fullAuvPath2d.geometry =
                new THREE.BufferGeometry().setFromPoints(
                    this.fullAuvPathPts.twod
                )
        }
        if (this.fullAuvPath3d) {
            this.fullAuvPath3d.geometry =
                new THREE.BufferGeometry().setFromPoints(
                    this.fullAuvPathPts.threed
                )
        }
        this.calculateMapHeading()
    }

    async updateAuvPose(pose: PoseData, loading: boolean) {
        if (this.auv.children.length <= 1 && !loading) {
            await this.initAuvPose()
            this.scene.add(this.fullAuvPath2d)
            moveCameraBy(this.auv.position, this.getCamera(), this.oControls)
        }
        if (
            !pose ||
            !this.mapTilesOrigin ||
            pose.easting == 0 ||
            !this.props.location
        ) {
            if (pose && pose.easting !== 0 && pose.northing !== 0) {
                this.props.setLocation(
                    utmToLatLng({
                        easting: pose.easting + pose.y,
                        northing: pose.northing + pose.x,
                        zone_number: pose.zone_number,
                        zone_letter: pose.zone_letter,
                    })
                )
                this.initMapTiles(this.props.location)
            }
            return
        }
        const scene = utmToScene(
            {
                easting: pose.easting + pose.y,
                northing: pose.northing + pose.x,
                zone_letter: pose.zone_letter,
                zone_number: pose.zone_number,
            },
            this.mapTilesOrigin
        )
        if (Math.abs(scene.x) > 10000 || Math.abs(scene.y) > 10000) {
            this.props.setLocation(
                utmToLatLng({
                    easting: pose.easting + pose.y,
                    northing: pose.northing + pose.x,
                    zone_number: pose.zone_number,
                    zone_letter: pose.zone_letter,
                })
            )
            return
        }
        const depth = loading
            ? -pose.depth * getPixelRes()
            : this.dimension === Dimension.THREED
            ? -pose.depth * getPixelRes()
            : 0.1
        loading
            ? this.auvPositionTracker.set(scene.x, scene.y, depth)
            : this.auv.position.set(scene.x, scene.y, depth)
        this.auv.setRotationFromEuler(
            new THREE.Euler(0, 0, -degToRad(pose.heading + 90), 'XYZ')
        )
        loading ? this.updateFullAuvPath() : this.displayAUVTraversed()
        if (this.props.cameraMode === CameraMode.FOLLOW && !loading) {
            moveCameraBy(this.auv.position, this.getCamera(), this.oControls)
        }
    }

    updateFullAuvPath() {
        const pos = this.auvPositionTracker.clone()
        if (this.fullAuvPathPts.threed.length < 2) {
            this.fullAuvPathPts.threed.push(pos)
            return
        }
        const dist =
            this.fullAuvPathPts.threed[
                this.fullAuvPathPts.threed.length - 1
            ].distanceTo(pos)
        if (dist > 10) {
            // Clear path if vehicle jumps by 10m
            this.fullAuvPathPts.threed = []
            this.fullAuvPathPts.twod = []
        } else if (dist > 0.5) {
            //Original condition is dist > 0.2
            this.fullAuvPathPts.threed.push(pos)
            this.fullAuvPathPts.twod.push(pos.clone().setZ(0.1))
        }
        if (this.fullAuvPathPts.threed.length > MAX_AUV_PATH) {
            this.fullAuvPathPts.threed.shift()
            this.fullAuvPathPts.twod.shift()
        }
    }

    // updateAuvPath() {
    //   const pos = this.auv.position.clone()
    //   if (this.auvPathPts.threed.length < 2) {
    //     // this.auvPathPts.threed.push(this.fullAuvPathPts.threed[0])
    //     // this.auvPathPts.threed.push(this.fullAuvPathPts.threed[1])
    //     this.auvPathPts.threed.push(pos)
    //     return
    //   }
    //   const dist =
    //     this.auvPathPts.threed[this.auvPathPts.threed.length - 1].distanceTo(pos)
    //   if (dist > 10) {
    //     // Clear path if vehicle jumpes by 10m
    //     this.auvPathPts.threed = []
    //     this.auvPathPts.twod = []
    //   } else if (dist > 0.2) {
    //     this.auvPathPts.threed.push(
    //       pos.addVectors(pos, new Vector3(0, 0, 0.1))
    //     )
    //     this.auvPathPts.twod.push(pos.clone().setZ(0.2))
    //   }
    //   if (this.auvPathPts.threed.length > MAX_AUV_PATH) {
    //     this.auvPathPts.threed.shift()
    //     this.auvPathPts.twod.shift()
    //   }
    //   if (this.auvPath) {
    //     this.auvPath.geometry.dispose()
    //     this.auvPath.geometry = new THREE.BufferGeometry().setFromPoints(
    //       this.dimension === Dimension.TWOD
    //         ? this.auvPathPts.twod
    //         : this.auvPathPts.threed
    //     )
    //   }
    //   this.updateRuler()
    // }

    displayAUVTraversed() {
        if (
            !this.fullAuvPathPtsLeft ||
            !this.fullAuvPathPtsTraversed ||
            !this.auvPathTraversed2d ||
            !this.auvPathTraversed3d ||
            !this.fullAuvPath2d ||
            !this.fullAuvPath3d
        ) {
            return
        }
        if (
            this.fullAuvPathPtsLeft.threed.length === 0 &&
            this.fullAuvPathPts.threed.length
        ) {
            // reposition auv to start point (i.e. first fullAuvPathPt)
            const position = this.fullAuvPathPts.threed[0]
            this.auv.position.set(position.x, position.y, position.z)
            this.currAuvPathIndex = 0
        }
        this.scene.remove(this.fullAuvPath2d)
        this.scene.remove(this.fullAuvPath3d)
        this.scene.remove(this.auvPathTraversed2d)
        this.scene.remove(this.auvPathTraversed3d)
        this.fullAuvPathPtsTraversed.twod = []
        this.fullAuvPathPtsTraversed.threed = []
        this.fullAuvPathPtsLeft.twod = []
        this.fullAuvPathPtsLeft.threed = []

        const currMinIndex = findMinDistanceFromObject(
            this.props.dimension === Dimension.TWOD
                ? this.fullAuvPathPts.twod
                : this.fullAuvPathPts.threed,
            this.auv.position
            // this.currAuvPathIndex
        )
        this.currAuvPathIndex = currMinIndex

        this.fullAuvPathPtsLeft.twod =
            this.fullAuvPathPts.twod.slice(currMinIndex)
        this.fullAuvPathPtsLeft.threed =
            this.fullAuvPathPts.threed.slice(currMinIndex)
        this.fullAuvPathPtsTraversed.twod = this.fullAuvPathPts.twod.slice(
            0,
            currMinIndex
        )
        this.fullAuvPathPtsTraversed.threed = this.fullAuvPathPts.threed.slice(
            0,
            currMinIndex
        )

        this.auvPathTraversed2d.geometry =
            new THREE.BufferGeometry().setFromPoints(
                this.fullAuvPathPtsTraversed.twod
            )
        this.fullAuvPath2d.geometry = new THREE.BufferGeometry().setFromPoints(
            this.fullAuvPathPtsLeft.twod
        )
        this.auvPathTraversed3d.geometry =
            new THREE.BufferGeometry().setFromPoints(
                this.fullAuvPathPtsTraversed.threed
            )
        this.fullAuvPath3d.geometry = new THREE.BufferGeometry().setFromPoints(
            this.fullAuvPathPtsLeft.threed
        )
        if (this.dimension === Dimension.THREED) {
            this.scene.add(this.auvPathTraversed3d)
            this.scene.add(this.fullAuvPath3d)
        } else {
            this.scene.add(this.auvPathTraversed2d)
            this.scene.add(this.fullAuvPath2d)
        }
    }

    clearTrail() {
        this.auvPathPts.threed = []
        this.auvPathPts.twod = []
        this.props.setClearTrail()
    }

    // SONAR
    initOverallSonar(sonarInfo: SonarInfo, sonarImage?: string) {
        if (sonarImage) {
            this.props.sonarRef?.current?.setImgSrc(sonarImage)
            this.props.sonarRef?.current?.setSonarInfo(sonarInfo)
        }
        this.updateSonarMask(sonarInfo)
        this.updateSonarInfo(sonarInfo)
        this.updateSonarImage()
    }

    initSonar(sonarInfo: SonarInfo) {
        this.sonarTex = new THREE.Texture(this.sonarCanvas)
        // this.sonarTex.minFilter = THREE.LinearFilter
        this.sonar = getSonar(this.sonarCanvas, this.sonarTex, sonarInfo)
        this.sonar.setRotationFromEuler(
            new THREE.Euler(0, 0, degToRad(90), 'XYZ')
        )
        this.auv.add(this.sonar)
    }

    updateSonarInfo(sonarInfo: SonarInfo) {
        if (!this.sonarTex) {
            this.initSonar(sonarInfo)
        }
        this.sonarCanvas.width = sonarInfo.width
        this.sonarCanvas.height = sonarInfo.height
        const lineX = (sonarInfo.width / 2) * sonarInfo.range_resol
        const imageY = sonarInfo.height * sonarInfo.range_resol
        const geometry = new THREE.PlaneBufferGeometry(lineX * 2, imageY, 32)
        const child = this.sonar.children[0]
        if (child instanceof THREE.Mesh) {
            child.geometry = geometry
            child.position.set(0, imageY / 2 + 0.2, 0.08)
            child.geometry.verticesNeedUpdate = true
        }
        this.updateSonarMask(sonarInfo)
    }

    updateSonarMask(sonarInfo: SonarInfo) {
        if (!this.sonarTex) return
        drawSonarMask(sonarInfo, this.sonarCanvas)
        this.sonarTex.needsUpdate = true
    }

    updateSonarImage() {
        if (
            !this.props.showSonar ||
            !this.props.sonarRef ||
            !this.props.sonarRef.current
        )
            return

        const sonarCtx = this.sonarCanvas.getContext('2d')
        if (!sonarCtx || !this.sonarTex) return
        sonarCtx.globalCompositeOperation = 'source-atop'
        const imgRef = this.props.sonarRef.current.imgRef.current!
        if (imgRef.complete) {
            sonarCtx.drawImage(imgRef, 0, 0)
        } else {
            this.props.sonarRef.current.imgRef.current!.onload = function () {
                sonarCtx.drawImage(imgRef, 0, 0)
            }
        }
        this.sonarTex.needsUpdate = true
    }

    async updateSelectedMarkerSonar(
        newMarker?: THREE.Object3D,
        sonarInfo?: SonarInfo,
        sonarImage?: string,
        oldMarker?: THREE.Object3D
    ) {
        oldMarker?.remove(this.sonar)
        sonarInfo && (await this.initOverallSonar(sonarInfo, sonarImage))
        newMarker?.add(this.sonar)
    }

    // MAP TILES
    async initMapTiles(coord: LatLngCoord | null) {
        this.props.setPercent(0)
        if (this.isAnyObjectTooFar(this.props.markers, this.props.models))
            return
        if (!coord) {
            const newCoord = this.findCoord()
            if (newCoord) this.props.setLocation(newCoord) // reinitializes initMapTiles
            return
        }
        this.mapTilesOrigin = null
        this.mapTiles.clear()
        this.mapObjects.clear()
        this.modelObjects.clear()
        const { origin, map } = await getMapTiles(coord)
        this.mapTilesOrigin = origin
        this.mapTiles.copy(map)
        this.updateMapTiles(coord)
        this.removeRuler()
        await this.initMarkers(
            this.props.markers,
            this.props.selectedMarkersIdx
        )
        await this.initModels(this.props.models)
        await this.initTerrain(this.props.terrain)
        this.moveCameraToAnyObject()
        this.props.setPercent(100)
    }

    findCoord() {
        if (this.props.markers && this.props.markers.length > 0) {
            return utmToLatLng(this.props.markers[0].origin)
        }
        if (this.props.models && this.props.models.length > 0) {
            const model = this.props.models[0]
            if (model.northing && model.easting) {
                return utmToLatLng({
                    northing: model.northing,
                    easting: model.easting,
                    zone_letter: model.zone_letter,
                    zone_number: model.zone_number,
                })
            } else {
                // when model has no northing/easting (i.e. xyz model), read
                return utmToLatLng({
                    northing: 5932797.228193143,
                    easting: 564059.4034266365,
                    zone_letter: 'N',
                    zone_number: 48,
                })
            }
        }
        return null
    }

    moveCameraToAnyObject() {
        if (this.auv.children.length > 0) {
            moveCameraToObject(this.auv, this.getCamera(), this.oControls)
            return
        }
        if (this.mapObjects.children.length > 0) {
            moveCameraToObject(
                this.mapObjects.children[0],
                this.getCamera(),
                this.oControls
            )
            return
        }
        if (this.modelObjects.children.length > 0) {
            if (this.modelObjects.children[0].name == 'map') {
                moveCameraToObject(
                    this.modelObjects.children[0].children[0],
                    this.getCamera(),
                    this.oControls,
                    this.viewOrientation
                )
            } else {
                console.log('test move', this.modelObjects.children[0])
                moveCameraToObject(
                    this.modelObjects.children[0],
                    this.getCamera(),
                    this.oControls,
                    this.viewOrientation
                )
            }
            return
        }
    }

    isAnyObjectTooFar(
        markers?: MarkerType[] | null,
        models?: ModelType[] | null
    ) {
        let res = false
        let coord = {
            easting: 0,
            northing: 0,
        }

        if (markers) {
            for (let i = 0; i < markers.length; i++) {
                if (
                    isCoordFarUTM(coord, {
                        easting: markers[i].origin.easting,
                        northing: markers[i].origin.northing,
                    })
                ) {
                    console.log('marker coords are far.')
                    res = true
                }
            }
        }
        if (models) {
            for (let i = 0; i < models.length; i++) {
                const model = models[i]
                let utm
                if (model.latitude && model.longitude) {
                    utm = UTM.fromLatLon(model.latitude, model.longitude)
                } else if (model.easting && model.northing) {
                    utm = {
                        easting: model.easting,
                        northing: model.northing,
                    }
                } else {
                    break
                }
                if (isCoordFarUTM(coord, utm)) {
                    console.log('model coords are far')
                    res = true
                }
            }
        }
        if (res && this.props.setIsTooFar) {
            this.props.setPercent(100)
            this.props.setIsTooFar(true)
        }
        return res
    }

    isObjectTooFar(coord: UTMCoord) {
        if (!this.mapTilesOrigin) return null
        const scene = utmToScene(
            {
                easting: coord.easting,
                northing: coord.northing,
                zone_letter: coord.zone_letter,
                zone_number: coord.zone_number,
            },
            this.mapTilesOrigin
        )
        if (Math.abs(scene.x) > 5000 || Math.abs(scene.y) > 5000) {
            const location = UTM.toLatLon(
                coord.easting,
                coord.northing,
                coord.zone_number || 48,
                coord.zone_letter || 'N',
                undefined,
                false
            )
            this.props.setLocation({
                latitude: location.latitude,
                longitude: location.longitude,
                zoom: this.props.location ? this.props.location.zoom : 19,
            })
        }
        return scene
    }

    async updateMapTiles(coord: LatLngCoord | null) {
        if (!this.mapRef || !this.mapTilesOrigin || !coord) return
        sceneToLatLng(this.oControls.target, this.mapTilesOrigin) // To initialise meterstopixel
        const numOfTiles =
            this.oCamera.zoom <= 1 ? 3 : this.oCamera.zoom < 5 ? 2 : 1
        await updateMapMesh(coord, numOfTiles, this.mapTiles)
    }

    /* TERRAIN */
    async initTerrain(terrain?: ModelType[]) {
        if (!this.mapTilesOrigin || !terrain) return
        for (let i = 0; i < terrain.length; i++) {
            const model = await this.loadModel(terrain[i])
            if (model) this.terrainObjects.add(model)
        }
        this.showTerrain()
    }

    showTerrain() {
        this.scene.add(this.terrainObjects)
    }

    hideTerrain() {
        this.scene.remove(this.terrainObjects)
    }

    offsetModel(offset?: number[], prevOffset?: number[]) {
        if (
            !offset ||
            offset.length !== 3 ||
            !prevOffset ||
            prevOffset.length !== 3
        )
            return
        this.modelObjects.position.set(
            offset[0] * getPixelRes(),
            offset[1] * getPixelRes(),
            -(offset[2] * getPixelRes())
        )
        this.mapObjects.position.set(
            offset[0] * getPixelRes(),
            offset[1] * getPixelRes(),
            -(offset[2] * getPixelRes())
        )
        this.markerLabels
            .filter((obj) => obj.name === 'markerLabel')
            .forEach((obj) =>
                obj.position.add(
                    new THREE.Vector3(
                        (offset[0] - prevOffset[0]) * getPixelRes(),
                        (offset[1] - prevOffset[1]) * getPixelRes(),
                        (prevOffset[2] - offset[2]) * getPixelRes()
                    )
                )
            )
    }

    async resetToDefaultModels() {
        this.modelObjects.clear()
        this.defaultModels.map((model) => this.modelObjects.add(model))
        this.props.materialSize &&
            this.updateMaterialSize(this.props.materialSize)
    }

    async updateMaterialSize(materialSize: number) {
        if (materialSize === 0) {
            await this.resetToDefaultModels()
            return
        }
        const mapModelObjects = this.modelObjects.children
        const newModels = getUpdatedMaterialSizes(mapModelObjects, materialSize)
        this.modelObjects.clear()
        newModels.map((model) => this.modelObjects.add(model)) // can only add after clearing to prevent synchronous updates
    }

    async loadModel(
        model: ModelType,
        modelQuality?: Quality,
        currProportion?: number[]
    ) {
        if (!this.mapTilesOrigin) return
        if (model.id === 'ground') return // Used to have ground. Not anymore.
        // if (model.id === 'box') {
        //     return await getBoxModel(model, this.mapTilesOrigin)
        // }
        if (model.file) {
            // For models that require external files
            const blob = this.props.modelFileMap
                ? this.props.modelFileMap[model.file]
                : null
            if (!blob) return
            if (model.id === 'collada') {
                return await getCollada(blob, model, this.mapTilesOrigin)
            } else if (model.id === 'pcd') {
                if (!model.easting || !model.northing) return
                return await getPCD(blob, model, this.mapTilesOrigin)
            } else if (model.id === 'mosaic') {
                return await getMosaic(blob, model, this.mapTilesOrigin)
            } else if (model.id === 'dxf') {
                return await getDXF(blob, model, this.mapTilesOrigin)
            } else if (model.id === 'map') {
                // TODO: RECURSE THIS
                if (!blob.model) return
                const object = new THREE.Object3D()
                object.name = 'map'
                for (let i = 0; i < blob.model.length; i++) {
                    if (blob.model[i].id === 'box') {
                        const { mesh, label } = await getBoxModel(
                            blob.model[i],
                            this.mapTilesOrigin
                        )
                        if (label) this.modelLabels.push(label)
                        if (mesh) object.add(mesh)
                    }
                }
                object.addEventListener('removed', (e) => {
                    e.target.clear()
                })
                return object
            } else if (model.id === 'xyz') {
                if (!currProportion) return
                const { mcd, object } = await getXYZ(
                    blob,
                    this.mapObjects,
                    this.mapTilesOrigin,
                    model.offsets ? model.offsets : { x: 0, y: 0, mcd: 0 },
                    model.materialSize,
                    modelQuality,
                    this.props.setPercent,
                    currProportion
                )
                this.fileMCDs[object.uuid] = mcd

                const totalPercentage =
                    100 * (1 / currProportion[1]) +
                    (currProportion[0] / currProportion[1]) * 100

                this.props.setPercent && this.props.setPercent(totalPercentage)
                return object
            } else if (model.id === 'glb') {
                const modelContainer = new THREE.Object3D()
                const scale = model.scale || GLB_MODEL_DEFAULT_SCALE
                modelContainer.scale.setScalar(scale)

                const pmremGenerator = new THREE.PMREMGenerator(this.renderer)
                const loader = new GLTFLoader()
                const url = URL.createObjectURL(blob)
                loader.load(
                    url,
                    (gltf) => {
                        if (!gltf.scene)
                            throw Error(
                                'The model from the .glb file contains no scene'
                            )
                        gltf.scene.rotation.x = Math.PI / 2
                        modelContainer.add(gltf.scene)
                        this.scene.environment = pmremGenerator.fromScene(
                            new RoomEnvironment()
                        ).texture
                    },
                    (xhr) =>
                        this.props.setPercent((xhr.loaded / xhr.total) * 100),
                    (error) => console.log('GLTFLoader error:', error)
                )
                return modelContainer
            } else if (model.id === 'obj') {
                if (
                    this.props.storageAccount === undefined ||
                    model.date === undefined
                ) {
                    console.error(
                        `${
                            model.date === undefined
                                ? 'model.date'
                                : 'storageAccount'
                        } is missing when rendering obj model`
                    )
                    return
                }

                let modelContainer = new THREE.Object3D()
                const scale = model.scale || OBJ_MODEL_DEFAULT_SCALE
                modelContainer.scale.setScalar(scale)

                // Check for texture file
                const textureFileName = model.file.replace('.obj', '.jpg')
                const textureBlob = await getBlob(
                    this.props.instance,
                    this.props.account,
                    this.props.storageAccount,
                    this.props.asset,
                    textureFileName,
                    model.date
                )
                const textureURL =
                    textureBlob === null ? '' : URL.createObjectURL(textureBlob)

                // Check for material file
                const mtlFileName = model.file.replace('.obj', '.mtl')
                const mtlBlob = await getBlob(
                    this.props.instance,
                    this.props.account,
                    this.props.storageAccount,
                    this.props.asset,
                    mtlFileName,
                    model.date
                )

                const mtlURL =
                    mtlBlob === null ? '' : URL.createObjectURL(mtlBlob)

                const numOfPctPortions =
                    [mtlURL, textureURL].filter((url) => url.length).length + 1
                const pctPortion = 100 / numOfPctPortions
                const updatePctProgress = async (
                    xhr: ProgressEvent<EventTarget>
                ) =>
                    this.props.setPercent(
                        (currPct) =>
                            currPct + (xhr.loaded / xhr.total) * pctPortion
                    )

                const showErrIfAny =
                    (loaderType: string) => (error: ErrorEvent) =>
                        console.log(`${loaderType} error:`, error)

                const addTextureIfAny = (obj: THREE.Group) => {
                    if (textureURL)
                        obj.traverse((child) => {
                            if (child instanceof THREE.Mesh)
                                child.material.map =
                                    new THREE.TextureLoader().load(
                                        textureURL,
                                        undefined,
                                        updatePctProgress,
                                        showErrIfAny('TextureLoader')
                                    )
                        })
                }

                const processOBJ = (obj: THREE.Group) => {
                    obj.traverse((child) => {
                        if (child instanceof THREE.Mesh)
                            child.material.side = THREE.DoubleSide
                    })
                    obj.rotation.x = Math.PI / 2
                    obj.rotation.y = Math.PI
                }

                const objURL = URL.createObjectURL(blob)

                if (mtlURL && textureURL)
                    new MTLLoader().load(
                        mtlURL,
                        (materials) => {
                            materials.preload()
                            const objLoader = new OBJLoader()
                            objLoader.setMaterials(materials)
                            objLoader.load(
                                objURL,
                                (obj) => {
                                    addTextureIfAny(obj)
                                    processOBJ(obj)
                                    modelContainer.add(obj)
                                },
                                updatePctProgress,
                                showErrIfAny('OBJLoader')
                            )
                        },
                        updatePctProgress,
                        showErrIfAny('MTLLoader')
                    )
                else
                    new OBJLoader().load(
                        objURL,
                        (obj) => {
                            // addTextureIfAny(obj)
                            processOBJ(obj)
                            modelContainer.add(obj)
                        },
                        updatePctProgress,
                        showErrIfAny('OBJLoader')
                    )

                return modelContainer
            }
        }
        return
    }

    async initModels(models: ModelType[] | null, modelQuality?: Quality) {
        if (!this.mapTilesOrigin || !models) return
        this.modelObjects.clear()
        this.props.setPercent(0)
        for (let i = 0; i < models.length; i++) {
            const model = models[i]
            if (model.easting && model.northing && this.mapTilesOrigin) {
                // only if model has easting and northing (all models except xyz models)
                const scene = this.isObjectTooFar({
                    northing: model.northing,
                    easting: model.easting,
                    zone_number: model.zone_number,
                    zone_letter: model.zone_letter,
                })
                console.log('current scene is', scene)
                // if (this.props.setIsTooFar && !scene) {
                //   this.setIsLoading(false)
                //   this.props.setIsTooFar(true)
                // }
                if (!scene) return
            }

            const modelObject = await this.loadModel(model, modelQuality, [
                i,
                models.length,
            ])
            console.log(modelObject)
            if (modelObject) {
                this.defaultModels.push(modelObject)
                this.modelObjects.add(modelObject)
            }
            this.props.setPercent((i / models.length) * 100)
        }

        // other initializations
        this.props.materialSize &&
            this.updateMaterialSize(this.props.materialSize)
        this.props.colorMap && this.renderDepthScale(this.props.colorMap)
        this.props.setPercent(100)
    }

    async renderDepthScale(colorMap: ColorMap, modelsOffset?: number[]) {
        if (this.modelObjects.children.length == 0) return
        const { hudWidth, hudHeight } = this.getHUDSize()
        if (hudHeight <= 250) {
            this.removeHUD()
            return
        }
        this.depthObject = {
            min: Number.POSITIVE_INFINITY,
            max: Number.NEGATIVE_INFINITY,
        }
        for (let i = 0; i < this.modelObjects.children.length; i++) {
            const child = this.modelObjects.children[i]
            if (child instanceof THREE.Points) {
                this.depthObject = getDepthMinMax(
                    child,
                    this.depthObject.min,
                    this.depthObject.max,
                    this.fileMCDs[child.uuid] +
                        (modelsOffset ? modelsOffset[2] : 0)
                )
            }
        }

        if (this.depthObject.min == Number.MAX_VALUE) return // No pcd objects
        const updatedModels = []
        for (let i = 0; i < this.modelObjects.children.length; i++) {
            const child = this.modelObjects.children[i]
            if (child instanceof THREE.Points) {
                const { updatedPCD, depthSprite } =
                    child.geometry &&
                    child.geometry.attributes.color &&
                    getDepthColorMap(
                        child,
                        this.depthObject.min,
                        this.depthObject.max,
                        hudHeight,
                        hudWidth,
                        ColorMap[colorMap].toLocaleLowerCase(),
                        this.fileMCDs[child.uuid] +
                            (modelsOffset ? modelsOffset[2] : 0)
                    )
                this.removeHUD()
                updatedModels.push(updatedPCD)
                if (depthSprite) this.hudObjects.add(depthSprite)
            }
        }
        for (let mapModelObject of this.modelObjects.children) {
            if (mapModelObject instanceof THREE.Points) {
                this.modelObjects.remove(mapModelObject)
            }
        }
        // this.mapModelObjects.clear()
        updatedModels.forEach((model) => this.modelObjects.add(model))

        if (this.hudObjects.children.length > 0) {
            getDepthScaleLabels(
                this.depthObject.min,
                this.depthObject.max,
                hudHeight,
                hudWidth,
                5
            ).forEach((x) => this.hudObjects.add(x))
        }
    }

    removeHUD() {
        this.hudObjects.clear()
    }

    // COLORS
    async changeObjectColor(oldMarkerIdx?: number, newMarkerIdx?: number) {
        if (newMarkerIdx != undefined) {
            const newMarker = this.mapObjects.children[newMarkerIdx].children[0]
            if (newMarker instanceof THREE.Mesh) {
                newMarker.material.color = new THREE.Color(1, 1, 1)
            }
        }

        if (oldMarkerIdx != undefined) {
            const oldMarker = this.mapObjects.children[oldMarkerIdx].children[0]
            if (oldMarker instanceof THREE.Mesh) {
                oldMarker.material.color = new THREE.Color(
                    0.011764705882352941,
                    0.8588235294117647,
                    0.9882352941176471
                )
            }
        }
    }

    // MARKERS
    async initMarkers(
        markers?: MarkerType[] | null,
        selectedMarkersIdx?: number[] | null
    ) {
        if (!markers) return
        this.props.setPercent(0)
        this.mapObjects.clear()
        this.scene.remove(...this.markerLabels)
        this.markerLabels = []
        for (let i = 0; i < markers.length; i++) {
            const marker = markers[i]
            if (!marker.origin || !this.mapTilesOrigin) break

            const offsets = marker.offsets
                ? marker.offsets
                : { x: 0, y: 0, mcd: 0 }
            let zone_number = marker.origin.zone_number || 48
            let zone_letter = marker.origin.zone_letter || 'N'
            if (marker.origin.zone) {
              zone_number = Number(marker.origin.zone.slice(0, -1))
              zone_letter = marker.origin.zone.slice(-1)
            }
            const scene = this.isObjectTooFar({
                easting: marker.origin.easting + marker.position.y + offsets.x,
                northing:
                    marker.origin.northing + marker.position.x + offsets.y,
                zone_number: zone_number,
                zone_letter: zone_letter,
            })
            if (!scene) return
            const depth =
                this.props.dimension === Dimension.TWOD ? 1 : -marker.position.z

            const markerObj = new THREE.Object3D()
            markerObj.add(await getIkanMesh())
            markerObj.position.set(
                scene.x,
                scene.y,
                (depth - offsets.mcd) * getPixelRes()
            )
            markerObj.setRotationFromEuler(
                new THREE.Euler(0, 0, -degToRad(marker.heading + 90), 'XYZ')
            )
            markerObj.name = String(i)
            markerObj.scale.setScalar(getPixelRes())
            if (
                this.markerLabels.length !== i + 1 &&
                this.markerLabels.length < markers.length
            ) {
                const markerLabel = marker.pec
                    ? getPECLabel(
                          roundValue(marker.position.z, 2),
                          roundValue(marker.altitude, 2),
                          marker.pec.thickness,
                          this.props.asBuilt || 0,
                          () => {
                              this.highlightLabel(i)
                              this.labelHover = true
                              this.props.selectMarker &&
                                  this.props.selectMarker(i)
                          },
                          (value) => {
                              console.log('event', value)
                              this.labelHover = value
                          }
                      )
                    : getIkanLabel(marker.name)
                this.props.dimension === Dimension.TWOD
                    ? markerLabel.position.set(
                          scene.x,
                          scene.y + 4,
                          (depth - offsets.mcd) * getPixelRes()
                      )
                    : markerLabel.position.set(
                          scene.x,
                          scene.y,
                          (depth - offsets.mcd) * getPixelRes()
                      )
                markerLabel.name = String(i)
                markerObj.add(markerLabel)
                this.markerLabels.push(markerLabel)
            }
            this.markerLabels[i].name = 'markerLabel'
            this.scene.add(this.markerLabels[i])
            this.mapObjects.add(markerObj)
            if (this.sonar) markerObj.add(this.sonar)
            this.props.setPercent((i / markers.length) * 100)
        }
        this.initMarkersPath()
        this.props.setPercent(100)
    }

    initMarkersPath() {
        if (!this.markersPath) {
            this.markersPath = getIkanPath()
            this.scene.add(this.markersPath)
        }
        const points = this.mapObjects.children.map((marker) => marker.position)
        this.markersPath.geometry.dispose()
        this.markersPath.geometry = new THREE.BufferGeometry().setFromPoints(
            points
        )
    }

    highlightLabel(idx: number) {
        for (let i = 0; i < this.markerLabels.length; ++i) {
            this.markerLabels[i].element.classList.remove('outline-border')
        }
        this.markerLabels[idx].element.classList.add('outline-border')
    }

    // TEMPORARY MARKER
    initTempMarker() {
        const div = document.createElement('div')
        div.className = 'task-marker-label rounded-md padding-sm cursor-pointer'
        this.tempMarker = new CSS2DObject(div)
        this.tempMarker.name = 'hidden'
    }

    toggleTempMarker(pos: THREE.Vector3) {
        if (this.tempMarker.name === 'shown') {
            this.removeTempMarker()
        } else {
            this.addTempMarker(pos)
        }
    }

    addTempMarker(pos: THREE.Vector3) {
        if (!this.mapTilesOrigin) return
        this.raycaster.setFromCamera(pos, this.getCamera())
        const intersects = this.raycaster.intersectObjects(
            this.mapTiles.children,
            true
        )
        if (intersects.length > 0) {
            this.tempMarker.name = 'shown'
            this.tempMarker.position.copy(intersects[0].point)
            this.scene.add(this.tempMarker)
        }
    }

    removeTempMarker() {
        if (this.tempMarker.name === 'hidden') return
        this.tempMarker.name = 'hidden'
        this.scene.remove(this.tempMarker)
    }

    // DISTANCE LABELS
    initDistLabels() {
        // 0: mission, 1: boat, 2: measurement
        for (let i = 0; i < 3; i++) {
            this.distLines.push(getRulerLine())
            this.distLabels.push(getRulerLabel())
        }
    }

    toggleDistLabels(show: boolean, idx: number) {
        if (show) {
            this.scene.add(this.distLines[idx])
            this.scene.add(this.distLabels[idx])
            if (idx === 2) this.scene.add(this.distMarkers)
        } else {
            this.scene.remove(this.distLines[idx])
            this.scene.remove(this.distLabels[idx])
            if (idx === 2) this.scene.remove(this.distMarkers)
        }
    }

    updateDistLabels(idx: number, pos: THREE.Vector3[]) {
        this.distLines[idx].geometry.dispose()
        this.distLines[idx].geometry = new THREE.BufferGeometry().setFromPoints(
            pos
        )
        const middle = getPositioningOfLine(pos[0], pos[1], 0.5)
        this.distLabels[idx].position.copy(middle)
        const dist = Math.round(pos[0].distanceTo(pos[1]))
        this.distLabels[idx].element.innerHTML = String(dist) + 'm'
    }

    addDistMarker() {
        if (this.isIntersect(this.mapTiles.children, true)) {
            if (this.distMarkers.children.length == 2) {
                this.distMarkers.children.shift()
            }
            const mesh = getSphereMesh(0.5)
            mesh.position.set(this.intersectPt.x, this.intersectPt.y, 1)
            this.distMarkers.add(mesh)
            if (this.distMarkers.children.length == 2) {
                this.updateDistLabels(
                    2,
                    this.distMarkers.children.map((child) => child.position)
                )
            }
        }
    }

    // CAMERA
    getCamera(): THREE.PerspectiveCamera | THREE.OrthographicCamera {
        if (this.dimension === Dimension.TWOD) return this.oCamera
        return this.pCamera
    }

    syncCamera() {
        if (this.dimension === Dimension.THREED) {
            this.pCamera.position.copy(this.oCamera.position)
            // this.pCamera.rotation.copy(this.oCamera.rotation)
        } else {
            this.oCamera.position.copy(this.pCamera.position)
            // this.oCamera.rotation.copy(this.pCamera.rotation)
        }
    }

    // RULER

    initHoverRuler() {
        if (!this.hoverRuler) {
            this.hoverRuler = getRulerLine(true)
        }
        if (!this.scene.children.includes(this.hoverRuler)) {
            this.scene.add(this.hoverRuler)
        }
        this.scene.add(this.hoverRulerMarkers)
        this.scene.add(this.rulerMarkers)
    }

    initRuler() {
        this.removeHoverRuler() // remove all hoverRuler before rendering ruler
        if (!this.ruler) {
            this.ruler = getRulerLine()
            this.angleLine = getRulerLine(true)
            this.rulerLabel = getRulerLabel()
            this.angleQuadrant = new THREE.Mesh()
        }
        if (!this.hoverRuler) {
            this.hoverRuler = getRulerLine(true)
        }
        if (this.rulerPts.length == 1) {
            if (!this.scene.children.includes(this.hoverRuler)) {
                this.scene.add(this.hoverRuler)
            }
            this.scene.add(this.hoverRulerMarkers)
            this.scene.add(this.rulerMarkers)
        } else if (
            this.rulerPts.length == 2 &&
            this.mouse.state === MouseState.RULER
        ) {
            this.scene.add(this.ruler)
            this.scene.add(this.rulerLabel)
        } else if (
            this.rulerPts.length >= 2 &&
            this.mouse.state === MouseState.MULTIRULER
        ) {
            const newRuler = getRulerLine()

            // add next ruler
            this.scene.add(newRuler)
            this.rulerList.push(newRuler)
        }
    }

    removeRuler() {
        this.rulerPts = []
        this.rulerMarkers.clear()
        this.scene.remove(this.ruler)
        this.scene.remove(this.angleLine)
        this.scene.remove(this.rulerLabel)
        this.scene.remove(this.angleQuadrant)
        this.scene.remove(this.rulerMarkers)

        // remove multilabel contents
        this.rulerList.map((ruler) => this.scene.remove(ruler))
        this.rulerList = []
        this.distanceTravelled = 0
        this.props.setDialogText && this.props.setDialogText('')
        this.props.setShowDialog && this.props.setShowDialog(false)

        this.removeHoverRuler()
    }

    rulerLeftClick() {
        if (!this.mapTilesOrigin) return
        this.initRaycasterThreshold()
        if (
            this.mouse.state === MouseState.RULER &&
            this.rulerPts.length === 2
        ) {
            this.removeRuler()
            return
        }

        // Check if user click on map
        let intersect = this.getRaycasterIntersections(this.props.dimension)
        if (intersect.length > 0) {
            let intersectPt
            let object
            if (
                intersect.length > 1 &&
                intersect[0].object.name.includes('tile')
            ) {
                intersectPt = intersect[1]
                object = intersect[1].object
            } else {
                intersectPt = intersect[0]
                object = intersect[0].object
            }
            if (intersectPt) {
                if (this.props.dimension === Dimension.TWOD)
                    intersectPt.point.setZ(TWOD_OBJECTS_DEPTH * 2) // to see points clearly on 2D
                if (object instanceof Points) {
                    const depth = intersectPt.point.z / getPixelRes()
                    const complimentaryColor = getComplimentaryColor(
                        getColor(depth - this.depthObject.min).getHexString()
                    )
                    const marker = getDotMarker(
                        0.1,
                        complimentaryColor ? complimentaryColor : 0x54e04f
                    )
                    marker.position.copy(intersectPt.point)
                    this.rulerMarkers.add(marker)
                    this.rulerPts.push(intersectPt.point)
                } else if (this.mapObjects.children.includes(object.parent!)) {
                    // AUV Intersections
                    const marker = getCircle(0.5, 0xff000, 0)
                    marker.position.copy(object.parent?.position!)
                    this.rulerMarkers.add(marker)
                    this.rulerPts.push(object.parent?.position!)
                } else {
                    const marker = getCircle(0.5, 0xff000, 0)
                    marker.position.copy(intersectPt.point)
                    this.rulerMarkers.add(marker)
                    this.rulerPts.push(intersectPt.point)
                }
                this.initRuler()
                this.updateRuler()
            }
        }
    }

    rulerRightClick() {
        if (this.rulerPts.length >= 2) {
            this.removeHoverRuler()
            this.removeRuler()
        }
    }

    updateHoverRuler() {
        if (
            (this.ruler && this.rulerPts.length === 1) ||
            (this.mouse.state === MouseState.MULTIRULER &&
                this.rulerPts.length >= 1)
        ) {
            this.hoverRuler.geometry.dispose()
            this.hoverRuler.geometry = new THREE.BufferGeometry().setFromPoints(
                this.hoverRulerPts
            )
            this.hoverRuler.computeLineDistances() // For dotted lines
            this.hoverRuler.position.setZ(
                is2DMarkers(this.hoverRulerPts) ? 0.5 : 0
            )
        }
    }

    updateRuler() {
        if (
            this.ruler &&
            this.rulerPts.length === 2 &&
            this.mouse.state === MouseState.RULER
        ) {
            this.ruler.geometry.dispose()
            this.ruler.geometry = new THREE.BufferGeometry().setFromPoints(
                this.rulerPts
            )
            this.ruler.position.setZ(is2DMarkers(this.rulerPts) ? 0.5 : 0)
            this.rulerLabel.position.copy(
                getPositioningOfLine(this.rulerPts[0], this.rulerPts[1], 0.5)
            )
            this.attachLabels(this.rulerPts[0], this.rulerPts[1], false)
        }
        if (
            this.rulerList.length &&
            this.rulerPts.length >= 2 &&
            this.mouse.state === MouseState.MULTIRULER
        ) {
            // to update multiRuler labels
            const rulerListIdx = this.rulerPts.length - 2

            if (this.rulerList.length - 1 >= rulerListIdx) {
                // initialise ruler end
                const currRuler = this.rulerList[rulerListIdx]
                const currRulerPts = this.rulerPts.slice(-2)
                currRuler.geometry.dispose()
                currRuler.geometry = new THREE.BufferGeometry().setFromPoints(
                    currRulerPts
                )
                currRuler.position.setZ(
                    is2DMarkers(this.rulerPts.slice(-2)) ? 0.5 : 0
                )

                // initialise end ruler label
                this.attachLabels(currRulerPts[0], currRulerPts[1], true)
            }
        }
    }

    initAngleLabel(
        pt1: THREE.Vector3,
        pt2: THREE.Vector3,
        angleRange: AngleRange | undefined
    ) {
        const newAngleLabel = getRulerLabel()
        const deeperPoint = pt1.z <= pt2.z ? pt1 : pt2
        const shallowerPoint = pt1.z > pt2.z ? pt1 : pt2
        const depth = Math.abs(shallowerPoint.z - deeperPoint.z) / getPixelRes()

        const newPt1 = new Vector3(pt1.x, pt1.y)
        const newPt2 = new Vector3(pt2.x, pt2.y)
        const dist =
            Math.round((newPt1.distanceTo(newPt2) / getPixelRes()) * 10) / 10

        const { angleDiv, angleValue } = getAngleContent(
            dist,
            depth,
            angleRange
        )
        newAngleLabel.element.innerHTML = ''
        angleDiv && newAngleLabel.element.appendChild(angleDiv)
        newAngleLabel.element.style.backgroundColor = `rgba(139,128,0,0.75)`

        return { newAngleLabel, angleValue }
    }

    initAllAngles(currAngleRange: AngleRange | undefined) {
        if (!this.props.selectedMarkersIdx || !this.props.markers) return
        // iterate through each marker and generate angle between first and second marker
        this.removeAllAngles() // remove all past angle labels

        for (let i = 1; i < this.props.selectedMarkersIdx.length; i++) {
            const prevMarker = this.props.markers[i - 1]
            const currMarker = this.props.markers[i]

            const prevScene = this.isObjectTooFar({
                easting: prevMarker.origin.easting + prevMarker.position.y,
                northing: prevMarker.origin.northing + prevMarker.position.x,
                zone_number: prevMarker.origin.zone_number,
                zone_letter: prevMarker.origin.zone_letter,
            })

            const prevDepth =
                this.props.dimension === Dimension.TWOD
                    ? 1
                    : -prevMarker.position.z

            const currScene = this.isObjectTooFar({
                easting: currMarker.origin.easting + currMarker.position.y,
                northing: currMarker.origin.northing + currMarker.position.x,
                zone_number: currMarker.origin.zone_number,
                zone_letter: currMarker.origin.zone_letter,
            })

            const currDepth =
                this.props.dimension === Dimension.TWOD
                    ? 1
                    : -currMarker.position.z

            const prevPt = prevScene
                ? new Vector3(
                      prevScene.x,
                      prevScene.y,
                      prevDepth * getPixelRes()
                  )
                : new Vector3()
            const currPt = currScene
                ? new Vector3(
                      currScene.x,
                      currScene.y,
                      currDepth * getPixelRes()
                  )
                : new Vector3()

            const { newAngleLabel, angleValue } = this.initAngleLabel(
                prevPt,
                currPt,
                currAngleRange
            )
            if (!angleValue) continue
            const addAngleLabelX = prevPt.x - currPt.x
            const addAngleLabelY = prevPt.y - currPt.y
            const addAngleLabelZ = prevPt.z - currPt.z

            currScene &&
                newAngleLabel.position.set(
                    currPt.x +
                        (addAngleLabelX < 12.5 ? 0.4 * addAngleLabelX : 5),
                    currPt.y +
                        (addAngleLabelY < 12.5 ? 0.4 * addAngleLabelY : 5),
                    currPt.z +
                        (addAngleLabelZ < 12.5 ? 0.05 * addAngleLabelZ : 3)
                )
            this.scene.add(newAngleLabel)
            this.allAngleLabels.push(newAngleLabel)

            // add planar lines
            const planarLine = getPlanarLineBetweenTwoPoints(prevPt, currPt)
            this.scene.add(planarLine)
            this.allAngleLines.push(planarLine)

            // add planar quadrant
            const circle = getQuadrantBetweenTwoPoints(
                prevPt,
                currPt,
                angleValue
            )
            this.allAngleQuadrants.push(circle)
            this.scene.add(circle)
        }
    }

    removeAllAngles() {
        this.allAngleLabels.map((angleLabel) => this.scene.remove(angleLabel)) // remove all past angle labels
        this.allAngleLabels = []
        this.allAngleLines.map((angleLine) => this.scene.remove(angleLine))
        this.allAngleLines = []
        this.allAngleQuadrants.map((angleQuadrants) =>
            this.scene.remove(angleQuadrants)
        )
        this.allAngleQuadrants = []
    }

    attachLabels(p1: THREE.Vector3, p2: THREE.Vector3, isMultiLabel?: boolean) {
        // clear all labels
        this.rulerLabel.clear()
        // this.angleLabel.clear()

        // initialise points and set dimension
        const pt1 = new Vector3()
        const pt2 = new Vector3()
        pt1.copy(p1)
        pt2.copy(p2)
        if (this.props.dimension === Dimension.TWOD) {
            pt1.setZ(0)
            pt2.setZ(0)
        }

        const deeperPoint = pt1.z <= pt2.z ? pt1 : pt2
        const shallowerPoint = pt1.z > pt2.z ? pt1 : pt2
        const depth = Math.abs(shallowerPoint.z - deeperPoint.z) / getPixelRes()
        pt1.setZ(0)
        pt2.setZ(0)
        const dist = pt1.distanceTo(pt2) / getPixelRes()

        // initialise rulerLabel
        if (isMultiLabel) {
            const hypotenuse = Math.sqrt(Math.pow(depth, 2) + Math.pow(dist, 2))
            this.distanceTravelled += hypotenuse
            this.props.setDialogText &&
                this.props.setDialogText(
                    `Total distance travelled: ${labelRound(
                        this.distanceTravelled
                    )}m`
                )
            this.props.setShowDialog && this.props.setShowDialog(true)
        } else {
            this.rulerLabel.element.innerHTML = ''

            const labelDiv = get3DRulerLabelContent(dist, depth)
            this.rulerLabel.element.appendChild(labelDiv)

            // initialise angleLabel, angleLine, angleQuadrant
            const { angleValue } = getAngleContent(dist, depth)
            if (!angleValue) {
                return
            }
            this.angleLine = getPlanarLineBetweenTwoPoints(p1, p2, '#176114')
            this.scene.add(this.angleLine) // attach planar line
            this.angleQuadrant = getQuadrantBetweenTwoPoints(
                p1,
                p2,
                angleValue,
                '#176114'
            )
            this.scene.add(this.angleQuadrant)
        }
    }

    // HOVER RULER
    removeHoverRuler() {
        this.scene.remove(this.hoverRuler)
        this.scene.remove(this.hoverRulerMarkers)
        this.hoverRulerPts = []
        this.hoverRuler = getRulerLine(true)
        this.hoverRulerMarkers.clear()
    }

    renderHoverRulerMarker() {
        const intersect = this.getRaycasterIntersections(this.props.dimension)
        this.hoverRulerPts = []
        this.hoverRulerMarkers.clear() // to remove previous hoverRulerMarkers

        if (intersect.length > 0) {
            const intersectPt = intersect[0]
            if (this.props.dimension === Dimension.TWOD)
                intersectPt.point.setZ(TWOD_OBJECTS_DEPTH * 2) // to see hover ruler clearly in 2D
            const object = intersect[0].object
            this.hoverRulerPts = [...this.rulerPts.slice(-1), intersectPt.point]
            let marker
            if (object instanceof Points) {
                const depth = intersectPt.point.z / getPixelRes()
                const complimentaryColor = getComplimentaryColor(
                    getColor(depth - this.depthObject.min).getHexString()
                )
                marker = getDotMarker(
                    0.1,
                    complimentaryColor ? complimentaryColor : 0x54e04f,
                    0.25
                )
            } else {
                marker = getCircle(0.5, 0xff000, 0, undefined, 0.5)
            }
            marker.position.copy(intersectPt.point)
            this.hoverRulerMarkers.add(marker)
        }

        this.initHoverRuler()
        this.updateHoverRuler()
    }

    // MOUSE HOVER LABEL
    initHoverLabel() {
        if (!this.depthHoverLabel) {
            this.depthHoverLabel = getRulerLabel()
            this.depthHoverLabel.element.style.marginLeft = '42px'
            this.depthHoverLabel.element.style.minWidth = '36px'
        }
        if (!this.scene.children.includes(this.depthHoverLabel)) {
            this.scene.add(this.depthHoverLabel)
        }
    }

    initHoverBoatLabel() {
        if (!this.boatHoverLabel) {
            this.boatHoverLabel = getBoatLabel()
        }
        if (!this.scene.children.includes(this.boatHoverLabel)) {
            this.scene.add(this.boatHoverLabel)
        }
    }

    renderHoverLabel() {
        this.raycaster.setFromCamera(this.mouse.endPos, this.getCamera())
        this.initRaycasterThreshold(1)
        const intersectMapModels = this.raycaster.intersectObjects(
            this.modelObjects.children,
            true
        )
        const intersectTerrainModels = this.raycaster.intersectObjects(
            this.terrainObjects.children,
            true
        )
        const intersectAuv = this.raycaster.intersectObjects(
            this.mapObjects.children,
            true
        )
        const intersectModels = [
            ...intersectMapModels,
            ...intersectTerrainModels,
            ...intersectAuv,
        ]

        if (
            intersectModels.length > 0 &&
            this.props.dimension === Dimension.THREED
        ) {
            this.initHoverLabel()
            const point = intersectModels[0].point
            const depth = getDepthFromPixelDepth(point.z)
            this.depthHoverLabel.position.copy(point)
            this.depthHoverLabel.element.innerHTML = `${depth}m`
        } else {
            this.removeHoverLabel()
        }
    }

    renderHoverCursor() {
        this.raycaster.setFromCamera(this.mouse.endPos, this.getCamera())
        this.initRaycasterThreshold(1)
        const intersectPts = this.raycaster.intersectObjects(
            this.rulerMarkers.children,
            true
        )
        if (intersectPts.length) {
            document.body.style.cursor = 'pointer'
        } else {
            document.body.style.cursor = 'default'
        }
    }

    removeHoverLabel() {
        this.hoverRulerPts = []
        this.scene.remove(this.depthHoverLabel)
    }

    handleHover() {
        switch (this.mouse.state) {
            case MouseState.RULER:
            case MouseState.MULTIRULER:
                if (!this.mouse.moved && this.modelObjects)
                    this.onHoverOverPoint()
                break
            default:
                this.removeHoverLabel()
                this.onHoverOverBoat()
                break
        }
    }

    onHoverOverPoint() {
        this.renderHoverLabel()
        this.renderHoverCursor()
        this.rulerPts.length === 1 &&
            this.mouse.state === MouseState.RULER &&
            this.renderHoverRulerMarker()
        this.rulerPts.length >= 1 &&
            this.mouse.state === MouseState.MULTIRULER &&
            this.renderHoverRulerMarker()
    }

    onHoverOverBoat() {
        this.raycaster.setFromCamera(this.mouse.endPos, this.getCamera())
        this.initRaycasterThreshold(1)
        const intersectBoatObject = this.raycaster.intersectObjects(
            this.boat.children
        )

        if (intersectBoatObject.length > 0) {
            this.initHoverBoatLabel()
            this.boatHoverLabel.position.copy(intersectBoatObject[0].point)
        } else {
            this.scene.remove(this.boatHoverLabel)
        }
    }

    // MOUSE
    isIntersect(objects: THREE.Object3D[], recursive = false): boolean {
        this.raycaster.setFromCamera(this.mouse.endPos, this.getCamera())
        const intersects = this.raycaster.intersectObjects(objects, recursive)
        if (intersects.length > 0) {
            this.intersectPt = intersects[0].point
        }
        return intersects.length > 0
    }

    getIntersect(
        objects: THREE.Object3D[],
        recursive = false
    ): THREE.Object3D | null {
        this.raycaster.setFromCamera(this.mouse.endPos, this.getCamera())
        const intersects = this.raycaster.intersectObjects(objects, recursive)
        if (intersects.length > 0) {
            this.intersectPt = intersects[0].point
            return intersects[0].object
        }
        return null
    }

    setMouseToDefault() {
        this.mouse.state = MouseState.DEFAULT
        this.oControls.enabled = true
        this.oControls.enablePan = true
        this.mapRef.style.cursor = 'default'
    }

    setMouseToGrab() {
        this.mouse.state = MouseState.GRAB
        this.oControls.enabled = true
        this.oControls.enablePan = false
        this.mapRef.style.cursor = 'pointer'
    }

    setMouseToGrabbing() {
        this.mouse.state = MouseState.GRABBING
        this.oControls.enabled = true
        this.oControls.enablePan = false
        this.mapRef.style.cursor = 'grabbing'
    }

    // setMouseToTransform() {
    //   this.mouse.state = MouseState.TRANSFORM
    //   this.oControls.enabled = true
    //   this.oControls.enablePan = false
    //   this.mapRef.style.cursor = 'move'
    // }

    setMouseToSelection() {
        this.mouse.state = MouseState.SELECTION
        this.oControls.enabled = true
        this.oControls.enablePan = false
        this.mapRef.style.cursor = 'copy'
    }

    updateMouseState(state: MouseState) {
        this.removeRuler()
        switch (state) {
            case MouseState.SELECTION:
                this.setMouseToSelection()
                break
            case MouseState.RULER:
            case MouseState.MULTIRULER:
                this.mouse.state = state
                break
            default:
                this.setMouseToDefault()
                break
        }
    }

    getMousePos(e: React.MouseEvent | PointerEvent): THREE.Vector3 {
        const rect = this.mapRef.getBoundingClientRect()
        const offsetX = e.clientX - rect.left
        const offsetY = e.clientY - rect.top
        return new Vector3(
            (offsetX / this.mapRef.clientWidth) * 2 - 1,
            -(offsetY / this.mapRef.clientHeight) * 2 + 1,
            0.5
        )
    }

    onMouseMove(e: React.MouseEvent) {
        this.mouse.endPos = this.getMousePos(e)
        if (this.mouse.down) this.mouse.moved = true
        this.handleHover()
        // switch (this.mouse.state) {
        //   case MouseState.GRABBING:
        //     if (this.mouse.down)
        //       this.moveTransformGroup()
        //     break
        //   case MouseState.SELECTION:
        //     if (this.mouse.down)
        //       this.updateSelectionRibbon(e)
        //     break
        //   case MouseState.DEFAULT:
        //     this.isMouseOverTaskMarker()
        //   default:
        //     break
        // }
    }

    onMouseDown(e: React.MouseEvent) {
        this.mouse.down = true
        this.mouse.type = e.button
        this.mouse.startPos = this.getMousePos(e)
        if (this.mapMenuRef.visible) {
            this.hideMenu()
            this.removeTempMarker()
        }
        // switch (this.mouse.type) {
        //   case MouseClick.LEFT:
        //     this.onMouseDownLeftClick(e)
        //     break
        //   case MouseClick.RIGHT:
        //     break
        //   default:
        //     break
        // }
    }

    onMouseUp(e: React.MouseEvent) {
        switch (this.mouse.type) {
            case MouseClick.LEFT:
                this.onMouseUpLeftClick(e)
                break
            case MouseClick.RIGHT:
                this.onMouseUpRightClick(e)
                break
            default:
                break
        }
        this.mouse.down = false
        this.mouse.moved = false
    }

    onMouseUpLeftClick(e: React.MouseEvent) {
        if (!this.mouse.down) return
        switch (this.mouse.state) {
            case MouseState.RULER:
            case MouseState.MULTIRULER:
                if (!this.mouse.moved) this.rulerLeftClick()
                break
            default:
                // TODO: Clear select marker when click on empty space
                // if (!this.labelHover) {
                //   console.log(this.labelHover)
                //   this.props.selectMarker && this.props.selectMarker(null)
                // }
                break
        }
    }

    onMouseUpRightClick(e: React.MouseEvent) {
        if (!this.mouse.down) return
        const mousePos = this.getMousePos(e)
        switch (this.mouse.state) {
            case MouseState.DEFAULT:
                if (!this.mouse.moved) {
                    if (this.props.mapMode === MapMode.MAP) {
                        this.addTempMarker(mousePos)
                        this.showMenu(this.tempMarker.position)
                    }
                }
                break
            case MouseState.MULTIRULER:
                if (!this.mouse.moved) this.rulerRightClick()
                break
            default:
                break
        }
    }

    getRaycasterIntersections(dimension: Dimension) {
        let intersect
        this.raycaster.setFromCamera(this.mouse.endPos, this.getCamera())
        const intersectTiles = this.raycaster.intersectObjects(
            this.mapTiles.children,
            true
        )
        const intersectModels =
            dimension === Dimension.THREED
                ? this.raycaster.intersectObjects(
                      this.modelObjects.children,
                      true
                  )
                : []
        //Original Code
        // if (this.props.showMap) {
        //   intersect = [...intersectTiles, ...intersectModels]
        // } else {
        //   intersect = intersectModels
        // }
        // end here
        const intersectTerrain = this.raycaster.intersectObjects(
            this.terrainObjects.children,
            true
        )
        const intersectAuv =
            dimension === Dimension.THREED
                ? this.raycaster.intersectObjects(
                      this.mapObjects.children,
                      true
                  )
                : []
        if (this.props.showMap && this.props.showModels) {
            intersect = [
                ...intersectTiles,
                ...intersectModels,
                ...intersectTerrain,
                ...intersectAuv,
            ]
        } else if (this.props.showMap && !this.props.showModels) {
            intersect = [...intersectTiles, ...intersectModels, ...intersectAuv]
        } else if (!this.props.showMap && this.props.showModels) {
            intersect = [
                ...intersectModels,
                ...intersectTerrain,
                ...intersectAuv,
            ]
        } else {
            intersect = intersectModels
        }
        return intersect
    }

    // MAP MENU
    showMenu(position: THREE.Vector3) {
        // this.oControls.enabled = false
        this.mapMenuRef.setType(0)
        // this.mapMenuRef.divRef.current?.addEventListener('pointerdown', () =>
        //   this.getCoords()
        // )
        this.mapMenu.position.copy(position)

        this.scene.add(this.mapMenu)
        this.mapMenuRef.setVisible(true)
    }

    hideMenu() {
        // this.oControls.enabled = true
        // this.mapMenuRef.divRef.current?.removeEventListener('pointerdown', () =>
        //   this.getCoords()
        // )
        this.scene.remove(this.mapMenu)
        this.mapMenuRef.setVisible(false)
        this.mapMenuRef.setMoving(false)
    }

    getCoords() {
        setTimeout(() => {
            if (!this.mapTilesOrigin) return
            const coord = sceneToLatLng(
                this.tempMarker.position,
                this.mapTilesOrigin
            )
            navigator.clipboard
                .writeText(`${coord.latitude}, ${coord.longitude}`)
                .then(
                    function () {
                        alert(
                            `Coordinates copied to clipboard! \n${coord.latitude}, ${coord.longitude}`
                        )
                    },
                    function () {
                        alert('An error occured. Please try again.')
                    }
                )
        }, 100)
    }

    // AXES HELPER & ASSISTIVE ARROW
    updateAxesHelperRelativePos() {
        this.axesHelperRelativePos = new Vector3(
            this.axesHelperPosFactor * this.pCamera.aspect,
            this.axesHelperPosFactor,
            -2
        )
    }

    renderAxesHelper() {
        if (this.dimension === Dimension.TWOD) {
            this.scene.remove(this.axesHelper)
            this.scene.remove(this.assistiveArrow)
            this.scene.remove(this.assistiveArrowLabel)
            this.axesHelperLabels.forEach((labelObject) =>
                this.scene.remove(labelObject)
            )
            return
        }
        if (!this.showingAxesHelper) return
        this.pCamera.updateMatrixWorld()
        this.scene.add(this.axesHelper)
        const axesHelperAbsolutePos = this.getCamera().localToWorld(
            this.axesHelperRelativePos.clone()
        )
        this.axesHelper.position.copy(axesHelperAbsolutePos)
        if (this.isGLBModel) {
            this.scene.add(this.assistiveArrow)
            this.scene.add(this.assistiveArrowLabel)
            this.assistiveArrow.position.copy(axesHelperAbsolutePos)
            this.assistiveArrowLabel.position.copy(
                axesHelperAbsolutePos.add(ASSISTIVE_ARROW_LABEL_DISPLACEMENT)
            )
        } else
            this.axesHelperLabels.forEach((labelObject, idx) => {
                this.scene.add(labelObject)
                labelObject.position
                    .copy(axesHelperAbsolutePos)
                    .add(AXES_HELPER_LABELS_DISPLACEMENT[idx])
            })
    }

    // OTHERS
    onWindowResize() {
        setTimeout(() => {
            if (!this.mapRef) return
            const windowHeight = this.mapRef.clientHeight
            const windowWidth = this.mapRef.clientWidth
            const hudHeight = this.hudRef.clientHeight
            const hudWidth = this.hudRef.clientWidth
            this.pCamera.aspect = windowWidth / windowHeight
            this.pCamera.updateProjectionMatrix()

            this.updateAxesHelperRelativePos()

            this.oCamera.left = windowWidth / -2
            this.oCamera.right = windowWidth / 2
            this.oCamera.top = windowHeight / 2
            this.oCamera.bottom = windowHeight / -2
            this.oCamera.updateProjectionMatrix()

            this.hudCamera.left = hudWidth / -2
            this.hudCamera.right = hudWidth / 2
            this.hudCamera.top = hudHeight / 2
            this.hudCamera.bottom = hudHeight / -2
            this.hudCamera.updateProjectionMatrix()
            this.hudRenderer.setSize(hudWidth, hudHeight)
            this.hudLabelRenderer.setSize(hudWidth, hudHeight)
            // if (this.pcd) this.renderDepthScale(this.pcd)

            this.renderer.setSize(windowWidth, windowHeight)
            this.labelRenderer.setSize(windowWidth, windowHeight)
            this.composer.setSize(windowWidth, windowHeight)
            // this.effectFXAA.uniforms['resolution'].value.set(1/windowWidth, 1/windowHeight);
        }, 0.4)
    }

    private matLines: LineMaterial[] = [
        new LineMaterial({
            color: 0x42d4f5,
            linewidth: 3, // in world units with size attenuation, pixels otherwise
            vertexColors: false,
            dashed: false,
            alphaToCoverage: true,
        }),
        new LineMaterial({
            color: 0x84def2,
            linewidth: 3, // in world units with size attenuation, pixels otherwise
            vertexColors: false,
            dashed: false,
            alphaToCoverage: true,
            opacity: 0.5,
        }),
        new LineMaterial(),
    ]

    renderScene() {
        // this.renderer.render(this.scene, this.getCamera())
        this.renderAxesHelper()
        this.labelRenderer.render(this.scene, this.getCamera())
        this.hudRenderer.render(this.hudScene, this.hudCamera)
        this.hudLabelRenderer.render(this.hudScene, this.hudCamera)
        this.composer.render()
        const { width, height } = this.getWindowSize()
        if (width && height) {
            // this.renderer.setClearColor(0x000000, 0);
            // this.renderer.setViewport(0, 0, window.innerWidth, window.innerHeight);
            this.matLines.map((value, _) => value.resolution.set(width, height))
            this.renderer.render(this.scene, this.getCamera())
            this.labelRenderer.render(this.scene, this.getCamera())
        }
    }

    animate() {
        this.renderScene()
        this.oControls.update()
        setTimeout(() => {
            requestAnimationFrame(this.animate.bind(this))
        }, 1000 / 30)
    }

    render(): JSX.Element {
        return (
            <>
                <MapMenu
                    ref={(ref) => (this.mapMenuRef = ref!)}
                    getCoords={() => {
                        this.getCoords()
                    }}
                />
                <div
                    style={{
                        inset: '0px',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        zIndex: 0,
                        position: 'absolute',
                    }}
                    ref={(ref) => (this.mapRef = ref!)}
                    onPointerUp={(e) => this.onMouseUp(e)}
                    onPointerMove={(e) => this.onMouseMove(e)}
                    onPointerDown={(e) => this.onMouseDown(e)}
                />
                <div
                    style={{
                        inset: '0px',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        zIndex: 0,
                        position: 'absolute',
                        pointerEvents: 'none',
                        backgroundColor: 'transparent',
                        width: '20%',
                        height: '100%',
                    }}
                    ref={(ref) => (this.hudRef = ref!)}
                />
            </>
        )
    }
}

export default Map

// HELPER FUNCTIONS ============================================================

function isChanged(prev: any, curr: any): boolean {
    return JSON.stringify(prev) !== JSON.stringify(curr)
}

export function is2DMarkers(rulerPts: THREE.Vector3[]) {
    if (rulerPts.length < 2) return false
    return rulerPts[0].z === 0 && rulerPts[1].z === 0
}

function isCoordFar(coord1: LatLngCoord, coord2: LatLngCoord): boolean {
    const utm1 = UTM.fromLatLon(coord1.latitude, coord1.longitude)
    const utm2 = UTM.fromLatLon(coord2.latitude, coord2.longitude)
    return (
        Math.abs(utm1.easting - utm2.easting) > 10000 ||
        Math.abs(utm1.northing - utm2.northing) > 10000
    )
}

export function isCoordFarUTM(marker1: UTMCoord, marker2: UTMCoord): boolean {
    if (marker1.easting === 0) {
        marker1.easting = marker2.easting
        marker1.northing = marker2.northing
        marker1.zone_letter = marker2.zone_letter
        marker1.zone_number = marker2.zone_number
    }
    return (
        Math.abs(marker1.easting - marker2.easting) > 10000 ||
        Math.abs(marker1.northing - marker2.northing) > 10000
    )
}

function vectorCloseEnough(vector1: THREE.Vector3, vector2: THREE.Vector3) {
    const xDiff = Math.abs(vector1.x - vector2.x)
    const yDiff = Math.abs(vector1.y - vector2.y)
    const zDiff = Math.abs(vector1.z - vector2.z)
    if (xDiff < 1 && yDiff < 1 && zDiff < 1) {
        return true
    }
    return false
}

function boatVectorCloseEnough(vector1: THREE.Vector3, vector2: THREE.Vector3) {
    const xDiff = Math.abs(vector1.x - vector2.x)
    const yDiff = Math.abs(vector1.y - vector2.y)
    const zDiff = Math.abs(vector1.z - vector2.z)
    if (xDiff < 0.5 && yDiff < 0.5 && zDiff < 0.5) {
        return true
    }
    return false
}

function findMinDistanceFromObject(
    arrayPts: THREE.Vector3[],
    object: THREE.Vector3,
    indexStart?: number
) {
    let currMinIndex = 0
    let currMin = Infinity

    for (let i = indexStart || 0; i < arrayPts.length; i++) {
        const dist = object.distanceTo(arrayPts[i])
        if (dist < currMin) {
            currMinIndex = i
            currMin = dist
        }
    }

    return currMinIndex
}
