import React from 'react'

const WebGLPage = () => {
  return (
    <div className='w-100 h-100 align-center justify-center flex-wrap flex flex-col text-white'>
      <div className='h1'>Opps! WebGL appears to be missing.</div>
      <div className='h3 text-white' style={{ marginTop: '24px' }}>
        It looks like your browser does not have WebGL running.{' '}
        <a className='primary' href='https://get.webgl.org/'>
          Please click here for more information.
        </a>
      </div>
    </div>
  )
}

export default WebGLPage
