export const SUBSCRIPTION_ID = '3813765c-1aa7-4d9b-b7bf-b0adba1efed0'
export const RESOURCE_GROUP_NAME = "MMP"
export const scopes = {
  storage : ["https://management.azure.com/.default"],
  container: ["https://storage.azure.com/user_impersonation"]
}
const TENANT_ID = 'eacdd9b4-099b-48e2-bdd3-2c7fdc3fe1b8'
export const REDIRECT_URI = process.env.NODE_ENV == 'development' ? 'http://localhost:3000/' : 'https://sambal.beex.sg/'

export const msalConfig = {
  auth: {
    clientId: "e8cfce4f-7341-4a65-9e37-f16f4561009c",
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: REDIRECT_URI
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["User.Read"]
}
