import * as THREE from 'three'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls'
import { getPixelRes } from './CoordHelper/CoordUtils'
import { ViewOrientation } from '../../../types'

let prevCameraPose: THREE.Vector3 | undefined = new THREE.Vector3(0, 0, 0)

export function resetCameraToNorth(
    camera: THREE.Camera,
    controls: MapControls
  ) {
    const target = controls.target
    camera.position.set(target.x, target.y - 10, camera.position.z)
    controls.update()
  }

export function moveCameraToObject(
    object: THREE.Object3D,
    camera: THREE.Camera,
    controls: MapControls,
    viewOrientation?: ViewOrientation,
    multiSelect?: boolean,
    isModel?: boolean
) {
    if (object === null || controls === undefined) {
        return
    }
    const pos = new THREE.Vector3().copy(object.position)
    if (object instanceof THREE.Points) {
        const positions = object.geometry.attributes.position
        const x = positions.getX(0) * getPixelRes()
        const y = positions.getY(0) * getPixelRes()
        const z = positions.getZ(0) * getPixelRes()
        pos.add(new THREE.Vector3(x, y, z))
    }
    controls.target.copy(pos)
    camera.position.copy(pos)
    const viewDistance = 100
    switch (viewOrientation) {
        case ViewOrientation.FRONT:
            camera.position.add(new THREE.Vector3(0, viewDistance, 0))
            break
        case ViewOrientation.SIDE:
            camera.position.add(new THREE.Vector3(viewDistance, 0, 0))
            break
        case ViewOrientation.TOP:
            camera.position.add(new THREE.Vector3(0, 0, viewDistance))
            break
        default:
            const orthDist = viewDistance / Math.sqrt(3)
            camera.position.add(new THREE.Vector3(orthDist, orthDist, orthDist))
            break
    }
    if (camera instanceof THREE.OrthographicCamera && !multiSelect) {
        camera.zoom = 5
        camera.updateProjectionMatrix()
    } else if (camera instanceof THREE.OrthographicCamera && multiSelect) {
        isModel ? (camera.zoom = 5) : (camera.zoom = 25)
        camera.updateProjectionMatrix()
    } else if (camera instanceof THREE.PerspectiveCamera && multiSelect) {
        if (isModel) {
            camera.position.add(new THREE.Vector3(0, -80, -80))
            camera.zoom = 1
        } else {
            camera.position.add(new THREE.Vector3(0, -40, -80))
            camera.zoom = 5
        }
        camera.updateProjectionMatrix()
    }
    prevCameraPose = undefined
    controls.update()
}

export function moveCameraToPos(
    pos: THREE.Vector3,
    camera: THREE.Camera,
    controls: MapControls
) {
    if (controls === undefined) {
        return
    }
    controls.target.copy(pos)
    camera.position.set(pos.x, pos.y, pos.z + 100)
    if (camera instanceof THREE.OrthographicCamera) {
        camera.zoom = 5
        camera.updateProjectionMatrix()
    }
    prevCameraPose = undefined
    controls.update()
}

export function moveCameraBy(
    pos: THREE.Vector3,
    camera: THREE.Camera,
    controls: MapControls
) {
    if (prevCameraPose) {
        const dir = pos.clone().sub(prevCameraPose)
        camera.position.add(dir)
        if (controls) {
            controls.target.add(dir)
        }
    }
    prevCameraPose = pos.clone()
}
