import React, { useEffect, useState } from 'react'
import {
    MarkerType,
    PDFReportImageType,
    Hashtag,
    HashtagType,
} from '../../../../types'
import FilledTonalButton from '../../../elements/buttons/FilledTonalButton'
import Button from '../../../elements/buttons/Button'
import IconButton from '../../../elements/buttons/IconButton'
import ModalOverlay from '../../../elements/ModalOverlay'
import PDFInputPanel from './PDFInputPanel'
import PriorityIcon from '../../../../assets/icons/priority_high_black_24dp.svg'
import FilledButton from '../../../elements/buttons/FilledButton'
import { isMobile } from '../../../utils/general'
import HashtagInput from '../../../elements/tags/hashtag'
import TextBox from '../../../elements/inputs/TextBox'
import { MarkerDetails } from './components/MarkerDetails'

const commentCharacterLimit = 540
const commentLineLimit = 12
enum CommentError {
    EXCEED_CHARACTER_LIMIT = 'character limit of 540 characters',
    EXCEED_LINE_LIMIT = 'line limit of 12 lines',
}

type RightPanelProps = {
    marker: MarkerType
    hashtags: Hashtag[]
    frontCamImage: string
    sonarImage: string
    edited: boolean
    asset: string
    storage: string
    setEdited: (edited: boolean) => void
    saveEdit: (newMarker: MarkerType, newHashtagList: Hashtag[]) => void
    generatePDF?: boolean
    allHashtags: Hashtag[]
    saveAllHashtags?: (
        newHashtagList: Hashtag[],
        hashtagsEdited?: { old: Hashtag; new: Hashtag }[]
    ) => Promise<void>
    setHashtagEdited?: () => void
}

const RightPanel = ({
    marker,
    hashtags,
    frontCamImage,
    sonarImage,
    edited,
    asset,
    storage,
    setEdited,
    saveEdit,
    generatePDF = true,
    allHashtags,
    saveAllHashtags,
    setHashtagEdited,
}: RightPanelProps) => {
    const mobile = isMobile()
    const [newMarker, setNewMarker] = useState<MarkerType>(marker)
    const [newHashtagList, setNewHashtagList] = useState<Hashtag[]>([])
    const [newHashtag, setNewHashtag] = useState<Hashtag | null>()
    const [addingNewHashtag, setAddingNewHashtag] = useState<Hashtag>()
    const [hashtagErrorMessage, sethashtagErrorMessage] = useState<string>('')
    const [charLengthErrorMessage, setCharLengthErrorMsg] = useState<string>('')
    const [componentStructureTags, setComponentStructureTags] = useState<
        Hashtag[]
    >([
        { type: HashtagType.STRUCTURE, content: '' },
        { type: HashtagType.COMPONENT, content: '' },
    ])
    const [commentError, setCommentError] = useState<CommentError | undefined>()
    const [titleError, setTitleError] = useState<boolean>(false)
    const [showPDFInput, setShowPDFInput] = useState<boolean>(false)
    const [hashtagMap, setHashtagMap] = useState<Map<string, Hashtag[]>>(
        new Map<string, Hashtag[]>()
    )
    const [images, setImages] = useState<PDFReportImageType[]>([
        {
            idx: 0,
            img_type: 'sonar',
            url: sonarImage,
        },
        {
            idx: 0,
            img_type: 'front_cam',
            url: frontCamImage,
        },
    ])
    const handleGeneratePDF = () => {
        setShowPDFInput(true)
        const hashtagMap = new Map<string, Hashtag[]>()
        setHashtagMap(hashtagMap.set(marker.bag_id, newHashtagList))
    }
    const handleSaveEdit = () => {
        if (!edited || !newMarker) return
        const updatedHashtags = cleanHashtagArray(newHashtagList)
        setNewHashtagList(updatedHashtags)
        saveEdit(newMarker, updatedHashtags)
    }
    const handleCancelEdit = () => {
        setNewMarker(marker)
        setNewHashtag(null)
        setNewHashtagList(hashtags)
        setEdited(false)
    }
    const handleUpdateMarkerName = (value: string) => {
        if (value.length > 50) {
            setTitleError(true)
        } else {
            setTitleError(false)
        }
        if (newMarker.comment) {
            updateMarker(value, newMarker.comment, newMarker.no_anode)
        } else {
            updateMarker(value, '', newMarker.no_anode)
        }
    }
    const handleUpdateMarkerComment = (value: string) => {
        if (value.length > commentCharacterLimit) {
            setCommentError(CommentError.EXCEED_CHARACTER_LIMIT)
        } else if (value.split('\n').length > commentLineLimit) {
            setCommentError(CommentError.EXCEED_LINE_LIMIT)
        } else {
            setCommentError(undefined)
        }
        updateMarker(newMarker.name, value, newMarker.no_anode)
    }
    const handleUpdateMarkerAnode = (value: boolean) => {
        if (newMarker.comment) {
            updateMarker(newMarker.name, newMarker.comment, value)
        } else {
            updateMarker(newMarker.name, '', value)
        }
    }
    const updateMarker = (name: string, comment: string, noAnode: boolean) => {
        if (!newMarker) return
        const updatedMarker = { ...newMarker }
        updatedMarker.name = name
        updatedMarker.comment = comment
        updatedMarker.no_anode = noAnode
        setNewMarker(updatedMarker)
        setEdited(true)
    }
    // const handleUpdateNewHashTag = (value: Hashtag) => {
    //   sethashtagErrorMessage('')
    //   if (value.content == '') {
    //     setNewHashtag(null)
    //     return
    //   }
    //   setNewHashtag(cleanHashtag(value))
    //   setEdited(true)
    // }
    // const handleUpdateHashTag = (idx: number, value: Hashtag) => {
    //   sethashtagErrorMessage('')
    //   const original = newHashtagList[idx]
    //   const updatedHashtags = [...newHashtagList]
    //   if (original.content === value.content) return
    //   updatedHashtags[idx] = cleanHashtag(value)
    //   setNewHashtagList(updatedHashtags)
    //   setEdited(true)
    // }
    // const handleDeleteHashtag = (idx: number) => {
    //   sethashtagErrorMessage('')
    //   const updatedHashtags = [...newHashtagList]
    //   updatedHashtags.splice(idx, 1)
    //   setNewHashtagList(updatedHashtags)
    //   setEdited(true)
    // }
    // const handleAddHashTag = (value: Hashtag | undefined) => {
    //   if (!value || value.content === '') {
    //     sethashtagErrorMessage('Please enter a valid hashtag')
    //     return
    //   }
    //   // if (value.content.length > 16) {
    //   //   sethashtagErrorMessage('Hashtags should not exceed 16 characters')
    //   //   return
    //   // }
    //   // if (checkRepeatedHashTag(value, newHashtagList)) {
    //   //   sethashtagErrorMessage('Hashtag already exists')
    //   //   return
    //   // }
    //   // if (newHashtagList.length >= 5) {
    //   //   sethashtagErrorMessage('Max hashtag limit reached')
    //   //   return
    //   // }
    //   // setAddingNewHashtag(value)
    //   const updatedHashtags = [...newHashtagList]
    //   updatedHashtags.push(value)
    //   // setNewHashtagList(updatedHashtags)
    //   setNewHashtag(null)
    //   setEdited(true)
    // }

    const handleSaveAllHashtags = async (
        hashtags: Hashtag[],
        hashtagType: HashtagType,
        hashtagsEdited?: { old: Hashtag; new: Hashtag }[]
    ) => {
        let updatedTags: Hashtag[] = []
        const structureTags = allHashtags.filter(
            (x) => x.type === HashtagType.STRUCTURE
        )
        const componentTags = allHashtags.filter(
            (x) => x.type === HashtagType.COMPONENT
        )
        if (hashtagType === HashtagType.STRUCTURE) {
            updatedTags = componentTags.concat(hashtags)
        } else if (hashtagType === HashtagType.COMPONENT) {
            updatedTags = structureTags.concat(hashtags)
        }
        saveAllHashtags && saveAllHashtags(updatedTags, hashtagsEdited)
    }

    const cleanHashtagArray = (arr: Hashtag[]) => {
        return [...new Set(arr.filter((ht) => ht.content !== ''))]
    }
    const cleanHashtag = (hashtag: Hashtag) => {
        const newHashtagContent = hashtag.content
            .replace(' ', '')
            .replace(/[^\w\s]/gi, '')
            .toLowerCase()
        return { type: hashtag.type, content: newHashtagContent }
    }
    useEffect(() => {
        setNewMarker({ ...marker })
        setNewHashtagList([...hashtags])
    }, [])
    useEffect(() => {
        if (hashtagErrorMessage !== '') {
            setTimeout(() => sethashtagErrorMessage(''), 5000)
        }
    }, [hashtagErrorMessage])
    useEffect(() => {
        if (titleError && !commentError) {
            setCharLengthErrorMsg(
                'Title has exceeded character limit of 50 characters'
            )
        }
        if (!titleError && commentError) {
            setCharLengthErrorMsg(`Comment has exceeded ${commentError}`)
        }
        if (
            titleError &&
            commentError === CommentError.EXCEED_CHARACTER_LIMIT
        ) {
            setCharLengthErrorMsg(
                'Comment and Title have exceeded character limit!'
            )
        }
        if (!titleError && !commentError) {
            setCharLengthErrorMsg('')
        }
    }, [titleError, commentError])
    return (
        marker && (
            <>
                {showPDFInput && (
                    <ModalOverlay
                        isVisible={marker !== null}
                        isLoading={false}
                        onCloseModal={() => {
                            setShowPDFInput(false)
                        }}
                        className='z-alert'>
                        <PDFInputPanel
                            markers={[newMarker]}
                            models={[]}
                            images={images}
                            modelImages={[]}
                            hashtagMap={hashtagMap}
                            asset={asset}
                            setShowPDFInput={setShowPDFInput}
                        />
                    </ModalOverlay>
                )}
                <div className='flex w-100 margin-b-md justify-center align-center'>
                    <input
                        className={
                            'rounded-sm border-bottom-1 border-blue h-40px surface-container-bg text-white flex-grow-1 w-0 text-lg text-bold padding-l-md'
                        }
                        value={newMarker.name}
                        onChange={(e) => handleUpdateMarkerName(e.target.value)}
                    />
                    <div className='flex-basis-24' />
                    <IconButton
                        Icon={PriorityIcon}
                        className={`${
                            newMarker.no_anode ? 'on-no-anode' : 'on-anode'
                        }`}
                        onClick={() =>
                            handleUpdateMarkerAnode(!newMarker.no_anode)
                        }
                    />
                </div>
                <MarkerDetails marker={marker} />
                <div className={'flex align-center padding-h-sm'}>
                    <span
                        className='text-bold text-sm padding-r-sm'
                        style={{ width: '100px' }}>
                        Structure
                    </span>
                    <HashtagInput
                        flexGrow
                        type={HashtagType.STRUCTURE}
                        id={marker.bag_id}
                        tag={
                            newHashtagList
                                .filter((x) => x.type == HashtagType.STRUCTURE)
                                .map((value) => value.content)[0] || ''
                        }
                        onEdit={() => setHashtagEdited && setHashtagEdited()}
                    />
                </div>
                <div className={'flex align-center padding-h-sm'}>
                    <span
                        className='text-bold text-sm padding-r-sm'
                        style={{ width: '100px' }}>
                        Component
                    </span>
                    <HashtagInput
                        flexGrow
                        type={HashtagType.COMPONENT}
                        id={marker.bag_id}
                        tag={
                            newHashtagList
                                .filter((x) => x.type == HashtagType.COMPONENT)
                                .map((value) => value.content)[0] || ''
                        }
                        onEdit={() => setHashtagEdited && setHashtagEdited()}
                    />
                </div>
                <div className='flex justify-center align-center w-100 prompt-text'>
                    {hashtagErrorMessage}
                </div>
                <TextBox
                    value={newMarker.comment || ''}
                    onChange={handleUpdateMarkerComment}
                />
                {!edited && generatePDF && (
                    <div
                        className={
                            'margin-t-sm margin-b-mdWorkerType flex w-100 justify-end'
                        }>
                        <FilledTonalButton
                            text='Generate PDF'
                            onClick={() => handleGeneratePDF()}
                        />
                    </div>
                )}
                {edited && !titleError && !commentError && (
                    <div
                        className={
                            'margin-t-sm margin-b-md flex w-100 justify-end'
                        }>
                        <Button
                            contentColor={'on-surface-variant'}
                            text='Cancel'
                            onClick={() => handleCancelEdit()}
                        />
                        <FilledButton
                            text='Save'
                            onClick={() => handleSaveEdit()}
                        />
                    </div>
                )}
                {(titleError || commentError) && (
                    <div className='margin-t-sm margin-b-md flex w-100 justify-end prompt-text'>
                        {charLengthErrorMessage}
                    </div>
                )}
            </>
        )
    )
}

export default RightPanel
