import React, { useEffect, useState } from 'react'
import Chip from '../chips/Chip'
import TickIcon from '../../../assets/icons/done_black_24dp.svg'

type ChipBarProps = {
    tags: string[]
    selected: string[]
    onSelect: (tags: string[]) => void
}

const ChipBar = ({ tags, selected, onSelect }: ChipBarProps) => {
    const select = (value: string) => {
        const temp = [...selected]
        const index = temp.indexOf(value)
        if (index > -1) temp.splice(index, 1) // Chip already selected
        else temp.push(value) // Chip not selected
        onSelect(temp)
    }
    return (
        <div
            className='flex flex-grow-1 flex-wrap surface-container-bg overflow-scroll'
            style={{ maxHeight: '320px' }}>
            {tags.map((value, idx) => (
                <Chip
                    key={idx}
                    className='margin-r-sm margin-t-sm'
                    text={`#${value}`}
                    Icon={selected.includes(value) ? TickIcon : undefined}
                    selected={selected.includes(value)}
                    borderColor='outline'
                    selectedContainerColor='secondary-container'
                    selectedContentColor='on-secondary-container'
                    onClick={() => select(value)}
                />
            ))}
        </div>
    )
}

export default ChipBar
