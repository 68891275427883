import * as THREE from 'three'
import { LatLngCoord } from '../../../types'
import { latLngToTile, MAP_SCALE, tileTolatLng } from './CoordHelper/CoordUtils'

// MAP TILES ===================================================================
const TILE_SIZE = 256
let mapCenter = {
    x: 0,
    y: 0,
}
let mapTiles: { [key: string]: boolean } = {}

export async function getMapTiles(coord: LatLngCoord) {
    const mapObject = new THREE.Object3D()
    const { x, y } = latLngToTile(coord)
    mapCenter = { x: x, y: y }
    mapTiles = {}
    const { lat, lng } = tileTolatLng(x, y, coord.zoom)
    return {
        map: mapObject,
        origin: { latitude: lat, longitude: lng, zoom: coord.zoom },
    }
}

export async function updateMapMesh(
    coord: LatLngCoord,
    size: number,
    map: THREE.Object3D
) {
    const { x, y } = latLngToTile(coord)
    for (let i = -size; i <= size; i++) {
        for (let j = -size; j <= size; j++) {
            const tileName = `${coord.zoom}${x + i}${y + j}`
            if (!(tileName in mapTiles)) {
                mapTiles[tileName] = true
                getTileMesh(
                    x + i,
                    y + j,
                    x - mapCenter.x + i,
                    y - mapCenter.y + j,
                    coord
                ).then((tile) => map.add(tile))
            }
        }
    }
}

async function getTileMesh(
    xtile: number,
    ytile: number,
    xoffset: number,
    yoffset: number,
    coord: LatLngCoord
): Promise<THREE.Mesh> {
    let material
    const geometry = new THREE.PlaneGeometry(TILE_SIZE, TILE_SIZE)
    const url = await fetchTile(coord.zoom, xtile, ytile)
    if (url) {
        const texture = new THREE.TextureLoader().load(url)
        material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.FrontSide,
            opacity: 0.6,
            transparent: true,
        })
    } else {
        material = new THREE.MeshBasicMaterial({
            side: THREE.FrontSide,
            opacity: 0.6,
            transparent: true,
            color: 0x4d609c,
        })
    }
    const plane = new THREE.Mesh(geometry, material)
    plane.name = `tile${xtile}${ytile}`
    plane.position.set(
        (TILE_SIZE * xoffset + TILE_SIZE / 2) * MAP_SCALE,
        (-TILE_SIZE * yoffset - TILE_SIZE / 2) * MAP_SCALE,
        0
    )
    plane.scale.setScalar(MAP_SCALE)
    return plane
}

async function fetchTile(z: number, x: number, y: number) {
    const url = `https://mt1.google.com/vt/lyrs=s&x=${x}&y=${y}&z=${z}`
    try {
        await fetch(url)
        return url
    } catch (error) {
        console.log(error)
        return null
    }
}
