import React from 'react'
import TickedCheckBoxIcon from '../../assets/icons/check_box_black_24dp.svg'
import UnTickedCheckBoxIcon from '../../assets/icons/check_box_outline_blank_black_24dp.svg'
import IndeterminateCheckBoxIcon from '../../assets/icons/indeterminate_check_box_black_24dp.svg'

type SingleCheckBoxProp = {
    checked: boolean
    onChange: React.Dispatch<React.SetStateAction<boolean>> |
        React.Dispatch<React.SetStateAction<boolean | undefined>>
    label: string
}

type CheckBoxesProp = {
    indivCheckBoxesProp: SingleCheckBoxProp[]
    parentLabel?: string
}

const CheckBoxes = ({ indivCheckBoxesProp, parentLabel }: CheckBoxesProp) => {
    const getCheckBoxIcon = (
        checked: boolean,
        onChange: React.Dispatch<React.SetStateAction<boolean>> |
            React.Dispatch<React.SetStateAction<boolean | undefined>>
    ) => {
        const onClick = () => onChange((prev: any) => !prev)
        return checked ?
            <TickedCheckBoxIcon height={24} width={24} onClick={onClick} /> :
            <UnTickedCheckBoxIcon height={24} width={24} onClick={onClick} />
    }

    const getParentCheckBoxIcon = () => {
        const checkedCount = indivCheckBoxesProp.filter(({ checked }) => checked).length
        const allChecked = checkedCount === indivCheckBoxesProp.length
        const onClick = () => {
            if (allChecked)
                indivCheckBoxesProp.forEach(({ onChange }) => onChange(false))
            else
                indivCheckBoxesProp.forEach(({ onChange }) => onChange(true))
        }
        if (checkedCount === 0) {
            return <UnTickedCheckBoxIcon height={24} width={24} onClick={onClick} />
        } else if (allChecked) {
            return <TickedCheckBoxIcon height={24} width={24} onClick={onClick} />
        } else {
            return <IndeterminateCheckBoxIcon height={24} width={24} onClick={onClick} />
        }
    }

    return (
        <div className='margin-t-sm'>
            {parentLabel !== undefined && <div className='flex align-center'>
                {getParentCheckBoxIcon()}
                <div className='margin-l-sm margin-h-sm'>{parentLabel}</div>
            </div>}
            {indivCheckBoxesProp.map(({ checked, onChange, label }, index) => (
                <div key={index} className={`flex align-center ${parentLabel ? 'margin-l-lg' : ''}`}>
                    {getCheckBoxIcon(checked, onChange)}
                    <div className="margin-l-sm margin-h-sm">{label}</div>
                </div>
            ))}
        </div>
    )
}

export { CheckBoxes, SingleCheckBoxProp }