import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    ImageRun,
    SectionType,
    IImageOptions,
    ISectionOptions,
    AlignmentType,
    Header,
    IFloating,
    HorizontalPositionAlign,
    Footer,
    PageNumber,
    ParagraphChild,
    Table,
    TableRow,
    TableCell,
    VerticalAlign,
    BorderStyle,
    IShadingAttributesProperties,
    convertMillimetersToTwip,
    ShadingType,
    ITableCellBorders,
    HeightRule,
    VerticalPositionRelativeFrom,
    IPageMarginAttributes,
    ISectionPropertiesOptions,
    TableLayoutType,
} from 'docx'
import { IPropertiesOptions } from 'docx/build/file/core-properties/properties'
import { ITableCellMarginOptions } from 'docx/build/file/table/table-properties/table-cell-margin'
import { PDFDocument, PDFImage } from 'pdf-lib'
import { saveAs } from 'file-saver'
import {
    MarkerType,
    PDFReportImageType,
    Hashtag,
    SonarInfo,
    ModelType,
} from '../../types'
import {
    generateFileName,
    getMarkerDateNTime,
    getDataBoxLeftColVals,
} from './ReportGenerationCommonUtils'
import * as Jimp from 'jimp'
import { drawSonarOverlay } from './sonar'
import { processStrSeacp } from './DataUtils'
import { roundValue } from './MathUtils'
import {
    CELL_NO_MARGIN_TOP_BOT,
    CELL_MARGIN_ALIGN_RIGHT,
    CELL_MARGIN_ALIGN_LEFT,
    EMUS_PER_INCH,
    DXA_PER_IMG_PIXEL,
    PDF_TO_WORD_FACTOR,
    FONT_FAMILY,
    FontSizes,
    BORDER_NONE,
    BORDER_THICK,
    SHADING_CLEAR,
    SHADING_GREY,
    coverSectionTitleHeightOffset,
    coverSectionPropertiesOption,
    bottomMarginInCm,
    maxClientLogoHeight,
    contentHeightInMm,
} from './Docx/constants'
import { dummyParagraph } from './Docx/elements/Dummy'
import { createMarkerSection } from './Docx/sections/MarkerSection'
import { createModelSection } from './Docx/sections/ModelSection'
import { ImageDetail, ImgDims } from './Docx/types'
import { getImageRun } from './Docx/elements/ImageRun'
import { wrapTextInInvisibleCell } from './Docx/elements/Cell'
import { wrapInParagraph } from './Docx/elements/Paragraph'

// Intro section configuration
const introSectionLeftRightMargin = `1.09cm`
const introSectionPropertiesOption: ISectionPropertiesOptions = {
    type: SectionType.NEXT_PAGE,
    page: {
        margin: {
            top: `2.30cm`,
            bottom: `${bottomMarginInCm}cm`,
            left: introSectionLeftRightMargin,
            right: introSectionLeftRightMargin,
        },
    },
}
const introSectionHeading = 'Introduction and Objectives'

// Header (client logo) configurations
const headerFloatingOptions = {
    verticalPosition: {
        offset: 125000,
        relative: VerticalPositionRelativeFrom.TOP_MARGIN,
    },
    horizontalPosition: { align: HorizontalPositionAlign.CENTER },
}

// Footer's MMP logo configurations
const footerLogoUrl = '../../assets/images/logo_mmp.png'
const footerLogoScale = 0.2 * PDF_TO_WORD_FACTOR
const footerFLoatingOptions: IFloating = {
    horizontalPosition: {
        offset: 6.75 * EMUS_PER_INCH,
    },
    verticalPosition: {
        offset: 11 * EMUS_PER_INCH,
        // Choose this as can't set position of pageNum textRun
        // offset: 11.1 * EMUS_PER_INCH,
    },
}

// Watermark configurations
const watermarkUrl = '../../assets/images/watermark.png'
const watermarkScale = 1.55
const croppedWaterMarkTopLeftCorner = { x: 76, y: 36 }
const croppedWaterMarkBottomRightCorner = { x: 286, y: 288 }
const watermarkFLoatingOptions: IFloating = {
    horizontalPosition: {
        offset: 0 * EMUS_PER_INCH,
    },
    verticalPosition: {
        offset: 10.95 * EMUS_PER_INCH,
    },
    zIndex: -100, // DERENTODO: Fix zINdex
    behindDocument: true,
}

const warningIconUrl = '../../assets/images/warning.png' // Used for no_anode

// Sonar grid configurations
const sonarGridLineWidth = 2.2
const sonarGridLineColour = '#fcfc00'
const sonarGridFontStyle = 'bold 18px ' + FONT_FAMILY

const addSonarGrid = (
    sonarImageUrl: string,
    sonarInfo: SonarInfo
): Promise<string> => {
    const dummyImage = document.createElement('img')
    document.body.appendChild(dummyImage)
    dummyImage.setAttribute('style', 'display:none')
    dummyImage.setAttribute('alt', 'script div')
    dummyImage.setAttribute('src', sonarImageUrl)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')

    canvas.width = sonarInfo.width
    canvas.height = sonarInfo.height

    return new Promise(function (resolve, reject) {
        dummyImage.onload = function () {
            if (context) {
                context.drawImage(dummyImage, 0, 0, canvas.width, canvas.height)
                drawSonarOverlay(
                    sonarInfo,
                    canvas,
                    sonarGridLineWidth,
                    sonarGridLineColour,
                    sonarGridFontStyle,
                    false
                )
                resolve(canvas.toDataURL())
            } else reject('')
        }
        dummyImage.onerror = () => reject('')
    })
}

const createDOCX = async (
    storage: string,
    markers: MarkerType[],
    models: ModelType[],
    usingSVY21: boolean,
    images: PDFReportImageType[],
    modelImages: PDFReportImageType[],
    hashtagMap: Map<string, Hashtag[]>,
    clientName: string,
    objective: string,
    isPremium: boolean,
    clientLogoFile?: File
) => {
    //Load images
    let sonarImages: (ImageDetail | null)[] = []
    let frontCamImages: (ImageDetail | null)[] = []
    let mosaicImages: (ImageDetail | null)[] = []
    console.log('test: ', images, markers)
    for (let i = 0; i < images.length; i++) {
        if (images[i].url) {
            if (images[i].img_type === 'sonar') {
                const imgDims = await getImageDims(images[i])
                const url = await addSonarGrid(
                    images[i].url,
                    markers[images[i].idx].sonar_info
                )
                const imageDetail: ImageDetail = {
                    originalDims: imgDims,
                    bytes: await fetch(url).then((res) => res.arrayBuffer()),
                }
                sonarImages.push(imageDetail)
            } else if (images[i].img_type === 'front_cam') {
                const imageDetail: ImageDetail = {
                    originalDims: await getImageDims(images[i]),
                    bytes: await (await fetch(images[i].url)).arrayBuffer(),
                }
                frontCamImages.push(imageDetail)
            }
        } else {
            if (images[i].img_type === 'sonar') {
                sonarImages.push(null)
            } else if (images[i].img_type === 'front_cam') {
                frontCamImages.push(null)
            }
        }
    }
    for (let i = 0; i < modelImages.length; i++) {
        if (modelImages[i].img_type === 'mosaic') {
            console.log(modelImages[i])
            const imageDetail: ImageDetail = {
                originalDims: await getImageDims(modelImages[i], 'png'),
                bytes: await (await fetch(modelImages[i].url)).arrayBuffer(),
            }
            mosaicImages.push(imageDetail)
        }
    }
    const mosaicList = models.filter((value) => value.id == 'mosaic')

    const warningIconDims = await getImageDims(warningIconUrl)
    const warningIconBytes = await fetch(warningIconUrl).then((res) =>
        res.arrayBuffer()
    )
    const warningIconImage = await getImageRun(
        warningIconBytes,
        warningIconDims.scale(0.2 * PDF_TO_WORD_FACTOR)
    )

    const allSections = []
    // Section is equivalent to Page in PDFUtils

    if (clientName !== '') allSections.push(createCoverSection(clientName))
    // Cover section is cover page

    let totalNumOfPages = markers.length + mosaicList.length
    let upcomingPageNum = 1

    const sectionHeader = await getHeader(clientLogoFile)
    const sectionFooter = await getFooter(!isPremium) // To use default page number
    if (objective !== '') {
        totalNumOfPages++
        const introSection = createIntroSection(
            objective,
            sectionHeader,
            await getFooter(!isPremium, upcomingPageNum++, totalNumOfPages)
            // Custom page number text
        )
        allSections.push(introSection)
        // Intro section is intro page
    }
    for (let i = 0; i < mosaicList.length; i++) {
        const markerSection = await createModelSection(
            storage,
            hashtagMap,
            mosaicList[i],
            mosaicImages[i],
            usingSVY21,
            sectionHeader,
            await getFooter(!isPremium, upcomingPageNum++, totalNumOfPages),
            // Custom page number text
            warningIconImage
        )
        allSections.push(markerSection)
    }

    for (let i = 0; i < markers.length; i++) {
        const markerSection = await createMarkerSection(
            storage,
            hashtagMap,
            markers[i],
            frontCamImages[i],
            sonarImages[i],
            usingSVY21,
            sectionHeader,
            await getFooter(!isPremium, upcomingPageNum++, totalNumOfPages),
            // Custom page number text
            warningIconImage
        )
        allSections.push(markerSection)
    }

    let docOptions: IPropertiesOptions = {
        creator: 'BeeX',
        title: `${clientName || 'BeeX'} Mission Report`,
        sections: allSections,
    }
    if (objective !== '')
        docOptions = {
            ...docOptions,
            description: objective,
        }
    const doc = new Document(docOptions)

    const blob = await Packer.toBlob(doc)
    const fileName = generateFileName('docx')
    saveAs(blob, fileName)
}

export default createDOCX

// ------------------ COVER SECTION ------------------

const createCoverSection = (clientName: string) =>
    ({
        properties: coverSectionPropertiesOption,
        children: [
            new Table({
                rows: [
                    new TableRow({
                        height: {
                            value:
                                convertMillimetersToTwip(contentHeightInMm) +
                                coverSectionTitleHeightOffset,
                            rule: HeightRule.EXACT,
                        },
                        children: [
                            wrapTextInInvisibleCell(
                                clientName,
                                AlignmentType.CENTER,
                                FontSizes.coverTitle,
                                VerticalAlign.CENTER
                            ),
                        ],
                    }),
                ],
                width: { size: 100, type: 'pct' },
                layout: TableLayoutType.FIXED,
                alignment: AlignmentType.CENTER,
            }),
        ],
    } as ISectionOptions)

// --------------------- HEADER ---------------------
// Used in intro section & marker section

const getHeader = async (clientLogoFile: File | undefined) => {
    if (!clientLogoFile) return undefined
    const originalLogoDims = await getImageDims(clientLogoFile)
    const factor = maxClientLogoHeight / originalLogoDims.height
    const logoDims = originalLogoDims.scale(factor)
    const logoImageBytes = await clientLogoFile.arrayBuffer()
    const logoImage = getImageRun(
        logoImageBytes,
        logoDims,
        headerFloatingOptions
    )
    const header = new Header({ children: [wrapInParagraph(logoImage)] })
    return { default: header }
}

// --------------------- FOOTER ---------------------
// Used in intro section & marker section

const getFooter = async (
    hasWaterMarkAndMMPLogo: boolean,
    currPageNumber?: number,
    totalPageNum?: number
) => {
    const pageNumTextRun =
        currPageNumber && totalPageNum
            ? new TextRun({
                  // Custom page number text
                  text: `${currPageNumber}/${totalPageNum}`,
                  bold: true,
                  font: FONT_FAMILY,
                  size: FontSizes.pageNo,
              })
            : new TextRun({
                  children: [PageNumber.CURRENT, '/', PageNumber.TOTAL_PAGES],
              })
    // Built-in page number text

    const elementsInFooter = [pageNumTextRun]
    // if (hasWaterMarkAndMMPLogo)
    //     elementsInFooter.push(await getMMPLogo(), await getWaterMarkImage())

    const footer = new Footer({
        children: [
            new Paragraph({
                alignment: AlignmentType.CENTER,
                children: elementsInFooter,
            }),
        ],
    })
    return { default: footer }
}

const getMMPLogo = async () => {
    const originalLogoDims = await getImageDims(footerLogoUrl)
    const logoDims = originalLogoDims.scale(footerLogoScale)
    const logoImageBytes = await fetch(footerLogoUrl).then((res) =>
        res.arrayBuffer()
    )
    return getImageRun(logoImageBytes, logoDims, footerFLoatingOptions)
}

const getWaterMarkImage = async () => {
    const getCroppedWatermarkDims = () => {
        const width =
            croppedWaterMarkBottomRightCorner.x -
            croppedWaterMarkTopLeftCorner.x
        const height =
            croppedWaterMarkBottomRightCorner.y -
            croppedWaterMarkTopLeftCorner.y
        return new ImgDims(width, height)
    }

    const getCroppedWaterMarkBytes = async () => {
        const width =
            croppedWaterMarkBottomRightCorner.x -
            croppedWaterMarkTopLeftCorner.x
        const height =
            croppedWaterMarkBottomRightCorner.y -
            croppedWaterMarkTopLeftCorner.y
        const imageBytes = await cropNGetBuffer(
            watermarkUrl,
            croppedWaterMarkTopLeftCorner.x,
            croppedWaterMarkTopLeftCorner.y,
            width,
            height
        )
        return imageBytes
    }

    const cropNGetBuffer = (
        url: string,
        x: number,
        y: number,
        width: number,
        height: number
    ) =>
        Jimp.read(url)
            .then((image) =>
                image.crop(x, y, width, height).getBufferAsync(Jimp.MIME_PNG)
            )
            .catch((err) => {
                console.error('ERROR', err)
                return new ArrayBuffer(0)
            })

    const originalLogoDims = getCroppedWatermarkDims()
    const logoDims = originalLogoDims.scale(watermarkScale)
    const logoImageBytes = await getCroppedWaterMarkBytes()
    return getImageRun(logoImageBytes, logoDims, watermarkFLoatingOptions)
}

// --------------------- INTRO SECTION ---------------------

const createIntroSection = (
    objective: string,
    header: { default: Header } | undefined,
    footer: { default: Footer }
) => {
    const paragraphs = objective
        .split(/\r?\n/)
        .map((paragraphText) => [
            dummyParagraph,
            wrapInParagraph(paragraphText, false),
        ])
        .flat()
    return {
        headers: header,
        footers: footer,
        properties: introSectionPropertiesOption,
        children: [
            wrapInParagraph(introSectionHeading, false, FontSizes.title, true),
            ...paragraphs,
        ],
    } as ISectionOptions
}

// --------------------- HELPER FUNCTIONS FOR IMAGE ---------------------

async function getImageDims(
    src: string | File | PDFReportImageType,
    type?: string
) {
    const dummyDoc = await PDFDocument.create()
    let image: PDFImage | null = null
    if (typeof src === 'string') {
        const imageBytes = await fetch(src).then((res) => res.arrayBuffer())
        image = await dummyDoc.embedPng(imageBytes)
    } else if (src instanceof File) {
        const imageBytes = await src.arrayBuffer()
        if (src.type === 'image/png') {
            image = await dummyDoc.embedPng(imageBytes)
        } else if (src.type === 'image/jpeg') {
            image = await dummyDoc.embedJpg(imageBytes)
        }
    } else {
        const imageBytes = await fetch(src.url).then((res) => res.arrayBuffer())
        if (type == 'png') {
            image = await dummyDoc.embedPng(imageBytes)
        } else {
            image = await dummyDoc.embedJpg(imageBytes)
        }
    }
    return new ImgDims(image!.width, image!.height)
}

// --------------------- HELPER FUNCTIONS FOR PARAGRAPH ---------------------
