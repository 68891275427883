import React, { useEffect, useState, useRef } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import QrCodeWithLogo from 'qrcode-with-logos'
import LOGO from '../assets/images/logo.png'

import { REDIRECT_URI } from '../backend/msal/config'

const QrCodePage = ({location}: RouteComponentProps) => {
  const { accounts } = useMsal()
  const history = useHistory()
  const [url, setUrl] = useState('')
  const imgRef = useRef<HTMLImageElement>(null)
  const signJWT = async (subject: string, expire: string) => {
    const res = await fetch(`/demo/getJWT?subject=${subject}&expire=${expire}&username=${accounts[0].username}`)
    const token = await res.text()
    if (!res.ok) {
      history.push('/')
    } else {
      const urlString = `${REDIRECT_URI}token?=${token}`
      setUrl(urlString)
      new QrCodeWithLogo({
        content: urlString,
        width: 740,
        image: imgRef.current!,
        logo: {
          src: LOGO,
        }
      }).toImage();
    }
  }
  useEffect(() => {
    const array = location.search.split('?=')[1].split('&')
    signJWT(array[0], array[1])
  }, [])
  return (
    <div className='div-flex-center-full '>
      <a href={url} style={{ width: '50%', height: '50%' }}><img ref={imgRef} style={{width: '100%', height: '100%', objectFit: 'contain'}} /></a>
    </div>
  )
}

export default QrCodePage
