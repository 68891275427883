import React from 'react'

/**
 * Ghost Panel
 * Use this if you require a click anywhere to close function
 */

type GhostPanelProps = {
  onClick: () => void
}

const GhostPanel = ({ onClick }: GhostPanelProps) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
      }}
      onClick={() => onClick()}
    />
  )
}

export default GhostPanel
