import * as THREE from 'three'
import { initColor } from '../map/utils/ThreeUtils'
import { Quality, SceneCoord } from '../../types'

export const XYZLoader = async (
    file: File,
    materialSize?: number,
    modelQuality?: Quality,
    setPercent?: (value: number) => void,
    currProportion?: number[] // curr, length
) => {
    const avg = {
      x: 0,
      y: 0,
    }
    const text = await file.text()
    const lines = text.split('\n')

    const vertices: number[] = []
    const colors: number[] = []
    const iterativePlus =
        modelQuality === Quality.HIGH
            ? 1
            : modelQuality === Quality.MEDIUM
            ? 2
            : 4
    let currPercentTenInterval = 100
    for (let i = 0; i < lines.length; i += iterativePlus) {
        const percentage = Math.ceil((i * 100) / lines.length / 10) * 10
        if (
            currPercentTenInterval != percentage &&
            currProportion &&
            currProportion.length == 2
        ) {
            currPercentTenInterval = percentage
            const totalPercentage =
                currPercentTenInterval * (1 / currProportion[1]) +
                (currProportion[0] / currProportion[1]) * 100
            setPercent && (await setPercent(totalPercentage))
        }

        let line = lines[i]
        line = line.trim()

        if (
            line.charAt(0) === '#' ||
            line.charAt(0) === 'x' ||
            line.charAt(0) === '/'
        )
            continue // skip comments and header

        const lineValues = line.split(/[,\s]+/)

    if (lineValues.length >= 3) {
      // XYZ Confidences
      const rawX = parseFloat(lineValues[0])
      const rawY = parseFloat(lineValues[1])
      const rawXInKm = rawX / 1000
      const rawYInKm = rawY / 1000
      const x = rawX + getXOffset(rawXInKm, rawYInKm)
      const y = rawY + getYOffset(rawXInKm, rawYInKm)
      avg.x += x
      avg.y += y
      vertices.push(x, y, parseFloat(lineValues[2]))
      if (lineValues.length >= 6) {
        const color = new THREE.Color(
          `rgb(${lineValues[3]}, ${lineValues[4]}, ${lineValues[5]})`
        )
        colors.push(color.r, color.g, color.b)
      }
    }
  }

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(vertices, 3)
    )

    if (colors.length > 0) {
        // color array is edited
        geometry.setAttribute(
            'color',
            new THREE.Float32BufferAttribute(colors, 3)
        )
    } else {
        initColor(null, geometry)
    }

    const material = new THREE.PointsMaterial({
        size: materialSize || 0.2,
        vertexColors: true, // always true as ColorMap requires vertexColors to be true
        // color: new THREE.Color(1, 1, 1),
    })
    // console.log(material.vertexColors)

    const comment = (await file.text()).split('\n')[0]
    const array = comment.split(' ')
    if (!array[0].includes('UTM')) {
        alert('XYZ does not have UTM coordinates')
    }

    let mcd = 0
    const mcdComment = (await file.text()).split('\n')[1]
    const mcdArray = mcdComment.split(' ')
    if (mcdArray[0].includes('MCD')) {
        mcd = Number(mcdArray[1])
    }

    const utm = {
        northing: Number(array[1].slice(0, -1)),
        easting: Number(array[2].slice(0, -1)),
        zone_number: Number(array[3].slice(0, -1)),
        zone_letter: array[3].slice(-1),
    }
  const firstPointCoordArr = vertices.slice(0, 2)
  
  return {
    firstPoint: {x: firstPointCoordArr[0], y: firstPointCoordArr[1]},
    center: avg,
    object: new THREE.Points(geometry, material),
    originUTM: utm,
    mcd: mcd,
    colors: colors,
  }
}

let DEG_OF_FREEDOM: number = 4
// DEG_OF_FREEDOM = 2
// DEG_OF_FREEDOM = 3
// DEG_OF_FREEDOM = 5
// DEG_OF_FREEDOM = 6

function getPolynomial(coeffs: number[], x: number) {
  let sum = 0
  coeffs.forEach((coeff, i) => sum += coeff * (x ** i))
  return sum
}

function getXOffset(x: number, y: number) {
  let fromY, fromX
  switch (DEG_OF_FREEDOM) {
    case 2:
      fromY = getPolynomial([-0.065446656860962626, -0.51929783579245259, -0.0017887259765312991], y)
      fromX = getPolynomial([-0.065450602245411105, 0.10900370294585071, 0.0018040860753748204], x)
      break
    case 3:
      fromY = getPolynomial([
        -0.065446656860962626,
        -0.51936155457591260,
        -0.0017887259765312991,
        1.0514997765596607e-8
      ], y)
      fromX = getPolynomial([
        -0.065450602245411105,
        0.13388384956849586,
        0.0018040860753748204,
        -4.1057702601810557e-6
      ], x)
      break
    case 5:
      fromY = getPolynomial([
        -0.065288537380782399,
        -0.51936155833434139,
        -0.0017888825485594417,
        1.0516734648652577e-8,
        1.8087370195541748e-11,
        -1.5479474678151861e-16
      ], y)
      fromX = getPolynomial([
          -0.065288532775065175,
          0.13388323523947754,
          0.0018039255920134841,
          -4.1054863602513337e-6,
          1.8539211640470143e-11,
          -2.5301771228400851e-14
      ], x)
      break
    case 6:
      fromY = getPolynomial([
          -0.065288533274351412,
          -0.51936155833434139,
          -0.0017888825571001029,
          1.0516734648652577e-8,
          1.8089907695634991e-11,
          -1.5479474678151863e-16,
          -1.8428350460944725e-19
      ], y)
      fromX = getPolynomial([
          -0.065288533217972650,
          0.13388323523947754,
          0.0018039255929346546,
          -4.1054863602513337e-6,
          1.8538937953226913e-11,
          -2.5301771228400851e-14,
          1.9876272904791863e-20
      ], x)
      break
    default:
      // Chosen 4 as the default degree of freedom
      if (DEG_OF_FREEDOM !== 4) console.error('Invalid DEG_OF_FREEDOM value. Defaulting to 4.')
      fromY = getPolynomial([
        -0.065288537380782399,
        -0.51936155457591260,
        -0.0017888825485594417,
        1.0514997765596607e-8,
        1.8087370195541748e-11
      ], y)
      fromX = getPolynomial([
        -0.065288532775065175,
        0.13388384956849586,
        0.0018039255920134841,
        -4.1057702601810557e-6,
        1.8539211640470143e-11
      ], x)
  }
  return fromY + fromX
}

function getYOffset(x: number, y: number) {
  let fromY, fromX
  switch (DEG_OF_FREEDOM) {
    case 2:
      fromY = getPolynomial([0.00017555640053057431, 6.8467757881078422, 0.0017372988456235325], y)
      fromX = getPolynomial([0.00016502109374005869, 0.52292135312093191, -0.0017850517077152906], x)
      break
    case 3:
      fromY = getPolynomial([
        0.00017555640053057431,
        6.8226005622213526,
        0.0017372988456235325,
        3.9894428671720824e-6
      ], y)
      fromX = getPolynomial([
        0.00016502109374005869,
        0.52285876229762618,
        -0.0017850517077152906,
        1.0328859583766641e-8
      ], x)
      break
    case 5:
      fromY = getPolynomial([
        2.5384285719349577e-5,
        6.8225999882191752,
        0.0017374475480639888,
        3.9897081308547373e-6,
        -1.7178266906405907e-11,
        -2.3640868880503882e-14
      ], y)
      fromX = getPolynomial([
        2.5556277165014417e-5,
        0.52285859462720530,
        -0.0017849136077850302,
        1.0406345131867098e-8,
        -1.5953453450333776e-11,
        -6.9056783968322905e-15
      ], x)
      break
    case 6:
      fromY = getPolynomial([
        2.5380759106215676e-5,
        6.8225999882191752,
        0.0017374475553987299,
        3.9897081308547373e-6,
        -1.7180446117793335e-11,
        -2.3640868880503882e-14,
        1.5826313182395291e-19
      ], y)
      fromX = getPolynomial([
        2.5380495570772391e-5,
        0.52285859462720530,
        -0.0017849132421899336,
        1.0406345131867098e-8,
        -1.6062074735779064e-11,
        -6.9056783968322889e-15,
        7.8885164222636354e-18
      ], x)
      break
    default:
      // Chosen 4 as the default degree of freedom
      if (DEG_OF_FREEDOM !== 4) console.error('Invalid DEG_OF_FREEDOM value. Defaulting to 4.')
      fromY = getPolynomial([
        2.5384285719349587e-5,
        6.8226005622213526,
        0.0017374475480639888,
        3.9894428671720824e-6,
        -1.7178266906405907e-11
      ], y)
      fromX = getPolynomial([
        2.5556277165014420e-5,
        0.52285876229762618,
        -0.0017849136077850302,
        1.0328859583766641e-8,
        -1.5953453450333776e-11
      ], x)
  }
  return fromY + fromX
}

export const processXYZFile = async (file: File) => {
    const mcdComment = (await file.text()).split('\n')[1]
    const mcdArray = mcdComment.split(' ')
    let mcd = 0
    if (mcdArray[0].includes('MCD')) {
        mcd = Number(mcdArray[1])
    }

    const utmComment = (await file.text()).split('\n')[0]
    const utmArray = utmComment.split(' ')
    if (!utmArray[0].includes('UTM')) {
        alert('XYZ does not have UTM coordinates')
        return { utm: null, mcd: mcd }
    }

    const utm = {
        northing: Number(utmArray[1].slice(0, -1)),
        easting: Number(utmArray[2].slice(0, -1)),
        zone_number: Number(utmArray[3].slice(0, -1)),
        zone_letter: utmArray[3].slice(-1),
    }
    return { utm: utm, mcd: mcd }
}

const getRawAverage = async (file: File, modelQuality?: Quality) => {
    const text = await file.text()
    const lines = text.split('\n')
    const iterativePlus =
        modelQuality === Quality.HIGH
            ? 1
            : modelQuality === Quality.MEDIUM
            ? 2
            : 4
    let avg = {
        x: 0,
        y: 0,
        z: 0,
    }
    let numOfPoints = 0
    for (let i = 0; i < lines.length; i += iterativePlus) {
        const line = lines[i].trim()
        if (
            line.charAt(0) === '#' ||
            line.charAt(0) === 'x' ||
            line.charAt(0) === '/'
        )
            continue // skip comments and header
        const lineValues = line.split(/[,\s]+/)
        if (lineValues.length >= 3) {
            avg.x += parseFloat(lineValues[0])
            avg.y += parseFloat(lineValues[1])
            avg.z += parseFloat(lineValues[2])
            numOfPoints++
        }
    }
    avg.x /= numOfPoints
    avg.y /= numOfPoints
    avg.z /= numOfPoints
    return avg
}
