import { toLatLon } from 'utm'
import {
    MarkerType,
    ModelType,
    timestampToDate,
    timestampToHMS,
} from '../../types'
import { SVY21, svy21NumOfDP } from '../map/utils/CoordHelper/svy21'
import { roundValue } from './MathUtils'

export const generateFileName = (fileExt = 'pdf') => {
    const addZeroPadding = (num: number) => '' + (num < 10 ? '0' : '') + num

    const currentdate = new Date()
    const datetime =
        currentdate.getDate() +
        '-' +
        (currentdate.getMonth() + 1) +
        '-' +
        currentdate.getFullYear() +
        '_' +
        addZeroPadding(currentdate.getHours()) +
        addZeroPadding(currentdate.getMinutes())
    return datetime + '_report.' + fileExt
}

export interface Coordinates {
    x: number
    y: number
}

export const getMarkerDateNTime = (marker: MarkerType) => {
    const date =
        marker.timestamp !== null
            ? 'Date: ' + timestampToDate(marker.timestamp)
            : 'Date: -'
    const time =
        marker.timestamp !== null
            ? 'Time: ' + timestampToHMS(marker.timestamp)
            : 'Time: -'
    return { date, time }
}

// Get all values of the coordinates, heading, depth & altitude
// But DO NOT get the value of cp_probe
export const getDataBoxLeftColVals = (
    marker: MarkerType,
    usingSVY21: boolean
) => {
    // If usingSVY21 is true, 'SVY21' will be added to the start of the array
    const values = usingSVY21 ? ['SVY21'] : []

    const latLon =
        marker.origin !== null && marker.position !== null
            ? toLatLon(
                  marker.origin.easting + marker.position.y,
                  marker.origin.northing + marker.position.x,
                  48,
                  'N',
                  undefined,
                  false
              )
            : { latitude: 0, longitude: 0 }

    const cv = new SVY21()
    const isValidSVY21 = cv.checkValidSVY21(latLon.latitude, latLon.longitude)
    let svy21Values: { N: number; E: number }
    if (usingSVY21)
        svy21Values = cv.computeSVY21(latLon.latitude, latLon.longitude)
    const latNorthingVal = usingSVY21
        ? isValidSVY21
            ? roundValue(svy21Values!.N, svy21NumOfDP) + 'm'
            : 'invalid'
        : roundValue(latLon.latitude, 6) + '\xB0'
    const latNorthing = marker.origin !== null ? latNorthingVal : '-'
    const lonEastingVal = usingSVY21
        ? isValidSVY21
            ? roundValue(svy21Values!.E, svy21NumOfDP) + 'm'
            : 'invalid'
        : roundValue(latLon.longitude, 6) + '\xB0'
    const lonEasting = marker.origin !== null ? lonEastingVal : '-'

    const heading =
        marker.heading !== null ? Math.round(marker.heading) + '\xB0' : '-'
    const depth =
        marker.position !== null ? roundValue(marker.position.z, 2) + 'm' : '-'
    const altitude =
        marker.altitude !== null ? roundValue(marker.altitude, 2) + 'm' : '-'

    values.push(latNorthing, lonEasting, heading, depth, altitude)
    return values
}

export const getDataBoxLeftColValsModel = (
    model: ModelType,
    usingSVY21: boolean
) => {
    // If usingSVY21 is true, 'SVY21' will be added to the start of the array
    if (
        !model.northing ||
        !model.easting ||
        !model.zone_letter ||
        !model.zone_number
    ) {
        return []
    }
    const values = usingSVY21 ? ['SVY21'] : []
    const latLon = toLatLon(
        model.easting,
        model.northing,
        model.zone_number,
        model.zone_letter,
        undefined,
        false
    )

    const cv = new SVY21()
    const isValidSVY21 = cv.checkValidSVY21(latLon.latitude, latLon.longitude)
    let svy21Values: { N: number; E: number }
    if (usingSVY21)
        svy21Values = cv.computeSVY21(latLon.latitude, latLon.longitude)
    const latNorthingVal = usingSVY21
        ? isValidSVY21
            ? roundValue(svy21Values!.N, svy21NumOfDP) + 'm'
            : 'invalid'
        : roundValue(latLon.latitude, 6) + '\xB0'
    const lonEastingVal = usingSVY21
        ? isValidSVY21
            ? roundValue(svy21Values!.E, svy21NumOfDP) + 'm'
            : 'invalid'
        : roundValue(latLon.longitude, 6) + '\xB0'

    values.push(latNorthingVal, lonEastingVal)
    return values
}
