import * as THREE from 'three'
import {
  CSS2DObject,
  CSS2DRenderer,
} from 'three/examples/jsm/renderers/CSS2DRenderer'

/** List of available functions
 *  getPECLabel
 *  getLabel
 */

export function getBoatLabel(): CSS2DObject {
  return getLabel(
    'Boat',
    `rounded-sm padding-sm margin-l-md text-medium`,
    '0 0 0 42px'
  )
}

export function getPECLabel(
  depth: number,
  altitude: number,
  thickness: number,
  maxThickness: number,
  onClick: () => void,
  onHover: (value: boolean) => void
): CSS2DObject {
  const percent = (thickness / maxThickness) * 100
  const color =
    percent < 50
      ? 'error-container error'
      : percent < 80
      ? 'warning-container warning'
      : percent <= 90
      ? 'primary-bg on-primary-bg'
      : 'success on-success'
  // return getLabel(
  //   `[D: ${depth} | A: ${altitude}] ${thickness}`,
  //   `rounded-sm padding-sm margin-l-md text-medium cursor-pointer ${color}`,
  //   undefined,
  //   onClick
  // )
  return getLabel(
    `${thickness}`,
    `rounded-sm padding-sm margin-l-md text-medium cursor-pointer outline-border-hover ${color}`,
    undefined,
    'pec',
    onClick,
    onHover
  )
}

export function getLabel(
  content: string,
  className: string,
  margin?: string,
  name?: string,
  onClick?: () => void,
  onHover?: (value: boolean) => void
): CSS2DObject {
  const div = document.createElement('div')
  div.className = className
  div.innerHTML = content
  if (margin) div.style.margin = margin
  div.addEventListener('pointerdown', () => onClick && onClick())
  // div.addEventListener('mouseover', () => onHover && onHover(true))
  // div.addEventListener('mouseout', (e) => {
  //   if (e.relatedTarget) onHover && onHover(false)
  // })
  return new CSS2DObject(div)
}
