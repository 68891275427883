import { useAccount, useMsal } from '@azure/msal-react'
import React, { useEffect, useRef, useState } from 'react'
import {
    UTMCoord,
    MouseState,
    ColorMap,
    ModelType,
    MapMode,
    CameraMode,
    Dimension,
    LatLngCoord,
    Quality,
} from '../../types'
import IconButton from '../elements/buttons/IconButton'
import LoadingSpinner from '../elements/loadingSpinner'
import MapToolbar from '../map/MapToolbar'
import PCDViewer from './pcdViewer'
import BackIcon from '../../assets/icons/arrow_back_black_24dp.svg'
import { processXYZFile } from './CustomXYZLoader'
import { saveAs } from 'file-saver'
import MapDialog from '../map/MapDialog'
import Map from '../map/Map'
import ProgressBar from '../ProgressBar'

const PointCloudPage = () => {
    const { instance, accounts } = useMsal()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState<File | null>(null)
    const [origin, setOrigin] = useState<UTMCoord | null>(null)
    const [mcd, setMCD] = useState<number>(0)

    // Map Toolbar
    const [mapHeading, setMapHeading] = useState<number>(0)
    const [colorMap, setColorMap] = useState<ColorMap>(ColorMap.NONE)
    const [mouseState, setMouseState] = useState<MouseState>(MouseState.DEFAULT)
    const [showMap, setShowMap] = useState(true)
    const [showModels, setShowModels] = useState(true)
    const [modelsOffset, setModelsOffset] = useState([0, 0, 0])
    const [materialSize, setMaterialSize] = useState<number>(0.5) // default materialSize
    const [dimension, setDimension] = useState<Dimension>(Dimension.TWOD)
    const [modelQuality, setModelQuality] = useState<Quality>(
        navigator.hardwareConcurrency > 16
            ? Quality.HIGH
            : navigator.hardwareConcurrency > 8
            ? Quality.MEDIUM
            : Quality.LOW
    )

    // Map Dialog
    const [showDialog, setShowDialog] = useState<boolean>(true)
    const [dialogText, setDialogText] = useState<string>('')

    // Map Initialization
    const [models, setModels] = useState<ModelType[]>([])
    const [modelFileMap, setModelFileMap] = useState<Record<string, Blob>>({})
    const [clearTrail, setClearTrail] = useState<boolean>(true)
    const [zoom, setZoom] = useState<number>(0)
    const [location, setLocation] = useState<LatLngCoord | null>(null)
    const [loadingMap, setLoadingMap] = useState<boolean>(false)

    // Map Objects
    const [kmlFile, setKMLFile] = useState<File | null>(null)

    // Progress Bar
    const [progressPercent, setProgressPercent] = useState<number>(0)

    const setPercentForProgress = async (
        percentageUpdate: number | ((currPercent: number) => number)
    ) => {
        const pause = () => {
            return new Promise((r) => setTimeout(r, 0))
        }
        await pause() // gives UI enough time to update
        setProgressPercent(percentageUpdate)
    }

    const saveNewOrigin = async (offsetValues: number[]) => {
        if (!offsetValues || offsetValues.length != 3) return

        const text = await file?.text()
        const lines = text?.split('\n') || []
        const updatedLines: string[] = []
        let hasMCD = false

        for (let line of lines) {
            const lineValues = line.split(/\s+/)
            if (lineValues[0] === '#UTM') {
                const northing = +lineValues[1].slice(0, -1) + offsetValues[1] // add to y value
                const easting = +lineValues[2].slice(0, -1) + offsetValues[0] // add to x value

                updatedLines.push(
                    `#UTM ${northing}N ${easting}E ${lineValues[3]}`
                )
            } else if (lineValues[0] === '#MCD') {
                hasMCD = true
                const mcd = +lineValues[1] + offsetValues[2] // add to z value
                updatedLines.push(`#MCD ${mcd}`)
            } else {
                updatedLines.push(line)
            }
        }

        // insert MCD if has offset
        if (!hasMCD && offsetValues[2] != 0) {
            updatedLines.splice(1, 0, `#MCD ${offsetValues[2]}`)
        }

        const type = 'application/octet-stream'
        const updatedBlob = new Blob([updatedLines.join('\n')], {
            type: type,
        })

        saveAs(updatedBlob, file?.name)
    }

    const setDefaultSettings = async () => {
        setMaterialSize(0.5)
        setModelsOffset([0, 0, 0])
    }

    const processFile = async (file: File) => {
        try {
            setLoading(true)
            const fileType = file.name.split('.').slice(-1)[0] // final segment to get file type in file name
            if (fileType === 'xyz') {
                const { utm: utm, mcd: newMCD } =
                    (await processXYZFile(file)) || {}
                if (!utm) {
                    alert(
                        'XYZ file is corrupted. Please upload another xyz file!'
                    )
                } else {
                    // Initialise file here
                    setOrigin(utm)
                    setFile(file)
                    setMCD(newMCD)
                    const newModel: ModelType = {
                        id: 'xyz',
                        asset: 'NIL',
                        file: file.name,
                        depth: 0,
                        comment: '',
                        heading: 0,
                        northing: utm.northing,
                        easting: utm.easting,
                        zone_letter: utm.zone_letter,
                        zone_number: utm.zone_number,
                    }
                    setModels([newModel])
                    modelFileMap[newModel.file] = file
                    setModelFileMap(modelFileMap)
                }
            } else {
                alert('Invalid file type. Only .xyz is supported')
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            alert('Opps. File seems to be corrupted.')
        }
    }
    const dropHandler = (e: React.DragEvent) => {
        e.preventDefault()
        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            if (e.dataTransfer.items.length > 1) {
                alert('Please only choose 1 file.')
                return
            }
            if (e.dataTransfer.items[0].kind === 'file') {
                var file = e.dataTransfer.items[0].getAsFile()
                if (file) {
                    processFile(file)
                } else {
                    alert('File does not exist.')
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            if (e.dataTransfer.files.length > 1) {
                alert('Please only choose 1 file.')
                return
            } else {
                processFile(e.dataTransfer.files[0])
            }
        }
    }
    const handleOpenFileDialog = () => {
        fileInputRef.current?.click()
    }
    const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target) return
        if (!e.target.files || e.target.files.length === 0) {
            alert('Please choose a file.')
            return
        } else if (e.target.files.length > 1) {
            alert('Please only choose 1 file.')
            return
        } else {
            processFile(e.target.files[0])
        }
    }

    const handleBack = async () => {
        setFile(null)
        setModelsOffset([0, 0, 0])
        setMaterialSize(0.5)
    }

    const doLoading = async (value: boolean) => {
        setLoading(value)
        console.log(loading)
    }

    useEffect(() => {
        console.log(loading)
    }, [loading])

    return (
        <div className='rel w-100 h-100'>
            {(loading || loadingMap) && (
                <div className='rel w-100 h-100'>
                    <div className='flex w-100 h-100 justify-center align-center'>
                        <ProgressBar
                            width={200}
                            height={20}
                            progressPercent={progressPercent}
                        />
                    </div>
                </div>
            )}
            {!file && !loading && (
                <div
                    className='rel w-100 h-100'
                    onDrop={dropHandler}
                    onDragOver={(e) => e.preventDefault()}>
                    {!loading && (
                        <div
                            className='flex w-100 h-100 justify-center align-center'
                            style={{
                                textAlign: 'center',
                                cursor: 'pointer',
                                flexDirection: 'column',
                            }}
                            onClick={() => handleOpenFileDialog()}>
                            <input
                                ref={fileInputRef}
                                type='file'
                                name='name'
                                style={{ display: 'none' }}
                                onChange={handleSelectFile}
                            />
                            <div className='h2'>
                                Click to select a .xyz file
                            </div>
                            <div className='h3' style={{ margin: '8px 0px' }}>
                                or
                            </div>
                            <div className='h2'>
                                Drag the file over here to view...
                            </div>
                            <div className='h4' style={{ marginTop: '30px' }}>
                                Note: This does <b>not</b> upload the data to
                                the cloud.
                            </div>
                        </div>
                    )}
                </div>
            )}
            {file && origin && !loading && (
                <div className='w-100 h-100 flex flex-col'>
                    <div
                        className='background flex align-center padding-l-lg'
                        style={{ height: '42px' }}>
                        <IconButton
                            Icon={BackIcon}
                            onClick={() => handleBack()}
                        />
                        <div>{file.name}</div>
                    </div>
                    <div className='rel flex-grow-1'>
                        {showDialog && (
                            <div className='flex rel w-100 h-100 align-self-end justify-center'>
                                <MapDialog
                                    onClose={() => setShowDialog(false)}
                                    dialogText={dialogText}
                                />
                            </div>
                        )}
                        <MapToolbar
                            colorMap={colorMap}
                            setColorMap={setColorMap}
                            showMap={showMap}
                            setShowMap={setShowMap}
                            showModels={showModels}
                            setShowModels={setShowModels}
                            mouseState={mouseState}
                            setMouseState={setMouseState}
                            modelsOffset={modelsOffset}
                            setModelsOffset={setModelsOffset}
                            materialSize={materialSize}
                            setMaterialSize={setMaterialSize}
                            setDefaultSettings={setDefaultSettings}
                            saveNewSettings={saveNewOrigin}
                            dimension={dimension}
                            setDimension={setDimension}
                            modelQuality={modelQuality}
                            setModelQuality={setModelQuality}
                            kmlFile={kmlFile}
                            setKMLFile={setKMLFile}
                        />
                        {/* <PCDViewer
              file={file}
              origin={origin}
              colorMap={colorMap}
              mouseState={mouseState}
              showMap={showMap}
              modelsOffset={modelsOffset}
              materialSize={materialSize}
              mcd={mcd}
              doLoading={doLoading}
            /> */}
                        <Map
                            instance={instance}
                            account={null}
                            location={location}
                            models={models}
                            mapMode={MapMode.MAP}
                            mouseState={mouseState}
                            cameraMode={CameraMode.STATIONARY}
                            dimension={dimension}
                            showTrail={true}
                            showSonar={true}
                            showMap={showMap}
                            showModels={showModels}
                            clearTrail={clearTrail}
                            zoom={zoom}
                            asset={''}
                            setClearTrail={() => setClearTrail(false)}
                            resetZoom={() => setZoom(0)}
                            setLocation={setLocation}
                            modelFileMap={modelFileMap}
                            materialSize={materialSize}
                            modelsOffset={modelsOffset}
                            kmlFile={kmlFile}
                            setPercent={setPercentForProgress}
                            mapHeading={mapHeading}
                            setMapHeading={setMapHeading}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default PointCloudPage
