import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    ImageRun,
    SectionType,
    IImageOptions,
    ISectionOptions,
    AlignmentType,
    Header,
    IFloating,
    HorizontalPositionAlign,
    Footer,
    PageNumber,
    ParagraphChild,
    Table,
    TableRow,
    TableCell,
    VerticalAlign,
    BorderStyle,
    IShadingAttributesProperties,
    convertMillimetersToTwip,
    ShadingType,
    ITableCellBorders,
    HeightRule,
    VerticalPositionRelativeFrom,
    IPageMarginAttributes,
    ISectionPropertiesOptions,
    TableLayoutType,
} from 'docx'
import { ITableCellMarginOptions } from 'docx/build/file/table/table-properties/table-cell-margin'
import {
    BORDER_NONE,
    FontSizes,
    FONT_FAMILY,
    CELL_NO_MARGIN_TOP_BOT,
    CELL_MARGIN_ALIGN_LEFT,
    CELL_MARGIN_ALIGN_RIGHT,
} from '../constants'

export const wrapElementInCell = (
    element: ParagraphChild,
    alignment: (typeof AlignmentType)[keyof typeof AlignmentType] | undefined,
    margins: ITableCellMarginOptions | undefined,
    shading: IShadingAttributesProperties,
    rowSpan = 1,
    colSpan = 1
) =>
    new TableCell({
        borders: BORDER_NONE,
        children: [
            new Paragraph({
                children: [element],
                alignment: alignment,
            }),
        ],
        margins: margins,
        shading: shading,
        rowSpan: rowSpan,
        columnSpan: colSpan,
    })

export const wrapTextInCell = (
    text: string | string[],
    bold: boolean,
    alignment: (typeof AlignmentType)[keyof typeof AlignmentType],
    verticalAlignment: (typeof VerticalAlign)[keyof typeof VerticalAlign],
    margins: ITableCellMarginOptions | undefined,
    shading: IShadingAttributesProperties,
    borderOptions: ITableCellBorders = BORDER_NONE,
    fontSize: FontSizes = FontSizes.text,
    rowSpan: number = 1
) => {
    const toParagraph = (text: string) =>
        new Paragraph({
            alignment: alignment,
            children: [
                new TextRun({
                    text: text,
                    size: fontSize,
                    font: FONT_FAMILY,
                    bold: bold,
                }),
            ],
        })
    const textArr = text instanceof Array ? text : [text]
    return new TableCell({
        margins: margins,
        verticalAlign: verticalAlignment,
        borders: borderOptions,
        rowSpan: rowSpan,
        shading: shading,
        children: textArr.map(toParagraph),
    })
}

export const wrapTextInInvisibleCell = (
    text: string,
    alignment: (typeof AlignmentType)[keyof typeof AlignmentType],
    fontSize: FontSizes = FontSizes.text,
    verticalAlign: (typeof VerticalAlign)[keyof typeof VerticalAlign] = VerticalAlign.BOTTOM
) => {
    let margins = CELL_NO_MARGIN_TOP_BOT
    if (alignment == AlignmentType.LEFT) margins = CELL_MARGIN_ALIGN_LEFT
    else if (alignment == AlignmentType.RIGHT) margins = CELL_MARGIN_ALIGN_RIGHT
    return wrapTextInCell(
        text,
        true,
        alignment,
        verticalAlign,
        margins,
        { type: ShadingType.NIL },
        BORDER_NONE,
        fontSize
    )
}
