import { useMsal } from '@azure/msal-react'
import React, { useState } from 'react'
import { isMobile } from '../../../utils/general'
import { FolderIcon } from '../../../../assets'
import LoadingSpinner from '../../../elements/loadingSpinner'
import StateLayer from '../../../elements/StateLayer'
import Dialog from '../../../elements/Dialogs/Dialog'
import { authorisedUsersToDelete } from '../../../../constants/environment'

type PanelProps = {
  list: string[] | null
  selectItem: (name: string) => void
  noStorage: boolean
  updateLogOnStorageAccess: (storageName: string) => void
  handleDeleteAsset?: (container: string) => void
}

const AssetPanel = ({
  list,
  selectItem,
  noStorage,
  updateLogOnStorageAccess,
  handleDeleteAsset,
}: PanelProps) => {
  const { accounts } = useMsal()
  const [notifyDeleteAsset, setNotifyDeleteAsset] = useState<boolean>(false)
  const [selectedDeleteAsset, setSelectedDeleteAsset] = useState<string>('')

  const mobile = isMobile()
  let prevAlphabet = ''
  const isSplitAlphabet = (value: string) => {
    const curr = value.slice(0, 1)
    if (curr !== prevAlphabet) {
      prevAlphabet = curr
      return true
    }
    return false
  }

  return (
    <>
      {notifyDeleteAsset && (
        <Dialog
          text={`Deleting asset ${selectedDeleteAsset}`}
          description={'Are you sure you want to delete?'}
          buttonText={'Delete'}
          onClick={() => {
            handleDeleteAsset ? handleDeleteAsset(selectedDeleteAsset) : null
            setNotifyDeleteAsset(false)
          }}
          onClose={() => setNotifyDeleteAsset(false)}
        />
      )}
      {list &&
        list.length > 0 &&
        list.map((value, idx) => {
          if (value === 'hashtags') return null
          return (
            <React.Fragment key={idx}>
              {isSplitAlphabet(value) && (
                <>
                  <div className={'break-row'} />
                  <div className={'h1 margin-t-xlg margin-l-xlg'}>
                    {value.slice(0, 1).toUpperCase()}
                  </div>
                  <div className={'break-row'} />
                </>
              )}
              <StateLayer
                style={{ width: mobile ? '100%' : '20%' }}
                className={`shadow margin-l-lg margin-t-lg rounded-md
                  ${mobile ? 'margin-r-lg' : ''}`}
                color={'surface'}
                hoverColor={'on-surface'}
                disabledColor={'surface-variant'}
                child={
                  <div className={'padding-lg padding-h-lg'}>
                    <FolderIcon width={24} height={24} />
                    <div className='margin-t-md'>{value.toString()}</div>
                  </div>
                }
                onClick={() => {
                  selectItem(value)
                  updateLogOnStorageAccess(value)
                }}
                canDelete={authorisedUsersToDelete.includes(accounts[0].username)}
                onCloseClick={() => {
                  setSelectedDeleteAsset(value)
                  setNotifyDeleteAsset(true)
                }}
              />
            </React.Fragment>
          )
        })}
      {list && list.length === 0 && (
        <div
          className={`flex w-100 h-100 justify-center align-content-center align-center`}>
          Opps, there is nothing here at the moment.
        </div>
      )}
      {!list && !noStorage && (
        <div
          className={`flex w-100 h-100 justify-center align-content-center align-center`}>
          <LoadingSpinner width={80} height={80} />
        </div>
      )}
      {!list && noStorage && (
        <div
          className={`flex w-100 h-100 justify-center align-content-center align-center`}>
          Sorry, you do not have access to any storage accounts. Contact BeeX
          for more information.
        </div>
      )}
      {
        // Padding at the bottom
        <div style={{ width: '100%', height: '36px' }} />
      }
    </>
  )
}

export default AssetPanel
