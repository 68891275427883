import React, { useEffect, useState } from 'react'
import { roundValue, processStrSeacp } from '../../../../utils'
import {
    MarkerType,
    timestampToDate,
    timestampToHMS,
} from '../../../../../types'
import { isMobile, processMarkerCoord } from '../../../../utils/general'
import { SVY21, svy21NumOfDP } from '../../../../map/utils/CoordHelper/svy21'
import { VDivider } from '../../../../elements/dividers'
import SegmentedButton from '../../../../elements/buttons/SegmentedButton'

type MarkerDetailsProps = {
    marker: MarkerType
}

const MarkerDetails = ({ marker }: MarkerDetailsProps) => {
    const [strSeacp, setStrSeacp] = useState<(number | null)[]>([])
    const [coord, setCoord] = useState<{
        latitude: number
        longitude: number
        northing: number
        easting: number
        hasValidSVY21: boolean
    } | null>(null)
    const [usingSVY21, setUsingSVY21] = useState<boolean>(false)
    const cv = new SVY21()
    useEffect(() => {
        if (marker.str_seacp) {
            const res = processStrSeacp(marker.str_seacp)
            if (res)
                setStrSeacp([res.contact, res.field_gradient, res.proximity])
        }
        if (marker.origin) {
            const { latitude, longitude } = processMarkerCoord(marker)
            const { N: northing, E: easting } = cv.computeSVY21(
                latitude,
                longitude
            )
            setCoord({
                latitude,
                longitude,
                northing,
                easting,
                hasValidSVY21: cv.checkValidSVY21(latitude, longitude),
            })
        }
    }, [marker])
    return (
        <div className={'flex-col allow-select'}>
            <div className='flex'>
                <div className='flex-grow-1 padding-r-sm'>
                    {marker.timestamp != null && (
                        <MarkerDetailsRow
                            desc={'Date'}
                            value={timestampToDate(marker.timestamp)}
                        />
                    )}
                    {marker.timestamp != null && (
                        <MarkerDetailsRow
                            desc={'Time'}
                            value={timestampToHMS(marker.timestamp)}
                        />
                    )}
                    {coord != null && (
                        <MarkerDetailsRow
                            desc={usingSVY21 ? 'Northing' : 'Latitude'}
                            value={String(
                                roundValue(
                                    usingSVY21
                                        ? coord.northing!
                                        : coord.latitude,
                                    usingSVY21 ? svy21NumOfDP : 6
                                )
                            )}
                        />
                    )}
                    {coord != null && (
                        <MarkerDetailsRow
                            desc={usingSVY21 ? 'Easting' : 'Longitude'}
                            value={String(
                                roundValue(
                                    usingSVY21
                                        ? coord.easting!
                                        : coord.longitude,
                                    usingSVY21 ? svy21NumOfDP : 6
                                )
                            )}
                        />
                    )}
                    {coord != null && coord.hasValidSVY21 && (
                        <div className='margin-t-xsm'>
                            <SegmentedButton
                                options={['Lat/Long', 'SVY21']}
                                selected={usingSVY21 ? 1 : 0}
                                onClick={(value) => setUsingSVY21(value === 1)}
                            />
                        </div>
                    )}
                </div>
                {isMobile() ? <div className='flex-break-row' /> : <VDivider />}
                <div className='flex-grow-1 padding-l-sm'>
                    {marker.heading != null && (
                        <MarkerDetailsRow
                            desc={'Heading'}
                            value={`${Math.round(marker.heading)}\u00B0`}
                        />
                    )}
                    {marker.position != null && (
                        <MarkerDetailsRow
                            desc={'Depth'}
                            value={`${roundValue(marker.position.z, 2)}m`}
                        />
                    )}
                    {marker.altitude != null && (
                        <MarkerDetailsRow
                            desc={'Altitude'}
                            value={`${roundValue(marker.altitude, 2)}m`}
                        />
                    )}
                    {marker.cp != null && (
                        <MarkerDetailsRow
                            desc={'CP'}
                            value={`${roundValue(marker.cp, 3)}V`}
                        />
                    )}
                    {marker.ut != null && (
                        <MarkerDetailsRow
                            desc={'UT'}
                            value={`${roundValue(marker.ut, 3)}mm`}
                        />
                    )}
                </div>
            </div>
            {strSeacp.length > 0 && <SeaCPDetails seacp={strSeacp} />}
            {marker.pec && <PECDetails pec={marker.pec} />}
            {marker.recording && <div className='text-sm padding-h-sm'>Taken from: {marker.recording}.beex</div>}
        </div>
    )
}

const MarkerDetailsRow = ({ desc, value }: { desc: string; value: string }) => {
    return (
        <div className='flex'>
            <div className='flex-grow-1 text-sm'>{desc}</div>
            <div className='flex-grow-1 text-bold'>{value}</div>
        </div>
    )
}

const MarkerDetailsCol = ({ desc, value }: { desc: string; value: string }) => {
    return (
        <div className='flex-col text-center flex-grow-1'>
            <div className='flex-grow-1 text-bold text-sm'>{desc}</div>
            <div className='flex-grow-1 text-bold'>{value}</div>
        </div>
    )
}

const SeaCPDetails = ({ seacp }: { seacp: (number | null)[] }) => {
    return (
        <div className={'w-100 allow-select flex-col'}>
            <div className='text-bold padding-h-sm text-sm'>CP Probe</div>
            <div className='flex rounded-sm padding-h-sm surface-container-bg on-surface'>
                <MarkerDetailsCol
                    desc={'Contact'}
                    value={`${seacp[0] ? Math.round(seacp[0]) + 'mV' : '-'}`}
                />
                <MarkerDetailsCol
                    desc={'Field Gradient'}
                    value={`${seacp[1] ? Math.round(seacp[1]) + 'mV' : '-'}`}
                />
                <MarkerDetailsCol
                    desc={'Proximity'}
                    value={`${seacp[2] ? Math.round(seacp[2]) + 'mV' : '-'}`}
                />
            </div>
        </div>
    )
}

const PECDetails = ({
    pec,
}: {
    pec: { thickness: number; calibration: number }
}) => {
    return (
        <div className={'allow-select flex-col'}>
            <div className='text-bold text-sm padding-h-sm '>PEC</div>
            <div className='flex rounded-sm padding-h-sm surface-container-bg on-surface'>
                <MarkerDetailsCol
                    desc={'Thickness'}
                    value={`${pec.thickness} mm`}
                />
                <MarkerDetailsCol
                    desc={'Calibration'}
                    value={`${pec.calibration || '-'} mm`}
                />
            </div>
        </div>
    )
}

export { MarkerDetails, MarkerDetailsRow }
