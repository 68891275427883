import React, { useState } from 'react'
import { ButtonProps } from './Button'
import IconButton from './IconButton'
import TextButton from './TextButton'

type VerticalExpandTextButtonProps = {
  value: number
  options: string[]
  autoReset?: boolean
  onClick: (value: number) => void
} & Omit<ButtonProps, 'onClick'>

const VerticalExpandTextButton = (props: VerticalExpandTextButtonProps) => {
  const [expand, setExpand] = useState(false)
  return (
    <div className='rel padding-w-sm'>
      {expand && (
        <div className='abs flex flex-col-rev rounded-lg surface-bg z-10 bottom-0'>
          {props.options.map((value, index) => (
            <TextButton
              minWidth
              key={index}
              text={value}
              onClick={() => {
                setExpand(false)
                props.onClick(index)
              }}
            />
          ))}
        </div>
      )}
      <TextButton
        {...props}
        minWidth
        text={props.options[props.value]}
        onClick={() => {
          setExpand(!expand)
          if (props.autoReset) props.onClick(0)
        }}
      />
    </div>
  )
}

export default VerticalExpandTextButton
