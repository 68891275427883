import React from 'react'

type SquareButtonProps = {
  Icon: SvgrComponent
  text: string
  selected?: boolean
  disabled?: boolean
  onClick: () => void
}

function SquareButton(props: SquareButtonProps): JSX.Element {
  const { text, Icon, selected, disabled, onClick } = props
  return (
    <div
      className={`flex flex-col padding-sm cursor-pointer ${
        disabled ? 'disabled' : ''
      }`}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}>
      <div
        className={`h-36px w-36px padding-md rounded-md margin-b-sm align-self-center ${
          selected ? 'primary-bg' : 'surface-variant-bg'
        }`}>
        <Icon className={`${selected ? 'on-primary' : ''}`} />
      </div>
      <div className={`text-sm ${selected ? 'primary' : ''}`}>{text}</div>
    </div>
  )
}

export default SquareButton
