import CloseIcon from './icons/close_black_24dp.svg'
import CheckCircleIcon from './icons/check_circle_black_24dp.svg'
import DatasetIcon from './icons/dataset_black_24dp.svg'
import DownloadIcon from './icons/file_download_black_24dp.svg'
import DoneIcon from './icons/done_black_24dp.svg'
import UncheckCircleIcon from './icons/radio_button_unchecked_black_24dp.svg'
import TickedCheckBoxIcon from './icons/check_box_black_24dp.svg'
import UnTickedCheckBoxIcon from './icons/check_box_outline_blank_black_24dp.svg'
import IndeterminateCheckBoxIcon from './icons/indeterminate_check_box_black_24dp.svg'
import FolderIcon from './icons/folder_black_24dp.svg'
import PCDFileIcon from './icons/pcd_file_icon_black.svg'
import XYZFileIcon from './icons/xyz_file_icon_black.svg'
import GLBFileIcon from './icons/glb_file_icon_black.svg'
import OBJFileIcon from './icons/obj_file_icon_black.svg'
import PriorityIcon from './icons/priority_high_black_24dp.svg'
import SearchIcon from './icons/search_black_24dp.svg'
import UploadFileIcon from './icons/file_upload_black_24dp.svg'
import TuneIcon from './icons/tune_black_24dp.svg'
import MapIcon from './icons/map_black_24dp.svg'
export {
    CloseIcon,
    CheckCircleIcon,
    DatasetIcon,
    DoneIcon,
    DownloadIcon,
    FolderIcon,
    MapIcon,
    PCDFileIcon,
    PriorityIcon,
    SearchIcon,
    TuneIcon,
    UploadFileIcon,
    UncheckCircleIcon,
    TickedCheckBoxIcon,
    UnTickedCheckBoxIcon,
    IndeterminateCheckBoxIcon,
    XYZFileIcon,
    GLBFileIcon,
    OBJFileIcon,
}
