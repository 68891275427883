import React from 'react'
import { useEffect, useState } from 'react'
import LoadingSpinner from './loadingSpinner'
import SadFaceIcon from '../../assets/icons/drawing.svg'

type LazyImgType = {
  fetchSrc: () => Promise<string>
  alt: string
  load: boolean
  style?: React.CSSProperties
  className?: string
  containerClassName?: string
  message?: string
}

const LazyImg = ({
  fetchSrc,
  alt,
  load,
  style,
  className,
  containerClassName,
  message,
}: LazyImgType) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [imageSrc, setImageSrc] = useState<string | null>(null)

  useEffect(() => {
    if (load && imageSrc === null) fetchImageSrc()
  }, [load])

  useEffect(() => {
    fetchImageSrc()
  }, [fetchSrc])

  const fetchImageSrc = async () => {
    const fetchedSrc = await fetchSrc()
    setImageSrc(fetchedSrc)
    setIsLoaded(true)
  }

  return (
    <div className='aspect-16-9 flex justify-center align-center rounded-sm black-bg'>
      <div
        className={`flex w-100 h-100 justify-center align-content-center ${containerClassName}`}>
        {imageSrc && (
          <img src={imageSrc} alt={alt || ''} className={className} />
        )}
        {!imageSrc && (
          <div className='rounded-sm surface-variant-bg on-surface-variant h-100 w-100 flex justify-center align-center'>
            {!isLoaded && <LoadingSpinner width={80} height={80} />}
            {isLoaded && (
              <div className='w-100 h-100 justify-center align-center flex flex-col'>
                <SadFaceIcon width={120} height={120} />
                <div className='padding-h-sm'>{message}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default LazyImg
