import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    ImageRun,
    SectionType,
    IImageOptions,
    ISectionOptions,
    AlignmentType,
    Header,
    IFloating,
    HorizontalPositionAlign,
    Footer,
    PageNumber,
    ParagraphChild,
    Table,
    TableRow,
    TableCell,
    VerticalAlign,
    BorderStyle,
    IShadingAttributesProperties,
    convertMillimetersToTwip,
    ShadingType,
    ITableCellBorders,
    HeightRule,
    VerticalPositionRelativeFrom,
    IPageMarginAttributes,
    ISectionPropertiesOptions,
    TableLayoutType,
} from 'docx'
/**
 * CONSTANTS USED FOR UNIT CONVERSION
 */
export const EMUS_PER_INCH = 914400
export const DXA_PER_IMG_PIXEL = 15
export const PDF_TO_WORD_FACTOR = 1.335

/**
 * FONTS
 */
export const FONT_FAMILY = 'Calibri'
export enum FontSizes {
    coverTitle = '20pt',
    title = '14pt',
    text = '9pt',
    hashtag = '9pt',
    pageNo = '8pt',
}

/**
 * SHADING
 */
export const SHADING_CLEAR = { type: ShadingType.NIL }
export const SHADING_GREY = { fill: 'DCDCDC' }

/**
 * CELL MARGIN CONFIGURATIONS
 */
export const CELL_NO_MARGIN_TOP_BOT = {
    top: 0,
    bottom: 0,
}
export const CELL_MARGIN_ALIGN_RIGHT = {
    top: 0,
    bottom: 0,
    right: 4,
}
export const CELL_MARGIN_ALIGN_LEFT = {
    top: 0,
    bottom: 0,
    left: 0,
}

/**
 * CELL BORDER CONFIGURATIONS
 */
const noBorderStyle = { style: BorderStyle.NIL }
export const BORDER_NONE = {
    top: noBorderStyle,
    bottom: noBorderStyle,
    left: noBorderStyle,
    right: noBorderStyle,
}
const thickBorderStyle = { style: BorderStyle.SINGLE, size: 8.5 }
export const BORDER_THICK = {
    top: thickBorderStyle,
    bottom: thickBorderStyle,
    left: thickBorderStyle,
    right: thickBorderStyle,
}

/**
 * PAGE MARGINS
 * In docx, page is smaller than section.
 * Section is equivalent to Page in PDFUtils.
 */
export const leftMarginInCm = 1.75
export const rightMarginInCm = 1.43
export const topMarginInCm = 2.77
export const bottomMarginInCm = 1 // Wont affect the footer elements, including page number, watermark & bottom right logo

const defaultPageMarginOptions = {
    top: `${topMarginInCm}cm`,
    bottom: `${bottomMarginInCm}cm`,
    left: `${leftMarginInCm}cm`,
    right: `${rightMarginInCm}cm`,
} as IPageMarginAttributes

export const defaultSectionPropertiesOption: ISectionPropertiesOptions = {
    type: SectionType.NEXT_PAGE,
    page: {
        margin: defaultPageMarginOptions,
    },
}

// Cover section configuration. Cover section means cover page
export const coverSectionTitleHeightOffset = -230 // In twips
// Or else overspill to next page
export const coverSectionPropertiesOption: ISectionPropertiesOptions = {
    ...defaultSectionPropertiesOption,
    verticalAlign: VerticalAlign.CENTER,
}

/**
 * PAGE DIMENSIONS
 */
// Configurations for one whole page
const a4WidthInCm = 21
const a4HeightInCm = 29.7
export const maxClientLogoHeight = 45 * PDF_TO_WORD_FACTOR

export const contentWidthInMm =
    (a4WidthInCm - leftMarginInCm - rightMarginInCm) * 10
export const contentHeightInMm =
    (a4HeightInCm - topMarginInCm - bottomMarginInCm) * 10
