export function processStrSeacp(array: number[]) {
  /*
    To process data returned by str seacp probe
    [ref1, ref2, delta, rem1, rem2, prox, prox_enabled]
    Contact: Show ref 1
    Proximity: Only show if prox_enabled is 1. Show prox - rem 1
    Field Gradient: Show delta
  */
  if (array.length !== 7) return null
  return {
    contact: array[0],
    proximity: array[6] >= 1 ? array[5] - array[3] : null,
    field_gradient: array[2],
  }
}
