import React, { useEffect } from 'react'
import IconButton from '../elements/buttons/IconButton'
import CloseIcon from '../../assets/icons/close_black_24dp.svg'

type MapDialogProps = {
  dialogText?: string
  onClose?: () => void
}

const MapDialog = (props: MapDialogProps): JSX.Element => {
  const { dialogText, onClose } = props
  // const Toolbar = (mapMode === MapMode.MAP) ? MapToolbarDefault : MapToolbarMission

  return (
    <div className='rel flex z-dialog justify-center align-self-end padding-b-md'>
      {dialogText && (
        <div className='flex flex-row background on-secondary-container padding-h-xsm padding-w-sm rounded-sm flex flex-inline align-center'>
          <div className='text-md text-medium padding-md'>{dialogText}</div>
          {onClose && (
            <div className='flex justify-end'>
              <IconButton
                containerColor={'surface-variant-bg'}
                Icon={CloseIcon}
                onClick={onClose}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MapDialog
