import React, { useEffect, useState } from 'react'
import { ChangelogType, SectionDictType } from '..'
import { HDivider } from '../../elements/dividers'
import { prettifyText } from './helper'
import { useAccount, useMsal } from '@azure/msal-react'
import { getBlob } from '../../../backend'

type ContainerProps = {
  path: string
  sectionIdx: number[]
}

export const Container = ({ path, sectionIdx }: ContainerProps) => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [text, setText] = useState<string>('')

  useEffect(() => {
    fetchText()
  }, [])

  const pathArray = path.split('/')
  const currContainer = pathArray[0].replace(/[.,\s]/g, '').toLowerCase()
  const textPath = pathArray
    .slice(1)
    .join('/')
    .replace(/[\s]/g, '_')
    .slice(0, -1)

  const fetchText = async () => {
    const blob = await getBlob(
      instance,
      account,
      'user1manual',
      currContainer,
      'text.txt',
      textPath
    )
    const file = new File([blob], 'name')
    const text = await file.text()
    setText(text)
  }

  return <div>{prettifyText(path, text, sectionIdx, instance, account)}</div>
}

type ChangelogTableProps = {
  changelog?: ChangelogType[]
}

export const ChangelogTable = ({ changelog }: ChangelogTableProps) => {
  return (
    <div className='flex flex-col'>
      <div className='text-xxlg text-bold margin-b-md'>REVISION HISTORY</div>
      <HDivider height={36} />
      {changelog &&
        changelog.map((value, idx) => {
          return (
            <div key={idx} className='flex margin-b-md'>
              <div style={{ width: '60px' }}>{value.version}</div>
              <div style={{ width: '100px' }}>{value.date}</div>
              <div className='flex flex-col'>
                {value.changes.map((change, idx) => (
                  <div key={idx} className='flex-grow-1'>
                    {change}
                  </div>
                ))}
              </div>
            </div>
          )
        })}
    </div>
  )
}

type CoverPageProps = {
  title: string
  changelog?: ChangelogType[]
  Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>
}

export const CoverPage = ({ title, changelog, Icon }: CoverPageProps) => {
  return (
    <>
      <div className='break-after-always'>
        <div style={{ paddingTop: '200px', paddingBottom: '400px' }}>
          {Icon && <Icon height={64} className='margin-r-md' />}
          <div className='text-bold text-xxxlg margin-t-lg'>
            {title.toUpperCase()}
          </div>
          <div className='text-bold text-xxlg margin-t-lg'>User Manual</div>
          {changelog && (
            <div className='text-bold text-xlg margin-t-md'>
              v{changelog[0].version}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

type TableOfContentsProps = {
  sections: SectionDictType
  indent: number
  section: number[]
  setSelect: (select: number[]) => void
}

export const TableOfContents = ({
  sections,
  indent,
  section,
  setSelect,
}: TableOfContentsProps) => {
  return (
    <div className='flex flex-col margin-t-xlg padding-h-lg break-after-always'>
      <div className='text-xxlg text-bold margin-b-md'>TABLE OF CONTENTS</div>
      <HDivider height={36} />
      <div className='flex flex-col flex-wrap'>
        <TableRow
          sections={sections}
          indent={indent + 1}
          section={section}
          setSelect={setSelect}
        />
      </div>
    </div>
  )
}

const TableRow = ({
  sections,
  indent,
  section,
  setSelect,
}: TableOfContentsProps) => {
  const marginLeft = indent != 1 ? '12px' : '0px'
  const marginTop = indent == 1 ? '12px' : '0px'
  const bold = indent == 1
  return (
    <>
      {Object.entries(sections).map(([k, v], idx) => (
        <div
          style={{
            marginTop: marginTop,
            marginLeft: marginLeft,
            width: '50%',
          }}>
          <div
            className={`cursor-pointer primary-light-hover ${
              bold ? 'text-bold' : ''
            }`}
            onClick={() => setSelect([...section, idx])}>
            {section.length > 1 &&
              `${section
                .map((value) => value + 1)
                .join('.')
                .slice(2)}.`}
            {k}
          </div>
          <TableRow
            sections={v.children}
            indent={indent + 1}
            section={[...section, idx]}
            setSelect={setSelect}
          />
        </div>
      ))}
    </>
  )
}
