import React, { useContext, useEffect, useRef, useState } from 'react'
import xlsx from 'node-xlsx'
import { AppContext } from '../../../../../store/context'
import { useAccount, useMsal } from '@azure/msal-react'
import { getBlob } from '../../../../../backend'
import { MarkerType, ModelType, timestampToDate } from '../../../../../types'
import MapPanel from '../MapPanel'
import { roundValue } from '../../../../utils'
import LoadingSpinner from '../../../../elements/loadingSpinner'
import { isMobile, processMarkerCoord } from '../../../../utils/general'
import IconButton from '../../../../elements/buttons/IconButton'
import { CloseIcon, DownloadIcon, UploadFileIcon } from '../../../../../assets'
import MarkerPanel from './MarkerPanel'

const SiteNames: { [key: string]: string } = {
    SQ: 'South Quay',
    EQ: 'East Quay',
    D1: 'Dry Dock 1',
    D2: 'Dry Dock 2',
}

const AsBuilt: { [key: string]: number } = {
    SQ: 24.3,
    EQ: 17.0,
    D1: 15.5,
    D2: 24.3,
    AA: 16,
}

type PECPanelProps = {
    model: ModelType
    markerMap: Map<string, (MarkerType | ModelType)[]> | null
    onClose: () => void
}

const PECPanel = ({ model, markerMap, onClose }: PECPanelProps) => {
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const { state } = useContext(AppContext)
    const { msal } = state

    const [markers, setMarkers] = useState<MarkerType[]>([])
    const [selectedMarkerIdx, setSelectedMarkerIdx] = useState<number | null>(
        null
    )

    const [asBuilt, setAsBuilt] = useState<{ [key: string]: number }>(AsBuilt)

    // For uploading dxf
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [models, setModels] = useState<ModelType[]>([])
    const [modelFileMap, setModelFileMap] = useState<Record<string, any>>({})

    const setAreaAsBuilt = (value: number) => {
        const key = markers[0].name.split('_')[0]
        const temp = { ...asBuilt }
        temp[key] = value
        setAsBuilt(temp)
        console.log(temp)
    }

    const fetchExcelFile = async () => {
        const blob = await getBlob(
            instance,
            account,
            msal.storage,
            msal.asset,
            model.file,
            model.date || 'asset'
        )
        processExcelData(blob)
    }
    const processExcelData = (buffer: Buffer) => {
        try {
            const calibration = processSummary(buffer)
            const pecValues = processCScans(buffer)
            console.log(pecValues, markerMap)
            if (!pecValues || !markerMap) return
            let arr: MarkerType[] = []
            markerMap?.forEach((markers, key) => {
                const filtered = markers
                    .filter(
                        (marker): marker is MarkerType =>
                            isMarkerType(marker) && marker.name in pecValues
                    )
                    .map((value) => {
                        console.log(
                            'calibration',
                            calibration,
                            value.name.split('_').slice(0, -1).join('_')
                        )
                        return {
                            ...value,
                            pec: {
                                thickness: pecValues[value.name],
                                calibration:
                                    calibration[
                                        value.name
                                            .split('_')
                                            .slice(0, -1)
                                            .join('_')
                                    ] || 0,
                            },
                        }
                    })
                arr = [...arr, ...filtered]
            })
            setMarkers(arr)
        } catch (error) {
            alert('Opps there is an error with the file')
            console.log(error)
        }
    }
    const exportExcelData = () => {
        const rows = processMarkersIntoExcel(
            markers,
            asBuilt[markers[0].name.split('_')[0]]
        )
        const data = [ExcelHeaders, ...rows]
        const sheetOptions = {
            '!cols': [
                { wch: 10 },
                { wch: 5 },
                { wch: 5 },
                { wch: 5 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
                { wch: 10 },
            ],
        }
        const filename = model.title || 'data'
        var buffer = xlsx.build([
            { name: filename, data: data, options: sheetOptions },
        ]) // Returns a buffer

        var blob = new Blob([buffer], { type: 'application/xlsx' })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${filename}.xlsx`
        link.click()
    }
    const importDXF = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target) return
        if (!e.target.files || e.target.files.length === 0) {
            alert('Please choose a file.')
            return
        }
        if (e.target.files.length > 1) {
            alert('Please only choose 1 file.')
            return
        }
        if (!e.target.files[0].name.includes('.dxf')) {
            alert('Please only upload dxf file.')
            return
        }
        const newModel: ModelType = {
            id: 'dxf',
            file: e.target.files[0].name,
            depth: 0,
            asset: '',
            heading: 0,
            comment: '',
            scale: 0.001,
        }
        modelFileMap[newModel.file] = await e.target.files[0].text()
        setModelFileMap(modelFileMap)
        setModels([newModel])
    }
    useEffect(() => {
        fetchExcelFile()
    }, [])
    const mobile = isMobile()
    return (
        <div
            style={{ inset: mobile ? '0px' : '36px' }}
            className={`z-dialog surface-dim-bg rounded-md border-box shadow fixed flex flex-col pointer-all rel`}>
            <div className='abs right-12px top-12px z-dialog flex'>
                <IconButton
                    Icon={DownloadIcon}
                    onClick={() => exportExcelData()}
                />
                <input
                    ref={fileInputRef}
                    type='file'
                    name='name'
                    style={{ display: 'none' }}
                    onChange={importDXF}
                />
                <IconButton
                    Icon={UploadFileIcon}
                    onClick={() => fileInputRef.current?.click()}
                />
                <IconButton Icon={CloseIcon} onClick={() => onClose()} />
            </div>
            {selectedMarkerIdx !== null && (
                <div
                    className='abs left-12px top-12px bottom-12px background z-10 rounded-sm padding-lg overflow-auto'
                    style={{ width: '40%', maxWidth: '400px' }}>
                    <MarkerPanel
                        marker={markers[selectedMarkerIdx]}
                        onClose={() => setSelectedMarkerIdx(null)}
                    />
                </div>
            )}
            {markers.length <= 0 && (
                <div className='flex abs h-100 w-100 top-0 right-0 bottom-0 left-0 z-dialog justify-center align-center'>
                    <LoadingSpinner width={80} height={80} />
                </div>
            )}
            {markers.length > 0 && (
                <MapPanel
                    list={markers}
                    initLocation={{
                        latitude: 1.2722988179357277, // Dummy location for initialisation
                        longitude: 103.77898738692801,
                        zoom: 18,
                    }}
                    selectedMarkersIdx={[0]}
                    selectMarker={setSelectedMarkerIdx}
                    defaultShowModels={true}
                    models={models}
                    modelFileMap={modelFileMap}
                    asBuilt={
                        asBuilt[
                            markers[0].name.replace('PEC_', '').split('_')[0]
                        ]
                    }
                    setAsBuilt={setAreaAsBuilt}
                />
            )}
        </div>
    )
}
export { PECPanel }

/** PEC UTILS */

const isMarkerType = (x: any): x is MarkerType => {
    return (x as MarkerType).bag_id !== undefined
}

/* Extracts wall thickness value from Summary sheet to be used as calibration */
const processSummary = (buffer: Buffer) => {
    const sheets = xlsx
        .parse(buffer)
        .filter((value) => value.name.includes('Summary'))
    let res: { [name: string]: number } = {}
    for (let i = 0; i < sheets.length; ++i) {
        res = { ...res, ...processSummarySheet(sheets[i]) }
    }
    return res
}

const processSummarySheet = (sheet: { name: string; data: any[][] }) => {
    const cell = getCellIdx(sheet.data, 'Wall Thickness')
    if (!cell) return
    const thickness = Number(sheet.data[cell[0]][5].split(' ')[0])
    const zones = getCellIdx(sheet.data, 'Scan Zones')
    if (!zones) return
    console.log('test', cell, zones, thickness)
    let res: { [name: string]: number } = {}
    let row = zones[0] + 3
    do {
        if (sheet.data[row] && sheet.data[row][0]) {
            res[sheet.data[row][0]] = thickness
        } else break
        row += 1
    } while (true || row < 1000) // Set a max number of loops just in case
    return res
}

/* Extracts wall thickness values from C-scans sheets */
const processCScans = (buffer: Buffer) => {
    const excludeSheets = ['Summary', 'Defects']
    const data = xlsx
        .parse(buffer)
        .filter(
            (value) =>
                excludeSheets.includes(value.name) ||
                !value.name.includes('Ref')
        )
    return processAllSheets(data)
}

const processAllSheets = (sheets: { name: string; data: any[][] }[]) => {
    let res: { [name: string]: number } = {}
    for (let i = 0; i < sheets.length; ++i) {
        res = { ...res, ...processSheet(sheets[i]) }
    }
    return res
}

const processSheet = (sheet: { name: string; data: any[][] }) => {
    const row = getCellIdx(sheet.data, 'Wall thickness values')
    if (!row) return
    return processTable(
        sheet.data,
        row[0] + 7,
        sheet.name.replace('C-scans - ', '')
    )
}

const getCellIdx = (sheet: any[][], value: string) => {
    for (let row = 0; row < sheet.length; ++row) {
        for (let col = 0; col < sheet[row].length; ++col) {
            if (sheet[row][col] === value) return [row, col]
        }
    }
}

const processTable = (sheet: any[][], rowStart: number, sheetName: string) => {
    const data: { [name: string]: number } = {}
    for (let row = rowStart; rowStart < sheet.length; ++row) {
        for (let col = 1; col < sheet[rowStart].length; ++col) {
            if (sheet[row].length == 0) return data
            const name = `${sheetName}_${
                row - rowStart + 1
            }${String.fromCharCode(64 + col)}`
            data[name] = roundValue(sheet[row][col], 2)
        }
    }
    return data
}

const ExcelHeaders = [
    'ID',
    'Site',
    'Block',
    'Column',
    'Position',
    'Wall Thickness Value (mm)',
    'As-Built Thickness (mm)',
    'Depth (m)',
    'Altitude (m)',
    'PEC Calibration (mm)',
    'Loss (%)',
    'Date of Inspection',
    'Latitude',
    'Longitude',
]

const processMarkersIntoExcel = (markers: MarkerType[], asbuilt: number) => {
    /* [ID, Site, Block, Column, Position, Wall Thickness Value (mm)', As-Built Thickness (mm),Depth (m), Altitude (m), PEC Calibration (mm), Date of Inspection, Latitude, Longitude*/
    return markers.map((marker) => {
        const { site, block, column, position } = processSiteId(marker.name)
        const coord = processMarkerCoord(marker)
        return [
            marker.name,
            site,
            block,
            column,
            position,
            marker.pec?.thickness || 0,
            asbuilt,
            roundValue(marker.position.z, 2),
            roundValue(marker.altitude, 2),
            marker.pec?.calibration || 0,
            calcLoss(asbuilt, marker.pec?.thickness || 0),
            timestampToDate(marker.timestamp),
            coord.latitude,
            coord.longitude,
        ]
    })
}

const processSiteId = (name: string) => {
    const arr = name.split('_')
    const position = arr[2].includes('1')
        ? 'Top'
        : arr[2].includes('2')
        ? 'Middle'
        : 'Bottom'
    return {
        site: SiteNames[arr[0]] || arr[0],
        block: arr[1].slice(1),
        column: arr[2][1],
        position: position,
    }
}

const calcLoss = (asbuilt: number, wall: number) => {
    return roundValue(((asbuilt - wall) / asbuilt) * 100, 2)
}
