import React from 'react'
import Button, { ButtonProps } from './Button'

/**
 * Text Button without background color
 */
const TextButton = (props: ButtonProps) => {
  return <Button {...props} contentColor='on-surface-variant' />
}

export default TextButton
