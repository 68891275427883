import React, { useEffect, useState, useContext } from 'react'
import { PageType, ResourceLog, ActivityType, Activities } from '../../types'
import { useAccount, useMsal } from '@azure/msal-react'
import { SelectionPage } from './SelectionPage'
import ReportPage from '../reportpage'
import VideoPage from '../videopage'
import MissionPage from '../missionpage'
import UserManualPage from '../usermanualpage'
import PointCloudPage from '../pointcloudpage'
import LoadingSpinner from '../elements/loadingSpinner'
import SadFaceIcon from '../../assets/icons/drawing.svg'
import Topbar from './Topbar'
import { addLog } from '../utils/user_activities'
import { getStorageAccounts } from '../../backend'
import { regexStorageName } from '../utils/general'
import { AppProvider } from '../../store/context'

const HomePage = () => {
  const { instance, accounts, inProgress } = useMsal()
  const username = accounts.length > 0 ? accounts[0].username : 'demo@beex.sg'
  const account = useAccount(accounts[0] || {})
  const [page, setPage] = useState<PageType>(PageType.HOME)
  const [bagName, setBagName] = useState('')
  const [resourceLog, setResourceLog] = useState<ResourceLog>({
    loginTime: '',
    isLogged: false,
  })
  const [userManualAccess, setUserManualAccess] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [noPermissions, setNoPermissions] = useState<boolean>(false)

  useEffect(() => {
    if (resourceLog.loginTime != '' && !resourceLog.isLogged) {
      let newResourceLog: ResourceLog
      var s = new Date().getTime() - new Date(resourceLog.loginTime).getTime()
      var ms = s % 1000
      s = (s - ms) / 1000
      var secs = s % 60
      s = (s - secs) / 60
      var mins = s % 60
      var hrs = (s - mins) / 60
      const timeSpent = hrs + ':' + mins + ':' + secs + '.' + ms
      newResourceLog = {
        loginTime: resourceLog ? resourceLog.loginTime : '',
        logoutTime: new Date().toISOString(),
        timeSpent: timeSpent,
        isLogged: false,
      }
      const dataToStore: ActivityType = {
        timestamp: new Date().toISOString(),
        user: username,
        action: Activities[Activities.OFFSTORAGE],
        resourcelog: newResourceLog,
      }
      resourceLog.storage && resourceLog.container && account
        ? addLog(
            resourceLog.storage,
            resourceLog.container,
            instance,
            account,
            dataToStore
          )
        : null
      setResourceLog({ loginTime: '', isLogged: false })
    }
  }, [resourceLog])

  useEffect(() => {
    setIsLoading(true)
    const initializePermissions = async () => {
      try {
        const storageAccounts = await getStorageAccounts(instance, account)
        storageAccounts.indexOf(regexStorageName('user1manual')) > -1
          ? setUserManualAccess(true)
          : setUserManualAccess(false)
      } catch (error) {
        setNoPermissions(true)
      }
    }
    initializePermissions()
    setIsLoading(false)
  }, [])

  return (
    <AppProvider>
      <div className={`background on-background flex flex-col w-100 h-100`}>
        <Topbar
          bagName={bagName}
          setPage={() => setPage(PageType.HOME)}
          setBagName={setBagName}
        />
        <div
          id='print-section-parent'
          className={`flex flex-grow-1 justify-center align-content-center overflow-auto`}>
          {isLoading && (
            <div className={`align-self-center`}>
              <LoadingSpinner width={80} height={80} />
            </div>
          )}
          {noPermissions && (
            <div className='w-100 h-100 justify-center align-center flex flex-col'>
              <SadFaceIcon width={200} height={200} />
              <div className='padding-h-sm text-center h1'>
                Opps, this account does not have permissions to access the
                portal!
                <br />
                Do contact an administrator for this issue.
              </div>
            </div>
          )}
          {page === PageType.HOME && !isLoading && !noPermissions && (
            <SelectionPage
              onChangePage={(page) => setPage(page)}
              userManualAccess={userManualAccess}
            />
          )}
          {page === PageType.REPORT && (
            <ReportPage
              goHome={() => {
                setPage(PageType.HOME)
                setResourceLog({
                  loginTime: resourceLog.loginTime,
                  isLogged: false,
                  storage: resourceLog.storage,
                  container: resourceLog.container,
                })
              }}
              resourceLog={resourceLog}
              setResourceLog={setResourceLog}
            />
          )}
          {page === PageType.VIDEO && <VideoPage setBagName={setBagName} />}
          {page === PageType.MISSION && <MissionPage />}
          {page === PageType.USER_MANUAL && userManualAccess && (
            <UserManualPage />
          )}
          {page === PageType.PCD && <PointCloudPage />}
        </div>
      </div>
    </AppProvider>
  )
}

export default HomePage
