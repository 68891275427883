export const getStorageAccountsDemo = async () => {
  const options = {
    method: 'GET',
  }
  const response = await fetch('/demo/getStorageAccounts', options)
  const json = await response.json()
  return json.value
}

export const getContainersDemo = async (storage: string) => {
  const options = {
    method: 'GET',
  }
  const response = await fetch(
    `/demo/getContainers?storage=${storage}`,
    options
  )
  const json = await response.json()
  return json.value
}

export const getBlobDemo = async (container: string, blob: string) => {
  const options = {
    method: 'GET',
  }
  console.log('fetch', container, blob)
  const response = await fetch(
    `/demo/getBlob?container=${container}&blob=${blob}`,
    options
  )
  if (response && response.status === 200) {
    if (blob.includes('json')) {
      return response.json()
    }
    if (blob.includes('dae')) {
      return response.text()
    }
    return response.blob()
  }
  return null
}

export const getVersion = async () => {
  const options = {
    method: 'GET',
  }
  const response = await fetch('/api/getversion', options)
  if (response && response.status === 200) {
    return response.text()
  }
  return ''
}
