import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { SUBSCRIPTION_ID, RESOURCE_GROUP_NAME, scopes } from './config'
import { Client } from '@microsoft/microsoft-graph-client'
import {
    regexStorageName,
    unregexStorageName,
    unregexAssetName,
    regexAssetName,
} from '../../components/utils/general'

function getHeaders(token: string, disableCache?: boolean) {
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${token}`)
    headers.append('x-ms-date', new Date().toUTCString())
    headers.append('x-ms-version', '2019-12-12')
    if (disableCache) {
        headers.append('pragma', 'no-cache')
        headers.append('cache-control', 'no-cache')
    }
    return headers
}

// only given permission to admins
export const getSharedOneDriveFolders = async (
    instance: IPublicClientApplication,
    account: AccountInfo
) => {
    const endpointForSharedFolders = '/me/drive/sharedWithMe'

    try {
        const accessTokenResponse = await instance.acquireTokenSilent({
            scopes: ['User.Read', 'Files.Read'],
            account: account,
        })

        const graphClient = Client.init({
            authProvider: (done) => {
                done(null, accessTokenResponse.accessToken)
            },
        })

        const response = await graphClient.api(endpointForSharedFolders).get()
        return response.value
    } catch (error) {
        console.log(error)
    }
}

export const getOneDriveFolderContent = async (
    instance: IPublicClientApplication,
    account: AccountInfo,
    driveId: string,
    folderId: string
) => {
    const endpoint = `/drives/${driveId}/items/${folderId}/children`

    try {
        const accessTokenResponse = await instance.acquireTokenSilent({
            scopes: ['User.Read', 'Files.Read'],
            account: account,
        })

        const graphClient = Client.init({
            authProvider: (done) => {
                done(null, accessTokenResponse.accessToken)
            },
        })

        const response = await graphClient.api(endpoint).get()
        return response.value
    } catch (error: any) {
        throw new Error(error.message)
    }
}

export const callApiWithToken = async (
    token: string,
    apiEndpoint: string,
    disableCache?: boolean,
    throwingErrorIf404: boolean = false
) => {
    const options = {
        method: 'GET',
        headers: getHeaders(token, disableCache),
    }
    return fetch(apiEndpoint, options).then((response) => {
        if (!response.ok) {
            // Error Handling
            if (response.status === 404 && !throwingErrorIf404) return response
            throw new Error(`${response.status}:${response.statusText}`)
        } else {
            return response
        }
    })
    // .catch(error => {
    //   console.log(error);
    //   return error;
    // })
}

type StorageAccountType = {
    name: string
}

export const getStorageAccountsMSAL = async (
    instance: IPublicClientApplication,
    account: AccountInfo
) => {
    const endpoint = `https://management.azure.com/subscriptions/${SUBSCRIPTION_ID}/resourceGroups/${RESOURCE_GROUP_NAME}/providers/Microsoft.Storage/storageAccounts?api-version=2021-04-01`
    const tokenRes = await instance.acquireTokenSilent({
        scopes: scopes.storage,
        account: account,
    })
    return callApiWithToken(tokenRes.accessToken, endpoint)
        .then((response) => response?.json())
        .then((json) =>
            json.value.map((item: StorageAccountType) => {
                return regexStorageName(item.name)
            })
        )
        .then((list) => list.sort())
}

export const getContainersMSAL = async (
    instance: IPublicClientApplication,
    account: AccountInfo,
    storage: string
) => {
    storage = unregexStorageName(storage)
    const endpoint = `https://${storage}.blob.core.windows.net/?comp=list`
    const response = await instance.acquireTokenSilent({
        scopes: scopes.container,
        account: account,
    })
    return callApiWithToken(response.accessToken, endpoint)
        .then((response) => {
            if (response) return response.text()
            return ''
        })
        .then((text) => new DOMParser().parseFromString(text, 'text/xml'))
        .then((dom) =>
            Array.from(
                dom.documentElement.getElementsByTagName('Container')
            ).map(async (item) => {
                const containerName =
                    item.getElementsByTagName('Name')[0].textContent! || ''
                const containerUrl = `https://${storage}.blob.core.windows.net/${containerName}?restype=container&comp=list`
                try {
                    await callApiWithToken(response.accessToken, containerUrl)
                    return regexAssetName(containerName)
                } catch (error) {
                    return ''
                }
            })
        )
}

export const getBlobMSAL = async (
    instance: IPublicClientApplication,
    account: AccountInfo,
    storage: string,
    container: string,
    blob: string,
    subdirectory?: string,
    throwingErrorIf404?: boolean
) => {
    storage = unregexStorageName(storage)
    container = unregexAssetName(container)
    if (subdirectory) subdirectory = '/' + subdirectory
    const endpoint = `https://${storage}.blob.core.windows.net/${container}${
        subdirectory || ''
    }/${blob}`
    const response = await instance.acquireTokenSilent({
        scopes: scopes.container,
        account: account,
    })
    return callApiWithToken(
        response.accessToken,
        endpoint,
        blob.includes('.json'),
        throwingErrorIf404
    ).then((response) => {
        if (response && response.status === 200) {
            if (blob.includes('json')) {
                return response.json()
            }
            if (blob.includes('dae') || blob.includes('dxf')) {
                return response.text()
            }
            if (blob.includes('pcd') || blob.includes('xlsx')) {
                return response.arrayBuffer()
            }
            return response.blob()
        }
        return null
    }).catch((error) => {throw error})
}

export const getBlobsInContainerMSAL = async (
    instance: IPublicClientApplication,
    account: AccountInfo,
    storage: string,
    container: string,
    subdirectory?: string
) => {
    storage = unregexStorageName(storage)
    container = unregexAssetName(container)
    let endpoint
    if (subdirectory) {
        endpoint = `https://${storage}.blob.core.windows.net/${container}?prefix=${subdirectory}&restype=container&comp=list`
    } else {
        endpoint = `https://${storage}.blob.core.windows.net/${container}?restype=container&comp=list`
    }
    const response = await instance.acquireTokenSilent({
        scopes: scopes.container,
        account: account,
    })
    return callApiWithToken(response.accessToken, endpoint)
        .then((response) => {
            if (response) return response.text()
            return ''
        })
        .then((text) => new DOMParser().parseFromString(text, 'text/xml'))
        .then((dom) =>
            Array.from(dom.documentElement.getElementsByTagName('Blob')).map(
                (item) => {
                    return (
                        item.getElementsByTagName('Name')[0].textContent || ''
                    )
                }
            )
        )
}
