import React, {
    MutableRefObject,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import LoadingSpinner from '../../../elements/loadingSpinner'
import MultiSelectThumbnail from '../../../elements/MultiSelectThumbnail'
import LazyImg from '../../../elements/LazyImage'
import Button from '../../../elements/buttons/Button'
import {
    CheckCircleIcon,
    UncheckCircleIcon,
    DoneIcon,
    PCDFileIcon,
    XYZFileIcon,
    GLBFileIcon,
    OBJFileIcon,
    DatasetIcon,
    MapIcon,
} from '../../../../assets'

import {
    MarkerType,
    MarkerContainerPairType,
    ThumbnailType,
    timestampToHMS,
    ModelType,
    Hashtag,
} from '../../../../types'
import AssistChip from '../../../elements/chips/AssistChip'
import { FilterType, filterDates, isFilterOn } from '../../../utils/FilterUtils'
import { getDateString, getMonthString, isSplitDate } from '../../../utils/'
import {
    getThumbnailGroup,
    getNumberOfGroups,
    isNextGroup,
} from '../../../utils/'
import { AppContext } from '../../../../store/context'

type FolderPanelProps = {
    list: string[] | null
    dateFolder: string
    setDateFolder: (name: string) => void
    markerMap: Map<string, (MarkerType | ModelType)[]> | null
    thumbnailMap: Map<string, ThumbnailType> | null
    // modelMap: Map<string, ModelType[]> | null
    hashtagMap: Map<string, Hashtag[]> | null
    selectedItems: MarkerContainerPairType[]
    setSelectedItems: React.Dispatch<
        React.SetStateAction<MarkerContainerPairType[]>
    >
    viewMode: number
    toggleHashtag: number
    filter: FilterType
    setMarker: React.Dispatch<React.SetStateAction<MarkerType | null>>
    setModel: React.Dispatch<React.SetStateAction<ModelType | null>>
    setViewSelectedItems: (viewSelectedItems: boolean) => void
    getModelNameFromFileName: (filename: string) => string
    isMarkerType: (x: any) => boolean
    isModelType: (x: any) => boolean
    deleteSelectedItems: boolean
    updateOpenSnapshot: (
        container: string,
        bagId?: string,
        fileName?: string
    ) => Promise<void>
    selectButtons: Map<string, boolean>
    setSelectButtons: (value: Map<string, boolean>) => void
}

export const FolderPanel = ({
    list,
    dateFolder,
    setDateFolder,
    markerMap,
    thumbnailMap,
    hashtagMap,
    selectedItems,
    setSelectedItems,
    viewMode,
    toggleHashtag,
    setMarker,
    setModel,
    setViewSelectedItems,
    getModelNameFromFileName,
    isMarkerType,
    isModelType,
    deleteSelectedItems,
    updateOpenSnapshot,
    selectButtons,
    setSelectButtons,
}: FolderPanelProps) => {
    const { state } = useContext(AppContext)
    const { filter } = state
    const numberOfThumbnails = thumbnailMap !== null ? thumbnailMap.size : 0
    const [loadThumbnailGroup, setLoadThumbnailGroup] = useState<boolean[]>([])
    const [filteredMarkerMap, setFilteredMarkerMap] =
        useState<Map<string, (MarkerType | ModelType)[]>>()
    let prevDate = ''
    let loadedMarkers = 0 // For search function

    // all button
    var selectedDateItems: Map<
        string,
        Array<MarkerContainerPairType>
    > = new Map()
    const [selectAllButton, setSelectAllButton] = useState<Boolean>(false)

    const onClickWholeYearButton = (year: string) => {
        if (!list) return
        const filteredList = list.filter((date) => filteredMarkerMap?.has(date) && date.split('-')[0] === year)
        var newDict = selectButtons
        setSelectAllButton(!selectAllButton)
        filteredList.forEach((date) => newDict.set(date, !newDict.get(date)))
        setSelectButtons(newDict)

        const markerModelArray: MarkerContainerPairType[] = []
        selectedDateItems.forEach((value, date) => {
            if (date.split('-')[0] !== year) return
            markerModelArray.push(...value)
        })

        if (selectButtons.get(filteredList[0])) {   // Use the first date to check if all are selected
            var newSelectedItems = selectedItems
            for (let i = 0; i < markerModelArray.length; i++) {
                if (
                    !newSelectedItems.filter((x) =>
                        isPairEqual(x, markerModelArray[i])
                    ).length
                ) {
                    newSelectedItems = [
                        ...newSelectedItems,
                        markerModelArray[i],
                    ]
                }
            }
            setSelectedItems(newSelectedItems)
        } else {
            var newSelectedItems = selectedItems
            for (let i = 0; i < markerModelArray.length; i++) {
                newSelectedItems = newSelectedItems.filter(
                    (x) => !isPairEqual(x, markerModelArray[i])
                )
            }
            setSelectedItems(newSelectedItems)
        }
    }
    const isWholeYearSelected = (year: string) => {
        if (!list) return false
        const filteredList = list.filter((date) => filteredMarkerMap?.has(date) && date.split('-')[0] === year)
        for (let i = 0; i < filteredList.length; i++) {
            if (!selectButtons.get(filteredList[i])) {
                return false
            }
        }
        return true
    }

    const onClickWholeDateButton = (date: string) => {
        var newDict = selectButtons
        newDict.set(date, !newDict.get(date))
        setSelectButtons(newDict)
        setSelectAllButton(!selectAllButton)

        const markerModelArray = selectedDateItems.get(date) || []
        if (selectButtons.get(date)) {
            var newSelectedItems = selectedItems
            for (let i = 0; i < markerModelArray.length; i++) {
                if (
                    !newSelectedItems.filter((x) =>
                        isPairEqual(x, markerModelArray[i])
                    ).length
                ) {
                    newSelectedItems = [
                        ...newSelectedItems,
                        markerModelArray[i],
                    ]
                }
            }
            setSelectedItems(newSelectedItems)
        } else {
            var newSelectedItems = selectedItems
            for (let i = 0; i < markerModelArray.length; i++) {
                newSelectedItems = newSelectedItems.filter(
                    (x) => !isPairEqual(x, markerModelArray[i])
                )
            }
            setSelectedItems(newSelectedItems)
        }
    }

    const onClickThumbnail = (value: string, item: MarkerContainerPairType) => {
        item.marker
            ? updateOpenSnapshot(value, item.marker.bag_id, undefined)
            : {}
        item.model ? updateOpenSnapshot(value, undefined, item.model.file) : {}
        setDateFolder(value)
        item.marker && setMarker(item.marker)
        item.model && setModel(item.model)
    }
    const onClickIcon = (item: MarkerContainerPairType, date: string) => {
        if (!isSelected(item)) {
            setSelectedItems([...selectedItems, item])
        } else {
            if (selectButtons.get(date)) {
                var newDict = selectButtons
                newDict.set(date, !newDict.get(date))
                setSelectButtons(newDict)
            }
            const newSelectedItems = selectedItems.filter(
                (x) => !isPairEqual(x, item)
            )
            setSelectedItems(newSelectedItems)
        }
    }
    const handleClickHashtag = (value: string) => {
        // setSearchValue(`#${value}`)
        // TODO: handleclick
    }
    const getMarkerByBagId = (bagId: string, container: string) => {
        const marker = markerMap
            ?.get(container)
            ?.filter((marker): marker is MarkerType => {
                if (!('bag_id' in marker)) return false
                return marker.bag_id === bagId
            })[0]
        return marker ? marker : undefined
    }
    const isSelected = (item: MarkerContainerPairType) => {
        return selectedItems.filter((x) => isPairEqual(x, item)).length > 0
    }
    const isPairEqual = (
        pair1: MarkerContainerPairType,
        pair2: MarkerContainerPairType
    ) => {
        return (
            pair1.folder === pair2.folder &&
            ((pair1.marker &&
                pair2.marker &&
                pair1.marker.bag_id === pair2.marker.bag_id) ||
                (pair1.model &&
                    pair2.model &&
                    pair1.model.file === pair2.model.file))
        )
    }

    const getGroupLoad = (index: number) =>
        loadThumbnailGroup[getThumbnailGroup(index)] ||
        getThumbnailGroup(index) === 0

    const handleOberserverInView = (index: number) => {
        const clone = [...loadThumbnailGroup]
        const idxToUpdate = getThumbnailGroup(index)
        clone[idxToUpdate] = true
        clone[idxToUpdate + 1] = true
        setLoadThumbnailGroup(clone)
    }

    useEffect(() => {
        setLoadThumbnailGroup(
            Array(getNumberOfGroups(numberOfThumbnails)).fill(false)
        )
    }, [numberOfThumbnails])

    useEffect(() => {
        // Filter list whenever filter criteria or markers are changed
        const updatedMarkerMap = new Map<string, (MarkerType | ModelType)[]>()
        markerMap?.forEach((markers, key) => {
            const res = filterDates(markers, filter)
            res.length > 0 && updatedMarkerMap.set(key, res)
        })
        setFilteredMarkerMap(updatedMarkerMap)
    }, [filter, markerMap])

    useEffect(() => {
        setSelectAllButton(false)
    }, [deleteSelectedItems])

    const dummyPromise = async () => {return ''}
    const emptyThumbnail: ThumbnailType = {
        frontCamImage: dummyPromise,
        frontCamClaheImage: dummyPromise,
        sonarImage: dummyPromise,
        fullDepthCamImage: dummyPromise,
    }


    return (
        <>
            {list &&
                list.length > 0 &&
                filteredMarkerMap &&
                markerMap !== null &&
                hashtagMap !== null &&
                thumbnailMap !== null &&
                // thumbnailMap.size > 0 &&
                list
                    .slice(0)
                    .reverse()
                    .map((currDate, idx) => {
                        if (currDate === '' || !filteredMarkerMap?.has(currDate)) {
                            return null
                        }
                        const [showDate, showMonth, showYear] = isSplitDate(
                            currDate,
                            prevDate
                        )
                        const year = currDate.split('-')[0]
                        return (
                            <>
                                {showYear && (
                                    <div
                                        className={
                                            'h0 top-0 z-3 w-100 padding-t-sm background flex align-center'
                                        }>
                                        <div>{year}</div>
                                        <Button
                                            Icon={
                                                isWholeYearSelected(year)
                                                    ? CheckCircleIcon
                                                    : UncheckCircleIcon
                                            }
                                            contentColor={'white'}
                                            iconHeight={36}
                                            iconWidth={36}
                                            onClick={() =>
                                                onClickWholeYearButton(
                                                    year
                                                )
                                            }
                                            className={'top-4px'}
                                        />
                                    </div>
                                )}
                                {showMonth && (
                                    <div
                                        className={
                                            'h1 padding-t-sm top-0 z-3 w-100 background'
                                        }
                                        style={{ top: '57px' }}>
                                        {getMonthString(currDate)}
                                    </div>
                                )}
                                {showDate && (
                                    <div
                                        className={
                                            'h2 padding-h-sm sticky top-0 z-3 w-100 flex justify-between align-center background'
                                        }>
                                        <div>{getDateString(currDate)}</div>
                                        <Button
                                            Icon={
                                                selectButtons.get(currDate)
                                                    ? CheckCircleIcon
                                                    : UncheckCircleIcon
                                            }
                                            contentColor={'white'}
                                            iconHeight={36}
                                            iconWidth={36}
                                            onClick={() =>
                                                onClickWholeDateButton(currDate)
                                            }
                                        />
                                    </div>
                                )}
                                {filteredMarkerMap &&
                                    filteredMarkerMap.get(currDate)?.map((x) => {
                                        const isMarker = isMarkerType(x)
                                        const marker = isMarker
                                            ? (x as MarkerType)
                                            : undefined
                                        const model = !isMarker
                                            ? (x as ModelType)
                                            : undefined

                                        loadedMarkers++
                                        const bag_id = marker && marker.bag_id
                                        const currentMarker = bag_id
                                            ? getMarkerByBagId(bag_id, currDate)
                                            : undefined
                                        const markerContainerPair: MarkerContainerPairType =
                                            {
                                                folder: currDate,
                                                marker:
                                                    currentMarker !== null
                                                        ? currentMarker
                                                        : undefined,
                                            }
                                        model && (model.date = currDate)
                                        const modelName =
                                            model &&
                                            (model.title ||
                                                getModelNameFromFileName(
                                                    model.file
                                                ))
                                        const modelContainerPair = {
                                            folder: currDate,
                                            model: model,
                                        }

                                        const selectedMarkerModel = marker
                                            ? markerContainerPair
                                            : modelContainerPair

                                        const currDateItems =
                                            selectedDateItems.get(currDate) || []
                                        selectedDateItems.set(currDate, [
                                            ...currDateItems,
                                            selectedMarkerModel,
                                        ])
                                        const onDoubleClick = () => {
                                            marker &&
                                                updateOpenSnapshot(
                                                    currDate,
                                                    marker.bag_id,
                                                    undefined
                                                )
                                            model &&
                                                updateOpenSnapshot(
                                                    currDate,
                                                    undefined,
                                                    model.file
                                                )
                                            setDateFolder(currDate)
                                            marker && setMarker(marker)
                                            model && setModel(model)
                                        }
                                        let thumbnails =
                                            bag_id && thumbnailMap?.get(bag_id)
                                                ? thumbnailMap?.get(bag_id)
                                                : emptyThumbnail

                                        const markerThumbnailLazys = [
                                            // frontCamImage
                                            thumbnails?.frontCamImage
                                                ? thumbnails?.frontCamImage
                                                : dummyPromise,
                                            // sonarImage
                                            thumbnails?.sonarImage
                                                ? thumbnails?.sonarImage
                                                : dummyPromise,
                                            // fullDepthCamImage
                                            thumbnails?.fullDepthCamImage
                                                ? thumbnails?.fullDepthCamImage
                                                : dummyPromise
                                        ]
                                        let mosaicLazy =
                                            (model &&
                                                thumbnailMap?.get(model.file)
                                                    ?.frontCamImage) || dummyPromise
                                        const thumbnailIdx = loadedMarkers - 1

                                        return (
                                            <div
                                                className='rel padding-r-sm flex-col flex flex-grow-1'
                                                style={{
                                                    minWidth: '240px',
                                                    maxWidth: '360px',
                                                }}
                                                onDoubleClick={() =>
                                                    onDoubleClick()
                                                }>
                                                {isNextGroup(thumbnailIdx) && (
                                                    <ObserverDiv
                                                        handleOnScreen={() => {
                                                            handleOberserverInView(
                                                                thumbnailIdx
                                                            )
                                                        }}
                                                    />
                                                )}

                                                <div className='h-100 flex flex-col justify between'>
                                                    <MultiSelectThumbnail
                                                        id={`${
                                                            bag_id
                                                                ? bag_id
                                                                : modelName
                                                        }`}
                                                        Icon={DoneIcon}
                                                        isSelected={
                                                            marker
                                                                ? isSelected(
                                                                      markerContainerPair
                                                                  )
                                                                : isSelected(
                                                                      modelContainerPair
                                                                  )
                                                        }
                                                        isFlagged={
                                                            marker?.no_anode
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={(
                                                            id: string
                                                        ) => {
                                                            marker &&
                                                                onClickIcon(
                                                                    markerContainerPair,
                                                                    currDate
                                                                )
                                                            model &&
                                                                onClickIcon(
                                                                    modelContainerPair,
                                                                    currDate
                                                                )
                                                        }}>
                                                        <ThumbnailImage
                                                            isMarker={isMarker}
                                                            load={getGroupLoad(
                                                                thumbnailIdx
                                                            )}
                                                            alt={
                                                                marker
                                                                    ? marker.name
                                                                    : modelName
                                                            }
                                                            model={model}
                                                            image={
                                                                model
                                                                    ? mosaicLazy
                                                                    : markerThumbnailLazys[viewMode]
                                                            }
                                                            className={`${
                                                                marker
                                                                    ? 'w-100 h-100'
                                                                    : 'w-50 min-height-50'
                                                            } object-fit-contain rounded-sm black-bg`}
                                                            onClick={() => {
                                                                marker &&
                                                                    onClickThumbnail(
                                                                        currDate,
                                                                        markerContainerPair
                                                                    )
                                                                model &&
                                                                    onClickThumbnail(
                                                                        currDate,
                                                                        modelContainerPair
                                                                    )
                                                            }}
                                                        />
                                                    </MultiSelectThumbnail>
                                                    <div className='padding-sm flex-grow-1'>
                                                        <div className='flex justify-between'>
                                                            <div
                                                                className='h2 text-ellipsis'
                                                                style={{
                                                                    maxWidth:
                                                                        '300px',
                                                                }}>
                                                                {marker
                                                                    ? marker.name
                                                                    : modelName}
                                                            </div>
                                                            <div>
                                                                {marker
                                                                    ? timestampToHMS(
                                                                          marker.timestamp
                                                                      )
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                        <div className='padding-0 flex flex-wrap margin-t-sm'>
                                                            {toggleHashtag ===
                                                            0 ? (
                                                                hashtagMap
                                                                    ?.get(
                                                                        bag_id
                                                                            ? bag_id
                                                                            : model
                                                                            ? model.file
                                                                            : ''
                                                                    )
                                                                    ?.sort(
                                                                        (
                                                                            a: Hashtag,
                                                                            b: Hashtag
                                                                        ) =>
                                                                            a.type -
                                                                            b.type
                                                                    )
                                                                    .map(
                                                                        (
                                                                            ht
                                                                        ) => {
                                                                            return (
                                                                                <div>
                                                                                    <AssistChip
                                                                                        className={
                                                                                            'text-ellipsis margin-r-xsm margin-b-xsm'
                                                                                        }
                                                                                        onClick={() =>
                                                                                            handleClickHashtag(
                                                                                                ht.content
                                                                                            )
                                                                                        }
                                                                                        text={`#${ht.content}`}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    )
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </>
                        )
                    })}
            {list && loadedMarkers === 0 && isFilterOn(filter) && (
                <div className='div-flex-center-full'>
                    Opps, there is nothing that matches your search.
                </div>
            )}
            {list && list.length === 0 && (
                <div className='div-flex-center-full'>
                    Opps, there is nothing here at the moment.
                </div>
            )}
            {(list === null ||
                markerMap === null ||
                thumbnailMap === null ||
                hashtagMap === null) && (
                <div className='div-flex-center-full'>
                    <LoadingSpinner width={80} height={80} />
                </div>
            )}
        </>
    )
}

function useOnScreen(ref: { current: HTMLDivElement }) {
    const [isIntersecting, setIntersecting] = useState(false)
    const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
    )
    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect()
        }
    }, [])

    return isIntersecting
}

type ObserverDivType = {
    handleOnScreen: () => void
}
/* An inivisble div that is observed to be on screen */
const ObserverDiv = ({ handleOnScreen }: ObserverDivType) => {
    const ref = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>
    const isVisible = useOnScreen(ref)

    useEffect(() => {
        handleOnScreen()
    }, [isVisible])

    return <div ref={ref} className={`visibility-hidden`} />
}

type ThumbnailImageType = {
    isMarker: boolean
    load: boolean
    model?: ModelType
    alt?: string
    image?: () => Promise<string>
    className?: string
    onClick?: () => void
}
const ThumbnailImage = ({
    isMarker,
    load,
    alt,
    model,
    image,
    className,
    onClick,
}: ThumbnailImageType) => {
    return (
        <div
            className='cursor-pointer'
            style={{ maxWidth: '360px', maxHeight: '202px' }}
            onClick={onClick}>
            {model && model.id === 'xyz' && image && (
                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                    <XYZFileIcon height={120} width={120} />
                </div>
            )}
            {model && model.id === 'glb' && image && (
                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                    <GLBFileIcon height={120} width={120} />
                </div>
            )}
            {model && model.id === 'obj' && image && (
                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                    <OBJFileIcon height={120} width={120} />
                </div>
            )}
            {!isMarker && model && model.id === 'pcd' && (
                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                    <PCDFileIcon height={120} width={120} />
                </div>
            )}
            {!isMarker && model && model.id === 'pec' && (
                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                    <DatasetIcon height={80} width={80} />
                </div>
            )}
            {!isMarker && model && model.id === 'map' && (
                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                    <MapIcon height={80} width={80} />
                </div>
            )}
            {!isMarker && model && model.id === 'dxf' && (
                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                    <MapIcon height={80} width={80} />
                </div>
            )}
            {!(
                model &&
                (model.id === 'pcd' ||
                    model.id === 'xyz' ||
                    model.id === 'pec' ||
                    model.id === 'glb' ||
                    model.id === 'obj' ||
                    model.id === 'map')
            ) &&
                image && (
                    <LazyImg
                        fetchSrc={image}
                        alt={alt ? alt : ''}
                        load={load}
                        className={`${className}`}
                        message={'Opps, something went wrong.'}
                    />
                )}
            {/* {!isMarker && model && model.id === 'mosaic' && (
        <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
          <ImageIcon height={120} width={120} />
        </div>
      )} */}
        </div>
    )
}
