/**
 * Utilities for handling of hashtags
 * Hashtags have two types: 'structure' and 'component'
 * msalFetchHashtagList    - to fetch all available hashtags
 * msalFetchHashtagIds     - to fetch all ids that belong to the hashtag
 * msalAddHashtag          - to add a new hashtag
 * msalRemoveIdFromHashtag - to remove an id from hashtag
 * msalAddIdsToHashtag      - to add an id to a hashtag
 * msalUpdateHashtag       - to move an id from the old hashtag to a new hashtag
 */

import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { getBlob, getBlobsInContainer } from './'
import { Hashtag, HashtagType } from '../types'
import { uploadBlob } from './msal/put'
import { deleteBlob } from './msal/delete'

type MsalInstanceType = {
  instance: IPublicClientApplication
  account: AccountInfo | null
  storage: string
  asset: string
}

export const HASHTAG_STG = 'hashtags'

/**
 * Fetches all hashtags available
 */
export const msalFetchHashtagList = async (
  msal: MsalInstanceType,
  type: HashtagType,
  removeJsonSuffix = true
) => {
  let list = []
  try {
    const json =
      (await getBlobsInContainer(
        msal.instance,
        msal.account,
        msal.storage,
        msal.asset,
        `${HashtagType[type].toLowerCase()}${HASHTAG_STG}`
      ))?.filter(isValidHashtagBlob) || []
    if (!removeJsonSuffix) return json
    for (const item of json) {
      list.push(item.split('.json')[0])
    }
    return list
  } catch (error) {
    console.log('hashtag', error)
    return list
  }
}

/**
 * Fetches all ids from hashtag.json
 */
export const msalFetchHashtagIds = async (
  msal: MsalInstanceType,
  type: HashtagType,
  hashtag: string
) => {
  try {
    const json = getBlob(
      msal.instance,
      msal.account,
      msal.storage,
      msal.asset,
      `${hashtag}.json`,
      `${HashtagType[type].toLowerCase()}${HASHTAG_STG}`
    )
    return json
  } catch (error) {
    console.log('hashtag', error)
    return null
  }
}

/**
 * Add hashtag
 * @returns {number} 0 for success, 1 for already exists, 2 for error
 */
export const msalAddHashtag = async (
  msal: MsalInstanceType,
  type: HashtagType,
  id: string,
  oldHashtag: string,
  newHashtag: string
) => {
  if (oldHashtag !== '') {
    // Remove ID from hashtag if there is an existing ID
    msalRemoveIdFromHashtag(msal, type, id, oldHashtag)
  }
  const existingList = await msalFetchHashtagList(msal, type)
  if (existingList.includes(newHashtag)) return 1 // If hashtag already exist, dont create new one
  try {
    await uploadBlob(
      msal.instance,
      msal.account,
      msal.storage,
      msal.asset,
      `${newHashtag}.json`,
      JSON.stringify([id]),
      `${HashtagType[type].toLowerCase()}${HASHTAG_STG}`
    )
    return 0
  } catch (error) {
    console.log('hashtag', error)
    return 2
  }
}
/**
 * Remove id from hashtag.json
 * @returns {number} 0 for success, 1 for already exists, 2 for error
 */
export const msalRemoveIdFromHashtag = async (
  msal: MsalInstanceType,
  type: HashtagType,
  id: string,
  hashtag: string
) => {
  if (hashtag == '') return 0
  try {
    const existingIds = await msalFetchHashtagIds(msal, type, hashtag)
    const index = existingIds.indexOf(id)
    if (index > -1) {
      existingIds.splice(index, 1)
    }
    await uploadBlob(
      msal.instance,
      msal.account,
      msal.storage,
      msal.asset,
      `${hashtag}.json`,
      JSON.stringify(existingIds),
      `${HashtagType[type].toLowerCase()}${HASHTAG_STG}`
    )
    return 0
  } catch (error) {
    console.log('hashtag', error)
    return 2
  }
}

/**
 * Remove id from hashtag.json
 * @returns {number} 0 for success, 1 for already exists, 2 for error
 */
export const msalAddIdsToHashtag = async (
  msal: MsalInstanceType,
  type: HashtagType,
  ids: string[],
  hashtag: string
) => {
  if (hashtag == '') return 0
  try {
    const existingIds = await msalFetchHashtagIds(msal, type, hashtag)
    const res = removeDuplicate([...existingIds, ...ids])
    await uploadBlob(
      msal.instance,
      msal.account,
      msal.storage,
      msal.asset,
      `${hashtag}.json`,
      JSON.stringify(res),
      `${HashtagType[type].toLowerCase()}${HASHTAG_STG}`
    )
    return 0
  } catch (error) {
    console.log('hashtag', error)
    return 2
  }
}

/**
 * Update hashtag for item
 * @returns {number} 0 for success, 1 for already exists, 2 for error
 */
export const msalUpdateHashtag = async (
  msal: MsalInstanceType,
  type: HashtagType,
  id: string,
  oldHashtag: string,
  newHashtag: string
) => {
  try {
    await msalRemoveIdFromHashtag(msal, type, id, oldHashtag)
    await msalAddIdsToHashtag(msal, type, [id], newHashtag)
    console.log('hashtag remove', oldHashtag)
    console.log('hashtag add', newHashtag)
    return 0
  } catch (error) {
    console.log('hashtag', error)
    return 2
  }
}

/**
 * Delete hashtag
 * @returns {number} 0 for success, 1 for already exists, 2 for error
 */
export const msalDeleteHashtag = async (
  msal: MsalInstanceType,
  type: HashtagType,
  hashtag: string
) => {
  try {
    await deleteBlob(
      msal.instance,
      msal.account,
      msal.storage,
      msal.asset,
      `${hashtag}.json`,
      `${HashtagType[type].toLowerCase()}${HASHTAG_STG}`
    )
    console.log('hashtag remove', hashtag)
    return 0
  } catch (error) {
    console.log('hashtag', error)
    return 2
  }
}

/**
 * Update hashtag for item
 * @returns {number} 0 for success, 1 for already exists, 2 for error
 */
export const msalRenameHashtag = async (
  msal: MsalInstanceType,
  type: HashtagType,
  oldHashtag: string,
  newHashtag: string
) => {
  try {
    const oldJson = await getBlob(
      msal.instance,
      msal.account,
      msal.storage,
      msal.asset,
      `${oldHashtag}.json`,
      `${HashtagType[type].toLowerCase()}${HASHTAG_STG}`
    )
    await uploadBlob(
      msal.instance,
      msal.account,
      msal.storage,
      msal.asset,
      `${newHashtag}.json`,
      JSON.stringify(oldJson),
      `${HashtagType[type].toLowerCase()}${HASHTAG_STG}`
    )
    await deleteBlob(
      msal.instance,
      msal.account,
      msal.storage,
      msal.asset,
      `${oldHashtag}.json`,
      `${HashtagType[type].toLowerCase()}${HASHTAG_STG}`
    )
    console.log('hashtag remove', oldHashtag)
    console.log('hashtag add', newHashtag)
    return 0
  } catch (error) {
    console.log('hashtag', error)
    return 2
  }
}

function isValidHashtagBlob(name: string) {
  const expectedFileExtension = '.json'
  const minLength = expectedFileExtension.length
  return (
    name.length > minLength &&
    name.endsWith(expectedFileExtension) &&
    !name.includes('<no name>')
  )
}

function removeDuplicate(array: string[]) {
  var a = array.concat()
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1)
    }
  }

  return a
}
