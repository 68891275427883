import React, { useState, useEffect, useRef } from 'react'
import ExpandLessIcon from '../../assets/icons/expand_less_black_24dp.svg'
import ExpandMoreIcon from '../../assets/icons/expand_more_black_24dp.svg'

type DropdownProps = {
  list: string[]
  selected: string
  select: (value: string) => void
  className?: string
  isMinimal?: boolean
  width?: string
  wrappingOverflowingOptions?: boolean
  noOptionListTopGap?: boolean
}

const Dropdown = (props: DropdownProps) => {
  const {
    list,
    selected,
    select,
    className,
    width,
    isMinimal,
    wrappingOverflowingOptions,
    noOptionListTopGap,
  } = props
  const [expand, setExpand] = useState(false)
  const handleSelect = (idx: number) => {
    select(list[idx])
    setExpand(false)
  }

  let paddingInPx = 0
  if (className?.includes('player-panel-and-dropdown'))
    paddingInPx = 10  // A bit not generalised, but works for now

  const hasCustomWidthHeightClass = className?.includes('h-') || className?.includes('w-') || width
  const defaultWidthHeightClass = hasCustomWidthHeightClass ? '' : 'dropdown'
  const customWidthStyle = width ? { width: width } : undefined

  const optionsListStyle: {width: string, top?: string} =
    {width: `calc(100% - ${paddingInPx * 2}px)`}
  if (noOptionListTopGap) optionsListStyle.top = '100%'

  return (
    <div
      className={`${defaultWidthHeightClass} ${className}`}
      onBlur={() => setExpand(false)}
      style={customWidthStyle}
    >
      {expand && (
        <div 
          className={`${isMinimal ? 'minimal-' : ''}dropdown-list rounded-xsm z-1`}
          style={optionsListStyle}
        >
          {list.map((value, idx) => (
            <div
              style={wrappingOverflowingOptions ? { overflow: 'wrap', whiteSpace: 'normal' } : undefined}
              className={'dropdown-option'}
              key={idx}
              onClick={() => handleSelect(idx)}>
              {value}
            </div>
          ))}
        </div>
      )}
      <div
        className='dropdown-button rounded-xsm'
        onClick={() => setExpand(!expand)}>
        <div>{selected}</div>
        {!expand && (
          <div style={{ width: '24px', height: '24px' }}>
            <ExpandLessIcon />
          </div>
        )}
        {expand && (
          <div style={{ width: '24px', height: '24px' }}>
            <ExpandMoreIcon />
          </div>
        )}
      </div>
    </div>
  )
}

export default Dropdown
