import React from 'react'
import TextButton from '../elements/buttons/TextButton'

type ContextMenuProps = {
  options: string[]
  top: number
  left: number
  onClick: (idx: number) => void
  onClose: () => void
}

const ContextMenu = ({
  options,
  top,
  left,
  onClick,
  onClose,
}: ContextMenuProps) => {
  return (
    <div
      className='fixed top-0 left-0 w-100 h-100 z-10'
      onClick={() => onClose()}>
      <div
        className='surface-variant-bg rel flex-col rounded-sm'
        style={{ width: '160px', top: top, left: left }}>
        {options.map((value, idx) => (
          <TextButton maxWidth text={value} onClick={() => onClick(idx)} />
        ))}
      </div>
    </div>
  )
}

export default ContextMenu
