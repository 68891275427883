import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    ImageRun,
    SectionType,
    IImageOptions,
    ISectionOptions,
    AlignmentType,
    Header,
    IFloating,
    HorizontalPositionAlign,
    Footer,
    PageNumber,
    ParagraphChild,
    Table,
    TableRow,
    TableCell,
    VerticalAlign,
    BorderStyle,
    IShadingAttributesProperties,
    convertMillimetersToTwip,
    ShadingType,
    ITableCellBorders,
    HeightRule,
    VerticalPositionRelativeFrom,
    IPageMarginAttributes,
    ISectionPropertiesOptions,
    TableLayoutType,
} from 'docx'
import { Hashtag, MarkerType } from '../../../../types'
import { ImageDetail, ImgDims } from '../types'
import {
    BORDER_NONE,
    BORDER_THICK,
    CELL_MARGIN_ALIGN_LEFT,
    CELL_MARGIN_ALIGN_RIGHT,
    CELL_NO_MARGIN_TOP_BOT,
    DXA_PER_IMG_PIXEL,
    FONT_FAMILY,
    FontSizes,
    SHADING_CLEAR,
    SHADING_GREY,
    contentWidthInMm,
    defaultSectionPropertiesOption,
    leftMarginInCm,
} from '../constants'
import {
    dummyParagraph,
    getClearDummyCell,
    getGreyDummyCell,
} from '../elements/Dummy'
import {
    wrapElementInCell,
    wrapTextInCell,
    wrapTextInInvisibleCell,
} from '../elements/Cell'
import { roundValue } from '../../MathUtils'
import { processStrSeacp } from '../../DataUtils'
import {
    getMarkerDateNTime,
    getDataBoxLeftColVals,
} from '../../ReportGenerationCommonUtils'
import { getImageRun } from '../elements/ImageRun'
import { toTableRowBaseFunc } from '../elements/Row'

// Hashtag configurations
const hashtagContentRowHeight = 362
const gapBetweenHashtagsInMm = 3.78
const mmPerCharInHashtag = 2
const numOfBufferChars = 3.6 // Used for left-right margin of hashtags

// Marker images configurations
const markerImagesMiddleSpacingHeight = 362 - 15
const frontCamCaptionText = 'Front Camera'
const sonarCaptionText = 'Sonar'
const snapshotImageMaxWidth = 432
const snapshotImageMaxHeight = 240

// Databox labels & units configurations
const syv21Labels = ['Coordinates:', 'Northing:', 'Easting:']
const latLngLabels = ['Latitude:', 'Longitude:']
const otherDataBoxLabels = ['Heading:', 'Depth:', 'Altitude:']
const commentsLabel = 'Comments:'
const cpLabel = 'CP:'
const cpUnit = 'V'
const utLabel = 'UT:'
const utUnit = 'mm'
const seaCpContactLabel = 'CP Probe Contact:'
const seaCpFieldGradientLabel = 'CP Probe Field Gradient:'
const seaCpProximityLabel = 'CP Probe Proximity:'
const seaCpUnit = 'mV'
const pecThicknessLabel = 'PEC Thickness:'
const pecCalibrationLabel = 'PEC Calibration:'
const pecUnit = 'mm'

// Databox overall configurations
const defaultRowHeightForText = 250 // Used for databox & marker image captions
const dataBoxTopMarginHeight = defaultRowHeightForText * 0.75
const dummyCellWidthInMm = 5.5
const dataBoxNumOfRows = 14
const totalNumOfCols = 6
const warningIconMarginOptions = { top: 100 }
const warningIconColWidthInMm = 10.5

const leftDataColHalfWidthInMm = 40.3 - 0.25
// Halfwidth as there are 2 subcolumns in the left portion
const labelSubColWidthInMm = leftDataColHalfWidthInMm * 1.1
// Make it longer to cater to 'CP Probe Field Gradient:'
const valueSubColWidthInMm = leftDataColHalfWidthInMm * 2 - labelSubColWidthInMm

export const createMarkerSection = async (
    storage: string,
    hashtagMap: Map<string, Hashtag[]>,
    marker: MarkerType,
    frontCamImageDetail: ImageDetail | null,
    sonarImageDetail: ImageDetail | null,
    usingSVY21: boolean,
    header: { default: Header } | undefined,
    footer: { default: Footer },
    warningIconImage: ImageRun
) => {
    const hashtags = hashtagMap.get(marker.bag_id)
    const hasAnyHashtag = hashtags !== undefined && hashtags.length > 0
    const children = [
        getMarkerTitleSection(marker, storage),
        dummyParagraph,
        getDataBox(marker, usingSVY21, warningIconImage, hasAnyHashtag),
    ]
    if (hasAnyHashtag) children.push(dummyParagraph, getHashtagsTable(hashtags))
    children.push(
        dummyParagraph,
        await getMarkerImages(frontCamImageDetail, sonarImageDetail)
    )
    return {
        headers: header,
        footers: footer,
        properties: defaultSectionPropertiesOption,
        children: children,
    } as ISectionOptions
}

const getMarkerTitleSection = (marker: MarkerType, storage: string) => {
    const { date: dateText, time: timeText } = getMarkerDateNTime(marker)
    const markerName = wrapTextInInvisibleCell(
        marker.name,
        AlignmentType.LEFT,
        FontSizes.title
    )
    const storageName = wrapTextInInvisibleCell(storage, AlignmentType.LEFT)
    const date = wrapTextInInvisibleCell(dateText, AlignmentType.RIGHT)
    const time = wrapTextInInvisibleCell(timeText, AlignmentType.RIGHT)

    const headings = new Table({
        width: { size: 100, type: 'pct' },
        rows: [
            new TableRow({ children: [markerName, date] }),
            new TableRow({ children: [storageName, time] }),
        ],
    })
    return headings
}

function getDataBox(
    marker: MarkerType,
    usingSVY21: boolean,
    warningIconImage: ImageRun,
    hasAnyHashtag: boolean
) {
    const wrapTextInCellForDataBox = (
        text: string | string[],
        bold: boolean,
        alignment: (typeof AlignmentType)[keyof typeof AlignmentType],
        rowSpan: number = 1
    ) =>
        wrapTextInCell(
            text,
            bold,
            alignment,
            VerticalAlign.TOP,
            alignment == AlignmentType.RIGHT
                ? CELL_MARGIN_ALIGN_RIGHT
                : CELL_NO_MARGIN_TOP_BOT,
            SHADING_GREY,
            BORDER_NONE,
            FontSizes.text,
            rowSpan
        )

    const wrapTextInCellForDataBoxLabels = (text: string) =>
        wrapTextInCellForDataBox(text, false, AlignmentType.LEFT)

    const tabulateIntoDataRow = (label: string, value: string) => [
        wrapTextInCellForDataBoxLabels(label),
        wrapTextInCellForDataBox(value, true, AlignmentType.RIGHT),
    ]

    const leftColLabels = [...otherDataBoxLabels]
    const leftColValues = getDataBoxLeftColVals(marker, usingSVY21)
    if (marker.cp) {
        leftColLabels.push(cpLabel)
        leftColValues.push(marker.cp.toFixed(2) + cpUnit)
    }
    if (marker.ut) {
        leftColLabels.push(utLabel)
        leftColValues.push(`${roundValue(marker.ut, 3)}${utUnit}`)
    }
    const strSeaCp = marker.str_seacp ? processStrSeacp(marker.str_seacp) : null
    if (strSeaCp) {
        leftColLabels.push(
            seaCpContactLabel,
            seaCpFieldGradientLabel,
            seaCpProximityLabel
        )
        leftColValues.push(
            Math.round(strSeaCp.contact) + seaCpUnit,
            Math.round(strSeaCp.field_gradient) + seaCpUnit,
            strSeaCp.proximity !== null
                ? Math.round(strSeaCp.proximity) + seaCpUnit
                : '-'
        )
    }
    if (marker.pec) {
        leftColLabels.push(pecThicknessLabel, pecCalibrationLabel)
        leftColValues.push(
            `${marker.pec.thickness} ${pecUnit}`,
            `${marker.pec.calibration || '-'} ${pecUnit}`
        )
    }

    if (usingSVY21) leftColLabels.unshift(...syv21Labels)
    else leftColLabels.unshift(...latLngLabels)

    const dataBoxExcludeHashtagsNumOfRows = dataBoxNumOfRows

    const dummyColumn = getGreyDummyCell(1, dataBoxExcludeHashtagsNumOfRows)

    const contentColumnSpan = totalNumOfCols - 2
    const topMarginRow = getGreyDummyCell(contentColumnSpan)

    const dataBoxRowsChildren = [[dummyColumn, topMarginRow]]
    if (marker.no_anode) {
        const warningIconCell = wrapElementInCell(
            warningIconImage,
            AlignmentType.CENTER,
            warningIconMarginOptions,
            SHADING_GREY,
            dataBoxExcludeHashtagsNumOfRows
        )
        dataBoxRowsChildren[0].push(warningIconCell)
    } else dataBoxRowsChildren[0].push(dummyColumn)

    const dataBoxContentStartIdx = dataBoxRowsChildren.length
    const numOfLabelsInLeftCol = leftColLabels.length
    for (let i = 0; i < numOfLabelsInLeftCol; i++) {
        const newRowChildren = tabulateIntoDataRow(
            leftColLabels[i],
            leftColValues[i]!
        )
        dataBoxRowsChildren.push(newRowChildren)
    }

    const rightColLabelCell = wrapTextInCellForDataBoxLabels(commentsLabel)
    const middleMargin = getGreyDummyCell(
        1,
        dataBoxExcludeHashtagsNumOfRows - 1
    )
    // Minus one for the top margin
    dataBoxRowsChildren[dataBoxContentStartIdx].push(
        middleMargin,
        rightColLabelCell
    )

    const rightColValueCell = wrapTextInCellForDataBox(
        marker.comment ? marker.comment.split('\n') : '-',
        true,
        AlignmentType.LEFT,
        dataBoxExcludeHashtagsNumOfRows - 2
        // Minus one for top margin, another for "Comments:" label
    )
    dataBoxRowsChildren[dataBoxContentStartIdx + 1].push(rightColValueCell)

    while (dataBoxRowsChildren.length < dataBoxExcludeHashtagsNumOfRows)
        dataBoxRowsChildren.push([getGreyDummyCell(), getGreyDummyCell()])

    const rightMarginColWidthInMm = marker.no_anode
        ? warningIconColWidthInMm
        : dummyCellWidthInMm
    const rightDataColWidthInMm =
        contentWidthInMm -
        leftDataColHalfWidthInMm * 2 -
        dummyCellWidthInMm * 2 -
        rightMarginColWidthInMm

    const columnWidthsInMm = [
        dummyCellWidthInMm,
        labelSubColWidthInMm,
        valueSubColWidthInMm,
        dummyCellWidthInMm,
        rightDataColWidthInMm,
        rightMarginColWidthInMm,
    ]

    const getDataBoxTopMargin = toTableRowBaseFunc(dataBoxTopMarginHeight)
    const toTableRow = toTableRowBaseFunc(defaultRowHeightForText)

    const dataBox = new Table({
        width: { size: 100, type: 'pct' },
        layout: TableLayoutType.FIXED,
        columnWidths: columnWidthsInMm.map(convertMillimetersToTwip),
        rows: dataBoxRowsChildren.map((cells, idx) =>
            idx === 0 ? getDataBoxTopMargin(cells) : toTableRow(cells)
        ),
    })
    return dataBox
}

const getHashtagsTable = (hashtagList: Hashtag[] | undefined) => {
    let columnWidthsInMm = undefined
    let hashtagsRowChildren = [getClearDummyCell()]
    if (hashtagList && hashtagList.length > 0) {
        const wrapHashtagInCell = (hashtag: Hashtag) =>
            wrapTextInCell(
                '#'.concat(hashtag.content.trim()),
                false,
                AlignmentType.CENTER,
                VerticalAlign.CENTER,
                CELL_NO_MARGIN_TOP_BOT,
                SHADING_GREY,
                BORDER_THICK,
                FontSizes.hashtag
            )

        const getHashtagCellWidth = (hashtag: Hashtag) =>
            (hashtag.content.trim().length + numOfBufferChars) *
            mmPerCharInHashtag

        columnWidthsInMm = [
            dummyCellWidthInMm,
            getHashtagCellWidth(hashtagList[0]),
        ]
        let remainingWidth =
            contentWidthInMm - columnWidthsInMm[0] - columnWidthsInMm[1]
        hashtagsRowChildren = [
            getClearDummyCell(),
            wrapHashtagInCell(hashtagList[0]),
        ]

        for (let i = 1; i < hashtagList.length; i++) {
            const hashtagCell = wrapHashtagInCell(hashtagList[i])
            hashtagsRowChildren.push(getClearDummyCell(), hashtagCell)
            columnWidthsInMm.push(
                dummyCellWidthInMm,
                getHashtagCellWidth(hashtagList[i])
            )
            remainingWidth -= gapBetweenHashtagsInMm
        }
        hashtagsRowChildren.push(getClearDummyCell())
        columnWidthsInMm.push(remainingWidth)
    }

    return new Table({
        width: { size: 100, type: 'pct' },
        layout: TableLayoutType.FIXED,
        columnWidths: columnWidthsInMm?.map(convertMillimetersToTwip),
        rows: [
            new TableRow({
                children: hashtagsRowChildren,
                height: {
                    value: hashtagContentRowHeight,
                    rule: HeightRule.EXACT,
                },
            }),
        ],
    })
}

const getMarkerImages = async (
    frontCamImageDetail: ImageDetail | null,
    sonarImageDetail: ImageDetail | null
) => {
    class ImagesDisplayComponents {
        element: ParagraphChild | null
        rowHeight: number
        alignment?: (typeof AlignmentType)[keyof typeof AlignmentType]

        static getImages(imageRuns: ImageRun, heightInImagePixels: number) {
            return new ImagesDisplayComponents(
                imageRuns,
                heightInImagePixels * DXA_PER_IMG_PIXEL,
                AlignmentType.CENTER
            )
        }
        static getSpacing(height: number) {
            return new ImagesDisplayComponents(null, height)
        }
        static getCaption(text: string) {
            return new ImagesDisplayComponents(
                new TextRun({
                    text: text,
                    bold: true,
                    font: FONT_FAMILY,
                    size: FontSizes.text,
                }),
                defaultRowHeightForText
            )
        }
        constructor(
            element: ParagraphChild | null,
            rowHeight: number,
            alignment?: (typeof AlignmentType)[keyof typeof AlignmentType]
        ) {
            this.element = element
            this.rowHeight = rowHeight
            this.alignment = alignment
        }
    }

    const toRowCellsNHeight = (component: ImagesDisplayComponents) => {
        let cells = [getClearDummyCell()]
        if (component.element) {
            cells = [
                wrapElementInCell(
                    component.element,
                    component.alignment,
                    CELL_MARGIN_ALIGN_LEFT,
                    SHADING_CLEAR
                ),
            ]
        }
        return { cells: cells, height: component.rowHeight }
    }

    const cellsNHeightToTableRow = ({
        cells,
        height,
    }: {
        cells: TableCell[]
        height: number
    }) => toTableRowBaseFunc(height)(cells)

    const imagesDisplayChildren: ImagesDisplayComponents[] = []

    const getScaledImgDims = (originalDims: ImgDims) => {
        const targetAspectRatio = snapshotImageMaxWidth / snapshotImageMaxHeight
        const originalAspectRatio = originalDims.width / originalDims.height

        let newWidth: number
        let newHeight: number

        if (targetAspectRatio > originalAspectRatio) {
            // Border is wider than the image
            newHeight = snapshotImageMaxHeight
            newWidth = newHeight * originalAspectRatio
        } else {
            // Border is taller than the image
            newWidth = snapshotImageMaxWidth
            newHeight = newWidth / originalAspectRatio
        }
        return new ImgDims(newWidth, newHeight)
    }

    const dummyImageDims = new ImgDims(
        snapshotImageMaxWidth,
        snapshotImageMaxHeight
    )
    const dummyImage = getImageRun(new ArrayBuffer(0), dummyImageDims)
    let frontCamDims = dummyImageDims.clone()
    if (frontCamImageDetail !== null) {
        // const newScale = frontCamImageDetail.originalDims.width / 960
        // frontCamDims = frontCamImageDetail.originalDims.scale(
        //     (0.35 * 1.335) / newScale
        // )
        // console.log("CHECK", frontCamDims)
        frontCamDims = getScaledImgDims(frontCamImageDetail.originalDims)
        const frontCamImage = getImageRun(
            frontCamImageDetail.bytes,
            frontCamDims
        )
        imagesDisplayChildren.push(
            ImagesDisplayComponents.getImages(
                frontCamImage,
                frontCamDims.height
            )
        )
    } else
        imagesDisplayChildren.push(
            ImagesDisplayComponents.getImages(dummyImage, frontCamDims.height)
        )

    imagesDisplayChildren.push(
        ImagesDisplayComponents.getCaption(frontCamCaptionText),
        ImagesDisplayComponents.getSpacing(markerImagesMiddleSpacingHeight)
    )

    let sonarDims = dummyImageDims.clone()
    let sonarNGridImageRun = dummyImage
    if (sonarImageDetail !== null) {
        sonarDims = getScaledImgDims(sonarImageDetail.originalDims)
        sonarNGridImageRun = getImageRun(sonarImageDetail.bytes, sonarDims)
    }

    imagesDisplayChildren.push(
        ImagesDisplayComponents.getImages(sonarNGridImageRun, sonarDims.height),
        ImagesDisplayComponents.getCaption(sonarCaptionText)
    )

    const tableRows = imagesDisplayChildren
        .map(toRowCellsNHeight)
        .map(cellsNHeightToTableRow)

    const tableWidth =
        Math.max(sonarDims.width, frontCamDims.width) * DXA_PER_IMG_PIXEL

    const table = new Table({
        rows: tableRows,
        width: { size: tableWidth, type: 'dxa' },
        alignment: AlignmentType.CENTER,
        layout: TableLayoutType.FIXED,
    })
    return table
}
