import React from 'react'
import Chip, { ChipProps } from './Chip'

const AssistChip = (props: ChipProps) => {
  return (
    <Chip {...props} contentColor='on-surface' borderColor='outline' />
  )
}

export default AssistChip
