import {
  getStorageAccountsMSAL,
  getContainersMSAL,
  getBlobMSAL,
  getBlobsInContainerMSAL,
} from './msal/fetch'
import {
  getStorageAccountsDemo,
  getContainersDemo,
  getBlobDemo,
} from './demo/fetch'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { callApiWithToken } from './msal/fetch'
import { scopes } from './msal/config'
import {
  unregexStorageName,
  unregexAssetName,
  regexAssetName,
} from '../components/utils/general'
export { msalFetchImage } from './images'

export const getStorageAccounts = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null
) => {
  if (account) {
    return await getStorageAccountsMSAL(instance, account)
  } else {
    return await getStorageAccountsDemo()
  }
}

export const getContainers = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  storage: string
) => {
  if (account) {
    const promiseContainers = await getContainersMSAL(
      instance,
      account,
      storage
    )
    let containers: string[] = []
    for (let promise of promiseContainers) {
      const container = await promise
      container != '' ? containers.push(container) : null
    }
    return containers
  } else {
    return await getContainersDemo(storage)
  }
}

export const getFolders = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  storage: string,
  container: string
) => {
  if (account) {
    const fileNames = await getBlobsInContainerMSAL(
      instance,
      account,
      storage,
      container
    )
    let output = new Set<string>()
    for (let file of fileNames) {
      const nameArray = file.split('/')
      output.add(nameArray[0])
    }
    return Array.from(output.values())
  } else {
    return []
  }
}

export const getBlob = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  storage: string,
  container: string,
  blob: string,
  subdirectory?: string,
  throwingErrorIf404?: boolean
) => {
  try {
    if (account) {
      return await getBlobMSAL(
        instance,
        account,
        storage,
        container,
        blob,
        subdirectory,
        throwingErrorIf404
      )
    } else {
      return await getBlobDemo(container, blob)
    }
  } catch (error) {
    throw error
  }
}

export const getBlobsInContainer = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  storage: string,
  container: string,
  subdirectory?: string
) => {
  if (account) {
    const blobs = await getBlobsInContainerMSAL(
      instance,
      account,
      storage,
      container,
      subdirectory
    ) // returns blobs with their subdirectory

    let updatedBlobs = []
    for (let blob of blobs) {
      const blobArray = blob.split('/')
      updatedBlobs.push(blobArray[blobArray.length - 1]) // gets file name only
    }

    return updatedBlobs
  } else {
    // TODO: return await getBlobDemo(container, blob)
  }
}
