/**
 * Mosaic Helper Functions
 * To load mosaic into ThreeJS scene
 */
import * as THREE from 'three'
import { getPixelRes, utmToScene } from '../../map/utils/CoordHelper/CoordUtils'
import { LatLngCoord, ModelType } from '../../../types'
import { readMetadata } from '../../map/utils/png-metadata'

export type Mosaic = {
    width: number
    height: number
    northing: number
    easting: number
    zone_letter: string
    zone_num: number
    data: Buffer
  }

export async function getMosaicFromFile(
    file: File,
    mapTileOrigin: LatLngCoord
  ) {
    // Check if mosaic contains all metadata
    const buffer = new Uint8Array(await file.arrayBuffer())
    const metadata = readMetadata(buffer).tEXt

    if (
        !(
            metadata['mosaic.northing'] &&
            metadata['mosaic.zone_num'] &&
            metadata['mosaic.zone_letter'] &&
            metadata['mosaic.heading'] &&
            metadata['mosaic.height'] &&
            metadata['mosaic.width']
        )
    ) {
        alert(
            '.png file is corrupted! Please select another .png file.'
        )
        return
    }
    const mosaic = {
        width: metadata['mosaic.width'],
        height: metadata['mosaic.height'],
        northing: metadata['mosaic.northing'],
        easting: metadata['mosaic.easting'],
        zone_letter: metadata['mosaic.zone_letter'],
        zone_num: metadata['mosaic.zone_num'],
        data: Buffer.from(buffer)
    }
    return await getMosaic(mosaic, mapTileOrigin)
  }
  
  export async function getMosaic(mosaic: Mosaic, mapOrigin: LatLngCoord) {
    if (mosaic.northing == 0 || mosaic.easting == 0) return
    const { x, y } = utmToScene(
      {
        northing: mosaic.northing,
        easting: mosaic.easting,
        zone_number: mosaic.zone_num || 48,
        zone_letter: mosaic.zone_letter || 'N'
      },
      mapOrigin
    )
    const mosaicGeometry = new THREE.PlaneGeometry(
      mosaic.width,
      mosaic.height,
      10,
      10
    )
    const texture = new THREE.Texture()
    const image = new Image()
    image.src = `data:png;base64,${mosaic.data.toString('base64')}`
    image.onload = function () {
      texture.image = image
      texture.needsUpdate = true
    }
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.FrontSide,
      opacity: 0.8,
      transparent: true
    })
    const mesh = new THREE.Mesh(mosaicGeometry, material)
    mesh.scale.setScalar(getPixelRes())
    mesh.position.set(x, y, 0.2)
    return mesh
  }