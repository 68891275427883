import React, { useEffect, useState } from 'react'
import FolderIcon from '../../../assets/icons/folder_black_24dp.svg'
import FileIcon from '../../../assets/icons/description_black_24dp.svg'
import DownArrowIcon from '../../../assets/icons/expand_more_black_24dp.svg'
import UpArrowIcon from '../../../assets/icons/expand_less_black_24dp.svg'
import IconButton from '../buttons/IconButton'
import { MsFolder } from '../../../types'
import TextButton from '../buttons/TextButton'
import { readableFileSize, readableDate } from '../../utils/general'

type FileDirectoryDialogProps = {
  stgAccs: string[]
  dir: MsFolder[]
  list: any[]
  selectFolder: (value: MsFolder) => void
  selectAccount: (value: string) => void
}

const FileDirectoryDialog = ({
  stgAccs,
  dir,
  list,
  selectFolder: selectFile,
  selectAccount,
}: FileDirectoryDialogProps) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [files, setFiles] = useState<any[]>([])
  const [folders, setFolders] = useState<any[]>([])
  useEffect(() => {
    if (list) {
      setFiles(list.filter((item) => !item.folder))
      setFolders(list.filter((item) => item.folder))
    }
  }, [list])
  return (
    <div className='flex-grow-1 flex flex-col border-box padding-w-xlg padding-h-md'>
      <div className='flex margin-b-md align-center rel'>
        <div className='margin-r-md text-bold text-xlg'>Your data</div>
        {dir.map((folder, idx) => (
          <>
            {idx == 0 && stgAccs.length > 1 && (
              <>
                <div className='text-bold text-xlg'>&gt;</div>
                <TextButton
                  minWidth
                  iconRight
                  Icon={showDropdown ? UpArrowIcon : DownArrowIcon}
                  className='text-bold text-xlg'
                  text={folder.name}
                  onClick={() => {
                    setShowDropdown(!showDropdown)
                  }}
                />
              </>
            )}
            {(idx >= 1 || stgAccs.length == 1) && (
              <>
                <div className='text-bold text-xlg'>&gt;</div>
                <TextButton
                  minWidth
                  className='text-bold text-xlg'
                  text={folder.name}
                  onClick={() => selectFile(folder)}
                />
              </>
            )}
          </>
        ))}
        {showDropdown && (
          <div
            className='abs overflow-auto surface-variant-bg padding-w-sm padding-h-sm rounded-sm z-dialog'
            style={{ top: '42px', left: '120px', maxHeight: '240px' }}>
            {stgAccs.map((value) => (
              <TextButton
                maxWidth
                text={value}
                onClick={() => {
                  if (dir.length > 1 && dir[1].name == value) {
                    selectFile(dir[1])
                  } else {
                    selectAccount(value)
                  }
                  setShowDropdown(false)
                }}
              />
            ))}
          </div>
        )}
      </div>
      <div className='flex flex-col flex-grow-1 overflow-auto padding-r-sm'>
        {files.length == 0 && folders.length == 0 && (
          <div>Opps, looks like there is nothing here.</div>
        )}
        {(files.length > 0 || folders.length > 0) && (
          <div className='flex flex-col min-height-min-content'>
            {folders.length > 0 && (
              <>
                <div className='text-bold text-lg margin-b-md'>Folders</div>
                {folders.map((item) => {
                  return (
                    <div className='margin-b-md margin-r-sm'>
                      <FolderItem
                        name={item.name}
                        onClick={() =>
                          selectFile({
                            name: item.name,
                            folder_id: item.id,
                            drive_id: item.parentReference.driveId,
                          })
                        }
                      />
                    </div>
                  )
                })}
              </>
            )}
            {files.length > 0 && (
              <>
                <div className='text-bold text-lg margin-b-md'>Files</div>
                {files.map((item) => {
                  return (
                    <FileItem
                      name={item.name}
                      size={item.size}
                      date={item.lastModifiedDateTime}
                      onClick={() =>
                        window.open(
                          item['@microsoft.graph.downloadUrl'],
                          '_blank'
                        )
                      }
                    />
                  )
                })}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default FileDirectoryDialog

type FileItemProps = {
  name: string
  size: number
  date: string
  onClick: () => void
}

const FileItem = ({ name, size, date, onClick }: FileItemProps) => {
  const [hover, setHover] = useState(false)
  return (
    <div
      className={`${
        hover ? 'surface-variant-bg' : ''
      } flex align-center padding-h-sm padding-w-md cursor-pointer`}
      onClick={() => onClick()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <FileIcon height={24} width={24} />
      <div className='margin-l-sm flex-grow-1'>{name}</div>
      <div className='margin-w-sm'>{readableFileSize(size)}</div>
      <div className='text-right' style={{ width: '180px' }}>
        {readableDate(date)}
      </div>
    </div>
  )
}

type FolderItemProps = {
  name: string
  onClick: () => void
}

const FolderItem = ({ name, onClick }: FolderItemProps) => {
  return (
    <IconButton
      Icon={FolderIcon}
      text={name}
      contentColor='on-surface-variant'
      containerColor='surface-variant'
      onClick={onClick}
    />
  )
}
