import React, { useState } from 'react'

export type ButtonProps = {
    className?: string
    text?: string
    Icon?:
        | React.FunctionComponent<React.SVGAttributes<SVGElement>>
        | JSX.Element
    containerColor?: string
    contentColor?: string
    borderColor?: string
    selectedContainerColor?: string
    selectedContentColor?: string
    selected?: boolean
    disabled?: boolean
    maxWidth?: boolean
    minWidth?: boolean
    badge?: number
    helperText?: string
    iconHeight?: number
    iconWidth?: number
    iconRight?: boolean
    rotateIcon?: number
    onClick: () => void
    onDoubleClick?: () => void
    onMouseOver?: () => void
    onMouseOut?: () => void
    onContextMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

/**
 * Button
 * @param {string} className - Additional classes to be passed on
 * @param {string} text - Text to be displayed
 * @param {SVGElement} Icon - Icon to be displayed
 * @param {string} containerColor - Color for the container
 * @param {string} contentColor - Color for the text/icon
 * @param {string} borderColor - Color for the border
 * @param {string} selectedContainerColor - Color for the container if a selected state is required
 * @param {string} selectedContentColor - Color for the content if a selected state is required
 * @param {boolean} selected - Set this variable to select button
 * @param {boolean} disabled - Set this variable to disable button
 * @param {boolean} maxWidth - Set this variable for button to take up maximum width
 * @param {boolean} minWidth - Set this variable for button to use small padding
 * @param {boolean} helperText - Mouseover tooltip text
 * @param {boolean} rotateIcon - Rotate icon in degrees
 * @returns A button
 */
const Button = ({
    className,
    text,
    rotateIcon,
    Icon,
    containerColor,
    contentColor,
    selectedContainerColor,
    selectedContentColor,
    borderColor,
    selected,
    disabled,
    maxWidth,
    minWidth,
    helperText,
    iconHeight,
    iconWidth,
    iconRight,
    badge,
    onClick,
    onDoubleClick,
    onMouseOver,
    onMouseOut,
    onContextMenu,
}: ButtonProps) => {
    const [hover, setHover] = useState(false)
    const [pointerDown, setPointerDown] = useState(false)
    return (
        <div className='rel'>
            {helperText && hover && !disabled && (
                <div
                    className='surface-variant-bg rounded-sm padding-sm text-medium no-wrap abs z-1'
                    style={{ top: '-24px', left: '24px' }}>
                    {helperText}
                </div>
            )}
            {badge !== undefined && badge > 0 && (
                <div
                    className='primary-bg on-primary rounded-lg flex justify-center text-xsm text-bold text-center no-wrap abs z-1'
                    style={{
                        top: '-4px',
                        right: '-4px',
                        height: '24px',
                        width: '24px',
                        lineHeight: '24px',
                    }}>
                    {badge}
                </div>
            )}
            <div
                className={`
          ${selected ? `${selectedContainerColor}-bg` : `${containerColor}-bg`}
          ${borderColor ? `${borderColor}-border` : ''}
          ${disabled ? 'disabled-container' : 'cursor-pointer'}
          ${
              Icon
                  ? text
                      ? 'padding-l-md'
                      : 'padding-l-sm'
                  : minWidth
                  ? 'padding-l-md'
                  : 'padding-l-lg'
          }
          ${
              text
                  ? minWidth
                      ? 'padding-r-md'
                      : 'padding-r-lg'
                  : Icon
                  ? 'padding-r-sm'
                  : 'padding-r-md'
          }
          ${maxWidth ? 'flex justify-center' : 'flex-inline'}
          h-40px align-center rounded-xlg text-bold rounded-sm rel overflow-hidden
          ${className}
        `}
                onContextMenu={(e) => {
                    if (disabled) return
                    e.preventDefault()
                    onContextMenu && onContextMenu(e)
                }}
                onPointerDown={(e) => {
                    if (disabled) return
                    e.preventDefault()
                    setPointerDown(true)
                }}
                onPointerUp={(e) => {
                    if (disabled) return
                    e.preventDefault()
                    setPointerDown(false)
                    if (pointerDown) onClick()
                }}
                onDoubleClick={(e) => {
                    if (disabled) return
                    e.preventDefault()
                    onDoubleClick && onDoubleClick()
                }}
                onMouseOut={() => {
                    if (disabled) return
                    setHover(false)
                    onMouseOut && onMouseOut()
                }}
                onMouseOver={() => {
                    if (disabled) return
                    setHover(true)
                    onMouseOver && onMouseOver()
                }}>
                {Icon &&
                    !iconRight &&
                    (Icon instanceof Function ? (
                        <Icon
                            style={{ transform: ` rotate(${rotateIcon}deg)` }}
                            height={iconHeight || 24}
                            width={iconWidth || 24}
                            className={`
                ${selected ? selectedContentColor : contentColor}
                ${disabled ? 'disabled-content' : ''}
                ${text ? 'margin-r-sm' : ''}`}
                        />
                    ) : (
                        Icon
                    ))}
                {text && (
                    <div
                        className={`
              ${selected ? selectedContentColor : contentColor}
              ${disabled ? 'disabled-content' : ''} z-1`}>
                        {text}
                    </div>
                )}
                {Icon &&
                    iconRight &&
                    (Icon instanceof Function ? (
                        <Icon
                            style={{ transform: ` rotate(${rotateIcon}deg)` }}
                            height={iconHeight || 24}
                            width={iconWidth || 24}
                            className={`
                ${selected ? selectedContentColor : contentColor}
                ${disabled ? 'disabled-content' : ''}
                ${text ? 'margin-l-sm' : ''}`}
                        />
                    ) : (
                        Icon
                    ))}
                {/* State layer (Only used for hover, focused, pressed, dragged*/}
                <div
                    className={`
            ${contentColor}-bg
            ${hover ? 'hover-state' : 'off-state'}
            abs left-0 right-0 top-0 bottom-0
          `}
                />
            </div>
        </div>
    )
}

export default Button
