import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Section, SectionDictType } from '..'
import ExpandMoreIcon from '../../../assets/icons/expand_more_black_24dp.svg'
import ExpandLessIcon from '../../../assets/icons/expand_less_black_24dp.svg'
import DownloadIcon from '../../../assets/icons/file_download_black_24dp.svg'
import FilledTonalButton from '../../elements/buttons/FilledTonalButton'
import { useAccount, useMsal } from '@azure/msal-react'
import { ActivityType, Activities, UserManualType } from '../../../types'
import { addLog } from '../../utils/user_activities'

type NavigationPanelProps = {
  sections: SectionDictType
  setSelect: (select: number[]) => void
  downloadPDF: () => void
}

export type NavigationPanelHandle = {
  setSelect: (value: number[]) => void
}

const NavigationPanel = forwardRef<NavigationPanelHandle, NavigationPanelProps>(
  (props, ref) => {
    const [select, setSelect] = useState<number[]>([0])
    useImperativeHandle(ref, () => ({
      setSelect,
    }))

    const { instance, accounts } = useMsal()
    const username = accounts.length > 0 ? accounts[0].username : 'demo@beex.sg'
    const account = useAccount(accounts[0] || {})

    const logManualAccess = (idx: number) => {
      const accessActivity: ActivityType = {
        timestamp: new Date().toISOString(),
        user: username,
        action: Activities[Activities.ACCESSMANUAL],
        manual: UserManualType[idx], // idx dependent on manual downloaded
      }
      account && addLog('user1manual', 'log', instance, account, accessActivity)
    }

    useEffect(() => {
      logManualAccess(select[0])
    }, [select[0]])

    return (
      <div className='black-bg w-100 h-100 rounded-md padding-xlg border-box flex flex-col'>
        <div className='text-bold padding-b-md'>User Manuals</div>
        <div className='flex-grow-1'>
          {Object.entries(props.sections).map(([k, v], idx) => (
            <NavigationItem
              key={idx}
              section={[idx]}
              select={select}
              name={k}
              item={v}
              onSelect={(select) => {
                setSelect(select)
                props.setSelect(select)
              }}
            />
          ))}
        </div>
        <div className='flex align-center margin-t-xlg'>
          <FilledTonalButton
            text='PDF'
            Icon={DownloadIcon}
            onClick={() => props.downloadPDF()}
          />
        </div>
      </div>
    )
  }
)

export default NavigationPanel

type NavigationItemProps = {
  name: string
  section: number[]
  select: number[]
  item: Section
  onSelect: (idx: number[]) => void
}

const NavigationItem = (props: NavigationItemProps) => {
  const [hover, setHover] = useState(false)
  const [expand, setExpand] = useState(false)
  useEffect(() => {
    setExpand(shouldExpand(props.section, props.select))
  }, [props.select])
  const childrenLen = Object.entries(props.item.children).length
  return (
    <div className='flex-grow-1'>
      <div
        className={`flex flex-grow-1 align-center padding-h-sm cursor-pointer ${
          hover || expand ? 'primary' : ''
        }`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => {
          props.onSelect(
            expand && props.item.children && childrenLen > 0
              ? props.section.slice(0, -1)
              : props.section
          )
        }}>
        {props.item.Icon && <props.item.Icon height={24} />}
        <div
          className={`${
            props.item.Icon && 'padding-l-md'
          } flex-grow-1 text-bold`}>
          {props.section.length > 1 &&
            `${props.section
              .map((value) => value + 1)
              .join('.')
              .slice(2, -1)}`}
          {props.name}
        </div>
        {props.item.children && childrenLen > 0 && !expand && (
          <ExpandMoreIcon height={24} />
        )}
        {props.item.children && childrenLen > 0 && expand && (
          <ExpandLessIcon height={24} />
        )}
      </div>
      {expand &&
        props.item.children &&
        Object.entries(props.item.children).map(([k, v], idx) => {
          return (
            <div
              className='flex'
              style={
                props.item.Icon ? { marginLeft: '12px' } : { marginLeft: '6px' }
              }>
              <div
                style={{
                  marginRight: props.item.Icon ? '28px' : '12px',
                  width: '2px',
                }}
                className='surface-variant-bg'
              />
              <NavigationItem
                key={idx}
                name={k}
                select={props.select}
                section={[...props.section, idx]}
                item={v}
                onSelect={props.onSelect}
              />
            </div>
          )
        })}
    </div>
  )
}

const shouldExpand = (current: number[], selected: number[]) => {
  // Selected array length should be more than current
  for (let i = 0; i < current.length; i++) {
    if (current[i] !== selected[i]) return false
  }
  return true
}
