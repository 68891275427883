import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { scopes } from './config'
import {
  unregexStorageName,
  unregexAssetName,
} from '../../components/utils/general'

function getHeaders(token: string) {
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)
  headers.append('x-ms-date', new Date().toUTCString())
  headers.append('x-ms-version', '2019-12-12')
  return headers
}

const callApiWithToken = async (token: string, apiEndpoint: string) => {
  const options = {
    method: 'DELETE',
    headers: getHeaders(token),
  }
  return fetch(apiEndpoint, options)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
      return null
    })
}

export const deleteContainer = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  storage: string,
  container: string
) => {
  if (!account) return
  storage = unregexStorageName(storage)
  container = unregexAssetName(container)

  const endpoint = `https://${storage}.blob.core.windows.net/${container}?restype=container`
  const response = await instance.acquireTokenSilent({
    scopes: scopes.container,
    account: account,
  })
  return callApiWithToken(response.accessToken, endpoint).then((response) => {
    console.log(response)
    return response
  })
}

export const deleteBlob = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  storage: string,
  container: string,
  blob: string,
  subdirectory?: string
) => {
  if (!account) return
  storage = unregexStorageName(storage)
  container = unregexAssetName(container)
  if (subdirectory) subdirectory = '/' + subdirectory
  const endpoint = `https://${storage}.blob.core.windows.net/${container}${
    subdirectory || ''
  }/${blob}`
  const response = await instance.acquireTokenSilent({
    scopes: scopes.container,
    account: account,
  })
  console.log('deleting', blob)
  return callApiWithToken(response.accessToken, endpoint).then((response) => {
    console.log(response)
    return response
  })
}
