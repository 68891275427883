import React from 'react'

import CloseIcon from '../../assets/icons/close_black_24dp.svg'
import BackIcon from '../../assets/icons/arrow_back_black_24dp.svg'
import { isMobile } from '../utils/general'
import Button from './buttons/Button'
import LoadingSpinner from './loadingSpinner'

type ModalOverlayProps = {
    children: React.ReactElement
    isVisible: boolean
    isLoading: boolean
    onCloseModal: () => void
    className?: string
    style?: React.CSSProperties
    onGoBack?: () => void
}

export default function ModalOverlay({
    children,
    isVisible,
    isLoading,
    onCloseModal,
    className,
    style,
    onGoBack,
}: ModalOverlayProps) {
    const mobile = isMobile()
    return isVisible ? (
        <>
            <div
                style={{ inset: mobile ? '0px' : '36px', ...style }}
                className={`z-dialog surface-dim-bg rounded-sm padding-lg border-box shadow fixed flex flex-col pointer-all
          ${className ? className : ''}`}>
                <div className='flex justify-end'>
                    <Button
                        Icon={onGoBack ? BackIcon : CloseIcon}
                        onClick={onGoBack || onCloseModal}
                    />
                </div>
                <div className='margin-t-sm padding-b-lg flex-grow-1 flex border-box justify-center overflow-auto'>
                    {children}
                </div>
                {isLoading && (
                    <>
                        <div className='flex abs h-100 w-100 top-0 right-0 bottom-0 left-0 z-dialog justify-center align-center'>
                            <LoadingSpinner width={80} height={80} />
                        </div>
                    </>
                )}
            </div>
            <div
                className={`background-opacity-5 top-0 left-0 right-0 bottom-0 fixed z-dialog-bg pointer-all`}
                onClick={isLoading ? undefined : onCloseModal}
            />
        </>
    ) : (
        <></>
    )
}
