export type ImageDetail = {
    originalDims: ImgDims
    bytes: ArrayBuffer | Buffer
}

export class ImgDims {
    width: number
    height: number

    constructor(width: number, height: number) {
        this.width = width
        this.height = height
    }
    scale(factor: number) {
        return new ImgDims(this.width * factor, this.height * factor)
    }

    clone() {
        return new ImgDims(this.width, this.height)
    }
}
