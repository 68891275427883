import { MTHS_NAME } from '../../types'

const months = MTHS_NAME

const getDateString = (value: string) => {
  const arr = value.split('-')
  return `${arr[2]} ${months[Number(arr[1]) - 1].slice(0, 3)} ${arr[0]}`
}

const getMonthString = (value: string) => {
  return months[Number(value.split('-')[1]) - 1]
}

const stringToDate = (value: string) => {
  const array = value.split('-')
  const month = Number(array[1])
  const year = Number(array[0])
  return [month, year]
}
const isSplitDate = (value: string, prevDate: string) => {
  const result = [false, false, false] // Show date, Show month, Show year
  if (value != prevDate) {
    // Check if should show date
    result[0] = true
  }
  const [month, year] = stringToDate(value)
  const [prevMonth, prevYear] = stringToDate(prevDate)
  if (prevMonth !== month) {
    result[1] = true
  }
  if (prevYear !== year) {
    result[1] = true
    result[2] = true
  }
  prevDate = value
  return result
}

export { getDateString, getMonthString, isSplitDate }
