import React, { useEffect, useRef, useState } from 'react'
import Chip, { ChipProps } from './Chip'
import { Hashtag, HashtagType } from '../../../types'

type EditableFilterChipProps = {
  onEnter: (value: Hashtag) => void
  isEditing?: (value: boolean) => void
} & ChipProps

const EditableFilterChip = (props: EditableFilterChipProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState<Hashtag | null>()
  useEffect(() => {
    editMode && inputRef.current && inputRef.current.focus()
    !editMode && inputRef.current && inputRef.current.blur()
    props.isEditing && props.isEditing(editMode)
  }, [editMode])
  const validateInput = () => {
    setEditMode(false)
    if (value) {
      props.onEnter(value)
    }
    setValue(null)
  }
  return (
    <div className='rel'>
      {editMode && (
        <input
          ref={inputRef}
          className={`z-2 abs border-box on-secondary-container
              secondary-container-bg text-ellipsis outline-none border-box
              padding-w-md padding-h-sm`}
          type='text'
          value={value?.content}
          placeholder={'Type Hashtag'}
          onChange={(e) =>
            setValue({
              type: HashtagType.COMPONENT,
              content: e.target.value,
            })
          }
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              console.log('reached here')
              validateInput()
            }
          }}
          onBlur={() => {
            // console.log('onBlur')
            // validateInput()
          }}
        />
      )}
      <Chip
        {...props}
        selected={true}
        selectedContentColor='on-secondary-container'
        selectedContainerColor='secondary-container'
        borderColor='outline'
        onClick={() => {
          setEditMode(true)
        }}
      />
    </div>
  )
}

export default EditableFilterChip
