import { ActivityType } from '../../types'
import {
  getContainers,
  getStorageAccounts,
  getBlob,
  getBlobsInContainer,
} from '../../backend'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { createContainer, uploadBlob } from '../../backend/msal/put'

export const addLog = async (
  storageName: string,
  containerName: string,
  instance: IPublicClientApplication,
  account: AccountInfo,
  dataToStore?: ActivityType,
  arrayDataToStore?: ActivityType[]
) => {
  // allow for both one or many ActivityTypes
  let activities: ActivityType[] = []
  if (dataToStore) {
    activities = [dataToStore]
  } else if (arrayDataToStore) {
    activities = arrayDataToStore
  }
  let dataObj = { activities: activities }
  await fetchLog(instance, account, storageName, containerName)
    .then(async (output) => {
      // Check length of activities array and trim activities here
      if (output.activities.length > 1000) {
        const trimmedActivities = output.activities.slice(
          0,
          1000 - activities.length
        )
        activities = activities.concat(trimmedActivities)
      } else {
        activities = activities.concat(output.activities)
      }
      dataObj = { activities: activities }
      await uploadLog(
        instance,
        account,
        dataObj,
        storageName,
        containerName,
        'log'
      )
    })
    .catch((error) => {
      console.log('Fetch Log Error: ', error)
      createLogContainer(instance, account, storageName)
        .then(() => {
          uploadLog(
            instance,
            account,
            dataObj,
            storageName,
            containerName,
            'log'
          )
        })
        .catch((error) => {
          console.log('Create Log Container error: ', error)
        })
    })
}

export const fetchLog = async (
  instance: IPublicClientApplication,
  account: AccountInfo,
  storageName: string,
  containerName: string
) => {
  return await getBlob(
    instance,
    account,
    storageName,
    containerName,
    `user_activity.json`,
    'log'
  )
}

export const createLogContainer = async (
  instance: IPublicClientApplication,
  account: AccountInfo,
  storageName: string
) => {
  return await createContainer(instance, account, storageName, 'log')
}

export const uploadLog = async (
  instance: IPublicClientApplication,
  account: AccountInfo,
  data: any,
  storageName: string,
  containerName: string,
  folderName?: string
) => {
  uploadBlob(
    instance,
    account,
    storageName,
    containerName,
    `user_activity.json`,
    JSON.stringify(data),
    folderName
  )
}
