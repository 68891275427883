import * as React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// MSAL imports
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'

import '../assets/css/App.css'
import HomePage from './homepage/HomePage'
import LoginPage from './loginpage/LoginPage'
import MaintenancePage from './maintenance'
import QrCodePage from './qrcode'
import WebGLPage from './webglpage'

type AppProps = {
  pca: IPublicClientApplication
}

class App extends React.Component<AppProps, unknown> {
  state = {
    demo: false,
    maintenance: false,
  }
  componentDidMount() {
    // const history = useHistory()
    // const navigationClient = new CustomNavigationClient(history)
    // this.props.pca.setNavigationClient(navigationClient)
  }
  render() {
    if (!isWebGLExist()) {
      return <WebGLPage />
    }
    if (!this.state.demo) {
      return (
        <MsalProvider instance={this.props.pca}>
          <AuthenticatedTemplate>
            <Router>
              <Switch>
                <Route
                  path='/qrcode*'
                  render={(props) => <QrCodePage {...props} />}
                />
                <Route path='/*' component={HomePage} />
              </Switch>
            </Router>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {!this.state.maintenance && (
              <Router>
                <Switch>
                  <Route
                    path='/*'
                    render={(props) => (
                      <LoginPage
                        {...props}
                        useDemo={() => this.setState({ demo: true })}
                      />
                    )}
                  />
                </Switch>
              </Router>
            )}
            {this.state.maintenance && (
              <Router>
                <Switch>
                  <Route
                    path='/private'
                    render={(props) => (
                      <LoginPage
                        {...props}
                        useDemo={() => this.setState({ demo: true })}
                      />
                    )}
                  />
                  <Route path='/'>
                    <MaintenancePage />
                  </Route>
                </Switch>
              </Router>
            )}
          </UnauthenticatedTemplate>
        </MsalProvider>
      )
    } else {
      return <HomePage />
    }
  }
}

export default App

const isWebGLExist = () => {
  const canvas = document.createElement('canvas')
  const gl =
    canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
  return gl && gl instanceof WebGLRenderingContext
}
