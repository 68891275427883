import { useAccount, useMsal } from '@azure/msal-react'
import React, { useEffect, useState, useRef, useContext } from 'react'
import {
    MapMode,
    CameraMode,
    Dimension,
    MouseState,
    MarkerType,
    LatLngCoord,
    ModelType,
    ColorMap,
    SonarInfo,
    Activities,
    ActivityType,
    AngleRange,
    Quality,
    ViewOrientation,
} from '../../../../types'
import ProgressBar from '../../../ProgressBar'
import Map from '../../../map/Map'
import MapDialog from '../../../map/MapDialog'
import MapToolbar from '../../../map/MapToolbar'
import { getBlob } from '../../../../backend'
import { uploadBlob } from '../../../../backend/msal/put'
import { addLog } from '../../../utils/user_activities'
import Snackbar from '../../../elements/Dialogs/Snackbar'
import { SonarImageInterface } from '../../../utils/sonar'
import { AppContext } from '../../../../store/context'
import { PECLegends } from '../../../map/MapLegends'

type MapPanelProps = {
    style?: React.CSSProperties
    className?: string
    sonarInfo?: SonarInfo
    sonarRef?: React.RefObject<SonarImageInterface>
    sonarImage?: string
    list: MarkerType[] | null
    setList?: (value: MarkerType[]) => void
    selectedMarkersIdx: number[] | null
    initLocation: LatLngCoord | null
    models: ModelType[] | null
    terrain?: ModelType[]
    selectMarker?: (idx: number | null) => void
    setIsTooFar?: (status: boolean) => void
    markerToFocus?: number
    modelToFocus?: number
    defaultShowModels?: boolean
    mini?: boolean
    setSelectedModels?: (value: ModelType[]) => void
    modelFileMap?: Record<string, Blob>
    asBuilt?: number
    setAsBuilt?: (value: number) => void
    is3DModel?: boolean
    setExpanded?: (value: boolean) => void
    restrict2D?: boolean
}

const MapPanel = (props: MapPanelProps) => {
    const { instance, accounts } = useMsal()
    const childRef = useRef<Map>(null)
    const {
        sonarInfo,
        sonarRef,
        sonarImage,
        list,
        selectedMarkersIdx,
        initLocation,
        models,
        terrain,
        style,
        className,
        selectMarker,
        setIsTooFar,
        markerToFocus,
        modelToFocus,
        defaultShowModels,
        mini,
        setSelectedModels,
        modelFileMap,
        is3DModel,
        setExpanded,
        restrict2D,
        setList,
    } = props
    const username = accounts.length > 0 ? accounts[0].username : 'demo@beex.sg'
    const account = useAccount(accounts[0] || {})
    const { state } = useContext(AppContext)
    const { msal } = state

    const [location, setLocation] = useState<LatLngCoord | null>(initLocation)
    const [mapMode, setMapMode] = useState<MapMode>(MapMode.MAP)
    const [cameraMode, setCameraMode] = useState<CameraMode>(
        CameraMode.STATIONARY
    )
    const [mouseState, setMouseState] = useState<MouseState>(MouseState.DEFAULT)
    const [dimension, setDimension] = useState<Dimension>(
        restrict2D ? Dimension.TWOD : Dimension.THREED
    )
    const [viewOrientation, setViewOrientation] = useState<ViewOrientation>(
        ViewOrientation.FRONT
    )
    const [modelQuality, setModelQuality] = useState<Quality>(
        navigator.hardwareConcurrency > 16
            ? Quality.HIGH
            : navigator.hardwareConcurrency > 8
            ? Quality.MEDIUM
            : Quality.LOW
    )
    const [showTrail, setShowTrail] = useState(true)
    const [showSonar, setShowSonar] = useState(true)
    const [showMap, setShowMap] = useState(true)
    const [showModels, setShowModels] = useState(
        defaultShowModels ? defaultShowModels : false
    )
    const [clearTrail, setClearTrail] = useState(false)
    const [zoom, setZoom] = useState(0)
    const [fullscreen, setFullscreen] = useState(false)
    const [mapHeading, setMapHeading] = useState<number>(0)
    const [colorMap, setColorMap] = useState<ColorMap>(ColorMap.NONE)
    const [modelsOffset, setModelsOffset] = useState([0, 0, 0])
    const [materialSize, setMaterialSize] = useState<number>(0)
    const [originalMaterialSize, setOriginalMaterialSize] = useState<number>(0)
    const [showAngles, setShowAngles] = useState<boolean>(false)
    const [angleRange, setAngleRange] = useState<AngleRange>({
        min: 0,
        max: 180,
    })
    const [snackbarText, setSnackbarText] = useState<string>('')
    // const [xyzBlobArray, setXyzBlobArray] = useState<any[]>([])
    // const [mosaicArray, setMosaicArray] = useState<ModelType[]>([])

    // Map Dialog
    const [showDialog, setShowDialog] = useState<boolean>(true)
    const [dialogText, setDialogText] = useState<string>('')
    const modelHasPCD = models?.some((model) => model.id === 'pcd')
    const modelHasXYZ = models?.some((model) => model.id === 'xyz')
    const modelHasMosaic = models?.some((model) => model.id === 'mosaic')
    const hasMarkers = list && list.length > 0
    const markerHasPEC = list?.some((marker) => marker.pec)

    // Map Objects
    const [kmlFile, setKMLFile] = useState<File | null>(null)

    // Progress Bar
    const [progressPercent, setProgressPercent] = useState<number>(0)

    useEffect(() => {
        const singleXYZMaterialSize = getSingleMaterialSize()
        if (singleXYZMaterialSize) {
            setMaterialSize(singleXYZMaterialSize)
            setOriginalMaterialSize(singleXYZMaterialSize)
        }
    }, [])

    const setPercentForProgress = async (
        percentageUpdate: number | ((currPercent: number) => number)
    ) => {
        const pause = () => {
            return new Promise((r) => setTimeout(r, 0))
        }
        await pause() // gives UI enough time to update
        setProgressPercent(percentageUpdate)
    }

    const getSingleMaterialSize = () => {
        const xyzModels = models?.filter((model) => model.id === 'xyz')
        if (xyzModels && xyzModels.length === 1) {
            if (xyzModels[0].materialSize != undefined)
                return xyzModels[0].materialSize
        }
    }

    const [markerList, setMarkerList] = useState<MarkerType[] | null>(list)
    useEffect(() => setMarkerList(list), [list])
    const [modelList, setModelList] = useState<ModelType[] | null>(models)
    const isGLBModel =
        modelList !== null &&
        modelList.some((model) => ['obj', 'glb'].includes(model.id))
    useEffect(() => setModelList(models), [models])
    const [markersModelsHaveAnyOffsets, setMarkersModelsHaveAnyOffsets] =
        useState(false)
    useEffect(() => {
        const checkMarkersModelsHaveAnyOffsets = async () => {
            const jsonFileByDate: Record<string, any> = {}
            if (models) {
                const offsetAdjustableModels = models.filter((model) =>
                    ['xyz', 'mosaic', 'pcd'].includes(model.id)
                )
                for (let model of offsetAdjustableModels) {
                    if (!model.date) continue
                    if (!(model.date in jsonFileByDate)) {
                        let jsonInput = await getBlob(
                            instance,
                            accounts[0],
                            msal.storage,
                            msal.asset,
                            `${model.date}.json`,
                            model.date
                        )
                        jsonFileByDate[model.date] = jsonInput
                    }
                    const jsonObject = jsonFileByDate[model.date]
                    const modelObjects = jsonObject['model'].filter(
                        (jsonModel: ModelType) => jsonModel.file === model.file
                    )
                    let modelObject = modelObjects.length
                        ? modelObjects[0]
                        : null
                    if (
                        modelObject &&
                        modelObject.offsets &&
                        (modelObject.offsets.x ||
                            modelObject.offsets.y ||
                            modelObject.offsets.mcd)
                    )
                        return true
                }
            }
            if (list && list.length) {
                for (let marker of list) {
                    const markerDate = marker.bag_id.split('--').length
                        ? marker.bag_id.split('--')[0]
                        : undefined
                    if (!markerDate) continue
                    if (!(markerDate in jsonFileByDate)) {
                        let jsonInput = await getBlob(
                            instance,
                            accounts[0],
                            msal.storage,
                            msal.asset,
                            `${markerDate}.json`,
                            markerDate
                        )
                        jsonFileByDate[markerDate] = jsonInput
                    }
                    const jsonObject = jsonFileByDate[markerDate]
                    const markerObjects = jsonObject['markers'].filter(
                        (jsonMarker: MarkerType) =>
                            jsonMarker.bag_id === marker.bag_id
                    )
                    let markerObject = markerObjects.length
                        ? markerObjects[0]
                        : null
                    if (
                        markerObject &&
                        markerObject.offsets &&
                        (markerObject.offsets.x ||
                            markerObject.offsets.y ||
                            markerObject.offsets.mcd)
                    )
                        return true
                }
            }
            return false
        }
        checkMarkersModelsHaveAnyOffsets().then((bool) =>
            setMarkersModelsHaveAnyOffsets(bool)
        )
    }, [models, list, models?.length, list?.length])

    const saveNewSettings = async (offsetValues: number[]) => {
        const hasValidOffsets =
            offsetValues.length === 3 &&
            offsetValues.some((offset) => offset !== 0)

        const checkAllOffsetsZero = (offsets: {
            x: number
            y: number
            mcd: number
        }) => offsets.x === 0 && offsets.y === 0 && offsets.mcd === 0

        // saveNewSettings for all xyz, mosaic & pcd files for now
        const saveNewOrigin = async () => {
            if (!hasValidOffsets) return
            let arrayDataToStore = []
            const jsonFileByDate: Record<string, any> = {}
            let hasAnyOffsets = false
            if (models) {
                const offsetAdjustableModels = models.filter((model) =>
                    ['xyz', 'mosaic', 'pcd'].includes(model.id)
                )
                for (let model of offsetAdjustableModels) {
                    let origin: {
                        prevXOffset?: number
                        editedXOffset?: number
                        prevYOffset?: number
                        editedYOffset?: number
                        prevMCDOffset?: number
                        editedMCDOffset?: number
                    } = {}

                    if (!model.date) continue
                    if (!(model.date in jsonFileByDate)) {
                        let jsonInput = await getBlob(
                            instance,
                            accounts[0],
                            msal.storage,
                            msal.asset,
                            `${model.date}.json`,
                            model.date
                        )
                        jsonFileByDate[model.date] = jsonInput
                    }
                    const jsonObject = jsonFileByDate[model.date]
                    const modelObjects = jsonObject['model'].filter(
                        (jsonModel: ModelType) => jsonModel.file === model.file
                    )
                    let modelObject = modelObjects.length
                        ? modelObjects[0]
                        : null
                    if (modelObject) {
                        if (modelObject.offsets) {
                            if (offsetValues[0])
                                origin.prevXOffset = modelObject.offsets.x
                            if (offsetValues[1])
                                origin.prevYOffset = modelObject.offsets.y
                            if (offsetValues[2])
                                origin.prevMCDOffset = modelObject.offsets.mcd
                            modelObject.offsets.x += offsetValues[0]
                            modelObject.offsets.y += offsetValues[1]
                            modelObject.offsets.mcd += offsetValues[2]
                            if (checkAllOffsetsZero(modelObject.offsets))
                                modelObject.offsets = undefined
                            else if (!hasAnyOffsets) hasAnyOffsets = true
                        } else {
                            if (offsetValues[0]) origin.prevXOffset = 0
                            if (offsetValues[1]) origin.prevYOffset = 0
                            if (offsetValues[2]) origin.prevMCDOffset = 0
                            modelObject.offsets = {
                                x: offsetValues[0],
                                y: offsetValues[1],
                                mcd: offsetValues[2],
                            }
                            if (!hasAnyOffsets) hasAnyOffsets = true
                        }
                        if (offsetValues[0])
                            origin.editedXOffset = modelObject.offsets.x
                        if (offsetValues[1])
                            origin.editedYOffset = modelObject.offsets.y
                        if (offsetValues[2])
                            origin.editedMCDOffset = modelObject.offsets.mcd

                        const models = jsonObject['model'].map(
                            (model: ModelType) =>
                                model.file === modelObject.file
                                    ? modelObject
                                    : model
                        )
                        jsonObject['model'] = models
                        jsonFileByDate[model.date] = jsonObject
                    }
                    let dataToStore: ActivityType = {
                        timestamp: new Date().toISOString(),
                        user: username,
                        action: Activities[Activities.EDITOFFSETS],
                        folder: model.date || '',
                        origin: origin,
                        model: { fileName: model.file },
                    }
                    arrayDataToStore.push(dataToStore)
                }
            }
            if (list && list.length) {
                for (let marker of list) {
                    let origin: {
                        prevXOffset?: number
                        editedXOffset?: number
                        prevYOffset?: number
                        editedYOffset?: number
                        prevMCDOffset?: number
                        editedMCDOffset?: number
                    } = {}
                    const markerDate = marker.bag_id.split('--').length
                        ? marker.bag_id.split('--')[0]
                        : undefined
                    if (!markerDate) continue
                    if (!(markerDate in jsonFileByDate)) {
                        let jsonInput = await getBlob(
                            instance,
                            accounts[0],
                            msal.storage,
                            msal.asset,
                            `${markerDate}.json`,
                            markerDate
                        )
                        jsonFileByDate[markerDate] = jsonInput
                    }
                    const jsonObject = jsonFileByDate[markerDate]
                    const markerObjects = jsonObject['markers'].filter(
                        (jsonMarker: MarkerType) =>
                            jsonMarker.bag_id === marker.bag_id
                    )
                    let markerObject = markerObjects.length
                        ? markerObjects[0]
                        : null
                    if (markerObject) {
                        if (markerObject.offsets) {
                            if (offsetValues[0])
                                origin.prevXOffset = markerObject.offsets.x
                            if (offsetValues[1])
                                origin.prevYOffset = markerObject.offsets.y
                            if (offsetValues[2])
                                origin.prevMCDOffset = markerObject.offsets.mcd
                            markerObject.offsets.x += offsetValues[0]
                            markerObject.offsets.y += offsetValues[1]
                            markerObject.offsets.mcd += offsetValues[2]
                            if (checkAllOffsetsZero(markerObject.offsets))
                                markerObject.offsets = undefined
                            else if (!hasAnyOffsets) hasAnyOffsets = true
                        } else {
                            if (offsetValues[0]) origin.prevXOffset = 0
                            if (offsetValues[1]) origin.prevYOffset = 0
                            if (offsetValues[2]) origin.prevMCDOffset = 0
                            markerObject.offsets = {
                                x: offsetValues[0],
                                y: offsetValues[1],
                                mcd: offsetValues[2],
                            }
                            if (!hasAnyOffsets) hasAnyOffsets = true
                        }
                        if (offsetValues[0])
                            origin.editedXOffset = markerObject.offsets.x
                        if (offsetValues[1])
                            origin.editedYOffset = markerObject.offsets.y
                        if (offsetValues[2])
                            origin.editedMCDOffset = markerObject.offsets.mcd
                        const markers = jsonObject['markers'].map(
                            (marker: MarkerType) =>
                                marker.bag_id === markerObject.bag_id
                                    ? markerObject
                                    : marker
                        )
                        jsonObject['markers'] = markers
                        jsonFileByDate[markerDate] = jsonObject
                    }
                    let dataToStore: ActivityType = {
                        timestamp: new Date().toISOString(),
                        user: username,
                        action: Activities[Activities.EDITOFFSETS],
                        folder: markerDate || '',
                        origin: origin,
                        marker: { bag_id: marker.bag_id },
                    }
                    arrayDataToStore.push(dataToStore)
                }
            }
            for (let date in jsonFileByDate) {
                await uploadBlob(
                    instance,
                    account,
                    msal.storage,
                    msal.asset,
                    `${date}.json`,
                    JSON.stringify(jsonFileByDate[date]),
                    date
                )
            }
            account
                ? addLog(
                      msal.storage,
                      msal.asset,
                      instance,
                      account,
                      undefined,
                      arrayDataToStore
                  )
                : null
            setMarkersModelsHaveAnyOffsets(hasAnyOffsets)
        }
        const saveNewMaterialSize = async () => {
            if (!models || materialSize === 0) return
            const dates = models.map((model) => model.date)
            for (let i = 0; i < dates.length; i++) {
                let blob = await getBlob(
                    instance,
                    account,
                    msal.storage,
                    msal.asset,
                    `${dates[i]}.json`,
                    dates[i]
                ).catch((error) => {
                    console.log(error)
                    return
                })
                const newModelArray = []
                // if (blob.model) {
                //   blob.model.forEach((model: ModelType) => models.find((thisModel) => mod))
                // }
                if (blob.model && blob.model.length) {
                    for (let model of blob.model) {
                        const matchModel = models.find(
                            (selectedModel) => selectedModel.file === model.file
                        )
                        if (matchModel) {
                            model.materialSize = materialSize // revert back to materialSize scale
                        }
                        newModelArray.push(model)
                    }
                    blob.model = newModelArray
                }
                await uploadBlob(
                    instance,
                    account,
                    msal.storage,
                    msal.asset,
                    `${dates[i]}.json`,
                    JSON.stringify(blob),
                    dates[i]
                ).catch((error) => {
                    console.log(error)
                })
            }
        }
        await saveNewOrigin()
        await saveNewMaterialSize()

        if (models) {
            const newModelList = models.map((model) => {
                const newModel = { ...model }
                if (materialSize !== 0) newModel.materialSize = materialSize
                if (!hasValidOffsets) return newModel
                if (newModel.offsets) {
                    newModel.offsets.x += offsetValues[0]
                    newModel.offsets.y += offsetValues[1]
                    newModel.offsets.mcd += offsetValues[2]
                    if (checkAllOffsetsZero(newModel.offsets))
                        newModel.offsets = undefined
                } else
                    newModel.offsets = {
                        x: offsetValues[0],
                        y: offsetValues[1],
                        mcd: offsetValues[2],
                    }
                return newModel
            })
            // re-set selectedModels as json file does not reload after exiting ReportPanel
            setSelectedModels && setSelectedModels(newModelList)
        }
        if (list && hasValidOffsets) {
            const newMarkerList = list.map((marker) => {
                const newMarker = { ...marker }
                if (newMarker.offsets) {
                    newMarker.offsets.x += offsetValues[0]
                    newMarker.offsets.y += offsetValues[1]
                    newMarker.offsets.mcd += offsetValues[2]
                    if (checkAllOffsetsZero(newMarker.offsets))
                        newMarker.offsets = undefined
                } else
                    newMarker.offsets = {
                        x: offsetValues[0],
                        y: offsetValues[1],
                        mcd: offsetValues[2],
                    }
                return newMarker
            })
            setList && setList(newMarkerList)
        }
        setSnackbarText('New settings saved!')
    }

    const setDefaultSettings = async () => {
        const removeOffsets = async () => {
            let arrayDataToStore = []
            const jsonFileByDate: Record<string, any> = {}
            if (models) {
                const offsetAdjustableModels = models.filter((model) =>
                    ['xyz', 'mosaic', 'pcd'].includes(model.id)
                )
                for (let model of offsetAdjustableModels) {
                    if (!model.date) continue
                    if (!(model.date in jsonFileByDate)) {
                        let jsonInput = await getBlob(
                            instance,
                            accounts[0],
                            msal.storage,
                            msal.asset,
                            `${model.date}.json`,
                            model.date
                        )
                        jsonFileByDate[model.date] = jsonInput
                    }
                    const jsonObject = jsonFileByDate[model.date]
                    const modelObjects = jsonObject['model'].filter(
                        (jsonModel: ModelType) => jsonModel.file === model.file
                    )
                    let modelObject = modelObjects.length
                        ? modelObjects[0]
                        : null
                    if (modelObject) {
                        if (modelObject.offsets) modelObject.offsets = undefined
                        const models = jsonObject['model'].map(
                            (model: ModelType) =>
                                model.file === modelObject.file
                                    ? modelObject
                                    : model
                        )
                        jsonObject['model'] = models
                        jsonFileByDate[model.date] = jsonObject
                    }
                    let dataToStore: ActivityType = {
                        timestamp: new Date().toISOString(),
                        user: username,
                        action: Activities[Activities.REMOVEOFFSETS],
                        folder: model.date || '',
                        model: { fileName: model.file },
                    }
                    arrayDataToStore.push(dataToStore)
                }
                setSelectedModels &&
                    setSelectedModels(
                        models.map((model) => ({
                            ...model,
                            offsets: undefined,
                        }))
                    )
            }
            if (list && list.length) {
                for (let marker of list) {
                    const markerDate = marker.bag_id.split('--').length
                        ? marker.bag_id.split('--')[0]
                        : undefined
                    if (!markerDate) continue
                    if (!(markerDate in jsonFileByDate)) {
                        let jsonInput = await getBlob(
                            instance,
                            accounts[0],
                            msal.storage,
                            msal.asset,
                            `${markerDate}.json`,
                            markerDate
                        )
                        jsonFileByDate[markerDate] = jsonInput
                    }
                    const jsonObject = jsonFileByDate[markerDate]
                    const markerObjects = jsonObject['markers'].filter(
                        (jsonMarker: MarkerType) =>
                            jsonMarker.bag_id === marker.bag_id
                    )
                    let markerObject = markerObjects.length
                        ? markerObjects[0]
                        : null
                    if (markerObject) {
                        if (markerObject.offsets)
                            markerObject.offsets = undefined
                        const markers = jsonObject['markers'].map(
                            (marker: MarkerType) =>
                                marker.bag_id === markerObject.bag_id
                                    ? markerObject
                                    : marker
                        )
                        jsonObject['markers'] = markers
                        jsonFileByDate[markerDate] = jsonObject
                    }
                    let dataToStore: ActivityType = {
                        timestamp: new Date().toISOString(),
                        user: username,
                        action: Activities[Activities.REMOVEOFFSETS],
                        folder: markerDate || '',
                        marker: { bag_id: marker.bag_id },
                    }
                    arrayDataToStore.push(dataToStore)
                }
                setList &&
                    setList(
                        list.map((marker) => ({
                            ...marker,
                            offsets: undefined,
                        }))
                    )
            }
            for (let date in jsonFileByDate) {
                await uploadBlob(
                    instance,
                    account,
                    msal.storage,
                    msal.asset,
                    `${date}.json`,
                    JSON.stringify(jsonFileByDate[date]),
                    date
                )
            }
            account
                ? addLog(
                      msal.storage,
                      msal.asset,
                      instance,
                      account,
                      undefined,
                      arrayDataToStore
                  )
                : null
            setMarkersModelsHaveAnyOffsets(false)
        }
        await removeOffsets()
        setSnackbarText('All the offsets are removed!')

        // Old code for resetting xyz model material size
        // const singleXYZMaterialSize = getSingleMaterialSize()
        // singleXYZMaterialSize
        //     ? await setMaterialSize(singleXYZMaterialSize)
        //     : await setMaterialSize(0)
        // childRef.current && await childRef.current.resetMaterialSizes()
    }

    return (
        <div
            className={`
        ${
            fullscreen
                ? 'abs left-24px right-24px bottom-24px top-24px'
                : 'rel w-100 h-100'
        }
        flex justify-center align-center z-3
        ${className}`}>
            {progressPercent < 100 && (
                <div className='z-dialog w-100 h-100 flex justify-center align-center background-opacity-12'>
                    <ProgressBar
                        width={200}
                        height={20}
                        progressPercent={progressPercent}
                    />
                </div>
            )}
            {snackbarText && (
                <Snackbar
                    text={snackbarText}
                    onClose={() => {
                        setSnackbarText('')
                    }}
                />
            )}
            {showDialog && (
                <MapDialog
                    onClose={() => setShowDialog(false)}
                    dialogText={dialogText}
                />
            )}
            {markerHasPEC && props.asBuilt && props.setAsBuilt && (
                <PECLegends
                    asBuilt={props.asBuilt}
                    setAsBuilt={props.setAsBuilt}
                />
            )}
            <MapToolbar
                mini={mini}
                dimension={isGLBModel ? undefined : dimension}
                setDimension={isGLBModel ? undefined : setDimension}
                viewOrientation={isGLBModel ? viewOrientation : undefined}
                setViewOrientation={isGLBModel ? setViewOrientation : undefined}
                colorMap={modelHasXYZ || modelHasPCD ? colorMap : undefined}
                setColorMap={
                    modelHasXYZ || modelHasPCD ? setColorMap : undefined
                }
                showMap={showMap}
                setShowMap={setShowMap}
                showModels={showModels}
                setShowModels={setShowModels}
                showAngles={showAngles}
                setShowAngles={setShowAngles}
                mapHeading={mapHeading}
                setMapHeading={setMapHeading}
                fullscreen={fullscreen}
                setFullscreen={
                    setExpanded
                        ? (fullScr) => {
                              setExpanded(fullScr)
                              setFullscreen(fullScr)
                          }
                        : setFullscreen
                }
                mouseState={mouseState}
                setMouseState={setMouseState}
                modelsOffset={
                    modelHasXYZ || modelHasPCD || modelHasMosaic || hasMarkers
                        ? modelsOffset
                        : undefined
                }
                setModelsOffset={
                    modelHasXYZ || modelHasPCD || modelHasMosaic || hasMarkers
                        ? setModelsOffset
                        : undefined
                }
                materialSize={
                    modelHasXYZ || modelHasPCD ? materialSize : undefined
                }
                setMaterialSize={
                    modelHasXYZ || modelHasPCD ? setMaterialSize : undefined
                }
                originalMaterialSize={originalMaterialSize}
                angleRange={
                    selectedMarkersIdx!.length >= 2 ? angleRange : undefined
                }
                setAngleRange={
                    selectedMarkersIdx!.length >= 2 ? setAngleRange : undefined
                }
                saveNewSettings={
                    modelHasXYZ || modelHasPCD || modelHasMosaic || hasMarkers
                        ? saveNewSettings
                        : undefined
                }
                setDefaultSettings={
                    modelHasXYZ || modelHasPCD || modelHasMosaic || hasMarkers
                        ? setDefaultSettings
                        : undefined
                }
                modelQuality={modelHasXYZ ? modelQuality : undefined} // only exclusive to XYZ models
                setModelQuality={modelHasXYZ ? setModelQuality : undefined}
                is3DModel={is3DModel}
                dimensionIsFixed={restrict2D}
                markersModelsHaveAnyOffsets={markersModelsHaveAnyOffsets}
                kmlFile={kmlFile}
                setKMLFile={setKMLFile}
            />
            <Map
                ref={childRef}
                sonarRef={sonarRef}
                sonarRefCurrent={
                    sonarRef?.current ? sonarRef.current : undefined
                }
                sonarInfo={sonarInfo}
                sonarImage={sonarImage}
                instance={instance}
                account={accounts[0]}
                storageAccount={msal.storage}
                models={models}
                terrain={terrain}
                location={location}
                mapMode={mapMode}
                mouseState={mouseState}
                cameraMode={cameraMode}
                dimension={dimension}
                viewOrientation={viewOrientation}
                colorMap={colorMap}
                showTrail={showTrail}
                showSonar={showSonar}
                showMap={showMap}
                showModels={showModels}
                clearTrail={clearTrail}
                zoom={zoom}
                markers={list}
                selectedMarkersIdx={selectedMarkersIdx}
                angleRange={angleRange}
                showAngles={showAngles}
                setShowAngles={setShowAngles}
                asset={msal.asset}
                fullscreen={fullscreen}
                modelsOffset={modelsOffset}
                materialSize={materialSize}
                markerToFocus={markerToFocus}
                modelToFocus={modelToFocus}
                selectMarker={selectMarker}
                setClearTrail={() => setClearTrail(false)}
                resetZoom={() => setZoom(0)}
                setLocation={setLocation}
                setIsTooFar={setIsTooFar}
                setDialogText={setDialogText}
                setShowDialog={setShowDialog}
                modelFileMap={modelFileMap}
                modelQuality={modelQuality}
                setPercent={setPercentForProgress}
                asBuilt={props.asBuilt}
                showingAxesHelperForMarker={fullscreen}
                kmlFile={kmlFile}
                mapHeading={mapHeading}
                setMapHeading={setMapHeading}
            />
        </div>
    )
}

export default MapPanel
