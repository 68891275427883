import React, { forwardRef } from 'react';

export const FrontCamPanel = forwardRef<HTMLImageElement>(
  (props, ref) => {
    return <img ref={ref} {...props} />
  }
)

export const FrontCamClahePanel = forwardRef<HTMLImageElement>(
  (props, ref) => {
    return <img ref={ref} {...props} />
  }
)