import React from 'react'
import Button, { ButtonProps } from './Button'

const IconButton = (props: ButtonProps) => {
  return (
    <Button
      contentColor='on-background'
      selectedContentColor='primary'
      containerColor=''
      selectedContainerColor=''
      {...props}
    />
  )
}

export default IconButton
