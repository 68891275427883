// Take from vision/extensions/src/plugins/sonar/SonarProximityOculusPlugin.cpp
// Lookup table for to calculate where the pixels are in the image
// Numbers are in milli degrees.
export const OCULUS_TABLE_SIZE = 256

export const OCULUS_TABLE_040_DEGS = [
  -2000, -1983, -1967, -1951, -1934, -1918, -1902, -1885, -1869, -1853, -1837,
  -1821, -1804, -1788, -1772, -1756, -1740, -1724, -1708, -1692, -1676, -1659,
  -1643, -1627, -1611, -1595, -1579, -1564, -1548, -1532, -1516, -1500, -1484,
  -1468, -1452, -1436, -1420, -1404, -1389, -1373, -1357, -1341, -1325, -1310,
  -1294, -1278, -1262, -1247, -1231, -1215, -1199, -1184, -1168, -1152, -1137,
  -1121, -1105, -1090, -1074, -1058, -1043, -1027, -1011, -996, -980, -965,
  -949, -933, -918, -902, -887, -871, -856, -840, -825, -809, -794, -778, -763,
  -747, -732, -716, -701, -685, -670, -654, -639, -623, -608, -592, -577, -561,
  -546, -531, -515, -500, -484, -469, -453, -438, -423, -407, -392, -376, -361,
  -346, -330, -315, -299, -284, -269, -253, -238, -222, -207, -192, -176, -161,
  -146, -130, -115, -99, -84, -69, -53, -38, -23, -7, 7, 23, 38, 53, 69, 84, 99,
  115, 130, 146, 161, 176, 192, 207, 222, 238, 253, 269, 284, 299, 315, 330,
  346, 361, 376, 392, 407, 423, 438, 453, 469, 484, 500, 515, 531, 546, 561,
  577, 592, 608, 623, 639, 654, 670, 685, 701, 716, 732, 747, 763, 778, 794,
  809, 825, 840, 856, 871, 887, 902, 918, 933, 949, 965, 980, 996, 1011, 1027,
  1043, 1058, 1074, 1090, 1105, 1121, 1137, 1152, 1168, 1184, 1199, 1215, 1231,
  1247, 1262, 1278, 1294, 1310, 1325, 1341, 1357, 1373, 1389, 1404, 1420, 1436,
  1452, 1468, 1484, 1500, 1516, 1532, 1548, 1564, 1579, 1595, 1611, 1627, 1643,
  1659, 1676, 1692, 1708, 1724, 1740, 1756, 1772, 1788, 1804, 1821, 1837, 1853,
  1869, 1885, 1902, 1918, 1934, 1951, 1967, 1983, 2000
]

export const OCULUS_TABLE_060_DEGS = [
  -3000, -2974, -2948, -2922, -2896, -2871, -2845, -2819, -2794, -2769, -2743,
  -2718, -2693, -2668, -2642, -2617, -2592, -2567, -2543, -2518, -2493, -2468,
  -2443, -2419, -2394, -2370, -2345, -2321, -2296, -2272, -2247, -2223, -2199,
  -2175, -2151, -2126, -2102, -2078, -2054, -2030, -2006, -1982, -1959, -1935,
  -1911, -1887, -1863, -1840, -1816, -1792, -1769, -1745, -1722, -1698, -1675,
  -1651, -1628, -1604, -1581, -1558, -1534, -1511, -1488, -1465, -1441, -1418,
  -1395, -1372, -1349, -1326, -1303, -1280, -1257, -1234, -1211, -1188, -1165,
  -1142, -1119, -1096, -1073, -1050, -1027, -1005, -982, -959, -936, -913, -891,
  -868, -845, -822, -800, -777, -754, -732, -709, -686, -664, -641, -619, -596,
  -573, -551, -528, -506, -483, -461, -438, -416, -393, -370, -348, -325, -303,
  -280, -258, -235, -213, -191, -168, -146, -123, -101, -78, -56, -33, -11, 11,
  33, 56, 78, 101, 123, 146, 168, 191, 213, 235, 258, 280, 303, 325, 348, 370,
  393, 416, 438, 461, 483, 506, 528, 551, 573, 596, 619, 641, 664, 686, 709,
  732, 754, 777, 800, 822, 845, 868, 891, 913, 936, 959, 982, 1005, 1027, 1050,
  1073, 1096, 1119, 1142, 1165, 1188, 1211, 1234, 1257, 1280, 1303, 1326, 1349,
  1372, 1395, 1418, 1441, 1465, 1488, 1511, 1534, 1558, 1581, 1604, 1628, 1651,
  1675, 1698, 1722, 1745, 1769, 1792, 1816, 1840, 1863, 1887, 1911, 1935, 1959,
  1982, 2006, 2030, 2054, 2078, 2102, 2126, 2151, 2175, 2199, 2223, 2247, 2272,
  2296, 2321, 2345, 2370, 2394, 2419, 2443, 2468, 2493, 2518, 2543, 2567, 2592,
  2617, 2642, 2668, 2693, 2718, 2743, 2769, 2794, 2819, 2845, 2871, 2896, 2922,
  2948, 2974, 3000
]

export const OCULUS_TABLE_080_DEGS = [
  -3000, -2974, -2948, -2922, -2896, -2871, -2845, -2819, -2794, -2769, -2743,
  -2718, -2693, -2668, -2642, -2617, -2592, -2567, -2543, -2518, -2493, -2468,
  -2443, -2419, -2394, -2370, -2345, -2321, -2296, -2272, -2247, -2223, -2199,
  -2175, -2151, -2126, -2102, -2078, -2054, -2030, -2006, -1982, -1959, -1935,
  -1911, -1887, -1863, -1840, -1816, -1792, -1769, -1745, -1722, -1698, -1675,
  -1651, -1628, -1604, -1581, -1558, -1534, -1511, -1488, -1465, -1441, -1418,
  -1395, -1372, -1349, -1326, -1303, -1280, -1257, -1234, -1211, -1188, -1165,
  -1142, -1119, -1096, -1073, -1050, -1027, -1005, -982, -959, -936, -913, -891,
  -868, -845, -822, -800, -777, -754, -732, -709, -686, -664, -641, -619, -596,
  -573, -551, -528, -506, -483, -461, -438, -416, -393, -370, -348, -325, -303,
  -280, -258, -235, -213, -191, -168, -146, -123, -101, -78, -56, -33, -11, 11,
  33, 56, 78, 101, 123, 146, 168, 191, 213, 235, 258, 280, 303, 325, 348, 370,
  393, 416, 438, 461, 483, 506, 528, 551, 573, 596, 619, 641, 664, 686, 709,
  732, 754, 777, 800, 822, 845, 868, 891, 913, 936, 959, 982, 1005, 1027, 1050,
  1073, 1096, 1119, 1142, 1165, 1188, 1211, 1234, 1257, 1280, 1303, 1326, 1349,
  1372, 1395, 1418, 1441, 1465, 1488, 1511, 1534, 1558, 1581, 1604, 1628, 1651,
  1675, 1698, 1722, 1745, 1769, 1792, 1816, 1840, 1863, 1887, 1911, 1935, 1959,
  1982, 2006, 2030, 2054, 2078, 2102, 2126, 2151, 2175, 2199, 2223, 2247, 2272,
  2296, 2321, 2345, 2370, 2394, 2419, 2443, 2468, 2493, 2518, 2543, 2567, 2592,
  2617, 2642, 2668, 2693, 2718, 2743, 2769, 2794, 2819, 2845, 2871, 2896, 2922,
  2948, 2974, 3000
]

export const OCULUS_TABLE_130_DEGS = [
  -6500, -6405, -6313, -6224, -6138, -6054, -5972, -5893, -5815, -5738, -5663,
  -5590, -5518, -5447, -5378, -5309, -5242, -5176, -5111, -5046, -4983, -4920,
  -4858, -4797, -4736, -4676, -4617, -4559, -4501, -4444, -4387, -4331, -4275,
  -4220, -4165, -4111, -4057, -4003, -3950, -3898, -3846, -3794, -3742, -3691,
  -3640, -3590, -3540, -3490, -3440, -3391, -3342, -3294, -3245, -3197, -3149,
  -3102, -3054, -3007, -2960, -2913, -2867, -2820, -2774, -2728, -2683, -2637,
  -2592, -2547, -2502, -2457, -2412, -2367, -2323, -2279, -2235, -2191, -2147,
  -2103, -2060, -2016, -1973, -1930, -1887, -1844, -1801, -1758, -1715, -1673,
  -1630, -1588, -1545, -1503, -1461, -1419, -1377, -1335, -1293, -1252, -1210,
  -1168, -1127, -1085, -1044, -1002, -961, -920, -879, -837, -796, -755, -714,
  -673, -632, -591, -550, -509, -468, -428, -387, -346, -305, -264, -224, -183,
  -142, -101, -61, -20, 20, 61, 101, 142, 183, 224, 264, 305, 346, 387, 428,
  468, 509, 550, 591, 632, 673, 714, 755, 796, 837, 879, 920, 961, 1002, 1044,
  1085, 1127, 1168, 1210, 1252, 1293, 1335, 1377, 1419, 1461, 1503, 1545, 1588,
  1630, 1673, 1715, 1758, 1801, 1844, 1887, 1930, 1973, 2016, 2060, 2103, 2147,
  2191, 2235, 2279, 2323, 2367, 2412, 2457, 2502, 2547, 2592, 2637, 2683, 2728,
  2774, 2820, 2867, 2913, 2960, 3007, 3054, 3102, 3149, 3197, 3245, 3294, 3342,
  3391, 3440, 3490, 3540, 3590, 3640, 3691, 3742, 3794, 3846, 3898, 3950, 4003,
  4057, 4111, 4165, 4220, 4275, 4331, 4387, 4444, 4501, 4559, 4617, 4676, 4736,
  4797, 4858, 4920, 4983, 5046, 5111, 5176, 5242, 5309, 5378, 5447, 5518, 5590,
  5663, 5738, 5815, 5893, 5972, 6054, 6138, 6224, 6313, 6405, 6500
]

const getProfilingTable = (
  fovMax: number,
  fovMin: number,
  beamRanges: Buffer
) => {
  const fovRange = fovMax - fovMin
  const length = beamRanges.length / 2
  const table: number[] = []
  for (let i = 0; i <= length; i++) {
    if (i == length / 2) continue // removes 0
    table.push(Math.round((fovMin + (i * fovRange) / length) * 100))
  }
  return table
}

export const getTable = (
  sonarType: number,
  fovMax?: number,
  fovMin?: number,
  beamRanges?: Buffer
) => {
  switch (sonarType) {
    case 1:
    case 2:
    case 3:
    case 5:
      return OCULUS_TABLE_130_DEGS
    case 4:
    case 6:
      return OCULUS_TABLE_080_DEGS
    case 7:
      return OCULUS_TABLE_060_DEGS
    case 8:
      return OCULUS_TABLE_040_DEGS
    default:
      if (!fovMax || !fovMin || !beamRanges) return null
      else return getProfilingTable(fovMax, fovMin, beamRanges)
  }
}

export const isProfiling = (sonarType: number) => {
  if (sonarType < 128) return false
  else return true
}

export const getSonarCartesianWidth = (sonarType: number, numRangeBins: number) => {
  const table = getTable(sonarType)
    if (table == null) return
    const bearingOrigin = table[0]
    const relBearingRange = Math.abs(table[table.length - 1] - bearingOrigin) // In 0.01 degree resolution
    const centerX = Math.trunc(
      numRangeBins * Math.sin(relBearingRange * 0.005 * (Math.PI / 180.0))
    )
    return centerX * 2 + 1
}
