import React, { useState } from 'react'

export type ChipProps = {
  className?: string
  text?: string
  Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  containerColor?: string
  contentColor?: string
  selectedContainerColor?: string
  selectedContentColor?: string
  borderColor?: string
  selected?: boolean
  disabled?: boolean
  maxWidth?: boolean
  badge?: number
  helperText?: string
  iconHeight?: number
  iconWidth?: number
  rotateIcon?: number
  TrailingIcon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  onClick: () => void
  onClickTrailing?: () => void
  onDoubleClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

/**
 * Button
 * @param {string} className - Additional classes to be passed on
 * @param {string} text - Text to be displayed
 * @param {SVGElement} Icon - Icon to be displayed
 * @param {string} containerColor - Color for the container
 * @param {string} contentColor - Color for the text/icon
 * @param {string} selectedContainerColor - Color for the container if a selected state is required
 * @param {string} selectedContentColor - Color for the content if a selected state is required
 * @param {boolean} selected - Set this variable to select button
 * @param {boolean} disabled - Set this variable to disable button
 * @param {boolean} maxWidth - Set this variable for button to take up maximum width
 * @param {boolean} helperText - Mouseover tooltip text
 * @param {boolean} rotateIcon - Rotate icon in degrees
 * @returns A button
 */
const Chip = ({
  className,
  text,
  rotateIcon,
  Icon,
  containerColor,
  contentColor,
  selectedContainerColor,
  selectedContentColor,
  borderColor,
  selected,
  disabled,
  maxWidth,
  helperText,
  iconHeight,
  iconWidth,
  TrailingIcon,
  onClick,
  onClickTrailing,
  onDoubleClick,
  onMouseOver,
  onMouseOut,
}: ChipProps) => {
  const [hover, setHover] = useState(false)
  const [pointerDown, setPointerDown] = useState(false)
  return (
    <div className='rel'>
      {helperText && hover && !disabled && (
        <div
          className='surface-variant-bg rounded-sm padding-sm text-medium no-wrap abs z-1'
          style={{ bottom: '-24px', left: '24px' }}>
          {helperText}
        </div>
      )}
      <div
        className={`
          ${selected ? `${selectedContainerColor}-bg` : `${containerColor}-bg`}
          ${borderColor && !selected ? `${borderColor}-border` : ''}
          ${
            disabled
              ? 'disabled-container'
              : !TrailingIcon
              ? 'cursor-pointer'
              : 'pointer-none'
          }
          ${Icon ? 'padding-l-sm' : 'padding-l-md'}
          ${maxWidth ? 'flex' : 'flex-inline'}
          ${TrailingIcon ? 'padding-r-sm' : 'padding-r-md'}
          h-32px align-center text-medium text-sm rounded-sm rel overflow-hidden border-box
          ${className}
        `}
        onPointerDown={(e) => {
          if (disabled) return
          e.preventDefault()
          setPointerDown(true)
        }}
        onPointerUp={(e) => {
          if (disabled) return
          e.preventDefault()
          if (pointerDown && !onClickTrailing) {
            // if has trailing clickable icon, cannot allow pointerUp
            onClick()
          }
        }}
        onDoubleClick={(e) => {
          if (disabled || TrailingIcon) return
          e.preventDefault()
          onDoubleClick && onDoubleClick()
        }}
        onMouseOut={() => {
          if (disabled) return
          setHover(false)
          setPointerDown(false)
          onMouseOut && onMouseOut()
        }}
        onMouseOver={() => {
          if (disabled) return
          setHover(true)
          onMouseOver && onMouseOver()
        }}>
        {Icon && (
          <Icon
            style={{ transform: ` rotate(${rotateIcon}deg)` }}
            height={iconHeight || 24}
            width={iconWidth || 24}
            className={`
              ${selected ? selectedContentColor : contentColor}
              ${disabled ? 'disabled-content' : ''}
              ${text ? 'margin-r-sm' : ''}`}
          />
        )}
        {text && (
          <div
            className={`
            cursor-pointer pointer-all
              ${selected ? selectedContentColor : contentColor}
              ${disabled ? 'disabled-content' : ''}`}
            onClick={() => onClick()}>
            {text}
          </div>
        )}
        {TrailingIcon && (
          <TrailingIcon
            height={iconHeight || 24}
            width={iconWidth || 24}
            className={`
              cursor-pointer pointer-all
              ${selected ? selectedContentColor : contentColor}
              ${disabled ? 'disabled-content' : ''}
              ${text ? 'margin-l-sm' : ''}`}
            onClick={() => {
              onClickTrailing ? onClickTrailing() : onClick()
            }}
          />
        )}
        {/* State layer (Only used for hover, focused, pressed, dragged*/}
        <div
          className={`
            ${contentColor}-bg
            ${hover ? 'hover-state' : 'off-state'}
            abs left-0 right-0 top-0 bottom-0
          `}
        />
      </div>
    </div>
  )
}

export default Chip
