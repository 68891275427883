import React, { createRef, useEffect, useRef, useState, UIEvent } from 'react'
import NavigationPanel from './NavigationPanel'
import LaptopIcon from '../../assets/icons/laptop_black_24dp.svg'
import VehicleIcon from '../../assets/icons/ikan.svg'
import CloudIcon from '../../assets/icons/cloud_queue_black_24dp.svg'
import RouterIcon from '../../assets/icons/router_black_24dp.svg'
import {
  ChangelogTable,
  Container,
  CoverPage,
  TableOfContents,
} from './CustomDivs'
import { NavigationPanelHandle } from './NavigationPanel'
import LoadingSpinner from '../elements/loadingSpinner'
import { HDivider } from '../elements/dividers'
import { useAccount, useMsal } from '@azure/msal-react'
import { getBlob } from '../../backend'
import { uploadBlob } from '../../backend/msal/put'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { addLog } from '../utils/user_activities'
import { ActivityType, Activities, UserManualType } from '../../types'

export type Section = {
  idx?: number
  Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  changelog?: ChangelogType[]
  children: SectionDictType
}

export type ChangelogType = {
  version: string
  date: string
  changes: [string]
}

export type SectionDictType = { [key: string]: Section }

let sections: SectionDictType = {
  'A.IKANBILIS': {
    idx: 0,
    Icon: VehicleIcon,
    children: {},
  },
  'Topside Box': {
    idx: 1,
    Icon: RouterIcon,
    children: {},
  },
  'Sambal User Interface': {
    idx: 2,
    Icon: LaptopIcon,
    children: {},
  },
  'Sambal Portal': {
    idx: 3,
    Icon: CloudIcon,
    children: {},
  },
}

type SectionProps = {
  refs: React.MutableRefObject<{
    [key: string]: HTMLDivElement | null
  }>
  name: string
  section: Section
  path: string
  sectionIdx: number[]
}

const Section = ({ refs, name, section, path, sectionIdx }: SectionProps) => {
  return (
    <>
      <div
        ref={(el) => (refs.current[sectionIdxToString(sectionIdx)] = el)}
        className={`${sectionIdx.length <= 2 ? 'text-xxlg' : 'text-xlg'} ${
          sectionIdx.length === 2 ? 'header margin-t-xlg' : ''
        } text-bold padding-h-lg flex align-center`}>
        {sectionIdx.length > 1 && (
          <>
            {sectionIdxToReadable(sectionIdx).slice(0, -1)}
            {section.Icon && (
              <section.Icon height={42} className='margin-r-md' />
            )}
            <div>{name.toUpperCase()}</div>
          </>
        )}
      </div>
      {sectionIdx.length === 2 && <HDivider height={36} />}
      <div>
        <Container path={path} sectionIdx={sectionIdx} />
      </div>
      <div>
        {section.children &&
          Object.entries(section.children).map(([k, v], idx) => {
            return (
              <Section
                refs={refs}
                name={k}
                section={v}
                path={`${path}${k}/`}
                sectionIdx={[...sectionIdx, idx]}
              />
            )
          })}
      </div>
    </>
  )
}

const UserManualPage = () => {
  const { instance, accounts, inProgress } = useMsal()
  const username = accounts.length > 0 ? accounts[0].username : 'demo@beex.sg'
  const account = useAccount(accounts[0] || {})
  const [manual, setManual] = useState('A.IKANBILIS')
  const [fetched, setFetched] = useState(false)
  const navPanelRef = useRef<NavigationPanelHandle>(null)
  const refs = useRef<{ [key: string]: HTMLDivElement | null }>({})
  const mainRef = useRef<HTMLDivElement>(null)

  const generateSection = async (sectionName: string) => {
    sections = await getBlob(
      instance,
      account,
      'user1manual',
      'directory',
      'sections.json'
    )

    const changelog = await getBlob(
      instance,
      account,
      'user1manual',
      sectionName.replace(/[.,\s]/g, '').toLowerCase(),
      'changelog.json'
    )
    sections[sectionName].changelog = changelog['versions']
  }
  const generateAllSections = async () => {
    setFetched(false)
    const keys = Object.keys(sections)
    for (let i = 0; i < keys.length; i++) {
      await generateSection(keys[i])
    }
    const ver = sections[manual].changelog || [{ version: 0 }]
    document.title = `${manual} User Manual v${ver[0].version}`
    setFetched(true)
  }
  const scrollHandler = (e: UIEvent<HTMLDivElement>) => {
    Object.entries(refs.current).map(([k, v], idx) => {
      let latestSection: number[] = []
      if (v !== null) {
        if (isVisible(e.currentTarget, v)) {
          latestSection = k.split('.').map((v) => Number(v))
          // setSelect(latestSection)
          navPanelRef.current?.setSelect(latestSection)
        }
      }
    })
  }
  const scrollToSelect = (select: number[]) => {
    const ref = refs.current[sectionIdxToString(select)]
    if (refs.current == null || ref == null) {
      return
    }
    ref.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }
  const downloadPDF = () => {
    const downloadActivity: ActivityType = {
      timestamp: new Date().toISOString(),
      user: username,
      action: Activities[Activities.DOWNLOADMANUAL],
      manual: UserManualType[sections[manual].idx || 0], // idx dependent on manual downloaded
    }
    account && addLog('user1manual', 'log', instance, account, downloadActivity)
    if (!mainRef.current) return
    window.print()
  }
  useEffect(() => {
    if (mainRef.current) mainRef.current.scrollTop = 0
    generateAllSections()
  }, [manual])
  return (
    <>
      <div
        className='flex-grow-1 no-print sticky rounded-md padding-l-md'
        style={{ maxWidth: '400px', alignSelf: 'flex-start', top: 0 }}>
        <NavigationPanel
          ref={navPanelRef}
          sections={sections}
          setSelect={(select) => {
            if (select.length == 1) {
              setManual(Object.keys(sections)[select[0]])
            } else {
              scrollToSelect(select)
            }
          }}
          downloadPDF={() => downloadPDF()}
        />
      </div>
      <div className='flex-grow-1'>
        <div
          ref={mainRef}
          id='print-section'
          className='padding-w-xxlg padding-b-lg rel block white-bg on-white rounded-md'
          style={{ maxWidth: '800px' }}
          onScroll={scrollHandler}>
          {!fetched && (
            <div className='div-flex-center-full'>
              <LoadingSpinner width={80} height={80} dark />
            </div>
          )}
          {fetched && (
            <>
              <CoverPage
                title={manual}
                changelog={sections[manual].changelog}
                Icon={sections[manual].Icon}
              />
              <ChangelogTable changelog={sections[manual].changelog} />
              <TableOfContents
                sections={sections[manual].children}
                indent={0}
                section={[sections[manual].idx || 0]}
                setSelect={(select) => {
                  scrollToSelect(select)
                }}
              />
              <Section
                refs={refs}
                name={manual}
                section={sections[manual]}
                path={`${manual}/`}
                sectionIdx={[sections[manual].idx || 0]}
              />
              <div style={{ height: '120px' }} />
            </>
          )}
        </div>
      </div>
    </>
    // <div
    //   className='flex flex-grow-1 padding-xlg rel'
    //   style={{ lineHeight: '24px' }}>
    //   <div className='no-print flex-grow-1' style={{ maxWidth: '400px' }}>
    //     <NavigationPanel
    //       ref={navPanelRef}
    //       sections={sections}
    //       setSelect={(select) => {
    //         if (select.length == 1) {
    //           setManual(Object.keys(sections)[select[0]])
    //         } else {
    //           scrollToSelect(select)
    //         }
    //       }}
    //       downloadPDF={() => downloadPDF()}
    //     />
    //   </div>
    //   <div
    //     id='print-section'
    //     className='flex flex-grow-1 rounded-md pre-line overflow-scroll rel white-bg on-white justify-center'>
    //     <div
    //       ref={mainRef}
    //       className='padding-w-xxlg padding-b-lg rel block'
    //       style={{ maxWidth: '1000px' }}
    //       onScroll={scrollHandler}>
    //       {!fetched && (
    //         <div className='div-flex-center-full'>
    //           <LoadingSpinner width={80} height={80} dark />
    //         </div>
    //       )}
    //       {fetched && (
    //         <>
    //           <CoverPage
    //             title={manual}
    //             changelog={sections[manual].changelog}
    //             Icon={sections[manual].Icon}
    //           />
    //           <ChangelogTable changelog={sections[manual].changelog} />
    //           <TableOfContents
    //             sections={sections[manual].children}
    //             indent={0}
    //             section={[sections[manual].idx || 0]}
    //             setSelect={(select) => {
    //               scrollToSelect(select)
    //             }}
    //           />
    //           <Section
    //             refs={refs}
    //             name={manual}
    //             section={sections[manual]}
    //             path={`${manual}/`}
    //             sectionIdx={[sections[manual].idx || 0]}
    //           />
    //           <div style={{ height: '120px' }} />
    //         </>
    //       )}
    //     </div>
    //   </div>
    // </div>
  )
}

export default UserManualPage

/* HELPER FUNCTIONS */
const sectionIdxToString = (array: number[]) => {
  return array.join('.')
}

export const sectionIdxToReadable = (array: number[]) => {
  return array
    .map((value) => value + 1)
    .join('.')
    .slice(2)
}

function isVisible(parent: HTMLDivElement, child: HTMLDivElement) {
  const childRect = child.getBoundingClientRect()
  const parentRect = parent.getBoundingClientRect()
  var contHeight = parent.clientHeight / 2

  var elemTop = childRect.top - parentRect.top
  var elemBottom = elemTop + child.clientHeight

  var isTotal = elemTop >= 0 && elemBottom <= contHeight

  return isTotal
}

const processDirectory = (dir: string, name: string) => {
  const arr = dir.split('/').slice(0, -1)
  let dict = sections[name].children
  for (let i = 0; i < arr.length; i++) {
    if (!(arr[i] in dict)) {
      dict[arr[i]] = { children: {} }
    }
    dict = dict[arr[i]].children
  }
}
