import * as THREE from 'three'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { LatLngCoord } from '../../../../../types'
import { getColor } from '../common'
import { SVY21 } from '../../CoordHelper/svy21'
import { latLngToScene } from '../../CoordHelper/CoordUtils'

export function drawText(
  entity: any,
  data: any,
  font: any,
  unit: number,
  mapTileOrigin: LatLngCoord,
  pixelRes: number
) {
  var geometry, material, text

  if (!font)
    return console.warn(
      'Text is not supported without a Three.js font loaded with THREE.FontLoader! Load a font of your choice and pass this into the constructor. See the sample for this repository or Three.js examples at http://threejs.org/examples/?q=text#webgl_geometry_text for more details.'
    )

  geometry = new TextGeometry(entity.text, {
    font: font,
    height: 0,
    size: entity.textHeight / unit || 12
  })

  if (entity.rotation) {
    var zRotation = (entity.rotation * Math.PI) / 180
    geometry.rotateZ(zRotation)
  }

  material = new THREE.MeshBasicMaterial({ color: getColor(entity, data) })

  text = new THREE.Mesh(geometry, material)
  const cv = new SVY21()
  const latLon = cv.computeLatLon(
    entity.startPoint.y / unit,
    entity.startPoint.x / unit
  ) // X is east, Y is north
  const scene = latLngToScene(
    {
      latitude: latLon.lat,
      longitude: latLon.lon,
      zoom: mapTileOrigin?.zoom
    },
    mapTileOrigin
  )
  text.position.x = scene.x
  text.position.y = scene.y
  text.position.z = 0
  text.scale.setScalar(pixelRes)
  return text
}
