import React, { useEffect, useState } from 'react'

export type TextFieldProps = {
  title: string
  value: string | number
  error?: string
  regex?: RegExp
  strValidator?: (value: string) => boolean
  allowEmpty?: boolean
  className?: string
  onChange: (value: string | number) => void
  headerPresent?: boolean
  showError?: boolean
  transparent?: boolean
  storingFunction? : (value: string | number) => void
}

const TextInput = ({
  title,
  value,
  error,
  regex,
  strValidator,
  className,
  onChange,
  headerPresent,
  showError,
  transparent,
  storingFunction,
}: TextFieldProps) => {
  const [temp, setTemp] = useState(value)
  const [hint, setHint] = useState('')
  const [timerID, setTimerID] = useState(setTimeout(() => {}, 100))
  const validate = (value: string) => {
    if (storingFunction) storingFunction(value)
    setTemp(value)
    if (
      (!regex && !strValidator) ||
      (regex && !strValidator && value.match(regex)) ||
      (strValidator && !regex && strValidator(value)) ||
      (value.match(regex!) && strValidator!(value))
    ) {
      setHint('')
      if (value !== '-') {
        clearTimeout(timerID)
        setTimerID(setTimeout(() => onChange(value), 500))
      }
    } else if (error) {
      setHint(error)
    } else {
      setHint('Opps, input not accepted.')
    }
  }
  useEffect(() => {
    setTemp(value)
    if (typeof value === 'string' && value === '') return // If it is in initial empty state, no need to validate
    validate((typeof value === 'number' ? value.toString() : value) as string)
  }, [value])
  return (
    <div
      className={`
      ${className}
      ${headerPresent != false ? 'padding-t-sm' : ''} rel`}>
      {temp !== '' && headerPresent != false && (
        <div
          className='abs surface-bg'
          style={{ marginTop: '-8px', marginLeft: '12px', padding: '0px 4px' }}>
          {title}
        </div>
      )}
      <input
        type='text'
        placeholder={title}
        className={`${
          hint == ''
            ? transparent
              ? ''
              : 'outline-border'
            : 'outline-warning-border'
        }
        ${transparent ? '' : 'on-surface surface-bg'}
          padding-w-md rounded-sm border-box`}
        style={{ height: '42px' }}
        value={temp}
        onChange={(e) => validate(e.target.value)}
      />
      {error && (
        <div className='error margin-l-sm text-xsm' style={{ height: '8px' }}>
          {hint}
        </div>
      )}
    </div>
  )
}

export default TextInput
