/**
 * Loads KML Files
 * Can load Point, Lines and Text
 */

import * as THREE from 'three'
import { LatLngCoord, SceneCoord } from '../../../types'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'
import {
    latLngToScene,
    getPixelRes,
} from '../../map/utils/CoordHelper/CoordUtils'
import { drawCircle, drawLine } from './BasicShapesUtils'

export async function getKML(file: File, mapTileOrigin: LatLngCoord) {
    const xmlDoc = new DOMParser().parseFromString(
        await file.text(),
        'text/xml'
    )
    const placemarks = xmlDoc.getElementsByTagName('Placemark')
    if (placemarks.length == 0) return
    const group = new THREE.Object3D()
    var lastCoord = null
    for (let i = 0; i < placemarks.length; ++i) {
        const entry = processKMLEntry(placemarks[i], mapTileOrigin)
        if (entry) {
            if (
                !lastCoord ||
                (entry.coord && Math.abs(entry.coord.x - lastCoord.x) > 100) ||
                (entry.coord && Math.abs(entry.coord.y - lastCoord.y) > 100)
            ) {
                lastCoord = entry.coord
                group.add(entry.mesh)
            }
        }
    }
    group.addEventListener('removed', (e) => {
        e.target.remove(e.target.children[0])
    })
    group.position.set(0, 0, 1)
    return group
}

function processKMLEntry(element: Element, mapTileOrigin: LatLngCoord) {
    var obj
    obj = processKMLMultiGeometry(element, mapTileOrigin)
    if (obj) return obj
    obj = processKMLPoint(element, mapTileOrigin)
    if (obj) return obj
    obj = processKMLLine(element, mapTileOrigin)
    if (obj) return obj
    return
}

function processKMLMultiGeometry(element: Element, mapTileOrigin: LatLngCoord) {
    const geo = element.getElementsByTagName('MultiGeometry')
    if (geo.length == 0) return
    const children = geo[0].children
    const group = new THREE.Object3D()
    for (let i = 0; i < children.length; ++i) {
        const entry = processKMLEntry(children[i], mapTileOrigin)
        if (entry) group.add(entry.mesh)
    }
    return { mesh: group, coord: null }
}

function processKMLText(element: Element, scene: SceneCoord) {
    const data = element.getElementsByTagName('SimpleData')
    if (
        data.length > 19 &&
        data[19].getAttribute('name') === 'text' &&
        data[19].textContent !== null
    ) {
        const obj = new THREE.Object3D()
        const div = document.createElement('div')
        div.className = 'rounded-sm padding-xsm ruler-label text-xsm'
        div.innerHTML = data[19].textContent
        obj.addEventListener('removed', (e) => {
            e.target.remove(e.target.children[0])
        })
        obj.add(new CSS2DObject(div))
        obj.position.x = scene.x
        obj.position.y = scene.y
        return obj
    }
    return null
}

function processKMLPoint(element: Element, mapTileOrigin: LatLngCoord) {
    const points = element.getElementsByTagName('Point')
    if (points.length == 0) return
    const coord = points[0]
        .getElementsByTagName('coordinates')[0]
        .textContent?.split(',')
    if (!coord || coord.length != 3) return
    const scene = latLngToScene(
        {
            latitude: Number(coord[1]),
            longitude: Number(coord[0]),
            zoom: mapTileOrigin?.zoom,
        },
        mapTileOrigin
    )
    if (Math.abs(scene.x) > 10000 || Math.abs(scene.y) > 10000) return
    var obj = processKMLText(element, scene)
    if (!obj) {
        obj = drawCircle(scene, 20 * getPixelRes(), 0xff000)
    }
    return { mesh: obj, coord: null }
}

function processKMLLine(element: Element, mapTileOrigin: LatLngCoord) {
    const points = element.getElementsByTagName('LineString')
    if (points.length == 0) return
    const coordsText = points[0]
        .getElementsByTagName('coordinates')[0]
        .textContent?.trim()
    if (!coordsText) return
    const delimiter = coordsText.includes('\n') ? [',', '\n'] : [' ', ',']
    const coords = coordsText.split(delimiter[0])
    if (!coords || coords.length < 2) return
    const scenes = []
    for (let i = 0; i < coords.length; ++i) {
        const coord = coords[i].split(delimiter[1])
        scenes.push(
            latLngToScene(
                {
                    latitude: Number(coord[1]),
                    longitude: Number(coord[0]),
                    zoom: mapTileOrigin?.zoom,
                },
                mapTileOrigin
            )
        )
    }
    const obj = drawLine(scenes, 0xffff00)
    return { mesh: obj, coord: scenes[0] }
}
