import React, { useEffect, useState } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import { CameraMode, Dimension, LatLngCoord, MapMode, ModelType, MouseState } from '../../types'
import Map from '../map/Map'
import MapToolbar from '../map/MapToolbar'
import { getBlob, getStorageAccounts } from '../../backend'

const MissionPage = () => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [mapMode, setMapMode] = useState<MapMode>(MapMode.MAP)
  const [cameraMode, setCameraMode] = useState<CameraMode>(CameraMode.FOLLOW)
  const [mouseState, setMouseState] = useState<MouseState>(MouseState.DEFAULT)
  const [dimension, setDimension] = useState<Dimension>(Dimension.TWOD)
  const [showTrail, setShowTrail] = useState(true)
  const [showSonar, setShowSonar] = useState(true)
  const [showMap, setShowMap] = useState(true)
  const [showModels, setShowModels] = useState(false)
  const [clearTrail, setClearTrail] = useState(false)
  const [zoom, setZoom] = useState(0)

  const models: ModelType[] | null = null
  const [location, setLocation] = useState<LatLngCoord | null>(null)
  const [asset, setAsset] = useState<string>('test1')
  const [assetList, setAssetList] = useState<string[]>(['test1', ' test2'])
  
  const loadAssetList = () => {
    getStorageAccounts(instance, account)
      .then((result) => {
        if (result) {
          setAssetList(result)
          handleSetAsset(result[0])
        }
      })
      .catch((error) => console.log(error))
  }

  const handleSetAsset = async (value: string) => {
    setAsset(value)
    const blob = await getBlob(instance, account, value, 'asset', `_asset.json`)
    if (blob) {
      setLocation(blob.location)
    }
  }

  useEffect(() => {
    loadAssetList()
  }, [])

  return (
    <div style={{ width: 'calc(100% - 24px)', height: 'calc(100% - 24px)', position: 'relative', margin: '12px'}}>
      <MapToolbar
        dimension={dimension}
        setDimension={setDimension}
        showMap={showMap}
        setShowMap={setShowMap}
        showModels={showModels}
        setShowModels={setShowModels}
        asset={asset}
        assetList={assetList}
        setAsset={handleSetAsset}
      />
      {/* <Map
        // sonarRef={sonarRef}
        instance={instance}
        account={accounts[0]}
        models={models}
        location={location}
        mapMode={mapMode}
        MouseState={MouseState}
        cameraMode={cameraMode}
        dimension={dimension}
        showTrail={showTrail}
        showSonar={showSonar}
        showMap={showMap}
        showModels={showModels}
        clearTrail={clearTrail}
        zoom={zoom}
        markers={null}
        selectedMarkers={null}
        // setSelectedMarkers={setSelectedMarkers}
        setClearTrail={() => setClearTrail(false)}
        resetZoom={() => setZoom(0)}
        setLocation={setLocation}
      /> */}
    </div>
  )
}

export default MissionPage