import React from 'react'
import TextInput from '../elements/inputs/TextInput'

type PECLegendsProps = {
  asBuilt: number
  setAsBuilt: (value: number) => void
}
const PECLegends = ({ asBuilt, setAsBuilt }: PECLegendsProps) => {
  return (
    <div className='abs left-12px bottom-12px z-dialog'>
      <TextInput
        value={String(asBuilt)}
        onChange={(value) => setAsBuilt(Number(value))}
        error={'Only numbers allowed'}
        title={'As-Built Thickness'}
        regex={/^-?[0-9]*(\.\d+)?$/gm}
      />
      <div className='background padding-sm rounded-md flex'>
        <PECLegendLabel
          margin
          content={`<50%`}
          color={'error error-container'}
        />
        <PECLegendLabel
          margin
          content={`50%-79%`}
          color={'warning warning-container'}
        />
        <PECLegendLabel
          margin
          content={`80%-90%`}
          color={'primary-bg on-primary-bg'}
        />
        <PECLegendLabel content={`>90%`} color={'success on-success'} />
      </div>
    </div>
  )
}

const PECLegendLabel = ({
  content,
  color,
  margin,
}: {
  content: string
  color: string
  margin?: boolean
}) => {
  return (
    <div
      className={`${color} ${
        margin ? 'margin-r-sm' : ''
      } rounded-sm padding-sm text-bold text-sm`}>
      {content}
    </div>
  )
}

export { PECLegends }
