/*
  A snackbar that appears on the top right corner of the screen
  ------------------------
  |    Description  x    |
  ------------------------
*/

import React, { useEffect, useState } from 'react'
import CloseIcon from '../../../assets/icons/close_black_24dp.svg'
import IconButton from '../buttons/IconButton'
import { Status } from '../../../types'

type SnackbarProps = {
  text: string
  className?: string
  onClose?: () => void
  status?: Status
}

const Snackbar = (props: SnackbarProps) => {
  const { text, className, onClose, status } = props
  const [fadeOut, setFadeOut] = useState(false)
  useEffect(() => {
    if (fadeOut) {
      setTimeout(() => {
        if (onClose) onClose()
      }, 1000)
    }
  }, [fadeOut])
  useEffect(() => {
    setTimeout(() => {
      setFadeOut(true)
    }, 4000)
  }, [])
  let bgColor = 'secondary-container-bg'
  if (status === Status.SUCCESS) {
    bgColor = 'success'
  }
  if (status === Status.ERROR) {
    bgColor = 'error-container'
  }

  return (
    <div
      id='snackbar'
      className={`${fadeOut ? 'animate-fade-out-bottom' : 'animate-fade-in-bottom'} shadow ${bgColor} on-secondary-container padding-h-sm padding-w-md rounded-xlg flex flex-inline align-center z-alert ${className}`}
      style={{position: 'fixed', bottom: `${36 * 4}px`, left: '50%', transform: 'translateX(-50%)'}}>
      <div className='text-md text-medium padding-md'>{text}</div>
      {onClose && (
        <div className='flex justify-end space-end'>
          <IconButton containerColor={'surface-variant-bg'} Icon={CloseIcon} onClick={onClose} />
        </div>
      )}
    </div>
  )
}

export default Snackbar
