import React, { useRef, useEffect, useState, ReactEventHandler } from 'react'

export type SliderProps = {
  MaxIcon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  MinIcon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  value: number
  max: number
  min: number
  step: number
  width?: number
  className?: string
  onChange: (value: number) => void
  onMouseUp?: () => void
}

function Slider(props: SliderProps): JSX.Element {
  const { min, max, step, value, width, MaxIcon, MinIcon, onChange } = props
  const sliderRef = useRef<HTMLInputElement>(null)
  const tooltipRef = useRef<HTMLInputElement>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const [temp, setTemp] = useState(0)
  const [timerID, setTimerID] = useState(setTimeout(() => {}, 100))
  // Slide handle to not spam service call
  const slideHandle = (value: number) => {
    updateProgressTrack(value)
    setTemp(Number(value.toFixed(1)))
    clearTimeout(timerID)
    setTimerID(setTimeout(() => onChange(value), 500))
  }
  const updateProgressTrack = (value: number) => {
    if (!sliderRef.current || !tooltipRef.current) return
    const min = parseFloat(sliderRef.current.min)
    const max = parseFloat(sliderRef.current.max)
    const ratio = value === 0 ? 0 : (value - min) / (max - min)
    sliderRef.current.style.backgroundSize = ratio * 100 + '% 100%'
    // sliderRef.current.style.backgroundSize = 0 + '% 100%'
    const sliderWidth = sliderRef.current.clientWidth
    const offset = 10 - ratio * 18
    tooltipRef.current.style.left = ratio * sliderWidth + offset + 'px'
    setTemp(Number(value.toFixed(1)))
  }
  const handleScroll = (deltaY: number) => {
    if (deltaY > 0 && temp > min) {
      slideHandle(temp - 1)
    } else if (deltaY < 0 && temp < max) {
      slideHandle(temp + 1)
    }
  }
  useEffect(() => {
    updateProgressTrack(value)
  }, [value])
  return (
    <div
      className={`rel w-100 flex align-center ${props.className}`}
      style={{ width: width }}
      onWheel={(e) => handleScroll(e.deltaY)}>
      {MaxIcon && (
        <MaxIcon
          className='pointer-none'
          style={{ width: '36px', height: '36px' }}
        />
      )}
      <div
        ref={tooltipRef}
        className={`${
          showTooltip ? 'animate-fade-in' : 'animate-fade-out'
        } abs text-center rounded-sm secondary-bg padding-h-xsm shadow`}
        style={{ marginTop: '-60px', width: '40px', marginLeft: '-20px' }}>
        {temp}
      </div>
      <input
        ref={sliderRef}
        type='range'
        min={min}
        max={max}
        step={step}
        value={temp}
        className={'small-thumb h-8px w-100 rounded-md surface-variant-bg'}
        onChange={(e) => {
          // slideHandle(parseFloat(e.target.value))
          updateProgressTrack(parseFloat(e.target.value))
          setShowTooltip(true)
        }}
        onMouseUp={(e) => {
          onChange(temp)
          if (props.onMouseUp) props.onMouseUp()
          sliderRef.current?.blur()
          setShowTooltip(false)
        }}
      />
    </div>
  )
}

export default Slider
