import React, { useEffect, useState } from 'react'
import { ThumbnailType } from '../../../../../types'

type MosaicThumbnailType = {
  fetchSrc: ThumbnailType | undefined
  thumbnailRenderCount: number
  index: number
  setThumbnailRenderCount: (value: number) => void
  className?: string
}

const MosaicThumbnail = (props: MosaicThumbnailType) => {
  const {
    fetchSrc,
    thumbnailRenderCount,
    index,
    setThumbnailRenderCount,
    className,
  } = props
  const [imageSrc, setImageSrc] = useState<string>('')
  useEffect(() => {
    fetchImageSrc()
  }, [])

  const fetchImageSrc = async () => {
    await Promise.all([fetchSrc?.frontCamImage()]).then((output) => {
      setImageSrc(output[0] ? output[0] : '')
    })
  }
  return (
    <div className='object-fit-contain flex aspect-16-9 justify-center'>
      {imageSrc && (
        <img
          src={imageSrc}
          onLoad={() => {
            setThumbnailRenderCount(thumbnailRenderCount + 1)
          }}
          onError={() => {
            setThumbnailRenderCount(thumbnailRenderCount + 1)
          }}
          className={`${className}`}
        />
      )}
    </div>
  )
}

export default MosaicThumbnail
