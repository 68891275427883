import React, { useState } from 'react'
import { ButtonProps } from './Button'
import IconButton from './IconButton'

type ExpandIconButtonProps = {
  value: number
  options: React.FunctionComponent<React.SVGAttributes<SVGElement>>[]
  autoReset?: boolean
  onClick: (value: number) => void
} & Omit<ButtonProps, 'onClick'>

const ExpandIconButton = (props: ExpandIconButtonProps) => {
  const [expand, setExpand] = useState(false)
  return (
    <div className='rel'>
      {expand && (
        <div className='flex abs rounded-lg background z-10 right-0 top-0'>
          {props.options.map((value, index) => (
            <IconButton
              key={index}
              Icon={value}
              onClick={() => {
                setExpand(false)
                props.onClick(index)
              }}
            />
          ))}
        </div>
      )}
      <IconButton
        {...props}
        Icon={props.options[props.value]}
        onClick={() => {
          setExpand(!expand)
          if (props.autoReset) props.onClick(0)
        }}
      />
    </div>
  )
}

export default ExpandIconButton
