import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { scopes } from './config'
import {
  unregexAssetName,
  unregexStorageName,
} from '../../components/utils/general'

function getHeaders(token: string, size: number, type?: string) {
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)
  headers.append('x-ms-date', new Date().toUTCString())
  headers.append('x-ms-version', '2019-12-12')
  headers.append('x-ms-blob-type', 'BlockBlob')
  headers.append('Content-Length', String(size))
  if (type != undefined) {
    headers.append('Content-Type', type)
  } else {
    headers.append('Content-Type', 'text/plain; charset=UTF-8')
  }
  return headers
}

const callApiWithToken = async (
  token: string,
  apiEndpoint: string,
  data: any,
  type?: string
) => {
  const options = {
    method: 'PUT',
    headers: getHeaders(token, data.length, type),
    body: data,
  }
  return fetch(apiEndpoint, options)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
      return null
    })
}

export const uploadBlob = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  storage: string,
  container: string,
  blob: string,
  data: any,
  subdirectory?: string, // e.g. /folder
  type?: string
) => {
  if (!account) return
  storage = unregexStorageName(storage)
  container = unregexAssetName(container)
  if (subdirectory) subdirectory = '/' + subdirectory
  const endpoint = `https://${storage}.blob.core.windows.net/${container}${
    subdirectory || ''
  }/${blob}`
  const response = await instance.acquireTokenSilent({
    scopes: scopes.container,
    account: account,
  })
  console.log('uploading', blob)
  return callApiWithToken(response.accessToken, endpoint, data, type).then(
    (response) => {
      console.log(response)
      return response
    }
  )
}

export const createContainer = async (
  instance: IPublicClientApplication,
  account: AccountInfo | null,
  storage: string,
  container: string
) => {
  if (!account) return
  storage = unregexStorageName(storage)
  container = unregexAssetName(container)
  const endpoint = `https://${storage}.blob.core.windows.net/${container}?restype=container`
  const response = await instance.acquireTokenSilent({
    scopes: scopes.container,
    account: account,
  })
  console.log('creating container', container)
  return callApiWithToken(response.accessToken, endpoint, {}).then(
    (response) => {
      if (
        response?.statusText ===
        'This request is not authorized to perform this operation.'
      ) {
        throw Error('Not given permissions to create asset!')
      }
      return response
    }
  )
}
