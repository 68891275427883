import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import MsLogoIcon from '../../assets/icons/ms_logo.svg'
import Button from '../elements/buttons/Button'
import BACKGROUND from '../../assets/images/login_bg.jpg'
import MMP_LOGO from '../../assets/images/logo_mmp_white.png'
import { isMobile } from '../utils/general'
import { getVersion } from '../../backend/demo/fetch'

const LogInWithMSButton = () => {
  const { instance } = useMsal()
  const handleSignIn = () => {
    instance.loginRedirect()
  }
  return (
    <Button
      className='padding-h-sm'
      text='Log In With Microsoft'
      Icon={MsLogoIcon}
      contentColor={'on-surface-variant'}
      containerColor={'surface-variant'}
      maxWidth
      onClick={() => handleSignIn()}
    />
  )
}

const postOptions = (body: string) => {
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: body,
  }
}

type LoginPageProps = {
  useDemo: () => void
}

const LoginPage = ({ useDemo }: LoginPageProps) => {
  const mobile = isMobile()
  const location = useLocation()
  const [ver, setVer] = useState('1.0.0')
  const [hint, setHint] = useState('')
  const checkToken = async (token: string) => {
    const body = JSON.stringify({
      token: token,
    })
    const res = await fetch(`/demo/loginWithJWT`, postOptions(body))
    if (res.ok) useDemo()
    else {
      setHint(await res.text())
    }
  }
  const getAppVersion = async () => {
    setVer(await getVersion())
  }
  useEffect(() => {
    getAppVersion()
    if (location.pathname.includes('token'))
      checkToken(location.search.split('?=')[1])
  }, [])
  return (
    <div
      className={`w-100 h-100 bg-cover bg-no-repeat bg-center`}
      style={{ backgroundImage: `url(${BACKGROUND})` }}>
      <div className='w-100 h-100 background-opacity-5 flex'>
        <div
          className={`flex flex-col justify-center`}
          style={{
            marginLeft: mobile ? '12px' : '240px',
            marginRight: mobile ? '12px' : '',
          }}>
          <div
            className={`rounded-md background on-background padding-xlg
              border-box shadow`}
            style={{ width: mobile ? '100%' : '360px' }}>
            <img
              className={`w-100 h-auto padding-h-lg margin-b-md`}
              src={MMP_LOGO}
            />
            <LogInWithMSButton />
            {hint !== '' && (
              <div
                className='h4 margin-t-md margin-l-md'
                style={{ color: '#eb6354' }}>
                {hint}
              </div>
            )}
            <div className={`h4 text-center margin-t-lg`}>
              By logging in you agree to the{' '}
              <a
                className={`on-secondary-container`}
                href='https://beex.sg/software-terms'
                target='_blank'>
                Terms of Use
              </a>
            </div>
            <div className={`h4 margin-t-lg text-right`}>v{ver}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
