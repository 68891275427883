import React from 'react'
import Button from './Button'

type SegmentedButtonType = {
  options: string[]
  selected: number
  onClick: (idx: number) => void
  textSize?: string
  containerColor?: string
  className?: string
}

/**
 * Segmented Button
 * @param {string[]} options - List of strings as options. Between 2 and 5
 * @param {number} selected - Idx in the list selected
 * @returns
 */
const SegmentedButton = ({
  options,
  selected,
  onClick,
  textSize = 'sm',
  containerColor,
  className,
}: SegmentedButtonType) => {
  return (
    <div className={`flex-inline overflow-hidden outline-border rounded-lg text-${textSize} 
      justify-center text-center ${containerColor ? containerColor + '-bg' : ''} ${className}`}
      // style={{ width: '100%' }} // To make the buttons expand & align
    >
      {options.map((value, idx) => {
        return (
          <div className='flex-grow-1' key={idx}>
            <Button
              className={`
                ${
                  idx == 0
                    ? 'rounded-r-0 outline-border-r'
                    : idx == options.length - 1
                    ? 'rounded-l-0'
                    : 'rounded-none outline-border-r'
                } justify-center`}
              maxWidth={true}
              text={value}
              contentColor={'on-secondary-container'}
              selectedContainerColor={'secondary-container'}
              selected={idx == selected}
              onClick={() => onClick(idx)}
            />
          </div>
        )
      })}
    </div>
  )
}

export default SegmentedButton
