import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    ImageRun,
    SectionType,
    IImageOptions,
    ISectionOptions,
    AlignmentType,
    Header,
    IFloating,
    HorizontalPositionAlign,
    Footer,
    PageNumber,
    ParagraphChild,
    Table,
    TableRow,
    TableCell,
    VerticalAlign,
    BorderStyle,
    IShadingAttributesProperties,
    convertMillimetersToTwip,
    ShadingType,
    ITableCellBorders,
    HeightRule,
    VerticalPositionRelativeFrom,
    IPageMarginAttributes,
    ISectionPropertiesOptions,
    TableLayoutType,
} from 'docx'
import { FONT_FAMILY, FontSizes } from '../constants'

export const wrapInParagraph = (
    element: ParagraphChild | string,
    verticallyCenter = true,
    fontSize = FontSizes.text,
    bold = false
) => {
    if (typeof element === 'string')
        return new Paragraph({
            alignment: verticallyCenter ? AlignmentType.CENTER : undefined,
            children: [
                new TextRun({
                    text: element,
                    bold: bold,
                    font: FONT_FAMILY,
                    size: fontSize,
                }),
            ],
        })
    return new Paragraph({
        alignment: verticallyCenter ? AlignmentType.CENTER : undefined,
        children: [element as ParagraphChild],
    })
}
