/*
  -----------------------
  | Title                |
  | Description          |
  |                      |
  |    Cancel buttonText |
  -----------------------
*/

import React from 'react'
import TextButton from '../buttons/TextButton'
import LoadingSpinner from '../../elements/loadingSpinner'

type DialogProps = {
  children?: React.ReactElement
  text: string
  description?: string
  buttonText?: string
  className?: string
  boxClassName?: string
  onClick?: () => void
  onClose?: () => void
  loadingSubmit?: boolean
  nonFullScreen?: boolean
}

const Dialog = (props: DialogProps) => {
  const {
    children,
    text,
    description,
    buttonText,
    className,
    boxClassName,
    onClick,
    onClose,
    loadingSubmit,
    nonFullScreen,
  } = props
  return (
    <div
      className={`w-100 h-100 top-0 left-0 ${nonFullScreen ? '' : 'fixed bg-black-6 '}flex align-center justify-center z-dialog pointer-all ${className}`}>
      <div
        className={`flex-col surface-container-bg on-background padding-w-lg padding-h-md rounded-md ${boxClassName}`}>
        <div className='text-md text-bold padding-t-md'>{text}</div>
        {description && (
          <div
            className='text-sm padding-h-md inline-block'
            style={{ whiteSpace: 'pre-wrap', lineHeight: '24px' }}>
            {description}
          </div>
        )}
        {children && (
          <div className='margin-t-sm flex justify-center '>{children}</div>
        )}
        {buttonText && onClick && (
          <div className='flex justify-end space-end margin-t-md'>
            {onClose && <TextButton text='Cancel' onClick={() => onClose()} />}
            {!loadingSubmit && (
              <TextButton
                className='secondary-container-bg on-secondary-container'
                text={buttonText}
                onClick={() => onClick()}
              />
            )}
            {loadingSubmit && <LoadingSpinner width={40} height={40} />}
          </div>
        )}
      </div>
    </div>
  )
}

export default Dialog
