import * as THREE from 'three'
import { IDxf } from 'dxf-parser'
import { getColor, getSceneFromVertex, isValidBoundingSize } from '../common'
import { LatLngCoord } from '../../../../../types'

export function drawLine(
  entity: any,
  data: IDxf,
  unit: number,
  mapTileOrigin: LatLngCoord
) {
  let points = []
  let color = getColor(entity, data)
  var material,
    lineType,
    vertex,
    startPoint,
    endPoint,
    bulgeGeometry,
    bulge,
    i,
    line

  if (!entity.vertices) return console.log('entity missing vertices.')

  // create geometry
  for (i = 0; i < entity.vertices.length; i++) {
    if (entity.vertices[i].bulge) {
      bulge = entity.vertices[i].bulge / unit
      startPoint = getSceneFromVertex(entity.vertices[i], unit, mapTileOrigin)
      endPoint = getSceneFromVertex(
        i + 1 < entity.vertices.length ? entity.vertices[i + 1] : points[0],
        unit,
        mapTileOrigin
      )

      let bulgePoints = getBulgeCurvePoints(startPoint, endPoint, bulge)

      points.push.apply(points, bulgePoints)
    } else {
      vertex = getSceneFromVertex(entity.vertices[i], unit, mapTileOrigin)
      points.push(new THREE.Vector3(vertex.x, vertex.y, 0))
    }
  }
  if (entity.shape) points.push(points[0])
  if (entity.lineType) {
    lineType = data.tables.lineType.lineTypes[entity.lineType]
  }
  if (lineType && lineType.pattern && lineType.pattern.length !== 0) {
    material = new THREE.LineDashedMaterial({
      color: color,
      gapSize: 4,
      dashSize: 4
    })
  } else {
    material = new THREE.LineBasicMaterial({ linewidth: 1, color: color })
  }

  var geometry = new THREE.BufferGeometry().setFromPoints(points)
  if (!isValidBoundingSize(geometry)) return
  line = new THREE.Line(geometry, material)
  return line
}

type PointType = {
  x: number
  y: number
}
var THREEx = {
  Math: {
    angle2: (p1: PointType, p2: PointType): number => {
      return 0
    },
    polar: (point: any, distance: number, angle: number): PointType => {
      return { x: 0, y: 0 }
    }
  }
}
/**
 * Returns the angle in radians of the vector (p1,p2). In other words, imagine
 * putting the base of the vector at coordinates (0,0) and finding the angle
 * from vector (1,0) to (p1,p2).
 * @param  {Object} p1 start point of the vector
 * @param  {Object} p2 end point of the vector
 * @return {Number} the angle
 */
THREEx.Math.angle2 = (p1: PointType, p2: PointType): number => {
  var v1 = new THREE.Vector2(p1.x, p1.y)
  var v2 = new THREE.Vector2(p2.x, p2.y)
  v2.sub(v1) // sets v2 to be our chord
  v2.normalize()
  if (v2.y < 0) return -Math.acos(v2.x)
  return Math.acos(v2.x)
}

THREEx.Math.polar = (
  point: PointType,
  distance: number,
  angle: number
): PointType => {
  return {
    x: point.x + distance * Math.cos(angle),
    y: point.y + distance * Math.sin(angle)
  }
}

/**
 * Calculates points for a curve between two points using a bulge value. Typically used in polylines.
 * @param startPoint - the starting point of the curve
 * @param endPoint - the ending point of the curve
 * @param bulge - a value indicating how much to curve
 * @param segments - number of segments between the two given points
 */
function getBulgeCurvePoints(
  startPoint: any,
  endPoint: any,
  bulge: number,
  segments?: number
) {
  var vertex, i, center, p0, p1, radius, startAngle, thetaAngle
  var angle = 4 * Math.atan(bulge)
  var obj = {
    startPoint: (p0 = startPoint
      ? new THREE.Vector2(startPoint.x, startPoint.y)
      : new THREE.Vector2(0, 0)),
    endPoint: (p1 = endPoint
      ? new THREE.Vector2(endPoint.x, endPoint.y)
      : new THREE.Vector2(1, 0)),
    bulge: (bulge = bulge || 1),
    segments: (segments =
      segments || Math.max(Math.abs(Math.ceil(angle / (Math.PI / 18))), 6)) // By default want a segment roughly every 10 degrees
  }
  radius = p0.distanceTo(p1) / 2 / Math.sin(angle / 2)
  center = THREEx.Math.polar(
    startPoint,
    radius,
    THREEx.Math.angle2(p0, p1) + (Math.PI / 2 - angle / 2)
  )
  startAngle = THREEx.Math.angle2(center, p0)
  thetaAngle = angle / segments

  var vertices = []

  vertices.push(new THREE.Vector3(p0.x, p0.y, 0))

  for (i = 1; i <= segments - 1; i++) {
    vertex = THREEx.Math.polar(
      center,
      Math.abs(radius),
      startAngle + thetaAngle * i
    )
    vertices.push(new THREE.Vector3(vertex.x, vertex.y, 0))
  }

  return vertices
}
