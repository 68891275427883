import {
  FilterType,
  TagType,
  initFilter,
} from '../components/utils/FilterUtils'

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export type CombinedActions = TagActions | FilterActions | MSALActions

/**
 * Filter Reducer
 * Use for search bar to filter list of snapshots by name and tags
 */

export enum FilterActionType {
  SET_SEARCH = 'FILTER_SET_SEARCH',
  SET_STAG = 'FILTER_SET_STAG',
  SET_CTAG = 'FILTER_SET_CTAG',
  SET_STAG_ID = 'FILTER_SET_STAG_ID',
  SET_CTAG_ID = 'FILTER_SET_CTAG_ID',
  SET_FLAGGED = 'FILTER_SET_FLAGGED',
  RESET = 'FILTER_RESET',
}

type FilterPayload = {
  [FilterActionType.SET_SEARCH]: string
  [FilterActionType.SET_STAG]: string[]
  [FilterActionType.SET_CTAG]: string[]
  [FilterActionType.SET_STAG_ID]: string[] | null
  [FilterActionType.SET_CTAG_ID]: string[] | null
  [FilterActionType.SET_FLAGGED]: boolean
  [FilterActionType.RESET]: undefined
}

export type FilterActions =
  ActionMap<FilterPayload>[keyof ActionMap<FilterPayload>]

export const filterReducer = (state: FilterType, action: CombinedActions) => {
  switch (action.type) {
    case FilterActionType.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      }
    case FilterActionType.SET_STAG:
      return {
        ...state,
        sTags: action.payload,
      }
    case FilterActionType.SET_CTAG:
      return {
        ...state,
        cTags: action.payload,
      }
    case FilterActionType.SET_STAG_ID:
      return {
        ...state,
        sTagIDs: action.payload,
      }
    case FilterActionType.SET_CTAG_ID:
      return {
        ...state,
        cTagIDs: action.payload,
      }
    case FilterActionType.SET_FLAGGED:
      return {
        ...state,
        flagged: action.payload,
      }
    case FilterActionType.RESET:
      return initFilter
    default:
      return state
  }
}

/**
 * Tag Reducer
 * For management of hashtags
 */

export enum TagActionType {
  SET_STAG = 'TAG_SET_STAG',
  SET_CTAG = 'TAG_SET_CTAG',
}

type TagPayload = {
  [TagActionType.SET_STAG]: string[]
  [TagActionType.SET_CTAG]: string[]
}

export type TagActions = ActionMap<TagPayload>[keyof ActionMap<TagPayload>]

export const tagReducer = (state: TagType, action: CombinedActions) => {
  switch (action.type) {
    case TagActionType.SET_STAG:
      return {
        ...state,
        sTags: action.payload,
      }
    case TagActionType.SET_CTAG:
      return {
        ...state,
        cTags: action.payload,
      }
    default:
      return state
  }
}

/**
 * MSAL Reducer
 * For management of MSAL instance, storage and asset selection
 */

export enum MSALActionType {
  SET_STORAGE = 'MSAL_SET_STORAGE',
  SET_ASSET = 'MSAL_SET_ASSET',
}

type MSALPayload = {
  [MSALActionType.SET_STORAGE]: string
  [MSALActionType.SET_ASSET]: string
}

export type MSALActions = ActionMap<MSALPayload>[keyof ActionMap<MSALPayload>]

export const msalReducer = (
  state: {
    storage: string
    asset: string
  },
  action: CombinedActions
) => {
  switch (action.type) {
    case MSALActionType.SET_STORAGE:
      return {
        ...state,
        storage: action.payload,
      }
    case MSALActionType.SET_ASSET:
      return {
        ...state,
        asset: action.payload,
      }
    default:
      return state
  }
}
