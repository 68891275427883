import React, { useEffect, useState, useRef } from 'react'
import {
    LatLngCoord,
    MarkerType,
    ModelType,
    ThumbnailType,
    Hashtag,
    LENGTH_UNITS,
    METER_IN_OTHER_UNITS,
    PanelType,
    str2PanelType,
    expandablePanelTypes,
} from '../../../../types'
import LoadingSpinner from '../../../elements/loadingSpinner'
import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import MapPanel from './MapPanel'
import SonarImage, { SonarImageInterface } from '../../../utils/sonar'
import { isMobile } from '../../../utils/general'
import ModalOverlay from '../../../elements/ModalOverlay'
import DrawableCanvas from '../../../elements/DrawableCanvas'
import TextInput from '../../../elements/inputs/TextInput'
import IconButton from '../../../elements/buttons/IconButton'
import BinIcon from '../../../../assets/icons/bin.svg'
import SegmentedButton from '../../../elements/buttons/SegmentedButton'

const CAM_IMAGE_IDS = ['Front-Camera', 'Front-Camera-Filtered', 'External-Camera']
// Double up as SegmentedButton options

const panelDropdownMenusWidthInPx = 150

const NUMS_OF_DP = [3, 1, 3, 2] // Corresponds to [m, cm, ft, in]

type ReportPanelType = {
    marker: MarkerType
    hashtagList: Hashtag[]
    thumbnailList: ThumbnailType | undefined
    location: LatLngCoord | null
    models: ModelType[] | null
    storageAccount: string
    asset: string
    dateFolder: string
    edited: boolean
    setEdited: (edited: boolean) => void
    saveEdit: (
        dateFolder: string,
        newMarker: MarkerType,
        newHashtags: Hashtag[]
    ) => void
    allHashtags: Hashtag[]
    saveAllHashtags?: (newHashtagList: Hashtag[]) => Promise<void>
    setHashtagEdited: () => void
    setMarker: (marker: MarkerType) => void
}

const ReportPanel = (props: ReportPanelType) => {
    const {
        marker,
        hashtagList,
        thumbnailList,
        location,
        models,
        storageAccount,
        asset,
        dateFolder,
        edited,
        setEdited,
        saveEdit,
        allHashtags,
        saveAllHashtags,
        setHashtagEdited,
        setMarker,
    } = props
    const mobile = isMobile()
    const frontCamRef = useRef<HTMLImageElement>(null)
    const frontCamClaheRef = useRef<HTMLImageElement>(null)
    const extCamRef = useRef<HTMLImageElement>(null)
    const drawableCanvasImgRefs = [frontCamRef, frontCamClaheRef, extCamRef]
    const sonarRef = useRef<SonarImageInterface>(null)
    const [frontCamImage, setFrontCamImage] = useState<string | null>(null)
    const [frontCamClaheImage, setFrontCamClaheImage] = useState<string | null>(
        null
    )
    const [fullDepthCamImage, setFullDepthCamImage] = useState<string | null>(
        null
    )
    const drawableCanvasImgSrcs = [frontCamImage, frontCamClaheImage, fullDepthCamImage]

    const segmentedButtonOptions = fullDepthCamImage ?
        CAM_IMAGE_IDS.map((id) => id.replaceAll('-', ' ')) :
        CAM_IMAGE_IDS.slice(0, 2).map((id) => id.replaceAll('-', ' '))

    const [frontCamImageDims, setFrontCamImageDims] = useState<{
        height: number
        width: number
    } | null>(null)
    const [sonarImage, setSonarImage] = useState<string | null>(null)
    const [drawableCanvasViewMode, setDrawableCanvasViewMode] = useState<number>(-1)
    // -1 = not visible, 0 = front cam, 1 = front cam clahe, 2 = full depth cam
    const [topPanelIsExpanded, setTopPanelIsExpanded] = useState<boolean | undefined>(undefined)
    const expandFrontCam = drawableCanvasViewMode !== -1
    const [expandSonar, setExpandSonar] = useState(false)
    const [renderMap, setRenderMap] = useState<boolean>(false) // To ensure that middle panel renders to the right size
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const defaultTopPanelType = PanelType.FRONT_CAM
    const defaultBottomPanelType = PanelType.SONAR
    const [topPanelType, setTopPanelType] = useState<PanelType | null>(null)
    const [bottomPanelType, setBottomPanelType] = useState<PanelType | null>(null)

    const availablePanelTypes: PanelType[] = [
        PanelType.FRONT_CAM,
        PanelType.FRONT_CAM_CLAHE,
        PanelType.SONAR,
    ]
    // Checking only fullDepthCamImage as it is not a compulsory image
    if (fullDepthCamImage) availablePanelTypes.push(PanelType.EXT_CAM)
    const topRemainingPanelTypes =
        availablePanelTypes.filter(type => type !== topPanelType)
    const bottomRemainingPanelTypes =
        availablePanelTypes.filter(type => type !== bottomPanelType)

    // States for drawable canvas
    const [numOfPoints, setNumOfPoints] = useState<number>(0)
    const [erasing, setErasing] = useState<boolean>(false)

    const [area, setArea] = useState<number>(0)
    const [selectedUnitIdx, setSelectedUnitIdx] = useState<number>(0)
    const selectedUnit = LENGTH_UNITS[selectedUnitIdx]
    const conversionMultiplier = METER_IN_OTHER_UNITS.get(selectedUnit)!
    let areaUnit = `${selectedUnit}\xB2`

    const [dValueInMeter, setDValueInMeter] = useState<number>(1)
    const dValue = dValueInMeter * conversionMultiplier // The unit of this follows the selected unit
    const setDValue = (value: number) =>
        setDValueInMeter(value / conversionMultiplier) // Will have the same number of DP as the input field

    const setImageSize = (imageUrl: string) => {
        setImageSize
        const img = new Image()
        img.src = imageUrl
        img.onload = () => {
            setFrontCamImageDims({
                height: img.height,
                width: img.width,
            })
        }
    }

    const fetchImages = async () => {
        await Promise.all([
            thumbnailList?.frontCamImage(),
            thumbnailList?.sonarImage(),
            thumbnailList?.frontCamClaheImage(),
            thumbnailList?.fullDepthCamImage(),
        ]).then((output: (string | undefined)[]) => {
            setFrontCamImage(output[0] ? output[0] : '')
            if (output[0]) setImageSize(output[0])
            setSonarImage(output[1] ? output[1] : '')
            setFrontCamClaheImage(output[2] ? output[2] : '')
            setFullDepthCamImage(output[3] ? output[3] : '')
        })
    }
    useEffect(() => {
        fetchImages()
    }, [])

    useEffect(() => {
        if (
            availablePanelTypes.includes(defaultTopPanelType) &&
            availablePanelTypes.includes(defaultBottomPanelType)
        ) {
            setTopPanelType(defaultTopPanelType)
            setBottomPanelType(defaultBottomPanelType)
        } else if (availablePanelTypes.length >= 2) {
            setTopPanelType(availablePanelTypes[0])
            setBottomPanelType(availablePanelTypes[1])
        }
    }, [availablePanelTypes.length])

    useEffect(() => {
        if (renderMap) {
            setIsLoading(false)
        }
    }, [renderMap])

    const getImgWidth = () => {
        let imgElement = document.getElementById(CAM_IMAGE_IDS[drawableCanvasViewMode])
        if (!imgElement) return frontCamImageDims!.width
        return imgElement.clientWidth
    }

    const getImgHeight = () => {
        let imgElement = document.getElementById(CAM_IMAGE_IDS[drawableCanvasViewMode])
        if (!imgElement) return frontCamImageDims!.height
        return imgElement.clientHeight
    }

    return (
        <>
            {(expandSonar || expandFrontCam) && (
                <ModalOverlay
                    isVisible={expandSonar || expandFrontCam}
                    isLoading={isLoading}
                    onCloseModal={() => {
                        setDrawableCanvasViewMode(-1)
                        setTopPanelIsExpanded(undefined)
                        setExpandSonar(false)
                    }}>
                    <div
                        className={`h-100 w-100 flex flex-wrap align-start justify-center align-content-center`}>
                        {expandFrontCam && (
                            <>
                                <div className='rel max-width-95 flex-basis-70 h-auto'>
                                    {drawableCanvasImgSrcs[drawableCanvasViewMode] && (
                                        <img
                                            id={CAM_IMAGE_IDS[drawableCanvasViewMode]}
                                            className={`object-fit-contain w-100 h-auto z-0`}
                                            ref={drawableCanvasImgRefs[drawableCanvasViewMode]}
                                            src={drawableCanvasImgSrcs[drawableCanvasViewMode]!}
                                        />
                                    )}
                                    <DrawableCanvas
                                        imgElementRef={drawableCanvasImgRefs[drawableCanvasViewMode]}
                                        viewMode={drawableCanvasViewMode}
                                        className='object-fit-contain w-100 h-auto z-1 abs top-0 left-0'
                                        dValue={dValue} // The unit of this follows the selected unit
                                        getCanvasWidth={getImgWidth}
                                        getCanvasHeight={getImgHeight}
                                        actualImgHeight={
                                            frontCamImageDims!.height
                                        }
                                        actualImgWidth={
                                            frontCamImageDims!.width
                                        }
                                        setNumOfPoints={setNumOfPoints}
                                        erasing={erasing}
                                        setErasing={setErasing}
                                        setArea={setArea}
                                        selectedUnit={selectedUnit}
                                        numOfDP={NUMS_OF_DP[selectedUnitIdx]}
                                    />
                                </div>
                                <div
                                    className='flex flex-col align-stretch justify-center 
                                        min-width-250px margin-w-sm flex-auto margin-h-auto on-surface'
                                    style={{
                                        maxHeight: frontCamImageDims!.height,
                                        maxWidth: frontCamImageDims!.width,
                                    }}>
                                    <div className='surface-variant-bg rounded-md padding-lg'>
                                        <div className='padding-h-sm'>
                                            <div className='text-bold'>
                                                Image measurement
                                            </div>
                                            <div className='text-sm padding-h-sm'>
                                                Click on the image to measure
                                                dimensions.
                                            </div>
                                        </div>
                                        <div className='flex-grow-1 text-lg margin-t-sm text-left allow-select'>
                                            Area: &nbsp;&nbsp;&nbsp; {/* 3 spacings */}
                                            <b>
                                                {area.toFixed(
                                                    // Round off value of area
                                                    NUMS_OF_DP[selectedUnitIdx]
                                                )}
                                                {['in', 'ft'].includes(
                                                    selectedUnit
                                                ) && (
                                                    <>&nbsp;</> // To add padding before in & ft to make it more readable
                                                )}
                                                {areaUnit}
                                            </b>
                                        </div>
                                        <div className='flex justify-between align-center w-auto flex-grow-1'>
                                            <span className='min-width-75 margin-t-sm'>
                                                Distance to object
                                            </span>
                                            <TextInput
                                                className='min-width-50px'
                                                value={dValue}
                                                onChange={(value) => {
                                                    if (!isNaN(Number(value)))
                                                        setDValue(Number(value))
                                                    else
                                                        setDValue(
                                                            conversionMultiplier
                                                        )
                                                }}
                                                title=''
                                                error={'Only numbers allowed'}
                                                regex={/^-?[0-9]*(\.\d*)?$/gm}
                                            />
                                            <span className='margin-l-sm'>
                                                {selectedUnit}
                                            </span>
                                        </div>
                                        <SegmentedButton
                                            options={LENGTH_UNITS}
                                            selected={selectedUnitIdx}
                                            onClick={setSelectedUnitIdx}
                                            textSize='md'
                                            containerColor='surface'
                                        />
                                        <IconButton
                                            className='margin-t-md margin-b-xsm'
                                            containerColor='surface' // Black background
                                            Icon={BinIcon}
                                            disabled={numOfPoints === 0}
                                            text='Clear all'
                                            onClick={() => {
                                                setErasing(true)
                                                setNumOfPoints(0)
                                            }}
                                        />
                                    </div>
                                    <div className='margin-h-sm' />
                                    <div className='max-width-90 margin-l-xsm'>
                                        <SegmentedButton
                                            options={segmentedButtonOptions}
                                            selected={drawableCanvasViewMode}
                                            onClick={(index: number) => {
                                                // This links the segmented button to switching of the top & bottom panels
                                                const newPanelType = expandablePanelTypes[index]
                                                if (topPanelIsExpanded)
                                                    setTopPanelType(newPanelType)
                                                else if (topPanelIsExpanded === false)
                                                    setBottomPanelType(newPanelType)
                                                setDrawableCanvasViewMode(index)
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {expandSonar && sonarImage && (
                            <SonarImage
                                imgClassName={
                                    'w-100 h-100 object-fit-contain rel'
                                }
                                ref={sonarRef}
                                src={sonarImage}
                                sonarInfo={
                                    marker ? marker.sonar_info : undefined
                                }
                            />
                        )}
                    </div>
                </ModalOverlay>
            )}
            {(!marker || isLoading) && (
                <div className='abs-center'>
                    <LoadingSpinner width={80} height={80} />
                </div>
            )}
            {marker &&
                frontCamImage !== null &&
                frontCamClaheImage !== null &&
                sonarImage !== null &&
                fullDepthCamImage !== null &&
                topPanelType !== null &&
                bottomPanelType !== null && (
                    <div
                        className={`flex flex-wrap flex-grow-1 border-box justify-center padding-b-md  ${
                            isLoading
                                ? 'visibility-hidden'
                                : 'visibility-visible'
                        }`}
                        style={{ maxWidth: `${1200 + panelDropdownMenusWidthInPx}px` }}>
                        <LeftPanel
                            topPanelType={topPanelType}
                            topRemainingPanelTypes={topRemainingPanelTypes}
                            bottomPanelType={bottomPanelType}
                            bottomRemainingPanelTypes={bottomRemainingPanelTypes}
                            topDropdownSelect={(str) => setTopPanelType(str2PanelType(str))}
                            bottomDropdownSelect={(str) => setBottomPanelType(str2PanelType(str))}
                            panelDropdownMenusWidthInPx={panelDropdownMenusWidthInPx}
                            frontCamImage={frontCamImage}
                            frontCamClaheImage={frontCamClaheImage}
                            sonarImage={sonarImage}
                            fullDepthCamImage={fullDepthCamImage}
                            setDrawableCanvasViewMode={setDrawableCanvasViewMode}
                            setTopPanelIsExpanded={setTopPanelIsExpanded}
                            setExpandSonar={setExpandSonar}
                            setRenderMap={setRenderMap}
                            sonarRef={sonarRef}
                            sonarInfo={marker ? marker.sonar_info : undefined}
                        />
                        <div
                            className='flex flex-col align-start h-100 padding-w-sm border-box'
                            style={{ width: mobile ? '100%' : '33%' }}>
                            <RightPanel
                                marker={marker}
                                hashtags={hashtagList}
                                frontCamImage={frontCamClaheImage}
                                sonarImage={sonarImage}
                                edited={edited}
                                asset={asset}
                                storage={storageAccount}
                                setEdited={(edited: boolean) =>
                                    setEdited(edited)
                                }
                                saveEdit={(
                                    newMarker: MarkerType,
                                    newHashtags: Hashtag[]
                                ) =>
                                    saveEdit(dateFolder, newMarker, newHashtags)
                                }
                                allHashtags={allHashtags}
                                saveAllHashtags={saveAllHashtags}
                                setHashtagEdited={setHashtagEdited}
                            />
                            <div
                                className='flex-grow-1 margin-l-sm margin-t-sm margin-r-sm margin-b-sm'
                                style={{ minHeight: '320px' }}>
                                <MapPanel
                                    className={`${
                                        expandFrontCam || expandSonar
                                            ? 'pointer-none'
                                            : 'pointer-auto'
                                    }`}
                                    sonarInfo={
                                        marker ? marker.sonar_info : undefined
                                    }
                                    restrict2D
                                    sonarRef={sonarRef}
                                    sonarImage={sonarImage}
                                    list={[marker]}
                                    setList={(value:MarkerType[]) => setMarker(value[0])}
                                    selectedMarkersIdx={[0]}
                                    initLocation={location}
                                    models={null} // TODO: SELECTOR FOR MODELS
                                    terrain={models as ModelType[]}
                                    defaultShowModels={
                                        models !== null && models.length > 1
                                    }
                                    mini={true}
                                />
                            </div>
                        </div>
                    </div>
                )}
        </>
    )
}

export default ReportPanel
