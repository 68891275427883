import React, { useEffect } from 'react'
import {
    MapMode,
    CameraMode,
    Dimension,
    MouseState,
    ColorMap,
    AngleRange,
    Quality,
    ViewOrientation,
} from '../../types'
import IconButton from '../elements/buttons/IconButton'
import ExpandIconButton from '../elements/buttons/ExpandIconButton'
import MapToolbarPanel, { MapToolbarPanelProps } from './MapToolbarPanel'
import ThreeDIcon from '../../assets/icons/threed_black-24dp.svg'
import TwoDIcon from '../../assets/icons/twod_black_24dp.svg'
import FrontViewIcon from '../../assets/icons/front_view_black.svg'
import SideViewIcon from '../../assets/icons/side_view_black.svg'
import TopViewIcon from '../../assets/icons/top_view_black.svg'
import ContrastIcon from '../../assets/icons/contrast_icon.svg'
import RainbowIcon from '../../assets/icons/rainbow_icon.svg'
import ThermometerIcon from '../../assets/icons/thermometer_icon.svg'
import GrayscaleIcon from '../../assets/icons/grayscale_icon.svg'
import GpsFixedIcon from '../../assets/icons/my_location_black_24dp.svg'
import GpsNoFixedIcon from '../../assets/icons/gps_not_fixed_black_24dp.svg'
import FullscreenIcon from '../../assets/icons/fullscreen_black_24dp.svg'
import NoPointIcon from '../../assets/icons/no_points_24dp.svg'
import SinglePointIcon from '../../assets/icons/single_points_24dp.svg'
import MultiPointIcon from '../../assets/icons/multi_point_24dp.svg'
import NoColorIcon from '../../assets/icons/format_color_reset_black_24dp.svg'
import LowQualityIcon from '../../assets/icons/hdr_off_24dp.svg'
import MediumQualityIcon from '../../assets/icons/hdr_on_24dp.svg'
import HighQualityIcon from '../../assets/icons/hdr_plus_on_24dp.svg'
import NorthIcon from '../../assets/icons/compass_north.svg'

type MapToolbarProps = {
    mapMode?: MapMode
    cameraMode?: CameraMode
    mouseState?: MouseState
    dimension?: Dimension
    viewOrientation?: ViewOrientation
    colorMap?: ColorMap
    taskType?: number
    fullscreen?: boolean
    mini?: boolean
    showModels?: boolean
    modelsOffset?: number[]
    materialSize?: number
    angleRange?: AngleRange
    modelFile?: File | null
    kmlFile?: File | null
    mosaicFiles?: File[]
    showAngles?: boolean
    modelQuality?: Quality
    is3DModel?: boolean
    dimensionIsFixed?: boolean
    markersModelsHaveAnyOffsets?: boolean
    mapHeading?: number
    setMouseState?: (mode: MouseState) => void
    setCameraMode?: (mode: CameraMode) => void
    setDimension?: (mode: Dimension) => void
    setViewOrientation?: (mode: ViewOrientation) => void
    setColorMap?: (mode: ColorMap) => void
    setClearTrail?: () => void
    zoomIn?: () => void
    zoomOut?: () => void
    setTaskType?: (value: number) => void
    setFullscreen?: (value: boolean) => void
    setModelsOffset?: (value: number[]) => void
    setMaterialSize?: (value: number) => void
    saveNewSettings?: (value: number[]) => void
    setModelFile?: (value: File) => void
    setKMLFile?: (value: File) => void
    setMosaicFiles?: (value: File[]) => void
    setAngleRange?: (value: AngleRange) => void
    setShowAngles?: (value: boolean) => void
    setModelQuality?: (value: Quality) => void
    setMapHeading?: (value: number) => void
} & MapToolbarPanelProps

const MapToolbar = (props: MapToolbarProps): JSX.Element => {
    const {
        colorMap,
        setColorMap,
        dimension,
        setDimension,
        viewOrientation,
        setViewOrientation,
        cameraMode,
        setCameraMode,
        mouseState,
        setMouseState,
        fullscreen,
        setFullscreen,
        mini,
        modelQuality,
        setModelQuality,
        is3DModel,
        dimensionIsFixed,
        mapHeading,
        setMapHeading
    } = props
    // const Toolbar = (mapMode === MapMode.MAP) ? MapToolbarDefault : MapToolbarMission

    return (
        <div className='abs right-12px bottom-12px z-10'>
            <div className='flex flex-col'>
                {
                    (fullscreen || !mini) && mapHeading !== undefined && setMapHeading &&
                    <IconButton
                        className="margin-b-sm background-bg on-background"
                        Icon={NorthIcon}
                        rotateIcon={mapHeading}
                        onClick={() => setMapHeading(-1)}
                    />
                }
                {(fullscreen || !mini) &&
                    modelQuality !== undefined &&
                    setModelQuality && (
                        <ExpandIconButton
                            className='margin-b-sm background on-background'
                            value={modelQuality}
                            options={[
                                LowQualityIcon,
                                MediumQualityIcon,
                                HighQualityIcon,
                            ]}
                            onClick={(value) => {
                                setModelQuality(value)
                            }}
                        />
                    )}
                {(fullscreen || !mini) &&
                    colorMap !== undefined &&
                    setColorMap && (
                        <ExpandIconButton
                            className='margin-b-sm background on-background'
                            value={colorMap}
                            options={[
                                NoColorIcon,
                                RainbowIcon,
                                ThermometerIcon,
                                ContrastIcon,
                                GrayscaleIcon,
                            ]}
                            onClick={(value) => {
                                setColorMap(value)
                            }}
                        />
                    )}
                {(fullscreen || !mini) &&
                    dimension !== undefined &&
                    setDimension &&
                    !dimensionIsFixed && (
                        <ExpandIconButton
                            className='margin-b-sm background on-background'
                            value={dimension}
                            options={[TwoDIcon, ThreeDIcon]}
                            onClick={(value) => {
                                setDimension(value)
                            }}
                        />
                    )}
                {(fullscreen || !mini) &&
                    viewOrientation !== undefined &&
                    setViewOrientation && (
                        <ExpandIconButton
                            className='margin-b-sm background on-background'
                            value={viewOrientation}
                            options={[SideViewIcon, TopViewIcon, FrontViewIcon]}
                            onClick={(value) => {
                                setViewOrientation(value)
                            }}
                        />
                    )}
                {(fullscreen || !mini) &&
                    cameraMode !== undefined &&
                    setCameraMode && (
                        <ExpandIconButton
                            className={`margin-b-sm ${
                                cameraMode
                                    ? 'primary-container on-primary-container'
                                    : 'background on-background'
                            }`}
                            value={cameraMode}
                            options={[GpsNoFixedIcon, GpsFixedIcon]}
                            onClick={(value) => {
                                setCameraMode(value)
                            }}
                            autoReset={true}
                        />
                    )}
                {(fullscreen || !mini) &&
                    mouseState !== undefined &&
                    setMouseState &&
                    !is3DModel && (
                        <ExpandIconButton
                            className='margin-b-sm background on-background'
                            value={
                                mouseState === 0 ? 0 : mouseState === 4 ? 1 : 2
                            }
                            options={[
                                NoPointIcon,
                                SinglePointIcon,
                                MultiPointIcon,
                            ]}
                            onClick={(value) => {
                                if (value === 0) {
                                    setMouseState(MouseState.DEFAULT)
                                } else if (value === 1) {
                                    setMouseState(MouseState.RULER)
                                } else {
                                    setMouseState(MouseState.MULTIRULER)
                                }
                            }}
                        />
                    )}
                {(fullscreen || !mini) !== undefined && setFullscreen && (
                    <IconButton
                        className={`margin-b-sm`}
                        containerColor='background'
                        Icon={FullscreenIcon}
                        selected={fullscreen}
                        onClick={() => {
                            setFullscreen(!fullscreen)
                        }}
                    />
                )}
                {(fullscreen || !mini) && !is3DModel && (
                    <MapToolbarPanel {...props} />
                )}
            </div>
        </div>
    )
}

export default MapToolbar
