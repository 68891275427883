import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { bytesToBase64 } from 'byte-base64'
import Bag, { open, TimeUtil } from 'rosbag'
import { getBlob } from '../../backend'
import { PlayerBarInterface } from '../videopage/PlayerBar'
import Map from '../map/Map'
import {
  MarkerType,
  PoseData,
  SonarInfo,
  timestampToDateWithTime,
  timestampToHMS,
  timestampToDate,
  UTMCoord,
  VelocityDirection,
  LatLngCoord,
} from '../../types'
import { InfoPanelInterface } from '../videopage/InfoPanel'
import { SonarImageInterface } from './sonar'
import { utmToLatLng } from '../map/utils/CoordHelper/CoordUtils'
import { processStrSeacp } from './DataUtils'
import Player from '../../backend/Broadway/Player'
import {
  CSVExportOptions,
  MaybeMissingCSVFieldsExistence,
  utcHourIncToText,
  coordSystemNames,
} from '../videopage/CSVExportDialogue'
import * as UTM from 'utm'
import { SVY21, svy21NumOfDP } from '../map/utils/CoordHelper/svy21'
import { roundValue } from './MathUtils'
import { getSonarCartesianWidth } from './SonarPolarDecoder/SonarLookupTable'

const isOnLocalhost = ['localhost', '127.0.0.1'].includes(
  window.location.hostname
)
const decoderWorkerPath = isOnLocalhost
  ? '../backend/Broadway/Decoder.js'
  : './Decoder.js'

const topics = [
  '/ikan/front_cam/image_color/compressed',
  '/ikan/front_cam/image_color/clahe/compressed',
  '/ikan/front_cam/clahe_h264_HD',
  '/ikan/sonar/image/compressed',
  '/ikan/sonar/info',
  '/ikan/fls/data',
  '/ikan/nav/world_ned',
  '/ikan/nav/rpy_ned',
  '/ikan/pathfinder/altitude',
  '/ikan/nav/utm',
  '/ikan/topside/gps',
  '/ikan/hardware/str_seacp',
  '/ikan/hardware/cp_probe',
  '/ikan/hardware/ut_probe',
  '/ikan/fulldepth_camera/image_rectified_h264',
]

const BUFFER_DURATION = { sec: 10, nsec: 0 }

export interface Time {
  sec: number
  nsec: number
}

type RosbagMessageType = {
  topic: string
  timestamp: Time
  data: any
  longitude?: number
  latitude?: number
}

const initSonarInfo: SonarInfo = {
  width: 0,
  height: 0,
  fov_max: 0,
  max_range: 0,
  range_resol: 0,
}

class Rosbag {
  frontCamRef!: React.MutableRefObject<HTMLImageElement | null>
  frontCamClaheRef!: React.MutableRefObject<HTMLImageElement | null>
  sonarRef!: React.MutableRefObject<SonarImageInterface | null>
  fullDepthCam!: React.MutableRefObject<HTMLCanvasElement | null>
  fullDepthCamPlayer: any
  playerBarRef!: React.MutableRefObject<PlayerBarInterface | null>
  mapRef?: React.MutableRefObject<Map | null>
  infoPanelRef?: React.MutableRefObject<InfoPanelInterface | null>
  messages: RosbagMessageType[] = []
  playTime = { sec: 0, nsec: 0 }
  bufferedTime = { sec: 0, nsec: 0 }
  isPlaying = false
  playIdx = 0
  speed = 1
  totalDuration = 0
  bag: Bag | null = null
  buffering = false
  playing = false
  timer!: ReturnType<typeof setInterval>
  pose: PoseData = {
    x: 0,
    y: 0,
    depth: 0,
    heading: 0,
    altitude: 0,
    easting: 0,
    northing: 0,
    zone_letter: 'N',
    zone_number: 48,
    velocity: 0,
    velocity_direction: VelocityDirection[VelocityDirection.UPWARD],
    pitch: 0,
    roll: 0,
  }
  origin: UTMCoord = {
    northing: 0,
    easting: 0,
    zone_letter: 'N',
    zone_number: 48,
  }
  sonarInfo = initSonarInfo
  strSeacp = []
  cp: number | null = null
  ut: number | null = null
  initialiseFullAUVPath = false

  setRef(
    frontCam: React.MutableRefObject<HTMLImageElement | null>,
    frontCamClahe: React.MutableRefObject<HTMLImageElement | null>,
    sonarRef: React.MutableRefObject<SonarImageInterface | null>,
    fullDepthCam: React.MutableRefObject<HTMLCanvasElement | null>,
    playerBarRef: React.MutableRefObject<PlayerBarInterface | null>,
    mapRef?: React.MutableRefObject<Map | null>,
    infoPanelRef?: React.MutableRefObject<InfoPanelInterface | null>
  ) {
    this.frontCamRef = frontCam
    this.frontCamClaheRef = frontCamClahe
    this.sonarRef = sonarRef
    this.fullDepthCam = fullDepthCam
    this.playerBarRef = playerBarRef
    this.mapRef = mapRef
    this.infoPanelRef = infoPanelRef

    this.fullDepthCamPlayer = new Player({
      canvas: this.fullDepthCam.current,
      useWorker: true,
      workerFile: decoderWorkerPath, // Need to put Decoder into html
    })
  }

  reset() {
    this.bag = null
    this.isPlaying = false
    this.playerBarRef.current?.setIsPlaying(false)
    this.playing = false
    this.playTime = { sec: 0, nsec: 0 }
    this.bufferedTime = { sec: 0, nsec: 0 }
    this.playIdx = 0
    this.totalDuration = 0
    this.sonarInfo = initSonarInfo
    this.messages = []
  }

  resetLocal() {
    this.playTime = { sec: 0, nsec: 0 }
    this.bufferedTime = { sec: 0, nsec: 0 }
    this.playIdx = 0
    this.sonarInfo = initSonarInfo
    this.isPlaying = false
    this.seek(this.playTime)
    this.play()
  }

  // CONTROLS COMMAND
  async playInitial() {
    if (!this.bag || !this.bag.startTime || this.playing) return
    if (this.playIdx < 0) return
    this.isPlaying = true
    this.publishInitialPath()
  }

  play() {
    if (!this.bag || !this.bag.startTime || this.playing) return
    if (this.playIdx < 0) return
    this.playerBarRef.current?.setIsPlaying(true)
    this.isPlaying = true
    this.publish()
  }

  pause() {
    this.isPlaying = false
    this.playerBarRef.current?.setIsPlaying(false)
    this.playing = false
  }

  seek(duration: Time) {
    if (!this.bag || !this.bag.startTime) return
    duration.sec === 0
      ? this.seekTimestamp(TimeUtil.add(this.bag.startTime, duration), true)
      : this.seekTimestamp(TimeUtil.add(this.bag.startTime, duration), false)
    if (this.mapRef && this.mapRef.current) {
      // if (duration.sec !== 0) {
      //   setTimeout(() => {
      //     this.mapRef?.current?.displayTraversed()
      //   }, 100)
      // }
      this.mapRef.current.clearTrail()
    }
  }

  changeSpeed(speed: number) {
    switch (speed) {
      case 2:
        this.speed = 4
        break
      case 1:
        this.speed = 2
        break
      default:
        this.speed = 1
        break
    }
  }

  seekTimestamp(timestamp: Time, isStart: boolean) {
    if (!this.bag) return
    this.playIdx = this.searchForStartIdx(timestamp)
    this.playTime = this.messages[this.playIdx].timestamp
    this.publishSingleFrame(this.playIdx)
    if (this.mapRef && this.mapRef.current) {
      //Do something to process the trail traversed from bag.startTime to duration
      if (isStart === false) {
        setTimeout(() => {
          this.mapRef?.current?.displayAUVTraversed()
          this.mapRef?.current?.displayBoatTraversed()
        }, 100)
      }
      this.mapRef.current.clearTrail()
    }
  }

  isTimestampInBag(timestamp: Time) {
    if (!this.bag || !this.bag.startTime || !this.bag.endTime) return false
    if (TimeUtil.isGreaterThan(timestamp, this.bag.endTime)) {
      return false
    } else if (TimeUtil.isLessThan(timestamp, this.bag.startTime)) {
      return false
    }
    return true
  }

  getStartTime() {
    if (!this.bag) return undefined
    return this.bag.startTime
  }

  getMarker(name: string): MarkerType {
    const timestamp = timeToNumber(this.messages[this.playIdx].timestamp)
    return {
      name: name,
      bag_id: timestampToDateWithTime(timestamp),
      timestamp: timestamp,
      origin: {
        northing: this.origin.northing,
        easting: this.origin.easting,
        zone_letter: this.origin.zone_letter || 'N',
        zone_number: this.origin.zone_number || 32,
      },
      position: { x: this.pose.x, y: this.pose.y, z: this.pose.depth },
      heading: this.pose.heading,
      altitude: this.pose.altitude,
      comment: '',
      no_anode: false,
      sonar_info: this.sonarInfo,
      str_seacp: this.strSeacp,
      cp: this.cp,
      ut: this.ut,
    }
  }

  // LOADING FILE
  async downloadFile(
    instance: IPublicClientApplication,
    account: AccountInfo | null,
    storage: string,
    container: string,
    filename: string
  ) {
    this.playerBarRef.current?.setIsLoading(true)
    const blob = await getBlob(instance, account, storage, container, filename)
    await this.openFile(blob)
    this.playerBarRef.current?.setIsLoading(false)
    this.play()
  }
  async openFile(file: File | Blob) {
    this.reset()
    this.bag = await open(file)
    this.messages = []
    if (this.bag.endTime && this.bag.startTime) {
      this.playTime = this.bag.startTime
      this.totalDuration = getDuration(this.bag.endTime, this.bag.startTime)
      // const endTime = TimeUtil.add(this.bag.startTime, BUFFER_DURATION)
      // if (TimeUtil.isGreaterThan(this.bag.endTime, endTime)) {
      //   this.bufferedTime = await this.loadMessages(this.bag.startTime, endTime)
      // } else {
      //   this.bufferedTime = await this.loadMessages(this.bag.startTime, this.bag.endTime)
      // }
      await this.loadAllMessages()
      this.playIdx = this.searchForStartIdx(this.playTime)
      await this.playInitial()
    }
  }

  async loadAllMessages() {
    if (!this.bag || !this.bag.startTime || !this.bag.endTime) return
    let startTime = this.bag.startTime
    let endTime = TimeUtil.add(this.bag.startTime, BUFFER_DURATION)
    while (TimeUtil.isLessThan(endTime, this.bag.endTime)) {
      this.bufferedTime = await this.loadMessages(startTime, endTime)
      this.playerBarRef.current?.setBufferTime(
        getDuration(this.bufferedTime, this.bag.startTime!)
      )
      startTime = endTime
      endTime = TimeUtil.add(startTime, BUFFER_DURATION)
    }
    this.bufferedTime = await this.loadMessages(startTime, this.bag.endTime)
    this.playerBarRef.current?.setBufferTime(
      getDuration(this.bufferedTime, this.bag.startTime!)
    )
  }

  async loadMessages(startTime: Time, endTime: Time) {
    this.buffering = true
    await this.bag!.readMessages(
      { topics: topics, startTime: startTime, endTime },
      (msg) => {
        const { topic, timestamp, message } = msg
        this.messages.push({
          topic,
          timestamp: timestamp,
          data: message,
        })
      }
    )
    this.buffering = false
    return endTime
  }

  searchForStartIdx(time: Time) {
    for (let i = 0; i < this.messages.length; i++) {
      if (TimeUtil.isGreaterThan(this.messages[i].timestamp, time)) {
        return i
      }
    }
    return this.messages.length - 1
  }

  publishInitialPath() {
    if (!this.bag) return
    const speed = 200
    if (this.playIdx >= this.messages.length) {
      if (this.bag.startTime) this.pause()
      return
    }
    const msg = this.messages[this.playIdx]
    if (TimeUtil.isLessThan(msg.timestamp, this.playTime)) {
      this.publishMsg(msg, true)
      this.playIdx += 1
      this.publishInitialPath()
    } else {
      if (this.isPlaying) {
        this.playTime = TimeUtil.add(this.playTime, {
          sec: 0,
          nsec: 1e7 * speed,
        })
        setTimeout(this.publishInitialPath.bind(this), 10)
      }
    }
    if (this.isPlaying === false && this.playIdx >= this.messages.length) {
      this.initialiseFullAUVPath = true
      this.resetLocal()
    }
  }

  publish() {
    if (!this.bag) return
    if (this.playIdx >= this.messages.length) {
      if (this.bag.startTime) this.pause()
      return
    }
    const msg = this.messages[this.playIdx]
    if (TimeUtil.isLessThan(msg.timestamp, this.playTime)) {
      this.publishMsg(msg, false)
      this.playIdx += 1
      this.publish()
    } else {
      if (this.isPlaying) {
        this.playTime = TimeUtil.add(this.playTime, {
          sec: 0,
          nsec: 1e7 * this.speed,
        })
        setTimeout(this.publish.bind(this), 10)
      }
    }
  }

  publishSingleFrame(startIdx: number) {
    const maxTime = TimeUtil.add(this.messages[startIdx].timestamp, {
      sec: 1,
      nsec: 0,
    })
    const isDoneArray = Array(topics.length).fill(false)
    const isDone = () => {
      for (let i = 0; i < isDoneArray.length; i++) {
        if (!isDoneArray[i]) return false
      }
      return true
    }
    let idx = startIdx
    while (idx < this.messages.length && !isDone()) {
      const msg = this.messages[idx]
      if (TimeUtil.isGreaterThan(msg.timestamp, maxTime)) return
      for (let i = 0; i < topics.length; i++) {
        if (msg.topic === topics[i]) {
          isDoneArray[i] = true
        }
      }
      this.publishMsg(msg, false)
      idx += 1
    }
  }

  publishMsg(msg: RosbagMessageType, loading: boolean) {
    if (!this.bag) return
    !loading &&
      this.infoPanelRef?.current?.setTimestamp(timeToNumber(msg.timestamp))
    if (
      msg.topic.includes('front_cam/image_color/clahe') &&
      this.frontCamClaheRef.current &&
      !loading
    ) {
      this.frontCamClaheRef.current.src =
        'data:image/jpeg;base64,' + bytesToBase64(msg.data.data)
    } else if (
      msg.topic.includes('front_cam/image_color') &&
      !msg.topic.includes('clahe') &&
      this.frontCamRef.current &&
      !loading
    ) {
      this.frontCamRef.current.src =
        'data:image/jpeg;base64,' + bytesToBase64(msg.data.data)
    } else if (
      msg.topic.includes('sonar/image') &&
      this.sonarRef.current &&
      !loading
    ) {
      this.sonarRef.current.setImgSrc(
        'data:image/jpeg;base64,' + bytesToBase64(msg.data.data)
      )
      this.mapRef?.current?.updateSonarImage()
    } else if (
      msg.topic.includes('fulldepth_camera') &&
      // this.fullDepthCam.current &&
      this.fullDepthCamPlayer &&
      // this.fullDepthCamUrlCarrier.current &&
      !loading
    ) {
      this.fullDepthCamPlayer.decode(msg.data.data)
      // this.fullDepthCamUrlCarrier.current.src = this.fullDepthCamPlayer.canvas.toDataURL('image/jpeg')
    } else if (
      msg.topic.includes('front_cam/clahe_h264_HD') &&
      this.fullDepthCamPlayer &&
      !loading
    ) {
      this.fullDepthCamPlayer.decode(msg.data.data)
      // this.fullDepthCamUrlCarrier.current.src = this.fullDepthCamPlayer.canvas.toDataURL('image/jpeg')
    } else if (
      msg.topic.includes('world_ned') &&
      this.mapRef &&
      this.mapRef.current &&
      this.pose.northing !== 0 &&
      this.pose.easting !== 0
    ) {
      this.pose.x = msg.data.pose.pose.position.x
      this.pose.y = msg.data.pose.pose.position.y
      this.pose.depth = Math.round(msg.data.pose.pose.position.z * 100) / 100
      const velocityX = msg.data.twist.twist.linear.x
      const velocityY = msg.data.twist.twist.linear.y
      const maxVelocity =
        Math.abs(velocityX) >= Math.abs(velocityY) ? velocityX : velocityY
      const maxVelocityDirection =
        maxVelocity == velocityX
          ? maxVelocity > 0
            ? VelocityDirection[VelocityDirection.UPWARD]
            : VelocityDirection[VelocityDirection.DOWN]
          : maxVelocity > 0
          ? VelocityDirection[VelocityDirection.RIGHT]
          : VelocityDirection[VelocityDirection.LEFT]
      this.pose.velocity = Math.abs(Math.round(maxVelocity * 100) / 100)
      this.pose.velocity_direction = maxVelocityDirection
      if (!loading) {
        this.infoPanelRef?.current?.setDepth(this.pose.depth)
        this.infoPanelRef?.current?.setVelocity(this.pose.velocity)
        this.infoPanelRef?.current?.setVelocityDirection(
          this.pose.velocity_direction
        )
      }
      const latlng = utmToLatLng({
        northing: this.pose.x + this.pose.northing,
        easting: this.pose.y + this.pose.easting,
        zone_letter: this.pose.zone_letter,
        zone_number: this.pose.zone_number,
      })
      !loading &&
        this.infoPanelRef?.current?.setCoord(
          `${Math.round(latlng.latitude * 1000000) / 1000000}\n
        ${Math.round(latlng.longitude * 1000000) / 1000000}`
        )
    } else if (msg.topic.includes('rpy_ned') && !loading) {
      this.pose.heading = Math.round(msg.data.vector.z)
      this.pose.roll = Math.round(msg.data.vector.x)
      this.pose.pitch = Math.round(msg.data.vector.y)
      this.mapRef?.current?.updateAuvPose(this.pose, loading)
      if (!loading) {
        this.infoPanelRef?.current?.setHeading(this.pose.heading)
        this.infoPanelRef?.current?.setRoll(this.pose.roll)
        this.infoPanelRef?.current?.setPitch(this.pose.pitch)
      }
    } else if (msg.topic.includes('rpy_ned') && loading) {
      this.pose.heading = Math.round(msg.data.vector.z)
      this.pose.roll = Math.round(msg.data.vector.x)
      this.pose.pitch = Math.round(msg.data.vector.y)
      this.mapRef?.current?.updateAuvPose(this.pose, loading)
      if (!loading) {
        this.infoPanelRef?.current?.setHeading(this.pose.heading)
        this.infoPanelRef?.current?.setRoll(this.pose.roll)
        this.infoPanelRef?.current?.setPitch(this.pose.pitch)
      }
    } else if (
      msg.topic.includes('pathfinder/altitude') &&
      this.mapRef &&
      this.mapRef.current
    ) {
      if (msg.data.pose) {
        this.pose.altitude =
          Math.round(msg.data.pose.pose.position.z * 100) / 100
      } else if (msg.data.data) {
        this.pose.altitude = Math.round(msg.data.data * 100) / 100
      }
      !loading && this.infoPanelRef?.current?.setAltitude(this.pose.altitude)
    } else if (
      msg.topic.includes('ins/altitude') &&
      this.mapRef &&
      this.mapRef.current
    ) {
      this.pose.altitude = Math.round(msg.data.pose.pose.position.z * 100) / 100
      !loading && this.infoPanelRef?.current?.setAltitude(this.pose.altitude)
    } else if (
      msg.topic.includes('nav/utm') &&
      this.mapRef &&
      this.mapRef.current
    ) {
      const newOrigin = {
        northing: msg.data.y,
        easting: msg.data.x,
        zone_letter: msg.data.zone_letter || 'N',
        zone_number: msg.data.zone_number || 48,
      }
      if (JSON.stringify(newOrigin) !== JSON.stringify(this.origin)) {
        // this.mapRef.current.updateOrigin(newOrigin)
        this.pose.easting = newOrigin.easting
        this.pose.northing = newOrigin.northing
        this.pose.zone_letter = newOrigin.zone_letter
        this.pose.zone_number = newOrigin.zone_number
        this.origin = newOrigin
      }
    } else if (
      msg.topic.includes('sonar/info') &&
      this.sonarRef &&
      this.sonarRef.current
    ) {
      const newSonarInfo = {
        width: msg.data.width,
        height: msg.data.height,
        fov_max: msg.data.fov_max,
        max_range: msg.data.max_range,
        range_resol: msg.data.range_resol,
      }
      if (JSON.stringify(newSonarInfo) !== JSON.stringify(this.sonarInfo)) {
        this.mapRef?.current?.updateSonarInfo(newSonarInfo)
        this.sonarRef.current?.setSonarInfo(newSonarInfo)
        this.sonarInfo = newSonarInfo
      }
    } else if (
      msg.topic.includes('fls/data') &&
      this.sonarRef &&
      this.sonarRef.current
    ) {
      const newSonarInfo = {
        width:
          getSonarCartesianWidth(
            msg.data.sonar_type,
            msg.data.num_range_bins
          ) || 0,
        height: msg.data.num_range_bins,
        fov_max: msg.data.fov_max,
        max_range: msg.data.max_range,
        range_resol: msg.data.max_range / msg.data.num_range_bins,
      }
      if (JSON.stringify(newSonarInfo) !== JSON.stringify(this.sonarInfo)) {
        this.mapRef?.current?.updateSonarInfo(newSonarInfo)
        this.sonarRef.current?.setSonarInfo(newSonarInfo)
        this.sonarInfo = newSonarInfo
      }
    } else if (msg.topic.includes('cp_probe')) {
      if (loading) return
      this.cp = Math.round(msg.data.data * 1000) / 1000
      this.infoPanelRef?.current?.setCp(this.cp)
    } else if (msg.topic.includes('ut_probe')) {
      if (loading) return
      this.ut = Math.round(msg.data.converted_reading * 1000) / 1000
      this.infoPanelRef?.current?.setUt(this.ut)
    } else if (msg.topic.includes('topside/gps')) {
      this.mapRef?.current?.updateBoatPosition(
        msg.data.longitude,
        msg.data.latitude,
        loading
      )
    } else if (msg.topic.includes('hardware/str_seacp')) {
      this.strSeacp = msg.data.data.data
      const res = processStrSeacp(msg.data.data.data)
      if (res) {
        if (!loading) {
          this.infoPanelRef?.current?.setContact(res.contact)
          this.infoPanelRef?.current?.setProximity(res.proximity)
          this.infoPanelRef?.current?.setFg(res.field_gradient)
        }
      }
    }
    !loading &&
      this.playerBarRef.current?.setSeekTime(
        getDuration(msg.timestamp, this.bag.startTime!)
      )
  }

  getCSVDataBlob({
    timeZone,
    selectedCoordSystemOptionIdx,
    selectingCoordinate,
    selectingDepth,
    selectingAltitude,
    selectingVelocity,
    selectingHeading,
    selectingPitch,
    selectingRoll,
    selectingCp,
    selectingUt,
    selectingContact,
    selectingProximity,
    selectingFieldGradient,
  }: CSVExportOptions) {
    if (this.messages.length === 0) return

    const headers = {
      date: 'Date',
      time: `Time (${timeZone})`,
      latitude: 'Latitude (°)',
      longitude: 'Longitude (°)',
      northing: 'Northing (m)',
      easting: 'Easting (m)',
      zone: 'Zone',
      coordSystem: 'Coordinate System',
      depth: 'Depth (m)',
      altitude: 'Altitude (m)',
      velocityX: 'Velocity x (m/s)',
      velocityY: 'Velocity y (m/s)',
      velocityZ: 'Velocity z (m/s)',
      heading: 'Heading (°)',
      pitch: 'Pitch (°)',
      roll: 'Roll (°)',
      cp: 'CP (mV)',
      ut: 'UT (mm)',
      contact: 'Contact (mV)',
      proximity: 'Proximity (mV)',
      fieldGradient: 'Field Gradient (mV)',
    }

    // Add compulsory fields first
    const headerRow = [headers.date, headers.time]

    // Add optional fields based on selected options
    if (selectingCoordinate)
      switch (selectedCoordSystemOptionIdx) {
        case 0: // LatLong
          // NOTE THE ORDER OF PUSH
          headerRow.push(
            headers.latitude,
            headers.longitude,
            headers.coordSystem
          )
          break
        case 1: // UTM
          // NOTE THE ORDER OF PUSH
          headerRow.push(
            headers.northing,
            headers.easting,
            headers.zone,
            headers.coordSystem
          )
          break
        case 2: // SVY21
          // NOTE THE ORDER OF PUSH
          headerRow.push(headers.northing, headers.easting, headers.coordSystem)
          break
      }
    // NOTE THE ORDER OF PUSH
    if (selectingDepth) headerRow.push(headers.depth)
    if (selectingAltitude) headerRow.push(headers.altitude)
    if (selectingVelocity)
      headerRow.push(headers.velocityX, headers.velocityY, headers.velocityZ)
    if (selectingHeading) headerRow.push(headers.heading)
    if (selectingPitch) headerRow.push(headers.pitch)
    if (selectingRoll) headerRow.push(headers.roll)
    if (selectingCp) headerRow.push(headers.cp)
    if (selectingUt) headerRow.push(headers.ut)
    if (selectingContact) headerRow.push(headers.contact)
    if (selectingProximity) headerRow.push(headers.proximity)
    if (selectingFieldGradient) headerRow.push(headers.fieldGradient)

    const csvData: (string | number)[][] = [headerRow]

    const coordSystemName = coordSystemNames[selectedCoordSystemOptionIdx]
    const cv = new SVY21()
    const dummyEntryValue = 'N/A'
    const msgData2CSVRow = (
      timestamp: number,
      latLng: LatLngCoord,
      depth: number,
      altitude: number | undefined,
      velocityX: number,
      velocityY: number,
      velocityZ: number,
      heading: number | undefined,
      pitch: number | undefined,
      roll: number | undefined,
      cp: number | undefined,
      ut: number | undefined,
      contact: number | undefined,
      proximity: number | undefined,
      fieldGradient: number | undefined
    ) => {
      // Add compulsory fields first
      const row: (number | string | undefined)[] = [
        timestampToDate(timestamp, timeZone), // Date
        timestampToHMS(timestamp, timeZone, true), // Time
      ]

      // Add optional fields based on selected options
      if (selectingCoordinate)
        switch (selectedCoordSystemOptionIdx) {
          case 0: // LatLong
            // MUST FOLLOW SAME PUSH ORDER AS HEADERS
            row.push(
              roundValue(latLng.latitude, 8),
              roundValue(latLng.longitude, 8),
              coordSystemName.replace('/', ' ')
            )
            break
          case 1: // UTM
            const utm = UTM.fromLatLon(latLng.latitude, latLng.longitude)
            const zone = utm.zoneNum + utm.zoneLetter
            // MUST FOLLOW SAME PUSH ORDER AS HEADERS
            row.push(
              roundValue(utm.northing, 8), // Northing
              roundValue(utm.easting, 8), // Easting
              zone, // Zone
              coordSystemName
            )
            break
          case 2: // SVY21
            const isValidSVY21 = cv.checkValidSVY21(
              latLng.latitude,
              latLng.longitude
            )
            if (isValidSVY21) {
              const { N: northing, E: easting } = cv.computeSVY21(
                latLng.latitude,
                latLng.longitude
              )
              // MUST FOLLOW SAME PUSH ORDER AS HEADERS
              row.push(
                roundValue(northing, svy21NumOfDP),
                roundValue(easting, svy21NumOfDP),
                coordSystemName
              )
            } else row.push(dummyEntryValue, dummyEntryValue, coordSystemName)
            break
        }
      // MUST FOLLOW SAME PUSH ORDER AS HEADERS
      if (selectingDepth) row.push(depth)
      if (selectingAltitude) row.push(altitude)
      if (selectingVelocity) row.push(velocityX, velocityY, velocityZ)
      if (selectingHeading) row.push(heading)
      if (selectingPitch) row.push(pitch)
      if (selectingRoll) row.push(roll)
      if (selectingCp) row.push(cp)
      if (selectingUt) row.push(ut)
      if (selectingContact) row.push(contact)
      if (selectingProximity) row.push(proximity)
      if (selectingFieldGradient) row.push(fieldGradient)
      return row.map((field) => (field === undefined ? dummyEntryValue : field))
    }

    const changeUpwardToUp = (word: string) =>
      word === VelocityDirection[VelocityDirection.UPWARD]
        ? word.slice(0, 2)
        : word

    const toSentenceCase = (word: string) =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()

    let referenceOrigin: UTMCoord = {
      northing: 0,
      easting: 0,
      zone_letter: 'N',
      zone_number: 48,
    }
    // These data are not together with the compulsory latLong fields
    // as they are not in the 'world_ned' message
    let altitude: number | undefined
    let heading: number | undefined
    let pitch: number | undefined
    let roll: number | undefined
    let cp: number | undefined
    let ut: number | undefined
    let contact: number | undefined
    let proximity: number | undefined
    let fieldGradient: number | undefined

    for (const msg of this.messages) {
      // Update referenceOrigin if got new data
      if (msg.topic.includes('nav/utm')) {
        const newOrigin = {
          northing: msg.data.y,
          easting: msg.data.x,
          zone_letter: msg.data.zone_letter || 'N',
          zone_number: msg.data.zone_number || 48,
        }
        if (JSON.stringify(newOrigin) !== JSON.stringify(referenceOrigin))
          referenceOrigin = newOrigin
        // Update heading, pitch, roll if got new data
      } else if (msg.topic.includes('rpy_ned')) {
        heading = Math.round(msg.data.vector.z * 10) / 10
        roll = Math.round(msg.data.vector.x * 10) / 10
        pitch = Math.round(msg.data.vector.y * 10) / 10
        // Update altitude if got new data
      } else if (msg.topic.includes('pathfinder/altitude')) {
        if (msg.data.pose)
          altitude = roundValue(msg.data.pose.pose.position.z, 2)
        else if (msg.data.data) altitude = roundValue(msg.data.data, 2)
      } else if (msg.topic.includes('ins/altitude')) {
        altitude = roundValue(msg.data.pose.pose.position.z, 2)
        // Update cp if got new data
      } else if (msg.topic.includes('cp_probe')) {
        cp = roundValue(msg.data.data, 3)
        // Update ut if got new data
      } else if (msg.topic.includes('ut_probe')) {
        ut = roundValue(msg.data.converted_reading, 3)
        // Update contact, proximity, fieldGradient if got new data
      } else if (msg.topic.includes('hardware/str_seacp')) {
        const res = processStrSeacp(msg.data.data.data)
        if (!res) continue

        contact = res.contact
        proximity = res.proximity ? res.proximity : undefined
        fieldGradient = res.field_gradient
        // For latlong, depth, velocity, velocity direction, then push to CSV 2D array
      } else if (
        msg.topic.includes('world_ned') &&
        referenceOrigin.northing !== 0 &&
        referenceOrigin.easting !== 0
      ) {
        const latLngCoord = utmToLatLng({
          northing: referenceOrigin.northing + msg.data.pose.pose.position.x,
          easting: referenceOrigin.easting + msg.data.pose.pose.position.y,
          zone_letter: referenceOrigin.zone_letter,
          zone_number: referenceOrigin.zone_number,
        })
        const velocityX = msg.data.twist.twist.linear.x
        const velocityY = msg.data.twist.twist.linear.y
        const velocityZ = msg.data.twist.twist.linear.z
        const depth = msg.data.pose.pose.position.z
        csvData.push(
          msgData2CSVRow(
            timeToNumber(msg.timestamp),
            latLngCoord,
            roundValue(depth, 2),
            altitude,
            roundValue(velocityX, 2),
            roundValue(velocityY, 2),
            roundValue(velocityZ, 2),
            heading,
            pitch,
            roll,
            cp,
            ut,
            contact,
            proximity,
            fieldGradient
          )
        )
      }
    }

    const entireString = csvData.map((row) => row.join(',')).join('\n')
    return new Blob([entireString], { type: 'text/plain' })
  }

  getFullDepthCamImageUrl = () => this.fullDepthCamPlayer.urlCache

  getTopics() {
    return new Set(this.messages.map((msg) => msg.topic))
  }

  checkMaybeMissingCSVFieldsExist = () => {
    const strSeaCPFieldExists = this.messages.some((msg) =>
      msg.topic.includes('hardware/str_seacp')
    )
    return {
      cp: this.messages.some((msg) => msg.topic.includes('cp_probe')),
      ut: this.messages.some((msg) => msg.topic.includes('ut_probe')),
      contactAndFg: strSeaCPFieldExists,
      proximity:
        strSeaCPFieldExists &&
        this.messages
          .filter((msg) => msg.topic.includes('hardware/str_seacp'))
          .some((msg) => msg.data.data.data[6] >= 1),
    } as MaybeMissingCSVFieldsExistence
  }

  checkAnyValidSVY21 = () => {
    const cv = new SVY21()
    let referenceOrigin: UTMCoord = {
      northing: 0,
      easting: 0,
      zone_letter: 'N',
      zone_number: 48,
    }
    for (const msg of this.messages) {
      if (msg.topic.includes('nav/utm')) {
        const newOrigin = {
          northing: msg.data.y,
          easting: msg.data.x,
          zone_letter: msg.data.zone_letter || 'N',
          zone_number: msg.data.zone_number || 48,
        }
        if (JSON.stringify(newOrigin) !== JSON.stringify(referenceOrigin))
          referenceOrigin = newOrigin
      } else if (
        msg.topic.includes('world_ned') &&
        referenceOrigin.northing !== 0 &&
        referenceOrigin.easting !== 0
      ) {
        const latlng = utmToLatLng({
          northing: referenceOrigin.northing + msg.data.pose.pose.position.x,
          easting: referenceOrigin.easting + msg.data.pose.pose.position.y,
          zone_letter: referenceOrigin.zone_letter,
          zone_number: referenceOrigin.zone_number,
        })
        if (cv.checkValidSVY21(latlng.latitude, latlng.longitude)) return true
      }
    }
    return false
  }

  resetFullDepthCamPlayer() {
    this.fullDepthCamPlayer.worker.terminate()
    this.fullDepthCamPlayer = new Player({
      canvas: this.fullDepthCam.current,
      useWorker: true,
      workerFile: decoderWorkerPath, // Need to put Decoder into html
    })
  }

  resetSonarGrid() {
    this.sonarRef.current?.setSonarInfo(this.sonarInfo)
  }

  resetFrontCamPanel(
    frontCamRef: React.MutableRefObject<HTMLImageElement | null>
  ) {
    this.frontCamRef = frontCamRef
  }

  resetFrontCamClahePanel(
    frontCamClaheRef: React.MutableRefObject<HTMLImageElement | null>
  ) {
    this.frontCamClaheRef = frontCamClaheRef
  }
}

export { Rosbag }

/* HELPER FUNCTIONS */
export const timestampToTime = (timestamp: number) => {
  const arr = String(timestamp).split('.')
  return { sec: Number(arr[0]), nsec: Number(arr[1]) }
}

export const timeToNumber = (time: Time) => {
  return Number(`${time.sec}.${time.nsec}`)
}

export const getDuration = (endTime: Time, startTime: Time) => {
  const value =
    endTime.sec + endTime.nsec / 1e9 - (startTime.sec + startTime.nsec / 1e9)
  return Math.round(value * 10) / 10
}
