import React, { useEffect, useRef, useState, MouseEvent } from 'react'
import { SceneCoord } from '../../types'

// --- CONFIGURATIONS ---
const POINT_COLOR = 'yellow'
const POINT_RADIUS = 5
const POINT_DELETION_RADIUS = 10

const POLYGON_OPACITY = 0.7
const POLYGON_REGION_COLOR = `rgba(121, 135, 104, ${POLYGON_OPACITY})` // yellow
const LINE_WIDTH = 1 // Only for 2 points scenario

const ARROW_SHIFTING_DIST = 20
const ARROW_HEAD_SIZE = 5

const LABEL_FONT_BG_COLOR = 'rgb(135, 134, 38)'
const LABEL_FONT_COLOR = 'yellow'
const LABEL_FONT_SIZE = 12

const CONTAINER_ID = 'drawable-canvas-container'

// --- CALCULATION-RELATED HELPERS ---
const [fx, fy, cx, cy] = [985.8875, 993.552576, 991.237771, 609.152613]
const getVX = (px: number) => px / fx - cx
const getVY = (py: number) => cy - py / fy

const linearScale = (coordinate: number, oldMax: number, newMax: number) =>
    (coordinate / oldMax) * newMax

function reScalePoint(
    point: SceneCoord,
    oldWidth: number,
    oldHeight: number,
    newWidth: number,
    newHeight: number
) {
    return {
        x: linearScale(point.x, oldWidth, newWidth),
        y: linearScale(point.y, oldHeight, newHeight),
    }
}

function getActualPoint(
    point: SceneCoord,
    dValue: number,
    canvasHeight: number,
    canvasWidth: number,
    actualImgHeight: number,
    actualImgWidth: number
): SceneCoord {
    return {
        y: dValue * getVY(linearScale(point.y, canvasHeight, actualImgHeight)),
        x: dValue * getVX(linearScale(point.x, canvasWidth, actualImgWidth)),
    }
}

// The resultant angle is in range of 0 to 2π
function arctan(y: number, x: number) {
    const angle = Math.atan2(Math.abs(y), Math.abs(x))
    const _2Pi = 2 * Math.PI
    if (y < 0) return x > 0 ? angle : Math.PI - angle
    else return x > 0 ? (_2Pi - angle) % _2Pi : Math.PI + angle
}

// The resultant angle is in range of -π/2 to π/2
function getRotationalAngle(point1: SceneCoord, point2: SceneCoord) {
    const angle = arctan(point2.y - point1.y, point2.x - point1.x)
    const oneQuadrant = Math.PI / 2
    if (angle >= 3 * oneQuadrant) return angle - 2 * Math.PI
    if (angle >= oneQuadrant) return angle - Math.PI
    return angle
}

function calcPolygonArea(vertices: SceneCoord[]) {
    let total = 0
    for (let i = 0, l = vertices.length; i < l; i++) {
        let addX = vertices[i].x
        let addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y
        let subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x
        let subY = vertices[i].y
        total += addX * addY * 0.5
        total -= subX * subY * 0.5
    }
    return Math.abs(total)
}

function getMidPoint(point1: SceneCoord, point2: SceneCoord): SceneCoord {
    return {
        x: (point1.x + point2.x) / 2,
        y: (point1.y + point2.y) / 2,
    }
}

function getAvgCoord(vertices: SceneCoord[]) {
    const xSum = vertices.reduce((acc, curr) => acc + curr.x, 0)
    const ySum = vertices.reduce((acc, curr) => acc + curr.y, 0)
    return { x: xSum / vertices.length, y: ySum / vertices.length }
}

function findDist(point1: SceneCoord, point2: SceneCoord) {
    return Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2)
}

function getTranslationDeltas(startPoint: SceneCoord, endPoint: SceneCoord) {
    const angle = Math.atan2(
        Math.abs(endPoint.y - startPoint.y),
        Math.abs(endPoint.x - startPoint.x)
    )
    const deltaX = ARROW_SHIFTING_DIST * Math.sin(angle)
    const deltaY = ARROW_SHIFTING_DIST * Math.cos(angle)
    return { deltaX, deltaY }
}

function translatePoint(
    point: SceneCoord,
    deltaX: number,
    deltaY: number,
    isTop: boolean,
    isLeft: boolean
): SceneCoord {
    return {
        x: isLeft ? point.x - deltaX : point.x + deltaX,
        y: isTop ? point.y - deltaY : point.y + deltaY,
    }
}

// --- DRAWING-RELATED HELPERS ---
function plotPoint(context: CanvasRenderingContext2D, coords: SceneCoord) {
    context.fillStyle = POINT_COLOR
    context.beginPath()
    context.arc(coords.x, coords.y, POINT_RADIUS, 0, 2 * Math.PI, true)
    context.fill()
}

function drawLine( // Only used for 2 points scenario
    context: CanvasRenderingContext2D,
    point1: SceneCoord,
    point2: SceneCoord,
    color: string
) {
    context.beginPath()
    context.moveTo(point1.x, point1.y)
    context.lineTo(point2.x, point2.y)
    context.strokeStyle = color
    context.lineWidth = LINE_WIDTH
    context.stroke()
}

function drawDoubHeadedArrow(
    context: CanvasRenderingContext2D,
    startPoint: SceneCoord,
    endPoint: SceneCoord
) {
    const { x: fromx, y: fromy } = startPoint
    const { x: tox, y: toy } = endPoint
    let [dx, dy] = [tox - fromx, toy - fromy]
    let angle = Math.atan2(dy, dx)
    let [smallAngle, bigAngle] = [angle - Math.PI / 6, angle + Math.PI / 6]

    context.moveTo(fromx, fromy)
    context.lineTo(
        fromx + ARROW_HEAD_SIZE * Math.cos(smallAngle),
        fromy + ARROW_HEAD_SIZE * Math.sin(smallAngle)
    )
    context.moveTo(
        fromx + ARROW_HEAD_SIZE * Math.cos(bigAngle),
        fromy + ARROW_HEAD_SIZE * Math.sin(bigAngle)
    )
    context.lineTo(fromx, fromy)
    context.lineTo(tox, toy)
    context.lineTo(
        tox - ARROW_HEAD_SIZE * Math.cos(smallAngle),
        toy - ARROW_HEAD_SIZE * Math.sin(smallAngle)
    )
    context.moveTo(tox, toy)
    context.lineTo(
        tox - ARROW_HEAD_SIZE * Math.cos(bigAngle),
        toy - ARROW_HEAD_SIZE * Math.sin(bigAngle)
    )
    context.strokeStyle = POINT_COLOR
    context.lineWidth = LINE_WIDTH
    context.stroke()
}

// Main drawing function
function drawNLabelPolygon(
    context: CanvasRenderingContext2D,
    points: SceneCoord[],
    centroid: SceneCoord | null,
    dValue: number,
    canvasWidth: number,
    canvasHeight: number,
    actualImgWidth: number,
    actualImgHeight: number,
    unit: string,
    numOfDP: number,
    setArea: (area: number) => void
) {
    let length = points.length
    if (length === 0) return
    if (length === 1) {
        plotPoint(context, points[0])
        return
    }
    if (length === 2) {
        let [point1, point2] = points
        plotPoint(context, point1)
        plotPoint(context, point2)
        drawLine(context, point1, point2, POINT_COLOR)
        labelling2Points(
            point1,
            point2,
            dValue,
            canvasHeight,
            canvasWidth,
            actualImgHeight,
            actualImgWidth,
            unit,
            numOfDP
        )
        return
    }
    // For 3 or more points
    // Fill the polygon with color
    let polygonRegion = new Path2D()
    polygonRegion.moveTo(points[0].x, points[0].y)
    for (let prevIndex = 0; prevIndex < length; prevIndex++) {
        let currIndex = (prevIndex + 1) % length
        polygonRegion.lineTo(points[currIndex].x, points[currIndex].y)
        plotPoint(context, points[prevIndex])
    }
    polygonRegion.closePath()
    context.fillStyle = POLYGON_REGION_COLOR
    context.fill(polygonRegion)

    // Transform coordinates value to actual dimensions
    const actualPoints: SceneCoord[] = points.map((point) =>
        getActualPoint(
            point,
            dValue,
            canvasHeight,
            canvasWidth,
            actualImgHeight,
            actualImgWidth
        )
    )

    // Put arrows & label the edges
    let point1: SceneCoord
    let actualPoint1: SceneCoord
    let point2: SceneCoord
    let actualPoint2: SceneCoord
    let midPoint: SceneCoord
    let isTop: boolean
    let isLeft: boolean
    let nextIdx: number
    for (let currIdx = 0; currIdx < points.length; currIdx++) {
        point1 = points[currIdx]
        actualPoint1 = actualPoints[currIdx]
        nextIdx = currIdx === length - 1 ? 0 : currIdx + 1
        point2 = points[nextIdx]
        actualPoint2 = actualPoints[nextIdx]
        midPoint = getMidPoint(point1, point2)
        isTop = midPoint.y < centroid!.y
        isLeft = midPoint.x < centroid!.x
        let { deltaX, deltaY } = getTranslationDeltas(point1, point2)
        let [translatedPoint1, translatedPoint2, translatedMidPoint] = [
            point1,
            point2,
            midPoint,
        ].map((point) => translatePoint(point, deltaX, deltaY, isTop, isLeft))
        drawDoubHeadedArrow(context, translatedPoint1, translatedPoint2)
        labelArrow(
            actualPoint1,
            actualPoint2,
            translatedMidPoint,
            unit,
            numOfDP
        )
    }
    labelNStoreArea(actualPoints, centroid!, unit, numOfDP, setArea)
}

// ---LABELLING-RELATED HELPERS---
function labelCanvas(
    value: string,
    unit: string,
    positionCoords: SceneCoord,
    angle: number = 0
) {
    const padding = unit.startsWith('in') || unit.startsWith('ft') ? ' ' : ''
    const text = value + padding + unit
    const textElement = document.createElement('span')
    const container = document.getElementById(CONTAINER_ID)!
    textElement.innerText = text
    container.appendChild(textElement)
    const [textWidth, textHeight] = [
        textElement.offsetWidth,
        textElement.offsetHeight,
    ]
    container.removeChild(textElement)
    textElement.className = 'abs allow-select padding-xsm rounded-sm z-2'
    textElement.style.fontSize = `${LABEL_FONT_SIZE}px`
    textElement.style.color = LABEL_FONT_COLOR
    textElement.style.backgroundColor = LABEL_FONT_BG_COLOR
    textElement.style.left = `${positionCoords.x - textWidth / 2}px`
    textElement.style.top = `${positionCoords.y - textHeight / 2}px`
    textElement.style.transform = `rotate(${angle}rad)`
    container.appendChild(textElement)
}

function labelArrow(
    actualStartPoint: SceneCoord,
    actualEndPoint: SceneCoord,
    positionCoords: SceneCoord,
    unit: string,
    numOfDP: number
) {
    const angle = getRotationalAngle(actualStartPoint, actualEndPoint)
    const distVal = findDist(actualStartPoint, actualEndPoint)
    labelCanvas(distVal.toFixed(numOfDP), unit, positionCoords, angle)
}

function labelLine( // Only used for 2 points scenario
    distance: number,
    positionCoords: SceneCoord,
    unit: string,
    numOfDP: number,
    angle: number = 0
) {
    labelCanvas(distance.toFixed(numOfDP), unit, positionCoords, angle)
}

function labelling2Points(
    point1: SceneCoord,
    point2: SceneCoord,
    dValue: number,
    canvasHeight: number,
    canvasWidth: number,
    actualImgHeight: number,
    actualImgWidth: number,
    unit: string,
    numOfDP: number
) {
    const midPoint = getMidPoint(point1, point2)
    let [actualPoint1, actualPoint2] = [point1, point2].map((point) =>
        getActualPoint(
            point,
            dValue,
            canvasHeight,
            canvasWidth,
            actualImgHeight,
            actualImgWidth
        )
    )
    let distVal = findDist(actualPoint1, actualPoint2)
    const angle = getRotationalAngle(actualPoint1, actualPoint2)
    labelLine(distVal, midPoint, unit, numOfDP, angle)
}

function labelNStoreArea(
    actualPoints: SceneCoord[],
    positionCoords: SceneCoord,
    unit: string,
    numOfDP: number,
    setArea: (value: number) => void
) {
    let areaVal = calcPolygonArea(actualPoints)
    setArea(areaVal)
    labelCanvas(areaVal.toFixed(numOfDP), unit + '\u00B2', positionCoords)
}

// ---DELETION & CLEARING RELATED HELPERS---
function clearAllLabels() {
    const container = document.getElementById(CONTAINER_ID)!
    Array.from(container.children)
        .filter((child) => child instanceof HTMLSpanElement)
        .forEach((child) => container.removeChild(child))
}

const clearWholeCanvas = (
    context: CanvasRenderingContext2D,
    canvasWidth: number,
    canvasHeight: number,
    resetArea: () => void
) => {
    context.clearRect(0, 0, canvasWidth, canvasHeight)
    clearAllLabels()
    resetArea()
}

const isWithinDeletionRadius = (point: SceneCoord, click: SceneCoord) =>
    (point.x - click.x) ** 2 + (point.y - click.y) ** 2 <=
    POINT_DELETION_RADIUS ** 2

const isWithinPointRadius = (point: SceneCoord, click: SceneCoord) =>
    (point.x - click.x) ** 2 + (point.y - click.y) ** 2 <= POINT_RADIUS ** 2

type DrawableCanvasProps = {
    imgElementRef: React.RefObject<HTMLImageElement>
    viewMode: number
    className: string
    dValue: number
    getCanvasWidth: () => number
    getCanvasHeight: () => number
    actualImgHeight: number
    actualImgWidth: number
    setNumOfPoints: (prev: (prev: number) => number) => void
    erasing: boolean
    setErasing: (value: boolean) => void
    setArea: (value: number) => void
    selectedUnit: string
    numOfDP: number
}

const DrawableCanvas = ({
    imgElementRef,
    viewMode,
    className,
    dValue, // The unit of this follows the selected unit
    getCanvasHeight,
    getCanvasWidth,
    actualImgHeight,
    actualImgWidth,
    setNumOfPoints,
    erasing,
    setErasing,
    setArea,
    selectedUnit,
    numOfDP,
}: DrawableCanvasProps) => {
    const [canvasWidth, canvasHeight] = [getCanvasWidth(), getCanvasHeight()]
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const contextRef = useRef<CanvasRenderingContext2D | null>(null)

    const polygonPoints = useRef<SceneCoord[]>([])
    const angles = useRef<number[]>([])
    const centroid = useRef<SceneCoord | null>(null)

    const justRendered = useRef<boolean>(false)

    const updateCentroid = () =>
        (centroid.current = getAvgCoord(polygonPoints.current))

    function AddPointNUpdateCentroid(newPoint: SceneCoord) {
        let oldLength = polygonPoints.current.length - 1
        let oldCentroid: SceneCoord = { ...centroid.current! }
        centroid.current = {
            x: (oldCentroid.x * oldLength + newPoint.x) / (oldLength + 1),
            y: (oldCentroid.y * oldLength + newPoint.y) / (oldLength + 1),
        }
    }

    const findAngle = (point: SceneCoord) =>
        arctan(point.y - centroid.current!.y, point.x - centroid.current!.x)

    const updateAnglesNSortPoints = () => {
        angles.current = polygonPoints.current.map((point) => findAngle(point))
        polygonPoints.current.sort((point1, point2) => {
            let angle1 = findAngle(point1)
            let angle2 = findAngle(point2)
            return angle1 - angle2
        })
    }

    function AddPointNUpdateCentroidNAngles(newPoint: SceneCoord) {
        AddPointNUpdateCentroid(newPoint)
        updateAnglesNSortPoints()
    }

    function addPoint(newPoint: SceneCoord) {
        const points = polygonPoints.current
        const length = points.length
        let newPointAngle
        setNumOfPoints((prevLength) => prevLength + 1)
        switch (length) {
            case 0: // Add first point
                polygonPoints.current.push(newPoint)
                centroid.current = newPoint
                break
            case 1: // Add second point
                centroid.current = getMidPoint(points[0], newPoint)
                let oldPointAngle = findAngle(points[0])
                newPointAngle = findAngle(newPoint)
                if (newPointAngle < oldPointAngle) {
                    angles.current.push(newPointAngle)
                    angles.current.push(oldPointAngle)
                    points.unshift(newPoint)
                } else {
                    angles.current.push(oldPointAngle)
                    angles.current.push(newPointAngle)
                    points.push(newPoint)
                }
                break
            default: // Add subsequent points
                newPointAngle = findAngle(newPoint)
                let i = 0
                while (i < length && newPointAngle > angles.current[i]) {
                    i++
                }
                points.splice(i, 0, newPoint)
                AddPointNUpdateCentroidNAngles(newPoint)
                break
        }
    }

    function removePoint(index: number) {
        polygonPoints.current.splice(index, 1)
        setNumOfPoints((prevLength) => prevLength - 1)
        updateCentroid()
        updateAnglesNSortPoints()
    }

    const resetArea = () => setArea(0)
    function resetCanvas() {
        const canvas = canvasRef.current!
        const context = contextRef.current!
        clearWholeCanvas(context, canvas.width, canvas.height, resetArea)
    }

    const resetPolygonState = () => {
        polygonPoints.current = []
        angles.current = []
        centroid.current = null
        setNumOfPoints(() => 0)
        setErasing(false)
    }

    function redrawEverything() {
        const points = polygonPoints.current
        if (points.length === 0) return
        const canvas = canvasRef.current!
        const context = contextRef.current!
        drawNLabelPolygon(
            context,
            points,
            centroid.current,
            dValue,
            canvas.width,
            canvas.height,
            actualImgWidth,
            actualImgHeight,
            selectedUnit,
            numOfDP,
            setArea
        )
    }

    const drawingOnCanvas = (event: MouseEvent<HTMLCanvasElement>) => {
        const imgElement = imgElementRef.current
        if (!canvasRef.current || !contextRef.current || !imgElement) return

        event.preventDefault()
        event.stopPropagation()
        const imgRect = imgElement.getBoundingClientRect()
        const currPoint: SceneCoord = {
            x: event.pageX - imgRect.left,
            y: event.pageY - imgRect.top,
        }

        const points = polygonPoints.current
        const deletedIdx = points.findIndex((point) =>
            isWithinDeletionRadius(point, currPoint)
        )
        if (deletedIdx !== -1) removePoint(deletedIdx)
        else addPoint(currPoint)

        const context = contextRef.current
        resetCanvas()
        redrawEverything()
    }

    const showPointDeletionHoveringText = (
        event: MouseEvent<HTMLCanvasElement>
    ) => {
        event.preventDefault()
        event.stopPropagation()
        const imgElement = imgElementRef.current
        if (!canvasRef.current || !contextRef.current || !imgElement) return

        const points = polygonPoints.current
        const length = points.length
        const container = document.getElementById(CONTAINER_ID)!
        const hoveringText = container.getElementsByTagName('div')[0]
        if (length === 0) {
            hoveringText.style.display = 'none'
            return
        }
        const imgRect = imgElement.getBoundingClientRect()
        const currPoint: SceneCoord = {
            x: event.pageX - imgRect.left,
            y: event.pageY - imgRect.top,
        }
        if (
            points.findIndex((point) =>
                isWithinPointRadius(point, currPoint)
            ) !== -1
        ) {
            hoveringText.style.left = `${currPoint.x}px`
            hoveringText.style.top = `${currPoint.y - 24}px`
            hoveringText.style.display = 'inline'
        } else hoveringText.style.display = 'none'
    }

    // Initial setup
    useEffect(() => {
        const canvas = canvasRef.current
        if (!canvas) return
        const rect = canvas.getBoundingClientRect()
        let img = imgElementRef.current
        if (img) {
            ;[canvas.width, canvas.height] = [img.clientWidth, img.clientHeight]
        }
        contextRef.current = canvas.getContext('2d')
    }, [])

    useEffect(() => {
        if (!justRendered.current) {
            justRendered.current = true
        } else {
            const canvas = canvasRef.current
            const context = contextRef.current
            const points = polygonPoints.current
            if (!canvas || !context) return
            let [oldWidth, oldHeight] = [canvas.width, canvas.height]
            resetCanvas()
            ;[canvas.width, canvas.height] = [canvasWidth, canvasHeight]
            if (points.length !== 1) {
                // For only 1 point, rescaling will screw up the offsets
                polygonPoints.current = points.map((point) =>
                    reScalePoint(
                        point,
                        oldWidth,
                        oldHeight,
                        canvasWidth,
                        canvasHeight
                    )
                )
                updateCentroid()
                updateAnglesNSortPoints()
            }
            redrawEverything()
        }
    }, [canvasWidth, canvasHeight])

    // This will only run after the first render. It is to handle the wrong offset for the first point
    useEffect(() => {
        if (!justRendered.current) return
        const canvas = canvasRef.current
        const length = polygonPoints.current.length
        const context = contextRef.current
        if (!context || !canvas) return

        const firstPoint = polygonPoints.current[0]
        if (length !== 1) return
        ;[canvas.width, canvas.height] = [canvasWidth, canvasHeight]
        resetCanvas()
        if (firstPoint.x > canvasWidth || firstPoint.y > canvasHeight) {
            resetPolygonState()
        } else {
            redrawEverything()
        }
    }, [justRendered.current])

    // useEffect(() => {
    //     if (justRendered.current) {
    //     const canvas = canvasRef.current
    //     const context = contextRef.current
    //     if (!context || !canvas) return;
    //     [canvas.width, canvas.height] = [canvasWidth, canvasHeight]
    //     resetCanvas(context, canvas.width, canvas.height, resetArea)
    //     resetPolygonState()
    //     }
    // }, [window.innerHeight, window.innerWidth])

    // Clear canvas when 'Clear all' button is clicked
    useEffect(() => {
        const canvas = canvasRef.current
        if (!canvas) return
        const context = contextRef.current
        if (!context) return
        if (polygonPoints.current.length === 0) return

        resetCanvas()
        resetPolygonState()
    }, [erasing, viewMode])

    useEffect(() => {
        const canvas = canvasRef.current
        if (!canvas) return
        const context = contextRef.current
        const points = polygonPoints.current
        const length = points.length
        if (!context || length < 2 || !centroid.current) return
        clearAllLabels()
        if (length === 2) {
            let [point1, point2] = points
            labelling2Points(
                point1,
                point2,
                dValue,
                canvas.height,
                canvas.width,
                actualImgHeight,
                actualImgWidth,
                selectedUnit,
                numOfDP
            )
            return
        }
        const actualPoints: SceneCoord[] = points.map((point) =>
            getActualPoint(
                point,
                dValue,
                canvas.height,
                canvas.width,
                actualImgHeight,
                actualImgWidth
            )
        )

        // Relabel the edges
        for (let curr = 0; curr < length; curr++) {
            let next = (curr + 1) % length
            let midPoint = getMidPoint(points[curr], points[next])
            let { deltaX, deltaY } = getTranslationDeltas(
                points[curr],
                points[next]
            )
            let isTop = midPoint.y < centroid.current.y
            let isLeft = midPoint.x < centroid.current.x
            let translatedMidPoint = translatePoint(
                midPoint,
                deltaX,
                deltaY,
                isTop,
                isLeft
            )
            labelArrow(
                actualPoints[curr],
                actualPoints[next],
                translatedMidPoint,
                selectedUnit,
                numOfDP
            )
        }
        // Relabel the area
        labelNStoreArea(
            actualPoints,
            centroid.current,
            selectedUnit,
            numOfDP,
            setArea
        )
    }, [dValue, selectedUnit])

    return (
        <div
            id={CONTAINER_ID}
            className={className}
            // style={{ border: '2px solid #000' }} // For debugging purposes
        >
            <canvas
                ref={canvasRef}
                onMouseMove={showPointDeletionHoveringText}
                onMouseDown={drawingOnCanvas}></canvas>
            <div className='surface-variant-bg rounded-sm padding-sm text-sm no-wrap abs z-3'>
                Click to remove this point
            </div>
        </div>
    )
}

export default DrawableCanvas
