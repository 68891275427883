import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    ImageRun,
    SectionType,
    IImageOptions,
    ISectionOptions,
    AlignmentType,
    Header,
    IFloating,
    HorizontalPositionAlign,
    Footer,
    PageNumber,
    ParagraphChild,
    Table,
    TableRow,
    TableCell,
    VerticalAlign,
    BorderStyle,
    IShadingAttributesProperties,
    convertMillimetersToTwip,
    ShadingType,
    ITableCellBorders,
    HeightRule,
    VerticalPositionRelativeFrom,
    IPageMarginAttributes,
    ISectionPropertiesOptions,
    TableLayoutType,
} from 'docx'
import { BORDER_NONE, SHADING_CLEAR, SHADING_GREY } from '../constants'

const getDummyCell = (
    shading: IShadingAttributesProperties | undefined,
    columnSpan = 1,
    rowSpan = 1
) =>
    new TableCell({
        rowSpan: rowSpan,
        columnSpan: columnSpan,
        borders: BORDER_NONE,
        shading: shading,
        children: [],
    })
export const getClearDummyCell = (columnSpan = 1, rowSpan = 1) =>
    getDummyCell(SHADING_CLEAR, columnSpan, rowSpan)
export const getGreyDummyCell = (columnSpan = 1, rowSpan = 1) =>
    getDummyCell(SHADING_GREY, columnSpan, rowSpan)
export const dummyParagraph = new Paragraph({ children: [] })
