import React, { useContext, useEffect, useState } from 'react'
import { MarkerType, timestampToDate } from '../../../../../types'
import { MarkerDetails } from '../components/MarkerDetails'
import TextBox from '../../../../elements/inputs/TextBox'
import { msalFetchImage } from '../../../../../backend'
import { useAccount, useMsal } from '@azure/msal-react'
import { AppContext } from '../../../../../store/context'
import LoadingSpinner from '../../../../elements/loadingSpinner'
import IconButton from '../../../../elements/buttons/IconButton'
import { CloseIcon } from '../../../../../assets'

type MarkerPanelProps = {
  marker: MarkerType
  onClose: () => void
}

const MarkerPanel = ({ marker, onClose }: MarkerPanelProps) => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const { state } = useContext(AppContext)
  const { msal } = state

  // [Default front cam, Clahe front cam, sonar]
  const [images, setImages] = useState<(string | undefined | null)[]>([
    undefined,
    undefined,
    undefined,
  ])

  const fetchImages = async (marker: MarkerType) => {
    setImages([undefined, undefined, undefined])
    const msalInfo = {
      instance: instance,
      account: account,
      storage: msal.storage,
      asset: msal.asset,
    }
    const front = await msalFetchImage(
      msalInfo,
      timestampToDate(marker.timestamp),
      `${marker.bag_id}_front_cam.jpeg`
    )
    const frontClahe = await msalFetchImage(
      msalInfo,
      timestampToDate(marker.timestamp),
      `${marker.bag_id}_front_cam_clahe.jpeg`
    )
    const sonar = await msalFetchImage(
      msalInfo,
      timestampToDate(marker.timestamp),
      `${marker.bag_id}_sonar.jpeg`
    )
    setImages([front, frontClahe, sonar])
  }
  useEffect(() => {
    fetchImages(marker)
  }, [marker])
  return (
    <div className='flex-col'>
      <div className='flex justify-between align-center'>
        <div className='text-lg text-bold padding-h-md'>{marker.name}</div>
        <IconButton Icon={CloseIcon} onClick={onClose} />
      </div>
      <MarkerDetails marker={marker} />
      {marker.comment && (
        <TextBox
          disabled
          value={marker.comment}
          onChange={(value) => console.log(value)}
        />
      )}
      <div className='padding-t-md'>
        <CameraImage normal={images[0]} clahe={images[1]} />
      </div>
      <div className='padding-t-sm'>
        <SonarImage image={images[2]} />
      </div>
    </div>
  )
}

export default MarkerPanel

type CameraImageProps = {
  normal: string | undefined | null
  clahe: string | undefined | null
}

const CameraImage = ({ normal, clahe }: CameraImageProps) => {
  const [hover, setHover] = useState(false)
  return (
    <div className='flex justify-center'>
      {!hover && normal === undefined && (
        <LoadingSpinner width={80} height={80} />
      )}
      {!hover && normal === null && <div>Opps, image is missing.</div>}
      {!hover && normal && (
        <img
          className={`w-100 h-auto object-fit-contain rounded-sm`}
          src={normal}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      )}
      {hover && clahe === undefined && (
        <LoadingSpinner width={80} height={80} />
      )}
      {hover && clahe === null && <div>Opps, image is missing.</div>}
      {hover && clahe && (
        <img
          className={`w-100 h-auto object-fit-contain rounded-sm`}
          src={clahe}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      )}
    </div>
  )
}

type SonarImageProps = {
  image: string | undefined | null
}

const SonarImage = ({ image }: SonarImageProps) => {
  const [hover, setHover] = useState(false)
  return (
    <div className='flex justify-center'>
      {image === undefined && <LoadingSpinner width={80} height={80} />}
      {image === null && <div>Opps, image is missing.</div>}
      {image && (
        <img
          className={`w-100 h-auto object-fit-contain rounded-sm`}
          src={image || ''}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      )}
    </div>
  )
}
