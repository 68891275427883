import React, { Ref, useEffect, useState } from 'react'

type ProgressBarProps = {
  width: number | string
  height: number
  progressPercent: number
}

const ProgressBar = ({ width, height, progressPercent }: ProgressBarProps) => {
  return (
    <div className='progress-bar' style={{ width: width, height: height }}>
      <div
        className='progress-bar-fill rel w-100 h-100 flex justify-center align-center'
        style={{ width: `${progressPercent}%` }}
      />
    </div>
  )
}

export default ProgressBar
