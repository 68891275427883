const THUMBNAIL_GRP_SIZE = 3 // Load thumbnails every x thumbnails

const getThumbnailGroup = (index: number) =>
  Math.floor(index / THUMBNAIL_GRP_SIZE)

const getNumberOfGroups = (numberOfThumbnails: number) =>
  Math.ceil(numberOfThumbnails / THUMBNAIL_GRP_SIZE)

const isNextGroup = (index: number) => index % THUMBNAIL_GRP_SIZE === 0

export { getThumbnailGroup, getNumberOfGroups, isNextGroup }
