import React, { useContext, useEffect, useState } from 'react'
import GhostPanel from '../GhostPanel'
import TextInput from '../inputs/TextInput'
import TextButton from '../buttons/TextButton'
import { HDivider } from '../dividers'
import IconButton from '../buttons/IconButton'
import BinIcon from '../../../assets/icons/bin.svg'
import CloseIcon from '../../../assets/icons/close_black_24dp.svg'
import FilledButton from '../buttons/FilledButton'
import { HashtagType } from '../../../types'
import { useAccount, useMsal } from '@azure/msal-react'
import {
    msalAddHashtag,
    msalDeleteHashtag,
    msalFetchHashtagList,
    msalRemoveIdFromHashtag,
    msalRenameHashtag,
    msalUpdateHashtag,
} from '../../../backend/hashtag'
import LoadingSpinner from '../loadingSpinner'
import Dialog from '../Dialogs/Dialog'
import { AppContext } from '../../../store/context'

type HashtagInputProps = {
    flexGrow?: boolean
    tag: string
    type: HashtagType
    id: string
    onEdit: () => void
}

const HashtagInput = ({
    id,
    tag,
    type,
    flexGrow,
    onEdit,
}: HashtagInputProps) => {
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const { state } = useContext(AppContext)
    const { msal } = state

    const msalInfo = {
        instance: instance,
        account: account,
        storage: msal.storage,
        asset: msal.asset,
    }

    const [hover, setHover] = useState(false)
    const [expand, setExpand] = useState(false)
    const [loading, setLoading] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState({
        show: false,
        value: '',
    })

    const [temp, setTemp] = useState(tag)
    const [list, setList] = useState<string[]>([])

    const renameHashtag = async (oldHashtag: string, newHashtag: string) => {
        setLoading(true)
        const res = await msalRenameHashtag(
            msalInfo,
            type,
            oldHashtag,
            newHashtag
        )
        onEdit()
        if (oldHashtag == temp) {
            setTemp(newHashtag)
        }
        fetchHashtag()
    }

    const deleteHashtag = async (value: string) => {
        setLoading(true)
        const res = await msalDeleteHashtag(msalInfo, type, value)
        onEdit()
        setConfirmDelete({ show: false, value: '' })
        if (value == temp) setTemp('')
        fetchHashtag()
    }

    const selectHashtag = async (value: string) => {
        if (temp !== value) {
            setLoading(true)
            const res = await msalUpdateHashtag(msalInfo, type, id, temp, value)
            onEdit()
            setLoading(false)
        }
        setTemp(value)
        setExpand(false)
    }

    const addHashtag = async (value: string) => {
        setLoading(true)
        const res = await msalAddHashtag(msalInfo, type, id, temp, value)
        setLoading(false)
        if (res == 0) {
            setTemp(value)
            setExpand(false)
        }
    }

    const removeHashtag = async (value: string) => {
        setLoading(true)
        const res = await msalRemoveIdFromHashtag(msalInfo, type, id, value)
        onEdit()
        setLoading(false)
        if (res == 0) {
            setTemp('')
            setExpand(false)
        }
    }

    const fetchHashtag = async () => {
        setLoading(true)
        setList(await msalFetchHashtagList(msalInfo, type))
        setLoading(false)
    }

    useEffect(() => {
        if (expand) fetchHashtag()
    }, [expand])

    useEffect(() => {
        setTemp(tag)
    }, [tag])

    return (
        <div className={`${flexGrow ? 'flex-grow-1' : ''} rel`}>
            <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => setExpand(true)}
                style={{ height: '44px' }}
                className={`
        ${hover || expand ? 'primary-border' : 'outline-border'}
        rounded-sm surface-container-bg cursor-pointer flex align-center justify-between`}>
                <div className='padding-l-sm'>
                    {temp == '' ? '-' : `#${temp}`}
                </div>
                {temp !== '' && (
                    <IconButton
                        Icon={CloseIcon}
                        containerColor=''
                        onClick={() => removeHashtag(tag)}
                    />
                )}
            </div>
            {confirmDelete.show && (
                <Dialog
                    text={'Deleting tag'}
                    description={`Are you sure you want to delete #${confirmDelete.value}?`}
                    buttonText='Delete'
                    onClose={() => setConfirmDelete({ show: false, value: '' })}
                    onClick={() => deleteHashtag(confirmDelete.value)}
                />
            )}
            {expand && (
                <HashtagPanel
                    list={list}
                    loading={loading}
                    onCreate={addHashtag}
                    onSelect={selectHashtag}
                    onRename={renameHashtag}
                    onDelete={(value) =>
                        setConfirmDelete({ show: true, value: value })
                    }
                    onClose={() => setExpand(false)}
                    onRefresh={fetchHashtag}
                />
            )}
        </div>
    )
}

export default HashtagInput

type HashtagPanelProps = {
    list: string[]
    loading: boolean
    onCreate: (value: string) => void
    onSelect: (value: string) => void
    onDelete: (value: string) => void
    onRename: (oldValue: string, newValue: string) => void
    onClose: () => void
    onRefresh: () => void
}

const HashtagPanel = ({
    list,
    loading,
    onCreate,
    onSelect,
    onDelete,
    onRename,
    onClose,
    onRefresh,
}: HashtagPanelProps) => {
    const [temp, setTemp] = useState('')
    const [search, setSearch] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [canAdd, setCanAdd] = useState(false)

    const regexExp = /^(?:[a-zA-Z0-9]|([a-zA-Z0-9][\w\s-]*[a-zA-Z0-9]))$/
    const generateErrorMsg = (str: string): string => {
        if (!regexExp.test(str)) {
            if (/^.$/.test(str)) {
                return 'Tag cannot be an empty string.'
            }
            if (!/^[a-zA-Z0-9]/.test(str)) {
                return 'Must start with alphabet or number.'
            }
            if (!/[a-zA-Z0-9]$/.test(str)) {
                return 'Must end with a letter or digit.'
            }
            return 'Only a-z, A-Z, 0-9, and _ are valid.'
        }
        console.log(5)
        return ''
    }

    useEffect(
        () => (regexExp.test(temp) ? setSearch(temp) : setSearch('')),
        [temp]
    )

    useEffect(() => {
        const res = list.filter((value) => value == search)
        if (res.length > 0) setCanAdd(false)
        else setCanAdd(true)
    }, [search])
    return (
        <>
            <GhostPanel onClick={() => onClose()} />
            <div
                className={
                    'rounded-sm padding-md abs surface-container-bg z-4 shadow w-100 border-box'
                }>
                <TextInput
                    title={'Create or find tags'}
                    value={''}
                    headerPresent={false}
                    onChange={(_) => {}}
                    regex={regexExp}
                    storingFunction={(value) => setTemp(String(value))}
                    error={generateErrorMsg(temp)}
                />
                {loading && (
                    <div className='flex-grow-1 flex justify-center padding-t-sm'>
                        <LoadingSpinner width={36} height={36} />
                    </div>
                )}
                {!loading && (
                    <div
                        className='overflow-auto flex-grow-1'
                        style={{ maxHeight: '160px' }}>
                        {list
                            .filter((value) => value.includes(search))
                            .map((value, idx) => (
                                <HashtagItem
                                    key={idx}
                                    value={value}
                                    onClick={() => onSelect(value)}
                                    onRename={(newValue) =>
                                        onRename(value, newValue)
                                    }
                                    onDelete={() => onDelete(value)}
                                    editMode={editMode}
                                />
                            ))}
                        {list.length == 0 && (
                            <div className='text-center text-sm padding-sm on-surface margin-t-sm'>
                                No tags found.
                                <br />
                                Create a tag to get started.
                            </div>
                        )}
                    </div>
                )}
                {!loading && search !== '' && (
                    <FilledButton
                        disabled={!canAdd}
                        className='margin-t-sm'
                        text={'+ Create a new tag'}
                        maxWidth
                        onClick={() => onCreate(search)}
                    />
                )}
                {!loading && list.length > 0 && (
                    <>
                        <HDivider />
                        <TextButton
                            text={editMode ? 'Done' : 'Edit tags'}
                            maxWidth
                            onClick={() => {
                                if (editMode) onRefresh()
                                setEditMode(!editMode)
                            }}
                        />
                    </>
                )}
            </div>
        </>
    )
}

type HashtagItemProps = {
    value: string
    editMode: boolean
    onClick: () => void
    onRename: (value: string) => void
    onDelete: () => void
}
const HashtagItem = ({
    value,
    editMode,
    onClick,
    onRename,
    onDelete,
}: HashtagItemProps) => {
    const [temp, setTemp] = useState(value)
    const [hover, setHover] = useState(false)
    return (
        <div className='rel flex margin-t-sm justify-between'>
            {!editMode && (
                <div
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={() => onClick()}
                    className={`
          ${hover && !editMode ? 'surface-container-highest-bg' : ''}
          cursor-pointer rounded-sm padding-md flex-grow-1`}>
                    #{value}
                </div>
            )}
            {editMode && (
                <TextInput
                    title={''}
                    headerPresent={false}
                    value={temp}
                    regex={/^\S*$/g}
                    error={'No spaces allowed'}
                    showError={true}
                    onChange={(value) => setTemp(String(value))}
                />
            )}
            {editMode && temp !== value && (
                <div
                    className='cursor-pointer text-sm text-bold surface-container-highest-bg abs right-0 padding-sm rounded-sm'
                    style={{ marginRight: '50px', marginTop: '8px' }}
                    onClick={() => onRename(temp)}>
                    Save
                </div>
            )}
            {editMode && (
                <IconButton
                    className='margin-r-sm'
                    iconHeight={16}
                    iconWidth={16}
                    Icon={BinIcon}
                    onClick={onDelete}
                />
            )}
        </div>
    )
}
