import React, { useState, useEffect } from 'react'
import FullscreenIcon from '../../../../assets/icons/fullscreen_black_24dp.svg'
import {
  SonarInfo,
  PanelType,
  str2PanelType,
  expandablePanelTypes
} from '../../../../types'
import Button from '../../../elements/buttons/Button'
import { HBlock } from '../../../elements/dividers'
import { isMobile } from '../../../utils/general'
import SonarImage, { SonarImageInterface } from '../../../utils/sonar'
import SadFaceIcon from '../../../../assets/icons/drawing.svg'
import Dropdown from '../../../elements/Dropdown'

type LeftPanelWithDropdownsProps = {
  topPanelType: PanelType
  setTopPanelType: (value: PanelType) => void
  bottomPanelType: PanelType
  setBottomPanelType: (value: PanelType) => void
  remainingPanelTypes: PanelType[]
  frontCamImage: string
  frontCamClaheImage: string
  sonarImage: string
  fullDepthCamImage: string
  setExpandedCamViewerMode: (value: number) => void
  setExpandSonar: (value: boolean) => void
  setRenderMap: (value: boolean) => void
  sonarRef?: React.RefObject<SonarImageInterface>
  sonarInfo?: SonarInfo
}

const LeftPanelWithDropdowns = ({
  topPanelType,
  setTopPanelType,
  bottomPanelType,
  setBottomPanelType,
  remainingPanelTypes,
  frontCamImage,
  frontCamClaheImage,
  sonarImage,
  fullDepthCamImage,
  setExpandedCamViewerMode,
  setExpandSonar,
  setRenderMap,
  sonarRef,
  sonarInfo,
}: LeftPanelWithDropdownsProps) => {
  const mobile = isMobile()

  useEffect(() => {
    setTimeout(() => setRenderMap(true), 1)
  }, [])

  const getPanel = (panelType: PanelType) => {
    if (
      (frontCamClaheImage === '' && panelType === PanelType.FRONT_CAM_CLAHE) ||
      (frontCamImage === '' && panelType === PanelType.FRONT_CAM) ||
      (sonarImage === '' && panelType === PanelType.SONAR)
    ) return (
      <div className='w-100 h-100 flex flex-col justify-center align-center outline-border rounded-sm'>
        <SadFaceIcon width={120} height={120} />
        <div className='padding-h-sm'>
          {`Opps, the ${panelType.toString().toLowerCase()} image is missing.`}
        </div>
      </div>
    )

    if (panelType === PanelType.SONAR)
      return <SonarImage
        imgClassName={
          'bg-black-6 rounded-sm w-100 h-fit-content max-height-100 overflow-hidden rel'
        }
        ref={sonarRef}
        src={sonarImage}
        sonarInfo={sonarInfo}
        open={() => setExpandSonar(true)}
        close={() => setExpandSonar(false)}
      />

    let url = ''
    switch (panelType) {
      case PanelType.FRONT_CAM:
        url = frontCamImage
        break
      case PanelType.FRONT_CAM_CLAHE:
        url = frontCamClaheImage
        break
      case PanelType.EXT_CAM:
        url = fullDepthCamImage
        break
      default:
        throw new Error('Invalid panel type')
    }
    return <MouseOverImage
      src={url}
      open={() =>
        setExpandedCamViewerMode(expandablePanelTypes.indexOf(panelType))
      }
      close={() => setExpandedCamViewerMode(-1)}
    />
  }

  const getDropDown = (
    panelType: PanelType,
    setPanelType: (value: PanelType) => void
  ) => {
    return (
    <div className='z-1 w-55'>
      <Dropdown
        isMinimal={true}
        list={remainingPanelTypes}
        selected={panelType}
        select={(str: string) => setPanelType(str2PanelType(str))}
        noOptionListTopGap={true}
      />
    </div>)
  }

  return (
    // <div className='w-30 h-100 flex justify-between align-start flex-wrap overflow-y-auto'>
    <div
      className={`flex flex-col border-box ${
        mobile ? 'padding-b-lg w-100' : 'padding-r-lg w-50'
      }`}>
      <div className='h-40'>
        {getDropDown(topPanelType, setTopPanelType)}
        {topPanelType && getPanel(topPanelType)}
      </div>
      <HBlock />
      <div className='h-40'>
        {getDropDown(bottomPanelType, setBottomPanelType)}
        {bottomPanelType && getPanel(bottomPanelType)}
      </div>
    </div>
  )
}

export default LeftPanelWithDropdowns

type MouseOverImageProps = {
  src: string
  open?: () => void
  close?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
}

const MouseOverImage = ({
  src,
  open,
  close,
  onMouseOver,
  onMouseOut,
}: MouseOverImageProps) => {
  const [hover, setHover] = useState(false)

  return (
    <div
      className={` ${
        hover ? 'cursor-pointer' : ''
      } bg-black-6 rounded-sm w-100 h-fit-content max-height-100 overflow-hidden rel`}
      onClick={() => {
        if (open) open()
      }}>
      <div className='abs bottom-12px right-12px'>
        <Button
          Icon={FullscreenIcon}
          onClick={() => {
            if (open) open()
          }}
        />
      </div>
      <img
        className={`w-100 max-height-100 rounded-sm object-fit-contain`}
        src={src}
        onMouseOver={() => {
          setHover(true)
          if (onMouseOver) onMouseOver()
        }}
        onMouseOut={() => {
          setHover(false)
          if (onMouseOut) onMouseOut()
        }}
      />
    </div>
  )
}
