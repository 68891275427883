import React, { useState } from 'react'
import IconButton from './buttons/IconButton'
import BinIcon from '../../assets/icons/bin.svg'

enum StateLayerStates {
  DEFAULT,
  HOVER,
  PRESSED,
}

type StateLayerProps = {
  className?: string
  style?: React.CSSProperties
  disabled?: boolean
  color: string
  hoverColor: string
  disabledColor: string
  child: React.ReactElement
  canDelete?: boolean
  onClick: () => void
  onCloseClick?: () => void
}

const StateLayer = ({
  className,
  style,
  child,
  disabled,
  color,
  hoverColor,
  disabledColor,
  canDelete,
  onClick,
  onCloseClick,
}: StateLayerProps) => {
  const [state, setState] = useState(StateLayerStates.DEFAULT)
  const getOpacity = (state: StateLayerStates) => {
    switch (state) {
      case StateLayerStates.PRESSED:
        return '12%'
      case StateLayerStates.HOVER:
        return '8%'
      default:
        return '0%'
    }
  }
  const getColor = (state: StateLayerStates) => {
    if (disabled) return disabledColor
    switch (state) {
      case StateLayerStates.DEFAULT:
        return color
      default:
        return hoverColor
    }
  }
  return (
    <div
      className={`rel overflow-hidden
        ${disabled ? '' : 'cursor-pointer'}
        ${color}-bg ${className}`}
      style={style}
      onMouseEnter={() => {
        if (!disabled) setState(StateLayerStates.HOVER)
      }}
      onMouseLeave={() => {
        if (!disabled) setState(StateLayerStates.DEFAULT)
      }}
      onMouseDown={() => {
        if (!disabled) setState(StateLayerStates.PRESSED)
      }}
      onMouseUp={() => {
        if (!disabled) onClick()
      }}>
      {child}
      <div
        className={`abs inset-0 ${getColor(state)}`}
        style={{ opacity: getOpacity(state) }}></div>
      {canDelete && onCloseClick && state === StateLayerStates.HOVER && (
        <div className={`abs right-0 top-0`}>
          <IconButton
            Icon={BinIcon}
            contentColor='on-surface-variant'
            containerColor='surface-variant-bg'
            onClick={onCloseClick}
          />
        </div>
      )}
    </div>
  )
}

export default StateLayer
