import React from 'react'
import Button, { ButtonProps } from './Button'

const FilledButton = (props: ButtonProps) => {
  return (
    <Button {...props} contentColor='on-primary' containerColor='primary' />
  )
}

export default FilledButton
