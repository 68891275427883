import * as THREE from 'three'
import { IDxf } from 'dxf-parser'
import { LatLngCoord } from '../../../../../types'
import { getColor, getSceneFromVertex } from '../common'

export const drawArc = (
    entity: any,
    data: IDxf,
    unit: number,
    mapTileOrigin: LatLngCoord,
    pixelRes: number
) => {
    var startAngle, endAngle
    if (entity.type === 'CIRCLE') {
        startAngle = entity.startAngle || 0
        endAngle = startAngle + 2 * Math.PI
    } else {
        startAngle = entity.startAngle
        endAngle = entity.endAngle
    }

    var curve = new THREE.ArcCurve(
        0,
        0,
        entity.radius / unit,
        startAngle,
        endAngle,
        false
    )

    var points = curve.getPoints(32)
    var geometry = new THREE.BufferGeometry().setFromPoints(points)
    // if (!isValidBoundingSize(geometry)) return

    var material = new THREE.LineBasicMaterial({
        color: getColor(entity, data),
    })

    var arc = new THREE.Line(geometry, material)
    const pose = getSceneFromVertex(entity.center, unit, mapTileOrigin)
    arc.position.x = pose.x
    arc.position.y = pose.y
    arc.position.z = 0
    arc.scale.setScalar(pixelRes)
    return arc
}
