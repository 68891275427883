import { MarkerType, ModelType, timestampToMonthName } from '../../types'

/*
  Markers filter function
  - Searches for file name
  - Searches for file date
  - Searches for tags
*/

type TagType = {
    sTags: string[]
    cTags: string[]
}

type FilterType = {
    search: string
    sTags: string[] | null
    cTags: string[] | null
    sTagIDs: string[] | null
    cTagIDs: string[] | null
    flagged: boolean
}

const initFilter: FilterType = {
    search: '',
    sTags: null,
    cTags: null,
    sTagIDs: null,
    cTagIDs: null,
    flagged: false,
}

const isFilterOn = (filter: FilterType) => {
    return (
        filter.search !== '' ||
        filter.sTagIDs ||
        filter.cTagIDs ||
        filter.flagged
    )
}

const filterDates = (dates: (MarkerType | ModelType)[], filter: FilterType) => {
    return dates
        .filter((value) => {
            if (isMarkerType(value)) return filterMarker(value, filter)
            if (isModelType(value))
                return !filter.flagged && filterModel(value, filter)
            return true
        })
        .sort((a, b) => timestampSort(a, b))
}

const filterMarker = (value: MarkerType, filter: FilterType) => {
    return (
        isFlagged(value, filter) &&
        isTag(value.bag_id, filter.sTagIDs) &&
        isTag(value.bag_id, filter.cTagIDs) &&
        (value.name.toLowerCase().includes(filter.search.toLowerCase()) ||
            timestampToMonthName(value.timestamp).includes(filter.search))
    )
}

const filterModel = (value: ModelType, filter: FilterType) => {
    return (
        isTag(value.file, filter.sTagIDs) &&
        isTag(value.file, filter.cTagIDs) &&
        value.title &&
        value.title.toLowerCase().includes(filter.search.toLowerCase())
    )
}

const isFlagged = (value: MarkerType, filter: FilterType) => {
    return !filter.flagged || (filter.flagged && value.no_anode)
}

const isTag = (id: string, tags: string[] | null) => {
    return !tags || (tags.length > 0 && tags.includes(id))
}
const isMarkerType = (x: any): x is MarkerType => {
    return (x as MarkerType).bag_id !== undefined
}

const isModelType = (x: any): x is ModelType => {
    return (x as ModelType).file !== undefined
}

const timestampSort = (
    marker1: MarkerType | ModelType,
    marker2: MarkerType | ModelType
) => {
    const timestamp1 =
        'timestamp' in marker1 ? marker1.timestamp : Number.MAX_VALUE
    const timestamp2 =
        'timestamp' in marker2 ? marker2.timestamp : Number.MAX_VALUE
    return timestamp1 < timestamp2 ? 1 : -1
}

export { TagType, FilterType, initFilter, isFilterOn, filterDates }
