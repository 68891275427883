import * as THREE from 'three'
import { IDxf, IEntity } from 'dxf-parser'
import { LatLngCoord } from '../../../../types'
import { SVY21 } from '../CoordHelper/svy21'
import { latLngToScene } from '../CoordHelper/CoordUtils'

export const getColor = (entity: IEntity, data: IDxf): number => {
  var color = 0xc7c7c7 //default
  if (entity.color) color = entity.color
  else if (
    data.tables &&
    data.tables.layer &&
    data.tables.layer.layers[entity.layer]
  )
    color = data.tables.layer.layers[entity.layer].color

  if (color == null || color === 0xffffff) {
    color = 0xc7c7c7
  }
  return color
}

export function getSceneFromVertex(
  vertex: { x: number; y: number; z: number },
  unit: number,
  mapTileOrigin: LatLngCoord
) {
  const cv = new SVY21()
  const latLon = cv.computeLatLon(vertex.y / unit, vertex.x / unit) // X is east, Y is north
  const scene = latLngToScene(
    {
      latitude: latLon.lat,
      longitude: latLon.lon,
      zoom: mapTileOrigin?.zoom
    },
    mapTileOrigin
  )
  return {
    x: scene.x,
    y: scene.y,
    z: 0
  }
}

export function isValidBoundingSize(geometry: THREE.BufferGeometry) {
  geometry.computeBoundingBox()
  const bbox = new THREE.Vector3()
  geometry.boundingBox?.getSize(bbox)
  if (bbox.x < 10 && bbox.y < 10) return false
  return true
}
