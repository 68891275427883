import { useMsal } from '@azure/msal-react'
import React, { useEffect, useState, useRef } from 'react'
import { saveAs } from 'file-saver'
import {
    LatLngCoord,
    MarkerType,
    MarkerContainerPairType,
    ModelType,
    ThumbnailType,
    PDFReportImageType,
    timestampToHMS,
    SonarInfo,
    Hashtag,
    PanelType,
} from '../../../../types'
import LoadingSpinner from '../../../elements/loadingSpinner'
import PCDIcon from '../../../../assets/icons/pcd_file_icon_black.svg'
import XYZIcon from '../../../../assets/icons/xyz_file_icon_black.svg'
import GLBIcon from '../../../../assets/icons/glb_file_icon_black.svg'
import OBJIcon from '../../../../assets/icons/obj_file_icon_black.svg'
import SadFaceIcon from '../../../../assets/icons/drawing.svg'
import MapPanel from './MapPanel'
import AssistChip from '../../../elements/chips/AssistChip'
import FilledTonalButton from '../../../elements/buttons/FilledTonalButton'
import ModalOverlay from '../../../elements/ModalOverlay'
import PDFInputPanel from './PDFInputPanel'
import MarkerThumbnail from './multireportthumbnails/MarkerThumbnail'
import MosaicThumbnail from './multireportthumbnails/MosaicThumbnail'
import { isMobile } from '../../../utils/general'
import Button from '../../../elements/buttons/Button'
import IconButton from '../../../elements/buttons/IconButton'
import BackIcon from '../../../../assets/icons/arrow_back_black_24dp.svg'
import DownloadIcon from '../../../../assets/icons/file_download_black_24dp.svg'
import LeftPanelWithDropdowns from './LeftPanelWithDropdowns'
import RightPanel from './RightPanel'
import SonarImage, { SonarImageInterface } from '../../../utils/sonar'
import PCDRightPanel from './PCDRightPanel'
import { getBlob } from '../../../../backend'
import { processXYZFile } from '../../../pointcloudpage/CustomXYZLoader'
import { readMetadata } from '../../../map/utils/png-metadata'
import { DatasetIcon, MapIcon } from '../../../../assets'
import { MTHS_NAME } from '../../../../../client/types'

type ReportPanelType = {
    markers: MarkerContainerPairType[]
    hashtagMap: Map<string, Hashtag[]>
    thumbnailList: Map<string, ThumbnailType> | null
    location: LatLngCoord | null
    models: ModelType[]
    storageAccount: string
    asset: string
    toggleViewMode: number
    getModelNameFromFileName: (filename: string) => string
    dateFolder: string
    edited: boolean
    setEdited: (edited: boolean) => void
    saveEdit: (
        dateFolder: string,
        newHashtags: Hashtag[],
        newMarker?: MarkerType,
        newModel?: ModelType
    ) => void
    hashtagList?: string[]
    allHashtags: Hashtag[]
}

enum MultipleReportPanelState {
    PCD,
    MARKER,
    MARKERPCD,
}

const MultipleReportPanel = (props: ReportPanelType) => {
    const { instance, accounts } = useMsal()
    const {
        markers,
        hashtagMap,
        thumbnailList,
        location,
        models, // Asset Models
        storageAccount,
        asset,
        toggleViewMode,
        getModelNameFromFileName,
        dateFolder,
        edited,
        setEdited,
        saveEdit,
        hashtagList,
        allHashtags,
    } = props

    const isMarker = (item: MarkerType | undefined): item is MarkerType => {
        return !!item
    }
    const isModel = (item: ModelType | undefined): item is ModelType => {
        return !!item
    }

    const mobile = isMobile()
    const [thumnailRenderCount, setThumnailRenderCount] = useState<number>(0) // Keep track of number of images rendered
    const [renderMap, setRenderMap] = useState<boolean>(false) // Map has to render only after images
    const [isLoading, setIsLoading] = useState<boolean>(true) // Loading of the entire modal in general
    const [panelState, setPanelState] = useState<MultipleReportPanelState>(
        MultipleReportPanelState.MARKERPCD
    ) // Loading of the entire modal in general
    const [markerLoaded, setMarkerLoaded] = useState<boolean>(false)
    const [selectedMarkerContainerPairs, setSelectedMarkerContainerPairs] =
        useState<MarkerContainerPairType[]>(markers.filter((x) => x.marker))

    const getSelectedMarkers = () => {
        const markers = selectedMarkerContainerPairs
            .map((x) => x.marker)
            .filter(isMarker)
        return markers ? markers : []
    }
    const [selectedMarkers, setSelectedMarkers] = useState<MarkerType[]>(
        getSelectedMarkers()
    )
    useEffect(
        () => setSelectedMarkers(getSelectedMarkers()),
        [selectedMarkerContainerPairs, selectedMarkerContainerPairs.length]
    )

    const [selectedModels, setSelectedModels] = useState<ModelType[]>([])
    const [modelFileMap, setModelFileMap] = useState<Record<string, Blob>>({})
    const [noOfMosaic, setNoOfMosaic] = useState<number>(0)
    const [isTooFar, setIsTooFar] = useState<boolean>(false)
    const [showPDFInput, setShowPDFInput] = useState<boolean>(false)
    // For PDF Report
    const [markerArray, setMarkerArray] = useState<MarkerType[]>([])
    const [imagesArray, setImagesArray] = useState<PDFReportImageType[]>([])
    const [modelImagesArray, setModelImagesArray] = useState<
        PDFReportImageType[]
    >([])
    const [sortBy, setSortBy] = useState<number>(0)

    const [selectedHashtagMap, setSelectedHashtagMap] = useState<
        Map<string, Hashtag[]>
    >(new Map<string, Hashtag[]>())
    const [jumpToIdx, setJumpToIdx] = useState<number | undefined>()
    const [jumpToModelIdx, setJumpToModelIdx] = useState<number | undefined>()
    const [cursorPointer, setCursorPointer] = useState<boolean>(false)

    // Left and Right Panel items
    const [frontCamImage, setFrontCamImage] = useState<string | null>(null)
    const [frontCamClaheImage, setFrontCamClaheImage] = useState<string | null>(
        null
    )
    const [sonarImage, setSonarImage] = useState<string | null>(null)
    const [fullDepthCamImage, setFullDepthCamImage] = useState<string | null>(
        null
    )
    const [expandableCamViewerMode, setExpandedCamViewerMode] =
        useState<number>(-1)
    // -1 = not expanded, 0 = front cam, 1 = front cam clahe, 2 = full depth cam
    const expandableCamViewerImgSrcs = [
        frontCamImage,
        frontCamClaheImage,
        fullDepthCamImage,
    ]
    const expandableCamViewerImgSrc =
        expandableCamViewerImgSrcs[expandableCamViewerMode]
    const expandFrontCam = expandableCamViewerMode !== -1
    const [expandSonar, setExpandSonar] = useState(false)
    const sonarRef = useRef<SonarImageInterface>(null)

    const defaultTopPanelType = PanelType.FRONT_CAM
    const defaultBottomPanelType = PanelType.SONAR
    const [topPanelType, setTopPanelType] = useState<PanelType | null>(null)
    const [bottomPanelType, setBottomPanelType] = useState<PanelType | null>(
        null
    )

    const availablePanelTypes: PanelType[] = [
        PanelType.FRONT_CAM,
        PanelType.FRONT_CAM_CLAHE,
        PanelType.SONAR,
    ]
    // Checking only fullDepthCamImage as it is not a compulsory image
    if (fullDepthCamImage) availablePanelTypes.push(PanelType.EXT_CAM)
    const remainingPanelTypes = availablePanelTypes.filter(
        (type) => type !== topPanelType && type !== bottomPanelType
    )

    useEffect(() => {
        if (
            availablePanelTypes.includes(defaultTopPanelType) &&
            availablePanelTypes.includes(defaultBottomPanelType)
        ) {
            setTopPanelType(defaultTopPanelType)
            setBottomPanelType(defaultBottomPanelType)
        } else if (availablePanelTypes.length >= 2) {
            setTopPanelType(availablePanelTypes[0])
            setBottomPanelType(availablePanelTypes[1])
        }
    }, [availablePanelTypes.length])

    // Sonar Details
    const [sonarInfo, setSonarInfo] = useState<SonarInfo | null>(null)

    useEffect(() => {
        // setRenderMap(false)
        isTooFar ? setRenderMap(false) : setRenderMap(true)
    }, [isTooFar])

    // Initialize marker details when marker is selected
    useEffect(() => {
        if (jumpToIdx === undefined) {
            setFrontCamImage(null)
            setSonarImage(null)
            setFrontCamClaheImage(null)
            setSonarInfo(null)
            return
        }

        const selectedMarkersCleaned = selectedMarkerContainerPairs.filter(
            (markerContainer) => {
                if (markerContainer.marker) {
                    return markerContainer
                }
            }
        )
        const markerContainer = selectedMarkersCleaned[jumpToIdx]

        const marker = markerContainer.marker
        if (!marker) return
        const bag_id = marker.bag_id

        const fetchImages = async () => {
            await Promise.all([
                thumbnailList?.get(bag_id)?.frontCamImage(),
                thumbnailList?.get(bag_id)?.sonarImage(),
                thumbnailList?.get(bag_id)?.frontCamClaheImage(),
                thumbnailList?.get(bag_id)?.fullDepthCamImage(),
            ]).then((output) => {
                setFrontCamImage(output[0] ? output[0] : '')
                setSonarImage(output[1] ? output[1] : '')
                setFrontCamClaheImage(output[2] ? output[2] : '')
                setFullDepthCamImage(output[3] ? output[3] : '')
            })
            await setSonarInfo(marker.sonar_info)
        }
        fetchImages()
    }, [jumpToIdx])

    useEffect(() => {
        if (showPDFInput) handleGenerateReport()
    }, [sortBy])

    const generateImages = async (sortedMarkers: MarkerType[]) => {
        let reportImages: PDFReportImageType[] = []
        for (let i = 0; i < sortedMarkers.length; i++) {
            const marker = sortedMarkers[i]
            if (!marker) return
            const bag_id = marker.bag_id
            const emptyThumbnail = {
                frontCamImage: async () => '',
                frontCamClaheImage: async () => '',
                sonarImage: async () => '',
            }
            let thumbnails = thumbnailList?.get(bag_id)
                ? thumbnailList?.get(bag_id)
                : emptyThumbnail
            await Promise.all([
                thumbnails?.frontCamImage(),
                thumbnails?.sonarImage(),
                thumbnails?.frontCamClaheImage(),
            ]).then((output) => {
                if (typeof output[1] === 'string') {
                    const pdfReportImage = {
                        idx: i,
                        img_type: 'sonar',
                        url: output[1],
                    }
                    reportImages.push(pdfReportImage)
                }
                if (typeof output[2] === 'string') {
                    const pdfReportImage = {
                        idx: i,
                        img_type: 'front_cam',
                        url: output[2],
                    }
                    reportImages.push(pdfReportImage)
                }
            })
        }
        return reportImages
    }

    const generateMosaicImages = async (models: ModelType[]) => {
        let reportImages: PDFReportImageType[] = []
        for (let i = 0; i < models.length; i++) {
            if (models[i].id != 'mosaic') {
                continue
            }
            const emptyThumbnail = {
                frontCamImage: async () => '',
            }
            let thumbnails =
                thumbnailList?.get(models[i].file) || emptyThumbnail
            await Promise.all([thumbnails?.frontCamImage()]).then((output) => {
                if (output[0]) {
                    const pdfReportImage = {
                        idx: i,
                        img_type: 'mosaic',
                        url: output[0],
                    }
                    reportImages.push(pdfReportImage)
                }
            })
        }
        return reportImages
    }

    const handleGenerateReport = async () => {
        setShowPDFInput(true)
        const markers = selectedMarkerContainerPairs.map((markerContainer) => {
            if (markerContainer.marker) {
                return markerContainer.marker
            }
        })
        let cleanedMarkers: MarkerType[] = []
        markers.forEach((marker) => {
            if (typeof marker === 'object') {
                cleanedMarkers.push(marker)
            }
        })
        var sortedMarkers
        if (sortBy == 2) {
            sortedMarkers = cleanedMarkers.sort((a, b) => {
                const arrA = a.name.split('_')
                const arrB = b.name.split('_')
                for (let i = 0; i < arrA.length; i++) {
                    if (
                        arrA[i].match(/^[A-Z][0-9]*?$/) &&
                        arrB[i].match(/^[A-Z][0-9]*?$/)
                    ) {
                        const letterA = arrA[i].slice(0, 1)
                        const letterB = arrB[i].slice(0, 1)
                        const numberA = Number(arrA[i].slice(1))
                        const numberB = Number(arrB[i].slice(1))
                        if (letterA != letterB)
                            return letterA.localeCompare(letterB)
                        if (numberA != numberB) return numberA - numberB
                    } else {
                        return a.name.localeCompare(b.name)
                    }
                }
                return a.name.localeCompare(b.name)
            })
        } else if (sortBy == 1) {
            sortedMarkers = cleanedMarkers.sort((a, b) =>
                a.timestamp > b.timestamp
                    ? 1
                    : a.timestamp < b.timestamp
                    ? -1
                    : 0
            )
        } else {
            sortedMarkers = cleanedMarkers
        }
        setMarkerArray(sortedMarkers)
        const images = await generateImages(sortedMarkers)
        const mosaicImages = await generateMosaicImages(selectedModels)
        setModelImagesArray(mosaicImages)
        let cleanedImages: PDFReportImageType[] = []
        images?.forEach((image) => {
            if (typeof image === 'object') {
                cleanedImages.push(image)
            }
        })
        setImagesArray(cleanedImages)
        const tempHashtagMap = new Map<string, Hashtag[]>()
        selectedMarkerContainerPairs.forEach((markerContainer) => {
            if (markerContainer.marker) {
                const marker = markerContainer.marker
                const hashtagList = hashtagMap?.get(marker.bag_id)
                if (hashtagList) {
                    tempHashtagMap.set(marker.bag_id, hashtagList)
                }
            }
        })
        setSelectedHashtagMap(tempHashtagMap)
    }

    const initPanelState = () => {
        const markerCount = selectedMarkerContainerPairs.length
        const modelCount = selectedModels.length
        if (markerCount > 0 && modelCount > 0) {
            setPanelState(MultipleReportPanelState.MARKERPCD)
        } else if (markerCount > 0 && modelCount <= 0) {
            setPanelState(MultipleReportPanelState.MARKER)
        } else {
            setPanelState(MultipleReportPanelState.PCD)
        }
    }

    const getDateString = (value: string) => {
        const arr = value.split('-')
        return `${arr[2]} ${months[Number(arr[1]) - 1].slice(0, 3)} ${arr[0]}`
    }
    const months = MTHS_NAME

    const handleSnapToAUV = (event: React.MouseEvent, key: number) => {
        setJumpToIdx(key)
        setJumpToModelIdx(undefined)
    }

    const handleSnapToModel = (event: React.MouseEvent, key: number) => {
        setJumpToModelIdx(key)
        setJumpToIdx(undefined)
    }

    const generateThumbnails = () => {
        if (jumpToIdx != undefined || jumpToModelIdx != undefined) return
        const selectedMarkersCleaned = selectedMarkerContainerPairs.filter(
            (markerContainer) => {
                if (markerContainer.marker) {
                    return markerContainer
                }
            }
        )
        const thumbnails = selectedMarkersCleaned.map(
            (markerContainer, idx) => {
                const folder = markerContainer.folder
                const marker = markerContainer.marker
                if (!marker) return
                const bag_id = marker.bag_id

                const [camLazy, setCamLazy] = useState<
                    ThumbnailType | undefined
                >()

                if (thumbnailList) {
                    setCamLazy(thumbnailList.get(bag_id))
                }

                return (
                    <div
                        className={'padding-l-sm'}
                        style={{ width: '250px', minHeight: '100px' }}>
                        <div
                            className='w-100 rel display-inherit'
                            onMouseOver={() => {
                                setCursorPointer(true)
                            }}
                            onMouseOut={() => {
                                setCursorPointer(false)
                            }}
                            onClick={(event) => handleSnapToAUV(event, idx)}
                            key={idx}
                            style={{
                                cursor: cursorPointer ? 'pointer' : 'default',
                            }}>
                            <MarkerThumbnail
                                fetchSrc={camLazy}
                                noOfMarkers={
                                    selectedMarkerContainerPairs.length
                                }
                                index={idx}
                                toggleViewMode={toggleViewMode}
                                setMarkerLoaded={setMarkerLoaded}
                                className={`max-width-100 rounded-md object-fit-contain`}
                            />
                        </div>
                        <div className='padding-w-xsm padding-h-sm'>
                            <div className='h2'>{marker.name}</div>
                            <div>{getDateString(folder)}</div>
                            <div>{timestampToHMS(marker.timestamp)}</div>
                            <div
                                className={
                                    'flex w-100 flex-wrap min-width hidden-scrollbar'
                                }>
                                {hashtagMap?.get(bag_id)?.map((ht) => {
                                    return (
                                        // <div
                                        //   className={`secondary-container-bg on-secondary-container w-max-content margin-r-xsm margin-h-xsm padding-w-sm padding-h-xsm text-center rounded-sm text-sm border-1 border-solid border-transparent`}>{`#${ht}`}</div>
                                        <AssistChip
                                            className={
                                                'text-ellipsis margin-r-sm margin-b-xsm pointer-none'
                                            }
                                            onClick={() => {}}
                                            text={`#${ht.content}`}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            }
        )
        return thumbnails
    }
    const generatePcdThumbnails = () => {
        if (jumpToIdx != undefined || jumpToModelIdx != undefined) return
        const selectedModelsCleaned = markers.filter((markerContainer) => {
            if (markerContainer.model) {
                return markerContainer
            }
        })
        const pcdThumbnails = selectedModelsCleaned.map(
            (modelContainer, idx) => {
                const folder = modelContainer.folder
                const model = modelContainer.model
                //Possible ids for models: pcd, mosaic, collada, ground
                //Do we process collada and ground?
                if (!model) return
                const modelName =
                    model.title ||
                    getModelNameFromFileName(model.file) ||
                    getModelNameFromFileName(model.id)

                const [hoverState, setHoverState] = useState<boolean>(false)
                const [mosaicLazy, setMosaicLazy] = useState<
                    ThumbnailType | undefined
                >()

                if (model.id === 'mosaic' && thumbnailList) {
                    setMosaicLazy(thumbnailList.get(model.file))
                }

                setMarkerLoaded(true)

                return (
                    <div
                        className={'padding-l-sm'}
                        style={{ width: '250px', minHeight: '100px' }}>
                        <div className='w-100 rel display-inherit'>
                            {model.id === 'pcd' && (
                                <div
                                    className='max-width-100 rounded-md object-fit-contain aspect-16-9 flex justify-center align-center surface-variant-bg'
                                    onMouseOver={() => {
                                        setCursorPointer(true)
                                        setHoverState(true)
                                    }}
                                    onMouseOut={() => {
                                        setCursorPointer(false)
                                        setHoverState(false)
                                    }}
                                    onClick={(event) =>
                                        handleSnapToModel(event, idx)
                                    }
                                    key={idx}
                                    style={{
                                        cursor: cursorPointer
                                            ? 'pointer'
                                            : 'default',
                                        opacity: hoverState ? 0.4 : 1,
                                    }}>
                                    <PCDIcon height={60} width={60} />
                                </div>
                            )}
                            {model.id === 'xyz' && (
                                <div
                                    className='max-width-100 rounded-md object-fit-contain aspect-16-9 flex justify-center align-center surface-variant-bg'
                                    onMouseOver={() => {
                                        setCursorPointer(true)
                                        setHoverState(true)
                                    }}
                                    onMouseOut={() => {
                                        setCursorPointer(false)
                                        setHoverState(false)
                                    }}
                                    onClick={(event) =>
                                        handleSnapToModel(event, idx)
                                    }
                                    key={idx}
                                    style={{
                                        cursor: cursorPointer
                                            ? 'pointer'
                                            : 'default',
                                        opacity: hoverState ? 0.4 : 1,
                                    }}>
                                    <XYZIcon height={60} width={60} />
                                </div>
                            )}
                            {model.id === 'glb' && (
                                <div
                                    className='max-width-100 rounded-md object-fit-contain aspect-16-9 flex justify-center align-center surface-variant-bg'
                                    onMouseOver={() => {
                                        setCursorPointer(true)
                                        setHoverState(true)
                                    }}
                                    onMouseOut={() => {
                                        setCursorPointer(false)
                                        setHoverState(false)
                                    }}
                                    onClick={(event) =>
                                        handleSnapToModel(event, idx)
                                    }
                                    key={idx}
                                    style={{
                                        cursor: cursorPointer
                                            ? 'pointer'
                                            : 'default',
                                        opacity: hoverState ? 0.4 : 1,
                                    }}>
                                    <GLBIcon height={60} width={60} />
                                </div>
                            )}
                            {model.id === 'obj' && (
                                <div
                                    className='max-width-100 rounded-md object-fit-contain aspect-16-9 flex justify-center align-center surface-variant-bg'
                                    onMouseOver={() => {
                                        setCursorPointer(true)
                                        setHoverState(true)
                                    }}
                                    onMouseOut={() => {
                                        setCursorPointer(false)
                                        setHoverState(false)
                                    }}
                                    onClick={(event) =>
                                        handleSnapToModel(event, idx)
                                    }
                                    key={idx}
                                    style={{
                                        cursor: cursorPointer
                                            ? 'pointer'
                                            : 'default',
                                        opacity: hoverState ? 0.4 : 1,
                                    }}>
                                    <OBJIcon height={60} width={60} />
                                </div>
                            )}
                            {model.id == 'mosaic' && (
                                <div
                                    className='max-width-100 rounded-md object-fit-contain aspect-16-9 flex justify-center black-bg'
                                    onMouseOver={() => {
                                        setCursorPointer(true)
                                        setHoverState(true)
                                    }}
                                    onMouseOut={() => {
                                        setCursorPointer(false)
                                        setHoverState(false)
                                    }}
                                    onClick={(event) =>
                                        handleSnapToModel(event, idx)
                                    }
                                    key={idx}
                                    style={{
                                        cursor: cursorPointer
                                            ? 'pointer'
                                            : 'default',
                                        opacity: hoverState ? 0.4 : 1,
                                    }}>
                                    <MosaicThumbnail
                                        fetchSrc={mosaicLazy}
                                        thumbnailRenderCount={
                                            thumnailRenderCount
                                        }
                                        index={idx}
                                        setThumbnailRenderCount={
                                            setThumnailRenderCount
                                        }
                                        className={`w-50 min-height-50`}
                                    />
                                </div>
                            )}
                            {model.id == 'map' && (
                                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                                    <MapIcon height={80} width={80} />
                                </div>
                            )}
                            {model.id == 'pec' && (
                                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                                    <DatasetIcon height={80} width={80} />
                                </div>
                            )}
                            {model.id == 'dxf' && (
                                <div className='aspect-16-9 flex justify-center align-center surface-variant-bg rounded-sm'>
                                    <MapIcon height={80} width={80} />
                                </div>
                            )}
                        </div>
                        <div className='padding-w-xsm padding-h-sm'>
                            <div className='h2'>{modelName}</div>
                            <div>
                                {model.date ? getDateString(model.date) : ''}
                            </div>
                            <div
                                className={
                                    'flex w-100 flex-wrap min-width hidden-scrollbar'
                                }>
                                {hashtagMap.get(model.file)?.map((ht) => {
                                    return (
                                        <AssistChip
                                            className={
                                                'text-ellipsis margin-r-sm margin-b-xsm pointer-none'
                                            }
                                            onClick={() => {}}
                                            text={`#${ht.content}`}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            }
        )
        return pcdThumbnails
    }
    const generateSingleThumbnail = () => {
        if (jumpToIdx === undefined) return <div />
        const selectedMarkersCleaned = selectedMarkerContainerPairs.filter(
            (markerContainer) => {
                if (markerContainer.marker) {
                    return markerContainer
                }
            }
        )
        const markerContainer = selectedMarkersCleaned[jumpToIdx]

        const folder = markerContainer.folder
        const marker = markerContainer.marker
        if (!marker) return
        const bag_id = marker.bag_id
        if (expandSonar || expandFrontCam) {
            return (
                <ModalOverlay
                    isVisible={expandSonar || expandFrontCam}
                    isLoading={isLoading}
                    onCloseModal={() => {
                        setExpandedCamViewerMode(-1)
                        setExpandSonar(false)
                    }}>
                    <div className={`flex justify-center align-center`}>
                        {expandFrontCam && expandableCamViewerImgSrc && (
                            <img
                                className={`w-100 h-auto object-fit-contain`}
                                src={expandableCamViewerImgSrc}
                            />
                        )}
                        {expandSonar && sonarImage && (
                            <SonarImage
                                imgClassName={
                                    'w-100 h-100 object-fit-contain rel'
                                }
                                ref={sonarRef}
                                src={sonarImage}
                                sonarInfo={
                                    marker ? marker.sonar_info : undefined
                                }
                            />
                        )}
                    </div>
                </ModalOverlay>
            )
        }
        if (!marker || isLoading) {
            return (
                <div className='abs-center'>
                    <LoadingSpinner width={80} height={80} />
                </div>
            )
        }
        if (
            marker &&
            frontCamImage != null &&
            frontCamClaheImage != null &&
            sonarImage != null &&
            fullDepthCamImage != null &&
            topPanelType &&
            bottomPanelType
        ) {
            return (
                <div
                    className={`flex flex-wrap flex-grow-1 border-box justify-center padding-b-md  ${
                        isLoading ? 'visibility-hidden' : 'visibility-visible'
                    }`}
                    style={{ maxWidth: '1400px' }}>
                    <div className='padding-xsm'>
                        <Button
                            Icon={BackIcon}
                            onClick={() => setJumpToIdx(undefined)}
                            iconHeight={40}
                            iconWidth={40}
                        />
                    </div>
                    <LeftPanelWithDropdowns
                        topPanelType={topPanelType}
                        setTopPanelType={setTopPanelType}
                        bottomPanelType={bottomPanelType}
                        setBottomPanelType={setBottomPanelType}
                        remainingPanelTypes={remainingPanelTypes}
                        frontCamImage={frontCamImage}
                        frontCamClaheImage={frontCamClaheImage}
                        sonarImage={sonarImage}
                        fullDepthCamImage={fullDepthCamImage}
                        setExpandedCamViewerMode={setExpandedCamViewerMode}
                        setExpandSonar={setExpandSonar}
                        setRenderMap={setRenderMap}
                        sonarRef={sonarRef}
                        sonarInfo={marker ? marker.sonar_info : undefined}
                    />
                    <div
                        className='flex flex-col align-start h-100 padding-w-sm border-box'
                        style={{ width: mobile ? '100%' : '40%' }}>
                        <RightPanel
                            marker={marker}
                            hashtags={hashtagMap?.get(bag_id) || []}
                            frontCamImage={frontCamClaheImage}
                            sonarImage={sonarImage}
                            edited={edited}
                            asset={asset}
                            storage={storageAccount}
                            setEdited={(edited: boolean) => setEdited(edited)}
                            saveEdit={(
                                newMarker: MarkerType,
                                newHashtags: Hashtag[]
                            ) => saveEdit(folder, newHashtags, newMarker)}
                            generatePDF={false}
                            allHashtags={allHashtags}
                        />
                    </div>
                </div>
            )
        }

        return <></>
    }
    const generateSingleModel = () => {
        if (jumpToModelIdx == undefined) return
        const selectedModelsCleaned = markers.filter((markerContainer) => {
            if (markerContainer.model) {
                return markerContainer
            }
        })
        const selectedModel = selectedModelsCleaned[jumpToModelIdx].model
        if (selectedModel == undefined) {
            return <div></div>
        }

        const folder = selectedModelsCleaned[jumpToModelIdx].folder
        return (
            <div
                className='flex flex-wrap align-start padding-w-sm border-box'
                style={{ width: mobile ? '100%' : '100%' }}>
                <div className='padding-xsm'>
                    <Button
                        Icon={BackIcon}
                        onClick={() => setJumpToModelIdx(undefined)}
                        iconHeight={40}
                        iconWidth={40}
                    />
                </div>
                <PCDRightPanel
                    model={selectedModel}
                    hashtags={hashtagMap.get(selectedModel.file) || []}
                    edited={edited}
                    setEdited={setEdited}
                    saveEdit={(
                        newModel: ModelType,
                        newHashtagList: Hashtag[]
                    ) => {
                        saveEdit(folder, newHashtagList, undefined, newModel)
                        // setSelectedModels(markers.map((x) => x.model).filter(isModel))
                    }}
                    getModelNameFromFileName={getModelNameFromFileName}
                    allHashtags={allHashtags}
                    noPlayingOfBeexFile={true}
                />
            </div>
        )
    }

    const downloadModels = async () => {
        selectedModels.forEach((model) => {
            saveAs(new Blob([modelFileMap[model.file]]), model.file)
        })
    }

    useEffect(() => {
        const initialize = async () => {
            await initMarkers()
            await initModels()
        }
        const initMarkers = () => {
            setSelectedMarkerContainerPairs(markers.filter((x) => x.marker))
        }
        const initModels = async () => {
            const tempModels = markers.map((x) => x.model).filter(isModel)
            const currSelectedModels = [...selectedModels, ...tempModels]
            var newSelectedModels: any[] = []
            for (let model of currSelectedModels) {
                let newModel = model
                const blob = await getBlob(
                    instance,
                    accounts[0],
                    storageAccount,
                    props.asset,
                    model.file,
                    model.date || 'asset'
                )
                const jsonObject = await getBlob(
                    instance,
                    accounts[0],
                    storageAccount,
                    props.asset,
                    `${model.date}.json`,
                    model.date
                ) // possibly need to access jsonObject from different folders
                const updatedModels = jsonObject['model'].filter(
                    (jsonModel: ModelType) => jsonModel.file === model.file
                )
                let updatedModel = updatedModels.length
                    ? updatedModels[0]
                    : null
                if (model.id === 'xyz') {
                    const { utm } = await processXYZFile(
                        new File([blob], 'name')
                    )
                    if (!utm) continue
                    newModel = {
                        ...model,
                        northing: utm.northing,
                        easting: utm.easting,
                        zone_letter: utm.zone_letter,
                        zone_number: utm.zone_number,
                    }
                } else if (model.id === 'mosaic') {
                    const buffer = new Uint8Array(await blob.arrayBuffer())
                    const metadata = readMetadata(buffer).tEXt
                    if (metadata) {
                        newModel = {
                            ...model,
                            northing: Number(metadata['mosaic.northing']),
                            easting: Number(metadata['mosaic.easting']),
                            zone_letter: metadata['mosaic.zone_letter'],
                            zone_number: Number(metadata['mosaic.zone_num']),
                            height: Number(metadata['mosaic.height']),
                            heading: Number(metadata['mosaic.heading']),
                            width: Number(metadata['mosaic.width']),
                        }
                    } else if (updatedModel.northing) {
                        // assumes has other attributes
                        newModel = {
                            ...model,
                            northing: updatedModel.northing,
                            easting: updatedModel.easting,
                            zone_letter: updatedModel.zone_letter,
                            zone_number: updatedModel.zone_number,
                            height: updatedModel.height,
                            heading: updatedModel.heading,
                            width: updatedModel.width,
                        }
                    } else {
                        alert(
                            'Mosaic file is corrupted! Please contact the administrator.'
                        )
                        return
                    }
                } else if (model.id === 'pcd') {
                    newModel = {
                        ...model,
                        northing: updatedModel.northing,
                        easting: updatedModel.easting,
                        zone_letter: updatedModel.zone_letter,
                        zone_number: updatedModel.zone_number,
                        height: updatedModel.height,
                        heading: updatedModel.heading,
                        width: updatedModel.width,
                    }
                }
                newSelectedModels.push(newModel)
                modelFileMap[model.file] = blob
                setModelFileMap(modelFileMap)
            }
            setSelectedModels([...newSelectedModels])
        }
        initialize()
    }, [])
    useEffect(() => {
        initPanelState()
        // }, [selectedMarkerContainerPairs, selectedModels]) // commenting this out as this should not change
    }, [])

    // useEffect(() => {
    //   if (panelState === MultipleReportPanelState.PCD) {
    //     setRenderMap(true)
    //   }
    // }, [panelState])
    useEffect(() => {
        let mosaicNo = 0
        for (let i = 0; i < selectedModels.length; i++) {
            if (selectedModels[i].id === 'mosaic') mosaicNo += 1
        }
        setNoOfMosaic(mosaicNo)
    })
    useEffect(() => {
        if (
            // thumnailRenderCount >= noOfMosaic - 1 &&
            markerLoaded &&
            panelState === MultipleReportPanelState.MARKERPCD
        ) {
            setRenderMap(true)
        } else if (
            panelState === MultipleReportPanelState.MARKER &&
            markerLoaded
        ) {
            setRenderMap(true)
        } else if (
            panelState === MultipleReportPanelState.PCD &&
            thumnailRenderCount >= noOfMosaic
        ) {
            setRenderMap(true)
        }
    }, [thumnailRenderCount, markerLoaded])
    useEffect(() => {
        if (renderMap) setIsLoading(false)
    }, [renderMap])
    useEffect(() => {
        if (
            selectedModels.length > 0 ||
            selectedMarkerContainerPairs.length > 0
        ) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [selectedModels, selectedMarkerContainerPairs])

    const has3DModel = selectedModels.some((model) =>
        ['glb', 'obj'].includes(model.id)
    )
    const ableToView3DModel: boolean =
        !has3DModel ||
        (selectedModels.length === 1 && selectedMarkers.length === 0)

    return (
        <div className={`flex-grow-1 flex flex-col`}>
            {showPDFInput && (
                <ModalOverlay
                    isVisible={true}
                    isLoading={false}
                    onCloseModal={() => {
                        setShowPDFInput(false)
                    }}
                    className='z-alert'>
                    <PDFInputPanel
                        markers={markerArray}
                        images={imagesArray}
                        models={selectedModels}
                        modelImages={modelImagesArray}
                        hashtagMap={selectedHashtagMap}
                        asset={asset}
                        sortBy={sortBy}
                        setShowPDFInput={setShowPDFInput}
                        setSortBy={setSortBy}
                    />
                </ModalOverlay>
            )}
            <div
                className='text-center h1 padding-h-sm'
                style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                {`${
                    selectedMarkerContainerPairs.length + selectedModels.length
                } Items Selected`}
            </div>
            <div
                className={`${
                    mobile
                        ? 'flex flex-grow-1 flex-col'
                        : 'grid-2-cols h-90 overflow-hidden'
                }`}
                style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
                <div
                    className={`flex flex-grow-1 flex-wrap overflow-y-scroll ${
                        mobile ? 'flex-col max-width-50' : 'flex-row w-100'
                    }`}>
                    {generateThumbnails()}
                    {generatePcdThumbnails()}
                    {jumpToIdx != undefined ? generateSingleThumbnail() : {}}
                    {jumpToModelIdx != undefined ? generateSingleModel() : {}}
                </div>
                {(selectedMarkerContainerPairs.length > 0 ||
                    selectedModels.length > 0) &&
                    renderMap &&
                    ableToView3DModel &&
                    !isTooFar && (
                        <div
                            className={`flex-grow-1  ${
                                mobile ? '' : 'padding-l-lg'
                            }`}
                            style={{ minHeight: '400px' }}>
                            <div className='h-90 align-start'>
                                <MapPanel
                                    list={selectedMarkers}
                                    setList={setSelectedMarkers}
                                    selectedMarkersIdx={[
                                        ...Array(
                                            selectedMarkerContainerPairs.length
                                        ).keys(),
                                    ]}
                                    sonarRef={sonarRef}
                                    sonarInfo={
                                        sonarInfo ? sonarInfo : undefined
                                    }
                                    sonarImage={
                                        sonarImage ? sonarImage : undefined
                                    }
                                    initLocation={location}
                                    models={selectedModels}
                                    setSelectedModels={setSelectedModels}
                                    terrain={models}
                                    setIsTooFar={setIsTooFar}
                                    markerToFocus={jumpToIdx}
                                    modelToFocus={jumpToModelIdx}
                                    defaultShowModels={models.length !== 0}
                                    modelFileMap={modelFileMap}
                                />
                            </div>
                            <div className={'flex flex-row w-100 justify-end'}>
                                {selectedModels.length > 0 && (
                                    <div className={'margin-t-sm margin-b-md'}>
                                        <IconButton
                                            Icon={DownloadIcon}
                                            contentColor='on-surface-variant'
                                            containerColor='surface-variant-bg'
                                            onClick={downloadModels}
                                            iconHeight={30}
                                            iconWidth={30}
                                        />
                                    </div>
                                )}
                                {panelState !==
                                    MultipleReportPanelState.PCD && (
                                    <div className={'margin-t-sm margin-b-md'}>
                                        <FilledTonalButton
                                            text='Generate Report'
                                            onClick={() =>
                                                handleGenerateReport()
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                {selectedMarkerContainerPairs &&
                    (isTooFar || !ableToView3DModel) && (
                        <div
                            className={`flex-grow-1  ${
                                mobile ? '' : 'padding-l-lg'
                            }`}
                            style={{ minHeight: '400px' }}>
                            <div className='aspect-16-9 flex justify-center align-center rounded-sm'>
                                <div className='rounded-sm surface-variant-bg on-surface-variant h-100 w-100 flex justify-center align-center'>
                                    <div className='w-100 h-100 justify-center align-center flex flex-col'>
                                        <SadFaceIcon width={120} height={120} />
                                        {ableToView3DModel ? (
                                            <div className='padding-h-sm text-center'>
                                                Opps, the locations are too far
                                                apart. <br /> Please choose
                                                locations that are closer to
                                                each other.
                                            </div>
                                        ) : (
                                            <div className='padding-h-sm text-center'>
                                                The 3D model viewer can only
                                                display one model at a time.{' '}
                                                <br /> Please select a single
                                                GLB or OBJ file to view it.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {selectedModels.length > 0 && (
                                <div
                                    className={
                                        'flex justify-end margin-t-sm margin-b-md'
                                    }>
                                    <IconButton
                                        Icon={DownloadIcon}
                                        contentColor='on-surface-variant'
                                        containerColor='surface-variant-bg'
                                        onClick={downloadModels}
                                        iconHeight={30}
                                        iconWidth={30}
                                    />
                                </div>
                            )}
                        </div>
                    )}
            </div>

            {isLoading && (
                <div className='fixed-center'>
                    <LoadingSpinner width={80} height={80} />
                </div>
            )}
        </div>
    )
}

export default MultipleReportPanel
