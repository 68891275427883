import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { getBlob } from '.'

type MsalInstanceType = {
  instance: IPublicClientApplication
  account: AccountInfo | null
  storage: string
  asset: string
}

/**
 * Backend helper function for fetching images
 * msalFetchImage: fetches blob as image
 */

// Fetches image
export const msalFetchImage = async (
  msal: MsalInstanceType,
  folder: string,
  filename: string
) => {
  try {
    const blob = await getBlob(
      msal.instance,
      msal.account,
      msal.storage,
      msal.asset,
      filename,
      folder
    )
    if (blob) {
      const image = URL.createObjectURL(blob)
      return image
    }
    return null
  } catch (error) {
    console.log('Error fetching image: ', error)
    return null
  }
}
