import React, { useRef, useState } from 'react'
import FileIcon from '../../../assets/icons/description_black_24dp.svg'

type FileUploadDialogProps = {
    loadFile: (value: File) => void
    type?: string
    fileName?: string
    filesToReupload?: string[]
}

const FileUploadDialog = ({
    loadFile,
    type,
    fileName,
    filesToReupload,
}: FileUploadDialogProps) => {
    const fileType =
        fileName?.split('.')[1] === 'png' ? 'mosaic' : fileName?.split('.')[1]
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [hover, setHover] = useState(false)
    const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target) return
        if (!e.target.files || e.target.files.length === 0) {
            alert('Please choose a file.')
            return
        }
        if (e.target.files.length > 1) {
            alert('Please only choose 1 file.')
            return
        }
        loadFile(e.target.files[0])
    }

    const expectedFileExtSubstring = filesToReupload
        ? filesToReupload.join(' or ')
        : '.xyz or .png'

    return (
        <div className='padding-xlg flex'>
            <div
                className={`${
                    hover ? 'hover-surface-variant-bg' : ''
                } flex-grow-1 flex flex-col justify-center outline-border-dotted padding-xlg rounded-sm text-center cursor-pointer`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => fileInputRef.current?.click()}>
                <input
                    ref={fileInputRef}
                    type='file'
                    name='name'
                    style={{ display: 'none' }}
                    onChange={handleSelectFile}
                />
                {type === 'video' && (
                    <div>
                        <div className='h2'>
                            Click to select a .beex file from your desktop
                        </div>
                        <div className='h3' style={{ margin: '8px 0px' }}>
                            or
                        </div>
                        <div className='h2'>
                            Drag the file over here to start...
                        </div>
                        <div className='h4' style={{ marginTop: '30px' }}>
                            Note: This does <b>not</b> upload the data to the
                            cloud.
                        </div>
                    </div>
                )}
                {(type === 'model' || type === 'model_uploaded') && (
                    <div>
                        {type === 'model_uploaded' && fileName && (
                            <div className='flex flex-row align-center justify-center padding-h-sm padding-w-md margin-b-md'>
                                <FileIcon width={24} height={24} />
                                <div className='margin-l-sm h3'>
                                    {fileName}
                                    <br />
                                    uploaded successfully.
                                </div>
                            </div>
                        )}

                        {type === 'model' && (
                            <div className='h2'>
                                Click to upload a <br />
                                {expectedFileExtSubstring} <br />
                                file from your desktop
                            </div>
                        )}
                    </div>
                )}
                {(type === 'json_file' || type === 'json_file_uploaded') && (
                    <div>
                        {type === 'json_file_uploaded' && fileName && (
                            <div className='flex flex-row align-center justify-center padding-h-sm padding-w-md margin-b-md'>
                                <FileIcon width={24} height={24} />
                                <div className='margin-l-sm h3'>
                                    {fileName} has been processed.
                                </div>
                            </div>
                        )}
                        <div className='h2'>
                            Upload the json file to auto-fill in the available
                            fields.
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default FileUploadDialog
