import React, { useState } from 'react'
import IconButton from './buttons/IconButton'
import CheckedCircle from '../../assets/icons/check_circle_black_24dp.svg'
import EmptyCircle from '../../assets/icons/radio_button_unchecked_black_24dp.svg'
import PriorityIcon from '../../assets/icons/priority_high_black_24dp.svg'
import Button from './buttons/Button'

type MultiSelectThumbnailProps = {
  id: string
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  isSelected: boolean
  isFlagged: boolean
  onClick: (id: string) => void
  style?: React.CSSProperties
  className?: string
  childrenClassName?: string
  children?: React.ReactChild | React.ReactChild[]
}

const MultiSelectThumbnail = ({
  id,
  Icon,
  isSelected,
  isFlagged,
  onClick,
  className,
  childrenClassName,
  style,
  children,
}: MultiSelectThumbnailProps) => {
  const [isHover, setIsHover] = useState<boolean>(false)
  const mouseOver = () => {
    if (isHover) return
    setIsHover(true)
  }
  const mouseOut = () => {
    if (!isHover) return
    setIsHover(false)
  }

  return (
    <div
      id={id}
      className={`cursor-pointer justify-center align-center border-0 rel margin-w-auto padding-0 rounded-md w-100 overflow-hidden bg-transparent ${
        isSelected ? '' : ''
      } ${className}`}
      onMouseEnter={() => mouseOver()}
      style={style}>
      <div
        className={`abs z-2 right-12px top-12px`}
        style={{ visibility: isFlagged ? 'visible' : 'hidden' }}>
        <div
          className={`padding-l-sm padding-r-sm flex-inline h-36px align-center rounded-lg rel overflow-hidden on-background on-no-anode
          `}>
          <PriorityIcon height={20} width={20} />
        </div>
      </div>

      <div
        className={`${childrenClassName} ${
          isHover || isSelected ? 'dark-gradient-filter' : ''
        }`}
        onMouseLeave={() => mouseOut()}>
        {children}
        <div
          className={`abs z-2 left-12px top-12px`}
          style={{ visibility: isHover || isSelected ? 'visible' : 'hidden' }}>
          <Button
            Icon={isSelected ? CheckedCircle : EmptyCircle}
            contentColor={'white'}
            iconHeight={36}
            iconWidth={36}
            onClick={() => onClick(id)}
          />
        </div>
      </div>
    </div>
  )
}

export default MultiSelectThumbnail
