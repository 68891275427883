import React from 'react'

const MaintenancePage = () => {
  return (
    <div className='w-100 h-100 align-center justify-center flex-wrap flex flex-col text-white'>
      <div className='h1'>The portal is under maintenance</div>
      <div className='h3 text-white' style={{ marginTop: '24px' }}>
        We will be back in a few hours. Sorry for any inconvenience caused.
      </div>
    </div>
  )
}

export default MaintenancePage
