import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import Button from '../elements/buttons/Button'
import LoadingSpinner from '../elements/loadingSpinner'
import PlayIcon from '../../assets/icons/play_arrow_black_24dp.svg'
import PauseIcon from '../../assets/icons/pause_black_24dp.svg'
import VerticalExpandTextButton from '../elements/buttons/VerticalExpandTextButton'
import { Time } from '../utils/rosbag'

const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0')
const padTime = (duration: number) => {
  return `${zeroPad(Math.floor(duration / 60), 2)}:${zeroPad(
    Math.floor(duration % 60),
    2
  )}`
}

type PlayerBarType = {
  duration: number
  timelineRevealed: boolean
  play: () => void
  pause: () => void
  seek: (value: number) => void
  changeSpeed: (value: number) => void
  beexStartTime: number | undefined
  beexEndTime: number | undefined
  bagStartTime: Time | undefined
}

export interface PlayerBarInterface {
  setSeekTime: (duration: number) => void
  setBufferTime: (duration: number) => void
  setIsLoading: (value: boolean) => void
  setIsPlaying: (value: boolean) => void
  calcSeekerWidth: (time: number) => number
}

const PlayerBar = (
  {
    duration,
    timelineRevealed,
    play,
    pause,
    seek,
    changeSpeed,
    beexStartTime,
    beexEndTime,
    bagStartTime
  }: PlayerBarType,
  ref: React.Ref<PlayerBarInterface>
) => {
  const startTime = bagStartTime ?  bagStartTime.sec + bagStartTime.nsec / 1e9 : undefined
  const hasStartTimeMarker = beexStartTime !== undefined && startTime !== undefined
  const hasEndTimeMarker = beexEndTime !== undefined && startTime !== undefined
  const startTimeMarkerTooltip = useRef<HTMLDivElement>(null)
  const endTimeMarkerTooltip = useRef<HTMLDivElement>(null)
  const markerLeftPositionOffSet = -2 // In px
  const markerTooltipLeftPositionOffSet = 82  // In px

  const [isLoading, setIsLoading] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [seekTime, setSeekTime] = useState(0)
  const [bufferTime, setBufferTime] = useState(0)
  const [bufferWidth, setBufferWidth] = useState(0)
  const [isMouseDown, _setIsMouseDown] = useState(false)
  const [speed, setSpeed] = useState(0)
  const slider = useRef<HTMLDivElement>(null)
  const tooltip = useRef<HTMLDivElement>(null)
  const durationRef = useRef(duration)
  const isMouseDownRef = useRef(isMouseDown)
  const setIsMouseDown = (value: boolean) => {
    isMouseDownRef.current = value
    _setIsMouseDown(value)
  }
  useImperativeHandle(ref, () => ({
    setSeekTime,
    setBufferTime,
    setIsLoading,
    setIsPlaying,
    calcSeekerWidth,
    calcSeekTime,
  }))
  const handlePlay = () => {
    setIsPlaying((isPlaying) => {
      if (!isPlaying) play()
      else pause()
      return !isPlaying
    })
  }
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const seekTime = calcSeekTime(e.clientX)
    if (isNaN(seekTime)) return
    tooltip.current!.innerHTML = padTime(seekTime)
    const rect = slider.current!.getBoundingClientRect()
    tooltip.current!.style.left = `${e.clientX - rect.left + 80}px`
    tooltip.current!.style.visibility = 'visible'
  }
  const handleMouseOut = (e: React.MouseEvent<HTMLDivElement>) => {
    tooltip.current!.style.visibility = 'hidden'
  }
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsMouseDown(true)
    handleSeek(calcSeekTime(e.clientX)!)
  }
  const handleWindowMouseMove = (e: MouseEvent) => {
    if (!isMouseDownRef.current) return
    handleSeek(calcSeekTime(e.clientX)!)
  }
  const handleWindowMouseUp = (e: MouseEvent) => {
    setIsMouseDown(false)
  }
  const calcSeekTime = (clientX: number) => {
    const rect = slider.current!.getBoundingClientRect()
    // const sliderWidth = rect.width
    // let width = seekTime / duration * sliderWidth + 7
    // console.log(width)
    // return width
    let x = (clientX - rect.left) / rect.width
    if (x < 0) x = 0
    else if (x > 1) x = 1
    return x * durationRef.current
  }
  const handleSeek = (value: number) => {
    if (isNaN(value)) return
    // setSeekTime(value)
    seek(value)
  }
  const calcBufferWidth = () => {
    if (duration === 0 || !slider.current) return 0
    const rect = slider.current!.getBoundingClientRect()
    const sliderWidth = rect.width
    let width = (bufferTime / duration) * sliderWidth
    return width
  }
  const calcSeekerWidth = (time: number) => {
    if (duration === 0 || !slider.current) return 7
    const rect = slider.current!.getBoundingClientRect()
    const sliderWidth = rect.width
    let width = (time / duration) * sliderWidth + 7
    return Math.min(width, bufferWidth)
  }
  const handleKeypress = (e: KeyboardEvent) => {
    if (e.code === 'Space') {
      handlePlay()
    }
  }
  useEffect(() => {
    window.addEventListener('mousemove', handleWindowMouseMove)
    window.addEventListener('mouseup', handleWindowMouseUp)
    timelineRevealed
      ? null
      : window.addEventListener('keypress', handleKeypress)
    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove)
      window.removeEventListener('mouseup', handleWindowMouseUp)
      timelineRevealed
        ? null
        : window.removeEventListener('keypress', handleKeypress)
    }
  }, [timelineRevealed])
  useEffect(() => {
    durationRef.current = duration
  }, [duration])
  useEffect(() => {
    setBufferWidth(calcBufferWidth())
  }, [bufferTime, duration, slider.current !== null])

  const startMarkerCalculatedPosition = hasStartTimeMarker ?
    (beexStartTime! > startTime! ?  calcSeekerWidth(beexStartTime! - startTime!) : 0) :
    undefined
  const endMarkerCalculatedPosition = hasEndTimeMarker ?
    Math.min(calcSeekerWidth(beexEndTime - startTime), bufferWidth) :
    undefined

  const showingMarkerTooltip = (
    markerTooltip: React.RefObject<HTMLDivElement>,
    calculatedPosition: number
  ) => {
    markerTooltip.current!.style.visibility = 'visible'
    markerTooltip.current!.style.left = `${
      calculatedPosition + markerLeftPositionOffSet +
      markerTooltipLeftPositionOffSet - markerTooltip.current!.clientWidth/2
    }px`
  }
  const hidingMarkerTooltip = (markerTooltip: React.RefObject<HTMLDivElement>) =>
    markerTooltip.current!.style.visibility = 'hidden'

  return (
    <>
      <div className='player-bar'>
        <div
          style={{ width: '80px', display: 'flex', justifyContent: 'center' }}>
          {isLoading && (
            <div style={{ padding: '0px 12px' }}>
              <LoadingSpinner width={40} height={40} />
            </div>
          )}
          {!isLoading && (
            <PlayPauseButton play={isPlaying} onClick={() => handlePlay()} />
          )}
        </div>
        <div
          ref={slider}
          className='slider'
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}>
          <div className='buffer' style={{ width: `${bufferWidth}px` }} />
          <div
            className='seeker-bar'
            style={{ width: `${calcSeekerWidth(seekTime)}px` }}
          />
          <div className='seeker-thumb' />
          {hasStartTimeMarker && <div
            className='marker'
            onMouseEnter={() => showingMarkerTooltip(
              startTimeMarkerTooltip,
              startMarkerCalculatedPosition!
            )}
            onMouseLeave={() => hidingMarkerTooltip(startTimeMarkerTooltip)}
            style={{ left: `${
              startMarkerCalculatedPosition! + markerLeftPositionOffSet
            }px` }}
            />}
          {hasEndTimeMarker && <div
            className='marker'
            onMouseEnter={() => showingMarkerTooltip(
              endTimeMarkerTooltip,
              endMarkerCalculatedPosition!
            )}
            onMouseLeave={() => hidingMarkerTooltip(endTimeMarkerTooltip)}
            style={{ left: `${
              endMarkerCalculatedPosition! + markerLeftPositionOffSet
            }px` }}
          />}
        </div>
        <VerticalExpandTextButton
          value={speed}
          options={['1x', '2x', '4x']}
          onClick={(value) => {
            setSpeed(value)
            changeSpeed(value)
          }}
        />
        <div style={{ width: '120px' }}>
          {padTime(seekTime)}/{padTime(duration)}
        </div>
        <div ref={tooltip} className='tooltip' />
        {hasStartTimeMarker && <div
          ref={startTimeMarkerTooltip}
          className='marker-tooltip'
          style={{ left: `${
            startMarkerCalculatedPosition! + markerTooltipLeftPositionOffSet
          }px` }}
        >start</div>}
        {hasEndTimeMarker && <div
          ref={endTimeMarkerTooltip}
          className='marker-tooltip'
          style={{ left: `${
            endMarkerCalculatedPosition! + markerTooltipLeftPositionOffSet
          }px` }}
        >end</div>}
      </div>
    </>
  )
}

export default React.forwardRef(PlayerBar)

type PlayPauseButtonProps = {
  play: boolean
  onClick: () => void
}

const PlayPauseButton = ({ play, onClick }: PlayPauseButtonProps) => {
  return (
    <>
      {play && <Button Icon={PauseIcon} onClick={() => onClick()} />}
      {!play && <Button Icon={PlayIcon} onClick={() => onClick()} />}
    </>
  )
}
