import * as THREE from 'three'
import { IDxf } from 'dxf-parser'
import { LatLngCoord } from '../../../../../types'
import { drawEntity } from './Entity'

export const drawBlock = (
  entity: any,
  data: IDxf,
  unit: number,
  font: any,
  mapTileOrigin: LatLngCoord,
  pixelRes: number
) => {
  var block = data.blocks[entity.name]

  if (!block.entities) return null

  var group = new THREE.Object3D()

  if (entity.xScale) group.scale.x = entity.xScale
  if (entity.yScale) group.scale.y = entity.yScale

  if (entity.rotation) {
    group.rotation.z = (entity.rotation * Math.PI) / 180
  }

  if (entity.position) {
    group.position.x = entity.position.x / unit
    group.position.y = entity.position.y / unit
    group.position.z = entity.position.z / unit
  }

  for (var i = 0; i < block.entities.length; i++) {
    var childEntity = drawEntity(
      block.entities[i],
      data,
      unit,
      font,
      mapTileOrigin,
      pixelRes
    )
    if (childEntity) group.add(childEntity)
  }

  return group
}
