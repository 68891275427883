import * as THREE from 'three'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js'
import {
    CSS2DObject,
    CSS2DRenderer,
} from 'three/examples/jsm/renderers/CSS2DRenderer'
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader'
import { PCDLoader } from './PCDLoader'
import IKAN from '../../../assets/models/ikan/base_link.stl'
import {
    Dimension,
    LatLngCoord,
    ModelType,
    SonarInfo,
    AngleRange,
    Quality,
} from '../../../types'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'
import {
    degToRad,
    latLngToScene,
    utmToScene,
    getPixelRes,
    sceneToLatLng,
} from './CoordHelper/CoordUtils'
import { Lut } from 'three/examples/jsm/math/Lut'
import { BufferGeometry, Mesh } from 'three'
import { XYZLoader } from '../../pointcloudpage/CustomXYZLoader'

const TWOD_OBJECTS_DEPTH = 0.2

export function getPositioningOfLine(
    pointA: THREE.Vector3,
    pointB: THREE.Vector3,
    scale: number
): THREE.Vector3 {
    var dir = pointB.clone().sub(pointA)
    var len = dir.length()
    dir = dir.normalize().multiplyScalar(len * scale)
    return pointA.clone().add(dir)
}

export function getSelectMesh(): THREE.Mesh {
    const torusGeometry = new THREE.TorusGeometry(0.4, 0.2, 8, 100)

    const material = new THREE.MeshLambertMaterial({
        color: 0x49ef4,
        transparent: false,
        emissive: 0x2a2a2a,
        emissiveIntensity: 0.5,
        side: THREE.DoubleSide,
    })
    const mesh = new THREE.Mesh(torusGeometry, material)
    return mesh
}

// CAMERAS =====================================================================
export function getPerspectiveCamera(
    width: number,
    height: number
): THREE.PerspectiveCamera {
    const camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000)
    camera.up.set(0, 0, 1)
    camera.position.set(0, 0, 2)
    camera.lookAt(0, 0, 0)
    camera.updateProjectionMatrix()
    return camera
}

export function getOrthographicCamera(
    width: number,
    height: number
): THREE.OrthographicCamera {
    const camera = new THREE.OrthographicCamera(
        width / -2,
        width / 2,
        height / 2,
        height / -2,
        0.1,
        1000
    )
    camera.zoom = 5
    camera.up.set(0, 0, 1)
    camera.position.set(0, 0, 2)
    camera.lookAt(0, 0, 0)
    camera.updateProjectionMatrix()
    return camera
}

export function getHUDCamera(
    width: number,
    height: number
): THREE.OrthographicCamera {
    const camera = new THREE.OrthographicCamera(
        width / -2,
        width / 2,
        height / 2,
        height / -2,
        1,
        1000
    )
    // camera.zoom = 1
    // camera.up.set(0, 0, 1)
    camera.position.set(0, 0, 5)
    camera.lookAt(0, 0, 0)
    camera.updateProjectionMatrix()
    return camera
}

// SCENE =======================================================================
export function getScene(): THREE.Scene {
    const scene = new THREE.Scene()
    scene.background = new THREE.Color(0x2e2e2e)
    // scene.fog = new THREE.Fog(scene.background, 1, 1500)
    scene.add(new THREE.AmbientLight(0xffffff))
    return scene
}

export function getHUDScene(): THREE.Scene {
    const scene = new THREE.Scene()
    return scene
}

export function getLabelRenderer(width: number, height: number): CSS2DRenderer {
    const renderer = new CSS2DRenderer()
    renderer.setSize(width, height)
    renderer.domElement.style.position = 'absolute'
    renderer.domElement.style.top = '0px'
    renderer.domElement.style.border = 'none'
    renderer.domElement.style.outline = 'none'
    renderer.domElement.style.zIndex = '100'
    return renderer
}

export function getOrbitControls(
    camera: THREE.Camera,
    domElement: HTMLElement,
    dimension: Dimension,
    reStrictingAngles = true
): MapControls {
    const controls = new MapControls(camera, domElement)
    controls.enableDamping = false
    controls.dampingFactor = 0.08
    controls.screenSpacePanning = true
    controls.minDistance = 1
    controls.maxDistance = 800
    controls.minZoom = 0.1
    controls.maxZoom = 20
    if (dimension === Dimension.THREED) {
        if (reStrictingAngles) {
            controls.minPolarAngle = degToRad(0)
            controls.maxPolarAngle = degToRad(90)
        }
    } else {
        controls.minPolarAngle = 0
        controls.maxPolarAngle = 0
    }
    return controls
}

export function getOutlinePass(
    windowWidth: number,
    windowHeight: number,
    scene: THREE.Scene,
    camera: THREE.Camera
) {
    const outlinePass = new OutlinePass(
        new THREE.Vector2(windowWidth, windowHeight),
        scene,
        camera
    )
    outlinePass.edgeStrength = 8
    outlinePass.edgeThickness = 2
    outlinePass.edgeGlow = 0.4
    outlinePass.pulsePeriod = 5
    outlinePass.visibleEdgeColor.set(0xffffff)
    return outlinePass
}

// MESH ELEMENTS ===============================================================
export function getGrid(): THREE.GridHelper {
    const grid = new THREE.GridHelper(10, 10)
    grid.rotateX(degToRad(90))
    return grid
}

export function getIkanPath(): THREE.Line {
    const material = new THREE.MeshBasicMaterial({ color: 0x03fcf4 })
    return getLine(material)
}

export function getFullIkanPath(): THREE.Line {
    // const material = new THREE.MeshBasicMaterial({ color: 0x1379ec })
    const material = new THREE.MeshBasicMaterial({ color: 0x808080 })
    return getLine(material)
}

export function getRulerLine(dotted?: boolean): THREE.Line {
    const material = dotted
        ? new THREE.LineDashedMaterial({
              color: 0x54e04f,
              dashSize: 1,
              gapSize: 0.25,
          })
        : new THREE.MeshBasicMaterial({ color: 0x54e04f })

    return getLine(material)
}

export function getRulerLabel(): CSS2DObject {
    const div = document.createElement('div')
    div.className = 'rounded-sm padding-sm ruler-label text-medium'
    div.innerHTML = '0m'
    return new CSS2DObject(div)
}

export function getIkanLabel(text: string): CSS2DObject {
    const div = document.createElement('div')
    div.className = 'rounded-sm padding-xsm text-medium text-xsm'
    div.style.backgroundColor = 'rgba(1, 37, 42, .4)'
    div.innerHTML = text
    return new CSS2DObject(div)
}

export function get3DRulerLabelContent(
    distance: number,
    z: number
): HTMLDivElement {
    const labelDiv = document.createElement('div')
    labelDiv.style.display = 'flex'
    labelDiv.style.flexDirection = 'column'
    // div.style.marginBottom = '4px'
    const headers = ['Dist: ', 'Depth: ', 'Angle: ']
    const values = [labelRound(distance), labelRound(z)]
    // const angle = labelRound(radToDegree(Math.atan(z / distance)))
    // const colors = ['red', 'green', 'blue']
    for (let i = 0; i < 3; i++) {
        const parentDiv = document.createElement('div')
        const headerDiv = document.createElement('div')
        const valueDiv = document.createElement('div')
        parentDiv.style.display = 'flex'
        parentDiv.style.justifyContent = 'space-between'
        parentDiv.style.margin = '2px'
        headerDiv.innerHTML = headers[i]
        headerDiv.style.padding = '2px'
        headerDiv.style.marginRight = '6px'
        headerDiv.style.fontWeight = 'bold'

        parentDiv.appendChild(headerDiv)

        // append valueDiv/angleDiv
        if (i == 2) {
            const { angleDiv } = getAngleContent(distance, z)
            angleDiv && parentDiv.appendChild(angleDiv)
        } else {
            valueDiv.innerHTML = `${values[i]}m`
            valueDiv.style.padding = '2px'
            parentDiv.appendChild(valueDiv)
        }
        labelDiv.appendChild(parentDiv)
    }

    return labelDiv
}

export function get3DMultiRulerLabelContent(
    totalDistance: number
): HTMLDivElement {
    const labelDiv = document.createElement('div')
    labelDiv.style.display = 'flex'
    labelDiv.style.flexDirection = 'column'
    const headers = ['Distance travelled:']
    const values = [labelRound(totalDistance)]

    for (let i = 0; i < 1; i++) {
        const parentDiv = document.createElement('div')
        const headerDiv = document.createElement('div')
        const valueDiv = document.createElement('div')
        parentDiv.style.display = 'flex'
        parentDiv.style.justifyContent = 'space-between'
        parentDiv.style.margin = '2px'
        headerDiv.innerHTML = headers[i]
        headerDiv.style.padding = '2px'
        headerDiv.style.marginRight = '6px'
        headerDiv.style.fontWeight = 'bold'

        parentDiv.appendChild(headerDiv)
        valueDiv.innerHTML = `${values[i]}m`
        valueDiv.style.padding = '2px'
        parentDiv.appendChild(valueDiv)
        labelDiv.appendChild(parentDiv)
    }

    return labelDiv
}

export function getAngleContent(
    distance: number,
    z: number,
    angleRange?: AngleRange
): { angleDiv: HTMLDivElement | null; angleValue: number | null } {
    const angleDiv = document.createElement('div')
    const angleValue = labelRound(radToDegree(Math.atan(z / distance)))
    if (
        angleRange &&
        (angleValue > angleRange.max || angleValue < angleRange.min)
    ) {
        return { angleDiv: null, angleValue: null }
    }
    const valueDiv = document.createElement('div')
    valueDiv.innerHTML = `${angleValue.toString()}°`
    valueDiv.style.padding = '2px'
    angleDiv.appendChild(valueDiv)

    return { angleDiv, angleValue }
}

export function getPlanarLineBetweenTwoPoints(
    pt1: THREE.Vector3,
    pt2: THREE.Vector3,
    color?: string
) {
    const angleLine = new THREE.Line(
        new THREE.BufferGeometry().setFromPoints([
            new THREE.Vector3(pt2.x, pt2.y, pt2.z),
            new THREE.Vector3(pt1.x, pt1.y, pt2.z),
        ]),
        new THREE.LineDashedMaterial({
            color: color || 0x8b8000,
            gapSize: 1,
            dashSize: 3,
        })
    )

    return angleLine
}

export function getQuadrantBetweenTwoPoints(
    pt1: THREE.Vector3,
    pt2: THREE.Vector3,
    angleValue: number,
    color?: string
) {
    const isOnTop = pt2.z - pt1.z >= 0 ? false : true // relative to currPt
    const isOnRight = pt2.x - pt1.x >= 0 ? false : true // relative to currPt

    const angleValueInRadians = angleValue ? angleValue * (Math.PI / 180) : 0
    const thetaStart = isOnTop
        ? isOnRight
            ? 0
            : -1.5 * Math.PI + (Math.PI / 2 - angleValueInRadians)
        : isOnRight
        ? -angleValueInRadians
        : -Math.PI

    const newPt1 = new THREE.Vector3(pt1.x, pt1.y)
    const newPt2 = new THREE.Vector3(pt2.x, pt2.y)
    const circleGeometry = new THREE.CircleGeometry(
        newPt1.distanceTo(newPt2) * 0.3,
        32,
        thetaStart,
        angleValueInRadians
    )
    const circleMaterial = new THREE.MeshBasicMaterial({
        color: color || 0x8b8000,
        side: THREE.DoubleSide,
        opacity: 0.2,
    })
    const circle = new THREE.Mesh(circleGeometry, circleMaterial)
    circle.position.set(pt2.x, pt2.y, pt2.z)
    circle.rotation.x = Math.PI / 2

    const deltaY = (pt1.y - pt2.y) / getPixelRes()
    const deltaX = (pt1.x - pt2.x) / getPixelRes()
    const angleInRadians = Math.atan(deltaY / deltaX)
    circle.rotation.y = angleInRadians

    return circle
}

export function labelRound(value: number) {
    return Math.round(value * 10) / 10
}

export function radToDegree(radians: number) {
    var pi = Math.PI
    return radians * (180 / pi)
}

export function getDotMarker(
    radius: number,
    color: number,
    opacity?: number
): THREE.Mesh<THREE.SphereGeometry, THREE.MeshBasicMaterial> {
    const geometry = new THREE.SphereGeometry(radius)
    const material = new THREE.MeshBasicMaterial({
        color: color,
        opacity: opacity ? opacity : 1,
    })
    material.transparent = true
    const sphere = new THREE.Mesh(geometry, material)
    return sphere
}

export function getIkanMesh(): Promise<THREE.Mesh> {
    const material = new THREE.MeshPhongMaterial({
        color: 0x03dbfc,
        transparent: true,
        opacity: 0.8,
    })
    return new Promise((resolve) => {
        const loader = new STLLoader()
        loader.load(IKAN, (geometry) => {
            const mesh = new THREE.Mesh(geometry, material)
            mesh.scale.setScalar(0.035)
            mesh.rotateZ(3.1415926)
            resolve(mesh)
        })
    })
}

export function getTriangleMesh(): THREE.Mesh {
    const shape = new THREE.Shape()
        .moveTo(0, -0.25)
        .lineTo(0.5, -0.5)
        .lineTo(0, 0.5)
        .lineTo(-0.5, -0.5)
        .lineTo(0, -0.25)
    const extrudeSettings = {
        depth: 0.4,
        steps: 1,
        bevelEnabled: true,
        bevelThickness: 0.2,
        bevelSize: 0.2,
        bevelSegments: 1,
    }
    const geometry = new THREE.ExtrudeBufferGeometry(shape, extrudeSettings)
    const material = new THREE.MeshPhongMaterial({
        color: 0x49ef4,
        shininess: 36,
    })
    const mesh = new THREE.Mesh(geometry, material)
    return mesh
}

export function getCircle(
    radius: number,
    color: number,
    holeRadius?: number,
    depth?: number,
    opacity?: number
): THREE.Mesh {
    // const innerRadius = radius - hole
    const extrudeSettings = { depth: depth ? depth : 0.5, bevelEnabled: false }
    const circleShape = new THREE.Shape()
        .moveTo(0, radius)
        .quadraticCurveTo(radius, radius, radius, 0)
        .quadraticCurveTo(radius, -radius, 0, -radius)
        .quadraticCurveTo(-radius, -radius, -radius, 0)
        .quadraticCurveTo(-radius, radius, 0, radius)
    if (holeRadius) {
        const innerCircleShape = new THREE.Shape()
            .moveTo(0, holeRadius)
            .quadraticCurveTo(holeRadius, holeRadius, holeRadius, 0)
            .quadraticCurveTo(holeRadius, -holeRadius, 0, -holeRadius)
            .quadraticCurveTo(-holeRadius, -holeRadius, -holeRadius, 0)
            .quadraticCurveTo(-holeRadius, holeRadius, 0, holeRadius)
        circleShape.holes.push(innerCircleShape)
    }
    const geometry = new THREE.ExtrudeGeometry(circleShape, extrudeSettings)
    // const material = new THREE.MeshPhongMaterial({
    //   color: color,
    //   opacity: opacity ? opacity : 1,
    // })
    const material = new THREE.MeshBasicMaterial({
        color: color,
        opacity: opacity ? opacity : 1,
    })
    material.transparent = true

    const mesh = new THREE.Mesh(geometry, material)
    return mesh
}

export function getSphereMesh(radius = 0.4): THREE.Mesh {
    const geometry = new THREE.SphereGeometry(radius, 20, 20)
    const material = new THREE.MeshPhongMaterial({
        color: 0x49ef4,
        shininess: 36,
    })
    const mesh = new THREE.Mesh(geometry, material)
    return mesh
}

// export function getMissionMarker(marker: MissionTaskType, mapOrigin: LatLngCoord, dimension: Dimension): THREE.Mesh {
//   const sceneCoord = latLngToScene({
//     latitude: Number(marker.params[0]),
//     longitude: Number(marker.params[1]),
//     zoom: 0
//   }, mapOrigin)
//   let mesh
//   if (marker.type === TaskTypes.PILLAR_INSPECTION) {
//     mesh = getTriangleMesh()
//     mesh.applyMatrix4(new THREE.Matrix4().makeRotationZ(
//       degToRad(headingToRotation(Number(marker.params[2])))
//     ))
//   } else {
//     mesh = getSphereMesh()
//   }
//   const z = (dimension === Dimension.TWOD) ? 1 : -marker.params[3]
//   mesh.applyMatrix4(new THREE.Matrix4().makeTranslation(
//     sceneCoord.x, sceneCoord.y, z
//   ))
//   mesh.geometry.computeBoundingBox()
//   return mesh
// }

export function getPathLine(points: THREE.Vector3[]): THREE.Line {
    const material = new THREE.LineBasicMaterial({
        color: 0x49ef4,
    })
    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    return new THREE.Line(geometry, material)
}

export function getSonar(
    canvas: HTMLCanvasElement,
    texture: THREE.Texture,
    sonarInfo: SonarInfo
): THREE.Object3D {
    const sonar = new THREE.Object3D()
    canvas.width = sonarInfo.width
    canvas.height = sonarInfo.height
    const sonarGeometry = new THREE.PlaneBufferGeometry()
    const sonarMat = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.8,
    })
    const sonarPlane = new THREE.Mesh(sonarGeometry, sonarMat)
    sonar.add(sonarPlane)
    return sonar
}

export function getBox3Helper(points: THREE.Vector3[]): THREE.Box3Helper {
    const box = new THREE.Box3().setFromPoints(points)
    const boxCenter = new THREE.Vector3()
    const boxSize = new THREE.Vector3()
    box.getCenter(boxCenter)
    box.getSize(boxSize)
    const newBox = new THREE.Box3().setFromCenterAndSize(
        boxCenter,
        boxSize.addScalar(1.0)
    )
    return new THREE.Box3Helper(newBox, new THREE.Color(0x55aaff))
}

export async function getCollada(
    blob: any,
    model: ModelType,
    mapOrigin: LatLngCoord
) {
    if (!model.latitude || !model.longitude) return
    const { x, y } = latLngToScene(
        { latitude: model.latitude, longitude: model.longitude, zoom: 20 },
        mapOrigin
    )
    const loader = new ColladaLoader()
    const { scene } = loader.parse(blob, '')
    if (model.rotateX) scene.rotateX(degToRad(model.rotateX))
    else scene.rotateX(Math.PI / 2)
    if (model.rotateY) scene.rotateY(degToRad(model.rotateY))
    scene.rotateZ(degToRad(model.heading))
    const scale = model.scale || 1
    scene.scale.setScalar(getPixelRes() * scale)
    scene.position.set(x, y, -model.depth)
    scene.traverse(function (child) {
        if (child instanceof Mesh) {
            child.material.opacity = 0.3
            child.material.transparent = true
        }
    })
    return scene
}

export async function getMosaic(
    blob: any,
    model: ModelType,
    mapOrigin: LatLngCoord
) {
    let northing = model.northing
    let easting = model.easting
    let width = model.width
    let height = model.height
    let zone_letter = model.zone_letter
    let zone_number = model.zone_number
    let heading = model.heading || 0
    if (
        !height ||
        !width ||
        !northing ||
        !easting ||
        !zone_letter ||
        !zone_number
    )
        return
    const { x, y } = utmToScene(
        {
            northing: northing + (model.offsets ? model.offsets.y : 0),
            easting: easting + (model.offsets ? model.offsets.x : 0),
            zone_letter: zone_letter,
            zone_number: zone_number,
        },
        mapOrigin
    )

    const mosaicGeometry = new THREE.PlaneGeometry(width, height, 10, 10)
    const texture = new THREE.Texture()
    const url = URL.createObjectURL(blob)
    const image = new Image()
    image.src = url
    image.onload = function () {
        texture.image = image
        texture.needsUpdate = true
    }
    const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.FrontSide,
        opacity: 0.8,
        transparent: true,
    })
    const mosaic = new THREE.Mesh(mosaicGeometry, material)
    //Rotate around top left corner of the plane as a pivot
    const pivot = new THREE.Object3D()
    pivot.add(mosaic)
    pivot.scale.setScalar(getPixelRes())
    const mcdOffset = model.offsets ? model.offsets.mcd : 0
    pivot.position.set(x, y, TWOD_OBJECTS_DEPTH - mcdOffset * getPixelRes())
    pivot.setRotationFromEuler(new THREE.Euler(0, 0, -degToRad(heading), 'XYZ'))
    return pivot
}

// PCD & DEPTH SCALE ============================================================

export function initColor(
    pcd: THREE.Points<BufferGeometry, THREE.PointsMaterial> | null,
    geometry?: THREE.BufferGeometry
) {
    // set a default color attribute

    // Method 1: float
    const colorsArr = []
    const color = new THREE.Color('rgb(176, 198, 255)').convertLinearToSRGB()
    if (pcd) {
        if (!pcd.geometry.attributes.color) {
            pcd.material.color.setRGB(1, 1, 1)
            for (
                let i = 0, n = pcd.geometry.attributes.position.count;
                i < n;
                i++
            ) {
                colorsArr.push(color.r, color.g, color.b)
            }
            pcd.geometry.setAttribute(
                'color',
                new THREE.Float32BufferAttribute(colorsArr, 3, false)
            )
        }
        pcd.geometry.attributes.color.needsUpdate = true
    } else if (geometry) {
        for (let i = 0, n = geometry.attributes.position.count; i < n; i++) {
            colorsArr.push(color.r, color.g, color.b)
        }
        geometry.setAttribute(
            'color',
            new THREE.Float32BufferAttribute(colorsArr, 3, false)
        )
    }
}

export async function getPCD(
    blob: any,
    model: ModelType,
    mapOrigin: LatLngCoord
) {
    if (!blob || !model.northing || !model.easting) return
    const { x, y } = utmToScene(
        {
            northing: model.northing + (model.offsets ? model.offsets.y : 0),
            easting: model.easting + (model.offsets ? model.offsets.x : 0),
            zone_letter: model.zone_letter,
            zone_number: model.zone_number,
        },
        mapOrigin
    )
    const loader = new PCDLoader()
    const points = loader.parse(blob, '')
    points.material.size = model.materialSize || 0.2
    if (model.rotateX) points.rotateX(degToRad(model.rotateX))
    if (model.rotateY) points.rotateY(degToRad(model.rotateY))
    if (model.heading) points.rotateZ(degToRad(model.heading))
    const scale = model.scale || 1
    points.scale.setScalar(getPixelRes() * scale)
    const mcdOffset = model.offsets ? model.offsets.mcd : 0
    points.position.set(x, y, -model.depth - mcdOffset * getPixelRes())
    initColor(points)
    points.material.vertexColors = true
    points.name = 'pcd'
    return points
}

export async function getXYZ(
    blob: any,
    mapObjects: THREE.Object3D<THREE.Event>,
    origin: LatLngCoord,
    offsets: { x: number, y: number, mcd: number },
    materialSize?: number,
    modelQuality?: Quality,
    setPercent?: (value: number) => void,
    currProportion?: number[]
    // location: LatLngCoord | null,
    // isCoordFar: Function
) {
    // read file
    const file = new File([blob], 'name')
    const { object, originUTM, mcd, center } = await XYZLoader(
        file,
        materialSize,
        modelQuality,
        setPercent,
        currProportion
    )
    const resultantMcd = mcd + offsets.mcd

    // if (!origin) {
    //   // get location of object
    //   const mapTilesOrigin = utmToLatLng({
    //     easting: originUTM.easting + center.x,
    //     northing: originUTM.northing - center.y,
    //     zone_letter: originUTM.zone_letter,
    //     zone_number: originUTM.zone_number,
    //   })
    //   const { origin, map } = await getMapTiles(mapTilesOrigin)
    //   setLocation(mapTilesOrigin)
    // }
    const { x, y } = utmToScene(
        {
            northing: originUTM.northing + offsets.y,
            easting: originUTM.easting + offsets.x,
            zone_letter: originUTM.zone_letter,
            zone_number: originUTM.zone_number,
        },
        origin
    )

    object.position.set(x, y, -(resultantMcd * getPixelRes()))
    object.scale.setScalar(getPixelRes())
    mapObjects.add(object)

    return { mcd: resultantMcd, object: object, avgVector: center }
}

export function getUpdatedMaterialSizes(
    objects: THREE.Object3D<THREE.Event>[],
    materialSize: number
) {
    const newModels = []
    for (let i = 0; i < objects.length; i++) {
        const object = objects[i]
        if (
            object instanceof
            THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>
        ) {
            // for xyz/pcd models
            const objectGeometry = object.geometry
            const objectMaterial = new THREE.PointsMaterial({
                size: materialSize,
                vertexColors: true, // always true as ColorMap requires vertexColors to be true
                // color: new THREE.Color(1, 1, 1),
            })
            const newObject = new THREE.Points(objectGeometry, objectMaterial)
            newObject.position.copy(object.position)
            newObject.scale.setScalar(getPixelRes())
            newModels.push(newObject)
        } else {
            // for mosaic models
            newModels.push(object)
        }
    }
    return newModels
}

export function getDepthMinMax(
    pcd: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>,
    currentMin: number,
    currentMax: number,
    mcd?: number
) {
    const positions = pcd.geometry.attributes.position
    // const scale = pcd.scale.z
    let min = currentMin
    let max = currentMax
    let currMCD = mcd ? mcd : 0
    for (let i = 0; i < positions.count; i++) {
        const value = positions.getZ(i)
        min = value - currMCD < min ? value - currMCD : min
        max = value - currMCD > max ? value - currMCD : max
    }
    return { min: min, max: max }
}

let lut: Lut

export function initLut(min: number, max: number, colorMap: string) {
    lut = new Lut()
    lut.setColorMap(colorMap)
    lut.setMax(max - min)
    lut.setMin(0)
}

export function getColor(depth: number) {
    let colorValue = depth
    if (isNaN(colorValue)) {
        colorValue = 0
    }
    if (!lut) return new THREE.Color(0xff000)
    const color = lut.getColor(colorValue)
    return color
}

export function getLut() {
    return lut
}

export function getComplimentaryColor(hex: string) {
    const tempReg = (hex = hex.replace('#', '')).match(
        new RegExp('(.{' + hex.length / 3 + '})', 'g')
    )
    if (tempReg === null) return
    var rgbStr =
        'rgb(' +
        tempReg
            .map(function (l) {
                return parseInt(hex.length % 2 ? l + l : l, 16)
            })
            .join(',') +
        ')'
    var rgb = rgbStr.replace(/[^\d,]/g, '').split(',')
    var r = parseInt(rgb[0]),
        g = parseInt(rgb[1]),
        b = parseInt(rgb[2])
    r /= 255.0
    g /= 255.0
    b /= 255.0
    var max = Math.max(r, g, b)
    var min = Math.min(r, g, b)
    var h,
        s,
        l = (max + min) / 2.0
    if (max == min) {
        h = s = 0
    } else {
        var d = max - min
        s = l > 0.5 ? d / (2.0 - max - min) : d / (max + min)
        if (max == r && g >= b) {
            h = (1.0472 * (g - b)) / d
        } else if (max == r && g < b) {
            h = (1.0472 * (g - b)) / d + 6.2832
        } else if (max == g) {
            h = (1.0472 * (b - r)) / d + 2.0944
        } else if (max == b) {
            h = (1.0472 * (r - g)) / d + 4.1888
        }
    }
    if (!h) return
    h = (h / 6.2832) * 360.0 + 0
    h += 180
    if (h > 360) {
        h -= 360
    }
    h /= 360
    if (s === 0) {
        r = g = b = l
    } else {
        var hue2rgb = function hue2rgb(p: number, q: number, t: number) {
            if (t < 0) t += 1
            if (t > 1) t -= 1
            if (t < 1 / 6) return p + (q - p) * 6 * t
            if (t < 1 / 2) return q
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
            return p
        }
        var q = l < 0.5 ? l * (1 + s) : l + s - l * s
        var p = 2 * l - q
        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
    }
    r = Math.round(r * 255)
    g = Math.round(g * 255)
    b = Math.round(b * 255)
    var output = b | (g << 8) | (r << 16)
    var outputStr = '#' + (0x1000000 | output).toString(16).substring(1)
    return parseInt(outputStr.replace(/^#/, ''), 16)
}

export function getDepthColorMap(
    pcd: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>,
    min: number,
    max: number,
    height: number,
    width: number,
    colorMap: string,
    mcd?: number
) {
    initLut(min, max, colorMap)
    const updatedGeometry = pcd.geometry.clone() // ensures that new pointer to geometry is created
    const colors = updatedGeometry.attributes.color
    const positions = updatedGeometry.attributes.position
    for (let i = 0; i < positions.count; i++) {
        const color = getColor(positions.getZ(i) - min - (mcd ? mcd : 0))
        colors.setXYZ(i, color.r, color.g, color.b)
    }
    colors.needsUpdate = true
    const updatedPCD = new THREE.Points(updatedGeometry, pcd.material)
    updatedPCD.position.copy(pcd.position)
    updatedPCD.scale.setScalar(getPixelRes())
    const depthSprite = getDepthSprite(lut, height, width)
    const map = depthSprite.material.map
    if (map !== null) {
        lut.updateCanvas(map.image)
        map.needsUpdate = true
    }
    return { updatedPCD, depthSprite }
}

export function getDepthFromPixelDepth(pixelDepth: number) {
    const depth = pixelDepth / getPixelRes()
    return -Math.round(depth * 10) / 10
}

const depthScaleHeightRatio = 0.7
const depthScaleWidthRatio = 0.1

export function getDepthSprite(lut: Lut, height: number, width: number) {
    const sprite = new THREE.Sprite(
        new THREE.SpriteMaterial({
            map: new THREE.CanvasTexture(lut.createCanvas()),
        })
    )
    sprite.scale.y = height * depthScaleHeightRatio
    sprite.scale.x = width * depthScaleWidthRatio
    sprite.position.set(0, 0, -10)
    return sprite
}

export function getDepthScaleLabels(
    min: number,
    max: number,
    height: number,
    width: number,
    numberOfLevels: number
) {
    let labels = []
    const maxHeight = (height * depthScaleHeightRatio) / 2
    const interval = (height * depthScaleHeightRatio) / (numberOfLevels - 1)
    const offset = 15
    labels.push(getScaleTitle('Depth (m)', maxHeight, width))
    for (let i = 0; i < numberOfLevels; i++) {
        const value = max - ((max - min) / (numberOfLevels - 1)) * i
        const w = width * depthScaleWidthRatio + offset
        const h = maxHeight - interval * i

        const depthLabel = getScaleLabel(
            `${Math.round(-value * 100) / 100}`,
            w,
            h
        )
        labels.push(depthLabel)
    }
    return labels
}

export function getScaleTitle(value: string, height: number, width: number) {
    const heightBuffer = height * 0.15
    const widthBuffer = -width * 0.1
    const text = document.createElement('div')
    text.innerHTML = `${value}`
    text.style.fontSize = '16px'
    text.style.fontWeight = 'bold'
    const textObj = new CSS2DObject(text)
    textObj.position.set(
        width * depthScaleWidthRatio + widthBuffer,
        height + heightBuffer,
        -10
    )
    return textObj
}

export function getScaleLabel(value: string, x: number, y: number) {
    const text = document.createElement('div')
    text.innerHTML = `${value}`
    text.style.fontSize = '14px'
    const textObj = new CSS2DObject(text)
    textObj.position.set(x, y, -10)
    return textObj
}

export function getBoxModel(model: ModelType, mapOrigin: LatLngCoord) {
    if (!model.latitude || !model.longitude) return { mesh: null, label: null }
    const size = model.size || [1, 1, 1]
    const geometry = new THREE.BoxGeometry(size[0], size[1], size[2])
    const material = new THREE.MeshBasicMaterial({
        color: 0xffff00,
    })
    const mesh = new THREE.Mesh(geometry, material)
    const { x, y } = latLngToScene(
        {
            latitude: model.latitude,
            longitude: model.longitude,
            zoom: mapOrigin.zoom,
        },
        mapOrigin
    )
    if (model.rotateX) mesh.rotateX(degToRad(model.rotateX))
    if (model.rotateY) mesh.rotateY(degToRad(model.rotateY))
    mesh.position.set(x, y, -model.depth)
    const scale = model.scale || 1
    mesh.scale.setScalar(getPixelRes() * scale)
    var label
    if (model.title) {
        label = getIkanLabel(model.title)
        mesh.add(label)
    }
    mesh.addEventListener('removed', (e) => {
        e.target.remove(e.target.children[0]) // Remove label
    })
    return { mesh: mesh, label: label }
}

// GENERAL ELEMENTS ============================================================
export function getLine(
    material: THREE.MeshBasicMaterial | THREE.LineDashedMaterial
): THREE.Line {
    const geometry = new THREE.BufferGeometry()
    return new THREE.Line(geometry, material)
}

export function getMarkerLabel(text: string): CSS2DObject {
    const div = document.createElement('div')
    div.className = 'marker-label rounded'
    div.textContent = text
    div.style.marginTop = '-1em'

    const label = new CSS2DObject(div)
    label.position.set(0, 0, -1)
    return label
}

// GENERAL FUNCTIONS ===========================================================
export function disposeObject(
    object: THREE.Object3D | THREE.Mesh,
    scene: THREE.Scene
) {
    const children = object.children
    let child
    if (children) {
        for (let i = 0; i < children.length; i += 1) {
            child = children[i]
            disposeObject(child, scene)
        }
    }
    if (object instanceof THREE.Mesh) {
        const geometry = object.geometry
        const material = object.material
        if (geometry) {
            geometry.dispose()
        }
        if (material && !Array.isArray(material)) {
            // @ts-ignore
            const texture = material.map
            if (texture) {
                texture.dispose()
            }
            material.dispose()
        } else if (material) {
            for (let i = 0; i < material.length; i++) {
                material[i].dispose()
            }
        }
    }
    scene.remove(object)
}

function fetchTile(z: number, x: number, y: number): string {
    return `https://mt1.google.com/vt/lyrs=y&x=${x}&y=${y}&z=${z}`
}