import React, { createContext, useReducer } from 'react'
import {
  FilterType,
  TagType,
  initFilter,
} from '../components/utils/FilterUtils'
import {
  CombinedActions,
  filterReducer,
  msalReducer,
  tagReducer,
} from './reducers'

type InitialStateType = {
  filter: FilterType
  tags: TagType
  msal: {
    storage: string
    asset: string
  }
}

const initialState: InitialStateType = {
  filter: initFilter,
  tags: {
    sTags: [],
    cTags: [],
  },
  msal: {
    storage: '',
    asset: '',
  },
}

const AppContext = createContext<{
  state: InitialStateType
  dispatch: React.Dispatch<CombinedActions>
}>({
  state: initialState,
  dispatch: () => null,
})

const mainReducer = (
  { filter, tags, msal }: InitialStateType,
  action: CombinedActions
) => ({
  filter: filterReducer(filter, action),
  tags: tagReducer(tags, action),
  msal: msalReducer(msal, action),
})

const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
