import { Vector3 } from 'three'
import * as UTM from 'utm'
import { UTMCoord, LatLngCoord, SceneCoord, SonarInfo } from '../../../../types'

export const TILE_SIZE = 256
export const MAP_SCALE = 1.0

export function radToDeg(value: number): number {
    return (value / Math.PI) * 180
}

export function degToRad(value: number): number {
    return (value / 180) * Math.PI
}

export function latLngToTile({ latitude, longitude, zoom }: LatLngCoord) {
    const n = Math.pow(2, zoom)
    const xtile = Math.floor(((longitude + 180.0) / 360.0) * n)
    const ytile = Math.floor(
        ((1.0 - Math.asinh(Math.tan(degToRad(latitude))) / Math.PI) / 2.0) * n
    )
    return {
        x: xtile,
        y: ytile,
    }
}

export function tileTolatLng(xtile: number, ytile: number, zoom: number) {
    const lng = (xtile / Math.pow(2, zoom)) * 360 - 180
    var n = Math.PI - (2 * Math.PI * ytile) / Math.pow(2, zoom)
    const lat = (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)))
    return {
        lat: lat,
        lng: lng,
    }
}

export function latLngToScene(
    coord: LatLngCoord,
    mapTileOrigin: LatLngCoord
): SceneCoord {
    const origin = latLngToImage(mapTileOrigin)
    const point = latLngToImage({ ...coord, zoom: mapTileOrigin.zoom })
    return {
        x: point.x - origin.x,
        y: -point.y + origin.y,
    }
}

export function sceneToLatLng(
    pos: Vector3,
    mapTileOrigin: LatLngCoord
): LatLngCoord {
    const origin = latLngToImage(mapTileOrigin)
    const image = {
        x: pos.x + origin.x,
        y: -pos.y + origin.y,
    }
    return imageToLatLng(image, mapTileOrigin.zoom)
}

export function utmToScene(
    coord: UTMCoord,
    mapTileOrigin: LatLngCoord
): SceneCoord {
    if (
        coord.easting < 0 ||
        coord.easting > 10000000 ||
        coord.northing < 0 ||
        coord.northing > 10000000
    ) {
        console.log('coord out of range')
        return {
            x: 0,
            y: 0,
        }
    }
    const latLng = UTM.toLatLon(
        coord.easting,
        coord.northing,
        coord.zone_number || 48,
        coord.zone_letter || 'N',
        undefined,
        false
    )
    const coordImage = latLngToImage({
        latitude: latLng.latitude,
        longitude: latLng.longitude,
        zoom: mapTileOrigin.zoom,
    })
    const originImage = latLngToImage(mapTileOrigin)
    return {
        x: coordImage.x - originImage.x,
        y: -(coordImage.y - originImage.y),
    }
}

export function utmToLatLng(coord: UTMCoord): LatLngCoord {
    if (coord.zone) {
        coord.zone_number = Number(coord.zone.slice(0, -1))
        coord.zone_letter = coord.zone.slice(-1)
    }
    const latLng = UTM.toLatLon(
        coord.easting,
        coord.northing,
        coord.zone_number || 48,
        coord.zone_letter || 'N',
        undefined,
        false
    )
    return {
        latitude: latLng.latitude,
        longitude: latLng.longitude,
        zoom: 19,
    }
}

export function sceneToUtm(
    scene: SceneCoord,
    mapTileOrigin: LatLngCoord
): UTMCoord {
    const latLng = sceneToLatLng(new Vector3(scene.x, scene.y, 0), mapTileOrigin)
    const utm = UTM.fromLatLon(latLng.latitude, latLng.longitude)
    return { easting: utm.easting, northing: utm.northing }
    
    // // Old code
    // const origin = UTM.fromLatLon(
    //     mapTileOrigin.latitude,
    //     mapTileOrigin.longitude
    // )
    // const delta = {
    //     northing: scene.y / pixelPerMeter + origin.northing,
    //     easting: scene.x / pixelPerMeter + origin.easting,
    // }
    // return delta
}

// Calculate pixel resolution aka meters per pixel
let pixelPerMeter = 1
export function calcPixelRes(coord: LatLngCoord) {
    const C = 40075016.686
    const tileDist =
        (C * Math.cos(degToRad(coord.latitude))) / Math.pow(2, coord.zoom)
    pixelPerMeter = (TILE_SIZE * MAP_SCALE) / tileDist
}

export function getPixelRes() {
    return pixelPerMeter
}

export function sceneToMeters(coord: SceneCoord): SceneCoord {
    return {
        x: coord.x / pixelPerMeter,
        y: coord.y / pixelPerMeter,
    }
}

export function metersToScene(coord: SceneCoord): SceneCoord {
    return {
        x: coord.x * pixelPerMeter,
        y: coord.y * pixelPerMeter,
    }
}
export function latLngToImage(coord: LatLngCoord): SceneCoord {
    calcPixelRes(coord)
    // Get the world coordinates in pixels
    const worldCoordinate: SceneCoord = project(coord)
    // Scale to fit our image
    const scale = Math.pow(2, coord.zoom)

    // Apply scale to world coordinates to get image coordinates
    return {
        x: worldCoordinate.x * scale * MAP_SCALE,
        y: worldCoordinate.y * scale * MAP_SCALE,
    }
}

const project = (coord: LatLngCoord): SceneCoord => {
    let siny = Math.sin(degToRad(coord.latitude))

    // Truncating to 0.9999 effectively limits latitude to 89.189. This is
    // about a third of a tile past the edge of the world tile.
    siny = Math.min(Math.max(siny, -0.9999), 0.9999)
    return {
        x: TILE_SIZE * (0.5 + coord.longitude / 360),
        y:
            TILE_SIZE *
            (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)),
    }
}

function imageToLatLng(coord: SceneCoord, zoom: number): LatLngCoord {
    const scale = Math.pow(2, zoom)
    const latLng = invProject(
        { x: coord.x / MAP_SCALE / scale, y: coord.y / MAP_SCALE / scale },
        zoom
    )
    return latLng
}

const invProject = (coord: SceneCoord, zoom: number): LatLngCoord => {
    const longitude = (coord.x / TILE_SIZE - 0.5) * 360
    const exp = Math.exp((0.5 - coord.y / TILE_SIZE) * 4 * Math.PI)
    const siny = (exp - 1) / (1 + exp)
    const latitude = (Math.asin(siny) * 180) / Math.PI
    return {
        latitude: latitude,
        longitude: longitude,
        zoom: zoom,
    }
}

export function drawSonarMask(
    sonarInfo: SonarInfo,
    sonarMask: HTMLCanvasElement
) {
    const width = sonarInfo.width
    const height = sonarInfo.height + 1

    sonarMask.width = width
    sonarMask.height = height

    const maskCtx = sonarMask.getContext('2d')
    if (maskCtx === null) return
    // maskCtx.clearRect(0, 0, width, height)

    const startY =
        height - Math.tan(degToRad(90 - sonarInfo.fov_max)) * (width / 2)
    maskCtx.fillStyle = '#000000'
    maskCtx.lineWidth = 1
    maskCtx.beginPath()
    // maskCtx.rect(width, 0, -width, height)
    maskCtx.lineTo(width, startY)
    maskCtx.lineTo(width / 2, height)
    const dist = 5
    const n = (dist / sonarInfo.range_resol) * (width / sonarInfo.width)
    const i = sonarInfo.max_range / dist
    // const x = width/2 - i*n * Math.sin(degToRad(sonarInfo.fov_max));
    // const y = height - i*n * Math.cos(degToRad(sonarInfo.fov_max));
    maskCtx.ellipse(
        width / 2,
        height,
        i * n,
        i * n,
        degToRad(0),
        degToRad(270 - sonarInfo.fov_max),
        degToRad(270 + sonarInfo.fov_max),
        false
    )
    maskCtx.fill()
}
