import React, { useRef, useState, useImperativeHandle, useEffect } from 'react'
import TextButton from '../elements/buttons/TextButton'

export interface MapMenuInterface {
  divRef: React.MutableRefObject<HTMLDivElement | null>
  visible: boolean
  setVisible: (value: boolean) => void
  setType: (value: number) => void
  setMoving: (value: boolean) => void
}

type MapMenuProps = {
  getCoords: () => void
}

const MapMenu = (
  { getCoords }: MapMenuProps,
  ref: React.Ref<MapMenuInterface>
) => {
  const divRef = useRef<HTMLDivElement | null>(null)
  const [moving, setMoving] = useState(false)
  const [visible, setVisible] = useState(false)
  const [type, setType] = useState(0)

  useImperativeHandle(ref, () => ({
    divRef,
    visible,
    setVisible,
    setType,
    setMoving,
  }))

  useEffect(() => {
    if (visible) {
      divRef.current!.addEventListener('pointerdown', getCoords, {
        capture: true,
      })
      return () => {
        divRef.current!.removeEventListener('pointerdown', getCoords, {
          capture: true,
        })
      }
    }
  }, [visible])

  return (
    <div
      ref={divRef}
      style={{ visibility: visible ? 'visible' : 'hidden' }}
      className='pointer-none z-alert'>
      {/* <div className={`mapPanelDot shadow`}/> */}
      <div
        className={
          'rounded-md surface-bg pointer-all cursor-pointer flex flex-col'
        }
        style={{ transform: 'translate(50%, 50%)' }}>
        {moving && (
          <div className='h3' style={{ padding: '8px' }}>
            Moving here.
            <br />
            Press Z to stop.
          </div>
        )}
        {!moving && (
            <TextButton
              className='background'
              contentColor='on-background'
              maxWidth={true}
              text='Get coordinates'
              onClick={getCoords}
            />
        )}
      </div>
    </div>
  )
}

export default React.forwardRef(MapMenu)
