import React, { useEffect, useState } from 'react'
import AssistChip from '../../../elements/chips/AssistChip'
import { Hashtag, HashtagType } from '../../../../types'
import TextButton from '../../../elements/buttons/TextButton'
import TextInput from '../../../elements/inputs/TextInput'
import EditableFilterChip from '../../../elements/chips/EditableFilterChip'
import Dialog from '../../../elements/Dialogs/Dialog'
import Snackbar from '../../../elements/Dialogs/Snackbar'
import { CloseIcon } from '../../../../assets'

export type SearchTagProps = {
  placeholder: string
  suggestion?: string
  className?: string
  allTags: Hashtag[]
  modelTags?: Hashtag[] // refers to the structure and component tags associated to the model
  onChange: (value: Hashtag | null) => void
  type?: HashtagType
  setEdited?: (edited: boolean) => void
  handleSaveTags?: (
    hashtags: Hashtag[],
    hashtagsEdited?: { old: Hashtag; new: Hashtag }[]
  ) => void
}

const SearchTag = ({
  placeholder,
  allTags,
  className,
  modelTags,
  onChange,
  type,
  setEdited,
  handleSaveTags,
}: SearchTagProps) => {
  const [displayAllTags, setDisplayAllTags] = useState<Hashtag[]>(allTags)

  // Boolean states
  const [onFocus, setOnFocus] = useState<boolean>(false)
  const [onHover, setOnHover] = useState<boolean>(false)
  const [editing, setEditing] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [editingTag, setEditingTag] = useState<boolean>(false)

  // Variable states
  const [currHashtag, setCurrHashtag] = useState<Hashtag | null>(null)
  const [originalHashtag, setOriginalHashtag] = useState<Hashtag | null>(null) // to compare with currHashtag
  const [searchInput, setSearchInput] = useState<string>('')
  const [filterBySearchInput, setFilterBySearchInput] = useState<Hashtag[]>([])
  const [editedHashtags, setEditedHashtags] = useState<
    | {
        old: Hashtag
        new: Hashtag
      }[]
    | null
  >([])
  const [dialogMessage, setDialogMessage] = useState<string>('')
  const [hashtagToDelete, setHashtagToDelete] = useState<Hashtag | null>(null)
  // const [allOriginalTags, setAllOriginalTags] = useState<Hashtag[]>([])
  const [snackbarText, setSnackbarText] = useState<string>('')

  // Functions for handles
  const existingTag = (tagContent: string) => {
    const matchingTags = displayAllTags.filter((x) => x.content === tagContent)
    if (matchingTags.length) return matchingTags[0]
    return undefined
  }

  const handleAddHashtag = async (hashtagContent: string) => {
    if (type === undefined) return
    const newTag = { type: type, content: hashtagContent }
    setCurrHashtag(newTag)
    setEdited && setEdited(true)
    setOnFocus(false)
  }

  const handleAssistChipClick = (hashtag: Hashtag) => {
    onFocus && setCurrHashtag(hashtag)
    setOnFocus(false)
  }

  const handleClickCross = async (hashtag: Hashtag) => {
    await setDialogMessage(
      `Are you sure you want to remove '${hashtag.content}' hashtag? This will remove all other hashtags in the asset as well.`
    )
    await setHashtagToDelete(hashtag)
    await setOpenDialog(true)
  }

  const handleDeleteHashtag = async (hashtag: Hashtag) => {
    const updatedList = displayAllTags.filter(
      (x) => x.content != hashtag.content
    )
    setDisplayAllTags(updatedList)

    if (currHashtag && currHashtag.content === hashtag.content) {
      setCurrHashtag(null)
    }
  }

  const handleEnterEditableChip = async (
    previousContent: string,
    newContent: string
  ) => {
    if (existingTag(newContent)) {
      setSnackbarText('Hashtag already exists!')
      return
    }
    let newHashtagArray = editedHashtags || []
    const oldTag = { type: type!, content: previousContent }
    const newTag = { type: type!, content: newContent }
    const findMatchingOld = newHashtagArray.findIndex(
      (x) => x.new.content === previousContent
    )
    // const findMatchingNew = newHashtagArray.findIndex(
    //   (x) => x.new.content === newContent
    // )

    if (findMatchingOld != -1) {
      // find the original old hashtag
      newHashtagArray[findMatchingOld] = {
        old: newHashtagArray[findMatchingOld].old,
        new: newTag,
      }
    } else {
      newHashtagArray = [...newHashtagArray, { old: oldTag, new: newTag }]
    }

    setEditedHashtags(newHashtagArray)

    const findMatchingWithCurrentIndex = displayAllTags.findIndex(
      (x) => x.content == previousContent
    )
    let newDisplayTags = displayAllTags

    if (findMatchingWithCurrentIndex != -1) {
      newDisplayTags[findMatchingWithCurrentIndex] = newTag
      setDisplayAllTags(newDisplayTags)
    }
    // setCurrHashtag(newTag)
    // setEdited && setEdited(false)
  }

  const handleClickHashtagSave = async () => {
    setEditing(false)
    handleSaveTags &&
      handleSaveTags(
        displayAllTags,
        editedHashtags ? editedHashtags : undefined
      )
    const editedHashtagFiltered = editedHashtags?.filter(
      (x) => x.old.content === currHashtag?.content
    )
    if (editedHashtagFiltered?.length) {
      editedHashtagFiltered && setCurrHashtag(editedHashtagFiltered[0].new)
    }
    setEditedHashtags([])
    setOnFocus(false)
  }

  // const handleClickBack = async () => {
  //   const originalTags = editedHashtags?.map((x) => x.old)
  //   console.log(originalTags)
  //   originalTags && setDisplayAllTags(originalTags)
  //   setDisplayAllTags(allOriginalTags)
  //   setEditing(false)
  // }

  // update current states if modelTags change from parent component
  useEffect(() => {
    const currHashtags = modelTags?.filter((x) => x.type === type)
    if (currHashtags?.length) {
      setOriginalHashtag(currHashtags[0])
      !currHashtag && setCurrHashtag(currHashtags[0])
    }
  }, [modelTags])

  // update filtered list by search input
  useEffect(() => {
    if (searchInput != '') {
      setFilterBySearchInput(
        displayAllTags.filter((value) =>
          value.content.toLowerCase().includes(searchInput.toLowerCase())
        )
      )
    } else if (searchInput == '') {
      setFilterBySearchInput(displayAllTags)
    }
  }, [searchInput, displayAllTags])

  useEffect(() => {
    if (originalHashtag?.content != currHashtag?.content) {
      setEdited && setEdited(true)
    } else {
      setEdited && setEdited(false)
    }
    onChange(currHashtag)
  }, [currHashtag])

  return (
    <>
      {onFocus && (
        <div className='abs inset-0' onClick={() => setOnFocus(false)} />
      )}
      <div
        className={`${className} 'shadow rel max-width-100`}
        onFocus={() => setOnFocus(true)}>
        {snackbarText !== '' && (
          <Snackbar
            text={snackbarText}
            onClose={() => {
              setSnackbarText('')
            }}
          />
        )}
        {openDialog && (
          <Dialog
            boxClassName={`max-width-25 flex flex-wrap`}
            text={'Confirm Remove?'}
            description={dialogMessage}
            buttonText={`Confirm`}
            onClick={() => {
              hashtagToDelete && handleDeleteHashtag(hashtagToDelete)
              setOpenDialog(false)
            }}
            onClose={() => setOpenDialog(false)}
          />
        )}
        <button
          className={`rel cursor-pointer rounded-xsm h-42px z-2 w-100 border-box border-0 flex justify-center align-center ${
            onHover ? 'outline' : 'surface-container-bg outline-border'
          }`}
          onClick={() => {
            setOnFocus(true)
          }}
          onMouseOver={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}>
          {!currHashtag && <div className={`on-background`}>{placeholder}</div>}
          {currHashtag && (
            <AssistChip
              className={'max-width-100 h-100'}
              text={`#${currHashtag.content}`}
              TrailingIcon={CloseIcon}
              onClick={() => {}}
              onClickTrailing={async () => {
                await setCurrHashtag(null)
                await setOnFocus(false)
              }}
            />
          )}
        </button>
        {onFocus && (
          <div
            className='abs left-0 right-0 surface-variant-secondary-bg border-box flex flex-wrap overflow-y-scroll z-10 rounded-b-sm w-100'
            style={{ maxHeight: '350px' }}>
            <div
              className={`rel w-100 flex flex-col justify-center align-center padding-xsm`}>
              <div
                onFocus={() => setOnFocus(true)}
                onBlur={(e) => {
                  if (!editing) {
                    setOnFocus(false)
                  }
                }}>
                <TextInput
                  title={`Add/Search Tag`}
                  value={searchInput}
                  onChange={(value) => {
                    setSearchInput(value.toString().trim())
                  }}
                  className={`flex flex-wrap justify-center align-center text-center w-100`}
                  headerPresent={false}
                  regex={/^(?!\s+$).{0,16}$/}
                  error={`Maximum 16 characters!`}
                />
              </div>
              {searchInput != '' && existingTag(searchInput) === undefined && (
                <div className='margin-t-sm'>
                  <TextButton
                    text={`+ Add`}
                    onClick={() => {
                      handleAddHashtag(searchInput)
                    }}
                    disabled={
                      /^(?!\s+$).{0,16}$/.test(searchInput) ? false : true
                    }
                  />
                </div>
              )}
              {filterBySearchInput.length > 0 && (
                <div
                  className={`flex flex-col justify-center align-content-center w-100`}>
                  {filterBySearchInput.map((hashtag, idx) => {
                    return (
                      <div
                        className={'flex justify-center padding-xsm'}
                        onFocus={() => setOnFocus(true)}>
                        {!editing && (
                          <AssistChip
                            onClick={() => {
                              handleAssistChipClick(hashtag)
                            }}
                            className={'text-xsm'}
                            text={`#${hashtag.content}`}
                            key={idx}
                          />
                        )}
                        {editing && (
                          <EditableFilterChip
                            text={`#${hashtag.content}`}
                            onClick={() => {}} // Function not working...
                            onEnter={(currentHashtag) => {
                              handleEnterEditableChip(
                                hashtag.content,
                                currentHashtag.content
                              )
                            }}
                            TrailingIcon={CloseIcon}
                            onClickTrailing={() => handleClickCross(hashtag)}
                            isEditing={(value) => setEditingTag(value)}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
              )}
              {handleSaveTags && (
                <div className={'flex justify-center padding-xsm'}>
                  {!editing && filterBySearchInput.length > 0 && (
                    <TextButton
                      className={`rounded-sm`}
                      text={`Edit Tags`}
                      onClick={() => {
                        setEditing(true)
                      }}
                    />
                  )}
                  {editing && (
                    <div className={'flex w-100 justify-center flex-row'}>
                      {/* <IconButton
                      Icon={RestoreIcon}
                      onClick={() => {
                        handleClickBack()
                      }}
                    /> */}
                      <TextButton
                        className={`secondary-container-bg rounded-sm`}
                        text={`Save`}
                        onClick={() => handleClickHashtagSave()}
                        disabled={editingTag}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SearchTag
