import { useMsal } from '@azure/msal-react'
import React, { useState, useRef, useEffect } from 'react'
import {
    MapMode,
    CameraMode,
    Dimension,
    MouseState,
    LatLngCoord,
    ModelType,
    UTMCoord,
    ColorMap,
} from '../../types'
import Map from '../map/Map'
import MapToolbar from '../map/MapToolbar'
import { SonarImageInterface } from '../utils/sonar'
import LoadingSpinner from '../elements/loadingSpinner'
import { processXYZFile } from '../pointcloudpage/CustomXYZLoader'
import MapDialog from '../map/MapDialog'

type MapContainerProps = {
    mapRef: React.RefObject<Map>
    sonarRef: React.RefObject<SonarImageInterface>
    models: ModelType[] | null
    asset: string
    fullscreen?: boolean
    setFullscreen?: (value: boolean) => void
}

const MapContainer = (props: MapContainerProps) => {
    const { instance, accounts } = useMsal()
    const { mapRef, sonarRef, models, asset } = props
    const [location, setLocation] = useState<LatLngCoord | null>(null)
    // Location originally set to this coordinate
    // {
    //   latitude: 1.295147,
    //   longitude: 103.75962,
    //   zoom: 19,
    // }
    const [mapMode, setMapMode] = useState<MapMode>(MapMode.MAP)
    const [cameraMode, setCameraMode] = useState<CameraMode>(CameraMode.FOLLOW)
    const [mouseState, setMouseState] = useState<MouseState>(MouseState.DEFAULT)
    const [dimension, setDimension] = useState<Dimension>(Dimension.TWOD)
    const [mapHeading, setMapHeading] = useState<number>(0)
    const [showTrail, setShowTrail] = useState(true)
    const [showSonar, setShowSonar] = useState(true)
    const [showMap, setShowMap] = useState(true)
    const [showModels, setShowModels] = useState(false)
    const [clearTrail, setClearTrail] = useState(false)
    const [zoom, setZoom] = useState(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [resetObjects, setResetObjects] = useState<boolean>(false)
    const [colorMap, setColorMap] = useState<ColorMap>(ColorMap.NONE)

    // additional xyz models
    const [modelOrigin, setModelOrigin] = useState<UTMCoord | null>(null)
    const [modelFile, setModelFile] = useState<File | null>(null)
    const [kmlFile, setKMLFile] = useState<File | null>(null)
    const [mosaicFiles, setMosaicFiles] = useState<File[]>([])

    // Map Dialog
    const [showDialog, setShowDialog] = useState<boolean>(true)
    const [dialogText, setDialogText] = useState<string>('')

    const [percent, setPercent] = useState<number>(0)

    const resetModel = () => {
        setResetObjects(true)
        setModelOrigin(null)
    }

    const processFile = async (file: File) => {
        try {
            const fileType = file.name.split('.').slice(-1)[0] // final segment to get file type in file name
            if (fileType === 'xyz') {
                const { utm, mcd } = await processXYZFile(file)
                if (utm) {
                    setModelOrigin(utm)
                }
            } else {
                alert('Invalid file type. Only .xyz is supported')
            }
        } catch (error) {
            console.log(error)
            alert('Opps. File seems to be corrupted.')
        }
    }

    useEffect(() => {
        if (!modelFile) {
            resetModel()
            return
        }
        processFile(modelFile)
    }, [modelFile])

    return (
        <div
            style={{
                width: 'calc(100% - 20px)',
                height: 'calc(100% - 20px)',
                position: 'relative',
                margin: '10px',
            }}>
            {loading && (
                <div className='div-flex-center-full'>
                    <LoadingSpinner width={80} height={80} />
                </div>
            )}
            {!loading && (
                <div>
                    {showDialog && (
                        <div className='rel h-100'>
                            <MapDialog
                                onClose={() => setShowDialog(false)}
                                dialogText={dialogText}
                            />
                        </div>
                    )}
                    <MapToolbar
                        dimension={dimension}
                        setDimension={setDimension}
                        showMap={showMap}
                        setShowMap={setShowMap}
                        showModels={showModels}
                        setShowModels={setShowModels}
                        cameraMode={cameraMode}
                        setCameraMode={setCameraMode}
                        mouseState={mouseState}
                        setMouseState={setMouseState}
                        modelFile={modelFile}
                        setModelFile={setModelFile}
                        kmlFile={kmlFile}
                        setKMLFile={setKMLFile}
                        mosaicFiles={mosaicFiles}
                        setMosaicFiles={setMosaicFiles}
                        colorMap={colorMap}
                        setColorMap={setColorMap}
                        fullscreen={props.fullscreen}
                        setFullscreen={props.setFullscreen}
                        mapHeading={mapHeading}
                        setMapHeading={setMapHeading}
                    />
                    <Map
                        colorMap={colorMap}
                        ref={mapRef}
                        sonarRef={sonarRef}
                        instance={instance}
                        account={accounts[0]}
                        models={models}
                        location={location}
                        mapMode={mapMode}
                        mouseState={mouseState}
                        cameraMode={cameraMode}
                        dimension={dimension}
                        mapHeading={mapHeading}
                        showTrail={showTrail}
                        showSonar={showSonar}
                        showMap={showMap}
                        showModels={showModels}
                        clearTrail={clearTrail}
                        zoom={zoom}
                        asset={asset}
                        markers={null}
                        selectedMarkersIdx={null}
                        // setSelectedMarkers={setSelectedMarkers}
                        setClearTrail={() => setClearTrail(false)}
                        resetZoom={() => setZoom(0)}
                        setLocation={setLocation}
                        modelOrigin={modelOrigin}
                        modelFile={modelFile}
                        kmlFile={kmlFile}
                        mosaicFiles={mosaicFiles}
                        loading={loading}
                        resetObjects={resetObjects}
                        setDialogText={setDialogText}
                        setShowDialog={setShowDialog}
                        setPercent={setPercent}
                        setMapHeading={setMapHeading}
                        fullscreen={props.fullscreen}
                    />
                </div>
            )}
        </div>
    )
}

export default MapContainer
