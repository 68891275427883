import React, { useImperativeHandle, useState } from 'react'
import { timestampToDate, timestampToHMS, VelocityDirection } from '../../types'
import ArrowUpLogo from '../../assets/icons/arrow_circle_up_black_24dp.svg'
import ArrowDownLogo from '../../assets/icons/arrow_circle_down_black_24dp.svg'
import ArrowRightLogo from '../../assets/icons/arrow_circle_right_black_24dp.svg'
import ArrowLeftLogo from '../../assets/icons/arrow_circle_left_black_24dp.svg'
import DownloadIcon from '../../assets/icons/file_download_black_24dp.svg'
import FilledTonalButton from '../elements/buttons/FilledTonalButton'

export interface InfoPanelInterface {
    setHeading: (value: number) => void
    setDepth: (value: number) => void
    setAltitude: (value: number) => void
    setUt: (value: number) => void
    setCp: (value: number) => void
    setTimestamp: (value: number) => void
    setCoord: (value: string) => void
    setVelocity: (value: number) => void
    setVelocityDirection: (value: string) => void
    setPitch: (value: number) => void
    setRoll: (value: number) => void
    setContact: (value: number) => void
    setProximity: (value: number | null) => void
    setFg: (value: number) => void
}

type InfoPanelProps = {
    downloadCSVFile: () => void
}

const InfoPanel = (
    { downloadCSVFile }: InfoPanelProps,
    ref: React.Ref<InfoPanelInterface>
) => {
    const [timestamp, setTimestamp] = useState(0)
    const [heading, setHeading] = useState(0)
    const [depth, setDepth] = useState(0)
    const [altitude, setAltitude] = useState(0)
    const [coord, setCoord] = useState('')
    const [cp, setCp] = useState<number | null>(null)
    const [ut, setUt] = useState<number | null>(null)
    const [velocity, setVelocity] = useState(0)
    const [velocityDirection, setVelocityDirection] = useState(
        VelocityDirection[VelocityDirection.UPWARD]
    )
    const [pitch, setPitch] = useState(0)
    const [roll, setRoll] = useState(0)
    const [contact, setContact] = useState<number | null>(null)
    const [proximity, setProximity] = useState<number | null>(null)
    const [fg, setFg] = useState<number | null>(null)

    useImperativeHandle(ref, () => ({
        setHeading,
        setDepth,
        setAltitude,
        setCp,
        setUt,
        setTimestamp,
        setCoord,
        setVelocity,
        setVelocityDirection,
        setPitch,
        setRoll,
        setContact,
        setProximity,
        setFg,
    }))
    return (
        <div
            className={'overflow-scroll'}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                width: '100%',
                height: '100%',
                alignItems: 'flex-start',
                backgroundColor: '#17171d',
                borderRadius: '14px',
                userSelect: 'text',
            }}>
            <div style={{ margin: '12px' }}>
                <span className='h3'>Date</span>
                <br />
                <span className='h2'>{timestampToDate(timestamp)}</span>
            </div>
            <div style={{ margin: '12px' }}>
                <span className='h3'>Time</span>
                <br />
                <span className='h2'>{timestampToHMS(timestamp)}</span>
            </div>
            <div style={{ margin: '12px' }}>
                <span className='h3'>Heading</span>
                <br />
                <span className='h2'>{heading}&#176;</span>
            </div>
            <div style={{ margin: '12px' }}>
                <span className='h3'>Pitch</span>
                <br />
                <span className='h2'>{pitch}°</span>
            </div>
            <div style={{ margin: '12px' }}>
                <span className='h3'>Roll</span>
                <br />
                <span className='h2'>{roll}°</span>
            </div>
            <div style={{ margin: '12px' }}>
                <span className='h3'>Depth</span>
                <br />
                <span className='h2'>{depth}m</span>
            </div>
            <div style={{ margin: '12px' }}>
                <span className='h3'>Altitude</span>
                <br />
                <span className='h2'>{altitude}m</span>
            </div>
            <div style={{ margin: '12px' }}>
                <span className='h3'>Coordinate</span>
                <br />
                <span className='h2'>{coord}</span>
            </div>
            {/* <div style={{ margin: '12px' }}>
        <span style={{ overflowWrap: 'anywhere' }}>{props.filename}</span>
      </div> */}
            {cp !== null && (
                <div style={{ margin: '12px' }}>
                    <span className='h3'>CP</span>
                    <br />
                    <span className='h2'>{cp}mV</span>
                </div>
            )}
            {ut !== null && (
                <div style={{ margin: '12px' }}>
                    <span className='h3'>UT</span>
                    <br />
                    <span className='h2'>{ut}mm</span>
                </div>
            )}
            <div style={{ margin: '12px' }}>
                <span className='h3'>Velocity</span>
                <br />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {velocityDirection ===
                        VelocityDirection[VelocityDirection.UPWARD] && (
                        <div>
                            <ArrowUpLogo />
                        </div>
                    )}
                    {velocityDirection ===
                        VelocityDirection[VelocityDirection.DOWN] && (
                        <div>
                            <ArrowDownLogo />
                        </div>
                    )}
                    {velocityDirection ===
                        VelocityDirection[VelocityDirection.RIGHT] && (
                        <div>
                            <ArrowRightLogo />
                        </div>
                    )}
                    {velocityDirection ===
                        VelocityDirection[VelocityDirection.LEFT] && (
                        <div>
                            <ArrowLeftLogo />
                        </div>
                    )}
                    <span className='h2'>{velocity}m/s</span>
                </div>
            </div>
            {contact != null && (
                <div style={{ margin: '12px' }}>
                    <span className='h3'>Contact</span>
                    <br />
                    <span className='h2'>{contact}mV</span>
                </div>
            )}
            {proximity != null && (
                <div style={{ margin: '12px' }}>
                    <span className='h3'>Proximity</span>
                    <br />
                    <span className='h2'>{proximity}mV</span>
                </div>
            )}
            {fg != null && (
                <div style={{ margin: '12px' }}>
                    <span className='h3'>Field Gradient</span>
                    <br />
                    <span className='h2'>{fg}mV</span>
                </div>
            )}
            <div className='margin-l-sm margin-b-sm'>
                <FilledTonalButton
                    text='.csv'
                    onClick={downloadCSVFile}
                    Icon={DownloadIcon}
                    iconHeight={24}
                    iconWidth={24}
                />
            </div>
        </div>
    )
}

export default React.forwardRef(InfoPanel)
