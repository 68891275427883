import React, { useEffect, useState, useRef } from 'react'
import { Rosbag, timestampToTime } from '../utils/rosbag'
import LoadingSpinner from '../elements/loadingSpinner'
import FullscreenIcon from '../../assets/icons/fullscreen_black_24dp.svg'
import PlayerBar, { PlayerBarInterface } from './PlayerBar'
import { getBlob, getContainers, getStorageAccounts } from '../../backend'
import { useAccount, useMsal } from '@azure/msal-react'
import {
    imgSrcToBuffer,
    MarkerType,
    MsFolder,
    PanelType,
    str2PanelType,
} from '../../types'
import MapContainer from './MapContainer'
import MapApp from '../map/Map'
import InfoPanel, { InfoPanelInterface } from './InfoPanel'
import SonarImage, { SonarImageInterface } from '../utils/sonar'
import { FrontCamPanel, FrontCamClahePanel } from '../utils/frontCam'
import SnapshotTimeline from './SnapshotTimeline'
import { createContainer, uploadBlob } from '../../backend/msal/put'
import { deleteBlob } from '../../backend/msal/delete'
import Snackbar from '../elements/Dialogs/Snackbar'
import { getOneDriveFolderContent } from '../../backend/msal/fetch'
import FileUploadDialog from '../elements/Dialogs/FileUploadDialog'
import FileDirectoryDialog from '../elements/Dialogs/FileDirectoryDialog'
import Dropdown from '../elements/Dropdown'
import { saveAs } from 'file-saver'
import Dialog from '../elements/Dialogs/Dialog'
import CSVExportDialog, { IANATimeZone, MaybeMissingCSVFieldsExistence, coordSystemNames } from './CSVExportDialogue'
import Button from '../elements/buttons/Button'

const STORAGE_NAME_TO_REMOVE = ['SAMBALUI', 'VEHICLEOS', 'USER MANUAL']
const ASSET_NAME_TO_REMOVE = [
    'LOG',
    'COMPONENTHASHTAGS',
    'STRUCTUREHASHTAGS',
    'UTILS',
]

type VideoPageProps = {
  setBagName: (name: string) => void
  playableBeexFile?: File
  beexStartTime?: number
  beexEndTime?: number
}

enum loadStage {
    LOADING,
    OPENFILE,
    FULLTRAIL,
}

const topic2PanelTypeDict: Record<string, PanelType> = {
    '/ikan/front_cam/image_color/compressed': PanelType.FRONT_CAM,
    '/ikan/front_cam/image_color/clahe/compressed': PanelType.FRONT_CAM_CLAHE,
    '/ikan/sonar/image/compressed': PanelType.SONAR,
    '/ikan/fulldepth_camera/image_rectified_h264': PanelType.EXT_CAM,
}

const compareMarkers = (a: MarkerType, b: MarkerType) => {
    if (a.timestamp < b.timestamp) return -1
    if (a.timestamp > b.timestamp) return 1
    return 0
}

const rosbag = new Rosbag()

type NavigationItemProps = {
    children: React.ReactChild | React.ReactChild[]
}
const NavigationItem = ({ children }: NavigationItemProps) => {
    const [hover, setHover] = useState<boolean>(false)

    return (
        <div
            className={`cursor-pointer ${hover ? 'primary' : ''}`}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}>
            {children}
        </div>
    )
}

const VideoPage = ({
    setBagName,
    playableBeexFile,
    beexStartTime,
    beexEndTime
}: VideoPageProps) => {
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const frontCamRef = useRef<HTMLImageElement>(null)
    const frontCamClaheRef = useRef<HTMLImageElement>(null)
    const sonarRef = useRef<SonarImageInterface>(null)
    const fullDepthCamRef = useRef<HTMLCanvasElement>(null)

    const [availablePanelTypes, setAvailablePanelTypes] = useState<PanelType[]>([])
    const defaultTopPanelType = PanelType.FRONT_CAM
    const defaultBottomPanelType = PanelType.SONAR // Cannot be same as defaultTopPanelType
    const [topPanelType, setTopPanelType] = useState<PanelType | null>(null)
    const [bottomPanelType, setBottomPanelType] = useState<PanelType | null>(null)

    const [topPanelExpanded, setTopPanelExpanded] = useState(false)
    const [bottomPanelExpanded, setBottomPanelExpanded] = useState(false)
    const [mapPanelExpanded, setMapPanelExpanded] = useState(false)
    const topFullScreenIconSize = topPanelExpanded ? 70 : 36
    const bottomFullScreenIconSize = bottomPanelExpanded ? 70 : 36

    const [showTopDropdown, setShowTopDropdown] = useState(false)
    const [showBottomDropdown, setShowBottomDropdown] = useState(false)

    const playerBarRef = useRef<PlayerBarInterface>(null)
    const mapRef = useRef<MapApp>(null)
    const infoPanelRef = useRef<InfoPanelInterface>(null)
    const [totalTime, setTotalTime] = useState(0)
    const [loading, setLoading] = useState(false)
    const [markerList, setMarkerList] = useState<MarkerType[] | null>(null)
    const [selectedAsset, setSelectedAsset] = useState<string>('')
    const [assets, setAssets] = useState<string[]>([])
    const [date, setDate] = useState('')
    const [filename, setFilename] = useState('')
    const [loadedfullTrail, setLoadedFullTrail] = useState(loadStage.LOADING)
    const [timelineRevealed, setTimelineRevealed] = useState(false)
    const [storageAccounts, setStorageAccounts] = useState<string[]>([])
    const [selectedAccount, setSelectedAccount] = useState<string>('')

    // Snackbar
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>('')

    // Onedrive Files
    const [selectedFolder, setSelectedFolder] = useState<MsFolder>()
    const [selectedFolderFiles, setSelectedFolderFiles] = useState<any[]>([])
    const [folderDirectory, setFolderDirectory] = useState<MsFolder[]>([])
    const [selectedOneDriveAccount, setSelectedOneDriveAccount] =
        useState<string>('')

    // Export & Download CSV
    const [haveMaybeMissingCSVFields, setHaveMaybeMissingCSVFields] =
        useState<MaybeMissingCSVFieldsExistence>({
            cp: false, ut: false, contactAndFg: false, proximity: false,
        })

    const [notifyDownloadCSV, setNotifyDownloadCSV] = useState<boolean>(false)

    const [timeZone, setTimeZone] = useState<string>(IANATimeZone[0])

    const [hasSVY21Option, setHasSVY21Option] = useState<boolean>(false)
    const coordSystemOptions = hasSVY21Option ? coordSystemNames : coordSystemNames.slice(0, 2)
    const [selectedCoordSystemOptionIdx, setSelectedCoordSystemOptionIdx] = useState<number>(0)

    const [selectingCoordinate, setSelectingCoordinate] = useState<boolean>(true)
    const [selectingHeading, setSelectingHeading] = useState<boolean>(true)
    const [selectingPitch, setSelectingPitch] = useState<boolean>(true)
    const [selectingRoll, setSelectingRoll] = useState<boolean>(true)
    const [selectingDepth, setSelectingDepth] = useState<boolean>(true)
    const [selectingAltitude, setSelectingAltitude] = useState<boolean>(true)
    const [selectingVelocity, setSelectingVelocity] = useState<boolean>(true)

    const [selectingCp, setSelectingCp] = useState<boolean>(true)
    const [selectingUt, setSelectingUt] = useState<boolean>(true)
    const [selectingContact, setSelectingContact] = useState<boolean>(true)
    const [selectingProximity, setSelectingProximity] = useState<boolean>(true)
    const [selectingFieldGradient, setSelectingFieldGradient] = useState<boolean>(true)

    const fetchBagOffline = async (file: File) => {
        if (!file.name.includes('.beex')) {
            setLoadedFullTrail(loadStage.LOADING)
            alert('Please only upload a .beex file.')
            return
        }
        setLoading(true)
        const metadata = await file.slice(-255).text()
        console.log('.beex metadata', metadata)
        // if (!metadata.includes('BEEX')) {
        //   alert('File is corrupted. Try another file.')
        //   return
        // }
        try {
            await rosbag
                .openFile(file.slice(0, -255))
                .then(() => {
                    //200 cos playback speed is 200, * 1000 to convert to ms
                    const loadTime = (rosbag.totalDuration / 130.0) * 1000
                    setTimeout(() => {
                        //Set a transparent loading screen here
                        setLoadedFullTrail(loadStage.FULLTRAIL)
                        getSnapshots(selectedAsset, json.date)
                    }, loadTime)
                    setLoading(false)

                    setTotalTime(rosbag.totalDuration)
                    const json = JSON.parse(
                        metadata.slice(5).replace(/\0/g, '')
                    )
                    // setAsset(regexAssetName(json.asset))
                    setDate(json.date)
                    setFilename(file.name)
                    setBagName(`${file.name}`)

                    if (json.origin) {
                        rosbag.origin = json.origin
                    }
                })
                .then(() => {
                    const topics: PanelType[] = []
                    const allPanelRenderingTopics =
                        Object.keys(topic2PanelTypeDict)
                    rosbag.getTopics().forEach((topic) => {
                        if (allPanelRenderingTopics.includes(topic))
                            topics.push(topic2PanelTypeDict[topic])
                    })
                    setAvailablePanelTypes(topics)
                    setHaveMaybeMissingCSVFields(rosbag.checkMaybeMissingCSVFieldsExist())
                    setHasSVY21Option(rosbag.checkAnyValidSVY21())
                })
        } catch (error) {
            console.error(error)
            alert('File is corrupted . Please contact BeeX.')
            return
        }
    }
    const getSnapshots = async (asset: string, date: string) => {
        if (asset == '' || date == '' || selectedAccount == '') return
        try {
            // assume that asset contains the company name
            const company = selectedAccount
            const blob = await getBlob(
                instance,
                account,
                company,
                asset,
                `${date}.json`,
                date,
                true
            )
            console.log(blob)
            if (blob) {
                if (blob.markers) {
                    setMarkerList(
                        blob.markers.filter((marker: MarkerType) => {
                            return rosbag.isTimestampInBag(
                                timestampToTime(marker.timestamp)
                            )
                        })
                    )
                    console.log(
                        blob.markers.filter((marker: MarkerType) => {
                            return rosbag.isTimestampInBag(
                                timestampToTime(marker.timestamp)
                            )
                        })
                    )
                } else {
                    setMarkerList([])
                }
            }
            // } else {
            //   const containers = await getContainers(instance, account, asset)
            //   if (containers.includes(date)) {
            //     const json = { id: date, markers: [] }
            //     await uploadBlob(
            //       instance,
            //       account,
            //       asset,
            //       date,
            //       `${date}.json`,
            //       JSON.stringify(json)
            //     )
            //     getSnapshots(asset, date)
            //   } else {
            //     alert('Container ' + date + ' does not exist')
            //   }
            // }
        } catch (e) {
            console.log(e)
            const statusCode = String(e).split(':')[1].trim()
            const statusMessage = String(e).split(':')[2]
            if (statusCode == '403') {
                alert('You do not have access to this storage account')
                setLoadedFullTrail(loadStage.LOADING)
            } else if (statusCode == '404') {
                if ([
                    'The specified blob does not exist.',
                    'The specified container does not exist.'
                ].includes(statusMessage)) {
                    setMarkerList([])
                } else {
                    alert('Something went wrong. Do try again later!')
                }
                setLoadedFullTrail(loadStage.LOADING)
            }
        }
    }
    const uploadSnapshots = async (markerList: MarkerType[], id: string) => {
        // Upload marker json
        await uploadMarkerList(markerList)
        // Upload front cam image
        const frontCamImg = frontCamRef.current?.src
        if (frontCamImg && frontCamImg !== '') {
            await uploadBlob(
                instance,
                account,
                selectedAccount,
                selectedAsset,
                `${id}_front_cam.jpeg`,
                imgSrcToBuffer(frontCamImg),
                date
            )
        }
        // Upload front cam clahe image
        const frontCamClaheImg = frontCamClaheRef.current?.src
        if (frontCamClaheImg && frontCamClaheImg !== '') {
            await uploadBlob(
                instance,
                account,
                selectedAccount,
                selectedAsset,
                `${id}_front_cam_clahe.jpeg`,
                imgSrcToBuffer(frontCamClaheImg),
                date
            )
        }
        // Upload sonar image
        const sonarImg = sonarRef.current?.imgRef.current?.src
        if (sonarImg && sonarImg !== '') {
            await uploadBlob(
                instance,
                account,
                selectedAccount,
                selectedAsset,
                `${id}_sonar.jpeg`,
                imgSrcToBuffer(sonarImg),
                date
            )
        }
        // Upload full depth cam image
        const fullDepthCamImg = rosbag.getFullDepthCamImageUrl()
        if (fullDepthCamImg) {
            await uploadBlob(
                instance,
                account,
                selectedAccount,
                selectedAsset,
                `${id}_fulldepth_cam.jpeg`,
                imgSrcToBuffer(fullDepthCamImg),
                date
            )
        }
    }
    const deleteSnapshot = async (idx: number) => {
        if (!markerList) return
        const id = markerList[idx].bag_id
        await deleteBlob(
            instance,
            account,
            selectedAccount,
            selectedAsset,
            `${id}_front_cam.jpeg`,
            date
        )
        await deleteBlob(
            instance,
            account,
            selectedAccount,
            selectedAsset,
            `${id}_front_cam_clahe.jpeg`,
            date
        )
        await deleteBlob(
            instance,
            account,
            selectedAccount,
            selectedAsset,
            `${id}_sonar.jpeg`,
            date
        )
        const newMarkerList = markerList.filter((_, index) => index !== idx)
        setMarkerList(newMarkerList)
        await uploadMarkerList([], markerList[idx])
    }
    const uploadMarkerList = async (
        markers: MarkerType[],
        deleteMarker?: MarkerType
    ) => {
        let blob = undefined
        try {
            blob = await getBlob(
                instance,
                account,
                selectedAccount,
                selectedAsset,
                `${date}.json`,
                date,
                true
            )
        } catch (error) {
            blob = { id: date, markers: [], model: [] }
        }

        if (blob) {
            const originalMarkers = blob.markers ? [...blob.markers] : []
            for (let i = 0; i < markers.length; i++) {
                for (let j = 0; j < originalMarkers.length; j++) {
                    if (markers[i].timestamp === originalMarkers[j].timestamp) {
                        originalMarkers.splice(j, 1)
                        break
                    }
                }
            }
            if (deleteMarker) {
                for (let j = 0; j < originalMarkers.length; j++) {
                    if (
                        deleteMarker.timestamp === originalMarkers[j].timestamp
                    ) {
                        originalMarkers.splice(j, 1)
                        break
                    }
                }
            }
            const finalMarkers = originalMarkers
                .concat(markers)
                .sort((a, b) => a.timestamp - b.timestamp)
            const json = { ...blob, markers: finalMarkers }
            await uploadBlob(
                instance,
                account,
                selectedAccount,
                selectedAsset,
                `${date}.json`,
                JSON.stringify(json),
                date
            )
        }
    }
    const dropHandler = (e: React.DragEvent) => {
        e.preventDefault()
        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            if (e.dataTransfer.items.length > 1) {
                alert('Please only choose 1 file.')
                return
            }
            if (e.dataTransfer.items[0].kind === 'file') {
                var file = e.dataTransfer.items[0].getAsFile()
                if (file) {
                    setLoadedFullTrail(loadStage.OPENFILE)
                    fetchBagOffline(file)
                } else {
                    alert('File does not exist.')
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            if (e.dataTransfer.files.length > 1) {
                alert('Please only choose 1 file.')
                return
            } else {
                setLoadedFullTrail(loadStage.OPENFILE)
                fetchBagOffline(e.dataTransfer.files[0])
            }
        }
    }
    const handleSeekTimestamp = (value: number) => {
        const arr = String(value).split('.')
        const isStart = Number(arr[0]) === 0 ? true : false
        rosbag.seekTimestamp(
            { sec: Number(arr[0]), nsec: Number(arr[1]) },
            isStart
        )
    }
    const handleSeek = (value: number) => {
        rosbag.seek({ sec: value, nsec: 0 })
    }
    const createSnapshot = async (name: string) => {
        if (!markerList) return
        try {
            const newMarker = rosbag.getMarker(name)
            const existingMarkers = markerList.filter(
                (marker) => marker.bag_id === newMarker.bag_id
            )
            if (existingMarkers.length >= 1) {
                setSnackbarMessage(
                    `Snapshot ${existingMarkers[0].name} with the same timestamp already exists!`
                )
                setShowSnackbar(false)
                setShowSnackbar(true)
                return
            }
            const newArray = [...markerList, newMarker]
            newArray.sort(compareMarkers)
            await uploadSnapshots(newArray, newMarker.bag_id)
            setMarkerList(newArray)
            setSnackbarMessage('Snapshot successfully created!')
            setShowSnackbar(true)
        } catch (error) {
            console.log(error)
            const errorMessage = String(error).split(':')[2].trim()
            setSnackbarMessage(errorMessage)
            setShowSnackbar(true)
        }
    }

    const downloadCSVFile = async () => {
        const blob = rosbag.getCSVDataBlob({
            timeZone: timeZone,
            selectedCoordSystemOptionIdx: selectedCoordSystemOptionIdx,
            selectingCoordinate: selectingCoordinate,
            selectingDepth: selectingDepth,
            selectingAltitude: selectingAltitude,
            selectingVelocity: selectingVelocity,
            selectingHeading: selectingHeading,
            selectingPitch: selectingPitch,
            selectingRoll: selectingRoll,
            selectingCp: haveMaybeMissingCSVFields.cp ? selectingCp : undefined,
            selectingUt: haveMaybeMissingCSVFields.ut ? selectingUt : undefined,
            selectingContact: haveMaybeMissingCSVFields.contactAndFg ?
                selectingContact : undefined,
            selectingProximity: haveMaybeMissingCSVFields.proximity ?
                selectingProximity : undefined,
            selectingFieldGradient: haveMaybeMissingCSVFields.contactAndFg ?
                selectingFieldGradient : undefined,
        })
        blob && saveAs(
            blob,
            filename.replace('.beex', '.csv') || 'beex_file_vehicle_data.csv'
        )
        setNotifyDownloadCSV(false)
    }

  useEffect(() => {
    rosbag.setRef(
      frontCamRef,
      frontCamClaheRef,
      sonarRef,
      fullDepthCamRef,
      playerBarRef,
      mapRef,
      infoPanelRef
    )
    const loadStorageAccounts = async () => {
      const accounts = await getStorageAccounts(instance, account)
      for (let storageAccount of STORAGE_NAME_TO_REMOVE) {
        const idx = accounts.indexOf(storageAccount)
        if (idx > -1) {
          accounts.splice(idx, 1)
        }
      }
      setStorageAccounts(accounts)
      if (accounts.length > 0) {
        setSelectedOneDriveAccount(accounts[0])
        setSelectedAccount(accounts[0])
      }
    }
    loadStorageAccounts()
  }, [])

  useEffect(() => {
    if (playableBeexFile !== undefined) fetchBagOffline(playableBeexFile)
  }, [playableBeexFile])

    const reset = (panelType: PanelType | null) => {
        switch (panelType) {
            case PanelType.FRONT_CAM:
                rosbag.resetFrontCamPanel(frontCamRef)
                break
            case PanelType.FRONT_CAM_CLAHE:
                rosbag.resetFrontCamClahePanel(frontCamClaheRef)
                break
            case PanelType.SONAR:
                rosbag.resetSonarGrid()
                break
            case PanelType.EXT_CAM:
                rosbag.resetFullDepthCamPlayer()
                break
        }
    }

    useEffect(() => reset(topPanelType), [topPanelType])
    useEffect(() => reset(bottomPanelType), [bottomPanelType])

    // useEffect(() => rosbag.resetFullDepthCamPlayer(), [isTopPanelExtCam, isBottomPanelExtCam])
    // useEffect(() => rosbag.resetSonarGrid(), [isTopPanelSonar, isBottomPanelSonar])

    useEffect(() => {
        if (availablePanelTypes.length > 0) {
            if (availablePanelTypes.includes(defaultTopPanelType))
                setTopPanelType(defaultTopPanelType)
            else setTopPanelType(availablePanelTypes[0])
        }
        if (availablePanelTypes.length > 1) {
            if (availablePanelTypes.includes(defaultBottomPanelType))
                setBottomPanelType(defaultBottomPanelType)
            else setBottomPanelType(availablePanelTypes[1])
        }
    }, [availablePanelTypes.length])

    useEffect(() => {
        const loadAssets = async () => {
            if (selectedAccount == '') return
            const assets = await getContainers(
                instance,
                account,
                selectedAccount
            )
            const updateAssets = assets.filter(
                (asset: string) => !ASSET_NAME_TO_REMOVE.includes(asset)
            )
            setAssets(updateAssets)

            if (updateAssets.length > 0) setSelectedAsset(updateAssets[0])
        }
        loadAssets()
    }, [selectedAccount])

    useEffect(() => {
        getSnapshots(selectedAsset, date)
    }, [selectedAsset])

    useEffect(() => {
        const loadFolderFiles = async () => {
            // To get share
            // account && console.log(await getSharedOneDriveFolders(instance, account))
            try {
                const blob = await getBlob(
                    instance,
                    account,
                    selectedOneDriveAccount,
                    'utils',
                    'onedrive.json'
                )
                let folderContent = []
                try {
                    folderContent =
                        account &&
                        (await getOneDriveFolderContent(
                            instance,
                            account,
                            blob.drive_id,
                            blob.folder_id
                        ))
                } catch (error) {
                    console.log(error)
                    if (blob.other_folder_id) {
                        for (let folderName in blob.other_folder_id) {
                            const folderId = blob.other_folder_id[folderName]
                            folderContent.push({
                                id: folderId,
                                folder: {},
                                name: folderName,
                                parentReference: { driveId: blob.drive_id },
                            })
                        }
                    }
                }
                setSelectedFolderFiles(folderContent)
                setSelectedFolder({
                    name: selectedOneDriveAccount,
                    folder_id: blob.folder_id,
                    drive_id: blob.drive_id,
                })
                setFolderDirectory([
                    {
                        name: selectedOneDriveAccount,
                        folder_id: blob.folder_id,
                        drive_id: blob.drive_id,
                    },
                ])
            } catch (error) {
                console.log(error)
                setFolderDirectory([
                    {
                        name: selectedOneDriveAccount,
                        folder_id: '',
                        drive_id: '',
                    },
                ])
                setSelectedFolderFiles([])
            }
        }
        loadFolderFiles()
    }, [selectedOneDriveAccount])

    useEffect(() => {
        const loadFolderFiles = async () => {
            const folderContent =
                account &&
                selectedFolder &&
                (await getOneDriveFolderContent(
                    instance,
                    account,
                    selectedFolder.drive_id,
                    selectedFolder.folder_id
                ))
            setSelectedFolderFiles(folderContent)

            // Check if folder exists in folder directory, otherwise add
            let folderIdx: number | undefined = undefined
            folderDirectory.forEach((folder, idx) => {
                if (folder.folder_id === selectedFolder!.folder_id)
                    folderIdx = idx
            })
            folderIdx != undefined
                ? setFolderDirectory(folderDirectory.slice(0, folderIdx + 1))
                : selectedFolder &&
                  setFolderDirectory(
                      folderDirectory.concat({
                          name: selectedFolder.name,
                          folder_id: selectedFolder.folder_id,
                          drive_id: selectedFolder.drive_id,
                      })
                  )
        }
        loadFolderFiles()
    }, [selectedFolder])

    const remainingPanelTypes = availablePanelTypes.filter(
        (type) => type !== topPanelType && type !== bottomPanelType
    )
    const hasRemainingPanel = remainingPanelTypes.length > 0

    const panelTypesMaxStrLength = Math.max(
        ...availablePanelTypes.map((type) => type.length)
    )
    const dropdownWidthInPx = (panelTypesMaxStrLength * 34) / 3

    const getDropdownMenu = (
        selectedOption: PanelType,
        setSelectedOption: (
            value: React.SetStateAction<PanelType | null>
        ) => void,
        hidden: boolean
    ) => (
        <Dropdown
            list={remainingPanelTypes}
            selected={selectedOption}
            select={(type: string) => setSelectedOption(str2PanelType(type))}
            isMinimal={true}
            className={`player-panel-and-dropdown top-0 right-0 z-4 h-56px 
      ${hidden ? 'hide' : ''}`}
            width={`${dropdownWidthInPx}px`}
        />
    )

    const frontCamPanel = <FrontCamPanel ref={frontCamRef} />
    const frontCamClahePanel = <FrontCamClahePanel ref={frontCamClaheRef} />
    const sonarPanel = (
        <SonarImage
            imgClassName='bg-black-6 rounded-sm w-100 h-100 overflow-hidden rel'
            ref={sonarRef}
        />
    )
    const extCamPanel = (
        <canvas
            className='w-100 h-100 object-fit-contain'
            ref={fullDepthCamRef}
        />
    )

    const getPanel = (panelType: PanelType) => {
        switch (panelType) {
            case PanelType.FRONT_CAM:
                return frontCamPanel
            case PanelType.FRONT_CAM_CLAHE:
                return frontCamClahePanel
            case PanelType.SONAR:
                return sonarPanel
            case PanelType.EXT_CAM:
                return extCamPanel
            default:
                return <></>
        }
    }

    return (
        <div className='video-page'>
            <div
                style={{
                width: '100%',
                height: 'calc(100% - 42px)',
                position: 'relative',
                }}
                onDrop={dropHandler}
                onDragOver={(e) => e.preventDefault()}>
                {((loadedfullTrail === loadStage.OPENFILE && !loading) ||
                storageAccounts.length === 0) && (
                <div
                    className='div-flex-center-full z-10'
                    style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: 'rgba(16, 16, 16, 0.5)',
                    }}>
                    <LoadingSpinner width={80} height={80} />
                </div>
                )}
                {!loading && totalTime === 0 && (
                <>
                    {selectedOneDriveAccount && (
                    <div className='flex align-content-center justify-center padding-md w-100 h-100 border-box'>
                        <FileUploadDialog loadFile={fetchBagOffline} type={'video'} />
                        <FileDirectoryDialog
                        stgAccs={storageAccounts}
                        dir={folderDirectory}
                        list={selectedFolderFiles}
                        selectAccount={setSelectedOneDriveAccount}
                        selectFolder={setSelectedFolder}
                        />
                    </div>
                    )}
                </>
                )}
                {showSnackbar && (
                <Snackbar
                    text={snackbarMessage}
                    onClose={() => {
                    setShowSnackbar(false)
                    }}
                />
                )}
                {loading && (
                <div className='div-flex-center-full'>
                    <LoadingSpinner width={80} height={80} />
                </div>
                )}
                {!loading && totalTime !== 0 && (<>
                    <div style={{
                        width: '140px',
                        height: '100%',
                        float: 'left',
                        padding: '10px',
                        boxSizing: 'border-box',
                    }}>
                        <InfoPanel
                            ref={infoPanelRef}
                            downloadCSVFile={() => {
                                rosbag.pause()
                                playerBarRef.current?.setIsPlaying(false)
                                setNotifyDownloadCSV(true)
                            }}
                        />
                    </div>
                    <div style={{
                        width: 'calc(100% - 140px)',
                        height: '100%',
                        float: 'left',
                        position: 'relative',
                    }}>
                        {topPanelType && 
                            <div
                                className={`player-panel-and-dropdown top-0
                                    ${topPanelExpanded ? 'h-100 z-11' : 'h-50'}`}
                                style={{width: `${topPanelExpanded ? '100%' : 'calc(50% - 10px)'}`}}
                                onMouseEnter={() => setShowTopDropdown(true)}
                                onMouseLeave={() => setShowTopDropdown(false)}
                            >
                                {getPanel(topPanelType)}
                                {hasRemainingPanel && getDropdownMenu(
                                    topPanelType,
                                    setTopPanelType,
                                    !showTopDropdown
                                )}
                                <div className={`abs right-12px
                                    ${topPanelExpanded ? 'bottom-20px' : 'bottom-12px'}
                                `}>
                                    <Button
                                        Icon={<FullscreenIcon
                                            width={topFullScreenIconSize}
                                            height={topFullScreenIconSize}
                                        />}
                                        onClick={() => {setTopPanelExpanded(prev => !prev)}}
                                    />
                                </div>
                            </div>
                        }
                        {bottomPanelType &&
                            <div
                                className={`player-panel-and-dropdown bottom-0
                                    ${bottomPanelExpanded ? 'h-100 z-11' : 'h-50'}`}
                                style={{width: `${bottomPanelExpanded ? '100%' : 'calc(50% - 10px)'}`}}
                                onMouseEnter={() => setShowBottomDropdown(true)}
                                onMouseLeave={() => setShowBottomDropdown(false)}
                            >
                                {getPanel(bottomPanelType)}
                                {hasRemainingPanel && getDropdownMenu(
                                    bottomPanelType,
                                    setBottomPanelType,
                                    !showBottomDropdown
                                )}
                                <div className={`abs right-12px
                                    ${bottomPanelExpanded ? 'bottom-20px' : 'bottom-12px'}`}
                                >
                                    <Button
                                        Icon={<FullscreenIcon
                                            width={bottomFullScreenIconSize}
                                            height={bottomFullScreenIconSize}
                                        />}
                                        onClick={() => {setBottomPanelExpanded(prev => !prev)}}
                                    />
                                </div>
                            </div>
                        }
                        <div className='hide'>
                            {remainingPanelTypes.map((panelType) => (getPanel(panelType)))}
                        </div>
                        <div style={{
                            width: mapPanelExpanded ? '100%' : 'calc(50% + 10px)',
                            height: '100%',
                            float: 'right',
                            zIndex: mapPanelExpanded ? 11 : undefined,
                        }}>
                            <MapContainer
                                sonarRef={sonarRef}
                                mapRef={mapRef}
                                models={null}
                                asset={selectedAsset}
                                fullscreen={mapPanelExpanded}
                                setFullscreen={setMapPanelExpanded}
                            />
                        </div>
                    </div>
                </>)}
            </div>
            <SnapshotTimeline
                playerBarRef={playerBarRef}
                list={markerList}
                storageAccounts={storageAccounts}
                assets={assets}
                selectedAsset={selectedAsset}
                date={date}
                selectedAccount={selectedAccount}
                bagStartTime={rosbag.getStartTime()}
                seekTimestamp={handleSeekTimestamp}
                createSnapshot={createSnapshot}
                deleteSnapshot={deleteSnapshot}
                setTimelineRevealed={setTimelineRevealed}
                setSelectedAccount={setSelectedAccount}
                setSelectedAsset={setSelectedAsset}
            />
            <PlayerBar
                ref={playerBarRef}
                duration={totalTime}
                timelineRevealed={timelineRevealed}
                seek={handleSeek}
                play={() => rosbag.play()}
                pause={() => rosbag.pause()}
                changeSpeed={(value) => rosbag.changeSpeed(value)}
                beexStartTime={beexStartTime}
                beexEndTime={beexEndTime}
                bagStartTime={rosbag.getStartTime()}
            />
            {notifyDownloadCSV && (
                <Dialog
                    text={`Specify the options that you want for the CSV file.`}
                    buttonText={`Download`}
                    onClick={downloadCSVFile}
                    onClose={() => {
                        setNotifyDownloadCSV(false)
                    }}
                    boxClassName={'w-40'}
                >
                    <CSVExportDialog
                        timeZone={timeZone}
                        setTimeZone={setTimeZone}
                        coordSystemOptions={coordSystemOptions}
                        selectedCoordSystemOptionIdx={selectedCoordSystemOptionIdx}
                        setSelectedCoordSystemOptionIdx={setSelectedCoordSystemOptionIdx}
                        selectingCoordinate={selectingCoordinate}
                        setSelectingCoordinate={setSelectingCoordinate}
                        selectingHeading={selectingHeading}
                        setSelectingHeading={setSelectingHeading}
                        selectingPitch={selectingPitch}
                        setSelectingPitch={setSelectingPitch}
                        selectingRoll={selectingRoll}
                        setSelectingRoll={setSelectingRoll}
                        selectingDepth={selectingDepth}
                        setSelectingDepth={setSelectingDepth}
                        selectingAltitude={selectingAltitude}
                        setSelectingAltitude={setSelectingAltitude}
                        selectingVelocity={selectingVelocity}
                        setSelectingVelocity={setSelectingVelocity}
                        // Maybe-missing fields
                        selectingCp={selectingCp}
                        setSelectingCp={haveMaybeMissingCSVFields.cp ? setSelectingCp : undefined}
                        selectingUt={selectingUt}
                        setSelectingUt={haveMaybeMissingCSVFields.ut ? setSelectingUt : undefined}
                        selectingContact={selectingContact}
                        setSelectingContact={haveMaybeMissingCSVFields.contactAndFg ? setSelectingContact : undefined}
                        selectingProximity={selectingProximity}
                        setSelectingProximity={haveMaybeMissingCSVFields.proximity ? setSelectingProximity : undefined}
                        selectingFieldGradient={selectingFieldGradient}
                        setSelectingFieldGradient={haveMaybeMissingCSVFields.contactAndFg ? setSelectingFieldGradient : undefined}
                    />
                </Dialog>
            )}
        </div>
    )
}

export default VideoPage
