import { Vector3 } from 'three'

export type MsFolder = {
    name: string
    folder_id: string
    drive_id: string
}

// Hashtags
export enum HashtagType {
    STRUCTURE,
    COMPONENT,
}

export type Hashtag = {
    type: HashtagType
    content: string
}

export type AngleRange = {
    min: number
    max: number
}

export enum PageType {
    HOME,
    REPORT,
    VIDEO,
    MISSION,
    USER_MANUAL,
    PCD,
}

export type MarkerContainerPairType = {
    folder: string
    marker?: MarkerType
    model?: ModelType
}

export type PDFReportImageType = {
    idx: number
    img_type: string
    url: string
}

export type MarkerType = {
    list_idx?: number
    name: string
    bag_id: string
    timestamp: number
    origin: {
        northing: number
        easting: number
        zone_letter?: string
        zone_number?: number
        zone?: string
    }
    position: { x: number; y: number; z: number }
    heading: number
    altitude: number
    comment?: string
    no_anode: boolean
    sonar_info: SonarInfo
    ut?: number | null
    cp?: number | null
    str_seacp?: number[]
    pec?: {
        thickness: number
        calibration: number
    }
    offsets?: {
        x: number
        y: number
        mcd: number
    }
    recording?: string
}

export type SonarInfo = {
    width: number
    height: number
    fov_max: number
    max_range: number
    range_resol: number
}

export type DateType = {
    day: string
    month: string
    year: string
}

export type ModelType = {
    id: string
    asset: string
    file: string
    latitude?: number
    zoom?: number
    longitude?: number
    width?: number
    height?: number
    depth: number
    heading: number
    scale?: number
    rotateX?: number
    rotateY?: number
    easting?: number
    northing?: number
    zone_letter?: string
    zone_number?: number
    date?: string
    size?: number[]
    materialSize?: number
    title?: string
    comment: string
    start_time?: number
    end_time?: number
    onedrive_path?: string
    offsets?: {
        x: number
        y: number
        mcd: number
    }
}

export type ThumbnailType = {
    sonarImage: () => Promise<string>
    frontCamImage: () => Promise<string>
    frontCamClaheImage: () => Promise<string>
    fullDepthCamImage: () => Promise<string>
}

//For Logger
export enum Activities {
    ACCESS,
    OFFSTORAGE,
    EDITSNAPSHOT,
    EDITOFFSETS,
    REMOVEOFFSETS,
    OPENSNAPSHOT,
    DELETESNAPSHOT,
    ACCESSMANUAL,
    DOWNLOADMANUAL,
}

export type ResourceLog = {
    loginTime: string
    logoutTime?: string
    timeSpent?: string
    isLogged: boolean
    storage?: string
    container?: string
}

export enum UserManualType {
    AIKANBILIS,
    TOPSIDEBOX,
    SAMBALUI,
    SAMBALPORTAL,
}

export type ActivityType = {
    timestamp: string
    user: string
    action: string
    folder?: string
    resourcelog?: ResourceLog
    marker?: {
        bag_id: string
        prevName?: string
        editedName?: string
        prevComment?: string
        editedComment?: string
        prevHashtags?: Hashtag[]
        editedHashtags?: Hashtag[]
        prevFlagStatus?: boolean
        newFlagStatus?: boolean
    }
    model?: {
        fileName: string
        prevName?: string
        editedName?: string
        prevComment?: string
        editedComment?: string
        prevHashtags?: Hashtag[]
        editedHashtags?: Hashtag[]
    }
    origin?: {
        prevNorthing?: number
        editedNorthing?: number
        prevEasting?: number
        editedEasting?: number
        prevMCD?: number
        editedMCD?: number
        prevXOffset?: number
        editedXOffset?: number
        prevYOffset?: number
        editedYOffset?: number
        prevMCDOffset?: number
        editedMCDOffset?: number
    }
    manual?: string
}

// export type ThumbnailType = {
//   sonarImage: string | undefined;
//   frontCamImage: string | undefined;
//   frontCamClaheImage: string | undefined;
// };

// MOUSE
export enum MouseClick {
    LEFT,
    MIDDLE,
    RIGHT,
}

export enum MouseState {
    DEFAULT,
    SELECTION,
    GRAB,
    GRABBING,
    RULER,
    MULTIRULER,
    TRANSFORM,
}

export type MouseType = {
    moved: boolean
    down: boolean
    startPos: Vector3
    endPos: Vector3
    type: MouseClick
    state: MouseState
}

export const mouseTypeInit = {
    moved: false,
    down: false,
    type: MouseClick.LEFT,
    state: MouseState.DEFAULT,
    startPos: new Vector3(),
    endPos: new Vector3(),
}

// Map
export enum MapMode {
    MAP,
    MISSION,
    __LENGTH,
}

export enum CameraMode {
    STATIONARY,
    FOLLOW,
    __LENGTH,
}

export enum Dimension {
    TWOD,
    THREED,
    __LENGTH,
}

export enum ViewOrientation {
    SIDE,
    TOP,
    FRONT,
    __LENGTH,
}

export enum Quality {
    LOW, // quartered points
    MEDIUM, // halved points
    HIGH, // normal points
}

export enum ColorMap {
    NONE,
    RAINBOW,
    COOLTOWARM,
    BLACKBODY,
    GRAYSCALE,
    __LENGTH,
}

export type UTMCoord = {
    northing: number
    easting: number
    zone_letter?: string
    zone_number?: number
    zone?: string // In case no zone_letter and zone_number
}

export type LatLngCoord = {
    latitude: number
    longitude: number
    zoom: number
}

export type SceneCoord = {
    x: number
    y: number
}

export enum VelocityDirection {
    UPWARD,
    DOWN,
    RIGHT,
    LEFT,
}

export enum Status {
    SUCCESS,
    ERROR,
}

export enum FileFormat {
    PDF,
    DOCX,
}

export type PoseData = {
    x: number
    y: number
    depth: number
    altitude: number
    heading: number
    easting: number
    northing: number
    zone_letter: string
    zone_number: number
    velocity: number
    velocity_direction: string
    pitch: number
    roll: number
}

export const LENGTH_UNITS = ['m', 'cm', 'ft', 'in']

export const METER_IN_OTHER_UNITS = new Map(
    [1, 100, 3.28084, 39.3701].map(
        (val, idx) => [LENGTH_UNITS[idx], val] as [string, number]
    )
)
//   [
//   ['m', 1],
//   ['cm', 100],
//   ['ft', 3.28084],
//   ['in', 39.3701]
// ])

function convertTZ(date: Date, timeZone: string) {
    return new Date(date.toLocaleString('en-US', { timeZone: timeZone }))
}

export function timestampToDate(
    timestamp: number,
    timeZone: string | undefined = undefined
) {
    const localDate = new Date(timestamp * 1000)
    const date = timeZone !== undefined ? convertTZ(localDate, timeZone) : localDate
    const year = date.getFullYear()
    const month = ('0' + Number(date.getMonth() + 1)).substr(-2) // Returns 0-11 like what
    const day = ('0' + date.getDate()).substr(-2)
    return `${year}-${month}-${day}`
}

export function timestampToHMS(
    timestamp: number,
    timeZone: string | undefined = undefined,
    includeMS: boolean = false
) {
    const localDate = new Date(timestamp * 1000)
    const date = timeZone !== undefined ? convertTZ(localDate, timeZone) : localDate
    const hours = date.getHours()
    const mins = ('0' + date.getMinutes()).substr(-2)
    const secs = ('0' + date.getSeconds()).substr(-2)
    const ms = Math.floor((timestamp - Math.floor(timestamp)) * 1000)
    return includeMS ? `${hours}:${mins}:${secs}.${ms}` : `${hours}:${mins}:${secs}`
}
export const MTHS_NAME = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
export function timestampToMonthName(timestamp: number) {
    const date = new Date(timestamp * 1000)
    const month = date.getMonth() // Returns 0-11 like what
    return MTHS_NAME[month]
}

export function timestampToDateWithTime(timestamp: number) {
    const date = new Date(timestamp * 1000)
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).substr(-2) // Returns 0-11 like what
    const day = ('0' + date.getDate()).substr(-2)
    const hours = ('0' + date.getHours()).substr(-2)
    const mins = ('0' + date.getMinutes()).substr(-2)
    const secs = ('0' + date.getSeconds()).substr(-2)
    return `${year}-${month}-${day}--${hours}-${mins}-${secs}`
}

export function imgSrcToBuffer(src: string) {
    const base64Data = src.replace(/^data:image\/jpeg;base64,/, '')
    return Buffer.from(base64Data, 'base64')
}

export enum PanelType {
    FRONT_CAM = 'Front Camera',
    FRONT_CAM_CLAHE = 'Front Camera Filtered',
    SONAR = 'Sonar',
    SONAR_CLAHE = 'Sonar Filtered',
    PROFILING_SONAR = 'Profiling Sonar',
    EXT_CAM = 'External Camera',
}

export const str2PanelType = (str: string) => {
    switch (str) {
        case 'Front Camera':
            return PanelType.FRONT_CAM
        case 'Front Camera Filtered':
            return PanelType.FRONT_CAM_CLAHE
        case 'Sonar':
            return PanelType.SONAR
        case 'Sonar Filtered':
            return PanelType.SONAR_CLAHE
        case 'Profiling Sonar':
            return PanelType.PROFILING_SONAR
        case 'External Camera':
            return PanelType.EXT_CAM
        default:
            throw new Error('Invalid panel type')
    }
}

export const expandablePanelTypes = [
    PanelType.FRONT_CAM,
    PanelType.FRONT_CAM_CLAHE,
    PanelType.EXT_CAM,
]
