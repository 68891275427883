import React from 'react'
import { useMsal } from '@azure/msal-react'
import TextButton from '../elements/buttons/TextButton'
import BEEX_LOGO from '../../assets/images/logo_white_medium.png'
import { isMobile } from '../utils/general'

type TopbarProps = {
  bagName: string
  setPage: () => void
  setBagName: (name: string) => void
}

const Topbar = ({ bagName, setPage, setBagName }: TopbarProps) => {
  const mobile = isMobile()
  const { instance, accounts } = useMsal()
  const username = accounts.length > 0 ? accounts[0].username : 'demo@beex.sg'
  return (
    <div
      className={`background w-100 flex justify-between align-content-center 
        align-center border-box ${mobile ? '' : 'padding-w-lg'}`}
      style={{ flex: '0 0 64px' }}>
      <div
        className={`padding-w-lg padding-h-md border-box cursor-pointer`}
        style={{ height: '64px' }}
        onClick={() => {
          setPage()
          setBagName('')
        }}>
        <img src={BEEX_LOGO} className={`w-auto h-100`} />
      </div>
      <div className='h2'>{bagName}</div>
      <TextButton
        text={username}
        onClick={() => {
          if (instance) instance.logout()
        }}
      />
    </div>
  )
}

export default Topbar
