import React, { useContext, useEffect, useRef, useState } from 'react'
import { ModelType, Hashtag, HashtagType, MarkerType, PageType } from '../../../../types'
import TextButton from '../../../elements/buttons/TextButton'
import FilledTonalButton from '../../../elements/buttons/FilledTonalButton'
import DownloadIcon from '../../../../assets/icons/file_download_black_24dp.svg'
import CancelIcon from '../../../../assets/icons/cancel_black_24dp.svg'
import PlayIcon from '../../../../assets/icons/play_arrow_black_24dp.svg'
import LoadingSpinner from '../../../elements/loadingSpinner'
import IconButton from '../../../elements/buttons/IconButton'
import HashtagInput from '../../../elements/tags/hashtag'
import TextBox from '../../../elements/inputs/TextBox'
import { MarkerDetailsRow } from './components/MarkerDetails'
import { utmToLatLng } from '../../../map/utils/CoordHelper/CoordUtils'
import { SVY21, svy21NumOfDP } from '../../../map/utils/CoordHelper/svy21'
import { roundValue } from '../../../utils'
import SegmentedButton from '../../../elements/buttons/SegmentedButton'
import MapPanel from './MapPanel'
import { getBlob } from '../../../../backend'
import { useAccount, useMsal } from '@azure/msal-react'
import { AppContext } from '../../../../store/context'
import { getOneDriveFolderContent } from '../../../../backend/msal/fetch'
import { AccountInfo } from '@azure/msal-browser'
import ProgressBar from '../../../ProgressBar'

type PCDRightPanelProps = {
  model: ModelType
  hashtags: Hashtag[]
  edited: boolean
  setEdited: (edited: boolean) => void
  saveEdit: (newModel: ModelType, newHashtagList: Hashtag[]) => void
  getModelNameFromFileName: (value: string) => string
  setPlayableBeexFile?: (file: File) => void
  playableBeexFile?: File
  setBeexStartTime?: (time: number | undefined) => void
  beexStartTime?: number
  setBeexEndTime?: (time: number | undefined) => void
  beexEndTime?: number
  startPlayingBeexFile?: () => void
  noPlayingOfBeexFile?: boolean
  allHashtags: Hashtag[]
  saveAllHashtags?: (
    newHashtagList: Hashtag[],
    hashtagsEdited?: { old: Hashtag; new: Hashtag }[]
  ) => Promise<void>
  downloadModels?: () => void
  setHashtagEdited?: () => void
  otherExpanded?: boolean
}

const PCDRightPanel = ({
  model,
  hashtags,
  edited,
  setEdited,
  saveEdit,
  getModelNameFromFileName,
  setPlayableBeexFile,
  playableBeexFile,
  setBeexStartTime,
  beexStartTime,
  setBeexEndTime,
  beexEndTime,
  startPlayingBeexFile,
  noPlayingOfBeexFile,
  allHashtags,
  saveAllHashtags,
  downloadModels,
  setHashtagEdited,
  otherExpanded,
}: PCDRightPanelProps) => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const { state } = useContext(AppContext)
  const { msal } = state
  const [newModel, setNewModel] = useState<ModelType>(model)
  const [newHashtagList, setNewHashtagList] = useState<Hashtag[]>([])
  const [newHashtag, setNewHashtag] = useState<Hashtag | null>()
  const [addingNewHashtag, setAddingNewHashtag] = useState<Hashtag>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [componentStructureTags, setComponentStructureTags] = useState<
    Hashtag[]
  >([
    { type: HashtagType.STRUCTURE, content: '' },
    { type: HashtagType.COMPONENT, content: '' },
  ])
  const titleRef = useRef<HTMLInputElement>(null)
  //   let latLon = { latitude: 0, longitude: 0 }
  //   if (model && model.easting && model.northing) {
  //     latLon = toLatLon(
  //       model.easting + marker.position.y,
  //       model.northing + marker.position.x,
  //       48,
  //       'N'
  //     )
  //   }

  // Fetching of beex file
  const hasOnedriveBackLink = model.onedrive_path !== undefined
  const [loadedDriveItem, setLoadedDriveItem] = useState<any>(null)
  const loadedBeexFileUrl = loadedDriveItem ? loadedDriveItem['@microsoft.graph.downloadUrl'] : ''
  const isBeexFileUrlLoading = loadedBeexFileUrl.length === 0 && !playableBeexFile
  const [fetchingBeexFile, setFetchingBeexFile] = useState<boolean>(false)
  const [beexFileLoadingProgress, setBeexFileLoadingProgress] = useState<number>(0)
  const fetchControllerRef = useRef<AbortController | null>(null)
  const progressDisplayWidthPct = 95

  const modelHasHeadingNCoord = model.heading !== undefined && (
    (
      model.easting !== undefined && model.northing !== undefined &&
      model.zone_letter !== undefined && model.zone_number !== undefined
    ) ||
    (model.latitude !== undefined && model.longitude !== undefined)
  )

  const handleSaveAllHashtags = async (
    hashtags: Hashtag[],
    hashtagType: HashtagType,
    hashtagsEdited?: { old: Hashtag; new: Hashtag }[]
  ) => {
    let updatedTags: Hashtag[] = []
    const structureTags = allHashtags.filter(
      (x) => x.type === HashtagType.STRUCTURE
    )
    const componentTags = allHashtags.filter(
      (x) => x.type === HashtagType.COMPONENT
    )
    if (hashtagType === HashtagType.STRUCTURE) {
      updatedTags = componentTags.concat(hashtags)
    } else if (hashtagType === HashtagType.COMPONENT) {
      updatedTags = structureTags.concat(hashtags)
    }
    saveAllHashtags && saveAllHashtags(updatedTags, hashtagsEdited)
  }

  const handleSaveEdit = () => {
    if (!edited || !newModel) return
    const updatedHashtags = cleanHashtagArray(newHashtagList)
    setNewHashtagList(updatedHashtags)
    saveEdit(newModel, updatedHashtags)
  }
  const cleanHashtagArray = (arr: Hashtag[]) => {
    return [...new Set(arr.filter((ht) => ht.content !== ''))]
  }
  const handleCancelEdit = () => {
    setNewModel(model)
    setNewHashtag(null)
    setNewHashtagList(hashtags)
    if (titleRef.current?.value) {
      titleRef.current.value = ''
    }
    setEdited(false)
  }
  const handleUpdateModelName = (value: string) => {
    if (titleRef.current?.value) {
      updateModel(titleRef.current?.value, newModel.comment)
    }
    return
  }
  const handleUpdateModelComment = (value: string) => {
    if (titleRef.current?.value) {
      updateModel(titleRef.current.value, value)
    } else {
      model.title ? updateModel(model.title, value) : updateModel('', value)
    }
  }
  const updateModel = (name: string, comment: string) => {
    if (!newModel) return
    const updatedModel = { ...newModel }
    //updatedModel.file = convertEditedModelNameToFileName(name)
    updatedModel.title = name
    updatedModel.comment = comment
    setNewModel(updatedModel)
    setEdited(true)
  }
  const handleUpdateNewHashTag = (value: Hashtag) => {
    setErrorMessage('')
    if (value.content == '') {
      setNewHashtag(null)
      return
    }
    setNewHashtag(cleanHashtag(value))
    setEdited(true)
  }
  const handleDeleteHashtag = (idx: number) => {
    setErrorMessage('')
    const updatedHashtags = [...newHashtagList]
    updatedHashtags.splice(idx, 1)
    setNewHashtagList(updatedHashtags)
    setEdited(true)
  }
  const handleAddHashTag = (value: Hashtag | undefined) => {
    if (!value || value.content === '') {
      setErrorMessage('Please enter a valid hashtag')
      return
    }
    // if (checkRepeatedHashTag(value, newHashtagList)) {
    //   setErrorMessage('Hashtag already exists')
    //   return
    // }
    // if (newHashtagList.length >= 5) {
    //   setErrorMessage('Max hashtag limit reached')
    //   return
    // }
    setAddingNewHashtag(value)
    const updatedHashtags = [...newHashtagList]
    updatedHashtags.push(value)
    setNewHashtagList(updatedHashtags)
    setNewHashtag(null)
    setEdited(true)
  }
  const cleanHashtag = (hashtag: Hashtag) => {
    const newHashtagContent = hashtag.content
      .replace(' ', '')
      .replace(/[^\w\s]/gi, '')
      .toLowerCase()
    return { type: hashtag.type, content: newHashtagContent }
  }

  const getDriveItem = async (
    path: string,
  ) => {
    const blob = await getBlob(
      instance,
      account,
      msal.storage,
      'utils',
      'onedrive.json'
    )
    if (blob === null || account === null) return
    try {
      const file = await findDriveItemRecursively(
        path,
        account,
        blob
      )
      return file
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const findDriveItemRecursively = async (
    path: string,
    account: AccountInfo,
    blob: any
  ) => {
    let folderContent = []
    try {
      folderContent =
        account &&
        (await getOneDriveFolderContent(
          instance,
          account,
          blob.drive_id,
          blob.folder_id
        ))
    } catch (error) {
      console.log(error)
      if (blob.other_folder_id) {
        for (let folderName in blob.other_folder_id) {
          const folderId = blob.other_folder_id[folderName]
          folderContent.push({
            id: folderId,
            folder: {},
            name: folderName,
            parentReference: { driveId: blob.drive_id },
          })
        }
      }
    }
    const pathComponents = path.split('/')
    while (pathComponents.length > 0) {
      const folderName = pathComponents.shift()
      const file: any = folderContent.find(
        (item: any) => item.name === folderName
      )
      if (file) {
        if (pathComponents.length === 0) return file
        folderContent = await getOneDriveFolderContent(
          instance,
          account,
          file.parentReference.driveId,
          file.id
        )
      } else
        throw new Error('Folder not found')
    }
  }

  useEffect(() => {
    if (hasOnedriveBackLink)
      (async () => {
        const driveItem = await getDriveItem(model.onedrive_path!)
        if (driveItem === null) return
        setLoadedDriveItem(driveItem)
      })()
    setNewModel({ ...model })
    setNewHashtagList([...hashtags])
    return stopFetching // Abort the fetch request when the component unmounts
  }, []) // causing issues with MultiReportPanel while editing comment box
  useEffect(() => {
    if (errorMessage !== '') {
      setTimeout(() => setErrorMessage(''), 5000)
    }
  }, [errorMessage])

  const stopFetching = () => {
    if (fetchControllerRef.current) {
      fetchControllerRef.current.abort()
      fetchControllerRef.current = null
    }
    setFetchingBeexFile(false)
    setBeexFileLoadingProgress(0)
  }

  const startPlaying = () => {
    if (setBeexStartTime && !beexStartTime) setBeexStartTime(model.start_time)
    if (setBeexEndTime && !beexEndTime) setBeexEndTime(model.end_time)
    if (playableBeexFile) {
      if (startPlayingBeexFile) startPlayingBeexFile()
      setBeexFileLoadingProgress(100)
      return
    }
    const fetchController = new AbortController()
    fetchControllerRef.current = fetchController
    setFetchingBeexFile(true)
    fetch(loadedBeexFileUrl, {signal: fetchController.signal})
      .then(async (response) => {
        if (response.body !== null) {
          const contentLength = response.headers.get('content-length')
          if (contentLength === null) return
          const totalLength = parseInt(contentLength)
          const contentType = response.headers.get('content-type') || 'application/octet-stream'
          const reader = response.body.getReader()
          let receivedLength = 0
          const chunks = []
          while (true) {
            const { done, value: chunk } = await reader.read()
            if (done) break
            receivedLength += chunk.length
            chunks.push(chunk)
            setBeexFileLoadingProgress(receivedLength / totalLength * 100)
          }
          return chunks.map(chunk => new Blob([chunk], { type: contentType }))
        } else
          return [await response.blob()]
      }).then(blobs => {
        if (blobs === undefined || blobs.length === 0) return
        const file = new File(
          blobs, loadedDriveItem.name || 'dummy_string.beex', { type: blobs[0].type }
        )
        if (setPlayableBeexFile) setPlayableBeexFile(file)
        setFetchingBeexFile(false)
        if (startPlayingBeexFile) startPlayingBeexFile()
      }).catch((error: any) => {
        console.log(error)
        stopFetching()
      })
  }

  return (
    model && (
      <>
        <div className='flex w-100 margin-b-md justify-center align-center'>
          <input
            className={
              'border-bottom-1 border-blue h-40px background text-white flex-grow-1 w-0 text-lg text-bold rounded-none'
            }
            value={
              titleRef.current?.value && titleRef.current.value !== ''
                ? titleRef.current.value
                : model.title || getModelNameFromFileName(model.file)
            }
            ref={titleRef}
            onChange={(e) => handleUpdateModelName(e.target.value)}
          />
        </div>
        {model.id === 'glb' ?
          (<GLBModelDetails model={model} />) :
          (<div className={'w-100 allow-select flex flex-wrap'}>
            <div className='flex flex-basis-50 flex-grow-1 padding-'>
              <div className='on-background w-50'>
                {model.date && (
                  <span>
                    Date:
                    <br />
                  </span>
                )}
              </div>
              <div className='text-right text-bold w-50 padding-r-sm'>
                {model.date && (
                  <span>
                    {model.date}
                    <br />
                  </span>
                )}
              </div>
            </div>
            <div className='break-row' />
          </div>)
        }
        <div className={'flex align-center padding-h-sm'}>
          <span
            className='text-bold text-sm padding-r-sm'
            style={{ width: '100px' }}>
            Structure
          </span>
          <HashtagInput
            flexGrow
            type={HashtagType.STRUCTURE}
            id={model.file}
            tag={
              newHashtagList
                .filter((x) => x.type == HashtagType.STRUCTURE)
                .map((value) => value.content)[0] || ''
            }
            onEdit={() => setHashtagEdited && setHashtagEdited()}
          />
        </div>
        <div className={'flex align-center padding-h-sm'}>
          <span
            className='text-bold text-sm padding-r-sm'
            style={{ width: '100px' }}>
            Component
          </span>
          <HashtagInput
            flexGrow
            type={HashtagType.COMPONENT}
            id={model.file}
            tag={
              newHashtagList
                .filter((x) => x.type == HashtagType.COMPONENT)
                .map((value) => value.content)[0] || ''
            }
            onEdit={() => setHashtagEdited && setHashtagEdited()}
          />
        </div>
        <div className='flex justify-center align-center w-100 prompt-text'>
          {errorMessage}
        </div>
        <TextBox
          value={newModel.comment || ''}
          onChange={handleUpdateModelComment}
        />
        {['glb','obj'].includes(model.id) && modelHasHeadingNCoord ? (
          <GLBModelVehiclePositionPanel
            model={model}
            otherExpanded={otherExpanded || false}
          />
        ) : (<div className='break-row' />)}
        <div className={'margin-t-md flex flex-row justify-between w-100'}>
          <div className='flex'>
            {downloadModels && (
              <div className='rel'>
                <IconButton
                  Icon={DownloadIcon}
                  contentColor='on-surface-variant'
                  containerColor='surface-variant-bg'
                  onClick={downloadModels}
                  iconHeight={30}
                  iconWidth={30}
                />
              {hasOnedriveBackLink && <div
                className='text-sm abs bottom-0 margin-t-sm margin-l-sm'
              >.{model.file.split('.').pop()}</div>}
              </div>
            )}
            {hasOnedriveBackLink && <>
              <div className='rel'>
                <IconButton
                  className='z-1'
                  contentColor='on-surface-variant'
                  containerColor='surface-variant-bg'
                  onClick={() => window.open(loadedBeexFileUrl)}
                  Icon={isBeexFileUrlLoading ? <LoadingSpinner height={24} width={24} /> : DownloadIcon}
                  iconHeight={30}
                  iconWidth={30}
                  disabled={isBeexFileUrlLoading || fetchingBeexFile}
                />
                <div
                  className={`text-sm abs bottom-0 margin-t-sm margin-l-xsm
                    ${isBeexFileUrlLoading || fetchingBeexFile ? 'disabled-container' : ''}
                  `}
                >.{model.onedrive_path!.split('.').pop()}</div>
              </div>
              {model.onedrive_path!.endsWith('.beex') && !noPlayingOfBeexFile && <>
                <div className='flex justify-center align-center rel'>
                  <div
                    className={`w-${progressDisplayWidthPct} flex flex-col justify-start align-center abs`}
                    style={{bottom: '-22px'}}
                  >
                    {fetchingBeexFile && beexFileLoadingProgress < 100 && <>
                      <ProgressBar
                          width={`${progressDisplayWidthPct}%`}
                          height={5}
                          progressPercent={beexFileLoadingProgress}
                      />
                      <div
                        className={`w-${progressDisplayWidthPct} text-sm rel`}
                        style={{top: '-10px'}}
                      >{`${beexFileLoadingProgress.toFixed(2)}%`}</div>
                    </>}
                  </div>
                  <FilledTonalButton
                    text='Play'
                    onClick={startPlaying}
                    Icon={isBeexFileUrlLoading || fetchingBeexFile ?
                      <LoadingSpinner height={24} width={24} /> :
                      PlayIcon
                    }
                    iconHeight={24}
                    iconWidth={24}
                    disabled={isBeexFileUrlLoading || fetchingBeexFile}
                  />
                </div>
                {fetchingBeexFile && <IconButton
                  Icon={CancelIcon}
                  contentColor='error'
                  containerColor='error-bg'
                  onClick={stopFetching}
                  iconHeight={30}
                  iconWidth={30}
                />}
              </>}
            </>}
          </div>
          {edited && (
            <div className={'flex justify-end'}>
              <TextButton text='CANCEL' onClick={() => handleCancelEdit()} />
              <FilledTonalButton text='SAVE' onClick={() => handleSaveEdit()} />
            </div>
          )}
        </div>
      </>
    )
  )
}

export default PCDRightPanel

const GLBModelDetails = ({ model }: { model: ModelType }) => {
  const [usingSVY21, setUsingSVY21] = useState<boolean>(false)
  const [coord, setCoord] = useState<{
      latitude: number
      longitude: number
      northing: number
      easting: number
      hasValidSVY21: boolean
  } | null>(null)
  const cv = new SVY21()
  useEffect(() => {
      if (
        model.northing !== undefined &&
        model.easting !== undefined &&
        model.zone_letter &&
        model.zone_number
      ) {
        const utmCoord = {
          northing: model.northing,
          easting: model.easting,
          zone_letter: model.zone_letter,
          zone_number: model.zone_number,
        }
        const { latitude, longitude } = utmToLatLng(utmCoord)
        const { N: northing, E: easting } = cv.computeSVY21(
            latitude,
            longitude
        )
        setCoord({
            latitude,
            longitude,
            northing,
            easting,
            hasValidSVY21: cv.checkValidSVY21(latitude, longitude),
        })
      }
  }, [])

  return (
    <div className='flex flex-wrap align-start'>
      <div className='flex-grow-1'>
          {model.date && (
              <MarkerDetailsRow
                  desc={'Date'}
                  value={model.date}
              />
          )}
          {model.heading != null && (
              <MarkerDetailsRow
                  desc={'Heading'}
                  value={`${Math.round(model.heading)}\u00B0`}
              />
          )}
      </div>
      <div className='flex-grow-1 padding-r-sm'>
          {coord != null && (
              <MarkerDetailsRow
                  desc={usingSVY21 ? 'Northing' : 'Latitude'}
                  value={String(
                      roundValue(
                          usingSVY21
                              ? coord.northing!
                              : coord.latitude,
                          usingSVY21 ? svy21NumOfDP : 6
                      )
                  )}
              />
          )}
          {coord != null && (
              <MarkerDetailsRow
                  desc={usingSVY21 ? 'Easting' : 'Longitude'}
                  value={String(
                      roundValue(
                          usingSVY21
                              ? coord.easting!
                              : coord.longitude,
                          usingSVY21 ? svy21NumOfDP : 6
                      )
                  )}
              />
          )}
          {coord != null && coord.hasValidSVY21 && (
              <div className='margin-t-xsm'>
                  <SegmentedButton
                      options={['Lat/Long', 'SVY21']}
                      selected={usingSVY21 ? 1 : 0}
                      onClick={(value) => setUsingSVY21(value === 1)}
                  />
              </div>
          )}
      </div>
    </div>
  )
}

type GLBModelVehiclePositionPanelProps = {
  model: ModelType
  otherExpanded: boolean
}

const GLBModelVehiclePositionPanel = ({
  model,
  otherExpanded,
}: GLBModelVehiclePositionPanelProps) => {
  const utm = {
      northing: model.northing!,
      easting: model.easting!,
      zone_letter: model.zone_letter!,
      zone_number: model.zone_number!,
    }
  const latLngCoord = utmToLatLng(utm)

  const modelAsMarker: MarkerType = {
    name: '',
    bag_id: '',
    timestamp: 0,
    origin: utm,
    position: { x: 0, y: 0, z: model.depth },
    heading: model.heading!,
    altitude: 0,
    no_anode: false,
    sonar_info: {
      width: 0,
      height: 0,
      fov_max: 0,
      max_range: 0,
      range_resol: 0,
    },
  }

  return (
    <div
        className={`flex-grow-1 margin-l-sm margin-t-sm margin-r-sm margin-b-sm
          ${otherExpanded && 'z-0'}`}
        style={{ minHeight: '200px', flexBasis: '100%' }}>
        <MapPanel
            className={`${
              otherExpanded
                  ? 'pointer-none'
                  : 'pointer-auto'
            }`}
            list={[modelAsMarker]}
            selectedMarkersIdx={[0]}
            initLocation={latLngCoord}
            models={null} // TODO: SELECTOR FOR MODELS
            mini={true}
            restrict2D={true}
        />
    </div>
  )
}