import React, { useState } from 'react'
import {
    MarkerType,
    PDFReportImageType,
    Hashtag,
    FileFormat,
    ModelType,
} from '../../../../types'
import Button from '../../../elements/buttons/Button'
import FilledTonalButton from '../../../elements/buttons/FilledTonalButton'
import createPDF from '../../../utils/PDFUtils'
import createDOCX from '../../../utils/DOCXUtils'
import SegmentedButton from '../../../elements/buttons/SegmentedButton'
import LoadingSpinner from '../../../elements/loadingSpinner'
import { checkMarkerHasSVY21 } from '../../../utils/general'

type PDFInputPanelProps = {
    markers: MarkerType[]
    models: ModelType[]
    images: PDFReportImageType[]
    modelImages: PDFReportImageType[]
    hashtagMap: Map<string, Hashtag[]>
    asset: string
    sortBy?: number
    setShowPDFInput: (status: boolean) => void
    setSortBy?: (value: number) => void
}

const PDFInputPanel = ({
    markers,
    models,
    images,
    modelImages,
    hashtagMap,
    asset,
    sortBy,
    setShowPDFInput,
    setSortBy,
}: PDFInputPanelProps) => {
    const [reportObjectives, setReportObjectives] = useState<string>('')
    const [clientName, setClientName] = useState<string>('')
    const [companyLogoFile, setCompanyLogoFile] = useState<File>()
    const [isPremium, setIsPremium] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [usingSVY21, setUsingSVY21] = useState<boolean>(false)
    const anyHasSVY21 = markers.some(checkMarkerHasSVY21)
    const [selectedFileFormat, setSelectedFileFormat] = useState<FileFormat>(
        FileFormat.PDF
    )

    const handleUpdateObjective = (value: string) => {
        setReportObjectives(value)
    }

    const handleUpdateClientName = (value: string) => {
        setClientName(value)
    }

    const handleUploadLogo = (files: FileList) => {
        setCompanyLogoFile(files[0])
    }

    const handleGenerateReport = async (fileFormat: FileFormat) => {
        setLoading(true)
        const words = asset.split(' ')
        const refinedStorageName = words
            .map((word) => {
                return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        if (fileFormat === FileFormat.PDF) {
            const res = companyLogoFile
                ? await createPDF(
                      refinedStorageName,
                      markers,
                      usingSVY21,
                      images,
                      hashtagMap,
                      clientName,
                      reportObjectives,
                      isPremium,
                      companyLogoFile
                  )
                : await createPDF(
                      refinedStorageName,
                      markers,
                      usingSVY21,
                      images,
                      hashtagMap,
                      clientName,
                      reportObjectives,
                      isPremium
                  )
        } else if (fileFormat === FileFormat.DOCX) {
            const res = companyLogoFile
                ? await createDOCX(
                      refinedStorageName,
                      markers,
                      models,
                      usingSVY21,
                      images,
                      modelImages,
                      hashtagMap,
                      clientName,
                      reportObjectives,
                      isPremium,
                      companyLogoFile
                  )
                : await createDOCX(
                      refinedStorageName,
                      markers,
                      models,
                      usingSVY21,
                      images,
                      modelImages,
                      hashtagMap,
                      clientName,
                      reportObjectives,
                      isPremium
                  )
        }
        setLoading(false)
        setShowPDFInput(false)
    }

    return (
        <div>
            <div>
                <h1>Would you like to enter additional information?</h1>
            </div>
            <div>
                <h2>Company Name</h2>
                <input
                    className={
                        'rounded-sm padding-md surface-variant-bg margin-t-sm on-surface-variant w-100 border-0 outline-0 padding-sm border-box'
                    }
                    value={clientName || ''}
                    placeholder={'Enter Company Name'}
                    onChange={(e) => handleUpdateClientName(e.target.value)}
                />
            </div>
            <div>
                <h2>Company Logo</h2>
                <input
                    className='surface-variant-bg rounded-sm padding-sm'
                    type='file'
                    accept='image/png, image/jpeg'
                    onChange={(e) => {
                        if (e.target.files) {
                            handleUploadLogo(e.target.files)
                        }
                    }}
                />
            </div>
            <div>
                <h2>Mission Introduction and Objectives</h2>
                <textarea
                    className='rounded-sm padding-md surface-variant-bg margin-t-sm on-surface-variant w-100 border-0 outline-0 padding-sm border-box'
                    style={{ height: '120px', resize: 'none' }}
                    placeholder={'Type to add your mission objectives.'}
                    value={reportObjectives || ''}
                    onChange={(e) => handleUpdateObjective(e.target.value)}
                />
            </div>
            <div
                className='w-60'
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 3fr',
                }}>
                <div
                    className='margin-t-sm text-bold padding-r-sm flex 
                        flex-col justify-center'>
                    File format
                </div>
                <div className='margin-t-sm w-100'>
                    <SegmentedButton
                        options={['PDF', 'Word DOCX']}
                        selected={selectedFileFormat}
                        onClick={(value) => setSelectedFileFormat(value)}
                    />
                </div>
                {sortBy != undefined && setSortBy && (
                    <>
                        <div
                            className='margin-t-sm text-bold padding-r-sm flex 
                                flex-col justify-center'>
                            Sort by
                        </div>
                        <div className='margin-t-sm w-100'>
                            <SegmentedButton
                                options={['None', 'Timestamp', 'Alphabetical']}
                                selected={sortBy}
                                onClick={setSortBy}
                            />
                        </div>
                    </>
                )}
                {anyHasSVY21 && (
                    <>
                        <div
                            className='margin-t-sm text-bold padding-r-sm flex 
                                flex-col justify-center'>
                            Coordinates
                        </div>
                        <div className='margin-t-sm w-100'>
                            <SegmentedButton
                                options={['Lat/Long', 'SVY21']}
                                selected={usingSVY21 ? 1 : 0}
                                onClick={(value) => setUsingSVY21(value === 1)}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className={'margin-t-sm margin-b-md flex w-100 justify-end'}>
                {loading && <LoadingSpinner width={42} height={42} />}
                {!loading && (
                    <>
                        <Button
                            contentColor={'on-surface-variant'}
                            text='Cancel'
                            onClick={() => {
                                setShowPDFInput(false)
                            }}
                        />
                        <FilledTonalButton
                            text='Generate Report'
                            onClick={() =>
                                handleGenerateReport(selectedFileFormat)
                            }
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default PDFInputPanel
