import {
    Document,
    Packer,
    Paragraph,
    TextRun,
    ImageRun,
    SectionType,
    IImageOptions,
    ISectionOptions,
    AlignmentType,
    Header,
    IFloating,
    HorizontalPositionAlign,
    Footer,
    PageNumber,
    ParagraphChild,
    Table,
    TableRow,
    TableCell,
    VerticalAlign,
    BorderStyle,
    IShadingAttributesProperties,
    convertMillimetersToTwip,
    ShadingType,
    ITableCellBorders,
    HeightRule,
    VerticalPositionRelativeFrom,
    IPageMarginAttributes,
    ISectionPropertiesOptions,
    TableLayoutType,
} from 'docx'
import { ImgDims } from '../types'

export const getImageRun = (
    imageBytes: ArrayBuffer | Buffer,
    imageDims: ImgDims,
    floatingOptions: IFloating | undefined = undefined
) => {
    const imageRunOptions: IImageOptions = {
        data: imageBytes,
        transformation: {
            width: imageDims.width,
            height: imageDims.height,
        },
        floating: floatingOptions,
    }
    const imageRun = new ImageRun(imageRunOptions)
    return imageRun
}
