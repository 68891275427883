import { useEffect, useState } from 'react'
import { MarkerType, Hashtag } from '../../types'
import { toLatLon } from 'utm'
import { SVY21 } from '../map/utils/CoordHelper/svy21'

export const regexStorageName = (name: string) => {
  // return name.replace(/(?<=[^1])1/g, ' ')
  return name
    .replace('beex1', '')
    .replace(/1(?=[^1])/g, ' ') // Not working for safari
    .trim()
    .toUpperCase()
  // .split(' ')
  // .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  // .join(' ')
}

export const unregexStorageName = (name: string) => {
  return 'beex1' + name.trim().replace(/ /g, '1').toLowerCase()
}

export const regexAssetName = (name: string) => {
  return name.replace(/-/g, ' ').trim().toUpperCase()
}

export const unregexAssetName = (name: string) => {
  return name.trim().replace(/\s+/g, '-').toLowerCase()
}

export const checkRepeatedHashTag = (
  value: Hashtag,
  newHashtagList: Hashtag[]
) => {
  for (let i = 0; i < newHashtagList.length; i++) {
    //Checks if new hashtag is the same aft removing all special characters
    const newVal = value.content.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
    if (
      newVal ===
      newHashtagList[i].content.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
    ) {
      return true
    }
  }
  return false
}

export const checkRepeatedMarkers = (markers: MarkerType[]) => {
  let cleanedMarkerArr = []
  for (let i = 0; i < markers.length; i++) {
    if (i !== 0 && Object.is(markers[i].bag_id, markers[i - 1].bag_id)) {
    } else {
      cleanedMarkerArr.push(markers[i])
    }
  }
  return cleanedMarkerArr
}

// Hook to get window size
export function isMobile() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize.width <= 768
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function readableFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + ' ' + units[u]
}

export function readableDate(string: string): string {
  const date = new Date(string)
  return date.toDateString()
}

export function processMarkerCoord(marker: MarkerType) {
  return toLatLon(
    marker.origin.easting + marker.position.y,
    marker.origin.northing + marker.position.x,
    marker.origin.zone_number || 48,
    marker.origin.zone_letter || 'N',
    undefined,
    false
  )
}

export function checkMarkerHasSVY21(marker: MarkerType) {
    const { latitude, longitude } = processMarkerCoord(marker)
    return new SVY21().checkValidSVY21(latitude, longitude)
}
