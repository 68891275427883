import { IDxf } from 'dxf-parser'
import { LatLngCoord } from '../../../../../types'
import { drawArc } from './Arc'
import { drawLine } from './Line'
import { drawText } from './Text'
import { drawBlock } from './Block'

export const drawEntity = (
  entity: any,
  data: IDxf,
  unit: number,
  font: any,
  mapTileOrigin: LatLngCoord,
  pixelRes: number
) => {
  /*
    Circle and Text requires pixel res
    Line does not require pixel res
  */
  var mesh
  if (entity.type === 'CIRCLE' || entity.type === 'ARC') {
    mesh = drawArc(entity, data, unit, mapTileOrigin, pixelRes)
  } else if (
    entity.type === 'LWPOLYLINE' ||
    entity.type === 'LINE' ||
    entity.type === 'POLYLINE'
  ) {
    mesh = drawLine(entity, data, unit, mapTileOrigin)
  } else if (entity.type === 'TEXT') {
    mesh = drawText(entity, data, font, unit, mapTileOrigin, pixelRes)
    // } else if (entity.type === 'SOLID') {
    //   mesh = drawSolid(entity, data)
    // } else if (entity.type === 'POINT') {
    //   mesh = drawPoint(entity, data)
  } else if (entity.type === 'INSERT') {
    mesh = drawBlock(entity, data, unit, font, mapTileOrigin, pixelRes)
    // } else if (entity.type === 'SPLINE') {
    //   mesh = drawSpline(entity, data)
    // } else if (entity.type === 'MTEXT') {
    // console.log(entity.text)
    // mesh = drawMtext(entity, data, font)
    // } else if (entity.type === 'ELLIPSE') {
    //   mesh = drawEllipse(entity, data)
    // } else if (entity.type === 'DIMENSION') {
    //   var dimTypeEnum = entity.dimensionType & 7
    //   if (dimTypeEnum === 0) {
    //     mesh = drawDimension(entity, data)
    //   } else {
    //     console.log('Unsupported Dimension type: ' + dimTypeEnum)
    //   }
  } else {
    // console.log('Unsupported Entity Type: ' + entity.type)
  }
  return mesh
}
