/**
 * Draw Basic Shapes in THREEJS
 * drawCircle
 * drawLine
 */
import * as THREE from 'three'
import { SceneCoord } from '../../../types'

export function drawCircle(scene: SceneCoord, radius: number, color: number) {
    const curve = new THREE.ArcCurve(0, 0, radius, 0, 2 * Math.PI, false)
    const points = curve.getPoints(32)
    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    const material = new THREE.LineBasicMaterial({ linewidth: 1, color: color })

    const arc = new THREE.Line(geometry, material)
    arc.position.x = scene.x
    arc.position.y = scene.y
    arc.position.z = 0
    return arc
}

export function drawLine(scenes: SceneCoord[], color: number) {
    const points = []
    for (let i = 0; i < scenes.length; ++i) {
        points.push(new THREE.Vector3(scenes[i].x, scenes[i].y, 0))
    }

    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    const material = new THREE.LineBasicMaterial({ linewidth: 1, color: color })

    return new THREE.Line(geometry, material)
}
