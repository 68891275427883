import React from 'react'
import Button, { ButtonProps } from './Button'

const FilledTonalButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      contentColor='on-secondary-container'
      containerColor='secondary-container'
    />
  )
}

export default FilledTonalButton
