import React, { useContext, useEffect, useState } from 'react'
import GhostPanel from '../GhostPanel'
import ChipBar from './ChipBar'
import { FilterType } from '../../utils/FilterUtils'
import IconButton from '../buttons/IconButton'
import { PriorityIcon, SearchIcon } from '../../../assets'
import { useAccount, useMsal } from '@azure/msal-react'
import { AppContext } from '../../../store/context'
import { FilterActionType, TagActionType } from '../../../store/reducers'
import {
  msalFetchHashtagIds,
  msalFetchHashtagList,
} from '../../../backend/hashtag'
import { HashtagType } from '../../../types'

type SearchBarProps = {
  asset: string
  storage: string
}

const SearchBar = ({ asset, storage }: SearchBarProps) => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const { state, dispatch } = useContext(AppContext)
  const { filter, tags } = state
  const { sTags, cTags } = tags
  const { search, flagged } = filter
  const [onFocus, setOnFocus] = useState(false)

  const onSearch = (value: string) => {
    dispatch({
      type: FilterActionType.SET_SEARCH,
      payload: value,
    })
  }

  const convertTagToID = async (value: string[], type: HashtagType) => {
    if (value.length == 0) return null
    const msal = {
      instance: instance,
      account: account,
      storage: storage,
      asset: asset,
    }
    let arr: string[] = []
    for (let i = 0; i < value.length; ++i) {
      arr = [...arr, ...(await msalFetchHashtagIds(msal, type, value[i]))]
    }
    return arr
  }

  const onSelectCTags = async (value: string[]) => {
    dispatch({
      type: FilterActionType.SET_CTAG,
      payload: value,
    })
    dispatch({
      type: FilterActionType.SET_CTAG_ID,
      payload: await convertTagToID(value, HashtagType.COMPONENT),
    })
  }

  const onSelectSTags = async (value: string[]) => {
    dispatch({
      type: FilterActionType.SET_STAG,
      payload: value,
    })
    dispatch({
      type: FilterActionType.SET_STAG_ID,
      payload: await convertTagToID(value, HashtagType.STRUCTURE),
    })
  }

  const onFlagged = () => {
    dispatch({
      type: FilterActionType.SET_FLAGGED,
      payload: !flagged,
    })
  }

  return (
    <>
      <div className={`rel flex-grow-1 z-10`}>
        <div
          className={`rel flex-grow-1 z-10 padding-md rounded-sm h-42px
          border-box flex align-center shadow surface-container-bg`}
          onClick={() => setOnFocus(true)}>
          <IconButton Icon={SearchIcon} onClick={() => setOnFocus(true)} />
          <input
            type='text'
            placeholder={'Search'}
            className='surface-container-bg on-surface text-md padding-w-sm'
            value={search}
            onChange={(e) => onSearch(e.target.value)}
          />
          <IconButton
            Icon={PriorityIcon}
            selected={flagged}
            selectedContainerColor=''
            selectedContentColor='error'
            onClick={() => onFlagged()}
          />
        </div>
        {onFocus && (
          <>
            <GhostPanel onClick={() => setOnFocus(false)} />
            <div className='abs surface-container-bg shadow padding-lg rounded-sm w-100 border-box'>
              {sTags.length == 0 && cTags.length == 0 && (
                <div>
                  <div className='text-md padding-h-sm'>
                    Opps no tags found!
                  </div>
                  <div className='text-normal'>
                    Add a structure or component tag to a snapshot to get
                    started.
                  </div>
                </div>
              )}
              {sTags.length > 0 && (
                <div className='flex'>
                  <div
                    className='text-sm text-bold padding-t-md padding-r-sm'
                    style={{ width: '80px' }}>
                    Structure
                  </div>
                  <ChipBar
                    tags={sTags}
                    selected={filter.sTags || []}
                    onSelect={onSelectSTags}
                  />
                </div>
              )}
              {cTags.length > 0 && (
                <div className='flex margin-t-sm'>
                  <div
                    className='text-sm text-bold padding-t-md padding-r-sm'
                    style={{ width: '80px' }}>
                    Component
                  </div>
                  <ChipBar
                    tags={cTags}
                    selected={filter.cTags || []}
                    onSelect={onSelectCTags}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SearchBar
