import { useMsal } from '@azure/msal-react'
import React, { useEffect, useState } from 'react'
import {
    LatLngCoord,
    ModelType,
    ThumbnailType,
    Hashtag,
    PageType,
} from '../../../../types'
import LoadingSpinner from '../../../elements/loadingSpinner'
import PCDRightPanel from './PCDRightPanel'
import MapPanel from './MapPanel'
import FileUploadDialog from '../../../elements/Dialogs/FileUploadDialog'
import Dialog from '../../../elements/Dialogs/Dialog'
import SadFaceIcon from '../../../../assets/icons/drawing.svg'
import { isMobile } from '../../../utils/general'
import { getBlob } from '../../../../backend'
import { uploadBlob } from '../../../../backend/msal/put'
import { processXYZFile } from '../../../pointcloudpage/CustomXYZLoader'
import { readMetadata } from '../../../map/utils/png-metadata'
import { saveAs } from 'file-saver'

type PCDReportPanelType = {
    model: ModelType
    resetAftUpload: () => void
    hashtagList: Hashtag[]
    thumbnailList: ThumbnailType | undefined
    location: LatLngCoord | null
    models: ModelType[]
    storageAccount: string
    asset: string
    dateFolder: string
    edited: boolean
    setEdited: (edited: boolean) => void
    saveEdit: (
        dateFolder: string,
        newModel: ModelType,
        newHashtags: Hashtag[]
    ) => void
    getModelNameFromFileName: (filename: string) => string
    setPlayableBeexFile: (file: File) => void
    playableBeexFile?: File
    setBeexStartTime: (time: number | undefined) => void
    beexStartTime?: number
    setBeexEndTime: (time: number | undefined) => void
    beexEndTime?: number
    startPlayingBeexFile: () => void
    allHashtags: Hashtag[]
    saveAllHashtags?: (newHashtagList: Hashtag[]) => Promise<void>
    setHashtagEdited?: () => void
}

const PCDReportPanel = (props: PCDReportPanelType) => {
    const { instance, accounts } = useMsal()
    const account = accounts[0]
    const {
        model,
        resetAftUpload,
        models,
        hashtagList,
        storageAccount,
        asset,
        dateFolder,
        edited,
        setEdited,
        saveEdit,
        getModelNameFromFileName,
        setPlayableBeexFile,
        playableBeexFile,
        setBeexStartTime,
        beexStartTime,
        setBeexEndTime,
        beexEndTime,
        startPlayingBeexFile,
        allHashtags,
        saveAllHashtags,
        setHashtagEdited,
    } = props
    const mobile = isMobile()
    const [expandFrontCam, setExpandFrontCam] = useState(false)
    const [expandSonar, setExpandSonar] = useState(false)
    const [expandGLBViewer, setExpandGLBViewer] = useState(false)
    const [renderMap, setRenderMap] = useState<boolean>(true) // To ensure that middle panel renders to the right size
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [doesBlobExist, setDoesBlobExist] = useState<boolean>(true)
    const [uploadedMissingFile, setUploadedMissingFile] = useState<File | null>(
        null
    )
    const [uploadModelLoading, setUploadModelLoading] = useState<boolean>(false)
    const [selectedModels, setSelectedModels] = useState<ModelType[]>([])
    const [modelFileMap, setModelFileMap] = useState<Record<string, Blob>>({})
    const is3DModel = ['glb', 'obj'].includes(model.id)

    const downloadModels = async () => {
        saveAs(new Blob([modelFileMap[model.file]]), model.file)
    }

    // artificial loading time may not be viable anymore as size of files vary greatly
    // useEffect(() => {
    //   // Artificial loading time for pcd data to load
    //   setTimeout(() => {
    //     setIsLoading(false)
    //   }, 4000)
    // })

    const loadFileDrop = async (file: File) => {
        const fileType = file.name.split('.').slice(-1)[0] // final segment to get file type in file name
        console.log('test', fileType)
        if (fileType === 'xyz') {
            // Check if xyz file contains UTM coordinates
            const comment = (await file.text()).split('\n')[0]
            const array = comment.split(' ')
            const northing = Number(array[1].slice(0, -1))
            const easting = Number(array[2].slice(0, -1))
            const zone_number = Number(array[3].slice(0, -1))
            const zone_letter = array[3].slice(-1)

            if (
                !(northing && easting && zone_number) ||
                !zone_letter.match(/^[C-X]$/)
            ) {
                alert(
                    '.xyz file is corrupted! Please select another .xyz file.'
                )
                return
            }
        }

        if (file.name !== model.file) {
            alert(
                'Please upload the file with the same name as the missing file.'
            )
            return
        }

        // Do autofill for date and name
        const fileName = file.name
        const regex = /(\d{4})-(\d{2})-(\d{2})/
        const match = regex.exec(fileName)

        if (match) {
            const year = match[1]
            const month = match[2]
            const day = match[3]
            const index = match.index
            if (model.date !== `${year}-${month}-${day}`) {
                alert(
                    'Please upload the file with the same date as the missing file.'
                )
                return
            }
        }
        setUploadedMissingFile(file)
    }

    const uploadMissingFile = async () => {
        if (!uploadedMissingFile) {
            alert('Please upload the missing file before refreshing!')
            return
        }
        setUploadModelLoading(true)
        if (uploadedMissingFile) {
            await uploadBlob(
                instance,
                account,
                storageAccount,
                asset,
                model.file, // Same as uploadedMissingFile.name
                uploadedMissingFile,
                model.date
            )
        }
        setUploadModelLoading(false)
        resetAftUpload()
    }

    useEffect(() => {
        const initialiseXYZModels = async () => {
            let newModel: ModelType = model
            const blob = await getBlob(
                instance,
                account,
                storageAccount,
                props.asset,
                model.file,
                model.date || 'asset'
            )
            const blobExists = blob !== null
            setIsLoading(blobExists)
            setDoesBlobExist(blobExists)
            if (!blobExists) return
            const jsonObject = await getBlob(
                instance,
                account,
                storageAccount,
                props.asset,
                `${model.date}.json`,
                model.date
            )
            const updatedModels = jsonObject['model'].filter(
                (jsonModel: ModelType) => jsonModel.file === model.file
            )
            let updatedModel = updatedModels.length ? updatedModels[0] : null

            if (model.id === 'xyz' || model.id === 'mosaic') {
                if (model.id === 'xyz') {
                    const { utm } = await processXYZFile(
                        new File([blob], 'name')
                    )
                    if (!utm) return
                    newModel = {
                        ...model,
                        northing: utm.northing,
                        easting: utm.easting,
                        zone_letter: utm.zone_letter,
                        zone_number: utm.zone_number,
                    }
                } else {
                    const buffer = new Uint8Array(await blob.arrayBuffer())
                    const metadata = readMetadata(buffer).tEXt
                    if (metadata) {
                        newModel = {
                            ...model,
                            northing: Number(metadata['mosaic.northing']),
                            easting: Number(metadata['mosaic.easting']),
                            zone_letter: metadata['mosaic.zone_letter'],
                            zone_number: Number(metadata['mosaic.zone_num']),
                            height: Number(metadata['mosaic.height']),
                            heading: Number(metadata['mosaic.heading']),
                            width: Number(metadata['mosaic.width']),
                        }
                    } else if (updatedModel['northing']) {
                        // assumes has other attributes
                        newModel = {
                            ...model,
                            northing: updatedModel.northing,
                            easting: updatedModel.easting,
                            zone_letter: updatedModel.zone_letter,
                            zone_number: updatedModel.zone_number,
                            height: updatedModel.height,
                            heading: updatedModel.heading,
                            width: updatedModel.width,
                        }
                    } else {
                        alert(
                            'Mosaic file is corrupted! Please contact the administrator.'
                        )
                        return
                    }
                }
            } else if (model.id === 'pcd') {
                newModel = {
                    ...model,
                    northing: updatedModel.northing,
                    easting: updatedModel.easting,
                    zone_letter: updatedModel.zone_letter,
                    zone_number: updatedModel.zone_number,
                    height: updatedModel.height,
                    heading: updatedModel.heading,
                    width: updatedModel.width,
                }
            } else if (model.id === 'glb') {
                newModel = {
                    ...model,
                    northing: updatedModel.northing,
                    easting: updatedModel.easting,
                    zone_letter: updatedModel.zone_letter,
                    zone_number: updatedModel.zone_number,
                    latitude: updatedModel.latitude,
                    longitude: updatedModel.longitude,
                    heading: updatedModel.heading,
                    start_time: updatedModel.start_time,
                    end_time: updatedModel.end_time,
                    onedrive_path: updatedModel.onedrive_path,
                }
            } else if (model.id === 'obj') {
                newModel = {
                    ...model,
                    northing: updatedModel.northing,
                    easting: updatedModel.easting,
                    zone_letter: updatedModel.zone_letter,
                    zone_number: updatedModel.zone_number,
                }
            }

            setSelectedModels([newModel])
            modelFileMap[model.file] = blob
            setModelFileMap(modelFileMap)
            setIsLoading(false)
        }
        initialiseXYZModels()
    }, [uploadedMissingFile])

    return (
        <>
            {model && renderMap && !doesBlobExist && (
                <Dialog
                    text={``}
                    description={``}
                    buttonText={`Done`}
                    onClick={async () => {
                        uploadMissingFile()
                    }}
                    boxClassName={'w-40'}
                    loadingSubmit={uploadModelLoading}
                    nonFullScreen={true}>
                    <div
                        id='dialog-innerdiv'
                        className='justify-center align-center flex flex-col'>
                        <SadFaceIcon width={200} height={200} />
                        <div className='padding-h-sm text-center h2-20px'>
                            The file "{model.file}" seems to be missing. Please
                            upload it again.
                            <br />
                            Make sure the uploaded file has the exact same name.
                            <FileUploadDialog
                                loadFile={(file) => {
                                    loadFileDrop(file)
                                }}
                                type={
                                    uploadedMissingFile
                                        ? 'model_uploaded'
                                        : 'model'
                                }
                                fileName={uploadedMissingFile?.name}
                                filesToReupload={[model.file]}
                            />
                        </div>
                    </div>
                </Dialog>
            )}
            {model && renderMap && isLoading && doesBlobExist && (
                <>
                    <div className='abs-center z-alert'>
                        <LoadingSpinner width={80} height={80} />
                    </div>
                </>
            )}
            {model && renderMap && !isLoading && doesBlobExist && (
                <>
                    <div className='flex flex-wrap flex-grow-1 overflow-y-auto border-box padding-md visibility-visible'>
                        <div
                            className='margin-r-md flex-grow-1'
                            style={{
                                minWidth: mobile ? '100%' : '',
                                minHeight: mobile ? '400px' : '',
                            }}>
                            <MapPanel
                                className={`${
                                    expandFrontCam || expandSonar
                                        ? 'pointer-none'
                                        : 'pointer-auto'
                                }`}
                                list={[]}
                                selectedMarkersIdx={[]}
                                initLocation={null}
                                models={selectedModels}
                                setSelectedModels={setSelectedModels}
                                terrain={models}
                                defaultShowModels={true}
                                modelFileMap={modelFileMap}
                                is3DModel={is3DModel}
                                setExpanded={setExpandGLBViewer}
                            />
                        </div>
                        {mobile && (
                            <div style={{ width: '100%', height: '24px' }} />
                        )}
                        <div
                            className='flex flex-col align-start h-100 padding-w-sm border-box'
                            style={{ width: mobile ? '100%' : '33%' }}>
                            <PCDRightPanel
                                model={model}
                                hashtags={hashtagList}
                                edited={edited}
                                setEdited={setEdited}
                                saveEdit={(
                                    newModel: ModelType,
                                    newHashtagList: Hashtag[]
                                ) => {
                                    saveEdit(
                                        dateFolder,
                                        newModel,
                                        newHashtagList
                                    )
                                }}
                                getModelNameFromFileName={
                                    getModelNameFromFileName
                                }
                                allHashtags={allHashtags}
                                saveAllHashtags={saveAllHashtags}
                                downloadModels={downloadModels}
                                setHashtagEdited={setHashtagEdited}
                                otherExpanded={expandGLBViewer}
                                setPlayableBeexFile={setPlayableBeexFile}
                                playableBeexFile={playableBeexFile}
                                setBeexStartTime={setBeexStartTime}
                                beexStartTime={beexStartTime}
                                setBeexEndTime={setBeexEndTime}
                                beexEndTime={beexEndTime}
                                startPlayingBeexFile={startPlayingBeexFile}
                            />
                        </div>
                    </div>
                </>
            )}
            {(!model || !renderMap) && <div className={'w-30 h-30 grey'}></div>}
        </>
    )
}

export default PCDReportPanel
