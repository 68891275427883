import React from 'react'

type LoadingSpinnerProps = {
  width: number
  height: number
  dark?: boolean
}

const LoadingSpinner = ({ width, height, dark }: LoadingSpinnerProps) => {
  return (
    <div style={{ width: width, height: height }}>
      <div
        className={`${
          dark ? 'lds-ring-dark' : ''
        } lds-ring rel w-100 h-100 flex justify-center align-center`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default LoadingSpinner
