/**
 * LOADS DXF FILES
 */
import { LatLngCoord, ModelType } from '../../../../../types'
import { parseDXF } from '../../DXFHelper'

export async function getDXF(
    blob: any,
    model: ModelType,
    mapOrigin: LatLngCoord
) {
    return await parseDXF(blob, 1 / (model.scale || 1), mapOrigin)
}
