import React, { useState, useEffect } from 'react'
import FilledButton from '../elements/buttons/FilledButton'
import TextButton from '../elements/buttons/TextButton'
import Button from '../elements/buttons/Button'
import TextInput from '../elements/inputs/TextInput'
import ContextMenu from '../elements/ContextMenu'
import { MarkerType } from '../../types'
import ExpandLessIcon from '../../assets/icons/expand_less_black_24dp.svg'
import ExpandMoreIcon from '../../assets/icons/expand_more_black_24dp.svg'
import CameraIcon from '../../assets/icons/camera_black_24dp.svg'
import { getDuration, Time, timestampToTime } from '../utils/rosbag'
import { PlayerBarInterface } from './PlayerBar'
import LoadingSpinner from '../../components/elements/loadingSpinner'
import Dropdown from '../elements/Dropdown'

type SnapshotTimelineProps = {
  playerBarRef: React.RefObject<PlayerBarInterface>
  list: MarkerType[] | null
  bagStartTime?: Time
  selectedAsset: string
  date: string
  selectedAccount: string
  storageAccounts: string[]
  assets: string[]
  seekTimestamp: (timestamp: number) => void
  createSnapshot: (name: string) => void
  deleteSnapshot: (idx: number) => void
  setTimelineRevealed: (value: boolean) => void
  setSelectedAsset: (value: string) => void
  setSelectedAccount: (value: string) => void
}

const SnapshotTimeline = (props: SnapshotTimelineProps) => {
  const {
    list,
    bagStartTime,
    selectedAsset,
    date,
    selectedAccount,
    storageAccounts,
    assets,
    seekTimestamp,
    createSnapshot,
    deleteSnapshot,
    setTimelineRevealed,
    setSelectedAsset,
    setSelectedAccount,
  } = props
  const [hide, setHide] = useState(true)
  useEffect(() => {
    console.log(selectedAccount)
    console.log(selectedAsset)
  }, [selectedAccount, selectedAsset])
  const renderMarkers = () => {
    if (!bagStartTime || !list) return
    return list.map((item, idx) => {
      const time = timestampToTime(item.timestamp)
      const displayTime = padTime(getDuration(time, bagStartTime))
      // const left =
      //   playerBarRef.current!.calcSeekerWidth(getDuration(time, bagStartTime)) +
      //   80; // Add play button width
      return (
        <Marker
          name={item.name}
          time={displayTime}
          onClick={() => seekTimestamp(item.timestamp)}
          onDelete={() => deleteSnapshot(idx)}
        />
      )
    })
  }

  return (
    <>
      <div
        className='fixed top-0 bottom-0 z-10 padding-w-lg border-box flex flex-col'
        style={{
          width: '420px',
          right: `${hide ? '-420px' : '0px'}`,
          backgroundColor: '#17171d',
          transition: '0.3s',
        }}>
        <div
          className={'abs top-240px'}
          style={{
            backgroundColor: '#17171d',
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            transform: 'rotate(-90deg)',
            transformOrigin: 'center center',
            left: '-96px',
          }}>
          <Button
            className='snapshot-toggle-hide'
            text='Snapshots'
            Icon={hide ? ExpandLessIcon : ExpandMoreIcon}
            onClick={() => {
              setHide(!hide)
              setTimelineRevealed(hide)
            }}
          />
        </div>
        <h2 className='padding-h-md text-center' style={{ fontWeight: 'bold' }}>
          Snapshots
        </h2>
        {date != '' ? (
          <div className='padding-l-md padding-b-lg flex flex-row'>
            <div className='w-30'>Date: </div>
            <div className='w-70'>{date}</div>
          </div>
        ) : (
          <></>
        )}

        <div className={`z-dialog padding-l-md padding-b-lg flex flex-row`}>
          {/* <div className='w-100 flex justify-center margin-b-sm'>
              Company:
            </div> */}
          <div className='w-30'>Company: </div>
          {storageAccounts.length > 0 && (
            <div className='w-70'>
              <Dropdown
                list={storageAccounts}
                selected={selectedAccount}
                select={setSelectedAccount}
              />
            </div>
          )}
          {!storageAccounts.length && (
            <div className='error margin-l-sm w-70'>No available account.</div>
          )}
        </div>

        <div className={`z-dialog-bg padding-l-md padding-b-lg flex flex-row`}>
          {/* <div className='w-100 flex justify-center margin-b-sm'>Asset: </div> */}
          <div className='w-30'>Asset: </div>
          {assets.length > 0 && (
            <div className='w-70'>
              <Dropdown
                list={assets}
                selected={selectedAsset}
                select={setSelectedAsset}
              />
            </div>
          )}
          {!assets.length && (
            <div className='error margin-l-sm w-70'>
              No available assets in account. Create under Reports.
            </div>
          )}
        </div>

        {!list && (
          <div className='flex flex-grow-1 grey text-center justify-center'>
            Opps... no access to cloud.
            <br /> Contact BeeX for more information.
          </div>
        )}
        {list && list.length == 0 && (
          <div className='flex flex-grow-1 grey text-center justify-center'>
            No snapshots. <br /> Click on the button to create a snapshot.
          </div>
        )}
        {list && list.length > 0 && (
          <div className='flex flex-grow-1 overflow-auto flex-col'>
            {renderMarkers()}
          </div>
        )}

        <SnapshotToolbar
          createSnapshot={createSnapshot}
          disabled={selectedAccount === '' || selectedAsset === ''}
        />
      </div>
    </>
  )
}

export default SnapshotTimeline

type SnapshotToolbarProps = {
  createSnapshot: (name: string) => void
  disabled: boolean
}

const SnapshotToolbar = ({
  createSnapshot,
  disabled,
}: SnapshotToolbarProps) => {
  const [name, setName] = useState('')
  const [validate, setValidate] = useState(false)
  const regexExp = /^[\w\s]+$/
  const [loading, setLoading] = useState<boolean>(false)

  const handleCreateSnapshot = async () => {
    setLoading(true)
    setValidate(true)
    if (name && name !== '' && name.match(regexExp)) {
      await createSnapshot(name)
      setName('')
      setValidate(false)
    }
    setLoading(false)
  }
  return (
    <div className='w-100 padding-h-lg border-box flex-col justify-center align-center margin-b-md'>
      <div className='padding-h-sm'>
        <TextInput
          value={name}
          regex={regexExp}
          error={'Only a-z, A-Z, 0-9, and _ are valid.'}
          title={'Enter a name for the snapshot'}
          onChange={(value) => setName(String(value))}
        />
      </div>
      <div className='flex justify-end'>
        {!loading && (
          <FilledButton
            text='Create snapshot'
            Icon={CameraIcon}
            onClick={() => handleCreateSnapshot()}
            disabled={disabled}
          />
        )}
        {loading && <LoadingSpinner width={40} height={40} />}
      </div>
    </div>
  )
}

type MarkerProps = {
  name: string
  time: string
  onClick: () => void
  onDelete: () => void
}

const Marker = (props: MarkerProps) => {
  const { time, name, onClick, onDelete } = props
  const [showMenu, setShowMenu] = useState(false)
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })

  return (
    <>
      {showMenu && (
        <ContextMenu
          top={anchorPoint.y}
          left={anchorPoint.x}
          options={['Delete']}
          onClose={() => setShowMenu(false)}
          onClick={(idx) => onDelete()}
        />
      )}
      <TextButton
        maxWidth
        text={`[${time}] ${name}`}
        onClick={onClick}
        onContextMenu={(e) => {
          setAnchorPoint({ x: e.clientX, y: e.clientY })
          setShowMenu(true)
        }}
      />
    </>
  )
}

/*
  HELPER FUNCTIONS
*/
const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0')

const padTime = (duration: number) => {
  return `${zeroPad(Math.floor(duration / 60), 2)}:${zeroPad(
    Math.round(duration % 60),
    2
  )}`
}
