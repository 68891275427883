import React, { useImperativeHandle, useRef, useState, useEffect } from 'react'
import { SonarInfo } from '../../types'
import { degToRad } from '../map/utils/CoordHelper/CoordUtils'
import Button from '../elements/buttons/Button'
import FullscreenIcon from '../../assets/icons/fullscreen_black_24dp.svg'

export const drawSonarOverlay = (
    sonarInfo: SonarInfo,
    canvasRef: HTMLCanvasElement,
    lineWidth = 0.8,
    lineColor = '#d6cc3e',
    font = "14px 'Google Sans'",
    clearingContext = true
) => {
    // const { width, height } = this.getRenderedSize(true, target.width, target.height, sonarInfo.width, sonarInfo.height, 50)
    const width = sonarInfo.width
    const height = sonarInfo.height
    if (clearingContext) {
        canvasRef.width = width
        canvasRef.height = height
    }
    const start_y =
        height - Math.tan(degToRad(90 - sonarInfo.fov_max)) * (width / 2)
    const ctx = canvasRef.getContext('2d')
    if (ctx) {
        if (clearingContext) ctx.clearRect(0, 0, width, height)
        ctx.strokeStyle = lineColor
        ctx.fillStyle = lineColor
        ctx.font = font
        ctx.lineWidth = lineWidth
        ctx.beginPath()
        ctx.moveTo(0, start_y)
        ctx.lineTo(width / 2, height)
        ctx.lineTo(width, start_y)
        let sign = 1
        const num_of_lines = 5
        const n = sonarInfo.max_range / num_of_lines
        const d = (n / sonarInfo.range_resol) * (width / sonarInfo.width)
        const text_offset = width < height ? 10 : 0
        for (let i = 1; i <= num_of_lines; i++) {
            const x = width / 2 - i * d * Math.sin(degToRad(sonarInfo.fov_max))
            const y = height - i * d * Math.cos(degToRad(sonarInfo.fov_max))
            ctx.fillText(
                String(Math.round(i * n * 10) / 10),
                x + 10,
                y + text_offset
            )
            ctx.ellipse(
                width / 2,
                height,
                i * d,
                i * d,
                degToRad(0),
                degToRad(270 + sign * sonarInfo.fov_max),
                degToRad(270 - sign * sonarInfo.fov_max),
                sign > 0 ? true : false
            )
            sign = -sign
        }
        ctx.stroke()
    }
    return canvasRef.toDataURL()
}

export const getRangeOnSonar = (
    e: React.MouseEvent<HTMLCanvasElement>,
    sonarInfo: SonarInfo,
    tooltip: HTMLDivElement
) => {
    const rect = (e.target as HTMLCanvasElement).getBoundingClientRect()
    // console.log(rect)
    // console.log(sonarInfo)
    // console.log('client x: ', e.clientX)
    // console.log('client y: ', e.clientY)

    let scale = 1,
        offsetY = 0,
        offsetX = 0

    let maxY = 0,
        minY = 0,
        height = 0

    if (sonarInfo.height / rect.height > sonarInfo.width / rect.width) {
        scale = sonarInfo.height / rect.height
        offsetX = sonarInfo.width - rect.width * scale
        offsetY = 0
    } else {
        scale = sonarInfo.width / rect.width
        offsetY = sonarInfo.height - rect.height * scale
        offsetX = 0
    }

    if (sonarInfo.height > sonarInfo.width) {
        //tall sonar images
        maxY = Math.trunc(rect.top - 1)
        minY = Math.trunc(rect.bottom - 2)
        height = minY - maxY
    } else {
        //long sonar images
        const aspectRatio = sonarInfo.width / sonarInfo.height
        // New Height for landscape sonars
        const newHeight = rect.width / aspectRatio
        const gap = (rect.height - newHeight) / 2
        maxY = Math.trunc(rect.top)
        minY = Math.trunc(rect.bottom)
        height = minY - maxY
    }

    const mouseX = e.clientX - Math.trunc(rect.left) - rect.width / 2
    const mouseY = minY - e.clientY
    const r =
        ((mouseX ** 2 + mouseY ** 2) ** 0.5 / height) * sonarInfo.max_range
    const theta =
        mouseY === 0 ? 0 : (Math.atan(mouseX / mouseY) * 180) / Math.PI
    // console.log('mouse x: ', mouseX)
    // console.log('mouse y: ', mouseY)
    // console.log('angle: ', theta)
    if (Number.isNaN(r) || r > sonarInfo.max_range || mouseY < 0) {
        tooltip.style.display = 'none'
    } else {
        tooltip.style.display = 'block'
        tooltip.innerHTML = r.toFixed(2) + 'm, ' + Math.round(theta) + '\u00B0'
        tooltip.style.top = e.clientY - rect.top + 'px'
        tooltip.style.left = e.clientX - rect.left + 'px'
    }
    /*
  const mouseX = e.clientX - rect.left - rect.width / 2 + offsetX / 2
  const mouseY = rect.height - (e.clientY - rect.top) + offsetY / 2
  const r =
    ((mouseX ** 2 + mouseY ** 2) ** 0.5 * sonarInfo.max_range) /
    (rect.height + offsetY)
  const theta = (Math.atan(mouseX / mouseY) * 180) / 3.1415926
  if (Number.isNaN(r) || r > sonarInfo.max_range) {
    tooltip.style.display = 'none'
  } else {
    tooltip.style.display = 'block'
    tooltip.innerHTML =
      Math.round(r * 100) / 100 + 'm, ' + Math.round(theta) + '\u00B0'
    tooltip.style.top = e.clientY - rect.top + 'px'
    tooltip.style.left = e.clientX - rect.left + 'px'
  } */
}

export interface SonarImageInterface {
    imgRef: React.RefObject<HTMLImageElement>
    setImgSrc: (src: string) => void
    setSonarInfo: (sonarInfo: SonarInfo) => void
}

type SonarImageType = {
    src?: string
    sonarInfo?: SonarInfo
    imgClassName?: string
    open?: () => void
    close?: () => void
}

const SonarImage = (
    props: SonarImageType,
    ref: React.Ref<SonarImageInterface>
) => {
    const imgRef = useRef<HTMLImageElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const tooltipRef = useRef<HTMLDivElement>(null)
    const [sonarInfo, setSonarInfo] = useState<SonarInfo | null>(
        props.sonarInfo || null
    )
    const [imgSrc, setImgSrc] = useState('')
    const [hover, setHover] = useState(true)
    const handleShowTooltip = (e: React.MouseEvent<HTMLCanvasElement>) => {
        if (sonarInfo && tooltipRef.current && canvasRef.current) {
            getRangeOnSonar(e, sonarInfo, tooltipRef.current)
        }
    }
    const handleHideTooltip = () => {
        if (tooltipRef.current) tooltipRef.current.style.display = 'none'
    }
    useEffect(() => {
        if (sonarInfo === null) return
        drawSonarOverlay(sonarInfo, canvasRef.current!)
    }, [sonarInfo])
    useEffect(() => {
        if (props.src) setImgSrc(props.src)
    }, [props.src])
    useImperativeHandle(ref, () => ({ imgRef, setImgSrc, setSonarInfo }))
    return (
        <div
            className={`${props.imgClassName} ${hover ? 'cursor-pointer' : ''}`}
            onClick={() => props.open && props.open()}>
            <div
                ref={tooltipRef}
                className={`abs display-none overflow-hidden z-3 padding-h-sm padding-w-md
          text-sm rounded-lg secondary-container-bg on-secondary-container`}
                style={{ maxWidth: '240px' }}
            />
            {props.open && (
                <div className={`abs bottom-12px right-12px z-3`}>
                    <Button
                        Icon={FullscreenIcon}
                        onClick={() => {
                            if (props.open) props.open()
                        }}
                    />
                </div>
            )}
            <img
                className={'w-100 h-100 rounded-sm object-fit-contain'}
                ref={imgRef}
                src={imgSrc}
                onMouseOver={() => {
                    setHover(true)
                }}
                onMouseOut={() => {
                    setHover(false)
                }}
            />
            <canvas
                ref={canvasRef}
                className={`abs top-0 left-0 w-100 h-100 object-fit-contain`}
                onMouseMove={(e) => handleShowTooltip(e)}
                onMouseOut={() => handleHideTooltip()}
            />
        </div>
    )
}

export default React.forwardRef(SonarImage)
