import React, { useEffect, useRef } from 'react'
import { sectionIdxToReadable } from '..'
import { getBlob } from '../../../backend'
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { imgSrcToBuffer } from '../../../types'

const REGEX_BOLD = /\*[^*]+[^ ]\*/g
const REGEX_ITALICS = /\_[^_]+[^ ]\_/g
const REGEX_HASH = /\W(\#[a-zA-Z]+\b)(?!;)/g
const REGEX_INFO_PANEL = /\&gt;info:(.*?)?[\r\n]/g
const REGEX_TIPS_PANEL = /\&gt;tips:(.*?)?[\r\n]/g
const REGEX_WARN_PANEL = /\&gt;warn:(.*?)?[\r\n]/g
const REGEX_CODE_BLOCK = /\```[^```]+[^ ]\```/g
const REGEX_IMAGE = /\!\[[^)]+.*\)/g
const REGEX_GROUP_LIST = /((\n-- .*)+)/g
const REGEX_LIST = /(\-\-|\+) .*\s?/g
const REGEX_GROUP_ORDERED_LIST = /((\n\+ .*)+)/g
const REGEX_ORDERED_LIST = /\+ .*\s?/g
const REGEX_TABLE = /((^\| .*)|([^ ]\| .*))+/g
const REGEX_TABLE_ROW = /\| .*\|\s?/g
const REGEX_WHITESPACE = /\s/g

export const prettifyText = (
  path: string,
  text: string,
  sectionIdx: number[],
  instance: IPublicClientApplication,
  account: AccountInfo | null
) => {
  const ref = useRef<HTMLDivElement>(null)
  const makeText = async () => {
    if (!ref.current || text == '') return
    let html = ref.current.innerHTML + '\r\n'
    html = await replaceAsync(
      html,
      REGEX_IMAGE,
      Image,
      path,
      sectionIdx,
      instance,
      account
    )
    html = html.replace(REGEX_INFO_PANEL, (text) => InfoPanel(text).trim())
    html = html.replace(REGEX_TIPS_PANEL, (text) => TipsPanel(text).trim())
    html = html.replace(REGEX_WARN_PANEL, (text) => WarnPanel(text).trim())
    html = html.replace(REGEX_TABLE, (text) => Table(text).trim())
    // todo: regex group list not working
    html = html.replace(REGEX_GROUP_LIST, (text) => List(text).trim())
    html = html.replace(REGEX_GROUP_ORDERED_LIST, (text) => List(text).trim())
    html = html.replace(REGEX_BOLD, (text) => BoldText(text).trim())
    html = html.replace(REGEX_CODE_BLOCK, (text) => CodeBlock(text).trim())
    // html = html.replace(REGEX_ITALICS, (text) => ItalicsText(text).trim())
    ref.current.innerHTML = html
  }
  useEffect(() => {
    makeText()
  }, [text])
  return (
    <div className='display-block' ref={ref}>
      {text.trim()}
    </div>
  )
}

const HashtagText = (str: string) => {
  return <span className='text-medium text-aqua'>{str}</span>
}

const BoldText = (str: string) => {
  return `<span class='header text-bold'>${str.slice(1, -1)}</span>`
}

const ItalicsText = (str: string) => {
  return `<span class='text-italics'>${str.slice(1, -1)}</span>`
}

const TipsPanel = (str: string) => {
  return Panel(
    str,
    '../../../assets/icons/tips_and_updates_black_24dp.svg',
    'secondary-light'
  )
}
const InfoPanel = (str: string) => {
  return Panel(
    str,
    '../../../assets/icons/info_black_24dp.svg',
    'warning-light'
  )
}
const WarnPanel = (str: string) => {
  return Panel(str, '../../../assets/icons/warning_black_24dp.svg', 'error')
}

const Panel = (str: string, src: string, color: string) => {
  return `<span class='flex margin-h-sm padding-h-sm padding-w-md rounded-sm ${color}-bg on-${color}'>
      <img height='24px' width='24px' class='on-${color}-filter' src='${src}' />
      <span class='text-bold padding-l-md flex-grow-1'>${str
        .trim()
        .slice(9)
        .trim()}</span>
    </span>`
}

const CodeBlock = (str: string) => {
  return `<div class='avoid-break padding-h-md padding-w-lg margin-t-sm surface-variant-light-bg surface-variant-light-border on-surface-variant-light rounded-sm'>${str
    .slice(3, -3)
    .trim()}</div>`
}

const Table = (str: string) => {
  const REGEX_ROWSPAN = /^(\^)+/
  const noLines = str.split('|').length - 1
  const isTable = noLines == 2 ? false : true
  let rowspanNum = 0
  let res = isTable
    ? "<table style='width: 100%; max-width: 800px; table-layout: fixed;'>"
    : ''
  res += str.trim().replace(
    REGEX_TABLE_ROW,
    (text) =>
      `<tr>${text
        .split('|')
        .map((cell) => {
          const rowspanMatch = cell.trim().match(REGEX_ROWSPAN)
          rowspanNum = rowspanMatch ? rowspanMatch[0].length + 1 : 0
          return !cell.replace(REGEX_WHITESPACE, '').length
            ? ''
            : `<td ${rowspanNum ? "rowspan='" + rowspanNum + "'" : ''}>${cell
                .trim()
                .replace(REGEX_ROWSPAN, '')}</td>`
        })
        .join('')}</tr>`
  )
  res += rowspanNum ? '</table>' : ''
  return res
}
const List = (str: string) => {
  let res = '<ul>'
  res += str
    .trim()
    .replace(REGEX_LIST, (text) => `<li>${text.slice(2).trim()}</li>`)
  return res + `</ul>`
}

const OrderedList = (str: string) => {
  let res = '<ol>'
  res += str
    .trim()
    .replace(REGEX_ORDERED_LIST, (text) => `<li>${text.slice(1).trim()}</li>`)
  return res + `</ol>`
}

const Image = async (
  str: string,
  path: string,
  sectionIdx: number[],
  instance: IPublicClientApplication,
  account: AccountInfo | null
) => {
  const REGEX_ALT = /\[(.*)\]/g
  const REGEX_SRC = /\(.*\)/g
  const images = str.split('!').slice(1)
  let res =
    "<div class='canvas-wrap flex flex-wrap justify-center space-between'>"
  let figureNum = sectionIdxToReadable(sectionIdx)
  for (let i = 0; i < images.length; i++) {
    const alt = images[i].match(REGEX_ALT)
    const src = images[i].match(REGEX_SRC)
    const altstr = alt && alt[0].slice(1, -1)
    const srcstr = src && src[0].slice(1, -1)

    const pathArray = path.split('/')
    const currContainer = pathArray[0].replace(/[.,\s]/g, '').toLowerCase()

    const textPath = pathArray
      .slice(1)
      .join('/')
      .replace(/[\s]/g, '_')
      .slice(0, -1)

    const imgBlob =
      srcstr &&
      (await getBlob(
        instance,
        account,
        'user1manual',
        currContainer,
        srcstr,
        textPath
      ))
    const image = URL.createObjectURL(imgBlob)

    res += `<div class='flex-col text-center'><img
              class='h-auto w-auto cursor-pointer animate-scale img-hover'
              style='max-height: 320px'
              alt='${altstr}'
              src='${image}'
            /><div class='text-italic text-sm'>Figure ${figureNum}-${
      i + 1
    }: ${altstr}</div></div>`
  }
  res += '</div>'
  return res
}

async function replaceAsync(
  string: string,
  regexp: RegExp,
  replacerFunction: any,
  path: string,
  sectionIdx: number[],
  instance: IPublicClientApplication,
  account: AccountInfo | null
) {
  const replacements = await Promise.all(
    Array.from(string.matchAll(regexp), (match) =>
      replacerFunction(...match, path, sectionIdx, instance, account)
    )
  )
  let i = 0
  return string.replace(regexp, () => replacements[i++])
}
