import React, { useState, useEffect } from 'react'
import errorPlaceholder from '../../../../../assets/images/errorPlaceholder.png'
import { ThumbnailType } from '../../../../../types'

type MarkerThumbnailType = {
  fetchSrc: ThumbnailType | undefined
  noOfMarkers: number
  index: number
  toggleViewMode: number
  setMarkerLoaded: (value: boolean) => void
  className?: string
}

const MarkerThumbnail = (props: MarkerThumbnailType) => {
  const {
    fetchSrc,
    noOfMarkers,
    index,
    toggleViewMode,
    setMarkerLoaded,
    className,
  } = props

  const [useClaheImage, setUseClaheImage] = useState<boolean>(true)
  const [frontCamImage, setFrontCamImage] = useState<string | null>(null)
  const [frontCamClaheImage, setFrontCamClaheImage] = useState<string | null>(
    null
  )
  const [sonarImage, setSonarImage] = useState<string | null>(null)

  const fetchImageSrc = async () => {
    await Promise.all([
      fetchSrc?.frontCamImage(),
      fetchSrc?.sonarImage(),
      fetchSrc?.frontCamClaheImage(),
    ]).then((output) => {
      setFrontCamImage(output[0] ? output[0] : '')
      setSonarImage(output[1] ? output[1] : '')
      setFrontCamClaheImage(output[2] ? output[2] : '')
    })
  }
  useEffect(() => {
    fetchImageSrc()
  }, [])

  return (
    <div
      onMouseOver={() => {
        setUseClaheImage(true)
      }}
      onMouseOut={() => {
        setUseClaheImage(true)
      }}>
      {frontCamImage !== null && toggleViewMode == 0 && (
        <img
          className={`${className} ${
            !useClaheImage ? 'display-block' : 'display-none'
          }`}
          src={frontCamImage !== '' ? frontCamImage : errorPlaceholder}
          onLoad={() => {
            if (noOfMarkers - 1 === index) {
              setMarkerLoaded(true)
            }
          }}
          onError={() => {
            if (noOfMarkers - 1 === index) {
              setMarkerLoaded(true)
            }
          }}
        />
      )}
      {frontCamClaheImage !== null && toggleViewMode == 0 && (
        <img
          className={`${className} ${
            useClaheImage ? 'display-block' : 'display-none'
          }`}
          src={
            frontCamClaheImage !== '' ? frontCamClaheImage : errorPlaceholder
          }
        />
      )}
      {sonarImage !== null && toggleViewMode == 1 && (
        <img
          className={`${className}`}
          src={sonarImage !== '' ? sonarImage : errorPlaceholder}
          onLoad={() => {
            if (noOfMarkers - 1 === index) {
              setMarkerLoaded(true)
            }
          }}
          onError={() => {
            if (noOfMarkers - 1 === index) {
              setMarkerLoaded(true)
            }
          }}
        />
      )}
      {(sonarImage === null || frontCamImage === null) && (
        <div
          className={`grey w-50 h-50 ${className}`}
          onLoad={() => {
            if (noOfMarkers - 1 === index) {
              setMarkerLoaded(true)
            }
          }}
          onError={() => {
            if (noOfMarkers - 1 === index) {
              setMarkerLoaded(true)
            }
          }}
        />
      )}
    </div>
  )
}

export default MarkerThumbnail
