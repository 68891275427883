import React from 'react'

export const VDivider = () => {
  return (
    <div className='padding-w-sm border-box'>
      <div style={{ width: '1px', height: '100%' }} className='surface-variant' />
    </div>
  )
}

type HDividerProps = {
  height?: number,
  className?: string
}

export const HDivider = ({ height, className }: HDividerProps) => {
  return (
    <div className={`${className} flex align-center`} style={{ height: height || 24 }}>
      <div style={{ width: '100%', height: '1px' }} className='surface-variant-bg' />
    </div>
  )
}

export const WBlock = () => {
  return (
    <div style={{ width: '8px', height: '100%' }} />
  )
}

export const HBlock = () => {
  return (
    <div style={{ width: '100%', height: '8px' }} />
  )
}