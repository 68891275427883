import React, { useState, useEffect, useRef, useContext } from 'react'
import ReportPanel from './panels/ReportPanel/ReportPanel'
import PCDReportPanel from './panels/ReportPanel/PCDReportPanel'
import MultipleReportPanel from './panels/ReportPanel/MultipleReportPanel'
import { FolderPanel } from './panels/FolderPanel/FolderPanel'
import AssetPanel from './panels/AssetPanel/AssetPanel'
import {
    isMobile,
    checkRepeatedMarkers,
    unregexAssetName,
    regexAssetName,
} from '../utils/general'
import {
    LatLngCoord,
    MarkerType,
    MarkerContainerPairType,
    DateType,
    ModelType,
    ThumbnailType,
    ActivityType,
    Activities,
    ResourceLog,
    HashtagType,
    Hashtag,
    Status,
    PageType,
} from '../../types'

import { useAccount, useMsal } from '@azure/msal-react'
import {
    getContainers,
    getStorageAccounts,
    getBlob,
    getBlobsInContainer,
    getFolders,
} from '../../backend'
import { createContainer, uploadBlob } from '../../backend/msal/put'
import ModalOverlay from '../elements/ModalOverlay'
import { deleteBlob, deleteContainer } from '../../backend/msal/delete'
import NavigationBar from './NavigationBar'
import Dialog from '../elements/Dialogs/Dialog'
import { addLog } from '../utils/user_activities'
import Snackbar from '../elements/Dialogs/Snackbar'
import SearchTag from './panels/ReportPanel/SearchTag'
import LoadingSpinner from '../elements/loadingSpinner'
import TextInput from '../elements/inputs/TextInput'
import FileUploadDialog from '../elements/Dialogs/FileUploadDialog'
import { readMetadata } from '../map/utils/png-metadata'
import { FilterType } from '../utils/FilterUtils'
import { AppContext } from '../../store/context'
import { MSALActionType } from '../../store/reducers'
import { PECPanel } from './panels/ReportPanel/PECPanel'
import { msalFetchHashtagList, HASHTAG_STG } from '../../backend/hashtag'
import VideoPage from '../videopage'

const STORAGE_NAME_TO_REMOVE = ['SAMBALUI', 'VEHICLEOS', 'USER MANUAL']
const ASSET_NAME_TO_REMOVE = [
    'LOG',
    'COMPONENTHASHTAGS',
    'STRUCTUREHASHTAGS',
    'UTILS',
]

const usePrevious = (value: any) => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    }, [value])
    return ref.current
}

type UTMCoord = {
    northing: string
    easting: string
    zone: string
}
const defaultUtmZone = '48N'
const defaultUtmCoord: UTMCoord = {
    northing: '',
    easting: '',
    zone: defaultUtmZone,
}

type ReportPageProps = {
    goHome: () => void
    setResourceLog: React.Dispatch<React.SetStateAction<ResourceLog>>
    resourceLog: ResourceLog
}

const ReportPage = ({
    goHome,
    setResourceLog,
    resourceLog,
}: ReportPageProps) => {
    const htStorage = HASHTAG_STG
    const logStorage = 'log'
    const mobile = isMobile()
    const { instance, accounts, inProgress } = useMsal()
    const username = accounts.length > 0 ? accounts[0].username : 'demo@beex.sg'
    const account = useAccount(accounts[0] || {})
    const { dispatch } = useContext(AppContext)

    const [edited, setEdited] = useState(false)
    const [storageAccountList, setStorageAccountList] = useState<
        string[] | null
    >(null) // storage accounts available, default 1
    const [assetList, setAssetList] = useState<string[] | null>(null)
    // const [containerMap, setContainerMap] = useState<Map<
    //   string,
    //   string[]
    // > | null>(null)
    const [dateFolderList, setDateFolderList] = useState<string[] | null>(null)
    const [storageAccount, setStorageAccount] = useState('')
    const [loadingPage, setLoadingPage] = useState<boolean>(false)
    const [noStorage, setNoStorage] = useState(false)
    const prevStorageAccount = usePrevious(storageAccount)
    const [asset, setAsset] = useState('')
    const [dateFolder, setDateFolder] = useState('')
    // const prevContainer = usePrevious(container);
    const [location, setLocation] = useState<LatLngCoord | null>(null)
    const [models, setModels] = useState<ModelType[]>([])
    const [markerMap, setMarkerMap] = useState<Map<
        string,
        (MarkerType | ModelType)[]
    > | null>(null)
    const [marker, setMarker] = useState<MarkerType | null>(null)
    const [model, setModel] = useState<ModelType | null>(null)
    const [thumbnailMap, setThumbnailMap] = useState<Map<
        string,
        ThumbnailType
    > | null>(null)
    const [hashtagMap, setHashtagMap] = useState<Map<string, Hashtag[]> | null>(
        null
    ) // key: bag_id or file name
    const [allHashtags, setAllHashTags] = useState<Hashtag[]>([])
    const [selectedMarkerItems, setSelectedMarkerItems] = useState<
        MarkerContainerPairType[]
    >([])
    const [filter, setFilter] = useState<FilterType>({
        search: '',
        sTags: null,
        cTags: null,
        sTagIDs: null,
        cTagIDs: null,
        flagged: false,
    })
    const [toggleViewMode, setToggleViewMode] = useState<number>(0)
    const [toggleHashtag, setToggleHashtag] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false) // For Modal loading, not implemented yet
    const [viewSelectedItems, setViewSelectedItems] = useState<boolean>(false)
    const [hashtagList, setHashtagList] = useState<Hashtag[]>([])

    // ADD ASSET BUTTON
    const assetRegex = /^[a-zA-Z0-9][a-zA-Z0-9 ]*$/
    const [notifyAddAsset, setNotifyAddAsset] = useState<boolean>(false)
    const [assetName, setAssetName] = useState<string>('')
    const [showError, setShowError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    // EDIT HASHTAG BUTTON
    const [notifyEditHashtags, setNotifyEditHashtags] = useState<boolean>(false)
    const [componentStructureTags, setComponentStructureTags] = useState<
        Hashtag[]
    >([
        { type: HashtagType.STRUCTURE, content: '' },
        { type: HashtagType.COMPONENT, content: '' },
    ])
    const [selectedHashtags, setSelectedHashtags] = useState<Hashtag[]>([])

    // DELETE BUTTON
    const [notifyDelete, setNotifyDelete] = useState<boolean>(false)
    const [deleteSelectedItems, setDeleteSelectedItems] =
        useState<boolean>(false)

    // SNACKBAR
    const [snackbarText, setSnackbarText] = useState<string>('')
    const [snackbarStatus, setSnackbarStatus] = useState<Status | undefined>(
        undefined
    )
    const setSnackbarSuccessText = (str: string) => {
        setSnackbarText(str)
        setSnackbarStatus(Status.SUCCESS)
    }
    const setSnackbarErrorText = (str: string) => {
        setSnackbarText(str)
        setSnackbarStatus(Status.ERROR)
    }
    const resetSnackbar = () => {
        setSnackbarText('')
        setSnackbarStatus(undefined)
    }

    // ADD MODEL BUTTON
    const [notifyAddNonGLBModel, setNotifyAddNonGLBModel] =
        useState<boolean>(false)
    const [notifyAddGLBModel, setNotifyAddGLBModel] = useState<boolean>(false)
    const [modelName, setModelName] = useState<string>('')
    const [modelDate, setModelDate] = useState<DateType>({
        day: '',
        month: '',
        year: '',
    })
    const [modelFile, setModelFile] = useState<File | null>(null)
    const [uploadModelLoading, setUploadModelLoading] = useState<boolean>(false)

    const [jsonFile, setJsonFile] = useState<File | null>(null)
    // Metadata for glb model
    const [modelUtmCoord, setModelUtmCoord] =
        useState<UTMCoord>(defaultUtmCoord)
    const [modelHeading, setModelHeading] = useState<string>('')
    const [modelStartTime, setModelStartTime] = useState<string>('')
    const [modelEndTime, setModelEndTime] = useState<string>('')
    const [modelOnedrivePath, setModelOnedrivePath] = useState<string>('')
    const numValidators = {
        positive: (num: number) => num > 0,
        heading: (num: number) => num >= 0 && num <= 360,
        utmNorthing: (num: number) => num >= 0 && num <= 10000000,
        utmEasting: (num: number) => num > 0 && num <= 833000,
        utmZoneNumber: (num: number) => num >= 1 && num <= 60,
    }
    const isFloat = (str: string) => !isNaN(parseFloat(str))
    const isInt = (str: string) => !isNaN(parseInt(str))
    const strValidators = {
        heading: (str: string) =>
            isFloat(str) && numValidators.heading(parseFloat(str)),
        startTime: (str: string) => {
            const isValid =
                isFloat(str) && numValidators.positive(parseFloat(str))
            let beforeEndTime = true
            if (
                modelEndTime &&
                isFloat(modelEndTime) &&
                numValidators.positive(parseFloat(modelEndTime))
            )
                beforeEndTime = parseFloat(modelEndTime) > parseFloat(str)
            return isValid && beforeEndTime
        },
        endTime: (str: string) => {
            const isValid =
                isFloat(str) && numValidators.positive(parseFloat(str))
            let afterStartTime = true
            if (
                modelStartTime &&
                isFloat(modelStartTime) &&
                numValidators.positive(parseFloat(modelStartTime))
            )
                afterStartTime = parseFloat(modelStartTime) < parseFloat(str)
            return isValid && afterStartTime
        },
        utmNorthing: (str: string) =>
            isFloat(str) && numValidators.utmNorthing(parseFloat(str)),
        utmEasting: (str: string) =>
            isFloat(str) && numValidators.utmEasting(parseFloat(str)),
        utmZone: (str: string) => {
            if (str.length < 2 || str.length > 3) return false
            const zoneLetter = str.slice(-1)
            const zoneNumber = str.slice(0, -1)
            return (
                isInt(zoneNumber) &&
                numValidators.utmZoneNumber(parseInt(zoneNumber)) &&
                zoneLetter >= 'A' &&
                zoneLetter <= 'Z'
            )
        },
        onedrivePath: (str: string) => str.endsWith('.beex'),
    }

    // For streaming of .beex file for glb & obj models
    const [playableBeexFile, setPlayableBeexFile] = useState<File | undefined>(
        undefined
    )
    const [isBeexFilePlaying, setIsBeexFilePlaying] = useState<boolean>(false)
    const [beexStartTime, setBeexStartTime] = useState<number | undefined>(
        undefined
    )
    const [beexEndTime, setBeexEndTime] = useState<number | undefined>(
        undefined
    )

    const clearBeexFilePlayerCache = () => {
        setPlayableBeexFile(undefined)
        setBeexStartTime(undefined)
        setBeexEndTime(undefined)
        setIsBeexFilePlaying(false)
    }

    // SELECT ITEMS
    const [selectButtons, setSelectButtons] = useState<Map<string, boolean>>(
        new Map()
    )

    useEffect(() => {
        let arrayDataToStore = []
        if (viewSelectedItems) {
            for (let i = 0; i < selectedMarkerItems.length; i++) {
                let currFolder = selectedMarkerItems[i].folder
                let dataToStore: ActivityType = {
                    timestamp: new Date().toISOString(),
                    user: username,
                    action: Activities[Activities.OPENSNAPSHOT],
                    folder: currFolder,
                }
                const marker = selectedMarkerItems[i].marker
                const model = selectedMarkerItems[i].model
                if (marker != undefined) {
                    dataToStore['marker'] = {
                        bag_id: marker.bag_id,
                    }
                }
                if (model != undefined) {
                    dataToStore['model'] = {
                        fileName: model.file,
                    }
                }
                arrayDataToStore.push(dataToStore)
            }
        }
        account
            ? addLog(
                  storageAccount,
                  asset,
                  instance,
                  account,
                  undefined,
                  arrayDataToStore
              )
            : null
    }, [viewSelectedItems])

    useEffect(() => {
        if (deleteSelectedItems) {
            const selectedItemsByDate = new Map()
            for (let i = 0; i < selectedMarkerItems.length; i++) {
                const markerModelArray =
                    selectedItemsByDate.get(selectedMarkerItems[i].folder) || []
                markerModelArray.push(
                    selectedMarkerItems[i].marker
                        ? selectedMarkerItems[i].marker
                        : selectedMarkerItems[i].model
                )
                selectedItemsByDate.set(
                    selectedMarkerItems[i].folder,
                    markerModelArray
                )
            }
            const selectedItemsByDateKeys = Array.from(
                selectedItemsByDate.keys()
            )

            // find difference in dictionaries
            const markerMapKeys = Array.from(markerMap?.keys() || [])
            const newMarkerMap = new Map()
            for (let i = 0; i < markerMapKeys.length; i++) {
                const key = markerMapKeys[i]
                const markerArray = markerMap?.get(key)
                const dateArray = selectedItemsByDate.get(key) || []
                let difference
                if (dateArray.length > 0 && dateArray != undefined) {
                    difference = markerArray?.filter(
                        (x) => !dateArray.includes(x)
                    )
                } else {
                    difference = markerArray
                }
                newMarkerMap.set(key, difference)
            }
            setSelectedMarkerItems([])
            setMarkerMap(newMarkerMap)

            for (let i = 0; i < selectedItemsByDateKeys.length; i++) {
                const folder = selectedItemsByDateKeys[i]
                const folderArray = selectedItemsByDate.get(folder)
                deleteItemsFromFolderInJson(folder, folderArray)
                deleteImagesFromFolder(folder, folderArray)
                deleteItemsFromHashtagJson(folderArray)
            }

            // Update Snackbar
            setSnackbarSuccessText(
                `${selectedMarkerItems.length} item(s) deleted!`
            )
        }

        async function deleteItemsFromFolderInJson(
            currFolder: string,
            markerModels: Array<any>
        ) {
            const jsonObject = await getBlob(
                instance,
                account,
                storageAccount,
                asset,
                `${currFolder}.json`,
                currFolder
            ).catch((error) => {
                console.log(error)
            })
            let arrayDataToStore = []
            for (let i = 0; i < markerModels.length; i++) {
                if (isMarkerType(markerModels[i])) {
                    let dataToStore: ActivityType = {
                        timestamp: new Date().toISOString(),
                        user: username,
                        action: Activities[Activities.DELETESNAPSHOT],
                        folder: currFolder,
                    }
                    dataToStore['marker'] = { bag_id: markerModels[i].bag_id }
                    arrayDataToStore.push(dataToStore)
                    for (let j = 0; j < jsonObject.markers.length; j++) {
                        if (
                            jsonObject.markers[j].bag_id ==
                            markerModels[i].bag_id
                        ) {
                            jsonObject.markers.splice(j, 1)
                        }
                    }
                } else if (isModelType(markerModels[i])) {
                    let dataToStore: ActivityType = {
                        timestamp: new Date().toISOString(),
                        user: username,
                        action: Activities[Activities.DELETESNAPSHOT],
                        folder: currFolder,
                    }
                    dataToStore['model'] = { fileName: markerModels[i].file }
                    arrayDataToStore.push(dataToStore)
                    for (let j = 0; j < jsonObject.model.length; j++) {
                        if (jsonObject.model[j].file == markerModels[i].file) {
                            jsonObject.model.splice(j, 1)
                        }
                    }
                }
            }

            account
                ? await addLog(
                      storageAccount,
                      asset,
                      instance,
                      account,
                      undefined,
                      arrayDataToStore
                  )
                : null

            // Check json file's content, delete if no content, else update
            if (
                (!jsonObject['markers'] ||
                    jsonObject['markers'].length === 0) &&
                (!jsonObject['model'] || jsonObject['model'].length === 0)
            ) {
                await deleteBlob(
                    instance,
                    account,
                    storageAccount,
                    asset,
                    `${currFolder}.json`,
                    currFolder
                )
            } else {
                await uploadBlob(
                    instance,
                    account,
                    storageAccount,
                    asset,
                    `${currFolder}.json`,
                    JSON.stringify(jsonObject),
                    currFolder
                ).catch((error) => {
                    console.log(error)
                })
            }
        }

        async function deleteImagesFromFolder(
            currFolder: string,
            markerModels: Array<any>
        ) {
            const jpegToDelete = [
                'sonar.jpeg',
                'front_cam_clahe.jpeg',
                'front_cam.jpeg',
            ]
            for (let i = 0; i < markerModels.length; i++) {
                if (isMarkerType(markerModels[i])) {
                    for (const jpegItem of jpegToDelete) {
                        await deleteBlob(
                            instance,
                            account,
                            storageAccount,
                            asset,
                            `${markerModels[i].bag_id}_${jpegItem}`,
                            currFolder
                        )
                    }
                } else if (isModelType(markerModels[i])) {
                    await deleteBlob(
                        instance,
                        account,
                        storageAccount,
                        asset,
                        `${markerModels[i].file}`,
                        currFolder
                    )
                }
            }
        }

        async function deleteItemsFromHashtagJson(markerModels: Array<any>) {
            for (let i = 0; i < markerModels.length; i++) {
                let idName: string = isMarkerType(markerModels[i])
                    ? markerModels[i].bag_id
                    : markerModels[i].file
                const hashtags = hashtagMap?.get(idName) || []
                console.log(hashtags, idName)
                for (let hashtag of hashtags) {
                    let jsonFile: string[] = await getBlob(
                        instance,
                        account,
                        storageAccount,
                        asset,
                        `${hashtag.content}.json`,
                        `${HashtagType[hashtag.type].toLowerCase()}${htStorage}` // hashtag type is the folder name
                    )

                    jsonFile = jsonFile.filter((id) => id != idName)

                    await uploadBlob(
                        instance,
                        account,
                        storageAccount,
                        asset,
                        `${hashtag.content}.json`,
                        JSON.stringify(jsonFile),
                        `${HashtagType[hashtag.type].toLowerCase()}${htStorage}`
                    )
                }
            }
        }
        setDeleteSelectedItems(false)
    }, [deleteSelectedItems, selectedMarkerItems])

    const addAsset = async (storageName: string, assetName: string) => {
        if (assetName.match(assetRegex) != null) {
            try {
                await createContainer(
                    instance,
                    account,
                    storageName,
                    unregexAssetName(assetName)
                )
            } catch (error: any) {
                setSnackbarErrorText(error.message)
            }

            await loadAssetList()
            setAssetName('')
            setNotifyAddAsset(false)
        }
    }

    const loadStorageAccounts = async () => {
        // if (!account || inProgress !== "none") return
        const storageAccounts = await getStorageAccounts(instance, account)
        if (storageAccounts) {
            for (let i = 0; i < STORAGE_NAME_TO_REMOVE.length; i++) {
                let idx = storageAccounts.indexOf(STORAGE_NAME_TO_REMOVE[i]) // used to store UI/OS updates. do not show to users.
                if (idx > -1) {
                    storageAccounts.splice(idx, 1)
                }
            }
            if (storageAccounts.sort().length === 0) {
                //Set no containers
                setNoStorage(true)
            }
        }
        if (storageAccounts.length > 0) setStorageAccount(storageAccounts[0])
        setStorageAccountList(storageAccounts)
    }

    const loadAssetList = async () => {
        if (storageAccount === '') return
        setAssetList(null)
        let currAssets = await getContainers(instance, account, storageAccount)
        if (currAssets) {
            for (let i = 0; i < ASSET_NAME_TO_REMOVE.length; i++) {
                let idx = currAssets.indexOf(ASSET_NAME_TO_REMOVE[i]) // used to store UI/OS updates. do not show to users.
                if (idx > -1) {
                    currAssets.splice(idx, 1)
                }
            }
        }
        // currAssets = currAssets.map((x: string) => regexAssetName(x))
        setAssetList(currAssets)
    }

    const deleteAsset = async (container: string) => {
        try {
            await deleteContainer(instance, account, storageAccount, container)
            // const newAssetList =
            //   assetList?.slice(assetList?.indexOf(container)) || []
            assetList?.splice(assetList?.indexOf(container), 1)
            setAssetList(assetList)
            setSnackbarSuccessText('Asset successfully deleted')
        } catch (error: any) {
            setSnackbarErrorText(error.message)
        }
    }

    const loadFolders = async () => {
        // what is this function for?
        if (storageAccount === '' || asset === '') return
        const folders = await getFolders(
            instance,
            account,
            storageAccount,
            asset
        )
        if (folders) {
            let updatedFolders = folders
            const foldersToRemove = [
                'assets',
                'log',
                'componenthashtags',
                'structurehashtags',
            ]
            for (let folder of foldersToRemove) {
                const idxToRemove = updatedFolders.indexOf(folder)
                idxToRemove != -1 ? updatedFolders.splice(idxToRemove) : null
            }
            setDateFolderList(updatedFolders.sort())
        }
        const blob = await getBlob(
            instance,
            account,
            storageAccount,
            asset,
            '_asset.json',
            `asset`
        ).catch((error) => {
            console.log('no blob because: ', error)
        })

        if (blob && blob.model) setModels(blob.model)
    }

    const loadMarkers = async () => {
        let bagidMarkerMap = markerMap
            ? markerMap
            : new Map<string, (MarkerType | ModelType)[]>()
        let bagidThumbnailMap = thumbnailMap
            ? thumbnailMap
            : new Map<string, ThumbnailType>()
        if (dateFolderList) {
            for (let folder of dateFolderList) {
                if (
                    folder !== 'asset' &&
                    folder !== 'componenthashtags' &&
                    folder != 'structurehashtags' &&
                    folder !== 'log' &&
                    folder !== 'utils'
                ) {
                    const blob = await getBlob(
                        instance,
                        account,
                        storageAccount,
                        asset,
                        `${folder}.json`,
                        folder
                    ).catch((error) => {
                        console.log(error)
                    })
                    var modelMarkerList: (MarkerType | ModelType)[] = []
                    modelMarkerList =
                        blob && blob.markers
                            ? modelMarkerList.concat(
                                  checkRepeatedMarkers(
                                      blob.markers as MarkerType[]
                                  )
                              )
                            : modelMarkerList
                    // ignore northing/easting attributes for xyz models
                    const newModelArray =
                        blob && blob.model
                            ? blob.model.map((model: any) => {
                                  if (model.id === 'xyz') {
                                      delete model.northing
                                      delete model.easting
                                      delete model.zone_number
                                      delete model.zone_letter
                                  }
                                  return model
                              })
                            : []
                    modelMarkerList =
                        blob && blob.model
                            ? modelMarkerList.concat(newModelArray)
                            : modelMarkerList
                    modelMarkerList.length > 0 &&
                        bagidMarkerMap.set(folder, modelMarkerList)
                    blob &&
                        blob.markers &&
                        blob.markers.map(async (marker: MarkerType) => {
                            fetchImagesForMarker(folder, marker.bag_id).then(
                                (images) =>
                                    bagidThumbnailMap.set(marker.bag_id, images)
                            )
                        })
                    blob &&
                        blob.model &&
                        blob.model.map(async (model: ModelType) => {
                            if (model.id === 'mosaic') {
                                fetchImagesForMosaic(folder, model.file).then(
                                    (images) =>
                                        bagidThumbnailMap.set(
                                            model.file,
                                            images
                                        )
                                )
                            }
                        })
                }
            }
        }
        setMarkerMap(bagidMarkerMap)
        setThumbnailMap(bagidThumbnailMap)
    }

    // const getStorageAccountFromContainer = async (containerName: string) => {
    //   // assumes unique naming between containers across storage accounts
    //   const storageAccount =
    //     containerMap &&
    //     Array.from(containerMap.keys()).find((key) =>
    //       (containerMap.get(key) || []).includes(containerName)
    //     )
    //   return storageAccount
    // }

    const loadHashtags = async () => {
        if (!asset || asset === '') return
        await fetchHashtags().then((x) => {
            processHashtagMap(x)
            setHashtagMap(x)
            loadAllHashtags(x)
        })
    }
    const processHashtagMap = (hashtagMap: Map<string, string[]>) => {
        if (!hashtagMap || !(hashtagMap.size > 0)) return
        if (hashtagMap.values()) {
            const hashtagMapkeys = Array.from(hashtagMap.keys())
            const hashtagMapArray = Array.from(hashtagMap.values())
            if (hashtagMapkeys.length === hashtagMapArray.length) {
                for (let i = 0; i < hashtagMapArray.length; i++) {
                    const hashtagList = hashtagMapArray[i]
                    if (hashtagList.length > 5) {
                        const cleanedHashtagList =
                            cleanRepeatedHashtags(hashtagList)
                        hashtagMap.set(hashtagMapkeys[i], cleanedHashtagList)
                    }
                }
            }
        }
    }
    const cleanRepeatedHashtags = (a: string[]) => [...new Set(a)]
    const loadAllHashtags = (hashtagMap: Map<string, Hashtag[]>) => {
        if (!hashtagMap || !(hashtagMap.size > 0)) return
        hashtagMap?.values() &&
            setAllHashTags([
                ...new Set(Array.from(hashtagMap?.values()).flat()),
            ])
    }
    // const loadAssetModels = async () => {
    //   if (container === '') return
    //   const blob = await getBlob(
    //     instance,
    //     account,
    //     storage,
    //     container,
    //     `${container}.json`
    //   )
    //   if (blob) {
    //     if (blob.model) {
    //       const appendModels = blob.model.map((item: ModelType) => {
    //         return { ...item, date: container, asset: storage }
    //       })
    //       setModels([...models, ...appendModels])
    //     }
    //   }
    // }
    const getHashtagBagIdPairList = async (htList: Hashtag[]) => {
        return (
            await Promise.all(
                htList.map(async (ht) => ({
                    hashtag: {
                        type: ht.type,
                        content: ht.content.replace('.json', ''),
                    },
                    bagIdList: await getBlob(
                        instance,
                        account,
                        storageAccount,
                        asset,
                        ht.content,
                        `${HashtagType[ht.type].toLowerCase()}${htStorage}` // hashtag type is the folder name
                    ),
                }))
            )
        )
            .flatMap((pair) => {
                return pair.bagIdList.map((bagId: string) => ({
                    bagId: bagId,
                    hashtag: pair.hashtag,
                }))
            })
            .reduce((currMap, currPair) => {
                if (currMap.has(currPair.bagId)) {
                    const hashtagArray = [
                        ...currMap.get(currPair.bagId),
                        currPair.hashtag,
                    ]
                    currMap.set(currPair.bagId, hashtagArray)
                } else {
                    currMap.set(currPair.bagId, [currPair.hashtag])
                }
                return currMap
            }, new Map<string, Hashtag[]>())
    }
    const fetchHashtags = async () => {
        const htList = await fetchHashtagList()
        const bagIdPair = await getHashtagBagIdPairList(htList)
        return await getHashtagBagIdPairList(htList)
    }
    const fetchHashtagList = async () => {
        let htList: Hashtag[] = []
        const msal = { instance, account, storage: storageAccount, asset }
        try {
            const structureHashtagList = await msalFetchHashtagList(
                msal,
                HashtagType.STRUCTURE,
                false
            )
            const componentHashtagList = await msalFetchHashtagList(
                msal,
                HashtagType.COMPONENT,
                false
            )
            for (const structure of structureHashtagList) {
                htList.push({ type: HashtagType.STRUCTURE, content: structure })
            }
            for (const component of componentHashtagList) {
                htList.push({ type: HashtagType.COMPONENT, content: component })
            }
        } catch (error) {
            // createHashtagContainer(HashtagType.STRUCTURE)
            // createHashtagContainer(HashtagType.COMPONENT)
            //   .then(() => {
            //     fetchHashtagList()
            //   })
            //   .catch((error) => {
            //     console.log(error)
            //   })
            console.log(error)
        } finally {
            return htList ? htList : []
        }
    }
    const fetchImagesForMarker = async (
        folder: string,
        bag_id: string
    ): Promise<ThumbnailType> => {
        return {
            frontCamImage: async () =>
                fetchImage(folder, `${bag_id}_front_cam.jpeg`),
            frontCamClaheImage: async () =>
                fetchImage(folder, `${bag_id}_front_cam_clahe.jpeg`),
            sonarImage: async () => fetchImage(folder, `${bag_id}_sonar.jpeg`),
            fullDepthCamImage: async () =>
                fetchImage(folder, `${bag_id}_fulldepth_cam.jpeg`),
        }
    }
    const fetchImagesForMosaic = async (
        folder: string,
        file: string
    ): Promise<ThumbnailType> => {
        return {
            frontCamImage: async () => fetchImage(folder, `${file}`),
            frontCamClaheImage: async () => {
                return ''
            },
            sonarImage: async () => {
                return ''
            },
            fullDepthCamImage: async () => {
                return ''
            },
        }
    }
    const fetchImage = async (folder: string, filename: string) => {
        try {
            const blob = await getBlob(
                instance,
                account,
                storageAccount,
                asset,
                filename,
                folder
            )
            if (blob) {
                const image = URL.createObjectURL(blob)
                return image
            } else return ''
        } catch (error) {
            console.log(error)
            return ''
        }
    }

    const handleSaveAllHashtags = async (
        newHashtagList: Hashtag[],
        hashtagsEdited?: { old: Hashtag; new: Hashtag }[]
    ) => {
        const hashtagsRemoved = allHashtags.filter(
            (x) =>
                !newHashtagList.find(
                    (y) => x.content === y.content && x.type === y.type
                )
        )
        let updatedHashtagMap = hashtagMap
        for (let edited of hashtagsEdited!) {
            const blob = await fetchHashtag(edited.old)
            await uploadHashtag(edited.new, blob)
            await deleteHashtagBlob(edited.old)
            for (let [key, value] of updatedHashtagMap!.entries()) {
                const updatedArray = value
                updatedArray.forEach((x, idx) => {
                    if (x.content === edited.old.content) {
                        updatedArray[idx] = edited.new
                    }
                })
            }
        }
        let files = []
        for (let hashtag of hashtagsRemoved) {
            deleteHashtagBlob(hashtag)
            for (let [key, value] of updatedHashtagMap!.entries()) {
                updatedHashtagMap?.set(
                    key,
                    value.filter((x) => x.content != hashtag.content)
                )
                if (
                    value.findIndex((x) => x.content === hashtag.content) != -1
                ) {
                    files.push(key)
                }
            }
        }

        setHashtagMap(updatedHashtagMap)
        setAllHashTags(newHashtagList)
        setSnackbarSuccessText('Hashtags updated in asset!')
    }
    const handleSaveEdit = async (
        asset: string,
        newHashtagList: Hashtag[],
        newMarker?: MarkerType,
        newModel?: ModelType,
        folder?: string
    ) => {
        if (
            !account ||
            folder === 'asset' ||
            folder === 'hashtags' ||
            folder === 'log'
        )
            return
        const blob = await getBlob(
            instance,
            account,
            storageAccount,
            asset,
            `${newModel ? newModel?.date || '' : folder}.json`,
            newModel ? newModel?.date || '' : folder
        ).catch((error) => {
            console.log(error)
            return
        })
        const newMarkers =
            newMarker && getUpdatedMarkerList(blob.markers, newMarker)
        const newModels = newModel && getUpdatedModelList(blob.model, newModel)
        const models = newMarker && folder && getModelList(folder)
        const markers = newModel && getMarkerList(newModel?.date)
        if (newMarkers) {
            const markersJson = {
                id: folder,
                markers: newMarkers,
                model: models,
            }
            const newMarkerModelList = models
                ? [...newMarkers, ...models]
                : newMarkers
            uploadBlob(
                instance,
                account,
                storageAccount,
                asset,
                `${folder}.json`,
                JSON.stringify(markersJson),
                folder
            )
            if (markerMap === null) return
            const newMarkerMap = new Map<string, (ModelType | MarkerType)[]>()
            markerMap.forEach((value, key) => newMarkerMap.set(key, value))
            folder && newMarkerMap?.set(folder, newMarkerModelList)
            setMarkerMap(newMarkerMap)
        }
        if (newModels) {
            const modelDate = newModel?.date
            if (!modelDate) {
                console.log('Model has no date!')
                return
            }
            const markersJson = {
                id: newModel?.date,
                markers: markers,
                model: newModels,
            }
            const newMarkerModelList = markers
                ? [...markers, ...newModels]
                : newModels
            uploadBlob(
                instance,
                account,
                storageAccount,
                asset,
                `${folder}.json`,
                JSON.stringify(markersJson),
                folder
            )
            if (markerMap === null) return
            const newMarkerMap = new Map<string, (ModelType | MarkerType)[]>()
            markerMap.forEach((value, key) => newMarkerMap.set(key, value))
            newMarkerMap?.set(modelDate, newMarkerModelList)
            setMarkerMap(newMarkerMap)
        }
        let originalList: Hashtag[] | undefined
        let oldMarker: MarkerType | undefined
        let oldModel: ModelType | undefined
        blob.markers.forEach((markerBlob: MarkerType) => {
            if (markerBlob.bag_id === newMarker?.bag_id) {
                oldMarker = markerBlob
                return
            }
        })
        newModel &&
            blob.model.forEach((modelBlob: ModelType) => {
                if (modelBlob.file === newModel.file) {
                    oldModel = modelBlob
                }
            })
        if (newMarker) {
            originalList = hashtagMap?.get(newMarker.bag_id)
                ? hashtagMap?.get(newMarker.bag_id)
                : []
            await handleUploadHashtags(newHashtagList, newMarker.bag_id)
            hashtagMap?.set(newMarker.bag_id, newHashtagList)
            const index = selectedMarkerItems.findIndex(
                (x) => x.marker?.bag_id === newMarker.bag_id
            )

            if (selectedMarkerItems.length > 0) {
                var obj = selectedMarkerItems
                obj[index].marker = newMarker
                setSelectedMarkerItems(obj)
            }
        } else if (newModel) {
            originalList = hashtagMap?.get(newModel ? newModel.file : '')
                ? hashtagMap?.get(newModel ? newModel.file : '')
                : []
            await handleUploadHashtags(newHashtagList, newModel.file)
            hashtagMap?.set(newModel.file, newHashtagList)
            const index = selectedMarkerItems.findIndex(
                (x) => x.model?.file === newModel?.file
            )
            if (selectedMarkerItems.length > 0) {
                var obj = selectedMarkerItems
                obj[index].model = newModel
                setSelectedMarkerItems(obj)
            }
        }

        updateLogOnEdit(
            storageAccount,
            oldMarker,
            newMarker,
            oldModel,
            newModel,
            originalList,
            newHashtagList
        )
        setEdited(false)
        // update allHashtags
        let newAllHashtagsList = new Set<Hashtag>(allHashtags)
        newHashtagList.map((x) => newAllHashtagsList.add(x))
        setAllHashTags(Array.from(newAllHashtagsList.values()))

        // show snackbar
        setSnackbarSuccessText('Snapshot saved!')
    }

    const handleSaveMultipleEdit = async (
        selectedMarkerItems: MarkerContainerPairType[],
        newHashtagList: Hashtag[]
    ) => {
        console.log(selectedMarkerItems)
        if (newHashtagList.length == 0) return
        let storeHashtagFiles: Map<MarkerType | ModelType, Hashtag[]> =
            new Map()
        for (let i = 0; i < selectedMarkerItems.length; i++) {
            const marker = selectedMarkerItems[i].marker
            const model = selectedMarkerItems[i].model
            const folder = selectedMarkerItems[i].folder
            let oldHashtagList: Hashtag[]

            if (marker) {
                oldHashtagList = hashtagMap?.get(marker.bag_id) || []
                let updatedHashtagList = newHashtagList
                let componentExist = false,
                    structureExist = false
                for (let hashtag of updatedHashtagList) {
                    if (hashtag.type === HashtagType.COMPONENT) {
                        componentExist = true
                    } else if (hashtag.type === HashtagType.STRUCTURE) {
                        structureExist = true
                    }
                }
                if (!componentExist) {
                    updatedHashtagList = updatedHashtagList.concat(
                        oldHashtagList.filter(
                            (x) => x.type === HashtagType.COMPONENT
                        )
                    )
                } else if (!structureExist) {
                    updatedHashtagList = updatedHashtagList.concat(
                        oldHashtagList.filter(
                            (x) => x.type === HashtagType.STRUCTURE
                        )
                    )
                }
                storeHashtagFiles.set(marker, updatedHashtagList)
                handleSaveEdit(
                    asset,
                    updatedHashtagList,
                    marker,
                    undefined,
                    folder
                )
            } else if (model) {
                oldHashtagList = hashtagMap?.get(model.file) || []
                let updatedHashtagList = newHashtagList
                let componentExist = false,
                    structureExist = false
                for (let hashtag of updatedHashtagList) {
                    if (hashtag.type === HashtagType.COMPONENT) {
                        componentExist = true
                    } else if (hashtag.type === HashtagType.STRUCTURE) {
                        structureExist = true
                    }
                }
                if (!componentExist) {
                    updatedHashtagList = updatedHashtagList.concat(
                        oldHashtagList.filter(
                            (x) => x.type === HashtagType.COMPONENT
                        )
                    )
                } else if (!structureExist) {
                    updatedHashtagList = updatedHashtagList.concat(
                        oldHashtagList.filter(
                            (x) => x.type === HashtagType.STRUCTURE
                        )
                    )
                }
                storeHashtagFiles.set(model, updatedHashtagList)
                handleSaveEdit(
                    asset,
                    updatedHashtagList,
                    undefined,
                    model,
                    folder
                )
            }
        }
    }

    // const handleSaveMultipleHashtags = async (
    //   storeHashtagFiles: Map<MarkerType | ModelType, Hashtag[]>
    // ) => {
    //   for (let [markerModel, hashtagList] of storeHashtagFiles) {
    //     const marker = markerModel as MarkerType
    //     const model = markerModel as ModelType
    //     if (marker) {
    //       let originalList = hashtagMap?.get(marker.bag_id)
    //       hashtagMap?.set(marker.bag_id, hashtagList)
    //       updateLogOnEdit(
    //         storage,
    //         marker,
    //         marker,
    //         undefined,
    //         undefined,
    //         originalList,
    //         hashtagList
    //       )
    //     } else if (model) {
    //       let originalList = hashtagMap?.get(model.file)
    //       hashtagMap?.set(model.file, hashtagList)
    //       updateLogOnEdit(
    //         storage,
    //         marker,
    //         marker,
    //         undefined,
    //         undefined,
    //         originalList,
    //         hashtagList
    //       )
    //     }
    //     let newAllHashtagsList = new Set<Hashtag>(allHashtags)
    //     hashtagList.map((x) => newAllHashtagsList.add(x))
    //     setAllHashTags(Array.from(newAllHashtagsList.values()))
    //   }
    //   // handleUploadHashtags()
    //   setEdited(false)
    // }

    const handleUploadHashtags = async (
        newHashtagList: Hashtag[],
        bagId: string
    ) => {
        if (!hashtagMap) return
        const originalList = hashtagMap?.get(bagId)
            ? hashtagMap?.get(bagId)
            : []
        if (!newHashtagList || !originalList) return
        const addList = getArrayDifference(originalList, newHashtagList)
        const removalList = getArrayDifference(newHashtagList, originalList)
        addHashtags(addList, bagId)
        removeHashtags(removalList, bagId)
    }
    const handleViewSelectedItems = () => {
        if (selectedMarkerItems.length > 1) setViewSelectedItems(true)
        else {
            // setContainer(selectedMarkerItems[0].container)
            setDateFolder(selectedMarkerItems[0].folder)
            selectedMarkerItems[0].marker &&
                setMarker(selectedMarkerItems[0].marker)
            selectedMarkerItems[0].model &&
                setModel(selectedMarkerItems[0].model)
        }
    }

    function getByValue(map: Map<any, Hashtag[]>, searchHashtag: Hashtag) {
        let output = []
        for (let [key, value] of map.entries()) {
            if (
                value.some(
                    (x) =>
                        x.type === searchHashtag.type &&
                        x.content === searchHashtag.content
                )
            )
                output.push(key) // only push file names containing hashtag
        }
        return output
    }
    const addHashtags = async (addList: Hashtag[], bagId: string) => {
        let bagIdList: Set<string> = new Set([bagId])
        addList.forEach((ht) => {
            let currFilesWithHashtag = new Set(
                (hashtagMap && getByValue(hashtagMap, ht)) || []
            )
            let updatedFilesWithHashtag = new Set([
                ...currFilesWithHashtag,
                ...bagIdList,
            ])
            uploadHashtag(ht, updatedFilesWithHashtag)
                // fetchHashtag(ht)
                //   .then((bagList) => {
                //     bagIdList = bagIdList.concat(bagList)
                //     uploadHashtag(ht, bagIdList)
                //   })
                .catch((error) => {
                    // createHashtagContainer(ht.type)
                    //   .then(() => {
                    //     uploadHashtag(ht, bagIdList)
                    //   })
                    //   .catch((error) => {
                    //     uploadHashtag(ht, bagIdList)
                    //   })
                    console.log(error)
                })
        })
    }
    const removeHashtags = (removeList: Hashtag[], bagId: string) => {
        removeList.forEach((ht) => {
            // fetchHashtag(ht).then((bagList) => {
            let currFilesWithHashtag = hashtagMap && getByValue(hashtagMap, ht)
            currFilesWithHashtag?.splice(currFilesWithHashtag.indexOf(bagId), 1)
            // bagList.splice(bagList.indexOf(bagId), 1)
            // bagList.length === 0
            currFilesWithHashtag?.length === 0
                ? deleteHashtagBlob(ht)
                : uploadHashtag(ht, currFilesWithHashtag)
            // })
        })
    }
    const fetchHashtag = async (hashtag: Hashtag) => {
        return getBlob(
            instance,
            account,
            storageAccount,
            asset,
            `${hashtag.content}.json`,
            `${HashtagType[hashtag.type].toLowerCase()}${htStorage}`
        )
    }

    const loadFileDrop = async (file: File, isGLB: boolean) => {
        const fileType = file.name.split('.').slice(-1)[0] // final segment to get file type in file name
        if (fileType === 'xyz') {
            // Check if xyz file contains UTM coordinates
            const comment = (await file.text()).split('\n')[0]
            const array = comment.split(' ')
            const northing = Number(array[1].slice(0, -1))
            const easting = Number(array[2].slice(0, -1))
            const zone_number = Number(array[3].slice(0, -1))
            const zone_letter = array[3].slice(-1)

            if (
                !(northing && easting && zone_number) ||
                !zone_letter.match(/^[C-X]$/)
            ) {
                alert(
                    '.xyz file is corrupted! Please select another .xyz file.'
                )
                return
            }
        } else if (fileType === 'png') {
            // Check if mosaic contains all metadata
            const buffer = new Uint8Array(await file.arrayBuffer())
            const metadata = readMetadata(buffer).tEXt

            if (
                !(
                    metadata['mosaic.northing'] &&
                    metadata['mosaic.zone_num'] &&
                    metadata['mosaic.zone_letter'] &&
                    metadata['mosaic.heading'] &&
                    metadata['mosaic.height'] &&
                    metadata['mosaic.width']
                )
            ) {
                alert(
                    '.png file is corrupted! Please select another .png file.'
                )
                return
            }
        } else if (!['glb', 'obj'].includes(fileType)) {
            alert(
                `Please only upload ${
                    isGLB ? 'a .glb or .obj' : 'an .xyz or .png'
                } file.`
            )
            return
        }

        // Do autofill for date and name
        const fileName = file.name
        const regex = /(\d{4})-(\d{2})-(\d{2})/
        const match = regex.exec(fileName)

        if (match) {
            const year = match[1]
            const month = match[2]
            const day = match[3]
            const index = match.index
            const title = fileName.substring(0, index).replace(/_+$/, '')

            setModelDate({ year: year, month: month, day: day })
            setModelName(title)
        }

        setModelFile(file)
    }

    const uploadModel = async () => {
        if (
            !modelFile ||
            modelDate.year === '' ||
            modelDate.month === '' ||
            modelDate.day === '' ||
            modelName === ''
        ) {
            alert('Please fill in all empty fields!')
            return
        } else if (
            ['glb', 'obj'].includes(modelFile.name.split('.').slice(-1)[0]) &&
            (modelUtmCoord.easting === '' ||
                modelUtmCoord.northing === '' ||
                modelHeading === '')
        ) {
            alert('Please fill in all empty fields!')
            return
        } else if (
            !(
                modelDate.year.match(/^[1-9]\d{3}$/) &&
                modelDate.month.match(/^(0?[1-9]|1[0-2])$/) &&
                modelDate.day.match(/^(0?[1-9]|[1-2][0-9]|3[0-1])$/)
            )
        ) {
            alert('Date in wrong format!')
            return
        }

        const currentDate = `${modelDate.year}-${modelDate.month}-${modelDate.day}`
        // Will only check for the .json file in the same date-folder
        let jsonObject = null
        try {
            jsonObject = await getBlob(
                instance,
                account,
                storageAccount,
                asset,
                `${currentDate}.json`,
                currentDate
            )
        } catch (error) {
            console.log('Error checking for existing json file:', error)
        }

        if (jsonObject === null)
            jsonObject = { id: currentDate, model: [], markers: [] }
        else if (jsonObject['model'] === undefined)
            jsonObject = { ...jsonObject, model: [] }

        if (
            jsonObject.model.some(
                (single_model: any) => single_model.file === modelFile.name
            )
        ) {
            alert(`File already uploaded in this asset on ${currentDate}!`)
            return
        }

        setUploadModelLoading(true)
        const fileType = modelFile.name.split('.').slice(-1)[0] // final segment to get file type in file name
        let modelData: ModelType | null = null
        if (fileType === 'xyz') {
            modelData = {
                id: 'xyz',
                asset: asset,
                heading: 0,
                file: modelFile.name,
                title: modelName,
                depth: 0,
                scale: 1,
                materialSize: 0.1,
                comment: '',
                date: currentDate,
            }
        } else if (fileType === 'png') {
            modelData = {
                id: 'mosaic',
                asset: asset,
                file: modelFile.name,
                heading: 0,
                depth: 0,
                date: currentDate,
                title: modelName,
                comment: '',
            }
        } else if (['glb', 'obj'].includes(fileType)) {
            modelData = {
                id: fileType,
                asset: asset,
                file: modelFile.name,
                heading: parseFloat(modelHeading),
                depth: 0,
                date: currentDate,
                title: modelName,
                comment: '',
                northing: parseFloat(modelUtmCoord.northing),
                easting: parseFloat(modelUtmCoord.easting),
                zone_letter: modelUtmCoord.zone.slice(-1),
                zone_number: parseInt(modelUtmCoord.zone.slice(0, -1)),
            }
            if (modelStartTime)
                modelData = {
                    ...modelData,
                    start_time: parseFloat(modelStartTime),
                }
            if (modelEndTime)
                modelData = { ...modelData, end_time: parseFloat(modelEndTime) }
            if (modelOnedrivePath)
                modelData = { ...modelData, onedrive_path: modelOnedrivePath }
        }

        jsonObject.model.push(modelData)
        await uploadBlob(
            instance,
            account,
            storageAccount,
            asset,
            `${currentDate}.json`,
            JSON.stringify(jsonObject),
            currentDate
        )
        await uploadBlob(
            instance,
            account,
            storageAccount,
            asset,
            modelFile.name,
            modelFile,
            currentDate
        )

        await loadFolders()
        const isGLBModel = ['glb', 'obj'].includes(fileType)
        resetModelFileToUpload(isGLBModel)
        isGLBModel
            ? setNotifyAddGLBModel(false)
            : setNotifyAddNonGLBModel(false)
        setSnackbarSuccessText(`${modelName} successfully uploaded!`)
    }

    const resetGLBExclusiveDataFields = () => {
        setModelUtmCoord(defaultUtmCoord)
        setModelHeading('')
        setModelStartTime('')
        setModelEndTime('')
        setModelOnedrivePath('')
    }

    const resetModelDataFields = (isGLBModel: boolean) => {
        setModelDate({ day: '', month: '', year: '' })
        setModelName('')
        if (isGLBModel) resetGLBExclusiveDataFields()
    }

    const resetModelFileToUpload = (isGLBModel: boolean) => {
        resetModelDataFields(isGLBModel)
        setModelFile(null)
        if (isGLBModel) setJsonFile(null)
        setUploadModelLoading(false)
    }

    const parseJsonFile = async (file: File, targetModelFileName?: string) => {
        resetGLBExclusiveDataFields() // The json file will only auto-fill in these fields
        const jsonObject: any = await readJSONFile(file)
        if (jsonObject === null || jsonObject === undefined) return

        if (jsonObject.model === undefined) {
            const utmCoord: UTMCoord = { easting: '', northing: '', zone: '' }
            if (jsonObject.northing !== undefined)
                utmCoord.northing = jsonObject.northing
            if (jsonObject.easting !== undefined)
                utmCoord.easting = jsonObject.easting
            if (
                jsonObject.zone_letter !== undefined &&
                jsonObject.zone_number !== undefined
            )
                utmCoord.zone = `${jsonObject.zone_number}${jsonObject.zone_letter}`
            if (utmCoord.easting || utmCoord.northing || utmCoord.zone)
                utmCoord.zone
                    ? setModelUtmCoord(utmCoord)
                    : setModelUtmCoord({ ...utmCoord, zone: defaultUtmZone })

            if (jsonObject.heading !== undefined)
                setModelHeading(jsonObject.heading)
            if (jsonObject.start_time !== undefined)
                setModelStartTime(jsonObject.start_time)
            if (jsonObject.end_time !== undefined)
                setModelEndTime(jsonObject.end_time)
            if (jsonObject.onedrive_path !== undefined)
                setModelOnedrivePath(jsonObject.onedrive_path)
        } else if (targetModelFileName) {
            const model = jsonObject.model.filter(
                (model: any) => model.file === targetModelFileName
            )
            if (model.length) {
                const modelFound = model[0]
                if (
                    modelFound.date &&
                    modelFound.date.split('-').length === 3
                ) {
                    const dateComponents = modelFound.date.split('-')
                    setModelDate({
                        year: dateComponents[0],
                        month: dateComponents[1],
                        day: dateComponents[2],
                    })
                }
                const utmCoord: UTMCoord = {
                    easting: '',
                    northing: '',
                    zone: '',
                }
                if (modelFound.northing !== undefined)
                    utmCoord.northing = modelFound.northing
                if (modelFound.easting !== undefined)
                    utmCoord.easting = modelFound.easting
                if (
                    modelFound.zone_letter !== undefined &&
                    modelFound.zone_number !== undefined
                )
                    utmCoord.zone = `${modelFound.zone_number}${modelFound.zone_letter}`
                if (utmCoord.easting || utmCoord.northing || utmCoord.zone)
                    utmCoord.zone
                        ? setModelUtmCoord(utmCoord)
                        : setModelUtmCoord({
                              ...utmCoord,
                              zone: defaultUtmZone,
                          })

                if (modelFound.heading !== undefined)
                    setModelHeading(modelFound.heading)
                if (modelFound.start_time !== undefined)
                    setModelStartTime(modelFound.start_time)
                if (modelFound.end_time !== undefined)
                    setModelEndTime(modelFound.end_time)
                if (modelFound.onedrive_path !== undefined)
                    setModelOnedrivePath(modelFound.onedrive_path)
            }
        }

        setJsonFile(file)
    }

    function readJSONFile(file: File) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()

            fileReader.onload = () => {
                if (
                    fileReader.result === null ||
                    fileReader.result instanceof ArrayBuffer
                )
                    return reject('Failed to read file')
                try {
                    const jsonData = JSON.parse(fileReader.result)
                    resolve(jsonData)
                } catch (error) {
                    reject(error)
                }
            }

            fileReader.onerror = () => {
                reject(fileReader.error)
            }

            fileReader.readAsText(file)
        })
    }

    const uploadHashtag = async (hashtag: Hashtag, data: any) => {
        // if (hashtag.type != HashtagType.COMPONENT) {
        //   createContainer(instance, account, storage, `component${htStorage}`)
        // }
        // if (hashtag.type != HashtagType.STRUCTURE) {
        //   createContainer(instance, account, storage, `component${htStorage}`)
        // }
        uploadBlob(
            instance,
            account,
            storageAccount,
            asset,
            `${hashtag.content}.json`,
            JSON.stringify(data),
            `${HashtagType[hashtag.type].toLowerCase()}${htStorage}`
        )
    }
    const deleteHashtagBlob = async (hashtag: Hashtag) => {
        return await deleteBlob(
            instance,
            account,
            storageAccount,
            asset,
            `${hashtag.content}.json`,
            `${HashtagType[hashtag.type].toLowerCase()}${htStorage}`
        )
    }
    const createHashtagContainer = async (hashtagType: HashtagType) => {
        return await createContainer(
            instance,
            account,
            storageAccount,
            `${HashtagType[hashtagType].toLowerCase()}${htStorage}`
        )
    }
    /** Log file stuff */
    const updateOpenSnapshot = async (
        folderName: string,
        bagId?: string,
        fileName?: string
    ) => {
        let dataToStore: ActivityType | undefined

        if (bagId) {
            dataToStore = {
                timestamp: new Date().toISOString(),
                user: username,
                action: Activities[Activities.OPENSNAPSHOT],
                folder: folderName,
                marker: {
                    bag_id: bagId,
                },
            }
        } else if (fileName) {
            dataToStore = {
                timestamp: new Date().toISOString(),
                user: username,
                action: Activities[Activities.OPENSNAPSHOT],
                folder: folderName,
                model: {
                    fileName: fileName,
                },
            }
        }

        if (dataToStore && account)
            await addLog(storageAccount, asset, instance, account, dataToStore)
    }

    const updateLogOnStorageAccess = async (assetAccess: string) => {
        // const storageName = await getStorageAccountFromContainer(containerName)
        const storageName = storageAccount
        const dataToStore = {
            timestamp: new Date().toISOString(),
            user: username,
            action: Activities[Activities.ACCESS],
        }
        account && storageName
            ? addLog(storageName, assetAccess, instance, account, dataToStore)
            : null
    }
    const updateLogOnEdit = (
        storageName: string,
        oldMarker?: MarkerType,
        newMarker?: MarkerType,
        oldModel?: ModelType,
        newModel?: ModelType,
        oldHashtagList?: Hashtag[],
        newHashtagList?: Hashtag[]
    ) => {
        const sameHashtag =
            JSON.stringify(oldHashtagList) == JSON.stringify(newHashtagList) // fastest comparison
        let dataToStore: ActivityType | undefined
        if (oldMarker && newMarker) {
            oldMarker.comment ? null : (oldMarker.comment = '')
            newMarker.comment ? null : (newMarker.comment = '')
            dataToStore = {
                timestamp: new Date().toISOString(),
                user: username,
                folder: dateFolder,
                action: Activities[Activities.EDITSNAPSHOT],
                marker: {
                    bag_id: newMarker.bag_id,
                    prevName:
                        oldMarker.name == newMarker.name
                            ? undefined
                            : oldMarker.name,
                    editedName:
                        oldMarker.name == newMarker.name
                            ? undefined
                            : newMarker.name,
                    prevComment:
                        oldMarker.comment == newMarker.comment
                            ? undefined
                            : oldMarker.comment,
                    editedComment:
                        oldMarker.comment == newMarker.comment
                            ? undefined
                            : newMarker.comment,
                    prevHashtags: sameHashtag ? undefined : oldHashtagList,
                    editedHashtags: sameHashtag ? undefined : newHashtagList,
                    prevFlagStatus:
                        oldMarker.no_anode == newMarker.no_anode
                            ? undefined
                            : oldMarker.no_anode,
                    newFlagStatus:
                        oldMarker.no_anode == newMarker.no_anode
                            ? undefined
                            : newMarker.no_anode,
                },
            }
        } else if (oldModel && newModel) {
            oldModel.comment ? null : (oldModel.comment = '')
            newModel.comment ? null : (newModel.comment = '')
            dataToStore = {
                timestamp: new Date().toISOString(),
                user: username,
                folder: dateFolder,
                action: Activities[Activities.EDITSNAPSHOT],
                model: {
                    fileName: newModel.file,
                    prevName:
                        oldModel.title == newModel.title
                            ? undefined
                            : oldModel.title,
                    editedName:
                        oldModel.title == newModel.title
                            ? undefined
                            : newModel.title,
                    prevComment:
                        oldModel.comment == newModel.comment
                            ? undefined
                            : oldModel.comment,
                    editedComment:
                        oldModel.comment == newModel.comment
                            ? undefined
                            : newModel.comment,
                    prevHashtags: sameHashtag ? undefined : oldHashtagList,
                    editedHashtags: sameHashtag ? undefined : newHashtagList,
                },
            }
        }
        dataToStore && account
            ? addLog(storageName, asset, instance, account, dataToStore)
            : null
    }
    /** Gets list of current Markers */
    const getMarkerList = (date?: string) => {
        if (date) {
            const markerList = markerMap?.get(date)
            return markerList?.filter((marker: MarkerType | ModelType) => {
                return 'bag_id' in marker
            })
        }
        console.log('Model has no date!')
        return
    }
    /** Gets list of markers when a marker is edited */
    const getUpdatedMarkerList = (
        markers: MarkerType[],
        newMarker: MarkerType
    ) => {
        return checkRepeatedMarkers(markers)
            ?.map((marker: MarkerType | ModelType) => {
                if (!('bag_id' in marker)) return
                return marker.bag_id === newMarker.bag_id ? newMarker : marker
            })
            .filter((x): x is MarkerType => x?.bag_id !== undefined)
    }
    /** Gets list of current models */
    const getModelList = (date: string) => {
        const markerList = markerMap?.get(date)
        return markerList?.filter((marker: MarkerType | ModelType) => {
            return 'file' in marker
        })
    }
    /** Gets list of models when a model is edited */
    const getUpdatedModelList = (models: ModelType[], newModel: ModelType) => {
        return models
            ?.map((marker: MarkerType | ModelType) => {
                if (!('file' in marker)) return
                return marker.file === newModel.file ? newModel : marker
            })
            .filter((x): x is ModelType => x?.file !== undefined)
    }
    const getArrayDifference = (original: Hashtag[], current: Hashtag[]) => {
        return current.filter((x) => !original.includes(x))
    }
    const getModelNameFromFileName = (filename: string) => {
        //2nd layer of protection to deal with non-pcd model files too
        if (!filename) return ''
        if (!filename.includes('.pcd')) return filename
        const regex = new RegExp('_', 'g')
        return filename.replace(regex, ' ').replace('.pcd', '')
    }
    const isMarkerType = (x: any): x is MarkerType => {
        return (x as MarkerType).bag_id !== undefined
    }
    const isModelType = (x: any): x is ModelType => {
        return (x as ModelType).file !== undefined
    }

    // useEffect(() => {
    //   const storageAccount =
    //     containerMap &&
    //     Array.from(containerMap.keys()).find((key) =>
    //       (containerMap.get(key) || []).includes(container)
    //     )
    //   storageAccount && setStorage(storageAccount)
    // }, [container])

    useEffect(() => {
        loadStorageAccounts()
    }, [account, instance, inProgress])

    useEffect(() => {
        setLoadingPage(true)
        const storageAccountChange = async () => {
            if (prevStorageAccount !== storageAccount) {
                await loadAssetList()
            }
            setLoadingPage(false)
        }
        storageAccountChange()
        dispatch({
            type: MSALActionType.SET_STORAGE,
            payload: storageAccount,
        })
    }, [storageAccount])

    useEffect(() => {
        setDateFolderList(null)
        loadFolders()
        setHashtagMap(null)
        loadHashtags()
        setSelectedMarkerItems([])
        setToggleViewMode(0)
        setToggleHashtag(0)
        setResourceLog({
            loginTime: new Date().toISOString(),
            isLogged: true,
            storage: storageAccount,
            container: asset,
        })
        dispatch({
            type: MSALActionType.SET_ASSET,
            payload: asset,
        })
    }, [asset])

    // On page change
    useEffect(() => {
        setMarkerMap(null)
        setThumbnailMap(null)
        // setModelMap(null)
        loadMarkers()
    }, [dateFolderList])

    // useEffect(() => {
    //   loadAssetModels()
    // }, [container])

    useEffect(() => {
        setHashtagList([])
        if (!hashtagMap) return
        let list
        if (marker) {
            list = hashtagMap.get(marker.bag_id)
        } else if (model) {
            list = hashtagMap.get(model.file)
        }
        list && setHashtagList(list)
    }, [marker, model])

    useEffect(() => {
        window.document
            .getElementById('main')
            ?.scrollTo({ top: 0, behavior: 'smooth' })
    }, [toggleViewMode])

    useEffect(() => {
        window.document
            .getElementById('main')
            ?.scrollTo({ top: 0, behavior: 'smooth' })
    }, [toggleHashtag])

    return (
        <div
            className={`padding-b-lg flex flex-col w-100 align-center
        justify-start align-start overflow-auto min-height-min-content border-box
        ${mobile ? 'padding-w-lg' : 'padding-w-xxlg'}`}
            style={{ maxWidth: '1600px' }}>
            <NavigationBar
                asset={asset}
                storage={storageAccount}
                allHashtags={allHashtags}
                selectedItemNum={selectedMarkerItems.length}
                goHome={goHome}
                goBack={() => {
                    if (storageAccount !== '' || asset !== '') {
                        // setStorage('')
                        setAsset('')
                        setDateFolder('')
                    }
                    setResourceLog({
                        loginTime: resourceLog.loginTime,
                        isLogged: false,
                        storage: storageAccount,
                        container: asset,
                    })
                }}
                goToStorage={() => {
                    // setStorage('')
                    setAsset('')
                    setDateFolder('')
                }}
                toggleViewMode={toggleViewMode}
                toggleHashtag={toggleHashtag}
                selectButtons={selectButtons}
                setFilter={setFilter}
                setViewMode={setToggleViewMode}
                setToggleHashtag={setToggleHashtag}
                setSelectedItems={setSelectedMarkerItems}
                setSelectButtons={setSelectButtons}
                setViewSelectedItems={setViewSelectedItems}
                setDeleteSelectedItems={setDeleteSelectedItems}
                setNotifyDelete={setNotifyDelete}
                setNotifyEditHashtags={setNotifyEditHashtags}
                dropdownList={
                    !asset ? storageAccountList || undefined : undefined
                }
                setDropdownSelected={setStorageAccount}
                dropdownSelected={storageAccount}
                setNotifyAddAsset={setNotifyAddAsset}
                setNotifyAddNonGLBModel={setNotifyAddNonGLBModel}
                setNotifyAddGLBModel={setNotifyAddGLBModel}
            />
            {notifyAddNonGLBModel && (
                <Dialog
                    text={`Upload a mosaic or xyz model in ${asset}`}
                    description={`Please fill in all required fields below.`}
                    buttonText={`Add`}
                    onClick={async () => {
                        // addAsset(storageAccount, assetName)
                        uploadModel()
                    }}
                    onClose={() => {
                        setModelName('')
                        setModelDate({ day: '', month: '', year: '' })
                        setModelFile(null)
                        setNotifyAddNonGLBModel(false)
                    }}
                    boxClassName={'w-40'}
                    loadingSubmit={uploadModelLoading}>
                    <div className='w-100'>
                        <div className='padding-md'>
                            <div className='h5'>File to upload:</div>
                            <FileUploadDialog
                                loadFile={(file) => {
                                    loadFileDrop(file, false)
                                }}
                                type={modelFile ? 'model_uploaded' : 'model'}
                                fileName={modelFile?.name}
                            />
                        </div>

                        <div className='padding-md'>
                            <div className='w-100 h5'>Model name:</div>
                            <TextInput
                                value={modelName}
                                onChange={(value) => {
                                    setModelName(value.toString())
                                }}
                                title={`Model name`}
                            />
                        </div>

                        <div className='flex flex-row flex-wrap justify-around align-center align-content-center padding-md'>
                            <div className='w-100 h5'>Date of model:</div>
                            <TextInput
                                className='w-20 padding-b-sm'
                                title='YYYY'
                                regex={/^[1-9]\d{3}$/}
                                error={'Format!'}
                                value={modelDate.year}
                                onChange={(value) => {
                                    modelDate.year = value.toString()
                                    setModelDate(modelDate)
                                }}
                            />
                            <div className='h1'>-</div>
                            <TextInput
                                className='w-20 padding-b-sm'
                                title='MM'
                                regex={/^(0?[1-9]|1[0-2])$/}
                                error={'Format!'}
                                value={modelDate.month}
                                onChange={(value) => {
                                    modelDate.month = value
                                        .toString()
                                        .padStart(2, '0')
                                    setModelDate(modelDate)
                                }}
                            />
                            <div className='h1'>-</div>
                            <TextInput
                                className='w-20 padding-b-sm'
                                title='DD'
                                regex={/^(0?[1-9]|[1-2][0-9]|3[0-1])$/}
                                error={'Format!'}
                                value={modelDate.day}
                                onChange={(value) => {
                                    modelDate.day = value
                                        .toString()
                                        .padStart(2, '0')
                                    setModelDate(modelDate)
                                }}
                            />
                        </div>
                    </div>
                </Dialog>
            )}
            {notifyAddGLBModel && (
                <Dialog
                    text={`Upload a glb/obj model in ${asset}`}
                    description={`Please fill in all required fields below.`}
                    buttonText={`Add`}
                    onClick={uploadModel}
                    onClose={() => {
                        resetModelFileToUpload(true)
                        setNotifyAddGLBModel(false)
                    }}
                    boxClassName={'w-50'}
                    loadingSubmit={uploadModelLoading}>
                    <div className='w-100 flex'>
                        <div className='w-50'>
                            <div>
                                <div className='h5'>
                                    Model file (.glb or .obj) to upload:
                                </div>
                                <FileUploadDialog
                                    loadFile={(file) =>
                                        loadFileDrop(file, true)
                                    }
                                    type={
                                        modelFile ? 'model_uploaded' : 'model'
                                    }
                                    fileName={modelFile?.name}
                                    filesToReupload={['.glb', '.obj']}
                                />
                            </div>
                            <div>
                                <div className='h5'>
                                    Metadata file (.json) to upload (optional):
                                </div>
                                <FileUploadDialog
                                    loadFile={(file) =>
                                        parseJsonFile(file, modelFile?.name)
                                    }
                                    type={
                                        jsonFile
                                            ? 'json_file_uploaded'
                                            : 'json_file'
                                    }
                                    fileName={jsonFile?.name}
                                    filesToReupload={['.json']}
                                />
                            </div>
                        </div>
                        <div className='w-50'>
                            <div className='flex'>
                                <div className='padding-md'>
                                    <TextInput
                                        title={`Model name`}
                                        value={modelName}
                                        onChange={(value) =>
                                            setModelName(value.toString())
                                        }
                                    />
                                </div>
                                <div className='padding-md'>
                                    <TextInput
                                        title={`Vehicle heading (°)`}
                                        value={modelHeading}
                                        onChange={(value) =>
                                            setModelHeading(value.toString())
                                        }
                                        error={'Invalid angle range'}
                                        strValidator={strValidators.heading}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-row flex-wrap justify-around align-center align-content-center padding-md'>
                                <div className='w-100 h5'>Date of model:</div>
                                <TextInput
                                    className='w-20 padding-b-xxsm'
                                    title='YYYY'
                                    value={modelDate.year}
                                    onChange={(value) => {
                                        modelDate.year = value.toString()
                                        setModelDate(modelDate)
                                    }}
                                    error={'Format!'}
                                    regex={/^[1-9]\d{3}$/}
                                />
                                <div className='h1'>-</div>
                                <TextInput
                                    className='w-20 padding-b-xxsm'
                                    title='MM'
                                    value={modelDate.month}
                                    onChange={(value) => {
                                        modelDate.month = value
                                            .toString()
                                            .padStart(2, '0')
                                        setModelDate(modelDate)
                                    }}
                                    error={'Format!'}
                                    regex={/^(0?[1-9]|1[0-2])$/}
                                />
                                <div className='h1'>-</div>
                                <TextInput
                                    className='w-20 padding-b-xxsm'
                                    title='DD'
                                    value={modelDate.day}
                                    onChange={(value) => {
                                        modelDate.day = value
                                            .toString()
                                            .padStart(2, '0')
                                        setModelDate(modelDate)
                                    }}
                                    error={'Format!'}
                                    regex={/^(0?[1-9]|[1-2][0-9]|3[0-1])$/}
                                />
                            </div>

                            <div className='flex flex-row flex-wrap justify-around align-center align-content-center padding-md'>
                                <div className='w-100 h5'>UTM Coordinates:</div>
                                <TextInput
                                    className='w-25 padding-b-xxsm'
                                    title='Northing'
                                    value={modelUtmCoord.northing}
                                    onChange={(value) => {
                                        modelUtmCoord.northing =
                                            value.toString()
                                        setModelUtmCoord(modelUtmCoord)
                                    }}
                                    error={'Format!'}
                                    strValidator={strValidators.utmNorthing}
                                />
                                <TextInput
                                    className='w-20 padding-b-xxsm'
                                    title='Easting'
                                    value={modelUtmCoord.easting}
                                    onChange={(value) => {
                                        modelUtmCoord.easting = value.toString()
                                        setModelUtmCoord(modelUtmCoord)
                                    }}
                                    error={'Format!'}
                                    strValidator={strValidators.utmEasting}
                                />
                                <TextInput
                                    className='w-20 padding-b-xxsm'
                                    title='Zone'
                                    value={modelUtmCoord.zone}
                                    onChange={(value) => {
                                        modelUtmCoord.zone = value.toString()
                                        setModelUtmCoord(modelUtmCoord)
                                    }}
                                    error={'Format!'}
                                    strValidator={strValidators.utmZone}
                                />
                            </div>
                            <div className='flex'>
                                <div className='padding-md'>
                                    <TextInput
                                        title={`Start time (optional)`}
                                        value={modelStartTime}
                                        onChange={(value) =>
                                            setModelStartTime(value.toString())
                                        }
                                        error={'Invalid start time'}
                                        strValidator={strValidators.startTime}
                                    />
                                </div>
                                <div className='padding-md'>
                                    <TextInput
                                        title={`End time (optional)`}
                                        value={modelEndTime}
                                        onChange={(value) =>
                                            setModelEndTime(value.toString())
                                        }
                                        error={'Invalid end time'}
                                        strValidator={strValidators.endTime}
                                    />
                                </div>
                            </div>
                            <div className='padding-md'>
                                <TextInput
                                    title={`Onedrive path (optional)`}
                                    value={modelOnedrivePath}
                                    onChange={(value) =>
                                        setModelOnedrivePath(value.toString())
                                    }
                                    error={'The path must end with .beex'}
                                    strValidator={strValidators.onedrivePath}
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
            {notifyAddAsset && (
                <Dialog
                    text={`Add an asset in ${storageAccount}?`}
                    description={`Please do not input any special character.`}
                    buttonText={`Add`}
                    onClick={() => {
                        addAsset(storageAccount, assetName)
                    }}
                    onClose={() => setNotifyAddAsset(false)}
                    boxClassName={'w-20'}>
                    <TextInput
                        value={assetName}
                        onChange={(value) => {
                            setAssetName(value.toString())
                            if (
                                assetList?.includes(
                                    regexAssetName(value.toString())
                                )
                            ) {
                                // needs to regex to compare with existing list
                                setShowError(true)
                                setErrorMessage('Asset name already exists!')
                            } else {
                                setShowError(false)
                                setErrorMessage(
                                    'Minimum 3 characters (including spaces)!'
                                )
                            }
                        }}
                        error={errorMessage}
                        title={'Asset Name'}
                        regex={assetRegex}
                        className={'w-100 padding-h-0'}
                        showError={showError}
                    />
                </Dialog>
            )}
            {notifyEditHashtags && (
                <Dialog
                    text={`Editing hashtags of ${selectedMarkerItems.length} items(s)`}
                    description={``}
                    buttonText={'Save'}
                    onClick={() => {
                        handleSaveMultipleEdit(
                            selectedMarkerItems,
                            selectedHashtags
                        )
                        setNotifyEditHashtags(false)
                    }}
                    onClose={() => setNotifyEditHashtags(false)}
                    boxClassName={`flex flex-wrap`}>
                    <div className={'w-100 flex flex-row'}>
                        <div className={'min-width-150px padding-md'}>
                            <span>Structure:</span>
                            <SearchTag
                                placeholder={'N/A'}
                                allTags={
                                    allHashtags?.filter(
                                        (x) => x.type == HashtagType.STRUCTURE
                                    ) || []
                                }
                                onChange={(hashtagSelected) => {
                                    let newArray = componentStructureTags
                                    newArray[HashtagType.STRUCTURE] =
                                        hashtagSelected
                                            ? hashtagSelected
                                            : {
                                                  type: HashtagType.STRUCTURE,
                                                  content: '',
                                              }
                                    setComponentStructureTags(newArray)
                                    setSelectedHashtags(
                                        newArray.filter((x) => x.content != '')
                                    )
                                }}
                                type={HashtagType.STRUCTURE}
                                handleSaveTags={handleSaveAllHashtags}
                            />
                        </div>
                        <div className={'min-width-150px padding-md'}>
                            <span>Component:</span>
                            <SearchTag
                                placeholder={'N/A'}
                                allTags={
                                    allHashtags?.filter(
                                        (x) => x.type == HashtagType.COMPONENT
                                    ) || []
                                }
                                onChange={(hashtagSelected) => {
                                    let newArray = componentStructureTags
                                    newArray[HashtagType.COMPONENT] =
                                        hashtagSelected
                                            ? hashtagSelected
                                            : {
                                                  type: HashtagType.COMPONENT,
                                                  content: '',
                                              }
                                    setComponentStructureTags(newArray)
                                    setSelectedHashtags(
                                        newArray.filter((x) => x.content != '')
                                    )
                                }}
                                type={HashtagType.COMPONENT}
                                handleSaveTags={handleSaveAllHashtags}
                            />
                        </div>
                    </div>
                </Dialog>
            )}
            {notifyDelete && (
                <Dialog
                    text={`Deleting ${selectedMarkerItems.length} items(s)`}
                    description={'Are you sure you want to delete?'}
                    buttonText={'Delete'}
                    onClick={() => {
                        setDeleteSelectedItems(true)
                        setNotifyDelete(false)
                    }}
                    onClose={() => setNotifyDelete(false)}
                />
            )}
            {(storageAccount === '' || loadingPage) && (
                <div
                    className={`flex w-100 h-100 justify-center align-content-center align-center`}>
                    <LoadingSpinner width={80} height={80} />
                </div>
            )}
            {assetList && assetList?.length < 1 && (
                <div
                    className={`flex w-100 h-100 justify-center align-content-center align-center text-center h2`}>
                    Opps, there are no assets here.
                    <br />
                    Do create one with "Add Asset" button!
                </div>
            )}
            <div
                className={`w-100 flex flex-wrap justify-start align-start
          overflow-auto flex-grow-1 rel`}>
                {storageAccount != '' &&
                    assetList &&
                    assetList.length > 0 &&
                    asset === '' && (
                        <AssetPanel
                            list={assetList}
                            selectItem={setAsset}
                            noStorage={noStorage}
                            updateLogOnStorageAccess={updateLogOnStorageAccess}
                            handleDeleteAsset={deleteAsset}
                        />
                    )}
                {asset !== '' && (
                    <FolderPanel
                        list={dateFolderList}
                        dateFolder={dateFolder}
                        setDateFolder={setDateFolder}
                        markerMap={markerMap}
                        thumbnailMap={thumbnailMap}
                        hashtagMap={hashtagMap}
                        selectedItems={selectedMarkerItems}
                        setSelectedItems={setSelectedMarkerItems}
                        viewMode={toggleViewMode}
                        toggleHashtag={toggleHashtag}
                        filter={filter}
                        setMarker={setMarker}
                        setModel={setModel}
                        setViewSelectedItems={handleViewSelectedItems}
                        getModelNameFromFileName={getModelNameFromFileName}
                        isMarkerType={isMarkerType}
                        isModelType={isModelType}
                        deleteSelectedItems={deleteSelectedItems}
                        updateOpenSnapshot={updateOpenSnapshot}
                        selectButtons={selectButtons}
                        setSelectButtons={setSelectButtons}
                    />
                )}
                {snackbarText !== '' && (
                    <Snackbar
                        text={snackbarText}
                        onClose={resetSnackbar}
                        status={snackbarStatus}
                    />
                )}
                {asset !== '' && marker && dateFolder != '' && hashtagList && (
                    <ModalOverlay
                        isVisible={marker !== null}
                        isLoading={isLoading}
                        onCloseModal={() => {
                            setDateFolder('')
                            setMarker(null)
                            setEdited(false)
                        }}>
                        <ReportPanel
                            marker={marker}
                            setMarker={setMarker}
                            hashtagList={hashtagList}
                            thumbnailList={thumbnailMap?.get(marker.bag_id)}
                            location={location}
                            models={models}
                            storageAccount={storageAccount}
                            asset={asset}
                            dateFolder={dateFolder}
                            edited={edited}
                            setEdited={(edited) => setEdited(edited)}
                            saveEdit={(
                                folder: string,
                                newMarker: MarkerType,
                                newHashtags: Hashtag[]
                            ) => {
                                handleSaveEdit(
                                    asset,
                                    newHashtags,
                                    newMarker,
                                    undefined,
                                    folder
                                )
                            }}
                            allHashtags={allHashtags}
                            saveAllHashtags={handleSaveAllHashtags}
                            setHashtagEdited={loadHashtags}
                        />
                    </ModalOverlay>
                )}
                {asset != '' &&
                    dateFolder != '' &&
                    hashtagList &&
                    model &&
                    model.id == 'pec' && (
                        <PECPanel
                            markerMap={markerMap}
                            model={model}
                            onClose={() => {
                                setDateFolder('')
                                setModel(null)
                                setEdited(false)
                            }}
                        />
                    )}
                {asset !== '' &&
                    model &&
                    model.id !== 'pec' &&
                    dateFolder != '' &&
                    hashtagList && (
                        <ModalOverlay
                            isVisible={model !== null}
                            isLoading={isLoading}
                            onCloseModal={() => {
                                setDateFolder('')
                                setModel(null)
                                setEdited(false)
                                clearBeexFilePlayerCache()
                            }}
                            onGoBack={
                                isBeexFilePlaying
                                    ? () => setIsBeexFilePlaying(false)
                                    : undefined
                            }>
                            {isBeexFilePlaying ? (
                                <VideoPage
                                    setBagName={(_: string) => {}}
                                    playableBeexFile={
                                        isBeexFilePlaying
                                            ? playableBeexFile
                                            : undefined
                                    }
                                    beexStartTime={beexStartTime}
                                    beexEndTime={beexEndTime}
                                />
                            ) : (
                                <PCDReportPanel
                                    model={model}
                                    resetAftUpload={() => {
                                        setModel(null)
                                        setSnackbarSuccessText(
                                            'File uploaded successfully. Please refresh the page.'
                                        )
                                    }}
                                    hashtagList={hashtagList}
                                    thumbnailList={thumbnailMap?.get(
                                        model.file
                                    )}
                                    location={location}
                                    models={models}
                                    storageAccount={storageAccount}
                                    asset={asset}
                                    dateFolder={dateFolder}
                                    edited={edited}
                                    setEdited={(edited) => setEdited(edited)}
                                    saveEdit={(
                                        folder: string,
                                        newModel: ModelType,
                                        newHashtags: Hashtag[]
                                    ) => {
                                        handleSaveEdit(
                                            asset,
                                            newHashtags,
                                            undefined,
                                            newModel,
                                            folder
                                        )
                                    }}
                                    getModelNameFromFileName={
                                        getModelNameFromFileName
                                    }
                                    allHashtags={allHashtags}
                                    saveAllHashtags={handleSaveAllHashtags}
                                    setHashtagEdited={loadHashtags}
                                    setPlayableBeexFile={setPlayableBeexFile}
                                    playableBeexFile={playableBeexFile}
                                    setBeexStartTime={setBeexStartTime}
                                    setBeexEndTime={setBeexEndTime}
                                    startPlayingBeexFile={() =>
                                        setIsBeexFilePlaying(true)
                                    }
                                />
                            )}
                        </ModalOverlay>
                    )}
                {storageAccount !== '' && viewSelectedItems && (
                    <ModalOverlay
                        isVisible={true}
                        isLoading={isLoading}
                        onCloseModal={() => {
                            setDateFolder('')
                            setMarker(null)
                            setModel(null)
                            setViewSelectedItems(false)
                            setEdited(false)
                        }}>
                        <MultipleReportPanel
                            markers={selectedMarkerItems}
                            hashtagMap={
                                hashtagMap
                                    ? hashtagMap
                                    : new Map<string, Hashtag[]>()
                            }
                            thumbnailList={thumbnailMap}
                            location={location}
                            models={models}
                            storageAccount={storageAccount}
                            asset={asset}
                            toggleViewMode={toggleViewMode}
                            getModelNameFromFileName={getModelNameFromFileName}
                            dateFolder={dateFolder}
                            edited={edited}
                            setEdited={(edited) => setEdited(edited)}
                            saveEdit={(
                                folder: string,
                                newHashtags: Hashtag[],
                                newMarker?: MarkerType,
                                newModel?: ModelType
                            ) => {
                                if (newMarker) {
                                    handleSaveEdit(
                                        asset,
                                        newHashtags,
                                        newMarker,
                                        undefined,
                                        folder
                                    )
                                } else {
                                    handleSaveEdit(
                                        asset,
                                        newHashtags,
                                        undefined,
                                        newModel,
                                        folder
                                    )
                                }
                            }}
                            allHashtags={allHashtags}
                        />
                    </ModalOverlay>
                )}
            </div>
        </div>
    )
}

export default ReportPage
