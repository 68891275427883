import React, { useEffect, useState, useContext } from 'react'
import PriorityIcon from '../../assets/icons/priority_high_black_24dp.svg'
import AddIcon from '../../assets/icons/add_black_24dp.svg'
import BackIcon from '../../assets/icons/arrow_back_black_24dp.svg'
import HomeIcon from '../../assets/icons/home_black_24dp.svg'
import MoreIcon from '../../assets/icons/more_vert_black_24dp.svg'
import CrossIcon from '../../assets/icons/close_black_24dp.svg'
import IconButton from '../elements/buttons/IconButton'
import SegmentedButton from '../elements/buttons/SegmentedButton'
import SearchBar from '../elements/SearchBar'
import {
    isMobile,
    regexAssetName,
    regexStorageName,
    unregexStorageName,
} from '../utils/general'
import { MarkerContainerPairType, Hashtag, HashtagType } from '../../types'
import FilledButton from '../elements/buttons/FilledButton'
import TextButton from '../elements/buttons/TextButton'
import Dropdown from '../elements/Dropdown'
import { useAccount, useMsal } from '@azure/msal-react'
import {
    msalFetchHashtagIds,
    msalFetchHashtagList,
} from '../../backend/hashtag'
import { FilterType, initFilter } from '../utils/FilterUtils'
import { UploadFileIcon, TuneIcon } from '../../assets'
import GhostPanel from '../elements/GhostPanel'
import { AppContext } from '../../store/context'
import { FilterActionType, TagActionType } from '../../store/reducers'
import { authorisedUsersToDelete } from '../../constants/environment'

const thumbnailOptions = ['Front Camera', 'Sonar', 'External Camera']

type NavigationBarProps = {
    asset: string
    storage: string
    toggleViewMode: number
    toggleHashtag: number
    allHashtags: Hashtag[]
    selectedItemNum: number
    selectButtons: Map<string, boolean>
    goBack: () => void
    goHome: () => void
    goToStorage: () => void
    setFilter: (value: FilterType) => void
    setViewMode: (value: number) => void
    setToggleHashtag: (value: number) => void
    setSelectedItems: (value: MarkerContainerPairType[]) => void
    setViewSelectedItems: (value: boolean) => void
    setDeleteSelectedItems: (value: boolean) => void
    setNotifyDelete: React.Dispatch<React.SetStateAction<boolean>>
    setNotifyEditHashtags: React.Dispatch<React.SetStateAction<boolean>>
    dropdownList?: string[]
    dropdownSelected?: string
    setDropdownSelected?: (value: string) => void
    setNotifyAddAsset?: React.Dispatch<React.SetStateAction<boolean>>
    setNotifyAddNonGLBModel?: React.Dispatch<React.SetStateAction<boolean>>
    setNotifyAddGLBModel?: React.Dispatch<React.SetStateAction<boolean>>
    setSelectButtons: (value: Map<string, boolean>) => void
}

const NavigationBar = ({
    asset,
    storage,
    selectedItemNum,
    toggleViewMode,
    toggleHashtag,
    allHashtags,
    selectButtons,
    goBack,
    goHome,
    goToStorage,
    setViewMode,
    setToggleHashtag,
    setSelectedItems,
    setViewSelectedItems,
    setDeleteSelectedItems,
    setNotifyDelete,
    setNotifyEditHashtags,
    dropdownList,
    dropdownSelected,
    setDropdownSelected,
    setNotifyAddAsset,
    setNotifyAddNonGLBModel,
    setNotifyAddGLBModel,
    setSelectButtons,
}: NavigationBarProps) => {
    const mobile = isMobile()
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const [showMore, setShowMore] = useState(false)
    const { state, dispatch } = useContext(AppContext)
    const [showAddModelDropDown, setShowAddModelDropDown] = useState(false)

    const onClickCross = () => {
        setSelectedItems([])
        Array.from(selectButtons.keys()).forEach((key) =>
            selectButtons.set(key, false)
        )
        setSelectButtons(selectButtons)
    }
    const fetchHashtag = async () => {
        if (!storage || !asset) return
        const msal = {
            instance: instance,
            account: account,
            storage: storage,
            asset: asset,
        }
        dispatch({
            type: TagActionType.SET_STAG,
            payload: await msalFetchHashtagList(msal, HashtagType.STRUCTURE),
        })
        dispatch({
            type: TagActionType.SET_CTAG,
            payload: await msalFetchHashtagList(msal, HashtagType.COMPONENT),
        })
    }
    useEffect(() => {
        fetchHashtag()
    }, [storage, asset])
    return (
        <div
            className='h2 flex align-center align-content-center w-100 flex-wrap rel'
            style={{ flex: '0 0 72px' }}>
            {selectedItemNum > 0 && (
                <div
                    className={`abs inset-0 z-dialog background flex align-center`}>
                    <IconButton
                        Icon={CrossIcon}
                        onClick={() => onClickCross()}
                    />
                    <div className='h1 padding-sm text-center text-vertical-middle'>{`${selectedItemNum} Selected`}</div>
                    <div className='flex-grow-1' />
                    {authorisedUsersToDelete.includes(accounts[0].username) && (
                        <TextButton
                            className='background'
                            contentColor='on-error'
                            text='DELETE'
                            onClick={() => {
                                setNotifyDelete(true)
                            }}
                        />
                    )}
                    <TextButton
                        className='background'
                        contentColor='on-error'
                        text='EDIT HASHTAGS'
                        onClick={() => {
                            setNotifyEditHashtags(true)
                        }}
                    />

                    <div className='padding-r-sm' />
                    <FilledButton
                        text='Open'
                        onClick={() => setViewSelectedItems(true)}
                    />
                </div>
            )}

            <div
                style={{ marginTop: '-2px' }}
                className='w-24px h-24px margin-r-md cursor-pointer'
                onClick={() => {
                    goHome()
                    dispatch({ type: FilterActionType.RESET })
                }}>
                <HomeIcon />
            </div>
            {asset && (
                <>
                    <div className={`w-1px h-24px margin-r-md outline`} />
                    <div
                        className={`w-24px h-24px margin-r-md cursor-pointer`}
                        onClick={() => {
                            goBack()
                            dispatch({ type: FilterActionType.RESET })
                        }}>
                        <BackIcon />
                    </div>
                </>
            )}
            {/* <div className={`abs inset-0 z-dialog background flex align-center`}> */}
            {(!dropdownList || dropdownList.length < 1) && (
                <span
                    className={`cursor-pointer margin-r-lg`}
                    onClick={() => goToStorage()}>
                    {asset || `ASSETS UNDER: ${dropdownSelected!}`}
                </span>
            )}
            {/* <span>&nbsp;{container ? '|' : ''}&nbsp;</span>
          <span style={{ cursor: 'pointer' }} onClick={() => goToContainer()}>
            {container}
          </span> */}
            {dropdownList && dropdownList.length > 1 && (
                <span
                    className={`cursor-pointer margin-r-lg flex flex-row align-center`}
                    onClick={() => goToStorage()}>
                    {regexAssetName(asset) || `ASSETS UNDER:  `}

                    <div
                        className={`z-dialog background padding-l-md`}
                        style={{ width: '320px' }}>
                        <div className={`flex flex-grow-1`} />
                        <Dropdown
                            list={dropdownList}
                            selected={dropdownSelected!}
                            select={(value) => setDropdownSelected!(value)}
                        />
                    </div>
                </span>
            )}

            {dropdownSelected && setNotifyAddAsset && asset === '' && (
                <div className='flex justify-start align-center flex-grow-1 rel'>
                    <div className='flex-grow-1' />
                    <TextButton
                        className='flex background justify-end'
                        text='+ ADD ASSET'
                        onClick={() => {
                            setNotifyAddAsset(true)
                        }}
                    />
                </div>
            )}
            {/* </div> */}
            {mobile && (
                <div className='flex-break-row' style={{ height: '12px' }} />
            )}
            {asset !== '' && (
                <div className='flex justify-start align-center flex-grow-1 rel'>
                    <div className='flex-grow-1 margin-r-md'>
                        <SearchBar storage={storage} asset={asset} />
                    </div>
                    {mobile && (
                        <IconButton
                            Icon={MoreIcon}
                            selected={showMore}
                            onClick={() => setShowMore(!showMore)}
                        />
                    )}
                    {mobile && showMore && (
                        <div
                            className={`abs z-10 right-0 background flex flex-row align-center`}
                            style={{ top: '46px' }}>
                            <div className='flex flex-col'>
                                {setNotifyAddGLBModel && (
                                    <TextButton
                                        Icon={AddIcon}
                                        onClick={() => {
                                            setNotifyAddGLBModel(true)
                                        }}
                                        text={'ADD GLB/OBJ'}
                                        className='w-100px'
                                    />
                                )}
                                {setNotifyAddNonGLBModel && (
                                    <TextButton
                                        Icon={AddIcon}
                                        onClick={() => {
                                            setNotifyAddNonGLBModel(true)
                                        }}
                                        text={'ADD XYZ/MOSAIC'}
                                        className='w-100px'
                                    />
                                )}
                            </div>
                            <div className='flex flex-col margin-t-xsm'>
                                <SegmentedButton
                                    options={thumbnailOptions}
                                    selected={toggleViewMode}
                                    onClick={(nextMode: number) => {
                                        setViewMode(nextMode)
                                    }}
                                />
                                <div className='margin-t-sm' />
                                <SegmentedButton
                                    options={['show #', 'No #']}
                                    selected={toggleViewMode}
                                    onClick={(nextMode: number) => {
                                        setViewMode(nextMode)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {!mobile && (
                        <div className='flex flex-row'>
                            {setNotifyAddNonGLBModel &&
                                setNotifyAddGLBModel && (
                                    <div
                                        className='rel'
                                        onMouseLeave={() =>
                                            setShowAddModelDropDown(false)
                                        }>
                                        <IconButton
                                            Icon={UploadFileIcon}
                                            className={`margin-r-md`}
                                            onClick={() =>
                                                setShowAddModelDropDown(
                                                    (bool) => !bool
                                                )
                                            }
                                        />
                                        {showAddModelDropDown && (
                                            <div
                                                className='abs flex flex-col z-dialog surface-variant-bg rounded-sm'
                                                style={{
                                                    right: '25%',
                                                    width: '155px',
                                                }}>
                                                <TextButton
                                                    maxWidth
                                                    text={'XYZ/MOSAIC'}
                                                    onClick={() =>
                                                        setNotifyAddNonGLBModel(
                                                            true
                                                        )
                                                    }
                                                />
                                                <TextButton
                                                    maxWidth
                                                    text={'GLB/OBJ'}
                                                    onClick={() =>
                                                        setNotifyAddGLBModel(
                                                            true
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            {setNotifyAddNonGLBModel &&
                                !setNotifyAddGLBModel && (
                                    <IconButton
                                        Icon={UploadFileIcon}
                                        className={`margin-r-md`}
                                        onClick={() => {
                                            setNotifyAddNonGLBModel(true)
                                        }}
                                    />
                                )}
                            {setNotifyAddGLBModel &&
                                !setNotifyAddNonGLBModel && (
                                    <IconButton
                                        Icon={UploadFileIcon}
                                        className={`margin-r-md`}
                                        onClick={() => {
                                            setNotifyAddGLBModel(true)
                                        }}
                                    />
                                )}
                            <NavBarPanel
                                thumbnail={toggleViewMode}
                                setThumbnail={setViewMode}
                                showTag={toggleHashtag}
                                setShowTag={setToggleHashtag}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default NavigationBar

type NavBarPanelProps = {
    thumbnail: number
    setThumbnail: (value: number) => void
    showTag: number
    setShowTag: (value: number) => void
}

const NavBarPanel = ({
    thumbnail,
    setThumbnail,
    showTag,
    setShowTag,
}: NavBarPanelProps) => {
    const [expand, setExpand] = useState(false)

    return (
        <div className='rel'>
            <IconButton
                Icon={TuneIcon}
                className={`margin-r-md`}
                selected={expand}
                onClick={() => {
                    setExpand(!expand)
                }}
            />
            {expand && (
                <>
                    <GhostPanel onClick={() => setExpand(false)} />
                    <div className='abs surface-container-bg shadow padding-lg rounded-sm border-box z-10 right-0 flex align-stretch'>
                        <div className='flex flex-col justify-around margin-r-sm'>
                            <div className='text-sm text-vertical-middle'>
                                Thumbnail
                            </div>
                            <div className='text-sm text-vertical-middle'>
                                Hashtag
                            </div>
                        </div>
                        <div className='flex flex-col align-stretch justify-around'>
                            <SegmentedButton
                                options={thumbnailOptions}
                                selected={thumbnail}
                                onClick={setThumbnail}
                            />
                            <div className='margin-h-xsm' />
                            <SegmentedButton
                                options={['Show', 'Hide']}
                                selected={showTag}
                                onClick={setShowTag}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
