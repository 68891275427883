import React, { useState, useEffect } from 'react'
import FullscreenIcon from '../../../../assets/icons/fullscreen_black_24dp.svg'
import { SonarInfo, PanelType, expandablePanelTypes } from '../../../../types'
import Button from '../../../elements/buttons/Button'
import { HBlock } from '../../../elements/dividers'
import { isMobile } from '../../../utils/general'
import SonarImage, { SonarImageInterface } from '../../../utils/sonar'
import SadFaceIcon from '../../../../assets/icons/drawing.svg'
import Dropdown from '../../../elements/Dropdown'

type LeftPanelProps = {
  topPanelType: PanelType
  topRemainingPanelTypes: PanelType[]
  bottomPanelType: PanelType
  bottomRemainingPanelTypes: PanelType[]
  topDropdownSelect: (str: string) => void
  bottomDropdownSelect: (str: string) => void
  panelDropdownMenusWidthInPx: number
  frontCamImage: string
  frontCamClaheImage: string
  sonarImage: string
  fullDepthCamImage: string
  setDrawableCanvasViewMode: (value: number) => void
  setTopPanelIsExpanded: (value: boolean | undefined) => void
  setExpandSonar: (value: boolean) => void
  setRenderMap: (value: boolean) => void
  sonarRef?: React.RefObject<SonarImageInterface>
  sonarInfo?: SonarInfo
}

const LeftPanel = ({
  topPanelType,
  topRemainingPanelTypes,
  bottomPanelType,
  bottomRemainingPanelTypes,
  topDropdownSelect,
  bottomDropdownSelect,
  panelDropdownMenusWidthInPx,
  frontCamImage,
  frontCamClaheImage,
  sonarImage,
  fullDepthCamImage,
  setDrawableCanvasViewMode,
  setTopPanelIsExpanded,
  setExpandSonar,
  setRenderMap,
  sonarRef,
  sonarInfo,
}: LeftPanelProps) => {
  const mobile = isMobile()

  useEffect(() => {
    setTimeout(() => setRenderMap(true), 1)
  }, [])

  const getPanel = (panelType: PanelType, isTop: boolean) => {
    if (
      (frontCamClaheImage === '' && panelType === PanelType.FRONT_CAM_CLAHE) ||
      (frontCamImage === '' && panelType === PanelType.FRONT_CAM) ||
      (sonarImage === '' && panelType === PanelType.SONAR)
    )
      return (
        <div className='w-100 h-100 flex flex-col justify-center align-center outline-border rounded-sm'>
          <SadFaceIcon width={120} height={120} />
          <div className='padding-h-sm'>
            {`Opps, the ${panelType
              .toString()
              .toLowerCase()} image is missing.`}
          </div>
        </div>
      )

    if (panelType === PanelType.SONAR)
      return (
        <SonarImage
          imgClassName={
            'bg-black-6 rounded-sm w-100 h-fit-content max-height-100 overflow-hidden rel'
          }
          ref={sonarRef}
          src={sonarImage}
          sonarInfo={sonarInfo}
          open={() => setExpandSonar(true)}
          close={() => setExpandSonar(false)}
        />
      )

    let url = ''
    switch (panelType) {
      case PanelType.FRONT_CAM:
        url = frontCamImage
        break
      case PanelType.FRONT_CAM_CLAHE:
        url = frontCamClaheImage
        break
      case PanelType.EXT_CAM:
        url = fullDepthCamImage
        break
      default:
        throw new Error('Invalid panel type')
    }
    return (
      <MouseOverImage
        src={url}
        open={() => {
          setDrawableCanvasViewMode(expandablePanelTypes.indexOf(panelType))
          isTop ? setTopPanelIsExpanded(true) : setTopPanelIsExpanded(false)
        }}
        close={() => {
          setDrawableCanvasViewMode(-1)
          setTopPanelIsExpanded(undefined)
        }}
      />
    )
  }

  return (
    // <div className='w-30 h-100 flex justify-between align-start flex-wrap overflow-y-auto'>
    <div
      className={`flex flex-col border-box ${
        mobile ? 'padding-b-lg w-100' : 'padding-r-lg w-50'
      }`}>
      {mobile ? (
        <>
          {topPanelType && (
            <>
              <div className='rel w-fit-content'>
                <Dropdown
                  list={topRemainingPanelTypes}
                  selected={topPanelType}
                  select={topDropdownSelect}
                  width={`${panelDropdownMenusWidthInPx}px`}
                  isMinimal={true}
                  wrappingOverflowingOptions={true}
                  noOptionListTopGap={true}
                />
              </div>
              {getPanel(topPanelType, true)}
            </>
          )}
          {bottomPanelType && (
            <>
              <div className='rel w-fit-content margin-t-xsm'>
                <Dropdown
                  list={bottomRemainingPanelTypes}
                  selected={bottomPanelType}
                  select={bottomDropdownSelect}
                  width={`${panelDropdownMenusWidthInPx}px`}
                  isMinimal={true}
                  wrappingOverflowingOptions={true}
                  noOptionListTopGap={true}
                />
              </div>
              {getPanel(bottomPanelType, false)}
            </>
          )}
        </>
      ) : (
        <>
          {topPanelType && (
            <div className={'flex'}>
              <div
                className='margin-r-sm margin-t-xxsm rel h-fit-content'
                style={{ width: `${panelDropdownMenusWidthInPx}px` }}>
                <Dropdown
                  list={topRemainingPanelTypes}
                  selected={topPanelType}
                  select={topDropdownSelect}
                  width={`${panelDropdownMenusWidthInPx}px`}
                  isMinimal={true}
                  wrappingOverflowingOptions={true}
                  noOptionListTopGap={true}
                />
              </div>
              {getPanel(topPanelType, true)}
            </div>
          )}
          <HBlock />
          {bottomPanelType && (
            <div className={'flex'}>
              <div
                className='margin-r-sm margin-t-xxsm rel h-fit-content'
                style={{ width: `${panelDropdownMenusWidthInPx}px` }}>
                <Dropdown
                  list={bottomRemainingPanelTypes}
                  selected={bottomPanelType}
                  select={bottomDropdownSelect}
                  width={`${panelDropdownMenusWidthInPx}px`}
                  isMinimal={true}
                  wrappingOverflowingOptions={true}
                  noOptionListTopGap={true}
                />
              </div>
              {getPanel(bottomPanelType, false)}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default LeftPanel

type MouseOverImageProps = {
  src: string
  open?: () => void
  close?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
}

const MouseOverImage = ({
  src,
  open,
  close,
  onMouseOver,
  onMouseOut,
}: MouseOverImageProps) => {
  const [hover, setHover] = useState(false)

  return (
    <div
      className={` ${
        hover ? 'cursor-pointer' : ''
      } bg-black-6 rounded-sm w-100 h-fit-content max-height-100 overflow-hidden rel`}
      onClick={() => {
        if (open) open()
      }}>
      <div className='abs bottom-12px right-12px'>
        <Button
          Icon={FullscreenIcon}
          onClick={() => {
            if (open) open()
          }}
        />
      </div>
      <img
        className={`w-100 max-height-100 rounded-sm object-fit-contain`}
        src={src}
        onMouseOver={() => {
          setHover(true)
          if (onMouseOver) onMouseOver()
        }}
        onMouseOut={() => {
          setHover(false)
          if (onMouseOut) onMouseOut()
        }}
      />
    </div>
  )
}
